import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import { CustomField } from '@/components/Form/customFields/CustomField'
import CaluButton from '@/components/styledComponents/CaluButton'
import { deleteCentreProviderAdded } from '@/store/centreProvider/actions'
import { Grid } from '@mui/material'

import s from '../../../styles.module.scss'

export const FormContent = ({ isLoading = false, onClose, formValues }) => {
  const dispatch = useDispatch()

  const [openAlertDelete, setOpenAlertDelete] = useState(false)

  const handleDeleteCentreProvider = () => {
    dispatch(deleteCentreProviderAdded(formValues._id))
    onClose()
  }

  return (
    <>
      <Grid container spacing={2} className={s.containerForm}>
        <Grid item xs={12} md={6}>
          <CustomField label="Nombre del proveedor *" name="name" fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomField label="CUIT" name="cuit" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <CustomField label="Nota" name="note" fullWidth multiline={true} rows={3} />
        </Grid>

        <Grid item xs={12} className={s.containerButtonsForm}>
          {formValues !== null && (
            <>
              <CaluButton color="primary" inverted="true" size="medium" onClick={() => setOpenAlertDelete(true)}>
                Eliminar
              </CaluButton>
              <AlertDialogSlide
                open={openAlertDelete}
                setOpen={setOpenAlertDelete}
                title="Eliminar proveedor"
                textContent={'¿Realmente quiere eliminar el proveedor? Esta acción no se podrá restaurar.'}
                textBackButton="Cancelar"
                textConfirmationButton={'Eliminar'}
                onClose={handleDeleteCentreProvider}
              />
            </>
          )}

          <CaluButton color="primary" type="submit" size="medium" loading={isLoading}>
            Guardar
          </CaluButton>
        </Grid>
      </Grid>
    </>
  )
}
