import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import DebtAlert from '@/components/DebtAlert/DebtAlert'
import NavBar from '@/components/NavBar'
import { getBankTransferInfo } from '@/store/bankTransferInfo/actions'
import { fetchMercadoPagoSubscription } from '@/store/mercadoPago/actions'
import { getAllSubscriptionPayments, getSubscriptionPaymentsDebt } from '@/store/subscriptionPayments/actions'
import { getAllSubscriptionPlans } from '@/store/subscriptionPlans/actions'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab, tabsClasses } from '@mui/material'

import Information from './components/Information'
import Payments from './components/Payments'

import s from './styles.module.scss'

const MyPaysPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState('1')
  const [isLoading, setIsLoading] = useState(false)
  const [dataSubscription, setDataSubscription] = useState(null)

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { allSubscriptionPayments } = useSelector((state) => state?.subscriptionPayments)
  const { debt } = useSelector((state) => state.subscriptionPayments)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const setInit = async () => {
    setIsLoading(true)
    const params = {
      centre: centreDetail?._id,
    }

    dispatch(getSubscriptionPaymentsDebt({ centreId: centreDetail?._id }))
    await dispatch(getAllSubscriptionPayments(params))
    await dispatch(getAllSubscriptionPlans())
    await dispatch(getBankTransferInfo())

    if (centreDetail?.centreConfiguration?.subscriptionPaymentType === 'mercadoPagoSubscription') {
      const subscriptionFetch = await dispatch(
        fetchMercadoPagoSubscription({
          centreId: centreDetail?._id,
          mercadoPagoPreapprovalPlanId: centreDetail?.centreConfiguration?.mercadoPagoPreapprovalPlanId,
        }),
      )

      if (subscriptionFetch) setDataSubscription(subscriptionFetch)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (centreDetail?.status === 'demo') {
      navigate('/')
    }
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={s.container}>
      <NavBar />
      <div className={s.cardContainer}>
        <Box sx={{ width: '100%' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="Pestaña historias clínicas"
                sx={{
                  '& .MuiTab-root.Mui-selected': {
                    color: '#009688',
                  },
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                  },
                }}
              >
                <Tab label="Información" value="1" />
                {centreDetail?.centreConfiguration?.enableMyPayments && allSubscriptionPayments?.length > 0 && (
                  <Tab label="Pagos" value="2" />
                )}
              </TabList>
            </Box>

            <TabPanel value="1">
              <Information isLoading={isLoading} dataSubscription={dataSubscription} />
            </TabPanel>
            <TabPanel value="2">
              {debt?.totalDebt > 0 && (
                <div className={s.containerDebtAlert}>
                  <DebtAlert debt={debt} country={country} />
                </div>
              )}
              <Payments />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  )
}

export default MyPaysPage
