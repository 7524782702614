import { fetchBankTransferInfo, putBankTransferInfo } from '@/services/bankTransfer'
import { FETCH_BANKTRANSFER_INFO, LOADING_BANKTRANSFER } from '@/store/bankTransferInfo'
import { showToast } from '@/utils/toast'

const getBankTransferInfo = () => async (dispatch) => {
  try {
    const bankInfo = await fetchBankTransferInfo()
    dispatch(FETCH_BANKTRANSFER_INFO(bankInfo))
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Se produjo un error', 'error')
  }
}

const updateInfoBankTransfer = (data) => async (dispatch) => {
  try {
    dispatch(LOADING_BANKTRANSFER(true))
    const infoBankUpdated = await putBankTransferInfo(data)
    dispatch(FETCH_BANKTRANSFER_INFO(infoBankUpdated))
    dispatch(LOADING_BANKTRANSFER(false))
    showToast('Datos bancarios actualizados correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Error al actualizar datos bancarios', 'error')
    dispatch(LOADING_BANKTRANSFER(false))
  }
}

export { getBankTransferInfo, updateInfoBankTransfer }
