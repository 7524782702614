import React from 'react'
import { BsDot } from 'react-icons/bs'
import SelectWithSearchByParams from '@/components/SelectWithSearchByParms'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { Button, Grid } from '@mui/material'

import s from './styles.module.scss'

const TextFieldWithList = ({
  inputLabel,
  listItems,
  searchText,
  setSearchText,
  functionToDispatch,
  addNewItem,
  deleteItem,
  stateRedux,
}) => {
  return (
    <Grid item xs={12}>
      <Grid item xs={12} className={s.gridInput}>
        <Grid item xs={9}>
          <SelectWithSearchByParams
            inputLabel={inputLabel}
            valueSelect={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            stateRedux={stateRedux}
            functionToDispatch={functionToDispatch}
          />
        </Grid>{' '}
        <Button variant="contained" disableElevation onClick={() => addNewItem()} size="small" className={s.addButton}>
          +
        </Button>
      </Grid>

      <Grid item xs={12}>
        {listItems?.length ? (
          <ul className={s.divUl}>
            {listItems?.map((item) => (
              <li key={item._id} className={s.listItem}>
                <div className={s.item}>
                  <BsDot />
                  {item?.name ? (
                    <b>{`${item.name} (ID: ${item._id})`}</b>
                  ) : (
                    <b>{`${capitalizeFirstLetters(item?.lastname)} ${capitalizeFirstLetters(item.firstname)}`}</b>
                  )}
                  {item?.centre && <b> {` Centro: ${item?.centre?.name} `}</b>}
                  {item?.centre && <b> {` ID centro: ${item?.centre?._id} `}</b>}
                </div>

                <Button
                  className={s.btnDelete}
                  variant="contained"
                  disableElevation
                  onClick={() => deleteItem(item._id)}
                >
                  X
                </Button>
              </li>
            ))}
          </ul>
        ) : null}
      </Grid>
    </Grid>
  )
}

export default TextFieldWithList
