import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'fieldsHC',
  initialState: {
    allFieldsHC: [],
    selectedHCField: null,
  },
  reducers: {
    FETCH_FIELDS_HC: (state, { payload }) => {
      state.allFieldsHC = payload
    },
    FIELD_HC_SELECTED: (state, { payload }) => {
      state.selectedHCField = payload
    },
    RESET_FIELD_HC_SELECTED: (state, { payload }) => {
      state.selectedHCField = null
    },
    FIELD_HC_CREATED: (state, { payload }) => {
      state.allFieldsHC = [...(state.allFieldsHC || []), payload]
    },
    FIELD_HC_UPDATED: (state, { payload }) => {
      state.allFieldsHC = state.allFieldsHC.map((field) => {
        if (field._id === payload._id) {
          field = payload
        }
        return field
      })
      state.selectedHCField = payload
    },
    FIELD_HC_DELETE: (state, { payload }) => {
      state.allFieldsHC = state.allFieldsHC.filter((field) => field._id !== payload)
      state.selectedHCField = null
    },
  },
})

export const {
  FETCH_FIELDS_HC,
  FIELD_HC_SELECTED,
  RESET_FIELD_HC_SELECTED,
  FIELD_HC_CREATED,
  FIELD_HC_UPDATED,
  FIELD_HC_DELETE,
} = slice.actions
export default slice.reducer
