export const getMessageTypeReminder = (value) => {
  if (value === 3) {
    return 'Recordatorio de cita'
  }
  if (value === 4) {
    return 'Saludo de cumpleaños'
  }

  if (value === 5) {
    return 'Cita sin fecha'
  }
  if (value === 6) {
    return 'Cita cancelada'
  }
  if (value === 7) {
    return 'Cita finalizada'
  }
  return null
}
