import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchDoctorProfile = async (email) => {
  const route = `api/doctorProfiles/${email}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postDoctorProfile = async (data) => {
  const route = `api/doctorProfiles`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putDoctorProfile = async (data) => {
  const route = `api/doctorProfiles`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { fetchDoctorProfile, postDoctorProfile, putDoctorProfile }
