import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { resetUserPassword } from '@/store/users/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

const FormRecoverUserPassword = ({ className }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { errorResetUserPassword } = useSelector((state) => state.users)
  const [sentEmail, setSentEmail] = useState(false)

  const handleSubmit = async (data) => {
    setIsLoading(true)
    await dispatch(resetUserPassword(data))
    if (!errorResetUserPassword) {
      setSentEmail(true)
    }
    setIsLoading(false)
  }

  return (
    <ContainerForm
      onSubmit={(data) => handleSubmit(data)}
      defaultValues={{ ...formDefaultValues }}
      resolver={yupResolver(formValidationSchema)}
    >
      <FormContent isLoading={isLoading} sentEmail={sentEmail} />
    </ContainerForm>
  )
}

export default FormRecoverUserPassword
