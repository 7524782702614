import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import { setCurrentCentre } from '@/controllers/centres'
import useDebounce from '@/hooks/useDebounce'
import useValidateURLId from '@/hooks/useValidateURLId'
import { getCurrentCentre } from '@/store/centres/actions'
import { getAllServices, resetSelectedService } from '@/store/services/actions'

import MainPopUp from './components/mainPopUp'
import ServicesSearchBar from './components/ServicesSearchBar'
import ServicesTable from './components/ServicesTable'
import Layout1 from '../../layouts/Layout1/index'

import s from './styles.module.scss'

const ServicesPage = () => {
  const dispatch = useDispatch()
  const centreID = useValidateURLId()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const allServices = useSelector((state) => state.services?.allServices)
  const serviceSelected = useSelector((state) => state.services?.serviceSelected)
  const [searchText, setSearchText] = useState('')
  const DELAY_TIME = 300
  const MIN_CHARACTERS = 0
  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)

  const setInit = async () => {
    const currentCentre = await setCurrentCentre(centreID)
    dispatch(getCurrentCentre(currentCentre))
  }

  const getCentreAndServices = async () => {
    const params = {
      centre: centreID,
      paginate: true,
      page: 1,
    }
    await setInit()
    await dispatch(getAllServices(params))
    setIsLoading(false)
  }

  useEffect(() => {
    getCentreAndServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centreID])

  const getServiceBySearch = async () => {
    const params = {
      centre: centreID,
      paginate: true,
      search: inputDebounceValue,
    }
    setIsLoading(true)
    await dispatch(getAllServices(params))
    setIsLoading(false)
  }

  useEffect(() => {
    const inputSearch = async () => {
      if (inputDebounceValue?.length >= MIN_CHARACTERS) {
        getServiceBySearch()
      }
    }
    inputSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDebounceValue])

  const addNewService = () => {
    dispatch(resetSelectedService())
    setOpen(!open)
  }

  return (
    <Layout1 navbar>
      <div className={`${s.animatedContainer} animate__animated animate__fadeIn`}>
        <ServicesSearchBar addNewService={addNewService} inputSearch={searchText} setInputSearch={setSearchText} />
        {isLoading ? (
          <LoaderCircle size={50} />
        ) : allServices?.length === 0 ? (
          <p className={s.noItems}>No hay servicios cargados aún</p>
        ) : (
          <ServicesTable setOpen={setOpen} searchText={searchText} />
        )}
      </div>

      <MainPopUp open={open} setOpen={setOpen} serviceSelected={serviceSelected} />
    </Layout1>
  )
}

export default ServicesPage
