import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingCalu from '@/components/LoadingCalu'
import { useSocket } from '@/context/SocketProvider'
import { setCurrentCentre } from '@/controllers/centres'
import useValidateURLId from '@/hooks/useValidateURLId'
import { getCurrentCentre } from '@/store/centres/actions'
import Grid from '@mui/material/Grid'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const WaitingRoomView = () => {
  const idCentre = useValidateURLId()
  const dispatch = useDispatch()
  const centre = useSelector((state) => state.centres?.centreProfile)
  const [happeningTurns, setHappeningTurns] = useState([])
  const { socket } = useSocket()

  socket.on('calendar_spread_edit_turn', (turn) => {
    if (turn.state === 'happening') {
      setHappeningTurns([turn, ...happeningTurns])
      return
    }
    if (turn.state !== 'happening') {
      const filteredTurns = happeningTurns?.filter((t) => t?._id !== turn._id)
      return setHappeningTurns(filteredTurns)
    }
  })
  socket.on('calendar_spread_delete_turn', (turn) => {
    const filteredTurns = happeningTurns?.filter((t) => t?._id !== turn._id)
    setHappeningTurns(filteredTurns)
  })

  // const playAudio = () => {
  //   const sound = document.getElementById('notification-sound')
  //   sound.play()
  // }

  const getCentreData = async (idCentre) => {
    const currentCentre = await setCurrentCentre(idCentre)
    dispatch(getCurrentCentre(currentCentre))
  }

  useEffect(() => {
    getCentreData(idCentre)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [happeningTurns])

  const showDate = () => {
    const day = dayjs().format('dddd DD ')
    const month = dayjs().format('MMMM')
    const hour = dayjs().format('HH:mm')

    return (
      <>
        <span>{`${day} de ${month} `}</span>
        <span>|</span>
        <span style={{ color: '#777', fontWeight: 900 }}>{` ${hour} hs`}</span>
      </>
    )
  }
  const getDoctorCentreRoom = (turn) => {
    const entityId = turn?.machine?._id || turn?.doctorCentre?._id
    const room = centre?.rooms?.find((room) => room?.people[0]?._id === entityId)

    return room?.name || 'Consultorio no asignado'
  }

  return (
    <>
      {centre ? (
        <Grid container className={s.gridContainer}>
          <Grid item className={s.gridItem1}>
            <div className={s.centreName}>{centre?.name?.toUpperCase()}</div>
            <div className={s.date}>{showDate()}</div>
          </Grid>
          <Grid container className={s.gridItem2}>
            {happeningTurns?.map((turn, i) => (
              <Grid item xs={i === 0 ? 12 : 6} className={i === 0 ? s.divTurn1 : s.divTurn2} key={turn._id}>
                <div>{`${turn?.patientCentre?.lastname}, ${turn.patientCentre?.firstname}`}</div>
                <div className={i === 0 ? s.room : ''}>{getDoctorCentreRoom(turn)}</div>
              </Grid>
            ))}
          </Grid>
          {/* <audio id="notification-sound">
            <source src="assets/sounds/maramba.mp3" type="audio/mp3" />
          </audio> */}
        </Grid>
      ) : (
        <LoadingCalu />
      )}
    </>
  )
}

export default WaitingRoomView
