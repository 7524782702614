import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import AutoCompleteChedoc from '@/components/AutoCompleteChedoc'
import DividerSection from '@/components/DividerSection'
import { getListInsurances } from '@/store/insurances/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import {
  getAllEntityServices,
  getEntityServicesByDate,
  getMachineDoctorsByDate,
} from '@/utils/functions/getEntityWorkTimesPropByDate'
import { getUniqueListBy } from '@/utils/functions/getUniqueListBy'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
} from '@mui/material'

import s from '../../styles.module.scss'

const TurnDataForm = (props) => {
  const { t } = useTranslation('global')
  const icon = <CheckBoxOutlineBlank fontSize="small" />
  const checkedIcon = <CheckBox fontSize="small" />

  const machineSelected = useSelector((state) => state.machines?.machineSelected)
  const { doctorCentreSelected: doctorCentres } = useSelector((state) => state.doctorCentres)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const [extraServices, setExtraServices] = useState(null)
  const [showExtraServiceInput, setShowExtraServiceInput] = useState(false)
  const [showBonoAndPracticeInput, setShowBonoAndPracticeInput] = useState(false)

  const [selectedBonoPrice, setSelectedBonoPrice] = useState(null)

  const insurancesPrice = useSelector((state) => state.insurances.insurancesPrice)
  const [selectedInsurancePrice, setSelectedInsurancePrice] = useState([])

  const centreInsurancesDetail = useSelector((state) => state?.centreInsurances?.centreInsurancesDetail)

  const centreInsurances =
    machineSelected && !machineSelected?.hideCentreInsurances
      ? centreInsurancesDetail
      : doctorCentres && !doctorCentres?.hideCentreInsurances
      ? centreInsurancesDetail
      : []
  const entitySelectedInsurances = machineSelected ? machineSelected?.insurances : doctorCentres?.insurances

  const insuranceList = [
    { _id: '-', name: 'Particular' },
    ...(centreInsurances?.insurances?.length > 0 ? centreInsurances?.insurances : []),
    ...(entitySelectedInsurances && entitySelectedInsurances?.length > 0 ? entitySelectedInsurances : []),
  ]
  const uniqueInsuranceList = insuranceList.filter((insurance, index) => {
    return insuranceList.findIndex((i) => i._id === insurance._id) === index
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setChecked(event.target.checked)
  }

  const watchService = props.watch('service')
  const insurance = props.watch('insurance')

  useEffect(() => {
    if (watchService) {
      if (
        watchService._id === null ||
        watchService?._id === 'firstConsultation' ||
        watchService?._id === 'consultation'
      ) {
        return setShowExtraServiceInput(false)
      }
      if (!machineSelected && getDoctorCentreExtraServices()?.length >= 1) return setShowExtraServiceInput(true)
      if (machineSelected && getMachineExtraServices()?.length >= 1) return setShowExtraServiceInput(true)
      setShowExtraServiceInput(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchService])

  useEffect(() => {
    handleInsurancePrice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurance])

  useEffect(() => {
    const service = watchService
    const doctorCentrePrice = doctorCentres?.price || 0
    const extraServicesPrice = extraServices?.map((e) => e.price || 0)
    const extraServicesDuration = extraServices?.map((e) => e.duration || 0)

    const sumExtraServicesPrice = extraServicesPrice?.reduce((a, p) => a + p, 0) || 0
    const sumExtraServicesDuration = extraServicesDuration?.reduce((a, p) => a + p, 0) || 0
    if (service && !selectedBonoPrice) {
      if (service._id === 'consultation') {
        props.setAmount(doctorCentrePrice)
        props.setDebt(doctorCentrePrice)
        props.setDurationTurn(props.durationEvent)
      } else if (service._id === 'firstConsultation') {
        props.setAmount(doctorCentres.priceFirstTime || doctorCentrePrice)
        props.setDebt(doctorCentres.priceFirstTime || doctorCentrePrice)
        props.setDurationTurn(doctorCentres?.durationFirstTime || props.durationEvent)
      } else if (service._id === null) {
        props.setAmount(0)
        props.setDebt(0)
        props.setDurationTurn(props.durationEvent)
      } else {
        props.setAmount((service.price || 0) + (sumExtraServicesPrice || 0))
        props.setDebt((service.price || 0) + (sumExtraServicesPrice || 0))
        props.setDurationTurn((service?.duration || props.durationEvent) + (sumExtraServicesDuration || 0))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchService, extraServices, selectedBonoPrice])

  useEffect(() => {
    if (selectedBonoPrice) {
      props.setAmount(selectedBonoPrice)
      props.setDebt(selectedBonoPrice)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBonoPrice])

  const handleInsurancePrice = () => {
    if (insurance === '-') return
    let filteredInsuranceDetail = insurancesPrice?.filter((i) => i.insurance._id === insurance?._id)

    if (filteredInsuranceDetail?.length > 0) {
      setShowBonoAndPracticeInput(true)
      setSelectedInsurancePrice(filteredInsuranceDetail)
    } else {
      setSelectedBonoPrice(null)
      setShowBonoAndPracticeInput(false)
      props.setValue('insurancePrice', '')
    }
  }

  const getWorkTimeAvailable = () => {
    const availableMachineDoctors = getMachineDoctorsByDate(props.selectedDate, machineSelected)
    return availableMachineDoctors?.sort((a, b) => (a?.lastname?.toLowerCase() > b?.lastname?.toLowerCase() ? 1 : -1))
  }

  const getMachineAvailableServices = () => {
    const noService = {
      _id: null,
      name: '-',
    }
    let availableServices
    if (props?.selectedDate) {
      availableServices = getEntityServicesByDate(props.selectedDate, machineSelected)
    }
    if (!props?.selectedDate) {
      availableServices = getAllEntityServices(machineSelected)
    }

    const services = [noService, ...availableServices]
    const allServices = getUniqueListBy(services, '_id')
    return allServices
  }
  const getDoctorCentreAvailableServices = () => {
    let availableServices
    if (props?.selectedDate) {
      availableServices = getEntityServicesByDate(props.selectedDate, doctorCentres)
    }
    if (!props?.selectedDate) {
      availableServices = getAllEntityServices(doctorCentres)
    }
    const allServices = getUniqueListBy(availableServices, '_id')

    return allServices
  }

  const getDoctorCentreExtraServices = () => {
    const services = getDoctorCentreAvailableServices()
    const noRepeatServiceSelected = services.filter((s) => s._id !== watchService?._id)
    const allServices = noRepeatServiceSelected
      .filter((s) => s._id !== 'consultation')
      .filter((s) => s._id !== 'firstConsultation')
    return allServices
  }

  const getMachineExtraServices = () => {
    const services = getMachineAvailableServices()
    const noRepeatServiceSelected = services?.filter((s) => s?._id !== watchService?._id)
    const allServices = noRepeatServiceSelected.filter((s) => s._id !== null)
    return allServices
  }

  const showDefaultValuesOfServices = () => {
    if (doctorCentres) {
      return getDoctorCentreAvailableServices()[0]
    } else {
      return getMachineAvailableServices()[1] || getMachineAvailableServices()[0]
    }
  }

  const onChangeService = (selectedValues) => {
    props.setValue('service', selectedValues)
    if (selectedValues._id === 'firstConsultation' || selectedValues._id === 'consultation') {
      setShowExtraServiceInput(false)
    } else {
      setExtraServices(null)
      setShowExtraServiceInput(true)
    }
  }

  const onChangeExtraService = (selectedValues) => {
    props.setValue(
      'extraServices',
      selectedValues.map((el) => el),
    )
    setExtraServices(selectedValues)
  }

  const onChangeInsurancePrice = (selectedValues) => {
    if (selectedValues?.price) {
      props.setValue('insurancePrice', selectedValues)
      setSelectedBonoPrice(selectedValues.price)
    } else {
      props.setValue('insurancePrice', null)
      setSelectedBonoPrice(null)
    }
  }

  const renderOptionServices = (props, option, selected) => {
    return (
      <li {...props} key={option._id} className={s.listOptions}>
        {capitalizeFirstLetters(option.name)}
        {option.duration && ` - Duración: ${option.duration} Min -`}
        {option.price && ` Precio: $${option.price}`}
      </li>
    )
  }

  const getOptionLabelServices = (option) => {
    let options = capitalizeFirstLetters(option.name)
    if (option?.duration) {
      options += ' - Duración: ' + option.duration
    }
    if (option?.price) {
      options += ' - Precio: $' + option.price
    }
    return options
  }

  const widthServiceInput = () => {
    if (machineSelected && !props.showAllForm) return 12
    if (machineSelected && props.showAllForm && !showExtraServiceInput) return 6
    if (machineSelected && props.showAllForm && showExtraServiceInput) return 4
    if (!machineSelected && showExtraServiceInput) return 6
    if (!machineSelected && !showExtraServiceInput) return 12
  }

  const widthExtraServiceInput = () => {
    if (machineSelected) return 4
    return 6
  }

  const insurancePriceLabel = (option) => {
    if (option) return `codigo:${option.code} descripción:${option.description}`
    return ''
  }

  const checkIsMachine = () => {
    if (machineSelected && props.showAllForm) return true
  }

  const showPatientInsurances = () => {
    const patientInsurances = props?.patient[0]?.insurances
    if (patientInsurances) {
      const auxInsurances = getUniqueListBy(patientInsurances, '_id')
      return auxInsurances?.map((e) => e.name)?.join(', ')
    }
    return patientInsurances
  }

  return (
    <div>
      {props.showAllForm && (
        <div className={s.divider}>
          <DividerSection label="Datos de la cita" />

          {showPatientInsurances() ? (
            <Grid container className={s.gridContainerInsurance}>
              <Grid item className={s.insurancePatient}>{`El ${t(
                `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`,
              )} ya tiene ${t('insuranceLabel.paLoaded')}: ${showPatientInsurances()}`}</Grid>
            </Grid>
          ) : null}
        </div>
      )}

      <Grid container>
        {machineSelected && (
          <Grid item xs={12} sm={widthServiceInput()} className={checkIsMachine() ? s.gridMargin : s.gridDoctorCentre}>
            <Controller
              name="doctorCentre"
              control={props.control}
              defaultValue={getWorkTimeAvailable()?.length ? getWorkTimeAvailable()[0]?._id : '-'}
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel>Profesional</InputLabel>
                  <Select
                    {...field}
                    label="Tipo de servicio"
                    className={props.showAllForm ? s.inputWidth90 : s.fullWidth}
                  >
                    <MenuItem value={'-'}>-</MenuItem>
                    {getWorkTimeAvailable()?.length &&
                      getWorkTimeAvailable()?.map((option) => (
                        <MenuItem value={option._id} key={option._id}>
                          {`${capitalizeFirstLetters(option?.lastname)} ${capitalizeFirstLetters(option?.firstname)}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={widthServiceInput()} className={checkIsMachine() ? s.gridMargin : ''}>
          <Controller
            name="service"
            control={props.control}
            defaultValue={showDefaultValuesOfServices()}
            render={({ field }) => (
              <Autocomplete
                {...field}
                id="serviceAutoComplete"
                clearOnEscape
                className={props.showAllForm ? s.inputWidth98 : s.fullWidth}
                options={machineSelected ? getMachineAvailableServices() : getDoctorCentreAvailableServices()}
                getOptionLabel={(option) => getOptionLabelServices(option) || ''}
                renderOption={(props, option, { selected }) => renderOptionServices(props, option, selected)}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                fullWidth
                renderInput={(params) => <TextField {...params} label="Seleccione un servicio" variant="standard" />}
                onChange={(event, selectedValues) => selectedValues !== null && onChangeService(selectedValues)}
              />
            )}
          />
        </Grid>

        {showExtraServiceInput && (
          <Grid item xs={12} sm={widthExtraServiceInput()} className={machineSelected ? s.gridMargin : ''}>
            <Controller
              name="extraServices"
              control={props.control}
              defaultValue={[]}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple={true}
                  disableCloseOnSelect
                  className={s.inputWith98}
                  options={machineSelected ? getMachineExtraServices() : getDoctorCentreExtraServices()}
                  getOptionLabel={(option) => capitalizeFirstLetters(option.name) || ''}
                  renderOption={(props, option, { selected }) => renderOptionServices(props, option, selected)}
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Servicios extra" variant="standard" />}
                  onChange={(event, selectedValues) => onChangeExtraService(selectedValues)}
                />
              )}
            />
          </Grid>
        )}
        {props.showAllForm && (
          <>
            <Grid item xs={12} sm={!showBonoAndPracticeInput ? 12 : 6} className={s.marginTextFields}>
              <AutoCompleteChedoc
                name="insurance"
                control={props.control}
                setValue={props.setValue}
                stateRedux={uniqueInsuranceList}
                functionToDispatch={getListInsurances}
                inputLabel={`${t('insuranceLabel.S')}`}
                className={s.inputWidth98}
              />
            </Grid>

            {showBonoAndPracticeInput && (
              <Grid item xs={12} sm={6} className={s.marginTextFields}>
                <Controller
                  name="insurancePrice"
                  control={props.control}
                  defaultValue=""
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="insuranceAutoComplete"
                      clearOnEscape
                      className={s.inputWidth98}
                      options={selectedInsurancePrice}
                      getOptionLabel={(option) => insurancePriceLabel(option)}
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li {...props} key={option?._id}>
                            {`Código: ${option.code} - Descripción: ${
                              option.description
                            } - Precio: ${handleSetMoneySign(country)}${option.price}`}
                          </li>
                        )
                      }}
                      isOptionEqualToValue={(option, value) => option._id === value._id}
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Bono/práctica" variant="standard" />}
                      onChange={(event, selectedValues) => onChangeInsurancePrice(selectedValues)}
                    />
                  )}
                />
              </Grid>
            )}

            {props?.inputsInsurances ? (
              <>
                <Grid container className={s.marginTextFields}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="insurancePlan"
                      control={props.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="text"
                          label="Información adicional plan"
                          variant="standard"
                          className={s.inputWidth90}
                          multiline
                        />
                      )}
                      defaultValue=""
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="policyNumber"
                      control={props.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="text"
                          label="Número de afiliado"
                          variant="standard"
                          className={s.inputWidth90}
                          onKeyDown={handleKeyDown}
                        />
                      )}
                      defaultValue=""
                    />
                  </Grid>
                </Grid>

                <Grid container className={s.marginTextFields}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="insuranceAuthorization"
                      control={props.control}
                      render={({ field }) => (
                        <>
                          <FormControlLabel
                            control={<Checkbox {...field} defaultChecked={field.value} />}
                            label={<p className={s.labelCheckbox}>{`Bono autorizado:`}</p>}
                          />
                          <>
                            {field.value === true ? (
                              <span className={s.spanCheckboxYes}>
                                <i>
                                  <AiFillCheckCircle /> Sí
                                </i>
                              </span>
                            ) : (
                              <span className={s.spanCheckboxNo}>
                                <i>
                                  <AiFillCloseCircle /> No
                                </i>
                              </span>
                            )}
                          </>
                        </>
                      )}
                      defaultValue={true}
                    />
                  </Grid>
                </Grid>
              </>
            ) : null}

            <Grid item xs={12} className={s.marginTextFields}>
              <Controller
                name="comment"
                control={props.control}
                render={({ field }) => (
                  <TextField {...field} multiline type="text" label="Comentarios" variant="standard" fullWidth />
                )}
                defaultValue=""
              />
            </Grid>
          </>
        )}
      </Grid>
      <br />

      {props.showAllForm && (
        <Grid container>
          <Grid item xs={12} className={s.gridRepeatTurn}>
            <Controller
              name="repeat"
              control={props.control}
              render={({ field }) => (
                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={props.checked} onChange={handleChange} />
              )}
              defaultValue=""
            />{' '}
            ¿Repetir en las próximas semanas?
          </Grid>
          <br />
          {props.checked && (
            <Grid container className={s.checkSlider}>
              <Grid item xs={12} className={s.warningText}>
                Recordá comprobar la disponibilidad en las próximas semanas, ya que al repetir está cita podrían
                generarse sobreturnos.
              </Grid>
              <Grid item xs={12} md={2}>{`${props.weeksSelected} semanas`}</Grid>
              <Grid item xs={12} md={10}>
                <Box width={'100%'} className={s.divFlex}>
                  <Slider
                    size="small"
                    defaultValue={0}
                    max={52}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    onChange={(event) => props.setWeeksSelected(event.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  )
}

export default TurnDataForm
