import {
  getMercadoPagoPaymentLink,
  getMercadoPagoSubscription,
  postMercadoPagoPaymentLink,
  postMercadoPagoSubscription,
  // getMercadoPagoSubscriptionPayments,
  putMercadoPagoSubscription,
} from '@/services/mercadoPago'
import { putAturn } from '@/services/turns'
import { CENTRE_CONFIGURATION_UPDATED } from '@/store/centreConfigurations'
import { CENTRE_UPDATED } from '@/store/centres'
import { SELECTED_TURN_UPDATED } from '@/store/turns'
import { showToast } from '@/utils/toast'

const fetchMercadoPagoSubscription = (params) => async (dispatch) => {
  try {
    const subscriptionData = await getMercadoPagoSubscription(params)
    return subscriptionData
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const fetchMercadoPagoPaymentLink = (params) => async () => {
  try {
    const dataStatusPaymentLink = await getMercadoPagoPaymentLink(params)
    return dataStatusPaymentLink
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

// const fetchMercadoPagoSubscriptionPayments = (params) => async (dispatch) => {
//   try {
//     const subscriptionPayments = await getMercadoPagoSubscriptionPayments(params)
//     return subscriptionPayments.results
//   } catch (error) {
//     const { message } = error
//     console.log(message)
//     showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
//   }
// }

const createMercadoPagoSubscription = (centreData) => async (dispatch) => {
  try {
    const auxCentreData = await postMercadoPagoSubscription(centreData)
    dispatch(CENTRE_CONFIGURATION_UPDATED(auxCentreData?.centreConfiguration))
    dispatch(CENTRE_UPDATED(auxCentreData))
    return auxCentreData
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Error el crear suscripción', 'error', 5000)
  }
}

const createMercadoPagoPaymentLink = (data) => async (dispatch, getState) => {
  const selectedTurn = getState().turns.selectedTurn

  try {
    const turnPaymentLink = await postMercadoPagoPaymentLink(data)
    const updateSelectedTurn = {
      ...selectedTurn,
      paymentLink: turnPaymentLink,
      paymentLinkAmount: data?.amount,
    }

    if (data.saveTurn) {
      await putAturn(updateSelectedTurn)
    }
    dispatch(SELECTED_TURN_UPDATED(updateSelectedTurn))
    return turnPaymentLink
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Error el crear link de pagos', 'error', 5000)
  }
}

const updateMercadoPagoSubscription = (data) => async (dispatch) => {
  try {
    const auxSubscription = await putMercadoPagoSubscription(data)
    return auxSubscription
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Error al actualizar suscripción', 'error', 5000)
  }
}

export {
  createMercadoPagoPaymentLink,
  createMercadoPagoSubscription,
  fetchMercadoPagoPaymentLink,
  fetchMercadoPagoSubscription,
  // fetchMercadoPagoSubscriptionPayments,
  updateMercadoPagoSubscription,
}
