import React from 'react'
import TitlePopUp from '@/components/TitlePopUp'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'

import ExportSubscriptionPaymentsContent from './ExportSubscriptionPaymentsContent'

const PopUpExportSubscriptionPayments = ({ openPopUp, setOpenPopUp, setShowExportPanel }) => {
  const handleClosePopUp = () => {
    setOpenPopUp(false)
    setShowExportPanel(false)
  }

  return (
    <div>
      <Dialog
        disableEscapeKeyDown={true}
        maxWidth="md"
        fullWidth
        open={openPopUp}
        onClose={handleClosePopUp}
        scroll="paper"
      >
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle={'Exportar pagos de suscripción'} handleClose={handleClosePopUp} />
        </DialogTitle>
        <DialogContent style={{ padding: '0px' }}>
          <ExportSubscriptionPaymentsContent handleClosePopUp={handleClosePopUp} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpExportSubscriptionPayments
