import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import DividerSection from '@/components/DividerSection'
import CaluButton from '@/components/styledComponents/CaluButton'
import { TextOverButtons } from '@/components/TextOverButtons'
import TitlePopUp from '@/components/TitlePopUp'
import { paymentMethods } from '@/helpers/constants'
import { updatePatientDebt } from '@/store/turns/actions'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { showToast } from '@/utils/toast'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { DatePicker } from '@mui/x-date-pickers'

import dayjs from 'dayjs'

import s from './styles.module.scss'

export default function PopUpPayment({ open, onClose, selectedTurn }) {
  const dispatch = useDispatch()
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()
  const [paymentDate, setPaymentDate] = useState(dayjs())
  const [paymentAmount, setPaymentAmount] = useState()
  const [paymentHistory, setPaymentHistory] = useState(selectedTurn?.paymentHistory || [])
  const totalAmount = selectedTurn?.amount
  const [totalPayments, setTotalPayments] = useState(0)
  const [totalDebt, setTotalDebt] = useState(selectedTurn?.debt || 0)

  const { control, getValues } = useForm()

  useEffect(() => {
    let totalPayments = paymentHistory?.map((e) => e.amount).reduce((a, p) => a + p, 0)
    setTotalPayments(totalPayments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePaymentsTurnDebt = async () => {
    let auxTurn = {
      ...selectedTurn,
    }
    auxTurn.paymentHistory = paymentHistory
    await dispatch(updatePatientDebt(auxTurn))
    onClose()
  }

  const savePayment = () => {
    const paymentMethod = getValues('method')
    const formatedDate = dayjs(paymentDate).format()
    let payment = []

    if (!paymentAmount) {
      showToast('debe ingresar monto pagado ', 'warning', 2000)
      return
    } else {
      payment.push({
        amount: parseInt(paymentAmount),
        method: paymentMethod,
        date: toCustomTz(formatedDate, 'utc', true),
      })
      paymentHistory.forEach((payment) => (payment.showConfirm ? delete payment.showConfirm : null))
      setPaymentHistory(paymentHistory.concat(payment))
      setTotalPayments((prev) => prev + parseInt(paymentAmount))
      setTotalDebt((prev) => prev - parseInt(paymentAmount))
      setPaymentAmount('')
    }
  }

  const addPropWhenClick = (i) => {
    let auxData = [...paymentHistory]
    auxData[i].showConfirm = true
    setPaymentHistory(auxData)
  }

  const deleteItemPaymentHistory = (itemToDelete) => {
    let paymentItem = paymentHistory.filter((index) => index !== itemToDelete)
    setPaymentHistory(paymentItem)
    setTotalPayments((prev) => prev - itemToDelete.amount)
    setTotalDebt((prev) => prev + itemToDelete.amount)
  }

  const removePropWhenClick = (i) => {
    let auxData = [...paymentHistory]
    delete auxData[i].showConfirm
    setPaymentHistory(auxData)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={open} onClose={onClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle={'Pagos'} handleClose={onClose} />
        </DialogTitle>
        <DialogContent className={s.dialogTitle}>
          <Grid container>
            <div className={s.paymentDetails}>
              <div className={s.amounts}>
                Monto a pagar:{' '}
                <b>
                  {handleSetMoneySign(country)}
                  {totalAmount}
                </b>
              </div>
              <div className={s.amounts}>
                Monto pagado:{' '}
                <b>
                  {handleSetMoneySign(country)}
                  {totalPayments}
                </b>{' '}
              </div>
              <div className={s.amounts}>
                Deuda:{' '}
                <b>
                  {handleSetMoneySign(country)}
                  {totalDebt}
                </b>{' '}
              </div>
            </div>
          </Grid>
          <DividerSection label="Agregar pago" />
          <Grid container className={s.paymentTurnContainer}>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth variant="standard" className={s.inputWidth90}>
                <DatePicker
                  label="Fecha *"
                  value={paymentDate}
                  format="DD/MM/YYYY"
                  onChange={(newDate) => {
                    setPaymentDate(newDate)
                  }}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      fullWidth: true,
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                type="number"
                label={`${handleSetMoneySign(country)} Monto pagado`}
                variant="standard"
                className={s.inputWidth90}
                onChange={(e) => setPaymentAmount(e.target.value)}
                value={paymentAmount}
                onWheel={(e) => e.target.blur()}
                onKeyDown={handleKeyDown}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controller
                name="method"
                control={control}
                defaultValue={'ARScash'}
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth className={s.inputWidth90}>
                    <InputLabel>Medio de pago</InputLabel>
                    <Select {...field}>
                      {paymentMethods?.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <CaluButton size="small" inverted="true" onClick={savePayment} className={s.addPaymentButton}>
                Agregar
              </CaluButton>
            </Grid>
          </Grid>
          <Grid item xs={12} className={s.payments}>
            {paymentHistory.length > 0 ? <b>Pagos realizados:</b> : null}
            <ul className={s.paymentsUl}>
              {paymentHistory?.map((payment, i) => (
                <li key={i} className={s.paymentsList}>
                  <b>{dayjs(payment.date).format('DD/MM/YYYY') + '\u00A0'}</b>
                  <span>{`${handleSetMoneySign(country)} ${payment.amount}\u00A0`}</span>
                  <span>{`(${convertValueToName(paymentMethods, payment.method)})`}</span>

                  {!payment.showConfirm ? (
                    <TextOverButtons title=" eliminar" placement="right">
                      <IconButton onClick={() => addPropWhenClick(i)}>
                        <DeleteOutlineIcon className={s.deleteIcon} />
                      </IconButton>
                    </TextOverButtons>
                  ) : (
                    <Grid className={s.containerButtonsDelete}>
                      <TextOverButtons title="Si, eliminar" position="top">
                        <Grid className={s.btnsDeleteIcons}>
                          <IoIosCheckmarkCircleOutline onClick={() => deleteItemPaymentHistory(payment)} />
                        </Grid>
                      </TextOverButtons>
                      <TextOverButtons title="No, cancelar">
                        <Grid className={s.btnsCancelIcons}>
                          <IoIosCloseCircleOutline onClick={() => removePropWhenClick(i)} />
                        </Grid>
                      </TextOverButtons>
                    </Grid>
                  )}
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} spacing={2} className={s.containerButton}>
            <CaluButton size="medium" onClick={handlePaymentsTurnDebt} color="primary">
              Guardar
            </CaluButton>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}
