import { addNewFieldTable, deleteFieldTable, putFieldTable } from '@/services/fieldTables'
import {
  FETCH_FIELD_TABLES,
  FIELD_TABLE_CREATED,
  FIELD_TABLE_DELETE,
  FIELD_TABLE_SELECTED,
  FIELD_TABLE_UPDATED,
  RESET_FIELD_TABLE_SELECTED,
} from '@/store/fieldTables'
import { showToast } from '@/utils/toast'

const getAllFieldTables = (fetchAllFieldTables) => async (dispatch) => {
  dispatch(FETCH_FIELD_TABLES(fetchAllFieldTables))
}

const postNewFieldTable = (data) => async (dispatch) => {
  const auxFields = data.fields
  try {
    let newFieldTable = await addNewFieldTable(data)
    newFieldTable = {
      ...newFieldTable,
      fields: auxFields,
    }

    dispatch(FIELD_TABLE_CREATED(newFieldTable))
    dispatch(FIELD_TABLE_SELECTED(newFieldTable))
    if (newFieldTable) {
      showToast('Tabla guardada correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const deleteFieldTableAdded = (id) => async (dispatch) => {
  try {
    await deleteFieldTable(id)
    dispatch(FIELD_TABLE_DELETE(id))
    showToast('Tabla eliminada correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateFieldTable = (data, hideAlert) => async (dispatch) => {
  const auxFields = data.fields
  try {
    let fieldTableUpdated = await putFieldTable(data)
    fieldTableUpdated = {
      ...fieldTableUpdated,
      fields: auxFields,
    }

    dispatch(FIELD_TABLE_UPDATED(fieldTableUpdated))

    if (fieldTableUpdated && !hideAlert) {
      showToast('Tabla actualizada correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedFieldTable = (id) => async (dispatch, getState) => {
  // const state = getState()
  // const allFieldTables = state.fieldTables.allFieldTables
  // const selectedSingleTable = allFieldTables.map((fieldTable) => {
  //   if (fieldTable._id === id) {
  //     try {
  //       dispatch(FIELD_TABLE_SELECTED(fieldTable))
  //     } catch (error) {
  //       const { message } = error
  //       console.log(message)
  //     }
  //   }
  // })
}

const resetSelectedFieldTable = () => async (dispatch) => {
  try {
    dispatch(RESET_FIELD_TABLE_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export {
  deleteFieldTableAdded,
  getAllFieldTables,
  postNewFieldTable,
  resetSelectedFieldTable,
  selectedFieldTable,
  updateFieldTable,
}
