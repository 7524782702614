import React from 'react'
import { useDispatch } from 'react-redux'
import logo from '@/assets/images/appointment.png'
import { selectedCentreTreatment } from '@/store/treatments/actions'
import { sortArrayAlphabetically } from '@/utils/functions/sortArrayAlphabetically'

import s from './styles.module.scss'

const ContentSideBar = ({ listItem }) => {
  const dispatch = useDispatch()
  const items = [...listItem]
  sortArrayAlphabetically(items)

  return (
    <div>
      {items.length ? (
        items?.map((item) => (
          <div
            key={item._id}
            className={s.treatments}
            onClick={async () => await dispatch(selectedCentreTreatment(item._id))}
          >
            <img src={logo} alt="ficha" className={s.treatmentLogo} />

            <div className={s.containerInfoTreatment}>
              <p className={s.titleTreatment}>{item.name.toUpperCase()}</p>

              <p className={s.infoExtra}>{`$${item.price || 0} -- ${item.turnsCount || 0} sesiones`}</p>
            </div>
          </div>
        ))
      ) : (
        <p className={s.emptyList}>Aún no tienes plantillas creadas</p>
      )}
    </div>
  )
}

export default ContentSideBar
