import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchTemplatesHC = async (params) => {
  const route = `api/clinicHistoryTemplates`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewTemplateHC = async (data) => {
  const route = `api/clinicHistoryTemplates`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteTemplateHC = async (id) => {
  const route = `api/clinicHistoryTemplates/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putTemplateHC = async (data) => {
  const route = `api/clinicHistoryTemplates`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchSingleTemplateHC = async (id) => {
  const route = `api/clinicHistoryTemplates/${id}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { addNewTemplateHC, deleteTemplateHC, fetchSingleTemplateHC, fetchTemplatesHC, putTemplateHC }
