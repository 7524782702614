import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DividerSection from '@/components/DividerSection'
import { useUser } from '@/context/UserProvider'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Button, Menu, MenuItem } from '@mui/material'

import s from '../../styles.module.scss'

export default function AdminSimulateCentreOptions() {
  const { defaultCentre } = useUser()
  const { t } = useTranslation('global')

  const [showAdminPanel, setShowAdminPanel] = useState(false)
  const [currentTarget, setCurrentTarget] = useState(document.firstElementChild)

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const selectedEntity = localStorage?.getItem('selectedEntity')
  const entity = selectedEntity ? JSON?.parse(selectedEntity) : undefined

  return (
    <>
      {!entity?._id ? null : centreDetail?._id === entity?._id ? (
        <>
          <Button
            className={s.navButton}
            onClick={(e) => {
              setShowAdminPanel(true)
              setCurrentTarget(e.currentTarget)
            }}
          >
            {centreDetail?.name?.toUpperCase()}
            <KeyboardArrowDownIcon />
          </Button>

          <Menu
            id="menu-appbar"
            anchorEl={currentTarget}
            open={showAdminPanel}
            onClose={() => setShowAdminPanel(false)}
          >
            <Link to={`/agenda/centro/${defaultCentre}`} className={s.link}>
              <MenuItem className={s.hoverText}>Agenda</MenuItem>
            </Link>
            <Link to={`/pacientes/centro/${defaultCentre}`} className={s.link}>
              <MenuItem className={s.hoverText}>
                {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.Patients`)}
              </MenuItem>
            </Link>
            <Link to={`/historias-clinicas/${defaultCentre}`} className={s.link}>
              <MenuItem className={s.hoverText}>Historias clínicas</MenuItem>
            </Link>
            <Link to={`/caja/${defaultCentre}`} className={s.link}>
              <MenuItem className={s.hoverText}>Gestión</MenuItem>
            </Link>
            <Link to={`/centro/${defaultCentre}`} className={s.link}>
              <MenuItem className={s.hoverText}>Turnos online</MenuItem>
            </Link>
            {centreDetail?.centreConfiguration?.hasWappModule && (
              <Link to={`/recordatorios`} className={s.link}>
                <MenuItem className={s.hoverText}>Recordatorios</MenuItem>
              </Link>
            )}
            <DividerSection className={s.divider} />

            <Link to={`/agendas/centro/${defaultCentre}`} className={s.link}>
              <MenuItem className={s.hoverText}>Editar agendas</MenuItem>
            </Link>

            <Link to={`/administracion/centro/${defaultCentre}`} className={s.link}>
              <MenuItem className={s.hoverText}>Usuarios</MenuItem>
            </Link>

            <Link to={`/servicios/centro/${defaultCentre}`} className={s.link}>
              <MenuItem className={s.hoverText}>Servicios</MenuItem>
            </Link>
            <Link to={`/precios/centro/${defaultCentre}`} className={s.link}>
              <MenuItem className={s.hoverText}>Precio de planes</MenuItem>
            </Link>
            <Link to={`/tratamientos/centro/${defaultCentre}`} className={s.link}>
              <MenuItem className={s.hoverText}>Sesiones</MenuItem>
            </Link>
            <Link to="/perfil" className={s.link}>
              <MenuItem className={s.hoverText}>Configuración general</MenuItem>
            </Link>

            <DividerSection size="small" />

            <Link to={`/centro/${defaultCentre}`} className={s.link}>
              <MenuItem className={s.hoverText}>Central de citas</MenuItem>
            </Link>
          </Menu>
        </>
      ) : null}
    </>
  )
}
