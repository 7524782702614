import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { updatecentreConfiguration } from '@/store/centreConfigurations/actions'
import { updateCentre } from '@/store/centres/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from '../tabInsurances/styles.module.scss'

const TabOnlineTurns = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const centreConfiguration = useSelector((state) => state.centreConfigurations.centreConfigurationDetail)
  const auxCentreDetail = {
    ...centreDetail,
    centreConfiguration: centreConfiguration,
  }

  const handleSubmit = async (data) => {
    setIsLoading(true)
    const auxCentreConfiguration = data.centreConfiguration
    if (data?.slug === '') {
      delete data?.slug
    }

    try {
      if (centreDetail) {
        await dispatch(updateCentre(data))
        await dispatch(updatecentreConfiguration(auxCentreConfiguration))
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ContainerForm
      className={s.containerForm}
      onSubmit={(data) => handleSubmit(data)}
      defaultValues={{
        ...formDefaultValues,
        ...auxCentreDetail,
        onlineTurnsLimit: auxCentreDetail.onlineTurnsLimit !== 0 ? auxCentreDetail.onlineTurnsLimit : 60,
      }}
      resolver={yupResolver(formValidationSchema)}
    >
      <FormContent isLoading={isLoading} />
    </ContainerForm>
  )
}

export default TabOnlineTurns
