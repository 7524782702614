import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { AiFillCloseCircle } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import ClinicHistory from '@/components/ClinicHistory'
import TitlePopUp from '@/components/TitlePopUp'
import TurnsHistory from '@/components/TurnsHistory'
import { useUser } from '@/context/UserProvider'
import useValidateURLId from '@/hooks/useValidateURLId'
import { resetSelectedPatientCentre } from '@/store/patientCentres/actions'
import { getAllCentreTreatments, getAllPatientTreatments } from '@/store/treatments/actions'
import { resetDataToCreateTurnTreatment } from '@/store/turns/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { directionOfAnimation } from '@/utils/functions/directionOfAnimation'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { findEntityInfo } from '@/utils/functions/handleFindEntityInfo'
import { handleHasHCAccess } from '@/utils/functions/hasHCAccess'
import { hasValidDni } from '@/utils/functions/hasValidDni'
import { Box, DialogContent, Tab, Tabs, tabsClasses } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import PropTypes from 'prop-types'
import FormCreatePatient from '../formCreatePatient'
import TreatmentSection from '../treatmentSection'

import s from './styles.module.scss'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const MainPopUp = ({ open, setOpen, patientSelected, patientCentresTurns }) => {
  const { t } = useTranslation('global')
  const dispatch = useDispatch()
  const centreId = useValidateURLId()
  const { user } = useUser()

  const [valuePanel, setValuePanel] = useState(0)
  const [prevValuePanel, setPrevValuePanel] = useState(0)
  const [loadingTreatmentSection, setLoadingTreatmentSection] = useState(false)

  const centreConfiguration = useSelector((state) => state.centreConfigurations.centreConfigurationDetail)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isCentre, isDoctor, isSecretary, entityInLocalStorage } = usersProfileConnected

  const optionalDni = centreConfiguration?.enableOptionalDniOnPatients
  const scroll = 'paper'

  useEffect(() => {
    if (!entityInLocalStorage) return

    let entityFound = findEntityInfo(user, entityInLocalStorage?._id, entityInLocalStorage.role)

    if (isCentre) {
      // setHcAccess(true)
      return
    }

    if (isDoctor && entityFound?.centre === centreId) {
      // setHcAccess(true)
    }

    if (isSecretary && entityFound?.hcAccess) {
      // setHcAccess(true)
    }
  }, [centreId, entityInLocalStorage, user]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePanel = (event, newValue) => {
    setPrevValuePanel(valuePanel)
    setValuePanel(newValue)
  }

  const handleClosePopUp = () => {
    setOpen(!open)
    setValuePanel(0)
    dispatch(resetSelectedPatientCentre())
  }

  const showTreatmentsPatient = async () => {
    setLoadingTreatmentSection(true)
    await dispatch(getAllPatientTreatments(centreId, patientSelected?._id))
    await dispatch(getAllCentreTreatments(centreId))
    await dispatch(resetDataToCreateTurnTreatment())
    setLoadingTreatmentSection(false)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={open} onClose={handleClosePopUp}>
        <TitlePopUp
          textTitle={
            patientSelected
              ? `${t(
                  `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.Patient`,
                )}: ${capitalizeFirstLetters(patientSelected?.lastname)}, ${capitalizeFirstLetters(
                  patientSelected?.firstname,
                )}`
              : `Crear nuevo ${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}`
          }
          handleClose={handleClosePopUp}
        />
        <DialogContent dividers={scroll === 'paper'} style={{ margin: '0px', padding: '0px' }}>
          <>
            <div>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={valuePanel}
                    onChange={handleChangePanel}
                    aria-label={`${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.Patients`)}`}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                      '& .MuiTab-root.Mui-selected': {
                        color: '#009688',
                      },
                      [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                      },
                    }}
                  >
                    <Tab label="Datos personales" />
                    {patientSelected && <Tab label={`Historial de ${t('turnLabel.p')}`} />}
                    {patientSelected && (
                      <Tab disabled={hasValidDni(optionalDni, patientSelected?.dni)} label="Grupo de sesiones" />
                    )}
                    {patientSelected && handleHasHCAccess(usersProfileConnected) && (
                      <Tab disabled={hasValidDni(optionalDni, patientSelected?.dni)} label="Historia clínica" />
                    )}
                  </Tabs>
                </Box>
              </Box>

              <TabPanel value={valuePanel} index={0}>
                <FormCreatePatient onClose={handleClosePopUp} formValues={patientSelected} />
              </TabPanel>
              <TabPanel value={valuePanel} index={1}>
                <TurnsHistory
                  patientCentresTurns={patientCentresTurns}
                  directionOfAnimation={directionOfAnimation(valuePanel, prevValuePanel)}
                  patientCentre={patientSelected}
                />
              </TabPanel>
              <TabPanel value={valuePanel} index={2}>
                <TreatmentSection
                  patientSelected={patientSelected}
                  loading={loadingTreatmentSection}
                  requestPatientData={showTreatmentsPatient}
                />
              </TabPanel>
              <TabPanel value={valuePanel} index={3}>
                <ClinicHistory isNewTurn={false} doctorCentreId={''} />
              </TabPanel>
            </div>
            <div className={s.footer} style={{ width: '100%' }}>
              {/* <small className={s.small}>
                <i>
                  <AiFillCloseCircle />
                </i>{' '}
                No ha sacado {t('turnLabel.s')} online
              </small> */}

              {hasValidDni(optionalDni, patientSelected?.dni) && (
                <p className={s.clinicHistoryMessage}>
                  Las <b>Historias Clínicas y Sesiones</b> se habilitarán cuando se registre el DNI del{' '}
                  {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}.
                </p>
              )}
            </div>
          </>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default MainPopUp
