import {
  deleteUser,
  fetchAllUsers,
  fetchUserMe,
  getVerificationToken,
  postUser,
  postVerificationToken,
  putUser,
  putUserPassword,
  putUserPasswordById,
} from '@/services/users'
import {
  DELETE_USER,
  ERROR_RESET_USER_PASSWORD,
  GET_ALL_USERS,
  LOADED_USERS,
  LOADED_USERS_PROFILE_CONNECTED,
  UPDATED_USER,
  VERIFICATION_DATA,
  VERIFICATION_STATUS,
} from '@/store/users'

import { showToast } from '../../utils/toast/index'

const createUserNoLogin = (data, token, role) => async (dispatch) => {
  const userData = {
    ...data,
  }

  try {
    await postUser(userData)
    const response = await postVerificationToken(token, role)

    if (response) {
      showToast('Usuario verificado', 'success')
      dispatch(VERIFICATION_STATUS(true))
    }
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Error', 'error')
    dispatch(VERIFICATION_STATUS(false))
  }
}

const createNewUser = (user) => async (dispatch) => {
  try {
    const response = await postUser(user)

    return response
  } catch (e) {
    showToast('El email ingresado ya está en uso.', 'error')
  }
}

const getUserMe = () => async (dispatch) => {
  try {
    const response = await fetchUserMe()
    dispatch(VERIFICATION_STATUS(true))
    return response
  } catch (e) {
    if (e) {
      showToast('Ups! algo salio mal, intenta nuevamente', 'error')
    }
  }
}

const getVerificationData = (token, role) => async (dispatch) => {
  try {
    const response = await getVerificationToken(token, role)
    dispatch(VERIFICATION_DATA(response))
    return response
  } catch (e) {
    if (e) {
      showToast('Ups! algo salio mal, intenta nuevamente', 'error')
    }
  }
}

const onlyVerificationToken = (token, role) => async (dispatch) => {
  try {
    const response = await postVerificationToken(token, role)

    if (response) {
      showToast('Usuario verificado', 'success')
      dispatch(VERIFICATION_STATUS(true))
    }
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Error', 'error')
    dispatch(VERIFICATION_STATUS(false))
  }
}

const resetUserPassword = (emailData) => async (dispatch) => {
  try {
    let response = await putUserPassword(emailData)
    if (response) {
      showToast(response, 'success', 5000)
      dispatch(ERROR_RESET_USER_PASSWORD(false))
    }
  } catch (e) {
    showToast('No existe cuenta asociada al email ingresado', 'error', 5000)
    dispatch(ERROR_RESET_USER_PASSWORD(true))
  }
}

const changeUserPassword = (data, userId) => async () => {
  try {
    let response = await putUserPasswordById(data, userId)
    showToast('Contraseña modificada correctamente!', 'success', 2000)
    return response
  } catch (e) {
    showToast('Contraseña actual incorrecta', 'error', 2000)
  }
}

const getAllUsers = (params) => async (dispatch) => {
  try {
    const { docs: allUsers, ...paginationData } = await fetchAllUsers(params)
    dispatch(GET_ALL_USERS({ allUsers, paginationData }))
  } catch (e) {
    console.log(e)
  }
}

const updateUser = (data, userId) => async (dispatch) => {
  const auxCentres = data.centres
  const auxDoctorCentres = data.doctorCentres
  const auxsecretaryCentres = data.secretaryCentres
  const auxPatient = data.patient

  try {
    const updatedUser = await putUser(data, userId)
    if (auxCentres) updatedUser.centres = auxCentres
    if (auxDoctorCentres) updatedUser.doctorCentres = auxDoctorCentres
    if (auxsecretaryCentres) updatedUser.secretaryCentres = auxsecretaryCentres
    if (auxPatient) updatedUser.patient = auxPatient
    dispatch(UPDATED_USER(updatedUser))
    showToast('Usuario actualizado correctamente', 'success')
    return updatedUser
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const deleteUserAdded = (userId) => async (dispatch) => {
  try {
    await deleteUser(userId)
    dispatch(DELETE_USER(userId))
    showToast('Usuario Eliminado correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Error al eliminar usuario', 'error')
  }
}

const isLoadedUsers = (data) => async (dispatch) => {
  try {
    dispatch(LOADED_USERS(data))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const handlePaginationChangeUsers = (newPage, newLimit, searchText, roleSelected) => (dispatch, getState) => {
  const { centreProfile } = getState().users
  const params = {
    centre: centreProfile?._id,
    page: newPage,
    limit: newLimit,
    search: !searchText ? null : searchText,
    role: roleSelected === '-' ? null : roleSelected,
  }

  dispatch(getAllUsers(params))
}

const handleSetUsersProfileConnected = (usersProfile) => (dispatch) => {
  dispatch(LOADED_USERS_PROFILE_CONNECTED(usersProfile))
}

export {
  changeUserPassword,
  createNewUser,
  createUserNoLogin,
  deleteUserAdded,
  getAllUsers,
  getUserMe,
  getVerificationData,
  handlePaginationChangeUsers,
  handleSetUsersProfileConnected,
  isLoadedUsers,
  onlyVerificationToken,
  resetUserPassword,
  updateUser,
}
