import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'
import { reducePayload } from './reducePayload'

const fetchAllPatientsCentre = async (centreId, page, limit) => {
  const route = `api/patientCentres?centre=${centreId}&page=${page || 1}&limit=${limit || 30}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchPatientsCentreByInputSearch = async (params) => {
  const route = `api/patientCentres`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewPatientCentre = async (data) => {
  const route = `api/patientCentres`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchOrAddNewPatientCentre = async (patientCentreData) => {
  const route = `api/patientCentres`
  return API.get(route, { params: { centre: patientCentreData.centre, dni: patientCentreData.dni } })
    .then((response) => {
      const patientCentre = response.data.docs[0]
      if (patientCentre) {
        return Promise.resolve(patientCentre)
      }

      return API.post(route, patientCentreData)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
    })
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deletePatientCentres = async (id) => {
  const route = `api/patientCentres/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putPatientCentres = async (data) => {
  const auxData = reducePayload(data)

  const route = `api/patientCentres`
  return API.put(route, auxData)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchSinglePatient = async (id) => {
  const route = `api/patientCentres/${id}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchPatientCentreByDni = async (params) => {
  const route = `api/patientCentres`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export {
  addNewPatientCentre,
  deletePatientCentres,
  fetchAllPatientsCentre,
  fetchOrAddNewPatientCentre,
  fetchPatientCentreByDni,
  fetchPatientsCentreByInputSearch,
  fetchSinglePatient,
  putPatientCentres,
}
