import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import ContentPopUp from './contentPopUp'

const BackupPatients = ({ open, handleClose }) => {
  const { t } = useTranslation('global')

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" open={open} fullWidth onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={`Backup de ${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`)}`}
            handleClose={handleClose}
          />
        </DialogTitle>
        <DialogContent className="animate__animated animate__fadeIn" style={{ padding: '0px' }}>
          <ContentPopUp />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default BackupPatients
