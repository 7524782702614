import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import logo from '@/assets/images/centre-landscape.jpg'
import CaluButton from '@/components/styledComponents/CaluButton'
import { TextOverButtons } from '@/components/TextOverButtons'
import useCheckAvailableCreateAgendas from '@/hooks/useCheckAvailableCreateAgendas'
import { TextField } from '@mui/material'

import s from './styles.module.scss'

const BaseSideBar = ({
  inputValue,
  inputOnChange,
  inputLabel,
  buttonLabel,
  buttonOnClick,
  content,
  buttonOnClick2,
  buttonLabel2,
  agendaView = false,
  panelAdmin = false,
}) => {
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const url = window.location.href
  const searchString = '/agendas/centro/'
  const { shouldCreateAgenda } = useCheckAvailableCreateAgendas()

  const checkInclusion = useCallback((url, searchString) => {
    const lowercaseParagraph = url.toLowerCase()
    const lowercaseSearchString = searchString.toLowerCase()

    return lowercaseParagraph.includes(lowercaseSearchString)
  }, [])

  const isStringIncluded = checkInclusion(url, searchString)

  return (
    <div className={s.container}>
      {!agendaView && !panelAdmin && (
        <>
          <div
            className={s.logoHeader}
            style={{
              backgroundImage: `url(${centreDetail?.image || logo})`,
            }}
          >
            <h3 className={s.title}>{centreDetail?.name}</h3>
            <div className={s.gradient}></div>
          </div>
        </>
      )}

      <div className={s.containerButtons}>
        {buttonOnClick && (
          <div className={s.actionsContainer}>
            <TextOverButtons
              title={isStringIncluded && !shouldCreateAgenda ? 'Has llegado al limite de creación de agendas' : null}
            >
              <span>
                <CaluButton color="primary" onClick={buttonOnClick} disabled={isStringIncluded && !shouldCreateAgenda}>
                  {buttonLabel}
                </CaluButton>
              </span>
            </TextOverButtons>
          </div>
        )}

        {buttonOnClick2 && (
          <div className={s.actionsContainer}>
            <CaluButton color="primary" onClick={buttonOnClick2}>
              {buttonLabel2}
            </CaluButton>
          </div>
        )}
      </div>

      {!agendaView && inputOnChange && (
        <div className={s.searchBarContainer}>
          <TextField
            className={s.searchInput}
            id="search"
            size="small"
            autoComplete="off"
            label={inputLabel}
            variant="standard"
            fullWidth={true}
            type="text"
            value={inputValue}
            onChange={inputOnChange}
          />
        </div>
      )}

      {content}
    </div>
  )
}

export default BaseSideBar
