import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { useUser } from '@/context/UserProvider'
import { RESET_ONLINE_TURNS } from '@/store/turns'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { yupResolver } from '@hookform/resolvers/yup'

import dayjs from 'dayjs'
import { checkAvailabilityAndCreateOrUpdateTurn, cleanPatientCentreTurns } from '../../store/turns/actions'
import { showToast } from '../../utils/toast'
import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

const FormLogin = ({ className }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [errorInLogin, setErrorInLogin] = useState(false)
  const { user, defaultCentre } = useUser()
  const { login } = useUser()
  const dispatch = useDispatch()

  const { entityData, turnData, day, selectedService, extraServices } = useSelector((state) => state.turns?.onLineTurns)

  const handleSubmit = async (data) => {
    setErrorInLogin(false)
    setIsLoading(true)
    const response = await login(data)

    if (response?.error) {
      setErrorInLogin(response?.error)
    }
    setIsLoading(false)
  }

  const handleCreatePendingTurn = async (userConected, entity, turnData, day, selectedService, extraServices) => {
    if (userConected?.patient || userConected?.roles?.includes('patient')) {
      dispatch(cleanPatientCentreTurns())

      let dataPatientCentre = {
        ...(userConected?.patient || {}),
        centre: entity?.centre,
      }
      delete dataPatientCentre?._id //Aca estoy borrando el patient._id

      const selectedServicePrice = Number(selectedService?.price) || 0
      const extraServicesPrice = extraServices?.reduce((a, b) => Number(a) + (Number(b.price) || 0), 0)
      const amountTurn = selectedServicePrice + extraServicesPrice

      let dataTurn = {
        centre: entity?.centre,
        dni: dataPatientCentre?.dni,
        creatorDni: userConected?.dni,
        firstTime: selectedService?._id === 'firstConsultation' ? true : false,
        service: ['consultation', 'firstConsultation'].includes(selectedService?._id) ? null : selectedService,
        origin: 'patient',
        startHour: dayjs(day.concat(turnData?.startHour)).utc(true).toISOString(),
        finishHour: dayjs(day.concat(turnData?.finishHour)).utc(true).toISOString(),
        turnDuration: turnData?.turnDuration,
        consultationDuration: turnData?.turnDuration,
        amount: amountTurn,
        state: 'pending',
      }

      if (extraServices?.length > 0) {
        dataTurn.extraServices = extraServices
      }

      if (entity?.name) {
        dataTurn.machine = entity
      } else {
        dataTurn.doctorCentre = entity
      }

      try {
        const validateAndCreateOrUpdateTurn = await dispatch(checkAvailabilityAndCreateOrUpdateTurn(dataTurn))

        if (validateAndCreateOrUpdateTurn) {
          navigate(`/turnos/info-paciente/${entity?.centre}`)
        } else {
          dispatch(RESET_ONLINE_TURNS())
          navigate(`/centro/${entity?.centre}`)
        }
      } catch (error) {
        console.log(error)
        showToast('Error al crear el turno', 'error')
      }
    }
  }

  useEffect(() => {
    if (!user) return
    if (user.roles.includes('admin')) {
      navigate(`/admin/centros`)
      return
    }

    if ((user.roles.length === 1 && user.roles.includes('patient')) || user.roles.includes('doctor_no_centre')) {
      if (entityData && turnData && day && selectedService) {
        mxp.track(mxp.events.centreLanding.steps.loginDone)
        handleCreatePendingTurn(user, entityData, turnData, day, selectedService, extraServices)
        return
      }
      navigate(`/turnos`)
      return
    }

    if (entityData && turnData && day && selectedService) {
      mxp.track(mxp.events.centreLanding.steps.loginDone)
      handleCreatePendingTurn(user, entityData, turnData, day, selectedService, extraServices)
      return
    }

    navigate(`/agenda/centro/${defaultCentre}`)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, navigate, defaultCentre])

  return (
    <ContainerForm
      onSubmit={(data) => handleSubmit(data)}
      defaultValues={{ ...formDefaultValues }}
      resolver={yupResolver(formValidationSchema)}
    >
      <FormContent isLoading={isLoading} errorInLogin={errorInLogin} classForm={className} />
    </ContainerForm>
  )
}

export default FormLogin
