import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchSecretaryCentre = async (centre) => {
  const route = `api/secretaryCentres`
  const params = { centre }
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchSecretaryCentreByInputSearch = async (params) => {
  const route = `api/secretaryCentres`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchSingleSecretaryCentre = async (id) => {
  const route = `api/secretaryCentres/${id}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewSecretaryCentre = async (data) => {
  const route = `/api/secretaryCentres`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteSecretaryCentre = async (id) => {
  const route = `/api/secretaryCentres/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putSecretaryCentre = async (data) => {
  const route = `/api/secretaryCentres`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}
export {
  addNewSecretaryCentre,
  deleteSecretaryCentre,
  fetchSecretaryCentre,
  fetchSecretaryCentreByInputSearch,
  fetchSingleSecretaryCentre,
  putSecretaryCentre,
}
