import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchAllInternalCentreNotes = async (params) => {
  const route = `api/internalCentreNotes`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postInternalCentreNotes = async (data) => {
  const route = `api/internalCentreNotes`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putInternalCentreNotes = async (data) => {
  const route = `api/internalCentreNotes`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteInternalCentreNotes = async (id) => {
  const route = `api/internalCentreNotes/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { deleteInternalCentreNotes, fetchAllInternalCentreNotes, postInternalCentreNotes, putInternalCentreNotes }
