import React from 'react'
import { CustomField } from '@/components/Form/customFields/CustomField'
import CaluButton from '@/components/styledComponents/CaluButton'
import { Grid } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false, formValues }) => {
  return (
    <>
      <Grid container spacing={2} className={s.containerForm}>
        <Grid item xs={12}>
          <p>
            <span>Profesional: </span>
            <span className={s.name}>{formValues?.doctorCentre}</span>
          </p>
        </Grid>
        <Grid item xs={12}>
          <CustomField label="Precio consulta particular" type="number" name="price" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <CustomField label="Precio primera consulta" type="number" name="priceFirstTime" fullWidth />
        </Grid>

        <Grid item xs={12} className={s.contBtn}>
          <CaluButton color="primary" type="submit" size="medium" loading={isLoading}>
            Guardar
          </CaluButton>
        </Grid>
      </Grid>
    </>
  )
}
