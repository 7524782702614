import React from 'react'
import { FaCheck, FaPlus } from 'react-icons/fa'
import { useSelector } from 'react-redux'

import s from './styles.module.scss'

const InfoUser = () => {
  const { infoUserInCentreAdmin } = useSelector((state) => state?.centres)

  return (
    <div className={s.mainContainer}>
      <div className={s.itemContainer}>
        <div className={s.titleItem}>Cuenta administradora</div>
        <p className={s.detailItem}>
          <span className={s.username}>
            {infoUserInCentreAdmin?.user?.lastname} {infoUserInCentreAdmin?.user?.firstname}
          </span>
          <span> ({infoUserInCentreAdmin?.user?._id})</span>
        </p>
        <p className={s.detailItem}>
          <span>{infoUserInCentreAdmin?.user?.email}</span>
          <span>
            {' '}
            - Tel: {infoUserInCentreAdmin?.user?.mobile ? infoUserInCentreAdmin?.user?.mobile : 'Sin teléfono'}
          </span>
        </p>
        <p className={s.detailItem}>
          Roles:{' '}
          {infoUserInCentreAdmin?.user?.roles?.length ? (
            <span>
              [
              {infoUserInCentreAdmin?.user?.roles.map((rol, i) => (
                <span key={rol}>
                  {rol}
                  {i < infoUserInCentreAdmin?.user?.roles?.length - 1 ? ', ' : ''}
                </span>
              ))}
              ]
            </span>
          ) : (
            '[]'
          )}
        </p>
        <p className={s.detailItem}>
          Centres:{' '}
          {infoUserInCentreAdmin?.user?.centres?.length ? (
            <span>
              [
              {infoUserInCentreAdmin?.user?.centres.map((centre, i) => (
                <span key={centre}>
                  '{centre}' {i < infoUserInCentreAdmin?.user?.centres?.length - 1 ? ', ' : ''}
                </span>
              ))}
              ]
            </span>
          ) : (
            '[]'
          )}
        </p>
        <p className={s.detailItem}>
          doctorCentres:{' '}
          {infoUserInCentreAdmin?.user?.doctorCentres?.length ? (
            <span>
              [
              {infoUserInCentreAdmin?.user?.doctorCentres.map((doctor, i) => (
                <span key={doctor}>
                  '{doctor}' {i < infoUserInCentreAdmin?.user?.doctorCentres?.length - 1 ? ', ' : ''}
                </span>
              ))}
              ]
            </span>
          ) : (
            '[]'
          )}
        </p>
        <p className={s.detailItem}>
          secretaryCentres:{' '}
          {infoUserInCentreAdmin?.user?.secretaryCentres?.length ? (
            <span>
              [
              {infoUserInCentreAdmin?.user?.secretaryCentres.map((secretary, i) => (
                <span key={secretary}>
                  '{secretary}' {i < infoUserInCentreAdmin?.user?.secretaryCentres?.length - 1 ? ', ' : ''}
                </span>
              ))}
              ]
            </span>
          ) : (
            '[]'
          )}
        </p>
      </div>
      <div className={s.itemContainer}>
        <div className={s.titleItem}>Profesionales asociados ({infoUserInCentreAdmin?.doctorCentres?.length})</div>

        {infoUserInCentreAdmin?.doctorCentres?.length > 0 &&
          infoUserInCentreAdmin?.doctorCentres?.map((doctor) => (
            <p key={doctor._id} className={s.detailInsideItem}>
              <span className={s.contIcon}>
                {doctor?.userActivation ? <FaCheck className={s.iconTrue} /> : <FaPlus className={s.iconFalse} />}
              </span>{' '}
              <span className={s.username}>
                {doctor?.lastname} {doctor.firstname}
              </span>
              <span> - ({doctor._id}) </span>
              {doctor?.email && <span> - {doctor?.email}</span>}
              <span> - Cel: {doctor?.mobile ? doctor?.mobile : 'Sin celular'}</span>
              <span> - Tel: {doctor?.telephone ? doctor?.telephone : 'Sin teléfono'}</span>
            </p>
          ))}
      </div>

      <div className={s.itemContainer}>
        <div className={s.titleItem}>Asistentes asociados ({infoUserInCentreAdmin?.secretaryCentres?.length})</div>
        {infoUserInCentreAdmin?.secretaryCentres?.length > 0 &&
          infoUserInCentreAdmin?.secretaryCentres?.map((secretary) => (
            <p key={secretary._id} className={s.detailInsideItem}>
              <span className={s.contIcon}>
                {secretary?.userActivation ? <FaCheck className={s.iconTrue} /> : <FaPlus className={s.iconFalse} />}
              </span>{' '}
              <span className={s.username}>
                {secretary?.lastname} {secretary.firstname}
              </span>
              <span> - ({secretary._id}) </span>
              {secretary?.email && <span> - {secretary?.email}</span>}
              <span> - Cel: {secretary?.mobile ? secretary?.mobile : 'Sin celular'}</span>
              <span> - Tel: {secretary?.telephone ? secretary?.telephone : 'Sin teléfono'}</span>
            </p>
          ))}
      </div>

      <div className={s.itemContainer}>
        <div className={s.titleItem}>Agendas asociadas ({infoUserInCentreAdmin?.machines?.length})</div>
        {infoUserInCentreAdmin?.machines?.length > 0 &&
          infoUserInCentreAdmin?.machines?.map((machine) => (
            <p key={machine._id} className={s.detailInsideItem}>
              <span className={s.username}>{machine?.name}</span>
              <span> - ({machine._id}) </span>
            </p>
          ))}
      </div>

      <div className={s.itemContainer}>
        <div className={s.titleItem}>Servicios asociados ({infoUserInCentreAdmin?.services?.length})</div>
        {infoUserInCentreAdmin?.services?.length > 0 &&
          infoUserInCentreAdmin?.services?.map((service) => (
            <p key={service._id} className={s.detailInsideItem}>
              <span className={s.username}>{service?.name}</span>
              <span> - ({service._id}) </span>
            </p>
          ))}
      </div>
    </div>
  )
}

export default InfoUser
