import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { showToast } from '@/utils/toast'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import PropTypes from 'prop-types'
import TabAccessToCashManagment from './tabAccessToCashManagment'
import TabBackups from './tabBackups'
import TabGeneral from './tabGeneral'
import TabInsurances from './tabInsurances'
import TabNonWorkingDays from './tabNonWorkingDays'
import TabOnlineTurns from './tabOnlineTurns'
import TabPayments from './tabPayments'
import TabReminders from './tabReminders'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

const DetailProfile = () => {
  const [searchParams, setSearchParams] = useSearchParams({ tab: 'general' })
  const tabValue = searchParams.get('tab')
  const urlMessage = searchParams.get('mensaje')
  const [value, setValue] = useState(tabValue)
  const { t } = useTranslation('global')
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isCentre, isAdmin } = usersProfileConnected

  const shouldShowTabCashBox = () => {
    const hasCentreAccess = centreDetail?.hasCashboxModule && isCentre
    const hasAdminAccess = isAdmin

    return hasCentreAccess || hasAdminAccess
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
    setSearchParams({ tab: newValue })
  }

  useEffect(() => {
    if (urlMessage) showToast(urlMessage, 'success')
  }, [urlMessage])

  return (
    <Box sx={{ width: '100%', marginBottom: '30px' }}>
      <Box>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Módulo de gestión"
          value={value}
          onChange={handleTabChange}
          sx={{
            '& .MuiTab-root.Mui-selected': {
              color: '#4C5EFE',
            },
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          <Tab label="General" value={'general'} />
          <Tab label="Recordatorios" value={'recordatorios'} />
          <Tab label={`${t('turnLabel.P')} online`} value={`${t('turnLabel.p')} online`} />
          <Tab label="Dias no laborables" value={'Dias no laborables'} />
          <Tab label={`${capitalizeFirstLetters(t('insuranceLabel.P'))}`} value={`${t('insuranceLabel.p')}`} />
          {centreDetail?.centreConfiguration?.enableTurnsPaymentLinkModule && <Tab label="Pagos" value={'pagos'} />}
          {shouldShowTabCashBox() && <Tab label="Gestión" value={'gestion'} />}
          {isAdmin && <Tab label="Backups" value={'backups'} />}
        </Tabs>
      </Box>

      <TabPanel value={value} index={'general'}>
        <TabGeneral />
      </TabPanel>
      <TabPanel value={value} index={'recordatorios'}>
        <TabReminders />
      </TabPanel>
      <TabPanel value={value} index={`${t('turnLabel.p')} online`}>
        <TabOnlineTurns />
      </TabPanel>
      <TabPanel value={value} index={'Dias no laborables'}>
        <TabNonWorkingDays />
      </TabPanel>
      <TabPanel value={value} index={`${t('insuranceLabel.p')}`}>
        <TabInsurances />
      </TabPanel>
      {centreDetail?.centreConfiguration?.enableTurnsPaymentLinkModule && (
        <TabPanel value={value} index={'pagos'}>
          <TabPayments />
        </TabPanel>
      )}
      {shouldShowTabCashBox() && (
        <TabPanel value={value} index={'gestion'}>
          <TabAccessToCashManagment />
        </TabPanel>
      )}
      {isAdmin && (
        <TabPanel value={value} index={'backups'}>
          <TabBackups />
        </TabPanel>
      )}
    </Box>
  )
}
export default DetailProfile
