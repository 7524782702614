import React from 'react'
import defaultUserImage from '@/assets/images/default.png'
import LoaderCircle from '@/components/LoaderCircle'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

const ImageCircle = ({ image, defaultImage, uploadImage, loading, className, id }) => {
  return (
    <>
      <input type="file" onChange={(event) => uploadImage(event)} className={s.file} id={id} />
      {loading ? (
        <LoaderCircle />
      ) : (
        <div
          style={{ backgroundImage: `url(${image || defaultImage})` }}
          className={s[`${className}`]}
          onClick={() => document.getElementById(id).click()}
        ></div>
      )}
    </>
  )
}

export default ImageCircle

ImageCircle.propTypes = {
  image: PropTypes.string,
  defaultImage: PropTypes.string,
  uploadImage: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.oneOf([
    'uploadImageProfile',
    'uploadImageRectangle',
    'changeProfileImage',
    'uploadImageCentreProfile',
  ]),
  id: PropTypes.string,
}

ImageCircle.defaultProps = {
  defaultImage: defaultUserImage,
}
