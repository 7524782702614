import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import { useSocket } from '@/context/SocketProvider'
import { useUser } from '@/context/UserProvider'
import useValidateURLId from '@/hooks/useValidateURLId'
import { postSecretaryCentre, updateSecretaryCentre } from '@/store/secretaryCentre/actions'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { Grid, TextField } from '@mui/material'

import s from './styles.module.scss'

const FormCreateSecretaryCentre = ({ handleClose }) => {
  const centreID = useValidateURLId()
  const dispatch = useDispatch()
  const { defaultCentre } = useUser()
  const { socket } = useSocket()

  const [isLoading, setIsLoading] = useState(false)
  const [showErrorEmails, setShowErrorEmails] = useState(false)

  const secretaryCentreSelected = useSelector((state) => state.secretaryCentre?.secretaryCentreSelected)

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      repiteEmail: '',
      mobile: '',
      telephone: '',
    },
  })

  const onSubmit = async (data, e) => {
    if (!e) return
    e.preventDefault()
    setIsLoading(true)

    data.centre = centreID

    if (data.repiteEmail) {
      if (data.email !== data.repiteEmail) {
        setShowErrorEmails(true)
        setIsLoading(false)
        return
      } else {
        setShowErrorEmails(false)
      }
    }

    delete data.repiteEmail

    try {
      if (secretaryCentreSelected) {
        const updatedSecretary = await dispatch(updateSecretaryCentre(data))
        socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedSecretary)
      } else {
        const newSecretary = await dispatch(postSecretaryCentre(data))
        socket.emit('calendar_new_entity', `${defaultCentre}_calendar`, newSecretary)
      }
      handleClose()
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (secretaryCentreSelected) {
      Object.keys(secretaryCentreSelected).forEach((k) => {
        if (k === 'mobile') {
          if (!secretaryCentreSelected[k]) {
            setValue(k, '')
            return
          }
        }
        if (k === 'telephone') {
          if (!secretaryCentreSelected[k]) {
            setValue(k, '')
            return
          }
        }

        setValue(k, secretaryCentreSelected[k])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secretaryCentreSelected])

  return (
    <div>
      <div className={s.containerForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={s.subTitle}>
              <p>
                <b>Datos personales</b>
              </p>
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="firstname"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                    type="text"
                    label="Nombre(s) *"
                    variant="standard"
                    fullWidth
                    onKeyDown={handleKeyDown}
                  />
                )}
                rules={{ required: true }}
              />
              {errors.firstname?.type === 'required' && <p className={s.formAlert}>Nombre requerido</p>}
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="lastname"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                    type="text"
                    label="Apellido(s) *"
                    variant="standard"
                    fullWidth
                    onKeyDown={handleKeyDown}
                  />
                )}
                rules={{ required: true }}
              />
              {errors.lastname?.type === 'required' && <p className={s.formAlert}>Apellido requerido</p>}
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onKeyDown={handleKeyDown}
                    type="text"
                    label="Email *"
                    variant="standard"
                    fullWidth
                  />
                )}
                rules={{ required: true }}
              />
              {errors.email?.type === 'required' && <p className={s.formAlert}>Email requerido</p>}
            </Grid>

            {secretaryCentreSelected ? null : (
              <Grid item xs={12} md={6}>
                <Controller
                  name="repiteEmail"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onKeyDown={handleKeyDown}
                      type="text"
                      label="Repite email *"
                      variant="standard"
                      fullWidth
                    />
                  )}
                  rules={{ required: true }}
                />
                {errors.repiteEmail?.type === 'required' && <p className={s.formAlert}>Email requerido</p>}
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <Controller
                name="mobile"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onKeyDown={handleKeyDown}
                    type="number"
                    label="Teléfono Móvil"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="telephone"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onKeyDown={handleKeyDown}
                    type="number"
                    label="Teléfono fijo"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>

          {showErrorEmails ? (
            <div className={s.containerShowErrors}>
              <b>Los emails deben coincidir</b>
            </div>
          ) : null}

          <div className={s.containerBtnSave}>
            <CaluButton color="primary" size="medium" type="submit" loading={isLoading}>
              Guardar
            </CaluButton>
          </div>
        </form>
      </div>
    </div>
  )
}

export default FormCreateSecretaryCentre
