import React from 'react'
import { useUser } from '@/context/UserProvider'
import { AppBar, Box, Container } from '@mui/material'

import NavBarContent from './components/NavBarContent'

import s from './styles.module.scss'

export default function NavBar() {
  const { user } = useUser()
  const isAdmin = user?.roles?.includes('admin')

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className={isAdmin ? s.navAdmin : s.appBar}>
        <Container>
          <NavBarContent />
        </Container>
      </AppBar>
    </Box>
  )
}
