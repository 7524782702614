export const reducePayload = (patientCentre) => {
  if (!patientCentre) return {}
  // to force deep copy
  let auxPatientCentre = JSON.parse(JSON.stringify(patientCentre))

  // reduce patientCentre.logs
  if (auxPatientCentre?.logs?.length > 100) {
    auxPatientCentre.logs = auxPatientCentre?.logs?.slice(-100)
  }

  return auxPatientCentre
}
