import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DividerSection from '@/components/DividerSection'
import { useUser } from '@/context/UserProvider'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Button, Menu, MenuItem } from '@mui/material'

import s from '../../styles.module.scss'

export default function AccountSettings({ entityRole }) {
  const { defaultCentre } = useUser()
  const [showAdminPanel, setShowAdminPanel] = useState(false)
  const [currentTarget, setCurrentTarget] = useState(document.firstElementChild)
  const centre = useSelector((state) => state.centres.centreProfile)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected

  const treatmentTemplatesMenuItem = () => {
    if (entityRole === 'centre' && centre?.hasTreatments) return true
    if (entityRole === 'doctor' && centre?.hasTreatments) return true
  }

  const clinicHistoryMenuItem = () => {
    return entityRole === 'centre' || entityRole === 'doctor' || entityRole === 'admin'
  }

  return (
    <div>
      <Button
        className={s.navButton}
        onClick={(e) => {
          setShowAdminPanel(true)
          setCurrentTarget(e.currentTarget)
        }}
      >
        Configuración <KeyboardArrowDownIcon />
      </Button>

      <Menu anchorEl={currentTarget} open={showAdminPanel} onClose={() => setShowAdminPanel(false)}>
        {entityRole !== 'patient' && entityRole !== 'doctor' && (
          <div>
            <Link
              to={`/agendas/centro/${defaultCentre}`}
              className={s.link}
              onClick={() => mxp.track(mxp.events.navbar.configAgendasClicked)}
            >
              <MenuItem className={s.hoverText}>Agendas</MenuItem>
            </Link>
            <Link
              to={`/administracion/centro/${defaultCentre}`}
              className={s.link}
              onClick={() => mxp.track(mxp.events.navbar.configUsersClicked)}
            >
              <MenuItem className={s.hoverText}>Usuarios</MenuItem>
            </Link>
            <Link
              to={`/servicios/centro/${defaultCentre}`}
              className={s.link}
              onClick={() => mxp.track(mxp.events.navbar.configServicesClicked)}
            >
              <MenuItem className={s.hoverText}>Servicios</MenuItem>
            </Link>
            <Link
              to={`/precios/centro/${defaultCentre}`}
              className={s.link}
              onClick={() => mxp.track(mxp.events.navbar.configPricesClicked)}
            >
              <MenuItem className={s.hoverText}>Precio de planes</MenuItem>
            </Link>
          </div>
        )}

        {treatmentTemplatesMenuItem() && (
          <Link
            to={`/tratamientos/centro/${defaultCentre}`}
            className={s.link}
            onClick={() => mxp.track(mxp.events.navbar.configTreatmentsClicked)}
          >
            <MenuItem className={s.hoverText}>Sesiones</MenuItem>
          </Link>
        )}

        {clinicHistoryMenuItem() && (
          <Link
            to={`/historias-clinicas/${
              entityInLocalStorage?.role === 'doctor' ? entityInLocalStorage?.doctorCentreId : entityInLocalStorage?._id
            }`}
            className={s.link}
            onClick={() => mxp.track(mxp.events.navbar.configHCTemplatesClicked)}
          >
            <MenuItem className={s.hoverText}>Historias clínicas</MenuItem>
          </Link>
        )}

        <Link to="/perfil" className={s.link} onClick={() => mxp.track(mxp.events.navbar.configGeneralClicked)}>
          <MenuItem className={s.hoverText}>Configuración general</MenuItem>
        </Link>

        <DividerSection size="small" />

        {entityRole !== 'patient' && entityRole !== 'doctor' && (
          <Link to={`/centro/${defaultCentre}/espacios`} className={s.link}>
            <MenuItem className={s.hoverText}>Vista consultarios</MenuItem>
          </Link>
        )}
        {entityRole !== 'patient' && (
          <Link to={`/centro/${defaultCentre}`} className={s.link}>
            <MenuItem className={s.hoverText}>Central de citas</MenuItem>
          </Link>
        )}
      </Menu>
    </div>
  )
}
