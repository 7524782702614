import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'insurancePrices',
  initialState: {
    allInsurancePrices: [],
    insurancePriceSelected: null,
  },
  reducers: {
    FETCH_INSURANCE_PRICES: (state, { payload }) => {
      state.allInsurancePrices = payload
    },
    INSURANCE_PRICE_UPDATED: (state, { payload }) => {
      state.allInsurancePrices = state.allInsurancePrices.map((insurancePrice) => {
        if (insurancePrice._id === payload._id) {
          insurancePrice = payload
        }
        return insurancePrice
      })
      state.insurancePriceSelected = payload
    },
    INSURANCE_PRICE_DELETE: (state, { payload }) => {
      state.allInsurancePrices = state.allInsurancePrices.filter((insurancePrice) => insurancePrice._id !== payload)
      state.insurancePriceSelected = null
    },
    INSURANCE_PRICE_CREATED: (state, { payload }) => {
      state.allInsurancePrices = [...(state.allInsurancePrices || []), payload]
    },
    INSURANCE_PRICE_SELECTED: (state, { payload }) => {
      state.insurancePriceSelected = payload
    },
    RESET_INSURANCE_PRICE_SELECTED: (state, { payload }) => {
      state.insurancePriceSelected = null
    },
  },
})

export const {
  FETCH_INSURANCE_PRICES,
  INSURANCE_PRICE_UPDATED,
  INSURANCE_PRICE_DELETE,
  INSURANCE_PRICE_CREATED,
  INSURANCE_PRICE_SELECTED,
  RESET_INSURANCE_PRICE_SELECTED,
} = slice.actions
export default slice.reducer
