import React, { useEffect, useRef } from 'react'
import CaluButton from '@/components/styledComponents/CaluButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CaluModal = (props) => {
  const descriptionElementRef = useRef(null)

  useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [props.open])

  const handleClose = (e) => {
    if (props.keepOpen) return e.stopPropagation()

    if (props.functionInHandleClose) {
      props.functionInHandleClose()
    }

    props.setOpen(false)
  }

  const handleConfirmation = async () => {
    await props.onClose()
    props.setOpen(false)
  }
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        disableEscapeKeyDown={props.disableEscapeKeyDown}
        TransitionComponent={Transition}
        classes={{ paper: s.paperProps }}
        maxWidth={props.maxWidth}
      >
        <DialogTitle id="scroll-dialog-title" className={s.dialogTitle}>
          <div>{props.title || ''}</div>
          {!props.hideCloseButton && (
            <Button onClick={handleClose} className={s.closeBtn}>
              X
            </Button>
          )}
        </DialogTitle>
        <DialogContent dividers className={s.modalContent}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            className={s.dialogContentText}
            component="div"
          >
            {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={s.sectionButtons}>
          {props?.textConfirmationButton && (
            <CaluButton
              color="primary"
              onClick={handleConfirmation}
              size="large"
              loading={props.isLoading}
              disabled={props.isLoading}
            >
              {props.textConfirmationButton}
            </CaluButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CaluModal

CaluModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes?.oneOfType([PropTypes.array, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textConfirmationButton: PropTypes.string,
  maxWidth: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  keepOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  functionInHandleClose: PropTypes.func,
}

CaluModal.defaultProps = {
  maxWidth: 'sm',
  disableEscapeKeyDown: false,
  keepOpen: false,
  isLoading: false,
  disabled: false,
}
