import { createSlice } from '@reduxjs/toolkit'

const handleSortHC = (arrayHC) => {
  if (!arrayHC?.length) return
  let sortHC = arrayHC.sort((a, b) => (a.visit > b.visit ? 1 : -1)).sort((a, b) => (a.isPinned < b.isPinned ? 1 : -1))
  return sortHC
}

const slice = createSlice({
  name: 'clinicHistories',
  initialState: {
    allClinicHistories: [],
    paginationData: {
      page: 1,
      limit: 30,
    },
    clinicHistorySelected: null,
    isLoading: false,
  },
  reducers: {
    FETCH_CLINIC_HISTORIES: (state, { payload }) => {
      let sortHC = handleSortHC(payload.allClinicHistories)
      state.allClinicHistories = sortHC
      state.paginationData = payload.paginationData
    },
    CLINIC_HISTORY_UPDATED: (state, { payload }) => {
      let allHC = state.allClinicHistories.map((clinicHistory) => {
        if (clinicHistory._id === payload._id) {
          clinicHistory = payload
        }
        return clinicHistory
      })
      let sortHC = handleSortHC(allHC)
      state.allClinicHistories = sortHC
      state.clinicHistorySelected = payload
    },
    CLINIC_HISTORY_DELETE: (state, { payload }) => {
      state.allClinicHistories = state.allClinicHistories.filter((clinicHistory) => clinicHistory._id !== payload)
      state.clinicHistorySelected = null
    },
    CLINIC_HISTORY_CREATED: (state, { payload }) => {
      let allHC = [payload, ...(state?.allClinicHistories || [])]
      let sortHC = handleSortHC(allHC)
      state.allClinicHistories = sortHC
      state.clinicHistorySelected = payload
    },
    CLINIC_HISTORY_SELECTED: (state, { payload }) => {
      state.clinicHistorySelected = payload
    },
    SET_ISLOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    RESET_CLINIC_HISTORY_SELECTED: (state, { payload }) => {
      state.clinicHistorySelected = null
    },
  },
})

export const {
  FETCH_CLINIC_HISTORIES,
  CLINIC_HISTORY_UPDATED,
  CLINIC_HISTORY_DELETE,
  CLINIC_HISTORY_CREATED,
  CLINIC_HISTORY_SELECTED,
  RESET_CLINIC_HISTORY_SELECTED,
  SET_ISLOADING,
} = slice.actions
export default slice.reducer
