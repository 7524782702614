import { addNewMachine, deleteMachine, fetchMachine, fetchMachines, putMachine } from '@/services/machines'
import {
  FETCH_MACHINES,
  MACHINE_SELECTED,
  MACHINE_UPDATED,
  RESET_MACHINE_SELECTED,
  RESET_MACHINE_STATE,
} from '@/store/machines'
import { filterDoctorCentreValidWT, isDoctorCentreValidAgenda } from '@/utils/functions/isDoctorCentreValidAgenda'
import { showToast } from '@/utils/toast'

const getAllMachines = (params) => async (dispatch) => {
  try {
    let machines = await fetchMachines(params)
    machines = machines.filter((machine) => isDoctorCentreValidAgenda(machine))
    machines = machines.map((machine) => filterDoctorCentreValidWT(machine))
    dispatch(FETCH_MACHINES(machines))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const postMachine = (data) => async (dispatch) => {
  const insurances = data.insurances
  const services = data.services
  const associatedService = data?.workTimes ? data?.workTimes[0]?.services : []
  const associatedDoctorCentre = data?.workTimes ? data?.workTimes[0]?.doctorCentres : []

  try {
    let newMachine = await addNewMachine(data)
    if (insurances) {
      newMachine.insurances = insurances
    }
    if (services) {
      newMachine.services = services
    }
    if (associatedService?.length) {
      newMachine?.workTimes.map((wt) => (wt.services = associatedService))
    }
    if (associatedDoctorCentre?.length) {
      newMachine?.workTimes.map((wt) => (wt.doctorCentres = associatedDoctorCentre))
    }

    if (newMachine) {
      showToast('Agenda creada correctamente', 'success')
    }

    return newMachine
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const deleteMachineAdded = (id) => async (dispatch) => {
  try {
    await deleteMachine(id)
    showToast('Agenda Eliminada correctamente', 'success')
    return id
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateMachine = (data) => async (dispatch) => {
  const insurances = data.insurances
  const services = data.services
  try {
    let machineUpdated = await putMachine(data)
    machineUpdated.insurances = insurances
    machineUpdated.services = services

    if (machineUpdated) {
      showToast('Agenda actualizada correctamente', 'success')
    }
    return machineUpdated
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateImageMachine = (data) => async (dispatch) => {
  const insurances = data.insurances
  const services = data.services
  try {
    let machineUpdated = await putMachine(data)
    machineUpdated.insurances = insurances
    machineUpdated.services = services
    dispatch(MACHINE_UPDATED(machineUpdated))
    if (machineUpdated) {
      showToast('Agenda actualizada correctamente', 'success')
    }
    return machineUpdated
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedMachine = (id) => async (dispatch) => {
  try {
    const singleMachine = await fetchMachine(id)
    dispatch(MACHINE_SELECTED(singleMachine))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedMachine = () => async (dispatch) => {
  try {
    dispatch(RESET_MACHINE_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetMachineState = () => (dispatch) => {
  dispatch(RESET_MACHINE_STATE)
}

export {
  deleteMachineAdded,
  getAllMachines,
  postMachine,
  resetMachineState,
  resetSelectedMachine,
  selectedMachine,
  updateImageMachine,
  updateMachine,
}
