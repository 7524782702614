import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchPrescriptions = async (params) => {
  const route = `api/prescriptions`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewPrescription = async (data) => {
  const route = `api/prescriptions`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deletePrescription = async (id) => {
  const route = `api/prescriptions/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putPrescription = async (data) => {
  const route = `api/prescriptions`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchSinglePrescription = async (id) => {
  const route = `api/prescriptions/${id}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const prescriptionReport = async (data) => {
  const route = `api/mail/prescriptionGenerated`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export {
  addNewPrescription,
  deletePrescription,
  fetchPrescriptions,
  fetchSinglePrescription,
  prescriptionReport,
  putPrescription,
}
