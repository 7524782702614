import i18next from '@/configs/i18next'
import { addExtraDiscount } from '@/helpers/turns/addExtraDiscount'
import { calculateTurnAmountWithoutExtraDiscount } from '@/helpers/turns/calculateTurnAmountWithoutExtraDiscount'
import { getAmountWithoutExtraDiscount } from '@/helpers/turns/getAmountWithoutExtraDiscount'
import { validateAvailabilityOfTurn } from '@/services/centreAvailability'
import {
  cancelTurn,
  cancelTurnTime,
  confirmTurn,
  deleteTurnById,
  fetchSingleTurn,
  fetchTurns,
  fetchTurnsWithoutDate,
  postTurn,
  putAturn,
  setTurnDate,
} from '@/services/turns'
import { SHOW_ERROR_404 } from '@/store/app'
import { REMOVE_TURN_DEBT, UPDATE_TURN_DEBT } from '@/store/debts/index'
import {
  CLEAN_PATIENT_CENTRE_TURNS,
  FETCH_TURNS,
  FETCH_TURNS_FROM_WEEK,
  FETCH_TURNS_SELECTED_DATE,
  GET_PATIENT_CENTRES_TURNS,
  GET_TURNS_WITHOUT_DATE,
  NEW_TURN_CREATED,
  REMOVE_TURN_BY_ID,
  REMOVE_TURN_WITHOUT_DATE,
  RESET_DAYS_MONTH,
  RESET_TURN_TREATMENT,
  SELECTED_ONLINE_TURN,
  SELECTED_ONLINE_TURN_CONFIRM,
  SELECTED_ONLINE_TURN_PATIENT,
  SELECTED_TURN_UPDATED,
  SET_DATA_TURN_TREATMENT,
  SET_DAYS_MONTH,
  SET_ONLINE_TURN_DETAIL,
  TURN_CENTRE_UPDATED,
  TURN_PATIENT_CENTRE_UPDATED,
  TURNS_LOADING,
} from '@/store/turns'
import { findEntityObject } from '@/utils/functions/findEntityObject'

import dayjs from 'dayjs'
import { showToast } from './../../utils/toast/index'

const setSyncNewTurn = (createdTurn) => (dispatch) => {
  try {
    // hack to support chedoc data
    const auxTurn = {
      ...createdTurn,
      doctorCentre: createdTurn?.doctorCentre
        ? createdTurn?.doctorCentre?._id
          ? createdTurn?.doctorCentre
          : { _id: createdTurn?.doctorCentre }
        : undefined,
      patientCentre: createdTurn?.patientCentre
        ? createdTurn?.patientCentre?._id
          ? createdTurn?.patientCentre
          : { _id: createdTurn?.patientCentre }
        : undefined,
      machine: createdTurn?.machine
        ? createdTurn?.machine?._id
          ? createdTurn?.machine
          : { _id: createdTurn?.machine }
        : undefined,

      startHour: createdTurn?.startHour ? dayjs(createdTurn?.startHour).utc().toISOString() : null,
      finishHour: createdTurn?.finishHour ? dayjs(createdTurn?.finishHour).utc().toISOString() : null,
    }
    dispatch(NEW_TURN_CREATED(auxTurn))
    dispatch(resetDataToCreateTurnTreatment())
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const setSyncUpdateTurn = (updatedTurn) => async (dispatch) => {
  try {
    // hack to support chedoc data
    const auxTurn = {
      ...updatedTurn,
      doctorCentre: updatedTurn?.doctorCentre
        ? updatedTurn?.doctorCentre?._id
          ? updatedTurn?.doctorCentre
          : { _id: updatedTurn?.doctorCentre }
        : undefined,
      patientCentre: updatedTurn?.patientCentre
        ? updatedTurn?.patientCentre?._id
          ? updatedTurn?.patientCentre
          : { _id: updatedTurn?.patientCentre }
        : undefined,
      machine: updatedTurn?.machine
        ? updatedTurn?.machine?._id
          ? updatedTurn?.machine
          : { _id: updatedTurn?.machine }
        : undefined,
      startHour: dayjs(updatedTurn?.startHour).toISOString(),
      finishHour: dayjs(updatedTurn?.finishHour).toISOString(),
    }
    dispatch(TURN_CENTRE_UPDATED(auxTurn))
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const setSyncDeleteTurn = (turnId) => (dispatch) => {
  try {
    dispatch(REMOVE_TURN_BY_ID(turnId))
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const syncTurnPayment = (turn) => async (dispatch, getState) => {
  const { allTurns } = getState().turns

  try {
    const oldTurn = allTurns?.find((t) => t?._id === turn?._id)
    if (!oldTurn) return
    const newTurn = {
      ...oldTurn,
      state: turn?.state,
      paymentHistory: turn?.paymentHistory,
      totalPayments: turn?.totalPayments,
      debt: turn?.debt,
    }
    dispatch(TURN_CENTRE_UPDATED(newTurn))
    showToast('Nuevo pago recibido', 'success', 5000)
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const getAllTurns = (params) => async (dispatch, getState) => {
  const { doctorCentres: allDoctorCentres } = getState().doctorCentres
  const { allMachines } = getState().machines

  try {
    dispatch(TURNS_LOADING(true))
    if (params?.paginate) {
      let { docs: allTurns, ...paginationData } = await fetchTurns(params)
      dispatch(FETCH_TURNS({ allTurns, paginationData }))
      dispatch(TURNS_LOADING(false))
    } else {
      const turns = await fetchTurns(params)

      turns?.forEach((turn) => {
        if (turn?.doctorCentre) turn.doctorCentre = findEntityObject(allDoctorCentres, turn?.doctorCentre)
        if (turn?.machine) turn.machine = findEntityObject(allMachines, turn?.machine)
      })

      dispatch(FETCH_TURNS(turns))
      dispatch(TURNS_LOADING(false))
    }
  } catch (error) {
    dispatch(TURNS_LOADING(false))
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const handlePageChangeTurns = (params) => (dispatch, getState) => {
  const { paginationData } = getState().turns
  const { limit } = paginationData
  const newParams = {
    ...params,
    limit: limit,
  }

  dispatch(getAllTurns(newParams))
}

const handlePageSizeChangeTurns = (params) => (dispatch) => {
  const page = 1
  const newParams = {
    ...params,
    page: page,
  }

  dispatch(getAllTurns(newParams))
}

const getTurnFromSelectedDay = (params) => async (dispatch, getState) => {
  const { doctorCentres: allDoctorCentres } = getState().doctorCentres
  const { allMachines } = getState().machines
  dispatch(TURNS_LOADING(true))
  try {
    const fetchAllTurns = await fetchTurns(params)

    fetchAllTurns?.forEach((turn) => {
      if (turn?.doctorCentre) turn.doctorCentre = findEntityObject(allDoctorCentres, turn?.doctorCentre)
      if (turn?.machine) turn.machine = findEntityObject(allMachines, turn?.machine)
    })

    dispatch(FETCH_TURNS_SELECTED_DATE(fetchAllTurns))
    dispatch(TURNS_LOADING(false))
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const getTurnsFromWeek = (params) => async (dispatch, getState) => {
  let allTurns = getState().turns.allTurns
  const { doctorCentres: allDoctorCentres } = getState().doctorCentres
  const { allMachines } = getState().machines

  try {
    let fetchTurnsForWeek = await fetchTurns(params)

    fetchTurnsForWeek?.forEach((turn) => {
      if (turn?.doctorCentre) turn.doctorCentre = findEntityObject(allDoctorCentres, turn?.doctorCentre)
      if (turn?.machine) turn.machine = findEntityObject(allMachines, turn?.machine)
    })

    let totalTurns = [...allTurns, ...fetchTurnsForWeek]

    let hash = {}
    totalTurns = totalTurns.filter((o) => (hash[o.id] ? false : (hash[o.id] = true)))

    dispatch(FETCH_TURNS_FROM_WEEK(totalTurns))
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const setDaysMonth = (days) => async (dispatch) => {
  try {
    dispatch(SET_DAYS_MONTH(days))
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const resetDaysMonth = () => async (dispatch) => {
  try {
    dispatch(RESET_DAYS_MONTH())
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const setDataToCreateTurnTreatment = (data) => (dispatch) => {
  dispatch(SET_DATA_TURN_TREATMENT(data))
}

const resetDataToCreateTurnTreatment = () => (dispatch) => {
  dispatch(RESET_TURN_TREATMENT())
}

const getSingleTurn =
  (idTurn, shrinkCentre = true, hideAlert) =>
  async (dispatch) => {
    try {
      let turn = await fetchSingleTurn(idTurn)

      if (shrinkCentre) {
        turn.centre = turn.centre._id
      }

      if (turn) {
        dispatch(SHOW_ERROR_404(false))
        dispatch(
          SELECTED_TURN_UPDATED({
            ...turn,
            amountWithoutExtraDiscount: getAmountWithoutExtraDiscount(turn?.amount, turn?.extraAmount),
          }),
        )
      }

      if (!turn) dispatch(SHOW_ERROR_404(true))
      return turn
    } catch (error) {
      const { message } = error
      console.log(message)
      dispatch(SHOW_ERROR_404(true))

      if (!hideAlert) {
        showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
      }
    }
  }

const patchSelectedTurn = (data) => (dispatch, getState) => {
  const { selectedTurn } = getState().turns
  let auxTurn = {
    ...selectedTurn,
    ...data,
  }
  // recalculate amount
  auxTurn.amountWithoutExtraDiscount = calculateTurnAmountWithoutExtraDiscount(auxTurn)

  if (!data.notRecalculateAmount) {
    auxTurn.amount = addExtraDiscount(auxTurn?.amountWithoutExtraDiscount, auxTurn?.extraAmount)
  }

  // recalculate totalPayments and debt
  auxTurn.totalPayments = auxTurn?.paymentHistory?.reduce((accum, p) => accum + (p.amount || 0), 0)
  auxTurn.debt = auxTurn.amount - auxTurn.totalPayments

  dispatch(SELECTED_TURN_UPDATED(auxTurn))
}

const cleanSelectedTurn = () => (dispatch) => {
  dispatch(SELECTED_TURN_UPDATED(null))
}

const getPatientCentreTurns = (idCentre, idPatientCentre, page) => async (dispatch) => {
  try {
    const params = {
      centre: idCentre,
      patientCentre: idPatientCentre,
      paginate: true,
      page: page || 1,
    }
    let { docs: patientCentreTurns, ...paginationData } = await fetchTurns(params)
    dispatch(GET_PATIENT_CENTRES_TURNS({ patientCentreTurns, paginationData }))
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const getPatientCentreTurnsByDni = (params) => async (dispatch) => {
  try {
    let { docs: patientCentreTurns, ...paginationData } = await fetchTurns(params)

    dispatch(GET_PATIENT_CENTRES_TURNS({ patientCentreTurns, paginationData }))
    return patientCentreTurns
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const getPatientTurnsInOtherCentre =
  (centreId, userDni = 0, origin) =>
  async (dispatch) => {
    try {
      const params = {
        centre: centreId,
        dni: userDni,
        origin: origin,
      }
      let patientTurns = await fetchTurns(params)
      dispatch(GET_PATIENT_CENTRES_TURNS(patientTurns))
      return patientTurns
    } catch (error) {
      const { message } = error
      console.log(message)
      showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
    }
  }

const cleanPatientCentreTurns = () => (dispatch) => {
  dispatch(CLEAN_PATIENT_CENTRE_TURNS())
}

const resetSingleTurn = () => async (dispatch) => {
  dispatch(SELECTED_TURN_UPDATED(null))
}

const updateATurn = (auxTurn, hideAlert, centre) => async (dispatch) => {
  const doctorCentre = auxTurn?.doctorCentre
    ? auxTurn?.doctorCentre?._id
      ? auxTurn?.doctorCentre
      : { _id: auxTurn?.doctorCentre }
    : undefined
  const patientCentre = auxTurn?.patientCentre
    ? auxTurn?.patientCentre?._id
      ? auxTurn?.patientCentre
      : { _id: auxTurn?.patientCentre }
    : undefined
  const machine = auxTurn?.machine ? (auxTurn?.machine?._id ? auxTurn?.machine : { _id: auxTurn?.machine }) : null
  const treatment = auxTurn.treatment
  const service = auxTurn.service
  const extraServices = auxTurn.extraServices
  const insurance = auxTurn.insurance

  const turn = {
    ...auxTurn,
  }

  try {
    let updateATurn = await putAturn(turn)
    updateATurn.patientCentre = patientCentre
    updateATurn.doctorCentre = doctorCentre
    if (treatment) updateATurn.treatment = treatment
    if (machine) updateATurn.machine = machine
    if (service) updateATurn.service = service
    if (extraServices && extraServices.length > 0) {
      updateATurn.extraServices = extraServices
    }
    if (insurance) updateATurn.insurance = insurance

    if (!hideAlert) {
      showToast(`${i18next.t('turnLabel.Sa', { ns: 'global' })}`, 'success', 5000)
    }

    dispatch(TURN_CENTRE_UPDATED(updateATurn))

    return updateATurn
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const updateATurnDate = (turn, undatedTurnSection) => async (dispatch) => {
  const patientCentre = turn.patientCentre

  try {
    let updatedTurn = await setTurnDate(turn)
    updatedTurn.patientCentre = patientCentre

    if (undatedTurnSection) {
      dispatch(SELECTED_TURN_UPDATED(updatedTurn))
    }

    showToast(`${i18next.t('turnLabel.Sa', { ns: 'global' })}`, 'success', 5000)
    return updatedTurn
  } catch (error) {
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const updatePatienCentreTurn = (auxTurn, hideAlert) => async (dispatch, getState) => {
  const state = getState()
  let selectedTurn = auxTurn || state?.turns?.selectedTurn
  const centre = selectedTurn.centre
  const patientCentre = selectedTurn.patientCentre
  const doctorCentre = selectedTurn.doctorCentre
  const service = selectedTurn.service

  try {
    let updatePatientTurn = await putAturn(selectedTurn)
    updatePatientTurn.centre = centre
    updatePatientTurn.patientCentre = patientCentre
    updatePatientTurn.doctorCentre = doctorCentre
    updatePatientTurn.service = service

    dispatch(TURN_PATIENT_CENTRE_UPDATED(updatePatientTurn))

    if (selectedTurn._id === updatePatientTurn._id) dispatch(SELECTED_TURN_UPDATED(updatePatientTurn))

    if (!hideAlert) {
      showToast(`${i18next.t('turnLabel.Sa', { ns: 'global' })}`, 'success')
    }

    return updatePatientTurn
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const postNewTurn = (infoToAddNewTurn, hideAlert) => async (dispatch) => {
  const patientCentre = infoToAddNewTurn.patientCentre
  const doctorCentre = infoToAddNewTurn.doctorCentre
  const machine = infoToAddNewTurn.machine
  const treatment = infoToAddNewTurn.treatment

  const auxTurn = {
    ...infoToAddNewTurn,
  }

  try {
    const addNewTurn = await postTurn(auxTurn)
    if (treatment) addNewTurn.treatment = treatment
    if (patientCentre) addNewTurn.patientCentre = patientCentre
    if (doctorCentre) addNewTurn.doctorCentre = doctorCentre
    if (machine) addNewTurn.machine = machine

    if (!hideAlert) {
      showToast(`${i18next.t('turnLabel.Sag', { ns: 'global' })} correctamente`, 'success', 5000)
    }

    return addNewTurn
  } catch (error) {
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const updateOnlineTurn = (infoTurn, hideAlert) => async (dispatch) => {
  const patientCentre = infoTurn.patientCentre
  const doctorCentre = infoTurn.doctorCentre
  const machine = infoTurn.machine
  const treatment = infoTurn.treatment

  const auxTurn = {
    ...infoTurn,
  }

  try {
    const updatedTurn = await putAturn(auxTurn)
    if (treatment) updatedTurn.treatment = treatment
    if (patientCentre) updatedTurn.patientCentre = patientCentre
    if (doctorCentre) updatedTurn.doctorCentre = doctorCentre
    if (machine) updatedTurn.machine = machine

    if (!hideAlert) {
      showToast(`${i18next.t('turnLabel.Sag', { ns: 'global' })} correctamente`, 'success', 5000)
    }

    return updatedTurn
  } catch (error) {
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const checkAvailabilityAndCreateOrUpdateTurn = (infoTurn) => async (dispatch) => {
  try {
    let responseTurn

    if (infoTurn?._id) {
      let turnDetail = await fetchSingleTurn(infoTurn?._id)
      if (turnDetail) {
        responseTurn = await dispatch(updateOnlineTurn(infoTurn, true))
      } else {
        return false
      }
    } else {
      const checkAvailability = await validateAvailabilityOfTurn(infoTurn)

      if (!checkAvailability?.isValid) {
        return false
      }
      responseTurn = await dispatch(postNewTurn(infoTurn, true))
    }

    if (responseTurn) {
      dispatch(SET_ONLINE_TURN_DETAIL(responseTurn))
      return true
    }
    return false
  } catch (error) {
    console.log(error)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const updatePatientDebt = (turn, authorization) => async (dispatch) => {
  const patientCentre = turn.patientCentre
  try {
    let updatedTurnDebt = await putAturn(turn)
    updatedTurnDebt.patientCentre = patientCentre
    const totalPayment = updatedTurnDebt?.paymentHistory?.map((e) => e.amount).reduce((a, p) => a + p, 0)
    const totalAmount = updatedTurnDebt?.amount
    if (authorization) {
      dispatch(UPDATE_TURN_DEBT(updatedTurnDebt))
      showToast('Bono autorizado', 'success')
      return
    }
    if (totalPayment === totalAmount) {
      dispatch(REMOVE_TURN_DEBT(updatedTurnDebt))
      showToast('deuda eliminada', 'success')
    } else {
      dispatch(UPDATE_TURN_DEBT(updatedTurnDebt))
      showToast('deuda actualizada', 'success')
    }
  } catch (error) {
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const removeTurnById = (id, hideAlert) => async (dispatch) => {
  try {
    await deleteTurnById(id)
    dispatch(REMOVE_TURN_BY_ID(id))
    if (!hideAlert) {
      showToast(`${i18next.t('turnLabel.Sel', { ns: 'global' })}`, 'success')
    }
    return id
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const cancelTurnTimeToReprogram = (id) => async (dispatch) => {
  try {
    let reprogramTurn = await cancelTurnTime(id)
    return reprogramTurn
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const selectedOnLineTurn =
  (entityData, turnData, day, selectedService, extraServices) => async (dispatch, getState) => {
    try {
      const state = getState()
      const centreTargetProfile = state.centres.centreTarget

      const selectedOnLineTurn = {
        entityData,
        turnData,
        day,
        selectedService,
        centreTargetProfile,
        extraServices,
      }
      dispatch(SELECTED_ONLINE_TURN(selectedOnLineTurn))
    } catch (error) {
      const { message } = error
      console.log(message)
    }
  }

const selectedOnLineTurnPatient = (patientData) => async (dispatch) => {
  try {
    dispatch(SELECTED_ONLINE_TURN_PATIENT(patientData))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedOnLineTurnConfirm = (isConfirmed) => async (dispatch) => {
  try {
    dispatch(SELECTED_ONLINE_TURN_CONFIRM(isConfirmed))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const getTurnsWithoutDate = (params) => async (dispatch) => {
  try {
    const { docs: turnsWithoutDate, ...paginationData } = await fetchTurnsWithoutDate(params)

    dispatch(GET_TURNS_WITHOUT_DATE({ turnsWithoutDate, paginationData }))
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error')
  }
}

const deleteTurnWithoutDate = (turnId) => async (dispatch) => {
  try {
    await deleteTurnById(turnId)
    dispatch(REMOVE_TURN_WITHOUT_DATE(turnId))
    showToast('Turno eliminado correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const setConfirmedTurn = (auxTurn) => async (dispatch) => {
  try {
    const { turn } = await confirmTurn(auxTurn._id)
    turn.centre = auxTurn.centre
    turn.doctorCentre = auxTurn.doctorCentre
    turn.service = auxTurn.service
    dispatch(TURN_PATIENT_CENTRE_UPDATED(turn))
    showToast(`${i18next.t('turnLabel.Sco', { ns: 'global' })}`, 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error')
  }
}
const setCancelledTurn = (auxTurn) => async (dispatch) => {
  try {
    const { turn } = await cancelTurn(auxTurn?._id)
    turn.centre = auxTurn.centre
    turn.doctorCentre = auxTurn.doctorCentre
    turn.service = auxTurn.service
    dispatch(TURN_PATIENT_CENTRE_UPDATED(turn))
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error')
  }
}

export {
  cancelTurnTimeToReprogram,
  checkAvailabilityAndCreateOrUpdateTurn,
  cleanPatientCentreTurns,
  cleanSelectedTurn,
  deleteTurnById,
  deleteTurnWithoutDate,
  getAllTurns,
  getPatientCentreTurns,
  getPatientCentreTurnsByDni,
  getPatientTurnsInOtherCentre,
  getSingleTurn,
  getTurnFromSelectedDay,
  getTurnsFromWeek,
  getTurnsWithoutDate,
  handlePageChangeTurns,
  handlePageSizeChangeTurns,
  patchSelectedTurn,
  postNewTurn,
  removeTurnById,
  resetDataToCreateTurnTreatment,
  resetDaysMonth,
  resetSingleTurn,
  selectedOnLineTurn,
  selectedOnLineTurnConfirm,
  selectedOnLineTurnPatient,
  setCancelledTurn,
  setConfirmedTurn,
  setDataToCreateTurnTreatment,
  setDaysMonth,
  setSyncDeleteTurn,
  setSyncNewTurn,
  setSyncUpdateTurn,
  syncTurnPayment,
  updateATurn,
  updateATurnDate,
  updatePatienCentreTurn,
  updatePatientDebt,
}
