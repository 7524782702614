import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CaluModal from '@/components/CaluModal'
import LoaderCircle from '@/components/LoaderCircle'
import CaluButton from '@/components/styledComponents/CaluButton'
import CaluRawDatePicker from '@/components/styledComponents/CaluRawDatePicker'
import { getWappTurnReminders, setSentRemindersQuantity } from '@/store/wapp/actions'
import { handleWappReminder } from '@/utils/functions/handleWappReminders'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { showToast } from '@/utils/toast'
import { Grid } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const HeaderManagement = ({ startDate, setStartDate, finishDate, setFinishDate, deviceId, isLoadingReminders }) => {
  const { t } = useTranslation('global')
  const dispatch = useDispatch()
  const now = dayjs().format()

  const { wappTurnReminders, originalWappReminderAvailableToSend, paginationData } = useSelector((state) => state.wapp)
  const { status: wappStatus } = useSelector((state) => state.wapp)
  const { centreProfile } = useSelector((state) => state.centres)
  const { usersProfileConnected } = useSelector((state) => state.users)

  const [isLoading, setIsLoading] = useState(false)
  const [confirmSendRemindersNextPage, setConfirmSendRemindersNextPage] = useState(false)
  const [openPopUp, setOpenPopUp] = useState(false)
  const [reminderNextPagePopUp, setReminderNextPagePopUp] = useState(false)
  const [confirmSendMultipleRecords, setConfirmSendMultipleRecords] = useState(false)
  const [indexTurn, setIndexTurn] = useState(1)

  const { isAdmin } = usersProfileConnected
  const { page, hasNextPage } = paginationData

  const wappReminderAvailableToSend = wappTurnReminders?.filter((t) => {
    const attempts = t?.wappReminder?.attempts
    const lastAttempt = attempts?.slice(-1)[0]
    return !t?.wappReminder || attempts?.length === 1 || (lastAttempt && lastAttempt.status === 'error')
  })

  const reminderWord = originalWappReminderAvailableToSend > 1 ? 'recordatorios' : 'recordatorio'

  let params = {
    centre: deviceId,
    start: toCustomTz(startDate, 'utc', true),
    finish: toCustomTz(finishDate, 'utc', true),
    page: page + 1,
    limit: 30,
  }

  const handleSetStartDate = (newDate) => {
    const currentDate = dayjs()
    const today = dayjs().startOf('day')
    const tomorrow = dayjs().add(1, 'day').startOf('day')

    if (dayjs(newDate).format() === 'Invalid Date') return

    if (dayjs(newDate).isBefore(today, 'day')) {
      setStartDate(dayjs(currentDate).format())
      return
    }

    if (dayjs(newDate).isSame(today, 'day')) {
      setStartDate(currentDate.format())
    } else {
      setStartDate(dayjs(newDate).startOf('day').format())
    }

    if (dayjs(newDate).isAfter(tomorrow, 'day')) {
      setFinishDate(dayjs(newDate).endOf('day').format())
    }
  }

  const handleSetFinishDate = (newDate) => {
    if (dayjs(newDate).endOf('day').format() === 'Invalid Date') return
    if (dayjs(newDate).isBefore(dayjs(), 'day')) {
      setFinishDate(dayjs(startDate).endOf('day').format())
      return
    }
    setFinishDate(dayjs(newDate).endOf('day').format())
  }

  useEffect(() => {
    if (confirmSendRemindersNextPage) {
      handleSendMultiplesWapps()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmSendRemindersNextPage])

  const handleSendMultiplesWapps = async () => {
    setOpenPopUp(false)
    setReminderNextPagePopUp(false)
    setConfirmSendRemindersNextPage(false)

    let sentReminders = 0
    const hideAlert = true
    try {
      setConfirmSendMultipleRecords(true)
      setIsLoading(true)
      if (!wappReminderAvailableToSend?.length) {
        showToast('No hay recordatorios disponibles para enviar', 'error', 2000)
        return
      }
      for (let i = 0; i < wappReminderAvailableToSend.length; i++) {
        setIndexTurn(i + 1)
        const selectedTurn = wappReminderAvailableToSend[i]
        const auxData = {
          selectedTurn: {
            ...selectedTurn,
            centre: centreProfile,
          },
          state: 'init',
          dispatch: dispatch,
          wappStatus: wappStatus,
        }

        try {
          await handleWappReminder(auxData, hideAlert)
          sentReminders = sentReminders + 1
        } catch (error) {
          console.error(`Error en el envío del turno ${i}:`, error)
        }
        await new Promise((resolve) => setTimeout(resolve, 1500))
      }

      await dispatch(setSentRemindersQuantity(sentReminders))
      setIsLoading(false)
      setConfirmSendMultipleRecords(false)

      if (hasNextPage) {
        await dispatch(getWappTurnReminders(params))
        setReminderNextPagePopUp(true)
      }
      showToast('Recordatorios enviados correctamente', 'success', 2000)
    } catch (error) {
      showToast('upps! Algo salió mal, intenta nuevamente', 'error', 2000)
    }
  }

  const handleInputFocus = (date) => {
    return dayjs(date).isBefore(dayjs(), 'day')
  }

  return (
    <Grid
      container
      className={s.mainContainer}
      sx={{
        '& .MuiInput-underline:after': {
          borderBottomColor: '#4C5EFE',
        },
      }}
    >
      <Grid item container xs={12} md={8} className={s.containerDatePickers}>
        <Grid item className={s.subTitle}>
          {`Fecha de ${t('turnLabel.s')}`}
        </Grid>
        <Grid item>
          <CaluRawDatePicker
            labelText="Desde"
            value={startDate}
            onChange={handleSetStartDate}
            minDate={dayjs(now)}
            shouldDisableDate={handleInputFocus}
            disabled={isLoadingReminders}
          />
        </Grid>
        <Grid item>
          <CaluRawDatePicker
            labelText="Hasta"
            value={finishDate}
            onChange={handleSetFinishDate}
            minDate={dayjs(startDate)}
            disabled={isLoadingReminders}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} md={4} className={s.containerButton}>
        <CaluButton
          size="small"
          color="secondary"
          onClick={() => setOpenPopUp(true)}
          loading={isLoading}
          disabled={isLoading || !wappReminderAvailableToSend?.length || isAdmin}
        >
          Enviar todos los recordatorios
        </CaluButton>
      </Grid>

      <CaluModal
        open={openPopUp}
        setOpen={setOpenPopUp}
        title={'Enviar recordarios'}
        textConfirmationButton={'Si, enviar'}
        onClose={handleSendMultiplesWapps}
        maxWidth="xs"
      >
        <div> {`¿Está seguro que quiere enviar ${wappReminderAvailableToSend?.length} ${reminderWord}?`}</div>
      </CaluModal>

      <CaluModal
        open={reminderNextPagePopUp}
        setOpen={setReminderNextPagePopUp}
        title={'Enviar recordarios'}
        textConfirmationButton={'Si, enviar'}
        onClose={() => setConfirmSendRemindersNextPage(true)}
        maxWidth="xs"
      >
        <div>
          {' '}
          {`¿Está seguro que quiere enviar ${wappReminderAvailableToSend?.length} ${reminderWord} de la siguiente página?`}
        </div>
      </CaluModal>

      <CaluModal
        open={confirmSendMultipleRecords}
        setOpen={setConfirmSendMultipleRecords}
        title="Envio de recordatorios"
        hideCloseButton={true}
        disableEscapeKeyDown={true}
        keepOpen={true}
      >
        <LoaderCircle
          size={30}
          text={`Enviando ${indexTurn} de ${originalWappReminderAvailableToSend} ${reminderWord}`}
        />
      </CaluModal>
    </Grid>
  )
}

export default HeaderManagement
