import i18next from '@/configs/i18next'
import {
  addNewPatientCentre,
  deletePatientCentres,
  fetchAllPatientsCentre,
  fetchOrAddNewPatientCentre,
  fetchPatientCentreByDni,
  fetchPatientsCentreByInputSearch,
  fetchSinglePatient,
  putPatientCentres,
} from '@/services/patientCentres'
import { postTurn, putAturn } from '@/services/turns'
import {
  BACKUP_PATIENT_CENTRES,
  PATIENT_CENTRE_CREATED,
  PATIENT_CENTRE_DELETE,
  PATIENT_CENTRE_SELECTED,
  PATIENT_CENTRE_UPDATED,
  PATIENT_CENTRES_LOADING_UPDATED,
  PATIENT_CENTRES_UPDATED,
  RESET_PATIENT_CENTRE_SELECTED,
  RESET_PATIENT_CENTRE_STATE,
} from '@/store/patientCentres'
import { SELECTED_ONLINE_TURN_PATIENT, SELECTED_TURN_UPDATED } from '@/store/turns'
import { showToast } from '@/utils/toast'

const getAllPatientCentres = (centreId, page, limit) => async (dispatch) => {
  try {
    dispatch(PATIENT_CENTRES_LOADING_UPDATED(true))
    let { docs: patientCentres, ...paginationData } = await fetchAllPatientsCentre(centreId, page, limit)

    dispatch(PATIENT_CENTRES_UPDATED({ patientCentres, paginationData }))
  } catch (error) {
    dispatch(PATIENT_CENTRES_LOADING_UPDATED(false))
    const { message } = error
    console.log(message)
  }
}

const handlePaginationChangePatientCentres =
  (newPage, newLimit, searchText, selectedTag, selectedBirth) => async (dispatch, getState) => {
    const { centreProfile } = getState().centres
    const params = {
      centre: centreProfile?._id,
      page: newPage,
      limit: newLimit,
      search: searchText,
      patientTag: selectedTag === '-' ? null : selectedTag,
      birthStart: selectedBirth?.birthStart,
      birthFinish: selectedBirth?.birthFinish,
    }
    dispatch(PATIENT_CENTRES_LOADING_UPDATED(true))
    await dispatch(getPatientsCentreByInputSearch(params))
    dispatch(PATIENT_CENTRES_LOADING_UPDATED(false))
  }

const getPatientsCentreByInputSearch = (params) => async (dispatch) => {
  try {
    let { docs: patientCentres, ...paginationData } = await fetchPatientsCentreByInputSearch(params)
    dispatch(PATIENT_CENTRES_UPDATED({ patientCentres, paginationData }))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const getPatientsCentresForBackup = (params) => async (dispatch) => {
  try {
    let patientCentres = await fetchPatientsCentreByInputSearch(params)
    dispatch(BACKUP_PATIENT_CENTRES(patientCentres))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const getPatientsCentreByDni = (params) => async (dispatch) => {
  try {
    let { docs: patientCentre } = await fetchPatientCentreByDni(params)
    return patientCentre
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const getOrCreatePatientCentre = (turnPatientCentreData, user) => async (dispatch) => {
  try {
    let patientCentre = await fetchOrAddNewPatientCentre(turnPatientCentreData)
    let auxPatientCentre = { ...patientCentre }
    const userIsThePatient = user?.dni && user.dni === auxPatientCentre.dni
    if (!patientCentre.email && !patientCentre.mobile && userIsThePatient) {
      auxPatientCentre = {
        ...turnPatientCentreData,
        ...patientCentre,
      }

      auxPatientCentre = await putPatientCentres(auxPatientCentre)
    }

    dispatch(SELECTED_ONLINE_TURN_PATIENT(auxPatientCentre))
    return auxPatientCentre
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const postPatientCentres = (data) => async (dispatch) => {
  const patientTags = data?.patientTags
  const insurances = data?.insurances
  try {
    const newPatientCentres = await addNewPatientCentre(data)
    if (patientTags) newPatientCentres.patientTags = patientTags
    newPatientCentres.insurances = insurances
    dispatch(PATIENT_CENTRE_CREATED(newPatientCentres))
    showToast('Paciente agregado correctamente', 'success')
    return newPatientCentres
  } catch (error) {
    if (error.data?.code === 11000) {
      return showToast('El paciente ya existe', 'error')
    } else {
      return showToast('Error al crear el paciente', 'error')
    }
  }
}

const updatePatientAndCreateNewTurn =
  (infoToUpdatePatientCentre, infoToAddNewTurn, multipleTurnsCreated) => async (dispatch) => {
    const patientTags = infoToUpdatePatientCentre.patientTags
    const insurance = infoToAddNewTurn.insurance
    const service = infoToAddNewTurn.service
    const extraServices = infoToAddNewTurn.extraServices
    const patientCentre = infoToAddNewTurn.patientCentre
    const doctorCentre = infoToAddNewTurn.doctorCentre
    const machine = infoToAddNewTurn.machine
    const treatment = infoToAddNewTurn.treatment
    try {
      const patientUpdated = await putPatientCentres(infoToUpdatePatientCentre)
      patientUpdated.patientTags = patientTags

      let newTurn = {
        ...infoToAddNewTurn,
        patientCentre: patientUpdated,
      }

      const addNewTurn = await postTurn(newTurn)
      if (extraServices) addNewTurn.extraServices = extraServices
      if (service) addNewTurn.service = service
      if (treatment) addNewTurn.treatment = treatment
      if (patientCentre) addNewTurn.patientCentre = patientUpdated
      if (doctorCentre) addNewTurn.doctorCentre = doctorCentre
      if (machine) addNewTurn.machine = machine
      addNewTurn.patientCentre = patientUpdated
      addNewTurn.insurance = insurance

      if (!multipleTurnsCreated) {
        showToast(`${i18next.t('turnLabel.Sag', { ns: 'global' })} correctamente`, 'success', 5000)
      }
      return addNewTurn
    } catch (error) {
      const { message } = error
      console.error(message)
    }
  }

const createPatientAndCreateNewTurn = (postNewPatient, infoToAddNewTurn) => async (dispatch) => {
  const patientTags = postNewPatient.patientTags
  const insurance = infoToAddNewTurn.insurance
  const service = infoToAddNewTurn.service
  const extraServices = infoToAddNewTurn.extraServices
  const doctorCentre = infoToAddNewTurn.doctorCentre
  const machine = infoToAddNewTurn.machine
  const treatment = infoToAddNewTurn.treatment

  try {
    const newPatientCentre = await addNewPatientCentre(postNewPatient)
    newPatientCentre.patientTags = patientTags

    const newTurn = {
      ...infoToAddNewTurn,
      patientCentre: newPatientCentre,
    }

    const addNewTurn = await postTurn(newTurn)
    if (extraServices) addNewTurn.extraServices = extraServices
    if (service) addNewTurn.service = service
    if (treatment) addNewTurn.treatment = treatment
    addNewTurn.patientCentre = newPatientCentre
    if (doctorCentre) addNewTurn.doctorCentre = doctorCentre
    if (machine) addNewTurn.machine = machine
    addNewTurn.patientCentre = newPatientCentre
    addNewTurn.insurance = insurance

    showToast(`${i18next.t('turnLabel.Sag', { ns: 'global' })}`, 'success')
    return addNewTurn
  } catch (e) {
    console.log(e)
  }
}

const updatePatientCentreAndSelectedTurn =
  (infoToUpdatePatientCentre, infoToUpdateTurn, hideAlert) => async (dispatch) => {
    const machine = infoToUpdateTurn.machine
    const doctorCentre = infoToUpdateTurn.doctorCentre
    const treatment = infoToUpdateTurn.treatment
    const service = infoToUpdateTurn.service
    const extraServices = infoToUpdateTurn.extraServices
    const insurance = infoToUpdateTurn.insurance
    const consultationDuration = Number(infoToUpdateTurn.consultationDuration)
    const patientTags = infoToUpdatePatientCentre.patientTags

    try {
      let updatedPatient = await putPatientCentres(infoToUpdatePatientCentre)
      updatedPatient.patientTags = patientTags
      let updatedTurn = await putAturn(infoToUpdateTurn)
      updatedTurn.patientCentre = updatedPatient
      if (treatment) updatedTurn.treatment = treatment
      if (machine) updatedTurn.machine = machine
      if (doctorCentre) updatedTurn.doctorCentre = doctorCentre
      if (service) updatedTurn.service = service
      if (extraServices) updatedTurn.extraServices = extraServices
      if (insurance) updatedTurn.insurance = insurance
      if (consultationDuration) updatedTurn.consultationDuration = consultationDuration
      if (!hideAlert) {
        showToast(`${i18next.t('turnLabel.Sa', { ns: 'global' })}`, 'success', 5000)
      }
      return updatedTurn
    } catch (error) {
      const { message } = error
      console.log(message)
      showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
    }
  }

const deletePatientAdded = (id) => async (dispatch) => {
  try {
    await deletePatientCentres(id)
    dispatch(PATIENT_CENTRE_DELETE(id))
    showToast('Paciente Eliminado correctamente', 'success')
  } catch (error) {
    showToast('Error al eliminar paciente', 'error')
  }
}

const updatePatientCentres =
  (patientCentre, turn = null, hideAlert = false) =>
  async (dispatch) => {
    const insurances = patientCentre.insurances
    const patientTags = patientCentre.patientTags
    const extraFields = patientCentre.extraFields

    try {
      const patientUpdated = await putPatientCentres(patientCentre)
      patientUpdated.insurances = insurances
      patientUpdated.patientTags = patientTags
      patientUpdated.extraFields = extraFields

      if (turn) {
        // used in /agenda
        const auxTurn = {
          ...turn,
          patientCentre: patientUpdated,
        }

        dispatch(SELECTED_TURN_UPDATED(auxTurn))
      } else {
        // used in /patients
        dispatch(PATIENT_CENTRE_UPDATED(patientUpdated))
      }

      if (!hideAlert) {
        showToast('Paciente actualizado correctamente', 'success')
      }

      dispatch(PATIENT_CENTRE_SELECTED(patientUpdated))
      return patientUpdated
    } catch (error) {
      console.log(error)
      showToast('Error al actualizar el paciente', 'error')
    }
  }

const selectedPatientCentres = (id) => async (dispatch) => {
  try {
    const singlePatient = await fetchSinglePatient(id)
    dispatch(PATIENT_CENTRE_SELECTED(singlePatient))
    return singlePatient
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedPatientCentre = () => async (dispatch) => {
  try {
    dispatch(RESET_PATIENT_CENTRE_SELECTED(null))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const clearPatientCentres = () => async (dispatch) => {
  try {
    dispatch(RESET_PATIENT_CENTRE_STATE())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export {
  clearPatientCentres,
  createPatientAndCreateNewTurn,
  deletePatientAdded,
  getAllPatientCentres,
  getOrCreatePatientCentre,
  getPatientsCentreByDni,
  getPatientsCentreByInputSearch,
  getPatientsCentresForBackup,
  handlePaginationChangePatientCentres,
  postPatientCentres,
  resetSelectedPatientCentre,
  selectedPatientCentres,
  updatePatientAndCreateNewTurn,
  updatePatientCentreAndSelectedTurn,
  updatePatientCentres,
}
