import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { AiOutlinePlus } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import { defaultServices, turnDurations, weekDays, workTimeFrequencies } from '@/helpers/constants'
import DoctorCentresPopUp from '@/pages/AdministracionPage/components/popUp/DoctorCentresPopUp'
import PopUpService from '@/pages/ServicesPage/components/mainPopUp'
import { resetSelectedDoctorCentre } from '@/store/doctorCentres/actions'
import { resetSelectedService } from '@/store/services/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  styled,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import dayjs from 'dayjs'

import s from '../../styles.module.scss'

const FormCreateWT = ({
  control,
  handleSubmit,
  onSubmit,
  showDeferred,
  each15Days,
  selectedDay,
  setSelectedDay,
  errors,
  entity,
  setValue,
  startDateDeferred,
  setStartDateDeferred,
  finishDateDeferred,
  setFinishDateDeferred,
  showUpdateButton,
  weekDaysSelectedArray,
  setWeekDaysSelectedArray,
  uniqueDay,
  duplicateWT,
}) => {
  const { t } = useTranslation('global')
  const dispatch = useDispatch()

  const icon = <CheckBoxOutlineBlank fontSize="small" /> // Autocomplete Insurances
  const checkedIcon = <CheckBox fontSize="small" />

  const [openCreateService, setOpenCreateService] = useState(false)
  const [openCreateDoctorCentre, setOpenCreateDoctorCentre] = useState(false)
  const [openAutocompleteServices, setOpenAutocompleteServices] = useState(false)
  const [openAutocompleteDoctorCentres, setOpenAutocompleteDoctorCentres] = useState(false)
  const [checkAllServices, setCheckAllServices] = useState(false)
  const [checkAllDoctorCentres, setCheckAllDoctorCentres] = useState(false)

  const allDoctorsCentre = useSelector((state) => state.doctorCentres?.doctorCentres)
  const { allServices } = useSelector((state) => state.services)

  const servicesWithDefault = defaultServices.concat(allServices || [])

  const PopperStyledComponent = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'}`,
  }))

  useEffect(() => {
    if (checkAllServices) setValue('services', entity?.name ? allServices : servicesWithDefault)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allServices, servicesWithDefault])

  useEffect(() => {
    if (checkAllDoctorCentres) setValue('doctorCentres', allDoctorsCentre)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDoctorsCentre])

  const getServicesPopperComponent = (params) => (
    <PopperStyledComponent {...params}>
      <Box {...params} />
      <Divider />
      <div className={s.containerPopper}>
        <Box
          sx={{
            backgroundColor: 'white',
            height: '45px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <Checkbox
            checked={checkAllServices}
            onChange={checkAllChangeServices}
            id="check-all"
            onMouseDown={(e) => e.preventDefault()}
          />
          Marcar todos los servicios
        </Box>
        <Button
          endIcon={<AiOutlinePlus />}
          variant="contained"
          disableElevation
          onClick={() => handleCreateNewService()}
          onMouseDown={(e) => e.preventDefault()}
          className={s.createService}
          sx={{
            backgroundColor: 'white',
            height: '45px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          Crear servicio
        </Button>
      </div>
    </PopperStyledComponent>
  )

  const getDoctorCentresPopperComponent = (param) => (
    <PopperStyledComponent {...param}>
      <Box {...param} />
      <Divider />
      <div className={s.containerPopper}>
        <Box
          sx={{
            backgroundColor: 'white',
            height: '45px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <Checkbox
            checked={checkAllDoctorCentres}
            onChange={checkAllChangeDoctorCentres}
            id="check-all"
            onMouseDown={(e) => e.preventDefault()}
          />
          Marcar todos los profesionales
        </Box>
        <Button
          endIcon={<AiOutlinePlus />}
          variant="contained"
          disableElevation
          onClick={() => handleCreateNewDoctorCentre()}
          onMouseDown={(e) => e.preventDefault()}
          className={s.createService}
          sx={{
            backgroundColor: 'white',
            height: '45px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          Crear profesional
        </Button>
      </div>
    </PopperStyledComponent>
  )

  const handleCreateNewService = () => {
    dispatch(resetSelectedService())
    setOpenCreateService(true)
  }

  const handleCreateNewDoctorCentre = () => {
    dispatch(resetSelectedDoctorCentre())
    setOpenCreateDoctorCentre(true)
  }

  const checkAllChangeServices = (e) => {
    setCheckAllServices(e.target.checked)
    if (e.target.checked) {
      setValue('services', entity?.name ? allServices : servicesWithDefault)
    } else {
      setValue('services', [])
    }
  }

  const checkAllChangeDoctorCentres = (e) => {
    setCheckAllDoctorCentres(e.target.checked)
    if (e.target.checked) {
      setValue('doctorCentres', allDoctorsCentre)
    } else {
      setValue('doctorCentres', [])
    }
  }

  return (
    <div>
      <form className={s.containerForm} onSubmit={handleSubmit(onSubmit)}>
        <h4>Información del horario de atención</h4>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={10} container spacing={2}>
            <Grid item xs={12} container spacing={3} className="primero">
              <Grid item xs={12} lg={10} container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="frequency.code"
                    control={control}
                    render={({ field }) => (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel>Frecuencia</InputLabel>
                        <Select {...field} label="Frecuencia">
                          {workTimeFrequencies.map((f) => (
                            <MenuItem key={f.code} value={f.code}>
                              {f.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={4} className={s.containerWeekDay}>
                  {!each15Days && !uniqueDay && !showUpdateButton && !duplicateWT ? (
                    <div>
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        options={weekDays}
                        ListboxProps={{ style: { maxHeight: '15rem', fontSize: '12px' } }}
                        getOptionLabel={(option) => option?.name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props} key={option.code}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option?.name}
                          </li>
                        )}
                        isOptionEqualToValue={(option, value) => option?.code === value?.code}
                        fullWidth
                        value={weekDaysSelectedArray}
                        renderInput={(params) => <TextField {...params} label="Día(s)" variant="standard" />}
                        onChange={(event, selectedValues) => {
                          setWeekDaysSelectedArray(selectedValues)
                        }}
                      />
                    </div>
                  ) : showDeferred ? (
                    <>
                      <Controller
                        name="weekDay.code"
                        control={control}
                        render={({ field }) => (
                          <FormControl variant="standard" fullWidth>
                            <InputLabel>Día</InputLabel>
                            <Select {...field} label="Día">
                              {weekDays.map((d) => (
                                <MenuItem key={d.code} value={d.code}>
                                  {d.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        rules={{ required: true }}
                      />
                      {errors.weekDay?.code?.type === 'required' && (
                        <p className={s.formAlert}>Debe seleccionar un día</p>
                      )}
                    </>
                  ) : (
                    <DatePicker
                      label={each15Days ? 'Primer día' : 'Día'}
                      value={dayjs(selectedDay)}
                      format="DD/MM/YYYY"
                      onChange={(newValue) => {
                        setSelectedDay(newValue)
                      }}
                      slotProps={{
                        textField: {
                          required: true,
                          variant: 'standard',
                          fullWidth: true,
                        },
                      }}
                    />
                  )}
                </Grid>

                <Grid item xs={12} md={4}>
                  <Controller
                    name="turnDuration"
                    control={control}
                    render={({ field }) => (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel>Duración {t('turnLabel.sd')}</InputLabel>
                        <Select
                          {...field}
                          label={`Duración ${t('turnLabel.sd')}`}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 380,
                              },
                            },
                          }}
                        >
                          {turnDurations.map((d) => (
                            <MenuItem key={d.value} value={d.value}>
                              {d.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    rules={{ required: true }}
                  />
                  {errors.turnDuration?.type === 'required' && (
                    <p className={s.formAlert}>Debe seleccionar una duración</p>
                  )}
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={10} spacing={1} className={s.containerTime}>
                <Grid item xs={6}>
                  <p>Desde</p>
                  <div className={s.itemTimeInput}>
                    <Controller
                      name="startTime"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          type="time"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 60,
                          }}
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <p>Hasta</p>
                  <div className={s.itemTimeInput}>
                    <Controller
                      name="finishTime"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          type="time"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 60,
                            max: '23:59',
                          }}
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="services"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Autocomplete
                        {...field}
                        multiple
                        open={openAutocompleteServices}
                        onOpen={() => setOpenAutocompleteServices(true)}
                        onClose={() => setOpenAutocompleteServices(false)}
                        disableCloseOnSelect
                        ListboxProps={{
                          style: {
                            maxHeight: '200px',
                          },
                        }}
                        noOptionsText={'Sin opciones disponibles'}
                        options={entity?.name ? allServices || [] : servicesWithDefault}
                        getOptionLabel={(option) => `${capitalizeFirstLetters(option?.name)} `}
                        renderOption={(props, option, { selected }) => (
                          <li {...props} key={option?._id}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected || checkAllServices}
                            />
                            {option?.name}
                          </li>
                        )}
                        isOptionEqualToValue={(option, value) => option?._id === value?._id}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Servicio(s)"
                            variant="standard"
                            // onChange={(e) => setSearchText(e.target.value)}
                            sx={{
                              '&.Mui-InputBase-root': {
                                maxHeight: '100px',
                                overflow: 'scroll',
                              },
                              '&.MuiAutocomplete-endAdornment': {
                                right: '10px',
                              },
                              '&.MuiTextField-root': {
                                maxHeight: '170px',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                              },
                            }}
                          />
                        )}
                        onChange={(event, selectedValues, reason) => {
                          if (reason === 'selectOption') {
                            setValue('services', selectedValues)
                          } else if (reason === 'removeOption') {
                            setCheckAllServices(false)
                            setValue('services', selectedValues)
                          } else if (reason === 'clear') {
                            setValue('services', [])
                            setCheckAllServices(false)
                          }
                          // setSearchText('')
                        }}
                        PopperComponent={getServicesPopperComponent}
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>

            {entity?.name && (
              <Grid item xs={12}>
                <Controller
                  name="doctorCentres"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Autocomplete
                        {...field}
                        multiple
                        disableCloseOnSelect
                        open={openAutocompleteDoctorCentres}
                        onOpen={() => setOpenAutocompleteDoctorCentres(true)}
                        onClose={() => setOpenAutocompleteDoctorCentres(false)}
                        options={allDoctorsCentre}
                        getOptionLabel={(option) =>
                          `${capitalizeFirstLetters(option?.lastname)} ${capitalizeFirstLetters(option?.firstname)} `
                        }
                        renderOption={(props, option, { selected }) => (
                          <li {...props} key={option?._id}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option?.lastname} {option?.firstname}
                          </li>
                        )}
                        isOptionEqualToValue={(option, value) => option?._id === value?._id}
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Profesional(s)" variant="standard" />}
                        onChange={(event, selectedValues, reason) => {
                          if (reason === 'selectOption') {
                            setValue('doctorCentres', selectedValues)
                          } else if (reason === 'removeOption') {
                            setCheckAllDoctorCentres(false)
                            setValue('doctorCentres', selectedValues)
                          } else if (reason === 'clear') {
                            setValue('doctorCentres', [])
                            setCheckAllDoctorCentres(false)
                          }
                        }}
                        PopperComponent={getDoctorCentresPopperComponent}
                      />
                    </>
                  )}
                />
              </Grid>
            )}

            {showDeferred && (
              <Grid item xs={12} className={s.deferredContainer}>
                <Controller
                  name="deferred"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked)
                            }}
                          />
                        }
                        label={<p className={s.labelCheckbox}>{`¿Agregar Fecha Desde y Hasta?`}</p>}
                      />
                      {field.value && (
                        <Grid item xs={12} container spacing={2}>
                          <Grid item xs={12} sm>
                            <DatePicker
                              label="Desde"
                              value={dayjs(startDateDeferred)}
                              format="DD/MM/YYYY"
                              onChange={(newValue) => {
                                setStartDateDeferred(newValue)
                              }}
                              slotProps={{
                                textField: {
                                  variant: 'standard',
                                  fullWidth: true,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm>
                            <DatePicker
                              label="Hasta (se excluye)"
                              value={dayjs(finishDateDeferred)}
                              format="DD/MM/YYYY"
                              onChange={(newValue) => {
                                setFinishDateDeferred(newValue)
                              }}
                              slotProps={{
                                textField: {
                                  variant: 'standard',
                                  fullWidth: true,
                                },
                              }}
                              minDate={dayjs(startDateDeferred)}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              {each15Days ? (
                <p className={s.notVisible}>
                  Actualmente este tipo de horario laboral no es visible en {t('turnLabel.p')} online
                </p>
              ) : (
                <Controller
                  name="onlineTurns"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked)
                            }}
                          />
                        }
                        label={<p className={s.labelCheckbox}>{`Horario visible para ${t('turnLabel.p')} online:`}</p>}
                      />
                      <>
                        {field.value ? (
                          <span className={s.spanCheckboxYes}>
                            <i>
                              <AiFillCheckCircle /> Habilitado
                            </i>
                          </span>
                        ) : (
                          <span className={s.spanCheckboxNo}>
                            <i>
                              <AiFillCloseCircle /> Deshabilitado
                            </i>
                          </span>
                        )}
                      </>
                    </>
                  )}
                />
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} lg={2}>
            <div className={s.containerButtons}>
              {showUpdateButton && (
                <div>
                  <CaluButton color="primary" size="medium" type="submit" name="actualizar">
                    Actualizar
                  </CaluButton>
                </div>
              )}
              {duplicateWT && (
                <div>
                  <CaluButton color="primary" size="medium" type="submit" name="duplicar">
                    Guardar
                  </CaluButton>
                </div>
              )}
              {!showUpdateButton && !duplicateWT && (
                <div>
                  <CaluButton color="primary" size="medium" type="submit" name="guardar">
                    Guardar
                  </CaluButton>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </form>
      <PopUpService
        open={openCreateService}
        setOpen={setOpenCreateService}
        setOpenAutocompleteServices={setOpenAutocompleteServices}
      />
      <DoctorCentresPopUp
        openDoctorPopUp={openCreateDoctorCentre}
        setOpenDoctorPopUp={setOpenCreateDoctorCentre}
        setOpenAutocompleteDoctorCentres={setOpenAutocompleteDoctorCentres}
      />
    </div>
  )
}

export default FormCreateWT
