const allVideos = [
  {
    name: '¿Qué funcionalidades tiene Calu?',
    description:
      'Las funcionalidades principales de Calu son: agenda digital para organizar tus citas, sistema de recordatorio de citas automático por mail y semiautomático por Whatsapp, portal donde tus clientes/pacientes podrán reservar sus citas 24/7, módulo de base de datos de clientes/pacientes, módulo de caja para llevar registro de tus ingresos y egresos, y herramienta para la gestión de fichas o historia clínica de clientes/pacientes. ',
    validRoles: ['centre', 'doctor', 'secretary'],
  },
  {
    name: '¿Qué puedo personalizar y cómo?',
    description:
      'Todo es personalizable: la vista de la agenda puede ser diaria o semanal, los horarios de atención, la duración de las citas, los servicios que ofrezcas, los usuarios que trabajen en cada horario y los campos que incluyas en las fichas.',
    validRoles: ['centre', 'doctor', 'secretary'],
  },
  {
    name: '¿Cómo aprendo a usar y configurar Calu?',
    description:
      'La herramienta es muy fácil e intuitiva, pero es probable que necesites alguna orientación para aprovecharla al máximo. Podés ver los manuales en línea o contactar a soporte para que realicen una capacitación gratuita. Contacto a soporte: ',
    links: [
      {
        href: 'mailto:hola@calu.app',
        text: 'hola@calu.app',
      },
    ],

    validRoles: ['centre', 'doctor', 'secretary'],
  },
  {
    name: '¿Mi información está segura?',
    description:
      'La seguridad es prioridad para nosotros, por eso la almacenamos en los servidores de Amazon y realizamos copias de seguridad, para que tu información esté respaldada. En caso de que algún día quieras desuscribirte (esperemos que nunca pase) te enviamos una copia de todos tus datos.',
    validRoles: ['centre', 'doctor', 'secretary'],
  },
  {
    name: '¿Cuáles son los planes y medios de pago?',
    description:
      'Hay dos planes y el precio varía dependiendo la cantidad de agendas que utilices. El medio de pago es a través de Mercado Pago. Podés ver los precios en este link: ',
    validRoles: ['centre', 'doctor', 'secretary'],
    links: [
      {
        href: 'https://www.calu.app/planes',
        text: 'nuestros planes',
      },
    ],
  },
  {
    name: '¿Cómo contrato?',
    description: 'Para contratar podés contactarte con nosotros al Whatsapp haciendo click en este ',
    validRoles: ['centre', 'doctor', 'secretary'],
    links: [
      {
        href: 'https://wa.me/5492216175785',
        text: 'link',
      },
    ],
  },
]

export default allVideos
