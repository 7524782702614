import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'debts',
  initialState: {
    turnsDebts: null,
    paginationDataTurnsDebts: {
      page: 1,
      limit: 30,
    },
    isLoading: false,
    totalTurnsDebts: null,
    treatmentsDebts: null,
    paginationDataTreatmentsDebts: {
      page: 1,
      limit: 30,
    },
  },
  reducers: {
    GET_ALL_TURNS_DEBTS: (state, { payload }) => {
      state.turnsDebts = payload.turnsDebts
      state.paginationDataTurnsDebts = payload.paginationData
    },
    REMOVE_TURN_DEBT: (state, { payload }) => {
      state.turnsDebts = state.turnsDebts.filter((e) => e._id !== payload._id)
    },
    UPDATE_TURN_DEBT: (state, { payload }) => {
      state.turnsDebts = state.turnsDebts.map((turn) => {
        if (turn._id === payload._id) {
          turn = payload
        }
        return turn
      })
    },
    FETCH_TURNS_DEBTS_TOTALS: (state, { payload }) => {
      state.totalTurnsDebts = payload
    },
    LOADING: (state, { payload }) => {
      state.isLoading = payload
    },

    GET_ALL_TREATMENTS_DEBTS: (state, { payload }) => {
      state.treatmentsDebts = payload.treatmentsDebts
      state.paginationDataTreatmentsDebts = payload.paginationData
    },
    REMOVE_TREATMENT_DEBT: (state, { payload }) => {
      state.treatmentsDebts = state.treatmentsDebts.filter((e) => e._id !== payload._id)
    },
    UPDATE_TREATMENT_DEBT: (state, { payload }) => {
      state.treatmentsDebts = state.treatmentsDebts.map((e) => (e._id === payload._id ? payload : e))
    },
  },
})

export const {
  GET_ALL_TURNS_DEBTS,
  REMOVE_TURN_DEBT,
  FETCH_TURNS_DEBTS_TOTALS,
  LOADING,
  GET_ALL_TREATMENTS_DEBTS,
  REMOVE_TREATMENT_DEBT,
  UPDATE_TREATMENT_DEBT,
  UPDATE_TURN_DEBT,
} = slice.actions
export default slice.reducer
