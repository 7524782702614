import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'shifts',
  initialState: {
    dateShifts: [],
    selectedShift: null,
  },
  reducers: {
    SET_SHIFTS: (state, { payload }) => {
      state.dateShifts = payload
    },
    SET_SELECTED_SHIFT: (state, { payload }) => {
      state.selectedShift = payload
    },
  },
})

export const { SET_SHIFTS, SET_SELECTED_SHIFT } = slice.actions

export default slice.reducer
