import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { updatecentreConfiguration } from '@/store/centreConfigurations/actions'
import { updateCentre } from '@/store/centres/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from '../../styles.module.scss'

const MercadoPagoOptions = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const centreConfiguration = useSelector((state) => state.centreConfigurations.centreConfigurationDetail)

  const handleSubmit = async (data) => {
    setIsLoading(true)
    try {
      const auxCentreConfig = { ...centreDetail?.centreConfiguration, ...data }
      await dispatch(updatecentreConfiguration(auxCentreConfig))
      const auxCentre = {
        ...centreDetail,
        centreConfiguration: auxCentreConfig,
      }
      await dispatch(updateCentre(auxCentre))
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <ContainerForm
      className={s.containerFormMPOptions}
      onSubmit={(data) => handleSubmit(data)}
      defaultValues={{
        ...formDefaultValues,
        ...centreConfiguration,
      }}
      resolver={yupResolver(formValidationSchema)}
    >
      <FormContent isLoading={isLoading} />
    </ContainerForm>
  )
}

export default MercadoPagoOptions
