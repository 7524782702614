import React from 'react'
import { useSelector } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormCreateSecretaryCentre from '../formCreateSecretaryCentre'

const SecretaryCentrePopUp = ({ openSecretaryPopUp, setOpenSecretaryPopUp }) => {
  const secretaryCentreSelected = useSelector((state) => state.secretaryCentre?.secretaryCentreSelected)

  const handleCloseSecretaryPopUp = () => {
    setOpenSecretaryPopUp(false)
  }

  return (
    <div>
      <Dialog
        disableEscapeKeyDown={true}
        maxWidth="md"
        fullWidth
        open={openSecretaryPopUp}
        onClose={handleCloseSecretaryPopUp}
        scroll="paper"
      >
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={secretaryCentreSelected ? 'Configuración de asistente' : 'Nuevo asistente'}
            handleClose={handleCloseSecretaryPopUp}
          />
        </DialogTitle>
        <DialogContent style={{ padding: '0px' }} className="animate__animated animate__fadeIn">
          <FormCreateSecretaryCentre handleClose={handleCloseSecretaryPopUp} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default SecretaryCentrePopUp
