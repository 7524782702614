import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchBankTransferInfo = async () => {
  const route = `api/bankTransferInfo`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putBankTransferInfo = async (data) => {
  const route = `api/bankTransferInfo`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { fetchBankTransferInfo, putBankTransferInfo }
