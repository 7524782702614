import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import { Button, Grid, Paper } from '@mui/material'

import s from './styles.module.scss'

const CardInfo = ({ modifyItem, openAlertDelete, setOpenAlertDelete, deleteItem }) => {
  const { t } = useTranslation('global')

  const { insuranceSelected } = useSelector((state) => state.insurances)

  return (
    <Paper elevation={3} className={`${s.cardContainer} animate__animated animate__fadeIn`}>
      <Grid container>
        <Grid item sm={12} lg={8} className={s.containerInfo}>
          <p>
            <span className={s.bold}>Nombre: </span> <span> {insuranceSelected?.name}</span>
          </p>
          <p>
            <span className={s.bold}>Id: </span>
            <span>{insuranceSelected?._id}</span>
          </p>
        </Grid>
        <Grid
          item
          sm={12}
          lg={4}
          container
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <div className={s.containerButtons}>
            <div>
              <Button
                fullWidth={true}
                disableElevation
                variant="contained"
                className={s.editButton}
                onClick={modifyItem}
              >
                Modificar
              </Button>
            </div>

            <div>
              <Button
                className={s.deleteButton}
                fullWidth={true}
                disableElevation
                variant="contained"
                onClick={() => setOpenAlertDelete(!openAlertDelete)}
              >
                Eliminar
              </Button>
              <AlertDialogSlide
                open={openAlertDelete}
                setOpen={setOpenAlertDelete}
                textContent={`¿Está seguro que quiere eliminar este ${t('insuranceLabel.s')}?`}
                textBackButton="Cancelar"
                textConfirmationButton={'Eliminar'}
                onClose={deleteItem}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CardInfo
