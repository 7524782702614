import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import LoginForm from '@/components/FormLogin'
import FormRegister from '@/components/FormRegister'
import CaluButton from '@/components/styledComponents/CaluButton'
import { getOrCreatePatientCentre } from '@/store/patientCentres/actions'
import { RESET_ONLINE_TURNS } from '@/store/turns'
import { checkAvailabilityAndCreateOrUpdateTurn, cleanPatientCentreTurns, removeTurnById } from '@/store/turns/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { showToast } from '@/utils/toast'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'

import FormNewPatient from './components/FormCreatePatient'

import s from './styles.module.scss'

const QuestionCard = ({ userProfile }) => {
  const dispatch = useDispatch()
  const centreID = useParams().id
  const navigate = useNavigate()
  const { t } = useTranslation('global')

  const [valueAuthenticated, setValueAuthenticated] = useState('0')
  const [valueNoAuthenticated, setValueNoAuthenticated] = useState('')
  const [showCreatePatient, setShowCreatePatient] = useState(false)
  const [showLoginUser, setShowLoginUser] = useState(false)
  const [showFormRegister, setShowFormRegister] = useState(false)
  const [showCreateProfilePatient, setShowCreateProfilePatient] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { patientCentresTurns } = useSelector((state) => state.turns)
  const { centreTargetProfile, turnDetail } = useSelector((state) => state.turns?.onLineTurns)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const handleBeforeUnload = (event) => {
    event.preventDefault()
    event.returnValue = ''
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    if (!!userProfile) {
      setShowCreatePatient(false)
      setShowLoginUser(false)
      setShowFormRegister(false)
      setShowCreateProfilePatient(false)
    }
  }, [centreID, navigate, userProfile])

  const handleChangeAuthenticated = (e) => {
    setValueAuthenticated(e.target.value)
    if (e.target.value === '0') {
      setShowCreatePatient(false)
    }
    if (e.target.value === '1') {
      setShowCreatePatient(true)
    }
  }

  const handleChangeNoAuthenticated = (e) => {
    setValueNoAuthenticated(e.target.value)
    if (e.target.value === '0') {
      setShowFormRegister(true)
      setShowLoginUser(false)
    }
    if (e.target.value === '1') {
      setShowLoginUser(true)
      setShowFormRegister(false)
    }
  }

  const handleConfirmPatient = async () => {
    setIsLoading(true)
    mxp.track(mxp.events.centreLanding.steps.patientDataDone)
    if (userProfile?.patient || userProfile?.roles?.includes('patient')) {
      dispatch(cleanPatientCentreTurns())
      let dataPatientCentre = {
        ...(userProfile?.patient || {}),
        centre: centreID,
      }
      delete dataPatientCentre?._id //Aca estoy borrando el patient._id
      const auxPatientCentre = await dispatch(getOrCreatePatientCentre(dataPatientCentre, userProfile))
      let dataTurn = {
        ...turnDetail,
        centre: centreTargetProfile._id,
        dni: dataPatientCentre?.dni,
        patientCentre: auxPatientCentre,
      }

      try {
        const validateAndCreateOrUpdateTurn = await dispatch(checkAvailabilityAndCreateOrUpdateTurn(dataTurn))
        if (validateAndCreateOrUpdateTurn) {
          setIsLoading(false)
          navigate(`/turnos/info-turno/${centreID}`)
        } else {
          setIsLoading(false)
          dispatch(RESET_ONLINE_TURNS())
          navigate(`/centro/${centreTargetProfile._id}`)
        }
      } catch (error) {
        console.log(error)
        showToast('Error al crear el turno', 'error')
        setIsLoading(false)
      }
    }
    dispatch(cleanPatientCentreTurns())
    setShowCreateProfilePatient(true)
  }

  const handleDeletePendingTurns = async () => {
    if (patientCentresTurns?.length) {
      setIsLoading(true)
      const pendingTurn = patientCentresTurns?.filter(
        (turn) => turn?.state === 'pending' && (turn?.centre?._id || turn?.centre) === centreID,
      )
      await dispatch(removeTurnById(pendingTurn?.[0]?._id, true))
      setIsLoading(false)
    }
    await dispatch(RESET_ONLINE_TURNS())
    navigate(`/centro/${centreTargetProfile._id}`)
  }

  return (
    <div className={s.containerCard}>
      <div className={s.subContainer}>
        <p className={s.title}>
          <b>
            {!!userProfile
              ? `¿Quién va a ser el ${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}?`
              : '¿Ya usaste Calu anteriormente'}
          </b>
        </p>

        <FormControl className={s.containerRadioButtons}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={!!userProfile ? valueAuthenticated : valueNoAuthenticated}
            onChange={!!userProfile ? handleChangeAuthenticated : handleChangeNoAuthenticated}
          >
            <FormControlLabel
              value="0"
              control={<Radio size="small" color="success" />}
              label={
                !!userProfile
                  ? `${capitalizeFirstLetters(userProfile?.lastname)}, ${capitalizeFirstLetters(
                      userProfile?.firstname,
                    )} (Yo)`
                  : 'Soy nuevo en Calu'
              }
            />
            <FormControlLabel
              value="1"
              control={<Radio size="small" color="success" />}
              label={!!userProfile ? 'Otra persona' : 'Ya he usado Calu'}
            />
          </RadioGroup>
        </FormControl>
        {!!userProfile && !showCreatePatient && (
          <div className={s.containerButton}>
            <CaluButton color="primary" onClick={handleConfirmPatient} size="medium" loading={isLoading}>
              Continuar
            </CaluButton>
          </div>
        )}

        {showCreateProfilePatient && (
          <div className={s.createProfilePatient}>
            <b>
              Activá tu perfil de {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}{' '}
              <a href="/turnos">haciendo click aquí</a>
            </b>
          </div>
        )}
      </div>

      <div className={s.containerForms}>
        {showLoginUser && <LoginForm className={s.loginUser} />}

        {showFormRegister && (
          <div className={s.subContainer}>
            <FormRegister />
          </div>
        )}

        {showCreatePatient && <FormNewPatient />}
      </div>
      <div className={s.containerButton}>
        <CaluButton
          color="primary"
          inverted="true"
          onClick={handleDeletePendingTurns}
          size="medium"
          loading={isLoading}
        >
          Ver otros horarios disponibles
        </CaluButton>
      </div>
    </div>
  )
}

export default QuestionCard
