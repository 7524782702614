import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Button, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import PopUpEditService from './components/PopUpEditService'
import SearchBar from '../searchBar'

import s from '../../styles.module.scss'

const ServicesTab = ({ indexTab }) => {
  const [servicesFiltered, setServicesFiltered] = useState([])
  const [inputSearchServices, setInputSearchServices] = useState('')
  const [openPopUp, setOpenPopUp] = useState(false)
  const [selectedService, setSelectedService] = useState({})
  const [pageSize, setPageSize] = useState(20)

  const allServices = useSelector((state) => state.services?.allServices)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  }

  const columns = [
    {
      field: 'service',
      headerName: 'Servicio',
      minWidth: 500,
      headerClassName: 'header',
      sortable: false,
      ...stylesColumns,
    },
    {
      field: 'price',
      headerName: `Precio (${handleSetMoneySign(country)})`,
      minWidth: 300,
      headerClassName: 'header',
      editable: true,
      type: 'number',
      sortable: false,
      ...stylesColumns,
    },
    {
      field: 'button',
      headerName: '',
      minWidth: 80,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header',
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title="Editar">
          <Button onClick={() => handleClick(params?.row)} className={s.rowButton} tabIndex={params.hasFocus ? 0 : -1}>
            <EditOutlinedIcon />
          </Button>
        </Tooltip>
      ),
    },
  ]

  const rows = servicesFiltered?.map((service) => ({
    id: service._id,
    service: `${service.name.toUpperCase()}`,
    price: String(service?.price || 0),
    raw: service,
  }))

  const handleClick = (data) => {
    setOpenPopUp(true)
    setSelectedService(data)
  }

  const handlePaginationChange = ({ pageSize }) => {
    setPageSize(pageSize)
  }

  return (
    <div>
      <SearchBar
        inputSearch={inputSearchServices}
        setInputSearch={setInputSearchServices}
        prevState={allServices}
        setFilteredState={setServicesFiltered}
        indexTab={indexTab}
      />
      {allServices?.length === 0 ? (
        <p className={s.emptyList}>No hay servicios cargados aún</p>
      ) : (
        <div>
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: pageSize,
                },
              },
            }}
            rows={rows || []}
            columns={columns}
            pagination
            onPaginationModelChange={handlePaginationChange}
            pageSizeOptions={[20, 50, 100]}
            slotProps={{
              pagination: {
                labelRowsPerPage: 'Entradas por página:',
                labelDisplayedRows: (props) => `${props.from} - ${props.to} de ${props.count}`,
              },
            }}
            disableRowSelectionOnClick={true}
            rowHeight={45}
            autoHeight={true}
            onCellClick={(params) => handleClick(params?.row)}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            sx={{
              px: 0,
              '& .header': {
                fontSize: '14px',
              },
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
            }}
          />
        </div>
      )}

      <PopUpEditService open={openPopUp} setOpen={setOpenPopUp} selectedService={selectedService} />
    </div>
  )
}

export default ServicesTab
