import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'
import { reducePayload } from './reducePayload'
import { removeWrongData } from './removeWrongData'

const fetchMachines = async (centre) => {
  const route = `api/machines`
  const params = { centre }
  return API.get(route, { params })
    .then((response) => {
      let auxMachines = response.data?.map((m) => removeWrongData(m))

      return Promise.resolve(auxMachines)
    })
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchMachine = async (id) => {
  if (!id) return

  const route = `api/machines/${id}`
  return API.get(route)
    .then((response) => {
      const auxMachine = removeWrongData(response.data)

      return Promise.resolve(auxMachine)
    })
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewMachine = async (machine) => {
  const auxMachine = reducePayload(machine)

  const route = `api/machines`
  return API.post(route, auxMachine)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putMachine = async (machine) => {
  const auxMachine = reducePayload(machine)

  const route = `api/machines`
  return API.put(route, auxMachine)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteMachine = async (id) => {
  const route = `api/machines/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { addNewMachine, deleteMachine, fetchMachine, fetchMachines, putMachine }
