import React from 'react'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormCreateWT from '../formCreateWT'

const PopUpFormCreateWT = ({
  openPopUp,
  setOpenPopUp,
  control,
  handleSubmit,
  onSubmit,
  showDeferred,
  each15Days,
  selectedDay,
  setSelectedDay,
  errors,
  startTimeSelected,
  entity,
  setValue,
  startDateDeferred,
  setStartDateDeferred,
  finishDateDeferred,
  setFinishDateDeferred,
  showUpdateButton,
  selectedWorkTime,
  weekDaysSelectedArray,
  setWeekDaysSelectedArray,
  uniqueDay,
  duplicateWT,
}) => {
  const scroll = 'paper'

  const handleClosePopUp = () => {
    setOpenPopUp(false)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={openPopUp} onClose={handleClosePopUp}>
        <TitlePopUp
          textTitle={
            Object.entries(selectedWorkTime).length !== 0 ? `Editar Horario laboral` : `Crear nuevo horario laboral`
          }
          handleClose={handleClosePopUp}
        />
        <DialogContent dividers={scroll === 'paper'} style={{ margin: '0px', padding: '0px' }}>
          <FormCreateWT
            control={control}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            showDeferred={showDeferred}
            each15Days={each15Days}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            errors={errors}
            entity={entity}
            setValue={setValue}
            startDateDeferred={startDateDeferred}
            setStartDateDeferred={setStartDateDeferred}
            finishDateDeferred={finishDateDeferred}
            setFinishDateDeferred={setFinishDateDeferred}
            showUpdateButton={showUpdateButton}
            weekDaysSelectedArray={weekDaysSelectedArray}
            setWeekDaysSelectedArray={setWeekDaysSelectedArray}
            uniqueDay={uniqueDay}
            duplicateWT={duplicateWT}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpFormCreateWT
