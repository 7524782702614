import React from 'react'
import { useTranslation } from 'react-i18next'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { Grid } from '@mui/material'

import s from './styles.module.scss'

const NonexistentTurn = () => {
  const { t } = useTranslation('global')
  return (
    <div className={s.container}>
      <Grid className={s.content}>
        <i>
          <EventNoteIcon className={s.icon} />
        </i>
        <div className={s.text}>{t('turnLabel.Sl')} no existe.</div>
      </Grid>
    </div>
  )
}

export default NonexistentTurn
