import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  allMachines: [],
  machineSelected: null,
  isLoading: false,
  isLoaded: false,
}

const slice = createSlice({
  name: 'machines',
  initialState,
  reducers: {
    FETCH_MACHINES: (state, { payload }) => {
      state.allMachines = payload
      state.isLoaded = true
    },
    MACHINE_UPDATED: (state, { payload }) => {
      state.allMachines = state.allMachines.map((machine) => {
        if (machine._id === payload._id) {
          machine = payload
        }
        return machine
      })
      state.machineSelected = payload
    },
    MACHINE_DELETE: (state, { payload }) => {
      state.allMachines = state.allMachines.filter((machine) => machine._id !== payload)
      state.machineSelected = null
    },
    MACHINE_CREATED: (state, { payload }) => {
      let allMachines = [...(state.allMachines || []), payload]
      let sortMachines = allMachines.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      state.allMachines = sortMachines
      state.machineSelected = payload
    },
    MACHINE_SELECTED: (state, { payload }) => {
      state.machineSelected = payload
    },
    RESET_MACHINE_SELECTED: (state, { payload }) => {
      state.machineSelected = null
    },
    RESET_MACHINE_STATE: () => initialState,
  },
})

export const {
  FETCH_MACHINES,
  MACHINE_UPDATED,
  MACHINE_DELETE,
  MACHINE_CREATED,
  MACHINE_SELECTED,
  RESET_MACHINE_SELECTED,
  RESET_MACHINE_STATE,
} = slice.actions
export default slice.reducer
