import { saveAs } from 'file-saver'
// import Papa from 'papaparse'
import PropTypes from 'prop-types'
// import * as XLSX from 'xlsx/xlsx.mjs'

const downloadCsvData = (csvData, fileName) => {
  const csvBlob = new Blob([csvData], { type: 'text/csv' })
  saveAs(csvBlob, fileName)
}

// const parseCSVtoXLS = (file) => {
//   // convert CSV string to XLS array of arrays data
//   return new Promise((resolve) => {
//     Papa.parse(file, {
//       complete: (result) => {
//         resolve(result.data)
//       },
//     })
//   })
// }

// const s2ab = (s) => {
//   const buf = new ArrayBuffer(s.length)
//   const view = new Uint8Array(buf)
//   for (let i = 0; i < s.length; i++) {
//     view[i] = s.charCodeAt(i) & 0xff
//   }
//   return buf
// }

// const convertToExcelBlob = (xlsData) => {
//   const ws = XLSX.utils.json_to_sheet(xlsData, { skipHeader: true, dense: true })
//   const wb = XLSX.utils.book_new()
//   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

// FIXME: explota al generar XLXS de más de 30k filas
//   const xlsBlob = new Blob([s2ab(XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }))], {
//     type: 'application/octet-stream',
//   })

//   return xlsBlob
// }

// const downloadXlsData = async (csvData, fileName) => {
//   const xlsData = await parseCSVtoXLS(csvData)
//   const excelBlob = convertToExcelBlob(xlsData)
//   saveAs(excelBlob, fileName)
// }

const convertCSVToExcelAndDownload = async (csvData, fileName = null) => {
  if (!csvData) return

  downloadCsvData(csvData, fileName)
  // downloadXlsData(csvData, fileName)
}

export default convertCSVToExcelAndDownload

convertCSVToExcelAndDownload.propTypes = {
  csvFile: PropTypes.instanceOf(File).isRequired,
  fileName: PropTypes.string,
}
