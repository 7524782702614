import PropTypes from 'prop-types'

export const handleCalculateFinalPrice = (
  prevPrice,
  extraAmountType,
  extraAmountValue,
  setFinalPrice = null,
  tax = 0,
) => {
  let auxFinalPrice
  let auxTax = Number(tax)

  if (!prevPrice || prevPrice === '-') {
    if (setFinalPrice) {
      setFinalPrice('')
    }
    return
  }
  if (!extraAmountValue) {
    auxFinalPrice = Number(prevPrice)
  }
  if (extraAmountType === 'finalPrice' && extraAmountValue) {
    auxFinalPrice = Number(extraAmountValue)
  }
  if (extraAmountType === 'plusCash' && extraAmountValue) {
    auxFinalPrice = prevPrice + Number(extraAmountValue)
  }
  if (extraAmountType === 'minusCash' && extraAmountValue) {
    auxFinalPrice = prevPrice - Number(extraAmountValue)
  }
  if (extraAmountType === 'plusPercentage' && extraAmountValue) {
    auxFinalPrice = prevPrice + (prevPrice * Number(extraAmountValue)) / 100
  }
  if (extraAmountType === 'minusPercentage' && extraAmountValue) {
    auxFinalPrice = prevPrice - (prevPrice * Number(extraAmountValue)) / 100
  }

  auxFinalPrice = auxFinalPrice + auxFinalPrice * auxTax
  auxFinalPrice = Math.round(auxFinalPrice * 100) / 100

  if (setFinalPrice) {
    setFinalPrice(auxFinalPrice)
  }

  return auxFinalPrice
}

handleCalculateFinalPrice.propTypes = {
  prevPrice: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  extraAmountType: PropTypes.string.isRequired,
  extraAmountValue: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  setFinalPrice: PropTypes.func,
  tax: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
}
