import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import TitlePopUp from '@/components/TitlePopUp'
import i18next from '@/configs/i18next'
import useValidateURLId from '@/hooks/useValidateURLId'
import { fetchTurns } from '@/services/turns'
import { xlsxFromJson } from '@/utils/excel'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { showToast } from '@/utils/toast'
import { Dialog, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material'

import dayjs from 'dayjs'
import DateRangeWithCalendar from './component/DateRangeWithCalendar'
import { adapterTurnsReminderToExcel } from './adapterTurnsReminderToExcel'
import { adapterToExcel } from './excelDataAdapter'

import s from './component/styles.module.scss'

const DownloadTurns = ({ open, onClose }) => {
  const { t } = useTranslation('global')

  const centre = useSelector((state) => state.centres.centreProfile)
  const { allMachines } = useSelector((state) => state.machines)
  const allDoctorCentres = useSelector((state) => state.doctorCentres.doctorCentres)

  const [startSelectedDate, setStartSelectedDate] = useState(dayjs(new Date()).format())
  const [finishSelectedDate, setFinishSelectedDate] = useState(startSelectedDate)
  const centreID = useValidateURLId()

  const handleSubmit = async () => {
    mxp.track(mxp.events.agenda.turnsOptions.doneDownloadTurns)
    const fileProps = {
      fileName: `${t('turnLabel.P')}_${dayjs(startSelectedDate).format('DD-MM-YYYY')}_${dayjs(
        finishSelectedDate,
      ).format('DD-MM-YYYY')}`,
    }
    const turnsParams = {
      all: true,
      centre: centreID,
      start: dayjs(startSelectedDate).utc(true).hour(0).minute(0).second(0).format(),
      finish: dayjs(finishSelectedDate).utc(true).hour(23).minute(59).second(59).format(),
    }

    const turns = await fetchTurns(turnsParams)

    if (turns.length === 0) {
      showToast(`No hay ${i18next.t('turnLabel.p', { ns: 'global' })} entre las fechas seleccionadas`, 'warning')
    } else {
      xlsxFromJson(adapterToExcel(turns, centre, t, allDoctorCentres, allMachines), fileProps)
    }
  }

  const handleStartSelectedDate = (date) => {
    if (dayjs(finishSelectedDate).diff(dayjs(date), 'month') > 3) {
      return showToast('Máximo de 3 meses', 'error')
    }
    setStartSelectedDate(dayjs(date).format())
    setFinishSelectedDate(dayjs(date).format())
  }

  const handleFinishSelectedDate = (date) => {
    if (dayjs(startSelectedDate).diff(dayjs(date), 'month') > 3) {
      return showToast('Máximo de 3 meses', 'error')
    }
    setFinishSelectedDate(dayjs(date).format())
  }

  const calendarParams = {
    startSelectedDate,
    handleStartSelectedDate,
    finishSelectedDate,
    handleFinishSelectedDate,
  }

  const handleDownloadReminders = async () => {
    mxp.track(mxp.events.agenda.turnsOptions.doneDownloadReminders)
    const fileProps = {
      fileName: `recordat_${dayjs(startSelectedDate).format('DD-MM-YYYY')}_${dayjs(finishSelectedDate).format(
        'DD-MM-YYYY',
      )}`,
    }

    const turnsParams = {
      centre: centreID,
      start: dayjs(startSelectedDate).utc(true).hour(0).minute(0).second(0).format(),
      finish: dayjs(finishSelectedDate).utc(true).hour(23).minute(59).second(59).format(),
    }

    let turns = await fetchTurns(turnsParams)

    turns = turns.filter((turn) => {
      let hasMobilePhone = turn?.patientCentre && turn?.patientCentre?.mobile
      let isTurnStateWrong = ['absent', 'cancelled'].includes(turn?.state)

      return !isTurnStateWrong && hasMobilePhone
    })

    if (turns?.length === 0) {
      showToast(`No hay ${i18next.t('turnLabel.p', { ns: 'global' })} entre las fechas seleccionadas`, 'error')
    } else {
      xlsxFromJson(adapterTurnsReminderToExcel(turns, centre), fileProps)
    }
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={open} onClose={onClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle={`Descarga de planilla de ${t('turnLabel.p')}`} handleClose={onClose} />
        </DialogTitle>
        <DialogContent style={{ padding: '0px' }}>
          <DialogContent>
            <DialogContentText className={s.pInfo}>
              Para generar una planilla con la información de {t('turnLabel.pa')} seleccione Fecha de inicio y Fecha de
              fin, luego haga clic en el botón Descargar.
            </DialogContentText>
          </DialogContent>
          <DateRangeWithCalendar {...calendarParams} />
        </DialogContent>
        <Grid item xs={12} className={s.buttonContainer}>
          {centre?.centreConfiguration?.enableDownloadReminders && (
            <CaluButton
              color="primary"
              inverted="true"
              size="medium"
              onClick={() => {
                handleDownloadReminders()
              }}
            >
              Descargar recordatorios
            </CaluButton>
          )}

          <CaluButton
            color="primary"
            size="medium"
            onClick={() => {
              handleSubmit()
            }}
          >
            Descargar
          </CaluButton>
        </Grid>
      </Dialog>
    </div>
  )
}

export default DownloadTurns
