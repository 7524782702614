import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DividerSection from '@/components/DividerSection'
import { AutoCompleteField } from '@/components/Form/customFields/AutoCompleteField'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { CaluFormAutoComplete } from '@/components/formComponents/customFields/CaluFormAutoComplete'
import { getListInsurances } from '@/store/insurances/actions'
import { patchSelectedTurn } from '@/store/turns/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { getEntityServicesByDate, getMachineDoctorsByDate } from '@/utils/functions/getEntityWorkTimesPropByDate'
import { getUniqueListBy } from '@/utils/functions/getUniqueListBy'
import { Grid } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const TurnDataForm = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const { selectedTurn } = useSelector((state) => state.turns)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const centreInsurancesDetail = useSelector((state) => state?.centreInsurances?.centreInsurancesDetail)
  const { insurancesPrice: insurancesPriceList } = useSelector((state) => state.insurances)

  const { field: serviceField, fieldState: serviceFieldState } = useController({ name: 'service' })
  const { field: extraServicesField, fieldState: extraServiceFieldState } = useController({ name: 'extraServices' })
  const { field: insuranceField } = useController({ name: 'insurance' })
  const { field: insurancePriceField, fieldState: insurancePriceFieldState } = useController({ name: 'insurancePrice' })
  const { field: professionalField } = useController({ name: 'doctorCentre' })
  const { field: policyNumbersField } = useController({ name: 'patientCentre[policyNumbers][0]' })
  const { field: insurancePlanField } = useController({ name: 'patientCentre[insurancePlan][0]' })

  const [isFirstRunServiceInput, setIsFirstRunServiceInput] = useState(true)
  const [isFirstRunExtraServiceInput, setIsFirstRunExtraServiceInput] = useState(true)
  const [isFirstRunInsurancePriceField, setIsFirstRunInsurancePriceField] = useState(true)

  const centreInsurances =
    selectedTurn?.machine && !selectedTurn?.machine?.hideCentreInsurances
      ? centreInsurancesDetail
      : selectedTurn?.doctorCentre && !selectedTurn?.doctorCentre?.hideCentreInsurances
        ? centreInsurancesDetail
        : []
  const entitySelectedInsurances = selectedTurn?.machine
    ? selectedTurn?.machine?.insurances
    : selectedTurn?.doctorCentre?.insurances

  const insuranceList = [
    { _id: '-', name: 'Particular' },
    ...(centreInsurances?.insurances?.length > 0 ? centreInsurances?.insurances : []),
    ...(entitySelectedInsurances && entitySelectedInsurances?.length > 0 ? entitySelectedInsurances : []),
  ]
  const uniqueInsuranceList = insuranceList.filter((insurance, index) => {
    return insuranceList.findIndex((i) => i._id === insurance._id) === index
  })

  const [showExtraServiceInput, setShowExtraServiceInput] = useState(false)
  const [inputInsurances, setInputsInsurances] = useState(null)
  const filteredInsurancePrice = insurancesPriceList?.filter((i) => i?.insurance?._id === insuranceField?.value?._id)

  useEffect(() => {
    if (!insuranceField?.value || insuranceField?.value?._id === '-') {
      setInputsInsurances(null)
      return
    }
    setInputsInsurances(insuranceField.value)
    const policyIndex = selectedTurn?.patientCentre?.insurances?.findIndex((i) => i._id === insuranceField.value?._id)
    if (policyIndex !== -1) {
      policyNumbersField.onChange(selectedTurn?.patientCentre?.policyNumbers?.[policyIndex])
      insurancePlanField.onChange(selectedTurn?.patientCentre?.insurancePlan?.[policyIndex])
    } else {
      policyNumbersField.onChange('')
      insurancePlanField.onChange('')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTurn, insuranceField?.value])

  useEffect(() => {
    if (serviceField?.value) {
      if (
        serviceField?.value?._id === null ||
        serviceField?.value?._id === 'firstConsultation' ||
        serviceField?.value?._id === 'consultation'
      ) {
        return setShowExtraServiceInput(false)
      }
      if (!selectedTurn?.machine && getDoctorCentreExtraServices()?.length >= 1) return setShowExtraServiceInput(true)
      if (selectedTurn?.machine && getMachineExtraServices()?.length >= 1) return setShowExtraServiceInput(true)

      setShowExtraServiceInput(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceField?.value])

  useEffect(() => {
    if (!serviceField?.value) serviceField.onChange({ _id: null, name: '-' })
    if (!professionalField?.value) professionalField.onChange({ _id: null, name: '-' })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceField.value, professionalField.value])

  useEffect(() => {
    if (selectedTurn?.machine) return
    if (selectedTurn?.service) return
    if (!selectedTurn?.firstTime) {
      serviceField.onChange({ _id: 'consultation', name: 'Consulta' })
    }
    if (selectedTurn?.firstTime) {
      serviceField.onChange({ _id: 'firstConsultation', name: 'Primera consulta' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!serviceFieldState?.isDirty && isFirstRunServiceInput) return

    const serviceDuration = serviceField?.value?.duration || selectedTurn?.consultationDuration

    if (serviceDuration) {
      const formatFinishHour = dayjs(selectedTurn?.startHour).utc().add(serviceDuration, 'm').format()
      dispatch(
        patchSelectedTurn({
          service: serviceField?.value,
          consultationDuration: serviceDuration,
          finishHour: formatFinishHour,
        }),
      )
    }
    setIsFirstRunServiceInput(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceField.value, serviceFieldState.isDirty, isFirstRunServiceInput])

  useEffect(() => {
    let auxObject = {
      insurancePrice: insurancePriceField?.value,
    }
    if (isFirstRunInsurancePriceField) {
      auxObject.notRecalculateAmount = true
    }
    dispatch(patchSelectedTurn(auxObject))
    setIsFirstRunInsurancePriceField(false)
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurancePriceField.value, insurancePriceFieldState.isDirty])

  useEffect(() => {
    if (!extraServiceFieldState?.isDirty && isFirstRunExtraServiceInput) return

    const extraServicesDuration =
      extraServicesField?.value?.reduce((accum, p) => accum + (p.duration || 0), 0) +
      (serviceField?.value?.duration || 0)

    if (extraServicesDuration) {
      const formatFinishHour = dayjs(selectedTurn?.startHour).utc().add(extraServicesDuration, 'm').format()

      let auxObject = {
        extraServices: extraServicesField?.value,
        consultationDuration: extraServicesDuration,
        finishHour: formatFinishHour,
      }

      if (isFirstRunExtraServiceInput) {
        auxObject.notRecalculateAmount = true
      }
      setIsFirstRunExtraServiceInput(false)
      dispatch(patchSelectedTurn(auxObject))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraServicesField.value, serviceField.value, extraServiceFieldState.isDirty, isFirstRunExtraServiceInput])

  const getWorkTimeAvailable = () => {
    const noDoctor = {
      _id: null,
      name: '-',
    }
    const availableMachineDoctors = getMachineDoctorsByDate(selectedTurn?.startHour, selectedTurn?.machine)
    let sortedMachineDoctors = availableMachineDoctors?.sort((a, b) =>
      a?.lastname?.toLowerCase() > b?.lastname?.toLowerCase() ? 1 : -1,
    )
    sortedMachineDoctors.unshift(noDoctor)
    return sortedMachineDoctors
  }

  const getDoctorCentreAvailableServices = () => {
    const servicesBydayAvailable = getEntityServicesByDate(selectedTurn?.startHour, selectedTurn?.doctorCentre)
    return servicesBydayAvailable
  }

  const getDoctorCentreExtraServices = () => {
    const services = getDoctorCentreAvailableServices()
    const noRepeatServiceSelected = services?.filter((s) => s._id !== serviceField?.value?._id)
    const allServices = noRepeatServiceSelected
      .filter((s) => s._id !== 'consultation')
      .filter((s) => s._id !== 'firstConsultation')

    return allServices
  }

  const getMachineAvailableServices = () => {
    const noService = {
      _id: null,
      name: '-',
    }
    const servicesBydayAvailable = getEntityServicesByDate(selectedTurn.startHour, selectedTurn.machine)
    const services = [noService, ...servicesBydayAvailable]
    return services
  }

  const getMachineExtraServices = () => {
    const services = getMachineAvailableServices()
    const noRepeatServiceSelected = services?.filter((s) => s._id !== serviceField?.value?._id)
    return noRepeatServiceSelected
  }

  const widthServiceInput = () => {
    if (selectedTurn?.machine && serviceField?.value?._id === null) return 8
    if (selectedTurn?.machine && serviceField?.value?._id !== null) return 4
    if (!selectedTurn?.machine && showExtraServiceInput) return 6
    if (!selectedTurn?.machine && !showExtraServiceInput) return 12
  }

  const showPatientInsurances = () => {
    const patientInsurances = selectedTurn?.patientCentre?.insurances
    if (patientInsurances) {
      const auxInsurances = getUniqueListBy(patientInsurances, '_id')
      return auxInsurances?.map((e) => e.name)?.join(', ')
    }
    return patientInsurances
  }

  return (
    <>
      <DividerSection label={`Datos ${t('turnLabel.sd')}`} />
      {showPatientInsurances() ? (
        <Grid container>
          <Grid item className={s.insurancePatient}>{`El ${t(
            `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`,
          )} ya tiene ${t('insuranceLabel.paLoaded')}: ${showPatientInsurances()}`}</Grid>
        </Grid>
      ) : null}

      <Grid container>
        {selectedTurn?.machine ? (
          <Grid item xs={12} sm={4} className={s.marginTextFields}>
            <AutoCompleteField
              label="Profesional"
              name="doctorCentre"
              options={getWorkTimeAvailable()}
              multiple={false}
              className={s.inputWidth97}
            />
          </Grid>
        ) : null}
        <Grid item sm={widthServiceInput()} xs={12} className={s.marginTextFields}>
          <AutoCompleteField
            label="Seleccione un servicio"
            name="service"
            options={selectedTurn?.machine ? getMachineAvailableServices() : getDoctorCentreAvailableServices()}
            multiple={false}
            className={s.inputWidth98}
          />
        </Grid>
        {showExtraServiceInput && (
          <Grid item sm={widthServiceInput()} xs={12} className={s.marginTextFields}>
            <AutoCompleteField
              label="Servicios extras"
              name="extraServices"
              options={selectedTurn?.machine ? getMachineExtraServices() : getDoctorCentreExtraServices()}
              multiple={true}
              className={s.inputWidth97}
            />
          </Grid>
        )}
        <Grid item xs={filteredInsurancePrice?.length > 0 ? 6 : 12} className={s.marginTextFields}>
          <CaluFormAutoComplete
            name="insurance"
            stateredux={uniqueInsuranceList}
            functiontodispatch={getListInsurances}
            inputlabel={`${t('insuranceLabel.S')}`}
            className={s.inputWidth98}
          />
        </Grid>
        {filteredInsurancePrice?.length > 0 && (
          <Grid item xs={6} className={s.marginTextFields}>
            <AutoCompleteField
              name="insurancePrice"
              label="Bono/práctica"
              options={filteredInsurancePrice}
              multiple={false}
              className={s.inputWidth97}
            />
          </Grid>
        )}
        {inputInsurances && (
          <>
            <Grid item xs={6} className={s.marginTextFields}>
              <CustomField
                label="Información adicional del plan"
                name="patientCentre[insurancePlan][0]"
                type="text"
                className={s.inputWidth98}
              />
            </Grid>
            <Grid item xs={6} className={s.marginTextFields}>
              <CustomField
                label="Número de afiliado"
                name="patientCentre[policyNumbers][0]"
                type="text"
                className={s.inputWidth97}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} className={s.marginTextFields}>
          <CustomField
            name="comment"
            type="text"
            label="Comentarios"
            multiline
            onWheel={(e) => e.target.blur()}
            className={s.inputWidth98}
            inputProps={{ style: { textTransform: 'capitalize' } }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default TurnDataForm
