import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Slide } from '@mui/material'

import styles from './dialogueButton.module.scss'

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />
})

export default function AlertDialogSlide({ text, text2, text3, onClose }) {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handle = () => {
    onClose()
    setOpen(false)
  }

  return (
    <div className={styles.buttonContainer}>
      <Button className={styles.button} onClick={handleClickOpen}>
        {text}
      </Button>
      <Dialog
        open={open}
        // @ts-ignore
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText className={styles.textInformation} id="alert-dialog-slide-description">
            {text2}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.divButtons}>
          <Button className={styles.buttonCancel} variant="outlined" disableElevation onClick={handleClose}>
            Cancelar
          </Button>
          <Button className={styles.buttonAccept} variant="outlined" disableElevation onClick={handle}>
            {text3}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
