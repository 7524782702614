import dayjs from 'dayjs'

export const getWeekDays = (day) => {
  let week_start = dayjs(day).startOf('week').utc(true).format()
  let week_end = dayjs(day).endOf('week').utc(true).format()
  const diff = dayjs(week_end).diff(week_start, 'day')

  const arrayOfDays = []
  arrayOfDays.push(dayjs(week_start).format('YYYY-MM-DD'))
  for (let i = 0; i < diff; i++) {
    arrayOfDays.push(dayjs(week_start).add(1, 'days').format('YYYY-MM-DD'))
    week_start = dayjs(week_start).add(1, 'days').format('YYYY-MM-DD')
  }
  return arrayOfDays
}
