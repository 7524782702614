import React from 'react'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { toCustomTz } from '@/utils/functions/toCustomTz'

import s from './styles.module.scss'

const DebtAlert = ({ debt, country }) => {
  const lastPeriodUnpaid = debt?.totalDebt
    ? toCustomTz(debt?.lastVoucherUnpaid?.period, undefined, true, 'MMMM YYYY').toUpperCase()
    : null

  const lastDueDateUnpaid = debt?.totalDebt
    ? toCustomTz(debt?.lastVoucherUnpaid?.dueDate, undefined, true, 'DD-MM-YYYY')
    : null

  const lastAmountUnpaid = debt?.totalDebt ? debt?.lastVoucherUnpaid?.amount : null

  return (
    <div className={s.containerDebtAlert}>
      <div className={s.mainItemDebtAlert}>
        <p>
          <span>{`Tu suscripción para el periodo ${lastPeriodUnpaid} vence el ${lastDueDateUnpaid}. Monto a pagar`}</span>{' '}
          <span className={s.debtNumber}>
            {handleSetMoneySign(country)} {lastAmountUnpaid}
          </span>
        </p>
        <p>
          <span>Tu deuda actual es: </span>
          <span className={s.debtNumber}>
            {handleSetMoneySign(country)}
            {debt?.totalDebt}
          </span>
        </p>
      </div>
    </div>
  )
}

export default DebtAlert
