import dayjs from 'dayjs'

export const formatTime = (time, utc = true, tzArg = false) => {
  // Time: 17:00
  let currentDate = utc ? dayjs.utc() : dayjs()

  if (tzArg) {
    currentDate = currentDate.tz('America/Argentina/Buenos_Aires')
  }

  let newFormatTime = currentDate.format(`YYYY-MM-DDT${time}:00.000Z`)

  return newFormatTime // newFormatTime: 2021-12-16T17:00:00.000Z
}
