import { Controller, useController } from 'react-hook-form'
import { FormControl, Slider } from '@mui/material'

import PropTypes from 'prop-types'

export const SliderField = ({ fullWidth, label, name, disabled, step, min, max, initValue, marks }) => {
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({ name })

  return (
    <Controller
      name={name}
      render={() => (
        <FormControl fullWidth={fullWidth} variant="standard">
          <div>{label}</div>
          <Slider
            aria-label={name}
            value={value || initValue}
            valueLabelDisplay="auto"
            step={step}
            min={min}
            max={max}
            disabled={disabled}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            marks={marks}
          />
        </FormControl>
      )}
    />
  )
}

SliderField.propTypes = {
  fullWidth: PropTypes.bool,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  initValue: PropTypes.number,
  marks: PropTypes.array,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
}

SliderField.defaultProps = {
  fullWidth: false,
  step: 5,
  min: 0,
  max: 100,
  initValue: 30,
  disabled: false,
  marks: [],
}
