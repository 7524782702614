import i18next from '@/configs/i18next'
import {
  addNewInsurance,
  deleteInsurance,
  fetchInsurancesPrice,
  fetchSingleInsurance,
  getInsurances,
  putInsurance,
} from '@/services/insurances'
import {
  INSURANCE_CREATED,
  INSURANCE_DELETE,
  INSURANCE_SELECTED,
  INSURANCE_UPDATED,
  INSURANCES_PRICE,
  INSURANCES_UPDATED,
  RESET_INSURANCE_SELECTED,
} from '@/store/insurances'
import { showToast } from '@/utils/toast'

const getListInsurances = (params) => async (dispatch) => {
  try {
    if (!params?.noPaginate) {
      params.paginate = true
    }
    if (params?.paginate) {
      let insurancesList = []
      if (!params?.search?.length) {
        dispatch(INSURANCES_UPDATED({ insurancesList }))
      } else {
        const { docs: insurancesList } = await getInsurances(params)
        dispatch(INSURANCES_UPDATED({ insurancesList }))
      }
    } else {
      const insurancesList = await getInsurances(params)
      dispatch(INSURANCES_UPDATED({ insurancesList }))
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedInsurance = (id) => async (dispatch) => {
  try {
    const singleInsurance = await fetchSingleInsurance(id)
    dispatch(INSURANCE_SELECTED(singleInsurance))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}
const getListInsurancesPrice = (centreId) => async (dispatch) => {
  try {
    const singleInsurance = await fetchInsurancesPrice(centreId)
    dispatch(INSURANCES_PRICE(singleInsurance))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const postNewInsurance = (data) => async (dispatch) => {
  try {
    const newInsurance = await addNewInsurance(data)
    dispatch(INSURANCE_CREATED(newInsurance))
    dispatch(INSURANCE_SELECTED(newInsurance))
    showToast(`${i18next.t('insuranceLabel.Sc', { ns: 'global' })}`, 'success')

    return newInsurance
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const updateInsurance = (data) => async (dispatch) => {
  try {
    const insuranceUpdated = await putInsurance(data)
    dispatch(INSURANCE_UPDATED(insuranceUpdated))

    if (insuranceUpdated) {
      showToast(`${i18next.t('insuranceLabel.Sm', { ns: 'global' })}`, 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const deleteInsuranceAdded = (id) => async (dispatch) => {
  try {
    await deleteInsurance(id)
    dispatch(INSURANCE_DELETE(id))
    showToast(`${i18next.t('insuranceLabel.Sel', { ns: 'global' })}`, 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedInsuranceByList = (insurance) => async (dispatch) => {
  try {
    dispatch(INSURANCE_SELECTED(insurance))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedInsurance = () => async (dispatch) => {
  try {
    dispatch(RESET_INSURANCE_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export {
  deleteInsuranceAdded,
  getListInsurances,
  getListInsurancesPrice,
  postNewInsurance,
  resetSelectedInsurance,
  selectedInsurance,
  selectedInsuranceByList,
  updateInsurance,
}
