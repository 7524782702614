import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import { useUser } from '@/context/UserProvider'
import { getSecretaryCentre, updateSecretaryCentre } from '@/store/secretaryCentre/actions'
import Checkbox from '@mui/material/Checkbox'

import CashManagmentOptions from './CashManagmentOptions'

import s from './styles.module.scss'

const TabAccessToCashManagment = () => {
  const dispatch = useDispatch()
  const { defaultCentre } = useUser()
  const [checkedSecretary, setCheckedSecretary] = useState([])
  const [isLoading, setIsLoading] = useState([])
  const allSecretaries = useSelector((state) => state.secretaryCentre?.allSecretaries)

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setInit = async () => {
    setIsLoading(true)
    await dispatch(getSecretaryCentre(defaultCentre))
    setIsLoading(false)
  }

  const handleToggleSecretaries = (secretary) => async () => {
    const currentIndex = checkedSecretary.indexOf(secretary)
    let newChecked = [...checkedSecretary]
    if (currentIndex === -1) {
      newChecked.push(secretary)
      let auxSecretary = {
        ...secretary,
        hasCashboxAccess: true,
      }

      dispatch(updateSecretaryCentre(auxSecretary))
    } else {
      newChecked.splice(currentIndex, 1)
      let auxSecretary = {
        ...secretary,
        hasCashboxAccess: false,
      }
      dispatch(updateSecretaryCentre(auxSecretary))
    }
    setCheckedSecretary(newChecked)
  }

  useEffect(() => {
    const auxCheckedSecretary = []
    if (allSecretaries) {
      allSecretaries.forEach((k) => {
        if (k.hasCashboxAccess === true) {
          auxCheckedSecretary.push(k)
        }
      })
    }
    setCheckedSecretary(auxCheckedSecretary)
  }, [allSecretaries])

  return (
    <div className={s.mainContainer}>
      <div className={s.textDetail}>
        <span>
          Sólo tendrán acceso a Gestión de caja <b>asistentes habilitados</b> a continuación.
        </span>
      </div>

      <div className={s.containerSecretaryList}>
        {!isLoading ? (
          <>
            {allSecretaries?.map((secretary) => (
              <div key={secretary._id} className={s.secretaryItem}>
                <div
                  className={s.nameSecretary}
                >{`${secretary?.lastname?.toUpperCase()}, ${secretary?.firstname?.toUpperCase()}`}</div>
                <div>
                  <Checkbox
                    edge="end"
                    onChange={handleToggleSecretaries(secretary)}
                    checked={checkedSecretary.indexOf(secretary) !== -1}
                  />
                </div>
              </div>
            ))}
          </>
        ) : (
          <LoaderCircle size={40} />
        )}
      </div>

      <CashManagmentOptions />
    </div>
  )
}

export default TabAccessToCashManagment
