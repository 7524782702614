import React from 'react'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormCreateTemplateTreatment from '../formCreateTemplateTreatment'

const PopUpTemplateTreatment = ({ open, setOpen, treatmentSelected }) => {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog disableEscapeKeyDown={true} maxWidth="md" open={open} fullWidth onClose={handleClose} scroll="paper">
      <DialogTitle style={{ padding: '0px' }}>
        <TitlePopUp
          textTitle={treatmentSelected ? 'Modificar tratamiento' : 'Nuevo tratamiento'}
          handleClose={handleClose}
        />
      </DialogTitle>
      <DialogContent style={{ padding: '0px' }} className="animate__animated animate__fadeIn">
        <FormCreateTemplateTreatment handleClose={handleClose} treatmentSelected={treatmentSelected} />
      </DialogContent>
    </Dialog>
  )
}

export default PopUpTemplateTreatment
