import { addNewFieldHC, deleteFieldHC, putFieldHC } from '@/services/clinicHistoryField'
import {
  FETCH_FIELDS_HC,
  FIELD_HC_CREATED,
  FIELD_HC_DELETE,
  FIELD_HC_SELECTED,
  FIELD_HC_UPDATED,
  RESET_FIELD_HC_SELECTED,
} from '@/store/clinicHistoryField'
import { showToast } from '@/utils/toast'

const getListFieldsHC = (AllTemplatesHCFields) => async (dispatch) => {
  try {
    dispatch(FETCH_FIELDS_HC(AllTemplatesHCFields))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const postNewFieldHC = (data) => async (dispatch) => {
  try {
    const newFieldHC = await addNewFieldHC(data)
    dispatch(FIELD_HC_CREATED(newFieldHC))
    dispatch(FIELD_HC_SELECTED(newFieldHC))
    showToast('Campo de HC agregado correctamente', 'success')

    return newFieldHC
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const updateFieldHC = (data) => async (dispatch) => {
  try {
    const fieldHCUpdated = await putFieldHC(data)
    dispatch(FIELD_HC_UPDATED(fieldHCUpdated))

    if (fieldHCUpdated) {
      showToast('Campo de HC actualizado correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const deleteFieldHCAdded = (id) => async (dispatch) => {
  try {
    await deleteFieldHC(id)
    dispatch(FIELD_HC_DELETE(id))
    showToast('Campo de HC eliminado correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedFieldHC = (fieldHC) => async (dispatch) => {
  try {
    dispatch(FIELD_HC_SELECTED(fieldHC))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedFieldHC = () => async (dispatch) => {
  try {
    dispatch(RESET_FIELD_HC_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export { deleteFieldHCAdded, getListFieldsHC, postNewFieldHC, resetSelectedFieldHC, selectedFieldHC, updateFieldHC }
