import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { useUser } from '@/context/UserProvider'
import { changeUserPassword } from '@/store/users/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

const FormChangeUserPassword = () => {
  const { user } = useUser()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (data) => {
    setIsLoading(true)
    const userId = user._id
    const passwordChanged = await dispatch(changeUserPassword(data, userId))
    if (passwordChanged) {
      navigate('/')
    }
    setIsLoading(false)
  }

  return (
    <ContainerForm
      onSubmit={(data) => handleSubmit(data)}
      defaultValues={{ ...formDefaultValues }}
      resolver={yupResolver(formValidationSchema)}
    >
      <FormContent isLoading={isLoading} />
    </ContainerForm>
  )
}

export default FormChangeUserPassword
