import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  doctorCentres: [],
  paginationData: {
    page: 1,
    limit: 30,
  },
  doctorCentreSelected: null,
  isLoading: false,
  isLoaded: false,
  totalDoctorCentres: [],
}

const slice = createSlice({
  name: 'doctorCentres',
  initialState,
  reducers: {
    DOCTOR_CENTRES_UPDATED: (state, { payload }) => {
      state.doctorCentres = payload
      state.isLoaded = true
    },
    TOTAL_DOCTOR_CENTRES: (state, { payload }) => {
      state.totalDoctorCentres = payload
    },
    DOCTOR_CENTRES_PAGINATION: (state, { payload }) => {
      state.doctorCentres = payload.doctorCentres
      state.paginationData = payload.paginationData
      state.isLoaded = true
    },
    DOCTOR_CENTRE_UPDATED: (state, { payload }) => {
      state.doctorCentres = state.doctorCentres.map((doctor) => {
        if (doctor._id === payload._id) {
          doctor = payload
        }
        return doctor
      })
      state.totalDoctorCentres = state.totalDoctorCentres.map((doctor) => {
        if (doctor._id === payload._id) {
          doctor = payload
        }
        return doctor
      })
      state.doctorCentreSelected = payload
    },
    DOCTOR_CENTRE_DELETE: (state, { payload }) => {
      state.doctorCentres = state.doctorCentres.filter((doctor) => doctor._id !== payload)
      state.doctorCentreSelected = null
    },
    DOCTOR_CENTRE_CREATED: (state, { payload }) => {
      let allDoctors = [...(state.doctorCentres || []), payload]
      let sortDoctors = allDoctors.sort((a, b) => (a.lastname.toLowerCase() > b.lastname.toLowerCase() ? 1 : -1))
      state.doctorCentres = sortDoctors
      state.doctorCentreSelected = payload
    },
    DOCTOR_CENTRE_SELECTED: (state, { payload }) => {
      state.doctorCentreSelected = payload
    },
    RESET_DOCTOR_CENTRE_SELECTED: (state, { payload }) => {
      state.doctorCentreSelected = null
    },
    RESET_DOCTOR_CENTRE_STATE: () => initialState,
  },
})

export const {
  DOCTOR_CENTRES_UPDATED,
  DOCTOR_CENTRE_UPDATED,
  DOCTOR_CENTRE_DELETE,
  DOCTOR_CENTRE_SELECTED,
  DOCTOR_CENTRE_CREATED,
  RESET_DOCTOR_CENTRE_SELECTED,
  DOCTOR_CENTRES_PAGINATION,
  RESET_DOCTOR_CENTRE_STATE,
  TOTAL_DOCTOR_CENTRES,
} = slice.actions
export default slice.reducer
