import dayjs from 'dayjs'

export const adapterToExcel = (debt) => {
  const turnDebt = {
    Paciente: debt.raw.patientCentre.lastname.toUpperCase() + ', ' + debt.raw.patientCentre.firstname.toUpperCase(),
    Teléfono: debt.raw.patientCentre.mobile || debt.raw.patientCentre.telephone,
    'Fecha cita': dayjs(debt.raw.startHour).utc().format('DD-MM-YYYY'),
    Deuda:
      debt?.raw.debt > 0 && !debt?.raw.insuranceAuthorization
        ? `${debt?.raw.debt} | Bono sin autorización`
        : debt?.raw.debt === 0 && !debt?.raw.insuranceAuthorization
        ? `Bono sin autorización`
        : `${debt?.raw.debt}`,
  }

  return turnDebt
}
