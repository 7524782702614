import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import { getCentreTargetAvailabilities } from '@/store/centreAvailability/actions'
import { cancelTurnTimeToReprogram } from '@/store/turns/actions'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { Grid } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const ReprogramTurnDate = ({ singleTurn, setStartHour, errorMsg }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const [cannotRescheduleTurnMsg, setCannotRescheduleTurnMsg] = useState(null)

  const turn = {
    ...singleTurn,
  }
  delete turn.startHour

  const showDateTurn = () => {
    const date = dayjs(singleTurn.startHour).utc().format('dddd D MMMM')
    return date
  }
  const showHourTurn = () => {
    const hourTurn = dayjs(singleTurn.startHour).utc().format('HH:mm')
    return hourTurn
  }

  const reprogramTurn = async () => {
    if (errorMsg === null) {
      let service
      if (!singleTurn.service && singleTurn.firstTime) {
        service = { _id: 'firstConsultation', name: 'Primera consulta' }
      } else if (!singleTurn.service && !singleTurn.firstTime) {
        service = { _id: 'consultation', name: 'Consulta' }
      } else {
        service = singleTurn.service
      }
      const aux = {
        entityId: singleTurn?.machine ? singleTurn?.machine?._id : singleTurn?.doctorCentre?._id,
        entityType: singleTurn?.machine ? 'machine' : 'doctorCentre',
        selectedService: service,
      }

      try {
        dispatch(cancelTurnTimeToReprogram(singleTurn?._id))
        dispatch(getCentreTargetAvailabilities(singleTurn?.centre, aux))
        setStartHour(null)
      } catch (error) {
        console.log(error)
      }
    } else {
      setCannotRescheduleTurnMsg(errorMsg)
    }
  }
  return (
    <>
      {singleTurn && (
        <div className={s.paperContainer}>
          <Grid>
            <h3 className={s.title}>
              <p>
                <i>
                  <EventNoteIcon className={s.icon} />
                </i>
              </p>
              <span className={s.fontColor}>{t('turnLabel.Sl')} ya tiene seleccionada una fecha y horario.</span>
            </h3>
          </Grid>

          <h4>
            <b className={s.fontColor}>{`${showDateTurn()} a las ${showHourTurn()} hs`}</b>
          </h4>

          <Grid>
            <Grid className={s.errorMsg}>{cannotRescheduleTurnMsg}</Grid>
            <Grid className={s.containerButtonReprogramTurn}>
              <CaluButton color="primary" size="large" onClick={reprogramTurn} disabled={cannotRescheduleTurnMsg}>
                Reprogramar
              </CaluButton>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default ReprogramTurnDate
