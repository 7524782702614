import { postFile } from '@/controllers/postFile'
import { fetchImage } from '@/helpers/fetchImage'
import { toCustomTz } from '@/utils/functions/toCustomTz'

import jsPDF from 'jspdf'

const uploadFile = async (file) => {
  const formData = new FormData()

  formData.append('file', file, file?.name)
  const fileGenerated = await postFile(formData)

  try {
    return fileGenerated
  } catch (e) {
    console.log(e)
  }
}

const addPrescriptionPdfDefaultContent = (p, doc) => {
  // header
  doc.setFontSize(8)
  doc.setTextColor(149, 165, 166)
  doc.setFont('Helvetica', 'bold')
  doc.text('Identificador '.toUpperCase() + p._id.slice(-8).toUpperCase(), 440, 50)

  // centre info
  doc.setFontSize(10)
  doc.setTextColor(52, 73, 94)
  doc.setFont('Helvetica', 'bolditalic')
  doc.text(p.centreName.toUpperCase(), 50, 100)
  doc.setFont('Helvetica', 'italic')

  if (p.centreStreet && p.centreCity) {
    doc.text(p.centreStreet + ', ' + p.centreCity, 50, 114)
  }

  let _centrePhoneEmail = ''
  if (p.centrePhone) {
    _centrePhoneEmail += 'Tel: '
    _centrePhoneEmail += p.centrePhone
  }

  if (p.centreEmail) {
    _centrePhoneEmail += ' — '
    _centrePhoneEmail += p.centreEmail
  }
  doc.text(_centrePhoneEmail, 50, 128)

  doc.setFontSize(16)
  doc.setFont('Helvetica', 'bold')
  if (p.doctorFullName) {
    doc.text('Receta - Dr. ' + p.doctorFullName.toUpperCase(), 40, 170)
  }
  if (p.creatorFullName) {
    doc.text('Receta - Dr. ' + p.creatorFullName.toUpperCase(), 40, 170)
  }
  doc.line(40, 175, 546, 175)

  // patient info
  doc.setFontSize(10)
  doc.setFillColor(240, 240, 240)
  doc.rect(48, 191, 170, 12, 'F')
  doc.setFont('Helvetica', 'bold')
  doc.text('Apellido y Nombre del beneficiario:', 50, 200)

  doc.setFont('Helvetica', 'normal')
  doc.text(doc.splitTextToSize(p.patientFullName.toUpperCase(), 230), 224, 200)

  doc.setFillColor(240, 240, 240)
  doc.rect(448, 191, 92, 12, 'F')
  doc.setFont('Helvetica', 'bold')
  doc.text('Fecha de Emisión:', 450, 200)
  doc.setFont('Helvetica', 'normal')
  doc.text(toCustomTz(p.date, undefined, false, 'DD/MM/YYYY'), 450, 212)

  doc.setFillColor(240, 240, 240)
  doc.rect(48, 211, 30, 12, 'F')
  doc.setFont('Helvetica', 'bold')
  doc.text('DNI:', 50, 220)
  doc.setFont('Helvetica', 'normal')

  let _dniText = '-'
  if (p.patientDni) {
    _dniText = p.patientDni
  }
  doc.text(_dniText, 92, 220)

  doc.setFillColor(240, 240, 240)
  doc.rect(48, 231, 54, 12, 'F')
  doc.setFont('Helvetica', 'bold')
  doc.text('Cobertura:', 50, 240)
  doc.setFont('Helvetica', 'normal')

  var _insuranceText = '-'
  if (p.patientInsurance) {
    _insuranceText = p.patientInsurance.toUpperCase()

    if (p.patientInsurancePlan) {
      _insuranceText += ' - '
      _insuranceText += p.patientInsurancePlan
    }

    if (p.patientPolicyNumber) {
      doc.text('Nº Beneficiario: ' + p.patientPolicyNumber.toUpperCase(), 106, 255)
    }
  }
  doc.text(_insuranceText, 106, 240)

  // prescription box
  doc.setDrawColor(160, 160, 160)
  doc.setFillColor(245, 245, 245)
  doc.rect(40, 270, 505, 400, 'FD')

  // doctor info
  doc.setFontSize(10)
  doc.setFont('Helvetica', 'bold')
  if (p.doctorFullName) {
    doc.text('Dr. ' + p.doctorFullName.toUpperCase(), 300, 715)
  }
  if (p.creatorFullName) {
    doc.text('Dr. ' + p.creatorFullName.toUpperCase(), 300, 715)
  }

  if (p.doctorSpeciality) {
    doc.setFont('Helvetica', 'normal')
    doc.text(p.doctorSpeciality, 300, 730)
  }

  doc.setFont('Helvetica', 'bold')
  if (p.doctorNumber) {
    doc.text('MP ' + p.doctorNumber, 300, 745)
  }
  if (p.centreNumber) {
    doc.text('MP ' + p.centreNumber, 300, 745)
  }

  // footer
  doc.setFontSize(8)
  doc.setFont('Helvetica', 'bold')
  doc.setTextColor(39, 174, 96)
  doc.text('calu.app — Sistema de gestión de centros de salud', 40, 800)

  // defaults
  doc.setFontSize(10)
  doc.setFont('Helvetica', 'normal')
  doc.setTextColor(44, 62, 80)
}

const generatePrescriptionPdf = async (data) => {
  const doc = new jsPDF('p', 'pt', 'A4')

  doc.setFont('Helvetica', 'bold')
  doc.setFontSize(12)
  doc.setTextColor(44, 62, 80)

  addPrescriptionPdfDefaultContent(data, doc)

  if (data?.centreLogo) {
    try {
      let centreLogo = await fetchImage(data?.centreLogo)
      doc.addImage(centreLogo, 'png', 60, 25, 65, 65)
    } catch (error) {
      console.error(error)
    }
  }

  if (data?.doctorCentre?.signImage) {
    try {
      let centreSignImage = await fetchImage(data?.doctorCentre?.signImage)
      doc.addImage(centreSignImage, 'png', 85, 680, 210, 100)
    } catch (error) {
      console.error(error)
    }
  } else {
    try {
      let doctorSignImage = await fetchImage(data?.centreSignImage)
      doc.addImage(doctorSignImage, 'png', 85, 680, 210, 100)
    } catch (error) {
      console.error(error)
    }
  }

  doc.setFillColor(250, 250, 250)
  doc.rect(41, 580, 503, 43, 'F')

  doc.setFontSize(11)
  doc.setFont('Helvetica', 'bold')
  doc.text('Medicamentos', 60, 290)
  doc.text('Cantidad', 60, 595)
  doc.text('Diagnóstico', 60, 640)

  doc.setFontSize(10)
  doc.setFont('Helvetica', 'normal')

  if (data.medication) {
    doc.text(doc.splitTextToSize(data.medication.toUpperCase(), 385), 150, 290)
  }

  if (data.quantity) {
    doc.text(doc.splitTextToSize(data.quantity.toUpperCase(), 385), 150, 595)
  }

  if (data.diagnosis) {
    doc.text(doc.splitTextToSize(data.diagnosis.toUpperCase(), 385), 150, 640)
  }

  // page 2
  doc.addPage()
  addPrescriptionPdfDefaultContent(data, doc)

  if (data?.centreLogo) {
    try {
      let centreLogo = await fetchImage(data.centreLogo)
      doc.addImage(centreLogo, 'png', 60, 25, 65, 65)
    } catch (error) {
      console.error(error)
    }
  }

  if (data?.doctorCentre?.signImage) {
    try {
      let centreSignImage = await fetchImage(data?.doctorCentre?.signImage)
      doc.addImage(centreSignImage, 'png', 85, 680, 210, 100)
    } catch (error) {
      console.error(error)
    }
  } else {
    try {
      let doctorSignImage = await fetchImage(data?.centreSignImage)
      doc.addImage(doctorSignImage, 'png', 85, 680, 210, 100)
    } catch (error) {
      console.error(error)
    }
  }

  doc.setFillColor(250, 250, 250)
  doc.rect(41, 592, 503, 72, 'F')

  doc.setFontSize(11)
  doc.setFont('Helvetica', 'bold')
  doc.text('Medicamentos', 60, 300)
  doc.text('Indicaciones', 60, 620)

  doc.setFontSize(10)
  doc.setFont('Helvetica', 'normal')

  if (data.medication) {
    doc.text(doc.splitTextToSize(data.medication.toUpperCase(), 385), 150, 300)
  }

  if (data.indications) {
    doc.text(doc.splitTextToSize(data.indications.toUpperCase(), 385), 150, 620)
  }

  let blob = doc.output('blob')
  let file = new File([blob], 'receta.pdf', { type: 'application/pdf' })
  let result
  if (file) {
    result = await uploadFile(file)
  }

  return result
}

export default generatePrescriptionPdf
