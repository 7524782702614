import { deleteCentreMercadopagoInfo, fetchCentreMercadoPagoInfo } from '@/services/centreMercadoPagoInfo'
import {
  DELETE_CENTRE_MERCADO_PAGO_INFO,
  FETCH_CENTRE_MERCADO_PAGO_INFO,
  LOADED_CENTRE_MERCADO_PAGO_INFO,
} from '@/store/centreMercadoPagoInfo'
import { showToast } from '@/utils/toast'

const getCentreMercadoPagoInfo = (centreId) => async (dispatch) => {
  dispatch(LOADED_CENTRE_MERCADO_PAGO_INFO(true))
  try {
    const centreMercadoPagoInfoDetail = await fetchCentreMercadoPagoInfo(centreId)
    dispatch(FETCH_CENTRE_MERCADO_PAGO_INFO(centreMercadoPagoInfoDetail))
    dispatch(LOADED_CENTRE_MERCADO_PAGO_INFO(false))
  } catch (error) {
    const { message } = error
    console.log(message)
    dispatch(LOADED_CENTRE_MERCADO_PAGO_INFO(false))
  }
}

const deleteCentreMercadoPagoInfo = (centreMercadoPagoInfoId) => async (dispatch) => {
  try {
    await deleteCentreMercadopagoInfo(centreMercadoPagoInfoId)
    dispatch(DELETE_CENTRE_MERCADO_PAGO_INFO())
    showToast('Cuenta de mercado pago desconectada correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Error al desconectar cuenta de mercado pago', 'error')
  }
}

export { deleteCentreMercadoPagoInfo, getCentreMercadoPagoInfo }
