import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PageHearedTitle } from '@/components/pages'
import useValidateURLId from '@/hooks/useValidateURLId'
import Layout1 from '@/layouts/Layout1'
import BaseSideBar from '@/layouts/Layout1/components/BaseSideBar'
import { getAllCentreTreatments, resetTreatmentSelected } from '@/store/treatments/actions'
import { filterArrayBySearchText } from '@/utils/functions/filterArrayBySeachText'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { Paper } from '@mui/material'

import CardTemplateTreatment from './components/cardTemplateTreatment'
import ContentSidebar from './components/contentSidebar'
import PopUpTemplateTreatment from './components/popUpTemplateTreatment'

import s from './styles.module.scss'

const TreatmentTemplate = () => {
  const dispatch = useDispatch()
  const centre = useValidateURLId()
  const navigate = useNavigate()
  const { t } = useTranslation('global')

  const [searchText, setSearchText] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [openPopUp, setOpenPopUp] = useState(false)
  const [filteredTreatments, setFilteredTreatments] = useState([])

  const treatments = useSelector((state) => state.treatments.allTreatments)
  const treatmentSelected = useSelector((state) => state.treatments.treatmentSelected)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const getAllTreatments = async () => {
    await dispatch(getAllCentreTreatments(centre))
    setIsLoading(false)
  }

  useEffect(() => {
    if (!centreDetail?.hasTreatments) navigate(`/`)
    getAllTreatments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filterTreatments = filterArrayBySearchText(treatments, searchText)
    setFilteredTreatments(filterTreatments)
  }, [treatments, searchText])

  const addNewTemplate = () => {
    dispatch(resetTreatmentSelected())
    setOpenPopUp(!openPopUp)
  }

  const modifyTemplateTreatment = () => {
    setOpenPopUp(!openPopUp)
  }

  const handleChange = (e) => {
    setSearchText(e.target.value)
  }

  return (
    <Layout1
      navbar
      isLoading={isLoading}
      leftSidebar={
        <BaseSideBar
          inputLabel={'Buscar sesión'}
          inputValue={searchText}
          inputOnChange={handleChange}
          buttonLabel={'Nueva plantilla'}
          buttonOnClick={addNewTemplate}
          content={<ContentSidebar listItem={filteredTreatments} />}
        />
      }
    >
      <div className={s.divContainer}>
        <PageHearedTitle titleText="Plantillas de sesiones" />
        {treatmentSelected ? (
          <CardTemplateTreatment
            treatmentSelected={treatmentSelected}
            modifyTemplateTreatment={modifyTemplateTreatment}
          />
        ) : (
          <Paper elevation={3} className={`${s.cardContainer} animate__animated animate__fadeIn`}>
            <p>
              Las plantillas de sesiones son utilizadas para precargar los datos del grupo de sesiones de un{' '}
              {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}. Son compartidas para todo
              el centro de salud.
            </p>
            {treatments.length === 0 ? (
              <>
                <hr className={s.hr} />
                <div>No hay plantillas de sesiones cargadas aún.</div>
              </>
            ) : (
              <>
                <hr className={s.hr} />
                <div>Seleccione una plantilla de sesión del listado para ver su información.</div>
              </>
            )}
          </Paper>
        )}
      </div>
      <PopUpTemplateTreatment open={openPopUp} setOpen={setOpenPopUp} treatmentSelected={treatmentSelected} />
    </Layout1>
  )
}

export default TreatmentTemplate
