import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'
import { reducePayload } from './reducePayload'

const fetchTurns = async (params) => {
  const route = `api/turns`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchSingleTurn = async (id) => {
  const route = `/api/turns/${id}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putAturn = async (turn) => {
  const auxTurn = reducePayload(turn)

  const route = '/api/turns'
  return API.put(route, auxTurn)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postTurn = async (turn) => {
  const auxTurn = reducePayload(turn)

  const route = '/api/turns'
  return API.post(route, auxTurn)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteTurnById = async (id) => {
  const route = '/api/turns/' + id
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const setTurnDate = async (turn) => {
  const auxTurn = reducePayload(turn)

  const route = '/api/turns/set-date'
  return API.put(route, auxTurn)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const cancelTurnTime = async (id) => {
  const route = '/api/turns/cancel-time/' + id
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const confirmTurn = async (id) => {
  const route = `/api/turns/confirm/${id}`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const cancelTurn = async (id) => {
  const route = `/api/turns/cancel/${id}`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchTurnsWithoutDate = async (params) => {
  const route = `/api/turns/no-date`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchAbsentPatientTurns = async (params) => {
  const route = `/api/turns/absentism`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export {
  cancelTurn,
  cancelTurnTime,
  confirmTurn,
  deleteTurnById,
  fetchAbsentPatientTurns,
  fetchSingleTurn,
  fetchTurns,
  fetchTurnsWithoutDate,
  postTurn,
  putAturn,
  setTurnDate,
}
