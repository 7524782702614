import React from 'react'
import { useTranslation } from 'react-i18next'
import CaluRawDatePicker from '@/components/styledComponents/CaluRawDatePicker'
import { CaluSelect } from '@/components/styledComponents/CaluSelect'
import { Grid } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const HeaderManagement = ({
  startDate,
  setStartDate,
  finishDate,
  setFinishDate,
  selectValue,
  setSelectValue,
  filterOptionsValue,
  setFilterOptionValue,
  isLoadingRemindersHistory,
}) => {
  const { t } = useTranslation('global')

  const items = [
    { value: 1, name: `Fecha de ${t('turnLabel.s')}` },
    { value: 2, name: 'Fecha de envío' },
  ]

  const filterOptions = [
    { value: 2, name: 'Todos' },
    { value: 3, name: `Recordatorio de ${t('turnLabel.s')}` },
    { value: 4, name: 'Saludo de cumpleaños' },
    { value: 5, name: `${t('turnLabel.S')} sin fecha` },
    { value: 6, name: `${t('turnLabel.S')} cancelada` },
    { value: 7, name: `${t('turnLabel.S')} finalizada` },
  ]

  const handleWappRemindersType = (e) => {
    const value = e.target.value
    setSelectValue(value)

    if (value === 2) {
      setFilterOptionValue(2)
    }
  }

  const handleFilterOptions = (e) => {
    setFilterOptionValue(e.target.value)
  }

  const handleSetStartDate = (newDate) => {
    if (dayjs(newDate).format() === 'Invalid Date') return

    const formatedDate = dayjs(newDate).startOf('day')
    setStartDate(formatedDate.format())

    if (formatedDate.isAfter(dayjs(finishDate).startOf('day'))) {
      setFinishDate(formatedDate.endOf('day').format())
    }
  }

  const handleSetFinishDate = (newDate) => {
    if (dayjs(newDate).endOf('day').format() === 'Invalid Date') return
    if (dayjs(newDate).isBefore(dayjs(), 'day')) {
      setFinishDate(dayjs(startDate).endOf('day').format())
      return
    }
    setFinishDate(dayjs(newDate).endOf('day').format())
  }

  return (
    <Grid
      container
      className={s.mainContainer}
      sx={{
        '& .MuiInput-underline:after': {
          borderBottomColor: '#4C5EFE',
        },
      }}
    >
      <Grid item>
        <CaluSelect
          className={s.select}
          labelText="Buscar por..."
          value={selectValue}
          items={items}
          onChange={handleWappRemindersType}
          fullWidth
        />
      </Grid>
      {selectValue !== 1 && (
        <Grid item>
          <CaluSelect
            className={s.select}
            labelText="Filtrar por..."
            value={filterOptionsValue}
            items={filterOptions}
            onChange={handleFilterOptions}
            fullWidth
          />
        </Grid>
      )}
      <Grid item>
        <CaluRawDatePicker
          labelText="Desde"
          value={startDate}
          onChange={handleSetStartDate}
          disabled={isLoadingRemindersHistory}
        />
      </Grid>
      <Grid item>
        <CaluRawDatePicker
          labelText="Hasta"
          value={finishDate}
          onChange={handleSetFinishDate}
          disabled={isLoadingRemindersHistory}
        />
      </Grid>
    </Grid>
  )
}

export default HeaderManagement
