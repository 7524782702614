import {
  addNewCentreConfiguration,
  deleteCentreConfiguration,
  getCentreConfiguration,
  putCentreConfiguration,
} from '@/services/centreConfigurations'
import {
  CENTRE_CONFIGURATION_CREATED,
  CENTRE_CONFIGURATION_DELETE,
  CENTRE_CONFIGURATION_SELECTED,
  CENTRE_CONFIGURATION_UPDATED,
} from '@/store/centreConfigurations'
import { showToast } from '@/utils/toast'

const getCurrentcentreConfiguration = (id) => async (dispatch) => {
  try {
    const centreConfig = await getCentreConfiguration(id)
    dispatch(CENTRE_CONFIGURATION_SELECTED(centreConfig))
    return centreConfig
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const postcentreConfiguration = (data, hideAlert) => async (dispatch) => {
  try {
    const newCentreConfig = await addNewCentreConfiguration(data)
    dispatch(CENTRE_CONFIGURATION_CREATED(newCentreConfig))
    if (newCentreConfig && !hideAlert) {
      showToast('Configuración de centro agregada correctamente', 'success')
    }
    return newCentreConfig
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const deletecentreConfiguration = (id) => async (dispatch) => {
  try {
    await deleteCentreConfiguration(id)
    dispatch(CENTRE_CONFIGURATION_DELETE(id))
    showToast('Configuraciones del centro eliminadas', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updatecentreConfiguration = (data) => async (dispatch) => {
  try {
    const centreConfigUpdated = await putCentreConfiguration(data)
    dispatch(CENTRE_CONFIGURATION_UPDATED(centreConfigUpdated))
    return centreConfigUpdated
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export { deletecentreConfiguration, getCurrentcentreConfiguration, postcentreConfiguration, updatecentreConfiguration }
