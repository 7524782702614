import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import { useUser } from '@/context/UserProvider'
import useDebounce from '@/hooks/useDebounce'
import { RESET_PRODUCT_SELECTED } from '@/store/products'
import { getAllProducts, isLoadedProducts } from '@/store/products/actions'

import PopUpProducts from './components/popUpProducts'
import ProductsSearchBar from './components/productsSearchBar'
import ProductsTable from './components/productsTable'

import s from './styles.module.scss'

const ProductsPage = ({ directionOfAnimation }) => {
  const [openPopUp, setOpenPopUp] = useState(false)
  const { allProducts, isLoaded, selectedProduct } = useSelector((state) => state?.products)
  const [inputSearch, setInputSearch] = useState('')
  const DELAY_TIME = 300
  const inputDebounceValue = useDebounce(inputSearch, DELAY_TIME)
  const { defaultCentre } = useUser()
  const dispatch = useDispatch()

  const getProducts = async () => {
    let params
    if (inputDebounceValue.length === 0) {
      params = {
        centre: defaultCentre,
        limit: 30,
        page: 1,
      }
    } else {
      params = {
        centre: defaultCentre,
        search: inputDebounceValue,
      }
    }

    dispatch(isLoadedProducts(true))
    await dispatch(getAllProducts(params))
    dispatch(isLoadedProducts(false))
  }

  useEffect(() => {
    getProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDebounceValue])

  const handleClosePopUp = () => {
    setOpenPopUp(!openPopUp)
    dispatch(RESET_PRODUCT_SELECTED())
  }
  return (
    <div className={directionOfAnimation}>
      <ProductsSearchBar setOpen={setOpenPopUp} inputSearch={inputSearch} setInputSearch={setInputSearch} />
      {isLoaded ? (
        <LoaderCircle size={50} />
      ) : allProducts?.length === 0 ? (
        <p className={s.noItems}>No hay productos cargados aún</p>
      ) : (
        <ProductsTable setOpen={setOpenPopUp} inputSearch={inputSearch} />
      )}

      {openPopUp && <PopUpProducts open={openPopUp} selectedProduct={selectedProduct} onClose={handleClosePopUp} />}
    </div>
  )
}

export default ProductsPage
