import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { turnDurations } from '@/helpers/constants'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

const WorkTimeDuration = ({ control }) => {
  const { t } = useTranslation('global')
  return (
    <div>
      <Controller
        name="duration"
        control={control}
        render={({ field }) => (
          <FormControl variant="standard" fullWidth>
            <InputLabel>Duración de {t('turnLabel.s')} predeterminada *</InputLabel>
            <Select
              {...field}
              label={`Duración del servicio`}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 380,
                  },
                },
              }}
            >
              {turnDurations.map((d) => (
                <MenuItem key={d.value} value={d.value}>
                  {d.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        rules={{ required: true }}
      />
    </div>
  )
}

export default WorkTimeDuration
