// @ts-nocheck
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { updatePatientCentres } from '@/store/patientCentres/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const MedicalInformation = ({ directionOfAnimation }) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const { patientSelected } = useSelector((state) => state.patientCentres)
  const { selectedTurn } = useSelector((state) => state.turns)

  // on /patients we have patientSelected
  // on /agenda we have turn.patientCentre
  // TODO: patientSelected is not empty if we move from /patients to /agenda
  const patientData = selectedTurn?.patientCentre || patientSelected

  const handleSubmit = async (data) => {
    setIsLoading(true)
    try {
      await dispatch(updatePatientCentres({ ...patientData, ...data }, selectedTurn))
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={directionOfAnimation}>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={patientData || formDefaultValues}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent submitLoading={isLoading} />
      </ContainerForm>
    </div>
  )
}

export default MedicalInformation
