import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import DividerSection from '@/components/DividerSection'
import { AutoCompleteField } from '@/components/Form/customFields/AutoCompleteField'
import { CustomField } from '@/components/Form/customFields/CustomField'
import CaluButton from '@/components/styledComponents/CaluButton'
import { TextOverButtons } from '@/components/TextOverButtons'
import { useSocket } from '@/context/SocketProvider'
import { useUser } from '@/context/UserProvider'
import { paymentMethods } from '@/helpers/constants'
import { patchSelectedTurn, updateATurn } from '@/store/turns/actions'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { showToast } from '@/utils/toast'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Grid, IconButton } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const AddPaymentForm = () => {
  const dispatch = useDispatch()
  const { socket } = useSocket()
  const { defaultCentre } = useUser()

  const [paymentDate, setPaymentDate] = useState(dayjs())
  const { selectedTurn } = useSelector((state) => state.turns)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const { field: paidAmountField } = useController({ name: 'paidAmount' })
  const { field: paymentMethodField } = useController({ name: 'paymentMethod' })

  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  useEffect(() => {
    paymentMethodField.onChange({ name: 'Efectivo', value: 'ARScash' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const savePayment = async () => {
    if (!paidAmountField.value) return showToast('debe ingresar monto pagado ', 'warning', 2000)

    const formatedDate = dayjs(paymentDate).format()
    const auxPayment = {
      amount: parseInt(paidAmountField.value),
      method: paymentMethodField.value.value,
      date: toCustomTz(formatedDate, 'utc', true),
    }
    const auxPaymentHistory = [...selectedTurn.paymentHistory, auxPayment]

    let auxTurn = {
      ...selectedTurn,
      paymentHistory: auxPaymentHistory,
      notRecalculateAmount: true,
    }

    auxTurn.totalPayments = auxTurn?.paymentHistory?.reduce((accum, p) => accum + (p.amount || 0), 0)

    if (selectedTurn?.treatment) {
      auxTurn = {
        ...auxTurn,

        treatment: {
          ...selectedTurn?.treatment,
          paidPrice: selectedTurn?.treatment.paidPrice + auxPayment.amount,
        },
      }
    }

    dispatch(patchSelectedTurn(auxTurn))
    const updatedTurn = await dispatch(updateATurn(auxTurn))
    // @ts-ignore
    paidAmountField.onChange('')
    socket.emit('calendar_edit_turn', `${defaultCentre}_calendar`, updatedTurn)
  }

  const addPropWhenClick = (i) => {
    let auxData = [...selectedTurn.paymentHistory]
    auxData[i] = {
      ...auxData[i],
      showConfirm: true,
    }
    // @ts-ignore
    dispatch(patchSelectedTurn({ paymentHistory: auxData, notRecalculateAmount: true }))
  }

  const removePropWhenClick = (i) => {
    let auxData = [...selectedTurn.paymentHistory]
    auxData[i] = {
      ...auxData[i],
      showConfirm: false,
    }
    // @ts-ignore
    dispatch(patchSelectedTurn({ paymentHistory: auxData, notRecalculateAmount: true }))
  }

  const deleteItemPaymentHistory = async (itemToDelete) => {
    let arrayWithPaymentRemoved = selectedTurn.paymentHistory.filter((index) => index._id !== itemToDelete._id)
    let auxDataToUpdate = {
      ...selectedTurn,
      paymentHistory: arrayWithPaymentRemoved,
      notRecalculateAmount: true,
    }
    try {
      // @ts-ignore
      if (selectedTurn?.treatment) {
        auxDataToUpdate = {
          ...auxDataToUpdate,
          treatment: {
            ...selectedTurn?.treatment,
            paidPrice: selectedTurn?.treatment.paidPrice - itemToDelete.amount,
          },
        }
      }
      dispatch(patchSelectedTurn(auxDataToUpdate))
      const updatedTurn = await dispatch(updateATurn(auxDataToUpdate))
      socket.emit('calendar_edit_turn', `${defaultCentre}_calendar`, updatedTurn)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <DividerSection label="Agregar pago" />
      <Grid container className={s.divFlex}>
        <Grid item xs={12} sm={3}>
          <DatePicker
            className={s.inputWidth90}
            label="Fecha de pago"
            value={paymentDate}
            format="DD/MM/YYYY"
            onChange={(newDate) => setPaymentDate(newDate)}
            slotProps={{
              textField: {
                variant: 'standard',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CustomField label="Monto pagado" name="paidAmount" type="number" className={s.inputWidth90} />
        </Grid>
        <Grid item xs={12} md={4}>
          <AutoCompleteField
            label="Medio de pago"
            name="paymentMethod"
            options={paymentMethods}
            multiple={false}
            className={s.inputWidth90}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <CaluButton size="small" inverted="true" onClick={savePayment} className={s.addButton}>
            Agregar
          </CaluButton>
        </Grid>
      </Grid>

      <Grid className={s.gridPayments}>
        {selectedTurn?.paymentHistory?.length > 0 ? <b>Pagos realizados:</b> : null}
        <ul className={s.paymentsUl}>
          {selectedTurn?.paymentHistory?.map((payment, i) => (
            <li className={s.paymentsList} key={i}>
              <b>{toCustomTz(payment.date, undefined, true, 'DD/MM/YYYY')} </b>
              <span>{`${handleSetMoneySign(country)} ${payment.amount}`} </span>
              <span>{`(${convertValueToName(paymentMethods, payment.method)})`}</span>

              {!payment.showConfirm ? (
                <TextOverButtons title=" eliminar" placement="right">
                  <IconButton onClick={() => addPropWhenClick(i)}>
                    <DeleteOutlineIcon className={s.deleteIcon} />
                  </IconButton>
                </TextOverButtons>
              ) : (
                <Grid className={s.containerButtonsDelete}>
                  <TextOverButtons title="Si, eliminar">
                    <Grid className={s.btnsDeleteIcons}>
                      <IoIosCheckmarkCircleOutline onClick={() => deleteItemPaymentHistory(payment)} />
                    </Grid>
                  </TextOverButtons>
                  <TextOverButtons title="No, cancelar">
                    <Grid className={s.btnsCancelIcons}>
                      <IoIosCloseCircleOutline onClick={() => removePropWhenClick(i)} />
                    </Grid>
                  </TextOverButtons>
                </Grid>
              )}
            </li>
          ))}
        </ul>
      </Grid>
    </>
  )
}

export default AddPaymentForm
