import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { useUser } from '@/context/UserProvider'
import { generateMongoObjectId } from '@/helpers/generateMongoObjectId'
import { postcentreConfiguration } from '@/store/centreConfigurations/actions'
import { postCentre } from '@/store/centres/actions'
import { createMercadoPagoSubscription } from '@/store/mercadoPago/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const FormCreateAdmin = ({ formValues, onClose }) => {
  const dispatch = useDispatch()
  const { registerNewCentreUser } = useUser()

  const { allSubscriptionPlans } = useSelector((state) => state?.subscriptionPlans)

  const [isLoading, setIsLoading] = useState(false)
  const [suggestedPrice, setSuggestedPrice] = useState('')
  const [finalPrice, setFinalPrice] = useState('')

  const handleSubmit = async (data) => {
    setIsLoading(true)

    try {
      const fakeCentreId = generateMongoObjectId()

      let centreData = {
        name: data.name,
        status: data.status,
        country: data.country,
        paymentLink: data.paymentLink ? data.paymentLink : null,
      }

      delete data.name
      delete data.status
      delete data.country
      delete data.repitePassword

      const centreConfigData = {
        centre: fakeCentreId,
        ...data.centreConfiguration,
        auxAmount: finalPrice,
      }

      const centreConfiguration = await dispatch(postcentreConfiguration(centreConfigData, true))

      centreData._id = fakeCentreId
      centreData.centreConfiguration = centreConfiguration
      const centre = await dispatch(postCentre(centreData, true))

      delete data.centreConfiguration

      await registerNewCentreUser(data, centre?._id, 'centre', true)

      if (centreConfiguration?.subscriptionPaymentType === 'mercadoPagoSubscription') {
        const findSunscriptionPlan = allSubscriptionPlans?.find(
          (plan) => plan?._id === centreConfiguration?.subscriptionPlan,
        )
        const auxCentre = {
          ...centre,
          auxAmount: finalPrice,
          centreConfiguration: {
            ...centreConfiguration,
            subscriptionPlan: findSunscriptionPlan,
          },
        }
        await dispatch(createMercadoPagoSubscription(auxCentre))
      }

      onClose()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={{ ...formDefaultValues, ...formValues }}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent
          isLoading={isLoading}
          suggestedPrice={suggestedPrice}
          setSuggestedPrice={setSuggestedPrice}
          finalPrice={finalPrice}
          setFinalPrice={setFinalPrice}
        />
      </ContainerForm>
    </div>
  )
}

export default FormCreateAdmin
