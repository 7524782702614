import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TreatmentSection from '@/pages/PatientCentresPage/components/treatmentSection'
import { getAllCentreTreatments, getAllPatientTreatments } from '@/store/treatments/actions'
import { setDataToCreateTurnTreatment } from '@/store/turns/actions'

const TreatmentTab = ({
  turnData,
  setOpenPopUp,
  setTreatmentSection,
  patientSelected,
  handleClose,
  directionOfAnimation,
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const selectedTurn = useSelector((state) => state.turns.selectedTurn)

  const treatmentTabFromUpdateTurn = async () => {
    setLoading(true)
    await dispatch(getAllCentreTreatments(selectedTurn.centre))
    await dispatch(getAllPatientTreatments(selectedTurn.centre, selectedTurn.patientCentre._id))
    setLoading(false)
  }

  const treatmentTabFromCreateTurn = async () => {
    setLoading(true)
    await dispatch(setDataToCreateTurnTreatment(turnData))
    await dispatch(getAllPatientTreatments(turnData.centreId, turnData.patientSelected._id))
    await dispatch(getAllCentreTreatments(turnData.centreId))
    setLoading(false)
  }

  useEffect(() => {
    if (selectedTurn) {
      treatmentTabFromUpdateTurn()
    } else {
      treatmentTabFromCreateTurn()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTurn])

  return (
    <div className={directionOfAnimation}>
      {
        <TreatmentSection
          setTreatmentSection={setTreatmentSection}
          setOpenPopUp={setOpenPopUp}
          patientSelected={patientSelected}
          setLoading={setLoading}
          loading={loading}
          handleClose={handleClose}
        />
      }
    </div>
  )
}

export default TreatmentTab
