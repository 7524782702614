import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'
import { reducePayload } from './reducePayload'

const fetchCentres = async (page, limit) => {
  const route = `api/centres?page=${page || 1}&limit=${limit || 30}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchCentreByInputSearch = async (params) => {
  const route = `api/centres`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getCentreDetail = async (id) => {
  const route = `api/centres/${id}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getCentreDetailBySlug = async (slug) => {
  const route = `api/centres/slug/${slug}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getInfoUserInCentreAdmin = async (id) => {
  const route = `api/centres/${id}/lookup`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchCentresPaymentsInfo = async () => {
  const route = `api/centres/payment-info`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewCentre = async (centre) => {
  const auxCentre = reducePayload(centre)

  const route = `api/centres`
  return API.post(route, auxCentre)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putCentre = async (centre) => {
  const auxCentre = reducePayload(centre)

  const route = `api/centres`
  return API.put(route, auxCentre)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putCentresPaymentInfo = async (data) => {
  const route = `api/centres/payment-info`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteCentre = async (id) => {
  const route = `api/centres/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export {
  addNewCentre,
  deleteCentre,
  fetchCentreByInputSearch,
  fetchCentres,
  fetchCentresPaymentsInfo,
  getCentreDetail,
  getCentreDetailBySlug,
  getInfoUserInCentreAdmin,
  putCentre,
  putCentresPaymentInfo,
}
