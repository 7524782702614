import React from 'react'
import FormRegister from '@/components/FormRegister'
import NavBar from '@/components/NavBar'

import s from './styles.module.scss'

const RegisterPage = () => {
  return (
    <div className={s.container}>
      <NavBar />
      <FormRegister />
    </div>
  )
}

export default RegisterPage
