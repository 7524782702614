import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import defaultAgendaRoundImage from '@/assets/images/default-agenda-round.png'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import ImageCircle from '@/components/imageCircle'
import { PageHearedTitle } from '@/components/pages'
import { useSocket } from '@/context/SocketProvider'
import { setCurrentCentre } from '@/controllers/centres'
import { useDeleteImage } from '@/hooks/useDeleteImage'
import { useUploadImage } from '@/hooks/useUploadImage'
import useValidateURLId from '@/hooks/useValidateURLId'
import BaseSideBar from '@/layouts/Layout1/components/BaseSideBar'
import ContentSidebar from '@/pages/MachinesPage/components/contentSideBar'
import { getCurrentCentre } from '@/store/centres/actions'
import { deleteMachineAdded, getAllMachines, resetSelectedMachine, updateImageMachine } from '@/store/machines/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { Button, Paper } from '@mui/material'

import dayjs from 'dayjs'
import Layout1 from './../../layouts/Layout1/index'
import PopUpMachine from './components/popUpMachine'

import s from './styles.module.scss'

const MachinesPage = () => {
  const dispatch = useDispatch()
  const { socket } = useSocket()
  const centreID = useValidateURLId()

  const [open, setOpen] = useState(false)
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [machinesFiltered, setMachinesFiltered] = useState([])
  const [openDeleteImageAlert, setOpenDeleteImageAlert] = useState(false)
  const [openAlertDelete, setOpenAlertDelete] = useState(false)
  const { loadingImage, uploadImage } = useUploadImage()
  const { deleteProfileImage } = useDeleteImage()

  const allMachines = useSelector((state) => state.machines?.allMachines)
  const machineSelected = useSelector((state) => state.machines?.machineSelected)
  const creationDate = dayjs(machineSelected?.creationDate).year()

  const getCentre = async () => {
    const currentCentre = await setCurrentCentre(centreID)
    dispatch(getCurrentCentre(currentCentre))
  }

  const getMachines = async () => {
    dispatch(getAllMachines(centreID))
  }

  useEffect(() => {
    const init = async () => {
      await getCentre()
      await getMachines()
      setIsLoading(false)
    }
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filteredMachines = allMachines?.filter((machine) =>
      machine.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .includes(
          input
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
        ),
    )
    setMachinesFiltered(filteredMachines)
  }, [allMachines, input])

  const addNewMachine = () => {
    dispatch(resetSelectedMachine())
    setOpen(!open)
  }

  const modifyMachine = () => {
    setOpen(!open)
  }

  const deleteMachine = async () => {
    const deletedMachine = await dispatch(deleteMachineAdded(machineSelected._id))
    if (deletedMachine) {
      socket.emit('calendar_delete_entity', `${centreID}_calendar`, machineSelected)
    }
    dispatch(resetSelectedMachine())
    setOpenAlertDelete(false)
  }

  const handleChange = (e) => {
    setInput(e.target.value)
  }

  const showtextContentExtraOptional = () => {
    if (creationDate <= 2022) {
      return 'Al eliminarla, se perderán los datos de dicha agenda en el detalle de los turnos. La información registrada en la historia clínica y el historial de turnos se conservará, sin embargo, no se indicará el nombre de la agenda. Si no queres perder esta información podes eliminar solo los horarios laborales.'
    }
    return 'Verifica que la agenda no tenga citas o información relevante previo a eliminarla. Esta acción no se puede revertir y la información no se podrá recuperar.'
  }

  return (
    <Layout1
      navbar
      isLoading={isLoading}
      leftSidebar={
        <BaseSideBar
          inputLabel={'Buscar agenda'}
          inputValue={input}
          inputOnChange={handleChange}
          buttonLabel={'Nueva agenda'}
          buttonOnClick={addNewMachine}
          content={<ContentSidebar listItem={machinesFiltered} />}
        />
      }
    >
      <div className={s.mainContainer}>
        <div className={s.contentContainer}>
          <PageHearedTitle titleText="Agendas" />

          {machineSelected !== null ? (
            <Paper elevation={3} className={`${s.cardContainer} animate__animated animate__fadeIn`}>
              <div className={s.mainViewContent}>
                <div className={s.leftContent}>
                  <div className={s.logo}>
                    <ImageCircle
                      image={machineSelected?.image || defaultAgendaRoundImage}
                      uploadImage={(e) => uploadImage(e, 'image', updateImageMachine, machineSelected)}
                      loading={loadingImage}
                      className="uploadImageProfile"
                      id="patientImage"
                    />
                  </div>
                  {machineSelected.image && (
                    <div className={s.deleteImageButton} onClick={() => setOpenDeleteImageAlert(true)}>
                      Eliminar foto
                    </div>
                  )}

                  <div className={s.titleMachine}>{capitalizeFirstLetters(machineSelected.name)}</div>
                  <div className={s.descriptionMachine}>
                    {machineSelected.description ? (
                      <p>{capitalizeFirstLetters(machineSelected.description)}</p>
                    ) : (
                      <p>Sin descripción</p>
                    )}
                  </div>
                </div>
              </div>

              <div className={s.rightContent}>
                <div className={s.containerButtons}>
                  <Button
                    fullWidth={true}
                    disableElevation
                    variant="contained"
                    className={s.editButton}
                    onClick={modifyMachine}
                  >
                    Modificar
                  </Button>
                  <Button
                    className={s.deleteButton}
                    fullWidth={true}
                    disableElevation
                    variant="contained"
                    onClick={() => setOpenAlertDelete(!openAlertDelete)}
                  >
                    Eliminar
                  </Button>
                  <AlertDialogSlide
                    open={openAlertDelete}
                    setOpen={setOpenAlertDelete}
                    title="Importante⚠️"
                    textContent="Está a punto de eliminar la agenda."
                    textContentExtraOptional={showtextContentExtraOptional()}
                    textBackButton="Cancelar"
                    textConfirmationButton={'Eliminar'}
                    onClose={deleteMachine}
                  />
                </div>
              </div>

              <AlertDialogSlide
                open={openDeleteImageAlert}
                setOpen={setOpenDeleteImageAlert}
                textContent={'¿Confirma que quiere eliminar la imagen?'}
                textBackButton="Cancelar"
                textConfirmationButton={'Confirmar'}
                onClose={() => deleteProfileImage('image', machineSelected, updateImageMachine)}
              />
            </Paper>
          ) : (
            <Paper elevation={3} className={`${s.cardContainer} animate__animated animate__fadeIn`}>
              <p>Seleccione una agenda del listado para ver su información</p>
            </Paper>
          )}
        </div>

        <PopUpMachine open={open} setOpen={setOpen} entity={machineSelected} />
      </div>
    </Layout1>
  )
}

export default MachinesPage
