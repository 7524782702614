// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { useDispatch, useSelector } from 'react-redux'
import { useSocket } from '@/context/SocketProvider'
import { useUser } from '@/context/UserProvider'
import { useCalendar } from '@/pages/AgendaPage/CalendarProvider'
import RescheduleTurn from '@/pages/RescheduleTurn'
import { selectedRange } from '@/store/changeViewCalendar/actions'
import { setCentreEntity } from '@/store/doctorCentres/actions'
import { getSingleTurn, getTurnsFromWeek, setDaysMonth, updateATurn } from '@/store/turns/actions'
import { SELECTED_ENTITY } from '@/store/turns/index.js'
import { showWarningAlert } from '@/utils/alert'
import { formatHour } from '@/utils/functions/formatHour'
import { getWeekDays } from '@/utils/functions/getWeekDays'
import { handleTimeConvert } from '@/utils/functions/handleTimeConvert'
import { handleWappReminder } from '@/utils/functions/handleWappReminders'
import { removeHolidays } from '@/utils/functions/removeHolidays'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { showToast } from '@/utils/toast'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { IconButton } from '@mui/material'
import { useMenuState } from '@szhsin/react-menu'

import dayjs from 'dayjs'
import UpdateTurnDialog from '../formAgendaEvent/Dialogs/UpdateTurnDialog'
import FormCreateNewTurn from '../formAgendaEvent/formCreateNewTurn'
import ContextMenu from './ContextMenu'
import FullCalendarEventContent from './FullCalendarEventContent'

import s from './styles.module.scss'

export default function CalendarBody({ entity }) {
  const [menuProps, toggleMenu] = useMenuState()
  const [menuAnchorPoint, setMenuAnchorPoint] = useState({ x: 0, y: 0 })

  const dispatch = useDispatch()
  const { selectedDate } = useCalendar()
  const { defaultCentre } = useUser()
  const { socket } = useSocket()

  const itemRefs = useRef([])
  const [openRescheduleTurn, setOpenRescheduleTurn] = useState(false)
  const [turnInfo, setTurnInfo] = useState(null)
  const [selectedEntity, setSelectedEntity] = useState(null)
  const [openFormCreateNewTurn, setOpenFormCreateNewTurn] = useState(false)
  const [openFormUpdateTurn, setOpenFormUpdateTurn] = useState(false)
  const { centreProfile } = useSelector((state) => state.centres)
  const { allTurns, daysMonthLoaded: daysMonth } = useSelector((state) => state.turns)
  const { status: wappStatus } = useSelector((state) => state.wapp)
  const { dailyView } = useSelector((state) => state.changeViewCalendar)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected
  const allTurnsRef = useRef(allTurns)

  useEffect(() => {
    allTurnsRef.current = allTurns
  }, [allTurns])

  const handleGetTurnsForWeek = () => {
    let notIncludedDay = false
    let week_start = dayjs(selectedDate).startOf('week').utc(true).format()
    let week_end = dayjs(selectedDate).endOf('week').utc(true).format()

    let rangeData = {
      week_start,
      week_end,
    }
    dispatch(selectedRange(rangeData))

    //List of days a week
    const arrayOfDays = getWeekDays(selectedDate)

    for (let i = 0; i < arrayOfDays.length; i++) {
      let element = arrayOfDays[i]
      if (!daysMonth.includes(element)) {
        notIncludedDay = true
      }
    }

    if (!notIncludedDay) return

    // Add days to original List
    let totalListOfDays = [...arrayOfDays, ...daysMonth]

    // Delete repeat days
    totalListOfDays = totalListOfDays.reduce((acc, item) => {
      if (!acc.includes(item)) {
        acc.push(item)
      }
      return acc
    }, [])

    let turnsParams = {
      centre: defaultCentre,
      start: week_start,
      finish: week_end,
      selectedRole: entityInLocalStorage.role,
    }

    dispatch(getTurnsFromWeek(turnsParams))
    dispatch(setDaysMonth(totalListOfDays))
  }

  useEffect(() => {
    // TODO Logica de carga de turnos diarios y semanal que esten en el mismo lugar
    const { current: calendarApi } = itemRefs
    const API = calendarApi ? calendarApi.getApi() : []
    API && API.changeView(dailyView ? 'timeGridDay' : 'timeGridWeek')

    if (!!itemRefs.current) {
      let calendarApi = itemRefs.current
      calendarApi?.getApi().gotoDate(dayjs(selectedDate).format())
    }

    if (!dailyView) {
      handleGetTurnsForWeek()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyView, selectedDate])

  const sendWhatsapp = async (turn) => {
    const updatedTurns = allTurnsRef.current
    const filteredTurn = updatedTurns?.filter((t) => t._id === turn._id)[0]
    const state = filteredTurn.state

    const auxData = {
      selectedTurn: {
        ...filteredTurn,
        centre: centreProfile,
      },
      state: state,
      dispatch: dispatch,
      wappStatus: wappStatus,
    }
    await handleWappReminder(auxData)
  }

  const clickToCreateNewEvent = async (args) => {
    if (args?.jsEvent?.target?.className === 'fc-non-business') return
    if (args?.jsEvent?.target?.className?.includes('fc-bg-event')) return

    let startDate = toCustomTz(args?.dateStr, 'utc', true, '')
    let today = toCustomTz(new Date(), 'utc', true, '')

    setSelectedEntity({
      id: entity._id,
      selectedDate: startDate,
      lastDurationTurn: entity.currentSlotTimes.lastTurnDuration,
    })
    dispatch(setCentreEntity(entity._id))

    let showOverturnWarning = checkOverturn(entityTurns, startDate)

    if (showOverturnWarning) {
      setTimeout(() => {
        setOpenFormCreateNewTurn(true)
        mxp.track(mxp.events.agenda.newTurn.open)
      }, 1000)
      showWarningAlert('Estás a punto de dar un sobreturno!', 1000)
    } else if (startDate < today) {
      setTimeout(() => {
        setOpenFormCreateNewTurn(true)
        mxp.track(mxp.events.agenda.newTurn.open)
      }, 1000)
      showWarningAlert('La fecha seleccionada ya pasó!', 1000)
    } else {
      setOpenFormCreateNewTurn(true)
      mxp.track(mxp.events.agenda.newTurn.open)
    }
  }

  const clickOnCreatedEvent = async (info) => {
    if (info?.event?.display === 'background') return
    if (info?.event?.extendedProps?.state === 'pending') return
    const { tagName } = info.jsEvent.target
    const isWhatsAppClick = tagName === 'BUTTON' || tagName === 'svg' || tagName === 'path'
    if (isWhatsAppClick) return

    await dispatch(getSingleTurn(info.event.id))
    let auxSelectedEntity = {
      ...selectedEntity,
      id: info.event.id,
      lastDurationTurn: entity.currentSlotTimes.lastTurnDuration,
    }
    dispatch(SELECTED_ENTITY(auxSelectedEntity))
    setSelectedEntity(auxSelectedEntity)
    setOpenFormUpdateTurn(true)
    mxp.track(mxp.events.agenda.editTurn.open)
  }

  const updateEventHours = async (event = {}) => {
    if (!event) return
    if (!event?.extendedProps) return
    if (event?.extendedProps?.state === 'pending') return

    const turn = event?.extendedProps
    const newStartHour = toCustomTz(event?.start, 'utc', true, '')
    const newFinishHour = toCustomTz(event?.end, 'utc', true, '')
    const durationTurn = dayjs(newFinishHour).diff(newStartHour, 'minute')

    try {
      let auxTurn = {
        ...turn,
        consultationDuration: durationTurn,
        startHour: newStartHour,
        finishHour: newFinishHour,
      }
      const updatedTurn = await dispatch(updateATurn(auxTurn))
      if (updatedTurn) {
        socket.emit('calendar_edit_turn', `${defaultCentre}_calendar`, updatedTurn)
      }
    } catch (e) {
      showToast('Ups! algo falló, intenta nuevamente', 'error', 5000)
    }
  }

  const handleRightClick = (event) => {
    event.preventDefault()
    if (!event.target.fcSeg) return
    if (event.target.fcSeg.eventRange.def.extendedProps.state === 'pending') return
    setTurnInfo(event.target.fcSeg.eventRange.def.extendedProps)
    setMenuAnchorPoint({ x: event.clientX, y: event.clientY })
    toggleMenu(true)
  }

  const calendarDefaultConfiguration = {
    allDaySlot: false,
    dayHeaders: dailyView ? false : true,
    displayEventTime: false,
    editable: true,
    eventResizableFromStart: true,
    eventDurationEditable: true,
    eventResize: ({ event }) => updateEventHours(event),
    firstDay: 1,
    headerToolbar: dailyView ? false : true,
    height: 'auto',
    initialView: 'timeGridDay',
    nowIndicator: true,
    plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
    slotLabelFormat: ['HH:mm'],
    weekends: true,
    eventMinHeight: 5,
    slotEventOverlap: false,
  }

  const generateTurnsPerEntity = () => {
    let turnsPerEntity = {}

    allTurns?.forEach((t) => {
      if (entity.firstname && !t.machine) {
        const doctorCentreId = t.doctorCentre?._id || t.doctorCentre
        if (turnsPerEntity[doctorCentreId]) {
          turnsPerEntity[doctorCentreId].push(t)
        } else {
          turnsPerEntity[doctorCentreId] = [t]
        }
      }
      if (entity.name && t.machine) {
        const machineId = t.machine?._id || t.machine
        if (turnsPerEntity[machineId]) {
          turnsPerEntity[machineId].push(t)
        } else {
          turnsPerEntity[machineId] = [t]
        }
      }
    })

    return turnsPerEntity
  }

  const turnsPerEntity = generateTurnsPerEntity()
  let entityTurns = turnsPerEntity[entity._id] || []

  const checkOverturn = (turns, startDate) => {
    let showOverturnWarning = false
    let startHourSelected = dayjs(startDate).add(3, 'hours').format('HH:mm')
    let startDateSelected = dayjs(startDate).add(3, 'hours').format('YYYY-MM-DD')

    turns = turns?.filter((turn) => dayjs(turn?.startHour).add(3, 'hours').format('YYYY-MM-DD') === startDateSelected)

    turns?.forEach((turn) => {
      let startHourTurn = dayjs(turn.startHour).add(3, 'hours').format('HH:mm')
      let finishHourTurn = dayjs(turn.finishHour).add(3, 'hours').format('HH:mm')

      if (startHourSelected >= startHourTurn && startHourSelected < finishHourTurn && turn.state !== 'cancelled')
        return (showOverturnWarning = true)
    })

    return showOverturnWarning
  }

  const handleDuplicatedEvents = (events: EventApi[]) => {
    const lookup = events.reduce((a, e) => {
      a[e.id] = ++a[e.id] || 0
      return a
    }, {})

    const duplicatedEvents = events.filter((e) => lookup[e.id])

    if (duplicatedEvents.length) {
      duplicatedEvents.splice(-1, 1) // keep the last one
      duplicatedEvents.map((evt) => evt.remove()) // remove the others, may be more than one
    }
  }

  const createDailyBusinessHours = (entity, day) => {
    let businessHours = []
    let currentDate = day //YYYY-MM-DD
    let selectedWeekDay = dayjs(day).get('day')

    entity.workTimes.forEach((wt) => {
      //Dia unico
      if (wt?.frequency?.code === 2) {
        let uniqueDay = dayjs(wt?.frequency?.startDay).utc().format('YYYY-MM-DD')
        if (uniqueDay === currentDate) {
          businessHours.push({
            daysOfWeek: [selectedWeekDay],
            startTime: formatHour(wt?.startTime),
            endTime: formatHour(wt?.finishTime),
          })
        }
      }

      //Dia cada 15 dias
      if (wt?.frequency?.code === 1) {
        //uniqueDay = 1/12 (Fecha inicio intervalo cada 15 dias)
        const firstDay = dayjs(wt?.frequency?.startDay).utc().format('YYYY-MM-DD')
        const maxWeeks = 60
        //Desde el 1/12 vamos 60 semanas para adelante y creamos 60 dias unicos
        let allUniqueDays = [firstDay]

        for (let i = 1; i < maxWeeks; i++) {
          const iDay = dayjs(firstDay)
            .add(14 * i, 'day')
            .format('YYYY-MM-DD')
          allUniqueDays.push(iDay)
        }

        if (allUniqueDays?.includes(currentDate)) {
          businessHours.push({
            daysOfWeek: [selectedWeekDay],
            startTime: formatHour(wt?.startTime),
            endTime: formatHour(wt?.finishTime),
          })
        }
      }

      // Dia semanal
      if (wt?.frequency?.code === 0 && wt?.weekDay?.code === selectedWeekDay) {
        let isValid = true
        if (wt.deferred && (wt.deferred.from || wt.deferred.to)) {
          let wFrom = wt.deferred.from && dayjs(wt.deferred.from).toISOString().slice(0, 10)
          let wTo = wt.deferred.to && dayjs(wt.deferred.to).toISOString().slice(0, 10)

          let isValidFrom = !wFrom
          if (wFrom && selectedDate >= wFrom) {
            isValidFrom = true
          }

          let isValidTo = !wTo
          if (wTo && selectedDate < wTo) {
            isValidTo = true
          }

          isValid = isValidFrom && isValidTo
        }

        if (isValid) {
          businessHours.push({
            daysOfWeek: [wt?.weekDay?.code],
            startTime: formatHour(wt?.startTime),
            endTime: formatHour(wt?.finishTime),
          })
        }
      }
    })

    return businessHours
  }

  const createBusinessHours = (entity) => {
    //     {
    //   daysOfWeek: [ 1, 2, 3, 4 ], // Monday - Thursday

    //   startTime: '10:00', // a start time (10am in this example)
    //   endTime: '18:00', // an end time (6pm in this example)
    // }

    let businessHours = []

    if (dailyView) {
      businessHours = businessHours.concat(createDailyBusinessHours(entity, selectedDate))
    } else {
      const weekDays = getWeekDays(selectedDate)
      const holidayDates = centreProfile?.holidays?.map((elem) => dayjs(elem).utc().format('YYYY-MM-DD'))
      const filterHolidays = removeHolidays(weekDays, holidayDates)
      filterHolidays.forEach((day) => (businessHours = businessHours.concat(createDailyBusinessHours(entity, day))))

      const uniqueArray = businessHours.filter((value, index) => {
        const _value = JSON.stringify(value)
        return (
          index ===
          businessHours.findIndex((obj) => {
            return JSON.stringify(obj) === _value
          })
        )
      })

      businessHours = uniqueArray
    }

    if (!businessHours?.length) {
      businessHours.push({
        daysOfWeek: [],
      })
    }

    return businessHours
  }

  const generateBgEvents = (entity) => {
    const bgEvents = entity?.noWorkTimes?.map((noWt) => {
      const bgEvent = {
        start: toCustomTz(noWt?.startDate, undefined, false, 'YYYY-MM-DDTHH:mm:ss'),
        end: toCustomTz(noWt?.finishDate, undefined, false, 'YYYY-MM-DDTHH:mm:ss'),
        display: 'background',
        id: noWt?._id,
      }
      return bgEvent
    })
    return bgEvents
  }

  let bgEvents = generateBgEvents(entity)

  const entityEvents = entityTurns
    ?.map((turn, index) => ({
      id: `${turn?._id}`,
      editable: turn?.state === 'pending' ? false : true,
      title: `${(function showTitle() {
        if (turn?.patientCentre) {
          if (turn?.patientCentre?.name) {
            return turn?.totalPayments > 0 ? `$ ${turn.patientCentre.name}` : turn.patientCentre.name
          }
          if (turn?.patientCentre?.firstname) {
            return turn.totalPayments > 0
              ? `$ ${turn?.patientCentre?.lastname?.toUpperCase()}, ${turn?.patientCentre?.firstname?.toUpperCase()}`
              : `${turn?.patientCentre?.lastname?.toUpperCase()}, ${turn?.patientCentre?.firstname?.toUpperCase()}`
          }
        } else {
          return '❌ PACIENTE ELIMINADO'
        }
      })()}`,

      start: `${turn.startHour}`.slice(0, -5),
      end: `${turn.finishHour}`.slice(0, -5),
      extendedProps: turn,
      className: `turn ${turn.state} ${!turn.patientCentre ? 'patient-deleted' : ''}`,
    }))
    .concat(bgEvents)

  return (
    <>
      <div
        className={`tourStep2 ${dailyView ? s.calendarBody : s.calendarBodyWeeklyView}`}
        onContextMenu={handleRightClick}
      >
        <FullCalendar
          ref={itemRefs}
          eventsSet={(event) => handleDuplicatedEvents(event)}
          {...calendarDefaultConfiguration}
          eventOrder="creationDate"
          slotLabelInterval={handleTimeConvert(entity?.currentSlotTimes?.lastTurnDuration)}
          slotDuration={handleTimeConvert(entity?.currentSlotTimes?.lastTurnDuration)}
          contentHeight="auto"
          timeZone="local"
          dayHeaderContent={(args) => {
            return dayjs(args.date).format('ddd D/M')
          }}
          slotLabelFormat={{ hour: 'numeric', minute: '2-digit', omitZeroMinute: false, hour12: false }}
          eventTimeFormat={{ hour: '2-digit', minute: '2-digit', hour12: false }}
          slotMinTime={entity?.currentSlotTimes?.startTime || '00:00:00'}
          slotMaxTime={entity?.currentSlotTimes?.finishTime || '24:00:00'}
          businessHours={createBusinessHours(entity)}
          selectConstraint="businessHours"
          eventClick={clickOnCreatedEvent}
          dateClick={clickToCreateNewEvent}
          eventDrop={(info) => updateEventHours(info.event)}
          eventContent={(arg, event) => <FullCalendarEventContent event={arg.event} />}
          eventDidMount={(info) => {
            let turn = info?.event?.extendedProps
            if (!turn?._id) return
            const patientCentreMobile = turn?.patientCentre?.mobile
            if (!patientCentreMobile) return

            let div = document.createElement('div')
            div.className = s.optionsContainer

            const root = createRoot(div)

            root.render(
              <IconButton size={'small'} onClick={() => sendWhatsapp(turn)}>
                {turn?.state !== 'pending' && <WhatsAppIcon />}
              </IconButton>,
            )
            info.el.append(div)
          }}
          events={entityEvents}
        />
      </div>

      {turnInfo && (
        <ContextMenu
          menuProps={menuProps}
          menuAnchorPoint={menuAnchorPoint}
          toggleMenu={toggleMenu}
          turnInfo={turnInfo}
          setOpenRescheduleTurn={setOpenRescheduleTurn}
        />
      )}

      {openFormCreateNewTurn && (
        <FormCreateNewTurn
          entity={selectedEntity}
          openPopUp={openFormCreateNewTurn}
          setOpenPopUp={setOpenFormCreateNewTurn}
        />
      )}
      {openFormUpdateTurn && (
        <UpdateTurnDialog entity={selectedEntity} open={openFormUpdateTurn} setOpen={setOpenFormUpdateTurn} />
      )}

      {openRescheduleTurn && (
        <RescheduleTurn open={openRescheduleTurn} setOpen={setOpenRescheduleTurn} turnInfo={turnInfo} />
      )}
    </>
  )
}
