import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'centres',
  initialState: {
    centreProfile: null,
    allCentres: [],
    paginationData: {
      page: 1,
      limit: 30,
    },
    centreTarget: null,
    error: false,
    isLoaded: null,
    centreAdminDetail: null,
    infoUserInCentreAdmin: null,
    isLoading: false,
    blockedCentre: {
      status: false,
      info: '',
    },
  },
  reducers: {
    FETCH_CENTRES: (state, { payload }) => {
      state.allCentres = payload.allCentres
      state.paginationData = payload.paginationData
      state.isLoaded = false
    },
    CENTRE_SELECTED: (state, { payload }) => {
      state.centreProfile = payload
    },
    CENTRE_TARGET: (state, { payload }) => {
      state.centreTarget = payload
      state.error = false
    },
    CENTRE_UPDATED: (state, { payload }) => {
      state.allCentres = state.allCentres.map((centre) => {
        if (centre._id === payload._id) {
          centre = payload
        }
        return centre
      })
      state.centreProfile = payload
    },
    CENTRE_DELETE: (state, { payload }) => {
      state.allCentres = state.allCentres.filter((centre) => centre._id !== payload)
      state.centreProfile = null
      state.centreAdminDetail = null
    },
    CENTRE_CREATED: (state, { payload }) => {
      state.allCentres = [...(state.allCentres || []), payload]
    },
    RESET_CENTRE_SELECTED: (state, { payload }) => {
      state.centreProfile = null
    },
    RESET_CENTRE_TARGET: (state, { payload }) => {
      state.centreTarget = null
      state.error = false
    },
    CENTRE_ERROR: (state) => {
      state.error = true
    },
    LOADED_CENTRE_ENTITIES: (state, { payload }) => {
      state.isLoaded = payload
    },
    CENTRE_ADMIN_SELECTED: (state, { payload }) => {
      state.centreAdminDetail = payload
    },
    RESET_CENTRE_ADMIN_SELECTED: (state, { payload }) => {
      state.centreAdminDetail = null
    },
    CENTRE_ADMIN_UPDATED: (state, { payload }) => {
      state.allCentres = state.allCentres.map((centre) => {
        if (centre._id === payload._id) {
          centre = payload
        }
        return centre
      })
      state.centreAdminDetail = payload
    },
    INFO_USER_CENTRE_ADMIN_SELECTED: (state, { payload }) => {
      state.infoUserInCentreAdmin = payload
    },
    IS_LOADING_CENTRES: (state, { payload }) => {
      state.isLoading = payload
    },
    BLOCKED_CENTRE: (state, { payload }) => {
      state.blockedCentre = payload
    },
  },
})

export const {
  FETCH_CENTRES,
  BLOCKED_CENTRE,
  CENTRE_SELECTED,
  CENTRE_UPDATED,
  CENTRE_DELETE,
  CENTRE_CREATED,
  RESET_CENTRE_SELECTED,
  RESET_CENTRE_TARGET,
  CENTRE_TARGET,
  CENTRE_ERROR,
  LOADED_CENTRE_ENTITIES,
  CENTRE_ADMIN_SELECTED,
  RESET_CENTRE_ADMIN_SELECTED,
  CENTRE_ADMIN_UPDATED,
  INFO_USER_CENTRE_ADMIN_SELECTED,
  IS_LOADING_CENTRES,
} = slice.actions
export default slice.reducer
