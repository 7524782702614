import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import { removeTemplateTreatment } from '@/store/treatments/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { Button, Paper } from '@mui/material'

import s from './styles.module.scss'

const CardTemplateTreatment = ({ treatmentSelected, modifyTemplateTreatment }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const [openAlertDelete, setOpenAlertDelete] = useState(false)

  const deleteTemplate = () => {
    dispatch(removeTemplateTreatment(treatmentSelected._id))
  }

  return (
    <Paper elevation={3} className={`${s.mainContainer} animate__animated animate__fadeIn`}>
      <p>
        Las plantillas de tratamiento son utilizadas para precargar los datos de los tratamientos al crear un nuevo
        tratamiento para un {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}. Son
        compartidas para todo el centro de salud.
      </p>
      <hr />

      <p className={s.treatmentName}>{treatmentSelected?.name.toUpperCase()}</p>
      <div className={s.divContainer}>
        <div className={s.div1}>
          {treatmentSelected.description && <b>{treatmentSelected?.description}</b>}
          <div>
            Monto total a pagar: $<b>{treatmentSelected?.price || 0}</b>
          </div>
          <div>
            Cantidad de sesiones <b>{treatmentSelected?.turnsCount || 0}</b>
          </div>
        </div>
        <div className={s.div2}>
          <Button variant="contained" onClick={modifyTemplateTreatment} className={s.buttonModify}>
            Modificar
          </Button>
          <Button
            variant="contained"
            disableElevation
            className={s.deleteButton}
            onClick={() => setOpenAlertDelete(!openAlertDelete)}
          >
            Eliminar
          </Button>

          <AlertDialogSlide
            open={openAlertDelete}
            setOpen={setOpenAlertDelete}
            textContent={'¿Realmente quiere eliminar esta plantilla de tratamiento? Esta acción no se podrá deshacer.'}
            textBackButton="Cancelar"
            textConfirmationButton={'Eliminar'}
            onClose={deleteTemplate}
          />
        </div>
      </div>
    </Paper>
  )
}

export default CardTemplateTreatment
