import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { TextOverButtons } from '@/components/TextOverButtons'
import { useSocket } from '@/context/SocketProvider'
import { useCalendar } from '@/pages/AgendaPage/CalendarProvider'
import DownloadTurns from '@/pages/AgendaPage/forms/downloadTurns'
import FormDialog from '@/pages/PatientCentresPage/components/popUpPatient/index'
import { changeCalendarView, selectedEntity, selectedRange } from '@/store/changeViewCalendar/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { showSelectedDateFullName } from '@/utils/functions/showSelectedDateFullName'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { Circle, Download, LocalPrintshopOutlined, MoreVert } from '@mui/icons-material'
import AddLinkIcon from '@mui/icons-material/AddLink'
import { IconButton, Menu, MenuItem, Select } from '@mui/material'

import dayjs from 'dayjs'
import ConsultTurns from '../OptionsAgenda/ConsultTurns'
import TreatmentsPopUp from '../OptionsAgenda/treatments'
import UsefulInformation from '../OptionsAgenda/usefulInformation'
import adapterTurns from './adapterTurns'
import printTurns from './printTurns'

import s from '../../styles.module.scss'

export default function AgendaNavBarButtons({ entity }) {
  const { t } = useTranslation('global')
  const { reconnectSocket, isConnected: isSocketConnected } = useSocket()
  const dispatch = useDispatch()
  const { selectedDate } = useCalendar()
  const { dailyView } = useSelector((state) => state.changeViewCalendar)
  const wappStatus = useSelector((state) => state?.wapp?.status)
  const [openToAddNewPatient, setOpenToAddNewPatient] = useState(false)
  const [openUsefulInformation, setOpenUsefulInformation] = useState(false)
  const [openConsultTurns, setOpenConsultTurns] = useState(false)
  const [openToDownloadTurns, setOpenToDownloadTurns] = useState(false)
  const [openTreatments, setOpenTreatments] = useState(false)

  const { centreProfile } = useSelector((state) => state?.centres)
  const { allTurns } = useSelector((state) => state.turns)
  const today = dayjs(selectedDate).format('YYYY/MM/DD')
  const todayTurns = allTurns?.filter((t) => dayjs(t.startHour).format('YYYY/MM/DD') === today)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const { entityInLocalStorage } = useSelector((state) => state.users.usersProfileConnected)

  const handleMoreOptionsMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const showLinkAdviceIcon = () => {
    return centreProfile?.centreConfiguration?.hasWappModule && entityInLocalStorage?.role !== 'doctor'
  }

  const handlePrintTurns = async () => {
    mxp.track(mxp.events.agenda.turnsOptions.print)
    const turns = await adapterTurns(todayTurns)
    if (!turns) return

    let arrayGroupedTurns = Object.entries(turns)
    arrayGroupedTurns = arrayGroupedTurns.map((elem) => ({
      turns: elem[1],
      calendarName: elem[1][0].machine
        ? elem[1][0].machine.name
        : `${elem[1][0].doctorCentre.lastname}, ${elem[1][0].doctorCentre.firstname}`,
    }))

    printTurns(arrayGroupedTurns, centreProfile)
  }

  const showPopUpToAddPatient = () => {
    mxp.track(mxp.events.agenda.newPatient.open)
    setOpenToAddNewPatient(true)
    handleCloseMenu()
  }

  const showPopUpTreatments = () => {
    mxp.track(mxp.events.agenda.search.treatmentOpen)
    setOpenTreatments(true)
    handleCloseMenu()
  }

  const showPopUpUsefulInformation = () => {
    mxp.track(mxp.events.agenda.centreInfo.open)
    setOpenUsefulInformation(true)
    handleCloseMenu()
  }

  const showPopUpConsultTurns = () => {
    mxp.track(mxp.events.agenda.search.turnOpen)
    setOpenConsultTurns(true)
    handleCloseMenu()
  }

  const handleChangeCalendarView = (value) => {
    mxp.track(mxp.events.agenda.viewSelector.changed)
    dispatch(selectedEntity(entity[0]))
    dispatch(changeCalendarView(!dailyView))

    if (value === 'week') {
      let week_start = dayjs(selectedDate).startOf('week').utc(true).format()
      let week_end = dayjs(selectedDate).endOf('week').utc(true).format()

      let rangeData = {
        week_start,
        week_end,
      }
      dispatch(selectedRange(rangeData))
    }
  }

  const statusInfo = () => {
    return (
      <div>
        <div>{`Número: ${wappStatus?.phoneNumber} (${wappStatus?.phoneName})`}</div>
        <div>{`Última conexión:  ${dayjs(wappStatus?.phoneLastSync).format('DD/MM/YYYY [a las] HH:mm [hs]')} `}</div>
      </div>
    )
  }

  return (
    <div className={s.optionsAgenda}>
      <p className={s.selectedDateFullName}>{showSelectedDateFullName(selectedDate)}</p>
      <div className={s.subContainer}>
        <Select
          value={dailyView ? 'daily' : 'week'}
          onChange={(e) => handleChangeCalendarView(e.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          variant="outlined"
          className={s.selectView}
        >
          <MenuItem value={'daily'}>Vista diaria</MenuItem>
          <MenuItem value={'week'}>Vista semanal</MenuItem>
        </Select>

        <TextOverButtons title={`Imprimir ${t('turnLabel.p')} de hoy`}>
          <IconButton size={'small'} onClick={() => handlePrintTurns()}>
            <LocalPrintshopOutlined className={s.agendaOptionIcon} />
          </IconButton>
        </TextOverButtons>

        <TextOverButtons title={`Descargar planilla de ${t('turnLabel.p')}`}>
          <IconButton
            size={'small'}
            onClick={() => {
              mxp.track(mxp.events.agenda.turnsOptions.openDownloadTurns)
              setOpenToDownloadTurns(true)
            }}
          >
            <Download className={s.agendaOptionIcon} />
          </IconButton>
        </TextOverButtons>
        {showLinkAdviceIcon() && (
          <Link to={`/recordatorios`}>
            <TextOverButtons title={wappStatus?.status === 'connected' ? statusInfo() : 'Enlazar dispositivo'}>
              <IconButton size={'small'}>
                <AddLinkIcon
                  className={wappStatus?.status === 'connected' ? s.statusConnected : s.statusDisconnected}
                />
              </IconButton>
            </TextOverButtons>
          </Link>
        )}
        <IconButton size={'small'} onClick={handleMoreOptionsMenuClick}>
          <MoreVert className={s.agendaOptionIcon} />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          }}
        >
          <MenuItem onClick={showPopUpToAddPatient} className={s.hoverText}>
            Crear {t(`subCategoryLabel.${getCentreSubCategory(centreProfile?.category)}.patient`)}
          </MenuItem>
          <MenuItem onClick={showPopUpTreatments} className={s.hoverText}>
            Buscar grupo de sesiones
          </MenuItem>
          <MenuItem onClick={showPopUpConsultTurns} className={s.hoverText}>
            Buscar {t('turnLabel.p')}
          </MenuItem>
          <MenuItem onClick={showPopUpUsefulInformation} className={s.hoverText}>
            Información de Horarios
          </MenuItem>
        </Menu>

        <TextOverButtons title={isSocketConnected ? 'Activo' : 'Conexión incorrecta. clic para reconectar.'}>
          <IconButton size={'small'} onClick={() => reconnectSocket()}>
            <Circle className={isSocketConnected ? s.socketConnected : s.socketDisconnected} />
          </IconButton>
        </TextOverButtons>
      </div>

      {!!openToAddNewPatient && <FormDialog open={openToAddNewPatient} onClose={() => setOpenToAddNewPatient(false)} />}

      {!!openUsefulInformation && (
        <UsefulInformation open={openUsefulInformation} onClose={() => setOpenUsefulInformation(false)} />
      )}

      {!!openConsultTurns && <ConsultTurns open={openConsultTurns} onClose={setOpenConsultTurns} />}

      {!!openToDownloadTurns && (
        <DownloadTurns open={openToDownloadTurns} onClose={() => setOpenToDownloadTurns(false)} />
      )}

      {openTreatments && <TreatmentsPopUp open={openTreatments} onClose={() => setOpenTreatments(false)} />}
    </div>
  )
}
