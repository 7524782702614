import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaRegCopy } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { getTurnStateName } from '@/utils/functions/getTurnStateName'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { showToast } from '@/utils/toast'
import { Button, Divider, Grid } from '@mui/material'

import dayjs from 'dayjs'
import printResume from './printResume'

import s from './styles.module.scss'

const ResumenTurnTab = () => {
  const { t } = useTranslation('global')

  const turn = useSelector((state) => state.turns.selectedTurn)
  const patient = useSelector((state) => state.turns.selectedTurn.patientCentre)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const getTurnDate = () => {
    let startDate = dayjs(turn.startHour).utc().format('dddd DD/MM/YYYY HH:mm ')
    return startDate
  }

  const handlePrintResume = async () => {
    printResume()
  }

  const copyToClipboard = (string) => {
    navigator.clipboard.writeText(string)
    showToast('Id copiado en portapapeles', 'success', 2000)
  }
  const showLogState = (log) => {
    if (log?.comment === 'cancel-turn') return 'Cancelado'
    if (log?.comment === 'create') return 'Creado'
    if (log?.comment === 'update' && log.state === 'init') return 'Actualizado'
    return getTurnStateName(log.state)
  }

  const turnLogs = () => {
    const turnLog = turn.logs.map((turn) => turn)
    turnLog.sort((a, b) => a.date - b.date).reverse()

    return (
      <ul style={{ listStyle: 'square inside', fontSize: '12px' }}>
        {turnLog.map((log, i) => (
          // ATTENTION: log.date is rendered differently because it is generated from the DB
          <li key={i}>{`${toCustomTz(log?.date, undefined, false, 'dddd DD/MM/YYYY HH:mm')}hs (${showLogState(
            log,
          )}) -- por ${log.by.toUpperCase()} 
          `}</li>
        ))}
      </ul>
    )
  }

  const showService = () => {
    if (!turn.service) {
      return turn.firstTime === false ? 'Consulta' : 'Primera consulta'
    } else {
      return capitalizeFirstLetters(turn?.service?.name)
    }
  }

  return (
    <Grid container paddingLeft={1} paddingTop={2} className="animate__animated animate__fadeIn">
      <Grid container>
        <Grid item>
          <span className={s.title}>Generar impresion {t('turnLabel.sd')} individual : </span>
          <Button size={'small'} className={s.printButton} onClick={() => handlePrintResume()}>
            IMPRIMIR
          </Button>
        </Grid>
      </Grid>
      <Grid id="print-turn" container className={s.gridContainer}>
        <Grid item className={s.gridItem}>
          <div className={s.container}>
            <p className={s.underlineText}>Resumen {t('turnLabel.sd')}</p>
            {patient ? (
              <div>
                <b>{`${capitalizeFirstLetters(patient.lastname)}, ${capitalizeFirstLetters(patient.firstname)}`}</b>{' '}
                {patient?.dni?.length < 15 && <span>{`DNI: ${patient.dni}`}</span>}{' '}
                <span className={s.copyToClipboard} onClick={() => copyToClipboard(patient?._id)}>
                  <FaRegCopy />
                </span>
              </div>
            ) : (
              <b>{`${t(
                `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`,
              ).toUpperCase()} ELIMINADO`}</b>
            )}
            <div>
              {' '}
              <span>Fecha y hora: </span> <b>{`${getTurnDate()} hs`}</b>
            </div>
            <div>
              <span>Profesional: </span>{' '}
              {turn.doctorCentre ? (
                <b>{`${capitalizeFirstLetters(turn.doctorCentre.lastname)}, ${capitalizeFirstLetters(
                  turn.doctorCentre.firstname,
                )}`}</b>
              ) : (
                <b>-.-</b>
              )}
            </div>
            <div>
              <span>Servicio: </span> <b>{showService()}</b>
            </div>

            <div>
              <span>{t('insuranceLabel.S')}: </span>
              <b>{`${turn?.insurance?.name || `No hay ${t('insuranceLabel.s')} definida`}`}</b>
            </div>
            <div>
              <span>Teléfono Móvil: </span>
              <b>{`${patient?.mobile || 'No registrado'}`}</b>
            </div>
            <div>
              <span>Telefono: </span>
              <b>{` ${patient?.telephone || 'No registrado'}`}</b>
            </div>
            <br />
            <br />
            <Divider />
            <div className={s.centreInfo}>
              <p>
                <b>{capitalizeFirstLetters(centreDetail?.name)}</b>
              </p>
            </div>

            {centreDetail?.city && (
              <div>
                <span>Dirección: </span>
                <b>
                  {centreDetail?.street && `${centreDetail?.street},`}{' '}
                  {centreDetail?.city?.formatted_address || centreDetail?.city}
                </b>
              </div>
            )}

            {centreDetail?.phones && (
              <div>
                <span>Teléfono: </span>
                <b>{centreDetail?.phones[0]}</b>
              </div>
            )}

            <Divider sx={{ bgcolor: '#1c83c6', margin: '20px 0', opacity: '0.3' }} />
            <p className={s.underlineText}>{`Actualizaciones ${t('turnLabel.sd')}`}</p>
            <div>{turnLogs()}</div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ResumenTurnTab
