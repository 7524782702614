import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import analytics from '@/utils/functions/handleTracking'

export default function useGoogleAnalytics() {
  const location = useLocation()

  useEffect(() => {
    if (!import.meta.env.REACT_APP_GA_ACTIVATE) return
    analytics.initTracking()
  }, [])

  useEffect(() => {
    if (!import.meta.env.REACT_APP_GA_ACTIVATE) return
    const currentPath = location.pathname
    analytics.sendPageview(currentPath)
  }, [location])
}
