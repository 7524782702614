import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import CaluButton from '@/components/styledComponents/CaluButton'
import { apiURL } from '@/helpers/env'
import { deleteCentreMercadoPagoInfo } from '@/store/centreMercadoPagoInfo/actions'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'

import dayjs from 'dayjs'

import s from '../styles.module.scss'

const MercadoPagoCard = () => {
  const dispatch = useDispatch()
  const mercadoPagoRedirectURL = `${apiURL}api/mercadopago/callback`
  const mercadoPagoClientId = import.meta.env.REACT_APP_MERCADOPAGO_CLIENT_ID

  const { detail: centreMercadoPagoInfo } = useSelector((state) => state?.centreMercadoPagoInfo)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const [openAlertDelete, setOpenAlertDelete] = useState(false)
  const [checked, setChecked] = useState(false)

  const handleUnlinkMercadoPago = async (centreMercadoPagoInfoId) => {
    await dispatch(deleteCentreMercadoPagoInfo(centreMercadoPagoInfoId))
  }

  const statusMercadoPagoConexion = () => {
    const currentDate = dayjs().format('YYYY-MM-DD')
    const creationDateMp = dayjs(centreMercadoPagoInfo?.creationDate).format('YYYY-MM-DD')
    const diff = dayjs(currentDate).diff(creationDateMp, 'days')

    if (diff <= 173) {
      return <span className={s.activeColor}>Activado</span>
    }
    return <span className={s.warningColor}>Actualización pendiente</span>
  }

  return (
    <div className={s.cardContainer}>
      <h4>Mercado pago</h4>
      <div className={s.divider}></div>
      <div className={s.itemContainer}>
        {!centreMercadoPagoInfo ? (
          <div>
            <Link
              to={
                checked
                  ? `https://auth.mercadopago.com.ar/authorization?client_id=${mercadoPagoClientId}&response_type=code&platform_id=mp&state=${centreDetail?.id}&redirect_uri=${mercadoPagoRedirectURL}`
                  : ''
              }
              style={{ pointerEvents: checked ? 'auto' : 'none' }}
            >
              <CaluButton disabled={!checked} color="primary">
                Enlazar MercadoPago
              </CaluButton>
            </Link>
            <div className={s.msgMP}>
              <p>
                La comisión de uso de la integración con MercadoPago es de{' '}
                <span className={s.bold}>{centreDetail?.centreConfiguration?.mercadopagoApplicationFee}%</span> por cada
                transacción realizada. Sólo aplica a los enlaces generados automáticamente en la plataforma Calu.{' '}
              </p>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />}
                  label="Estoy de acuerdo"
                />
              </FormGroup>
            </div>
          </div>
        ) : (
          <>
            <p>
              <span className={s.bolder}>MercadoPago:</span> {statusMercadoPagoConexion()}
            </p>
            <p>
              <span className={s.bolder}>MercadoPago cuenta:</span> <span>{centreMercadoPagoInfo?.nickName}</span>{' '}
            </p>
            <div className={s.containerErrorButton}>
              <CaluButton color="error" size="medium" onClick={() => setOpenAlertDelete(!openAlertDelete)}>
                Desenlazar MercadoPago
              </CaluButton>
              <AlertDialogSlide
                open={openAlertDelete}
                title={'Desenlazar Mercado Pago'}
                setOpen={setOpenAlertDelete}
                textContent={'¿Realmente quiere desconectar su cuenta de Mercado Pago?'}
                textBackButton="Cancelar"
                textConfirmationButton={'Si'}
                onClose={() => handleUnlinkMercadoPago(centreMercadoPagoInfo?._id)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default MercadoPagoCard
