import { useController } from 'react-hook-form'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { parsedDniValue } from '@/utils/functions/parsedDniValue'
import { TextField } from '@mui/material'

import PropTypes from 'prop-types'

export const CustomField = ({
  className,
  fullWidth,
  label,
  name,
  type,
  multiline,
  rows,
  disabled,
  maxRows,
  maxLength,
  placeholder,
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({ name })

  return (
    <TextField
      className={className}
      error={Boolean(error)}
      fullWidth={fullWidth}
      helperText={Boolean(error) && error?.message}
      label={label || name.toUpperCase()}
      placeholder={placeholder || ''}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      ref={ref}
      disabled={disabled}
      type={type}
      value={['dni', 'patientCentre[dni]']?.includes(name) ? parsedDniValue(value) || '' : value || ''}
      variant="standard"
      multiline={multiline}
      rows={rows}
      maxRows={maxRows}
      onWheel={(e) => e.target.blur()}
      inputProps={{
        maxLength: maxLength,
        style: { textTransform: name === 'lastname' || name === 'firstname' ? 'capitalize' : 'none' },
      }}
      onKeyDown={multiline ? null : handleKeyDown}
    />
  )
}

CustomField.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  maxRows: PropTypes.number,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
}

CustomField.defaultProps = {
  fullWidth: false,
  type: 'text',
  multiline: false,
  rows: 0,
  disabled: false,
  maxRows: 15,
  maxLength: 1000,
}
