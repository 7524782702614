import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import CaluButton from '@/components/styledComponents/CaluButton'
import CaluInput from '@/components/styledComponents/CaluInput'
import TitlePopUp from '@/components/TitlePopUp'
import { extrasAmount, paymentStatus, subscriptionPaymentType } from '@/helpers/constants'
import {
  deleteSubscriptionPaymentAdded,
  postNewSubscriptionPayment,
  updateSubscriptionPayment,
} from '@/store/subscriptionPayments/actions'
import { convertIdToName } from '@/utils/functions/convertIdToName'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { Dialog, DialogContent, DialogTitle, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const VoucherPopUp = ({ open, setOpen, dataVoucher }) => {
  const dispatch = useDispatch()

  const { selectedPayment } = useSelector((state) => state?.subscriptionPayments)
  const { allSubscriptionPlans } = useSelector((state) => state?.subscriptionPlans)
  const centreAdminDetail = useSelector((state) => state?.centres?.centreAdminDetail)

  const [dueDate, setDueDate] = useState(selectedPayment ? dayjs(selectedPayment?.dueDate).utc() : dayjs())
  const [period, setPeriod] = useState(selectedPayment ? dayjs(selectedPayment?.period).utc() : dayjs())
  const [status, setStatus] = useState(selectedPayment ? selectedPayment?.paymentStatus : 'pending')
  const [auxPaymentDate, setAuxPaymentDate] = useState(null)
  const [loadingPayment, setLoadingPayment] = useState(false)
  const [openAlertDelete, setOpenAlertDelete] = useState(false)
  const [finalAmount, setFinalAmount] = useState(selectedPayment ? selectedPayment?.amount : dataVoucher?.amount)
  const [partiallyPaidAmount, setPartiallyPaidAmount] = useState(
    selectedPayment ? selectedPayment?.paidAmount : dataVoucher?.paidAmount,
  )

  const isAnualSubscription = centreAdminDetail?.centreConfiguration?.isAnualSubscription

  const determineVoucherType = () => {
    if (selectedPayment) {
      if (selectedPayment?.isAnual) {
        return 'ANUAL'
      } else {
        return 'MENSUAL'
      }
    } else {
      if (isAnualSubscription) {
        return 'ANUAL'
      } else {
        return 'MENSUAL'
      }
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCreateOrEditSubscriptionPayment = async () => {
    setLoadingPayment(true)

    const auxdataVoucher = selectedPayment
      ? {
          ...selectedPayment,
          paymentStatus: status,
          paymentDate: auxPaymentDate,
          amount: Number(finalAmount),
          paidAmount: Number(partiallyPaidAmount),
        }
      : {
          ...dataVoucher,
          amount: Number(finalAmount),
          paidAmount: Number(partiallyPaidAmount),
        }

    auxdataVoucher.dueDate = dayjs(dueDate).utc(true).hour(0).minute(0).second(0).format()
    auxdataVoucher.period = dayjs(period).utc(true).startOf('month').format()

    if (!auxdataVoucher?.subscriptionExtraAmount?.type) {
      delete auxdataVoucher.subscriptionExtraAmount
    }

    if (isAnualSubscription) {
      auxdataVoucher.isAnual = true
    }

    try {
      if (selectedPayment) {
        await dispatch(updateSubscriptionPayment(auxdataVoucher))
      } else {
        await dispatch(postNewSubscriptionPayment(auxdataVoucher))
      }
      setLoadingPayment(false)
      handleClose()
    } catch (error) {
      console.log(error)
      setLoadingPayment(false)
    }
  }

  const handleChangeStatus = (e) => {
    if (e.target.value === 'paid') {
      setAuxPaymentDate(dayjs().utc(true).hour(0).minute(0).second(0).format())
    }
    if (e.target.value === 'pending') {
      setAuxPaymentDate(null)
    }
    setStatus(e.target.value)
  }

  const handleDeleteVoucher = async () => {
    setLoadingPayment(true)
    await dispatch(deleteSubscriptionPaymentAdded(selectedPayment?._id))
    setLoadingPayment(false)
    handleClose()
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} open={open} maxWidth="md" fullWidth onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={selectedPayment ? 'Editar cupón de pago' : 'Crear nuevo cupón de pago'}
            handleClose={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className={s.mainContainer}>
            <Grid item xs={12}>
              <span className={s.bold}>Centro:</span>{' '}
              <span>{selectedPayment ? selectedPayment?.centre?.name : dataVoucher?.centre?.name}</span>
            </Grid>
            <Grid item xs={12}>
              <span className={s.bold}>Cantidad de agendas:</span>{' '}
              <span>
                {selectedPayment
                  ? selectedPayment?.subscriptionAgendasQuantity
                  : dataVoucher?.subscriptionAgendasQuantity}
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className={s.bold}>Extras o descuentos:</span>{' '}
              <span>
                {selectedPayment
                  ? convertValueToName(extrasAmount, selectedPayment?.subscriptionExtraAmount?.type)
                  : convertValueToName(extrasAmount, dataVoucher?.subscriptionExtraAmount?.type)}
              </span>{' '}
              <span>
                {handleSetMoneySign(selectedPayment ? selectedPayment?.currency : dataVoucher?.currency)}{' '}
                {selectedPayment
                  ? selectedPayment?.subscriptionExtraAmount?.value
                  : dataVoucher?.subscriptionExtraAmount?.value}
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className={s.bold}>Plan de suscripción:</span>{' '}
              <span>
                {selectedPayment
                  ? selectedPayment?.subscriptionPlan?.name
                  : convertIdToName(allSubscriptionPlans, dataVoucher?.subscriptionPlan)}
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className={s.bold}>Tipo de suscripción:</span>{' '}
              <span>
                {selectedPayment
                  ? convertValueToName(subscriptionPaymentType, selectedPayment?.subscriptionPaymentType)
                  : convertValueToName(subscriptionPaymentType, dataVoucher?.subscriptionPaymentType)}
              </span>
            </Grid>
            <Grid item xs={12} className={s.finalAmountContainer}>
              <span className={s.finalAmountLabel}>Monto final:</span>{' '}
              <span className={s.finalAmountValue}>
                <CaluInput
                  id="amount"
                  type="number"
                  autoComplete="off"
                  value={finalAmount}
                  onChange={(e) => setFinalAmount(e.target.value)}
                  className={s.searchBarContainer}
                />
              </span>
            </Grid>
            <Grid item xs={12} className={s.finalAmountContainer}>
              <span className={s.finalAmountLabel}>Monto pagado:</span>{' '}
              <span className={s.finalAmountValue}>
                <CaluInput
                  id="amount"
                  type="number"
                  autoComplete="off"
                  value={partiallyPaidAmount}
                  onChange={(e) => setPartiallyPaidAmount(e.target.value)}
                  className={s.searchBarContainer}
                />
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className={s.bold}>Moneda:</span>{' '}
              <span>{selectedPayment ? selectedPayment?.currency : dataVoucher?.currency}</span>
            </Grid>
            <Grid item xs={12}>
              {selectedPayment ? (
                <>
                  <InputLabel className={s.inputLabel}>Estado</InputLabel>
                  <Select
                    onChange={handleChangeStatus}
                    label="Estado"
                    variant="standard"
                    value={status}
                    sx={{
                      fontSize: '14px',
                      width: '150px',
                    }}
                  >
                    {paymentStatus?.map((plan) => (
                      <MenuItem key={plan.value} value={plan.value}>
                        {`${plan.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              ) : (
                <>
                  <span className={s.bold}>Estado:</span> <span>{convertValueToName(paymentStatus, status)}</span>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Fecha de vencimiento"
                value={dayjs(dueDate)}
                format="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDueDate(newDate)
                }}
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Periodo de pago"
                views={['month', 'year']}
                value={dayjs(period)}
                format="MM/YYYY"
                onChange={(newDate) => {
                  setPeriod(newDate)
                }}
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <span className={s.bold}>Tipo de cupón: </span>
              <span>{determineVoucherType()}</span>
            </Grid>

            <Grid item xs={12}>
              <div className={s.contButtons}>
                {selectedPayment && (
                  <>
                    <CaluButton
                      color="error"
                      size="medium"
                      loading={loadingPayment}
                      onClick={() => setOpenAlertDelete(!openAlertDelete)}
                    >
                      Eliminar
                    </CaluButton>
                    <AlertDialogSlide
                      open={openAlertDelete}
                      title={'Eliminar cupón'}
                      setOpen={setOpenAlertDelete}
                      textContent={'¿Realmente quiere eliminar este cupón? Esta acción no se podrá restaurar.'}
                      textBackButton="Cancelar"
                      textConfirmationButton={'Eliminar'}
                      onClose={handleDeleteVoucher}
                    />
                  </>
                )}
                <CaluButton
                  color="primary"
                  size="medium"
                  loading={loadingPayment}
                  onClick={() => handleCreateOrEditSubscriptionPayment()}
                >
                  {selectedPayment ? 'Guardar' : 'Crear cupón'}
                </CaluButton>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default VoucherPopUp
