import i18next from '@/configs/i18next'
import { createWappReminder, getWappTurnReminderById, sendWappMessage } from '@/store/wapp/actions'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { showToast } from '@/utils/toast'

import { handleSetCountryCode } from '../handleSetCountryCode'
import {
  sendWhatsappQuickRegister,
  sendWhatsappRescheduleMessage,
  sendWhatsappSetTurnHour,
  sendWhatsappToRemindTurn,
  sendWhatsappTurnCancelled,
  sendWhatsappTurnCreation,
  sendWhatsappTurnDetail,
} from '../whatsappMessages'

export const handleWappReminder = async (data, hideAlert) => {
  // data = { selectedTurn, state, dispatch, wappStatus }
  mxp.track(mxp.events.agenda.turnEvent.whatsappClicked)
  const { selectedTurn } = data
  const { centre } = selectedTurn
  const state = data?.state || selectedTurn?.state
  if (data?.wappStatus?.status === 'connected') {
    const turnLabel = i18next.t('turnLabel.s', { ns: 'global' })
    const patientCentre = selectedTurn?.patientCentre
    const turnWappReminder = await data.dispatch(getWappTurnReminderById(selectedTurn?._id))
    const countryCode = handleSetCountryCode(patientCentre?.country || import.meta.env.REACT_APP_COUNTRY)
    const isWappConnected = true
    let messageType
    let text
    if (state === 'init') {
      messageType = 'Recordatorio de cita'
      text = await sendWhatsappToRemindTurn(selectedTurn, centre, isWappConnected)
    } else if (state === 'finished') {
      messageType = 'Cita finalizada'
      text = await sendWhatsappTurnDetail(selectedTurn, centre, isWappConnected)
    } else if (state === 'cancelled') {
      messageType = 'Cita cancelada'
      text = await sendWhatsappTurnCancelled(selectedTurn, centre, isWappConnected)
    } else if (state === 'createAcountInvitation') {
      messageType = 'Cita reprogramada'
      text = await sendWhatsappQuickRegister(selectedTurn, centre, isWappConnected)
    } else if (state === 'rescheduleTurn') {
      text = await sendWhatsappRescheduleMessage(selectedTurn, centre, isWappConnected)
    } else if (state === 'turnCreation') {
      messageType = 'Cita agendada'
      text = await sendWhatsappTurnCreation(selectedTurn, centre, isWappConnected)
    } else if (state === 'undatedTurn') {
      messageType = 'Cita sin fecha'
      text = await sendWhatsappSetTurnHour(selectedTurn, centre, isWappConnected)
    } else {
      messageType = 'Recordatorio de cita'
      text = await sendWhatsappToRemindTurn(selectedTurn, centre, isWappConnected)
    }

    if (!text) {
      showToast(`La fecha de ${turnLabel} ya pasó`, 'error', 3000)
      return
    }
    const auxMessageData = {
      text: text,
      number: `${countryCode}${patientCentre.mobile}`,
    }
    if (auxMessageData?.number) {
      try {
        const sentStatusInfo = await data.dispatch(sendWappMessage(centre?._id, auxMessageData))
        const attemptData = {
          centre: centre._id,
          patientCentre: patientCentre._id,
          turn: selectedTurn._id,
          message: auxMessageData?.text,
          status: sentStatusInfo?.status === 1 ? 'sent' : 'error',
          mobile: `${countryCode}${patientCentre.mobile}`,
          senderNumber: data.wappStatus.phoneNumber,
          messageType: messageType,
          _id: turnWappReminder[0]?._id || null,
        }
        const createdWappReminder = await data.dispatch(createWappReminder(attemptData))
        if (!hideAlert) {
          showToast('Recordatorio enviado correctamente', 'success', 2000)
        }
        return createdWappReminder
      } catch (e) {
        console.log(e)
      }
    }
  } else {
    if (state === 'init') return sendWhatsappToRemindTurn(selectedTurn, centre)
    if (state === 'finished') return sendWhatsappTurnDetail(selectedTurn, centre)
    if (state === 'cancelled') return sendWhatsappTurnCancelled(selectedTurn, centre)
    if (state === 'createAcountInvitation') return sendWhatsappQuickRegister(selectedTurn, centre)
    if (state === 'turnCreation') return sendWhatsappTurnCreation(selectedTurn, centre)
    if (state === 'rescheduleTurn') return sendWhatsappRescheduleMessage(selectedTurn, centre)
    if (state === 'undatedTurn') return sendWhatsappSetTurnHour(selectedTurn, centre)

    return sendWhatsappToRemindTurn(selectedTurn, centre)
  }
}
