import { Controller, useController } from 'react-hook-form'
import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material'

import PropTypes from 'prop-types'

export const SelectField = ({ children, className, fullWidth, label, name, disabled, multiple }) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({ name })

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 400,
        width: 550,
      },
    },
  }

  return (
    <Controller
      name={name}
      render={() => (
        <FormControl fullWidth={fullWidth} variant="standard">
          <InputLabel error={Boolean(error)}>{label}</InputLabel>
          <Select
            multiple={multiple || false}
            disabled={disabled}
            className={className}
            error={Boolean(error)}
            fullWidth={fullWidth}
            label={label || name.toUpperCase()}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            value={value !== undefined && value !== null ? value : ''}
            MenuProps={MenuProps}
          >
            {children}
          </Select>
          {Boolean(error) && <FormHelperText error>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

SelectField.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
}

SelectField.defaultProps = {
  fullWidth: false,
  type: 'text',
}
