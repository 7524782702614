import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'templatesHC',
  initialState: {
    allTemplatesHC: [],
    templateHCSelected: null,
    paginationData: {
      page: 1,
      limit: 30,
    },
    isLoading: false,
  },
  reducers: {
    FETCH_TEMPLATES_HC: (state, { payload }) => {
      state.allTemplatesHC = payload.allTemplatesHC
      state.paginationData = payload.paginationData
    },
    TEMPLATE_UPDATED: (state, { payload }) => {
      state.allTemplatesHC = state.allTemplatesHC.map((template) => {
        if (template._id === payload._id) {
          template = payload
        }
        return template
      })
      state.templateHCSelected = payload
    },
    TEMPLATE_DELETE: (state, { payload }) => {
      state.allTemplatesHC = state.allTemplatesHC.filter((template) => template._id !== payload)
      state.templateHCSelected = null
    },
    TEMPLATE_CREATED: (state, { payload }) => {
      state.allTemplatesHC = [...(state.allTemplatesHC || []), payload]
      state.templateHCSelected = payload
    },
    TEMPLATE_SELECTED: (state, { payload }) => {
      state.templateHCSelected = payload
    },
    RESET_TEMPLATE_SELECTED: (state, { payload }) => {
      state.templateHCSelected = null
    },
    LOADING_TEMPLATES_HC: (state, { payload }) => {
      state.isLoading = payload
    },
  },
})

export const {
  FETCH_TEMPLATES_HC,
  TEMPLATE_UPDATED,
  TEMPLATE_DELETE,
  TEMPLATE_CREATED,
  TEMPLATE_SELECTED,
  RESET_TEMPLATE_SELECTED,
  LOADING_TEMPLATES_HC,
} = slice.actions
export default slice.reducer
