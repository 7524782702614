import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { INTERNAL_CENTRE_NOTE_SELECTED } from '@/store/internalCentreNotes'
import { handlePaginationChangeInternalCentreNotes } from '@/store/internalCentreNotes/actions'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Button, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import s from './styles.module.scss'

const InternalCentreNotesTable = ({ setOpenNotePopUp }) => {
  const dispatch = useDispatch()
  const { allInternalCentreNotes, paginationData, isLoading } = useSelector((state) => state?.internalCentreNotes)

  const { limit, totalDocs, page } = paginationData || {}

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  }

  const columns = [
    {
      field: 'date',
      headerName: 'Fecha',
      minWidth: 100,
      cellClassName: 'periodStyle',
      ...stylesColumns,
    },
    {
      field: 'title',
      headerName: 'Titulo',
      minWidth: 100,
      ...stylesColumns,
    },
    {
      field: 'message',
      headerName: 'Mensaje',
      minWidth: 100,
      ...stylesColumns,
    },
    {
      field: 'raw',
      headerName: '',
      minWidth: 100,
      maxWidth: 140,
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title="Editar">
          <Button
            onClick={() => handleClickEdit(params?.value)}
            className={s.rowButton}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            <EditOutlinedIcon />
          </Button>
        </Tooltip>
      ),
    },
  ]

  const rows = allInternalCentreNotes?.map((note) => {
    return {
      id: note?._id,
      date: toCustomTz(note?.creationDate, undefined, true, 'YYYY-MM-DD'),
      title: note?.title || '',
      message: note?.message || '',
      raw: note,
    }
  })

  const handleClickEdit = async (data) => {
    dispatch(INTERNAL_CENTRE_NOTE_SELECTED(data))
    setOpenNotePopUp(true)
  }

  const handlePaginationChange = ({ page, pageSize }) => {
    dispatch(handlePaginationChangeInternalCentreNotes(page + 1, pageSize))
  }

  return (
    <div>
      {allInternalCentreNotes?.length === 0 ? (
        <p className={s.noNotes}>No hay notas generadas</p>
      ) : (
        <Box
          sx={{
            px: 0,
            '& .header': {
              fontSize: '14px',
            },
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
        >
          <DataGrid
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: limit,
                },
              },
            }}
            disableColumnMenu={true}
            disableColumnReorder={true}
            rows={rows || []}
            rowCount={totalDocs || 0}
            loading={isLoading}
            columns={columns}
            paginationMode="server"
            pagination
            onPaginationModelChange={handlePaginationChange}
            pageSizeOptions={[30, 50, 100]}
            disableRowSelectionOnClick={true}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            slotProps={{
              pagination: {
                labelRowsPerPage: 'Entradas por página:',
                labelDisplayedRows: () =>
                  `${(page - 1) * limit + 1} - ${page * limit > totalDocs ? totalDocs : page * limit} de ${totalDocs}`,
              },
            }}
            sx={{ fontSize: '13px', marginBottom: '20px' }}
            rowHeight={45}
            autoHeight={true}
            sortModel={[{ field: 'date', sort: 'desc' }]}
          />
        </Box>
      )}
    </div>
  )
}

export default InternalCentreNotesTable
