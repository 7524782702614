import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import defaultImage from '@/assets/images/default.png'
import { TextOverButtons } from '@/components/TextOverButtons'
import DoctorCentresPopUp from '@/pages/AdministracionPage/components/popUp/DoctorCentresPopUp'
import PopUpMachine from '@/pages/MachinesPage/components/popUpMachine'
import { DOCTOR_CENTRE_SELECTED } from '@/store/doctorCentres'
import { resetSelectedDoctorCentre, setCentreEntity } from '@/store/doctorCentres/actions'
import { MACHINE_SELECTED } from '@/store/machines'
import { resetSelectedMachine } from '@/store/machines/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import { IconButton } from '@mui/material'

import dayjs from 'dayjs'
import FormCreateNewTurn from '../formAgendaEvent/formCreateNewTurn'

import s from '../../styles.module.scss'

export default function CalendarHeader({ entity, selectedDate }) {
  const { t } = useTranslation('global')
  const dispatch = useDispatch()

  const centreDetail = useSelector((state) => state?.centres?.centreProfile)
  const allTurns = useSelector((state) => state.turns.allTurns)

  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const [dayPayment, setDayPayment] = useState(0)

  const [selectedAgenda, setSelectedAgenda] = useState({})
  const [openToAddNewUndatedTurn, setOpenToAddNewUndatedTurn] = useState(false)
  const [openToModifyDoctorCentre, setOpenToModifyDoctorCentre] = useState(false)
  const [openToModifyMachine, setOpenToModifyMachine] = useState(false)

  const handleShowRoomName = (entityId) => {
    const room = centreDetail?.rooms?.find((room) => room?.people[0]?._id === entityId)
    return room?.name
  }

  const getDayPayment = () => {
    if (!entity) return

    let dayPayment = 0
    allTurns?.forEach((turn) => {
      if (turn?.startHour && dayjs(turn?.startHour).format('DD-MM-YYYY') !== dayjs(selectedDate).format('DD-MM-YYYY'))
        return

      if (turn?.totalPayments !== 0) {
        if (entity?._id === turn?.doctorCentre?._id && !turn?.machine) {
          dayPayment += turn?.totalPayments
        }

        if (entity?._id === turn?.machine?._id) {
          dayPayment += turn?.totalPayments
        }
      }
    })

    return dayPayment
  }

  useEffect(() => {
    if (!centreDetail?.hidePaymentInfo) setDayPayment(getDayPayment())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTurns, selectedDate])

  const newUndatedTurn = async () => {
    setSelectedAgenda({
      id: entity._id,
    })
    await dispatch(setCentreEntity(entity._id))
    setOpenToAddNewUndatedTurn(true)
  }

  const handleConfigAgendaPanelClose = async () => {
    await dispatch(resetSelectedDoctorCentre())
    setOpenToAddNewUndatedTurn(false)
  }

  const modifyDoctorOrMachine = () => {
    if (entity.firstname) {
      setOpenToModifyDoctorCentre(true)
      dispatch(DOCTOR_CENTRE_SELECTED(entity))
      dispatch(resetSelectedMachine())
    } else {
      setOpenToModifyMachine(true)
      dispatch(MACHINE_SELECTED(entity))
      dispatch(resetSelectedDoctorCentre())
    }
  }

  return (
    <div className={s.headerContainer}>
      <div className={s.entityInfo}>
        <img
          src={entity?.image || defaultImage}
          className={entity?.image ? s.entityLogo : s.defaultImage}
          alt="Imagen de perfil"
        />
        <div className={s.agendaName}>
          <TextOverButtons
            title={entity?.price && `-- Precio consulta particular ${handleSetMoneySign(country)} ${entity.price}`}
          >
            <div>
              <span className="agenda-name">
                {entity?.lastname
                  ? `${capitalizeFirstLetters(entity?.lastname)}, ${capitalizeFirstLetters(entity?.firstname)}`
                  : `${capitalizeFirstLetters(entity?.name)}`}
              </span>
            </div>
          </TextOverButtons>
          <div>
            <span className={s.dayPayment}>{dayPayment ? `(${handleSetMoneySign(country)} ${dayPayment})` : ''}</span>
            {handleShowRoomName(entity?._id) && dayPayment ? <span> - </span> : null}
            <span className={s.roomName}>{handleShowRoomName(entity?._id)}</span>
            {!handleShowRoomName(entity?._id) && !dayPayment && <span>--</span>}
          </div>
        </div>
      </div>

      <div className={s.contBtnsHeader}>
        <TextOverButtons title="Modificar agenda">
          <IconButton
            id="Edit from calendar"
            onClick={() => {
              mxp.track(mxp.events.agenda.modifyAgenda.open)
              modifyDoctorOrMachine()
            }}
          >
            <EditOutlinedIcon className={`${s.iconFontSize} tourStep1`} />
          </IconButton>
        </TextOverButtons>
        <TextOverButtons title={`${t('turnLabel.Nt')} sin fecha`}>
          <IconButton
            onClick={() => {
              mxp.track(mxp.events.agenda.noDateTurn.open)
              newUndatedTurn()
            }}
          >
            <SendOutlinedIcon className={s.iconFontSize} />
          </IconButton>
        </TextOverButtons>
      </div>

      {openToAddNewUndatedTurn && (
        <FormCreateNewTurn
          entity={selectedAgenda}
          openPopUp={openToAddNewUndatedTurn}
          setOpenPopUp={setOpenToAddNewUndatedTurn}
          handleConfigDoctorCentresPanelClose={handleConfigAgendaPanelClose}
        />
      )}

      {openToModifyDoctorCentre && (
        <DoctorCentresPopUp
          openDoctorPopUp={openToModifyDoctorCentre}
          setOpenDoctorPopUp={setOpenToModifyDoctorCentre}
          entity={entity}
        />
      )}

      {openToModifyMachine && (
        <PopUpMachine open={openToModifyMachine} setOpen={setOpenToModifyMachine} entity={entity} />
      )}
    </div>
  )
}
