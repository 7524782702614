import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import PropTypes from 'prop-types'
import Insurance from './insurance'
import PersonalData from './personalData'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const FormUpdatePatientProfile = ({ handleClose, selectedPatient }) => {
  const { t } = useTranslation('global')

  const [valuePanel, setValuePanel] = useState(0)

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const handleChangePanel = (event, newValue) => {
    setValuePanel(newValue)
  }

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={valuePanel}
            onChange={handleChangePanel}
            aria-label={`Actualización de perfil de ${t(
              `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`,
            )}`}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              '& .MuiTab-root.Mui-selected': {
                color: '#009688',
              },
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            <Tab label="Datos personales" />
            <Tab label={`${t('insuranceLabel.S')}`} />
          </Tabs>
        </Box>
      </Box>

      <TabPanel value={valuePanel} index={0}>
        <PersonalData selectedPatient={selectedPatient} handleClose={handleClose} />
      </TabPanel>
      <TabPanel value={valuePanel} index={1}>
        <Insurance handleClose={handleClose} />
      </TabPanel>
    </div>
  )
}

export default FormUpdatePatientProfile
