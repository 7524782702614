import dayjs from 'dayjs'

export const adapterToExcel = (debt) => {
  const completedSessions = debt.raw.turns.filter((d) => d.state === 'finished').length
  const cancelledSessions = debt.raw.turns.filter((d) => d.state === 'cancelled').length

  const status = () => {
    if (debt.raw.state === 'init' && debt.raw.turnsCount !== completedSessions) return 'Activo'
    if (debt.raw.state === ' finished' && debt.raw.turnsCount === completedSessions) return 'Finalizado'
  }

  const treatmentDebt = {
    Paciente: debt.raw.patientCentre.lastname.toUpperCase() + ', ' + debt.raw.patientCentre.firstname.toUpperCase(),
    Sesión: debt.raw.name.toUpperCase(),
    Estado: status(),
    'Fecha creación': dayjs(debt.raw.creationDate).utc().format('DD-MM-YYYY'),
    'Monto a pagar': debt.raw.price,
    'Monto pagado': debt.raw.paidPrice,
    Deuda: debt.raw.price - debt.raw.paidPrice,
    'Cantidad sesiones': debt.raw.turnsCount,
    Completadas: completedSessions,
    Pendientes: debt.raw.turns.length - completedSessions - cancelledSessions,
    'Faltan asignar': debt.raw.turnsCount - debt.raw.turns.length + cancelledSessions,
  }

  return treatmentDebt
}
