import React from 'react'
import { useSelector } from 'react-redux'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import InstagramIcon from '@mui/icons-material/Instagram'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import TwitterIcon from '@mui/icons-material/Twitter'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import YouTubeIcon from '@mui/icons-material/YouTube'

import s from './styles.module.scss'

const MoreInformationContent = () => {
  const centre = useSelector((state) => state.centres.centreTarget)

  return (
    <div className={s.itemMainContainer}>
      <div className={s.informationContainer}>
        <div className={s.itemInformation}>
          <div className={s.icon}>
            <FmdGoodOutlinedIcon />
          </div>
          <div className={s.headerTitle}>Dirección</div>
        </div>
        <div className={s.subHeader}>
          <div>{centre?.street || 'No especificado'}</div>
          <div>{centre?.city?.formatted_address || centre?.city || '-'}</div>
        </div>
      </div>
      <div className={s.informationContainer}>
        <div className={s.itemInformation}>
          <div className={s.icon}>
            <LocalPhoneOutlinedIcon />
          </div>
          <div className={s.headerTitle}>Teléfono</div>
        </div>
        <div className={s.subHeader}>
          <div>{centre?.phones.join(', ') || 'No especificado'}</div>
        </div>
      </div>
      {centre?.other1 && (
        <div className={s.informationContainer}>
          <div className={s.itemInformation}>
            <div className={s.icon}>
              <InfoOutlinedIcon />
            </div>
            <div className={s.headerTitle}>Información importante</div>
          </div>
          <div className={s.subHeader}>
            <div className={s.about}>{centre?.other1}</div>
          </div>
        </div>
      )}
      <div className={s.socialNetworksContainer}>
        {centre?.web && (
          <div className={s.iconDiv}>
            <a href={`//${centre?.web}`} target="_blank" rel="noopener noreferrer">
              <LanguageOutlinedIcon className={s.networkIcon} />
            </a>
          </div>
        )}

        {centre?.email?.length > 0 && (
          <div className={s.iconDiv}>
            <a target="_blank" rel="noopener noreferrer" href={`mailto:${centre?.email}`}>
              <EmailOutlinedIcon className={s.networkIcon} />
            </a>
          </div>
        )}

        {centre?.whatsappLink && (
          <div className={s.iconDiv}>
            <a target="_blank" rel="noopener noreferrer" href={`${centre?.whatsappLink}`}>
              <WhatsAppIcon className={s.networkIcon} />
            </a>
          </div>
        )}

        {centre?.socialNetworks?.facebook?.length > 0 && (
          <div className={s.iconDiv}>
            <a href={`//${centre?.socialNetworks?.facebook}`} target="_blank" rel="noopener noreferrer">
              {' '}
              <FacebookOutlinedIcon className={s.networkIcon} />
            </a>
          </div>
        )}

        {centre?.socialNetworks?.instagram?.length > 0 && (
          <div className={s.iconDiv}>
            <a href={`//${centre?.socialNetworks?.instagram}`} target="_blank" rel="noopener noreferrer">
              {' '}
              <InstagramIcon className={s.networkIcon} />
            </a>
          </div>
        )}

        {centre?.socialNetworks?.twitter?.length > 0 && (
          <div className={s.iconDiv}>
            <a href={`//${centre?.socialNetworks?.twitter}`} target="_blank" rel="noopener noreferrer">
              {' '}
              <TwitterIcon className={s.networkIcon} />
            </a>
          </div>
        )}

        {centre?.socialNetworks?.youtube?.length > 0 && (
          <div className={s.iconDiv}>
            <a href={`//${centre?.socialNetworks?.youtube}`} target="_blank" rel="noopener noreferrer">
              <YouTubeIcon className={s.networkIcon} />
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default MoreInformationContent
