import React from 'react'
import { setCentreEntity } from '@/store/doctorCentres/actions'
import { selectedSecretaryCentre } from '@/store/secretaryCentre/actions'

import ListItemsEntity from './components/listItemsEntity'

const ContentSidebar = ({
  listItems,
  listItems2,
  listTitle,
  listTitle2,
  loadingDoctorCentres,
  loadingSecretaryCentres,
}) => {
  return (
    <div>
      <ListItemsEntity
        listItemsPerEntity={listItems}
        listTitle={listTitle}
        selectedEntityFunction={setCentreEntity}
        messaje={'No hay profesionales cargados aún'}
        loadingEntity={loadingDoctorCentres}
      />

      <ListItemsEntity
        listItemsPerEntity={listItems2}
        listTitle={listTitle2}
        selectedEntityFunction={selectedSecretaryCentre}
        messaje={'No hay asistentes cargados aún'}
        loadingEntity={loadingSecretaryCentres}
      />
    </div>
  )
}

export default ContentSidebar
