import React from 'react'
import { useSelector } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormCreateAdmin from '../formCreateAdmin'

const PopUpAdmin = ({ open, setOpen }) => {
  const centreAdminDetail = useSelector((state) => state.centres.centreAdminDetail)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} open={open} maxWidth="md" fullWidth onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle={'Crear centro'} handleClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <FormCreateAdmin onClose={handleClose} formValues={centreAdminDetail} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpAdmin
