import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import FormConsultTurns from '@/pages/AgendaPage/components/OptionsAgenda/ConsultTurns/components/formConsultTurns'
import { cleanPatientCentreTurns } from '@/store/turns/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'

const ConsultTurns = ({ open, onClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const handleClose = () => {
    dispatch(cleanPatientCentreTurns())
    onClose(false)
  }

  return (
    <div>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={`Consultar ${t('turnLabel.p')} de ${t(
              `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`,
            )}`}
            handleClose={handleClose}
          />
        </DialogTitle>
        <DialogContent className="animate__animated animate__fadeIn">
          <FormConsultTurns handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ConsultTurns
