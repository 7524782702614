import React from 'react'
import CaluButton from '@/components/styledComponents/CaluButton'
import CaluInput from '@/components/styledComponents/CaluInput'
import { Grid } from '@mui/material'

import s from './styles.module.scss'

const TemplateHcSearchBar = ({ addNewTemplateHC, inputSearch, setInputSearch }) => {
  return (
    <Grid container className={s.mainContainer}>
      <Grid item xs={12} md={4}>
        <CaluInput
          id="search"
          type="text"
          autoComplete="off"
          labelText="Buscar tipo de ficha"
          value={inputSearch}
          onChange={(e) => setInputSearch(e.target.value)}
          className={s.searchBarContainer}
        />
      </Grid>

      <Grid item xs={12} md={4} className={s.containerButtonNewEntry}>
        <CaluButton color="primary" type="add" size="large" onClick={addNewTemplateHC}>
          Crear tipo de ficha
        </CaluButton>
      </Grid>
    </Grid>
  )
}

export default TemplateHcSearchBar
