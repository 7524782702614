import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from '@/context/UserProvider'
import { setBackURLMessage } from '@/store/app/actions'
import { getCentreMercadoPagoInfo } from '@/store/centreMercadoPagoInfo/actions'
import { resetDaysMonth } from '@/store/turns/actions'
import { updateWappStatus } from '@/store/wapp/actions'
import { showToast } from '@/utils/toast'

import CalendarProvider from './CalendarProvider'
import { Layout } from './Layout'

const AgendaPage = () => {
  const { defaultCentre } = useUser()
  const dispatch = useDispatch()

  const centreDetail = useSelector((state) => state?.centres?.centreProfile)
  const { backURLMessage } = useSelector((state) => state.app)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected

  useEffect(() => {
    if (centreDetail?.centreConfiguration?.enableTurnsPaymentLinkModule) {
      dispatch(getCentreMercadoPagoInfo(centreDetail?._id))
    }

    dispatch(resetDaysMonth())

    if (backURLMessage) {
      showToast(backURLMessage, 'success', 8000)
      dispatch(setBackURLMessage(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (centreDetail?.centreConfiguration?.hasWappModule) {
      if (entityInLocalStorage?.role === 'centre' || entityInLocalStorage?.role === 'secretary') {
        dispatch(updateWappStatus(defaultCentre))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centreDetail?.centreConfiguration, entityInLocalStorage])

  return (
    <CalendarProvider>
      <Layout />
    </CalendarProvider>
  )
}

export default AgendaPage
