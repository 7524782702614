import React from 'react'
import FAQs from '@/components/FAQs'
import Layout1 from '@/layouts/Layout1'
import { Paper } from '@mui/material'

import s from './styles.module.scss'

const FAQsPage = () => {
  return (
    <Layout1 navbar>
      <Paper className={s.container} elevation={3}>
        <FAQs />
      </Paper>
    </Layout1>
  )
}

export default FAQsPage
