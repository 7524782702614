export const reducePayload = (centre) => {
  if (!centre) return {}
  // to force deep copy
  let auxCentre = JSON.parse(JSON.stringify(centre))

  // reduce centre.services
  if (auxCentre?.services) {
    auxCentre.services = auxCentre.services.map((s) => (s?._id ? s._id : s))
  }

  return auxCentre
}
