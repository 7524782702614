import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { AiOutlinePlus } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import DoctorCentresPopUp from '@/pages/AdministracionPage/components/popUp/DoctorCentresPopUp'
import { resetSelectedDoctorCentre } from '@/store/doctorCentres/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { Autocomplete, Box, Button, Checkbox, Divider, Popper, styled, TextField } from '@mui/material'

import s from '../styles.module.scss'

const DoctorCentreAutocomplete = ({ control, setValue }) => {
  const icon = <CheckBoxOutlineBlank fontSize="small" /> // Autocomplete Insurances
  const checkedIcon = <CheckBox fontSize="small" />
  const dispatch = useDispatch()

  const PopperStyledComponent = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'}`,
  }))

  const [openAutocompleteDoctorCentres, setOpenAutocompleteDoctorCentres] = useState(false)
  const [openCreateDoctorCentre, setOpenCreateDoctorCentre] = useState(false)
  const [checkAllDoctorCentres, setCheckAllDoctorCentres] = useState(false)

  const allDoctorsCentre = useSelector((state) => state.doctorCentres?.doctorCentres)

  const handleCreateNewDoctorCentre = () => {
    dispatch(resetSelectedDoctorCentre())
    setOpenCreateDoctorCentre(true)
  }

  const checkAllChangeDoctorCentres = (e) => {
    setCheckAllDoctorCentres(e.target.checked)
    if (e.target.checked) {
      setValue('doctorCentres', allDoctorsCentre)
    } else {
      setValue('doctorCentres', [])
    }
  }

  const getDoctorCentresPopperComponent = (param) => (
    <PopperStyledComponent {...param}>
      <Box {...param} />
      <Divider />
      <div className={s.containerPopper}>
        <Box
          sx={{
            backgroundColor: 'white',
            height: '45px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <Checkbox
            checked={checkAllDoctorCentres}
            onChange={checkAllChangeDoctorCentres}
            id="check-all"
            onMouseDown={(e) => e.preventDefault()}
          />
          Marcar todos los profesionales
        </Box>
        <Button
          endIcon={<AiOutlinePlus />}
          variant="contained"
          disableElevation
          onClick={() => handleCreateNewDoctorCentre()}
          onMouseDown={(e) => e.preventDefault()}
          className={s.createService}
          sx={{
            backgroundColor: 'white',
            height: '45px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          Crear profesional
        </Button>
      </div>
    </PopperStyledComponent>
  )

  useEffect(() => {
    if (checkAllDoctorCentres) setValue('doctorCentres', allDoctorsCentre)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDoctorsCentre])

  return (
    <div>
      <Controller
        name="doctorCentres"
        control={control}
        render={({ field }) => (
          <>
            <Autocomplete
              {...field}
              multiple
              disableCloseOnSelect
              open={openAutocompleteDoctorCentres}
              onOpen={() => setOpenAutocompleteDoctorCentres(true)}
              onClose={() => setOpenAutocompleteDoctorCentres(false)}
              options={allDoctorsCentre || []}
              getOptionLabel={(option) =>
                `${capitalizeFirstLetters(option?.lastname)} ${capitalizeFirstLetters(option?.firstname)} `
              }
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option?._id}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option?.lastname} {option?.firstname}
                </li>
              )}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              fullWidth
              renderInput={(params) => <TextField {...params} label="Profesional(s)" variant="standard" />}
              onChange={(event, selectedValues, reason) => {
                if (reason === 'selectOption') {
                  setValue('doctorCentres', selectedValues)
                } else if (reason === 'removeOption') {
                  setCheckAllDoctorCentres(false)
                  setValue('doctorCentres', selectedValues)
                } else if (reason === 'clear') {
                  setValue('doctorCentres', [])
                  setCheckAllDoctorCentres(false)
                }
              }}
              PopperComponent={getDoctorCentresPopperComponent}
            />
          </>
        )}
      />

      <DoctorCentresPopUp
        openDoctorPopUp={openCreateDoctorCentre}
        setOpenDoctorPopUp={setOpenCreateDoctorCentre}
        setOpenAutocompleteDoctorCentres={setOpenAutocompleteDoctorCentres}
      />
    </div>
  )
}

export default DoctorCentreAutocomplete
