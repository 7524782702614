const fetchImage = async (imageUrl) => {
  if (!imageUrl) return
  // const res = await fetch(imageUrl.replace('https://', 'http://'))
  const res = await fetch(imageUrl)
  const imageBlob = await res.blob()
  const imageObjectURL = URL.createObjectURL(imageBlob)

  return imageObjectURL
}

export { fetchImage }
