import React from 'react'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormCreateTemplateHC from '../formCreateTemplateHC'

const MainPopUp = ({ open, setOpen, templateHCSelected, allFieldsHC, entityId, doctorCentreId, isDoctor }) => {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" open={open} fullWidth onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={
              templateHCSelected ? 'Modificar ficha de historia clínica' : 'Ficha de historia clínica personalizada'
            }
            handleClose={handleClose}
          />
        </DialogTitle>
        <DialogContent style={{ padding: '0px' }} className="animate__animated animate__fadeIn">
          <FormCreateTemplateHC
            handleClose={handleClose}
            templateHCSelected={templateHCSelected}
            allFieldsHC={allFieldsHC}
            entityId={entityId}
            doctorCentreId={doctorCentreId}
            isDoctor={isDoctor}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default MainPopUp
