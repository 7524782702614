import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchCentreTargetAvailabilities = async (centreId, data) => {
  // TODO: unify both functions
  // TODO: centreId should be ALWAYS an ID
  const route = `api/search/availability/${centreId?._id ? centreId?._id : centreId}`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchCentreTargetTurns = async (centreId, data) => {
  // TODO: unify both functions
  // TODO: centreId should be ALWAYS an ID
  const route = `api/search/availability/${centreId?._id ? centreId?._id : centreId}`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const validateAvailabilityOfTurn = async (data) => {
  const route = `api/search/availability/validate`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { fetchCentreTargetAvailabilities, fetchCentreTargetTurns, validateAvailabilityOfTurn }
