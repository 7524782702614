import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'subscriptionPlans',
  initialState: {
    allSubscriptionPlans: [],
    selectedPlan: null,
    error: false,
    isLoaded: null,
  },
  reducers: {
    FETCH_ALL_SUBSCRIPTION_PLANS: (state, { payload }) => {
      state.allSubscriptionPlans = payload
    },
    SUBSCRIPTION_PLAN_UPDATED: (state, { payload }) => {
      state.allSubscriptionPlans = state.allSubscriptionPlans.map((plan) => {
        if (plan._id === payload._id) {
          plan = payload
        }
        return plan
      })
    },
    SUBSCRIPTION_PLAN_DELETE: (state, { payload }) => {
      state.allSubscriptionPlans = state.allSubscriptionPlans.filter((plan) => plan._id !== payload)
      state.selectedPlan = null
    },
    SUBSCRIPTION_PLAN_CREATED: (state, { payload }) => {
      state.allSubscriptionPlans = [...(state.allSubscriptionPlans || []), payload]
    },
    SUBSCRIPTION_PLAN_SELECTED: (state, { payload }) => {
      state.selectedPlan = payload
    },
    RESET_SUBSCRIPTION_PLAN_SELECTED: (state) => {
      state.selectedPlan = null
    },
    LOADED_SUBSCRIPTION_PLAN: (state, { payload }) => {
      state.isLoaded = payload
    },
  },
})

export const {
  FETCH_ALL_SUBSCRIPTION_PLANS,
  SUBSCRIPTION_PLAN_UPDATED,
  SUBSCRIPTION_PLAN_DELETE,
  SUBSCRIPTION_PLAN_CREATED,
  LOADED_SUBSCRIPTION_PLAN,
  SUBSCRIPTION_PLAN_SELECTED,
  RESET_SUBSCRIPTION_PLAN_SELECTED,
} = slice.actions
export default slice.reducer
