import API from '../client'

const apiCheck = async () => {
  const route = ''
  try {
    const response = await API.get(route)
    return response.data
  } catch {
    return false
  }
}

// const apiStatus = async () => {
//   const route = 'api/status'
//   try {
//     await API.get(route)
//     return true
//   } catch {
//     return false
//   }
// }

export { apiCheck }
