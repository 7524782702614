import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'wappMessageTemplates',
  initialState: {
    allTemplates: [],
    paginationData: {
      page: 1,
      limit: 30,
    },
    selectedTemplate: null,
    isLoading: false,
  },
  reducers: {
    CREATE_WAPP_MESSAGE_TEMPLATE: (state, { payload }) => {
      state.allTemplates = [payload]
    },
    GET_WAPP_MESSAGE_TEMPLATES: (state, { payload }) => {
      state.allTemplates = payload.allTemplates
      state.paginationData = payload.paginationData
    },
    UPDATE_WAPP_MESSAGE_TEMPLATE: (state, { payload }) => {
      state.allTemplates = state.allTemplates.map((t) => {
        if (t._id === payload._id) {
          t = payload
        }
        return t
      })
    },
    SELECTED_TEMPLATE: (state, { payload }) => {
      state.selectedTemplate = payload
    },
    CLEAN_SELECTED_TEMPLATE: (state) => {
      state.selectedTemplate = null
    },
    DELETE_TEMPLATE: (state, { payload }) => {
      state.allTemplates = state.allTemplates.filter((template) => template._id !== payload)
      state.selectedTemplate = null
    },
    IS_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
  },
})

export const {
  GET_WAPP_MESSAGE_TEMPLATES,
  UPDATE_WAPP_MESSAGE_TEMPLATE,
  IS_LOADING,
  CREATE_WAPP_MESSAGE_TEMPLATE,
  SELECTED_TEMPLATE,
  CLEAN_SELECTED_TEMPLATE,
  DELETE_TEMPLATE,
} = slice.actions
export default slice.reducer
