import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import logo from '@/assets/images/clinicHistory.png'
import { handlePaginationChangeHCTemplates, selectedTemplateHC } from '@/store/clinicHistoryTemplate/actions'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Button, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import s from '../../styles.module.scss'

const TamplateHcTable = ({ setOpen, searchText }) => {
  const dispatch = useDispatch()
  const [rowId, setRowId] = useState('')
  const { allTemplatesHC, paginationData, isLoading } = useSelector((state) => state.templateHC)
  const { limit, totalDocs, page } = paginationData || {}

  const handleClick = async (data) => {
    await dispatch(selectedTemplateHC(data._id))
    setOpen(true)
  }

  const handleCellClick = (params, event) => {
    if (event.target.parentElement.id === 'whatsAppButton' || event.target.ariaLabel === 'whatsAppButton') {
      return
    } else {
      handleClick(params?.row?.raw)
    }
  }

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  }

  const columns = [
    {
      field: 'id',
      width: 90,
      flex: 1,
    },
    {
      field: 'image',
      headerName: '',
      minWidth: 40,
      maxWidth: 40,
      flex: 1,
      ...stylesColumns,
      renderCell: () => <img src={logo} alt="logo servicio" className={s.logo} />,
    },
    {
      field: 'name',
      headerName: 'Nombre',
      minWidth: 160,
      flex: 1,
    },

    {
      field: 'fields',
      headerName: 'Campos extras',
      minWidth: 160,
      flex: 1,
    },

    {
      field: 'raw',
      headerName: '',
      minWidth: 150,
      maxWidth: 180,
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title="Editar" placement="top">
          <Button
            onClick={() => handleClick(params?.value)}
            className={rowId === params.value._id ? s.rowHoverButton : s.rowButton}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            <EditOutlinedIcon />
          </Button>
        </Tooltip>
      ),
    },
  ]

  const rows = allTemplatesHC?.map((entry) => {
    return {
      id: entry?._id,
      name: entry?.name?.toUpperCase(),
      fields: entry?.fields?.length,
      raw: entry,
    }
  })

  const handleRowOver = (e) => {
    const rowId = e.currentTarget.dataset.id
    const row = rows.find((el) => el.id === rowId)
    setRowId(row.id)
  }

  const handlePaginationChange = ({ page, pageSize }) => {
    dispatch(handlePaginationChangeHCTemplates(page + 1, pageSize, searchText))
  }

  return (
    <Box
      sx={{
        px: 0,
        '& .header': {
          fontSize: '14px',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
          background: '#fff',
        },
      }}
    >
      <DataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          pagination: {
            paginationModel: {
              pageSize: limit,
            },
          },
        }}
        rows={rows || []}
        rowCount={totalDocs || 0}
        loading={isLoading}
        paginationMode="server"
        columns={columns}
        pagination
        onCellClick={(params, event) => handleCellClick(params, event)}
        onPaginationModelChange={handlePaginationChange}
        pageSizeOptions={[30]}
        disableRowSelectionOnClick={true}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        slotProps={{
          pagination: {
            labelRowsPerPage: 'Entradas por página:',
            labelDisplayedRows: () =>
              `${(page - 1) * limit + 1} - ${page * limit > totalDocs ? totalDocs : page * limit} de ${totalDocs}`,
          },
          row: {
            onMouseEnter: handleRowOver,
          },
        }}
        sx={{
          fontSize: '13px',
          background: '#fff',
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell ': {
            display: 'flex',
            justifyContent: 'flex-start',
          },
        }}
        rowHeight={45}
        autoHeight={true}
      />
    </Box>
  )
}

export default TamplateHcTable
