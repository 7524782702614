import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import LoaderCircle from '@/components/LoaderCircle'
import TitlePopUp from '@/components/TitlePopUp'
import { useSocket } from '@/context/SocketProvider'
import { getCentreTargetAvailabilities } from '@/store/centreAvailability/actions'
import { updateATurn } from '@/store/turns/actions'
import { handleWappReminder } from '@/utils/functions/handleWappReminders'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { Dialog, DialogContent, Grid } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const RescheduleTurn = ({ open, setOpen, turnInfo, onClose }) => {
  const { t } = useTranslation('global')
  const dispatch = useDispatch()
  const { socket } = useSocket()

  const entity = !turnInfo.machine ? turnInfo.doctorCentre : turnInfo.machine
  let serviceType = turnInfo?.service ? 'practices' : 'consultations'
  let consultationType = turnInfo?.firstTime ? 'firstConsultation' : 'consultation'
  let serviceId = turnInfo?.service ? turnInfo.service?._id : consultationType

  const centreAvailability = useSelector((state) => state.centreAvailability)
  const entityAvailability = centreAvailability?.[serviceType]?.[serviceId]?.availability?.[entity?._id]
  let availableDays = entityAvailability?.availableDays
  const [infoToRescheduleTurn, setInfoToRescheduleTurn] = useState({})
  const [OpenPopUpToRescheduleTurn, setOpenPopUpToRescheduleTurn] = useState(false)
  const [loading, setLoading] = useState(true)

  const centre = useSelector((state) => state.centres.centreProfile)
  const { status: wappStatus } = useSelector((state) => state.wapp)

  const getEntityService = () => {
    let service = null

    if (!turnInfo?.machine) {
      if (!turnInfo.service && turnInfo.firstTime) {
        service = { _id: 'firstConsultation', name: 'Primera consulta' }
      }

      if (!turnInfo.service && !turnInfo.firstTime) {
        service = service = { _id: 'consultation', name: 'Consulta' }
      }

      if (turnInfo.service) {
        service = turnInfo.service
      }
    }

    if (turnInfo.machine) {
      if (turnInfo?.service) {
        service = turnInfo?.service
      }
    }

    if (turnInfo?.extraServices) {
      service = [service, ...turnInfo.extraServices]
    }
    return service
  }

  const getEntityAvailable = async () => {
    const entityService = await getEntityService()

    const aux = {
      entityId: entity?._id,
      entityType: !turnInfo.machine ? 'doctorCentre' : 'machine',
      selectedService: entityService,
      showAll: true,
    }

    if (turnInfo.machine && !turnInfo.service) {
      setLoading(false)
      return
    }

    await dispatch(getCentreTargetAvailabilities(turnInfo?.centre, aux))
    setLoading(false)
  }

  useEffect(() => {
    if (turnInfo) {
      getEntityAvailable()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turnInfo])

  const handleClose = () => {
    setOpen(false)
  }

  const turnsPerDay = (day, entityAvailability) => {
    for (const key in entityAvailability) {
      if (key === day) {
        return entityAvailability[key]
      }
    }
  }

  const formatDate = (date) => {
    const dayNumber = dayjs(date).format('D')
    const dayName = dayjs(date).format('ddd').toUpperCase()
    const month = dayjs(date).format('MMM').toUpperCase()
    return `${dayName} ${dayNumber} ${month}.`
  }

  const handleAddNewSession = (day, elem) => {
    const date = dayjs(day).utc().format('YYYY-MM-DD')
    let hourAndMinuteTurn = elem.startHour.split(':')
    const startHour = dayjs(date)
      .utc()
      .hour(hourAndMinuteTurn[0], 'hour')
      .minute(hourAndMinuteTurn[1], 'minute')
      .format()
    const finishHour = dayjs(startHour).utc().add(elem.turnDuration, 'm').format()
    const objectDate = {
      date: dayjs(date).utc().format(),
      hourTurn: elem.startHour,
      startHour: startHour,
      finishHour: finishHour,
      turnDuration: elem.turnDuration,
    }
    setOpenPopUpToRescheduleTurn(true)
    setInfoToRescheduleTurn(objectDate)
  }

  const handleReprogramTurn = async () => {
    mxp.track(mxp.events.agenda.turnEvent.rescheduleDone)

    const auxTurn = {
      ...turnInfo,
      centre: turnInfo?.centre,
      startHour: infoToRescheduleTurn.startHour,
      finishHour: infoToRescheduleTurn.finishHour,
      date: infoToRescheduleTurn.date,
    }
    let updatedTurn = await dispatch(updateATurn(auxTurn, false, centre))

    socket.emit('calendar_edit_turn', `${updatedTurn.centre}_calendar`, updatedTurn)
    if (centre?.centreConfiguration?.shouldSendNotificationOnTurnModify) {
      const auxData = {
        selectedTurn: {
          ...updatedTurn,
          centre: centre,
        },
        wappStatus: wappStatus,
        state: 'rescheduleTurn',
        dispatch: dispatch,
      }
      handleWappReminder(auxData)
    }
    setOpenPopUpToRescheduleTurn(false)
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }

  const showTitle = () => {
    const startHour = dayjs(infoToRescheduleTurn.startHour).utc().format('HH:mm')
    const date = dayjs(infoToRescheduleTurn.date).utc().format('dddd D MMMM')
    return `¿Reprogramar para el día ${date} a las ${startHour} hs ?`
  }

  return (
    <>
      <Dialog disableEscapeKeyDown={true} open={open} fullWidth maxWidth="md">
        <TitlePopUp textTitle={`Reprogramación de turno`} handleClose={handleClose} />
        <DialogContent>
          {loading ? (
            <LoaderCircle text={`Buscando ${t('turnLabel.p')} disponibles`} />
          ) : availableDays?.length ? (
            <Grid className={s.mainContainer}>
              {availableDays?.map((day, i) => (
                <div key={i} className={s.listTurnsDay}>
                  <p className={s.turnsDay}>{formatDate(day)}</p>
                  <span className={s.detailTurns}>
                    <div>
                      {turnsPerDay(day, entityAvailability).map((elem, i) => (
                        <div key={i}>
                          <div className={s.itemTurn} onClick={() => handleAddNewSession(day, elem)}>
                            <span>{elem.startHour} hs</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </span>
                </div>
              ))}
            </Grid>
          ) : (
            <div className={s.divLoading}>
              {turnInfo?.machine && !turnInfo?.service ? (
                <div className={s.noAvailabilityText}>
                  <b>Servicio no seleccionado</b>
                  <div> Seleccione un servicio dentro de la cita previo a poder reprogramarla.</div>
                </div>
              ) : (
                <div className={s.noAvailabilityText}>
                  <b>Sin turnos disponibles</b>
                  <div> Verifica disponibilidad desde la agenda.</div>
                </div>
              )}
            </div>
          )}

          {OpenPopUpToRescheduleTurn && infoToRescheduleTurn && (
            <AlertDialogSlide
              open={OpenPopUpToRescheduleTurn}
              setOpen={setOpenPopUpToRescheduleTurn}
              title="Confirmar reprogramación de turno"
              textContent={showTitle()}
              textBackButton="Volver"
              textConfirmationButton={'Reprogramar'}
              onClose={handleReprogramTurn}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default RescheduleTurn
