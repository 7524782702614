import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { Button } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const HeaderPatientTreatment = ({ treatment, sectionTreatmentDebts }) => {
  const { t } = useTranslation('global')

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const [paidPrice, setPaidPrice] = useState(treatment?.paidPrice)
  const [price, setPrice] = useState(treatment?.price)

  const creationDateOfTreatment = () => {
    const date = dayjs(treatment.creationDate).format('DD/MM/YYYY')
    return date
  }

  const showStateTreatment = () => {
    let finishedTurns = treatment?.turns?.filter((turn) => turn?.state === 'finished')?.length
    let turnsCount = treatment.turnsCount

    if (treatment.state === 'init' && finishedTurns < turnsCount) return <b className={s.colorStateActive}>ACTIVO</b>
    if (treatment.state === 'init' && finishedTurns === turnsCount)
      return <b className={s.colorStateFinished}>FINALIZADO</b>
    if (treatment.state === 'finished' && finishedTurns < turnsCount)
      return <b className={s.colorStateFinished}>FINALIZADO INCOMPLETO</b>
  }

  const showTreatmentSessionsAvailable = () => {
    let pending = 0
    let complated = 0
    treatment?.turns?.map((e) => (e.state === 'init' ? pending++ : e.state === 'finished' && complated++))
    let restAsign = treatment.turnsCount - pending - complated

    return `${treatment.turnsCount} sesiones (${complated} completadas, ${pending} pendientes,  falta asignar: ${restAsign})`
  }

  useEffect(() => {
    if (!treatment.isPaid) {
      const total = treatment.turns
        .map((e) => e.paymentHistory.map((e) => e.amount).reduce((a, b) => a + b, 0))
        .reduce((a, b) => a + b, 0)
      setPaidPrice(total)
      setPrice(treatment.price)
    } else {
      setPaidPrice(treatment.price)
      setPrice(treatment.price)
    }
  }, [treatment])

  useEffect(() => {
    if (!sectionTreatmentDebts && treatment.isPaid) {
      setPaidPrice(treatment.price)
    } else {
      setPaidPrice(treatment.paidPrice)
    }
  }, [sectionTreatmentDebts, treatment])

  return (
    <div className={sectionTreatmentDebts ? s.divSectionDebts : s.divSectionTreatment}>
      {sectionTreatmentDebts ? (
        <div>
          {treatment?.patientCentre ? (
            <b>{`${treatment?.patientCentre?.lastname?.toUpperCase()}, ${treatment?.patientCentre?.firstname?.toUpperCase()}`}</b>
          ) : (
            <b className={s.deletePatient}>
              {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.Patient`)} eliminado
            </b>
          )}

          <div className={s.createdDate}>
            {showStateTreatment()}
            <span>{` -- ${treatment?.name.toUpperCase()} --`}</span>
            <span> {`(creado ${creationDateOfTreatment()})`} </span>
          </div>
        </div>
      ) : (
        <div className={s.treatmentNameDiv}>
          {showStateTreatment()}

          <div>
            <span>{capitalizeFirstLetters(treatment.name)}</span>
            <span> ({creationDateOfTreatment()})</span>
          </div>
        </div>
      )}

      <div className={s.divTreamentsDetail}>
        <span>
          Pagado{' '}
          <b>
            {handleSetMoneySign(country)}
            {paidPrice} / {handleSetMoneySign(country)}
            {price || 0}
          </b>
        </span>
        <span className={s.treatmentDetailItem}> -- {showTreatmentSessionsAvailable()}</span>
      </div>

      {sectionTreatmentDebts && treatment.turns.length > 0 && (
        <div>
          <Button
            variant="outlined"
            className={s.button}
            onClick={() => sectionTreatmentDebts.setDeleteDebtAlert(true)}
          >
            TODO ESTÁ PAGO
          </Button>
        </div>
      )}
    </div>
  )
}

export default HeaderPatientTreatment
