import * as yup from 'yup'

export const formValidationSchema = yup.object({
  patientCentre: yup.object({
    dni: yup.string().max(14, 'Este campo debe tener máximo 14 caracteres.'),
    lastname: yup.string().required('Campo obligatorio'),
    firstname: yup.string().required('Campo obligatorio'),
    email: yup.string().email('Debe ingresar un email válido'),
    mobile: yup.string().when('telephone', {
      is: (val) => Boolean(val),
      then: (formValidationSchema) =>
        formValidationSchema
          .test('maxLen', 'Este campo debe tener máximo 20 caracteres.', (val) => val.length <= 20)
          .test(
            'without_0_15',
            'Debe ir con característica, sin 0 y sin 15. Ejemplo: 1156789012.',
            (val, { parent }) => {
              if (parent && parent.country === 'ARG') {
                return !/^(15|0)/.test(val)
              }
              return true
            },
          ),
      otherwise: (formValidationSchema) =>
        formValidationSchema
          .required('Teléfono Móvil o Teléfono requerido')
          .test('maxLen', 'Este campo debe tener máximo 20 caracteres.', (val) => val.length <= 20)
          .test(
            'without_0_15',
            'Debe ir con característica, sin 0 y sin 15. Ejemplo: 1156789012.',
            (val, { parent }) => {
              if (parent && parent.country === 'ARG') {
                return !/^(15|0)/.test(val)
              }
              return true
            },
          ),
    }),
    telephone: yup.string().nullable(true),
  }),
})
