import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import { useUser } from '@/context/UserProvider'
import { setAllPrescriptionsController } from '@/controllers/prescriptions'
import { getAllPrescriptions } from '@/store/prescriptions/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'

import PrescriptionItem from './components/prescriptionItem'

import s from './styles.module.scss'

const Prescriptions = ({
  patientCentre,
  centreDetail,
  doctorCentre,
  doctorHasPrescriptionReqs,
  centreHasPrescriptionReqs,
  directionOfAnimation,
  isSecretary,
}) => {
  const dispatch = useDispatch()
  const { user } = useUser()
  const { t } = useTranslation('global')

  const [isLoading, setIsLoading] = useState(true)

  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isDoctor, entityInLocalStorage } = usersProfileConnected

  const { allPrescriptions } = useSelector((state) => state.prescriptions)
  const { selectedTurn } = useSelector((state) => state.turns)

  const patientData = selectedTurn ? selectedTurn?.patientCentre : patientCentre

  const setInit = async (params) => {
    const fetchAllPrescriptions = await setAllPrescriptionsController(params)
    dispatch(getAllPrescriptions(fetchAllPrescriptions))

    setIsLoading(false)
  }

  useEffect(() => {
    let params = {
      centre: centreDetail?._id,
      patientCentre: patientData?._id,
    }

    if (isDoctor) {
      params = {
        ...params,
        doctorCentre: entityInLocalStorage?.doctorCentreId,
      }
    }
    setInit(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch])

  const loadInsurancePlan = (insurance) => {
    let insuranceIndex = patientData?.insurances?.findIndex((elem) => elem?._id === insurance?._id)
    if (insuranceIndex !== -1) {
      return {
        policyNumber: patientData?.policyNumbers[insuranceIndex],
        insurancePlan: patientData?.insurancePlan[insuranceIndex],
      }
    } else {
      return {
        policyNumber: '',
        insurancePlan: '',
      }
    }
  }

  const addPrescriptionData = () => {
    let insurancePlan
    let policyNumber
    let insurancePatient = loadInsurancePlan(selectedTurn?.insurance || patientData?.insurances?.[0] || null)
    insurancePlan = insurancePatient?.insurancePlan
    policyNumber = insurancePatient?.policyNumber

    let prescription = {
      centreName: centreDetail?.name,
      centreLogo: centreDetail?.logo,
      centreCity: centreDetail?.city?.formatted_address || centreDetail?.city,
      centreStreet: centreDetail?.street,
      centrePhone: centreDetail?.phones[0],
      centreEmail: centreDetail?.email,
      patientFullName: `${patientData?.lastname}, ${patientData?.firstname}`,
      patientDni: patientData?.dni?.length !== 15 ? patientData?.dni : null,
      patientInsurance: selectedTurn?.insurance?.name || patientData?.insurances?.[0]?.name || 'particular',
      patientInsurancePlan: insurancePlan || '',
      patientPolicyNumber: policyNumber || '',
      patientCentre: patientCentre,
    }

    if (doctorCentre && entityInLocalStorage?.role === 'doctor') {
      prescription = {
        ...prescription,
        doctorCentre: doctorCentre,
        doctorFullName: `${doctorCentre?.lastname}, ${doctorCentre?.firstname}`,
        doctorSpeciality: doctorCentre?.specialities[0] ? doctorCentre?.specialities[0].name : '-',
        doctorNumber: doctorCentre?.doctorNumber ? doctorCentre?.doctorNumber : '',
        doctorSignImage: doctorCentre?.signImage ? doctorCentre?.signImage : null,
      }
    }

    if (user?.roles?.includes('centre') && ['centre', 'admin'].includes(entityInLocalStorage?.role)) {
      prescription = {
        ...prescription,
        creatorFullName: `${user?.lastname}, ${user?.firstname}`,
        centreNumber: centreDetail?.doctorNumber ? centreDetail?.doctorNumber : '',
        centreSignImage: centreDetail?.signImage ? centreDetail?.signImage : null,
      }
    }
    return prescription
  }

  if (isLoading) {
    return <LoaderCircle text="Cargando..." />
  }

  return (
    <div className={`${s.mainContainer} ${directionOfAnimation}`}>
      {!isSecretary && (
        <div className={s.listItem}>
          {(doctorHasPrescriptionReqs || centreHasPrescriptionReqs) && (
            <PrescriptionItem
              detailPrescription={addPrescriptionData()}
              centreDetail={centreDetail}
              index={1}
              isNewPrescription={true}
              isSecretary={isSecretary}
            />
          )}
          {!doctorHasPrescriptionReqs && !centreHasPrescriptionReqs && (
            <div>
              <h5>Se require tener cargada matrícula y firma para poder crear recetas</h5>

              <p>
                Puede modificar dichos datos desde su{' '}
                <a className={s.linkPerfil} href="/perfil">
                  perfil
                </a>
              </p>
            </div>
          )}
        </div>
      )}

      {allPrescriptions?.length === 0 && (
        <p className={s.emptyPrescriptions}>
          Aún no hay recetas creadas para este{' '}
          {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}
        </p>
      )}

      {allPrescriptions?.length > 0 && (
        <ul className={s.containerList}>
          {allPrescriptions?.map((prescription, index = 2) => (
            <div className={s.listItem} key={prescription?._id}>
              <PrescriptionItem
                detailPrescription={prescription}
                index={index}
                centreDetail={centreDetail}
                isNewPrescription={false}
                isSecretary={isSecretary}
              />
            </div>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Prescriptions
