import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'statistics',
  initialState: {
    statistics: [],
  },
  reducers: {
    FETCH_STATISTICS: (state, { payload }) => {
      state.statistics = payload
    },
  },
})

export const { FETCH_STATISTICS } = slice.actions
export default slice.reducer
