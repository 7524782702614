import React from 'react'
import { useDispatch } from 'react-redux'
import notificationLogo from '@/assets/images/notification.png'
import { selectedSpecialityByList } from '@/store/specialities/actions'

import s from './styles.module.scss'

const ContentSideBar = ({ listItem }) => {
  const dispatch = useDispatch()

  return (
    <div>
      {!listItem?.length ? (
        <p className={s.emptyList}>No hay especialidades cargadas aún</p>
      ) : (
        listItem?.map((item) => (
          <div key={item._id} className={s.item} onClick={async () => await dispatch(selectedSpecialityByList(item))}>
            <img src={notificationLogo} alt="especialidades" />
            <div className={s.containerInfoService}>
              <p>
                <span className={s.bold}>Nombre: </span> <span> {item?.name}</span>
              </p>
              <p>
                <span className={s.bold}>Id: </span>
                <span>{item?._id}</span>
              </p>
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default ContentSideBar
