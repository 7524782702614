import React from 'react'
import { useSelector } from 'react-redux'
import defaultImage from '@/assets/images/default.png'
import { weekDays } from '@/helpers/constants'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { formatHour } from '@/utils/functions/formatHour'

import s from './styles.module.scss'

const WorkingHours = () => {
  const allDoctorCentres = useSelector((state) => state.doctorCentres.doctorCentres)
  const allMachines = useSelector((state) => state.machines.allMachines)

  return (
    <div className={s.mainContainer}>
      <div>
        <div>
          {allDoctorCentres?.map((doctor) =>
            doctor?.workTimes.length === 0 ? null : (
              <div key={doctor._id} className={s.containerInformation}>
                <img
                  src={doctor?.image || defaultImage}
                  alt="Imagen de perfil"
                  className={doctor?.image ? s.entityImage : s.defaultImage}
                />

                <div className={s.itemData}>
                  <h4>
                    {doctor.lastname}, {doctor.firstname}
                  </h4>
                  <div>
                    <ul>
                      {doctor?.workTimes
                        .filter((wt) => wt.weekDay?.code)
                        .map(
                          (time, index) =>
                            convertValueToName(weekDays, time.weekDay.code) && (
                              <li key={index}>
                                <small>
                                  {`${convertValueToName(weekDays, time.weekDay.code)?.toUpperCase()} ${formatHour(
                                    time?.startTime,
                                  )} hs - ${formatHour(time?.finishTime)} hs`}
                                </small>
                              </li>
                            ),
                        )}
                    </ul>
                  </div>
                </div>
              </div>
            ),
          )}
        </div>

        <div>
          {allMachines?.map((machine) =>
            machine?.workTimes.length === 0 ? null : (
              <div key={machine._id} className={s.containerInformation}>
                <div>
                  <img
                    src={machine?.image || defaultImage}
                    alt="Agenda"
                    className={machine?.image ? s.entityImage : s.defaultImage}
                  />
                </div>
                <div className={s.itemData}>
                  <h4>{machine.name}</h4>
                  <div>
                    <ul>
                      {machine?.workTimes.map((time, index) => (
                        <li key={index}>
                          {' '}
                          <small>
                            {' '}
                            {convertValueToName(weekDays, time.weekDay.code)?.toUpperCase()}{' '}
                            {formatHour(time?.startTime)} hs - {formatHour(time?.finishTime)} hs{' '}
                          </small>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  )
}

export default WorkingHours
