import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const getInsurances = async (params) => {
  const route = `api/insurances`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchSingleInsurance = async (id) => {
  const route = `api/insurances/${id}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}
const fetchInsurancesPrice = async (centreId) => {
  const route = `api/insurancePrices?centre=${centreId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewInsurance = async (data) => {
  const route = `api/insurances`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putInsurance = async (data) => {
  const route = `api/insurances`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteInsurance = async (id) => {
  const route = `api/insurances/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { addNewInsurance, deleteInsurance, fetchInsurancesPrice, fetchSingleInsurance, getInsurances, putInsurance }
