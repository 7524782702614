import React from 'react'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import InputAdornment from '@mui/material/InputAdornment'

import PropTypes from 'prop-types'
import { STextField } from './styles'

import s from './styles.module.scss'

const CaluRawInput = ({ labelText, countryCode, enterToSubmit = false, ...rest }) => {
  const height = rest.multiline ? 'auto' : '48px'

  return (
    <div>
      {labelText && <div className={s.titleInput}>{labelText}</div>}
      <div className={`${s.defaultStyles} ${rest.className}`} style={{ height }}>
        <STextField
          InputProps={{
            startAdornment: countryCode ? <InputAdornment position="start">{`+${countryCode}`}</InputAdornment> : '',
            classes: {
              notchedOutline: s.notchedOutline,
            },
          }}
          inputProps={{
            maxLength: rest.maxLength,
          }}
          onWheel={(e) => e.target.blur()}
          helperText={rest?.error && rest.helperText}
          onKeyDown={enterToSubmit ? null : handleKeyDown}
          {...rest}
        />
      </div>
    </div>
  )
}

export default CaluRawInput

CaluRawInput.propTypes = {
  type: PropTypes.string.isRequired,
  countryCode: PropTypes.string,
  labelText: PropTypes.string,
}

CaluRawInput.defaultProps = {
  fullWidth: false,
  type: 'text',
  multiline: false,
  disabled: false,
  maxRows: 15,
  maxLength: 1000,
}
