import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import AutoCompleteChedoc from '@/components/AutoCompleteChedoc'
import LoaderCircle from '@/components/LoaderCircle'
import CaluButton from '@/components/styledComponents/CaluButton'
import { useSocket } from '@/context/SocketProvider'
import { useUser } from '@/context/UserProvider'
import { getCurrentcentreInsurances } from '@/store/centreInsurances/actions'
import { postDoctorCentres, updateDoctorCentres } from '@/store/doctorCentres/actions'
import { getListInsurances } from '@/store/insurances/actions'
import { updateRoom } from '@/store/rooms/actions'
import { getAllServices } from '@/store/services/actions'
import { getListSpecialities } from '@/store/specialities/actions'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import DoneIcon from '@mui/icons-material/Done'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Tooltip } from '@mui/material'
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const PersonalInformation = ({ handleClose, addWorkTimes, entity, fromMyProfile }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const { defaultCentre } = useUser()
  const { socket } = useSocket()
  const extraData = { addInMyProfile: true }

  const icon = <CheckBoxOutlineBlank fontSize="small" />
  const checkedIcon = <CheckBox fontSize="small" />

  const [isLoading, setIsLoading] = useState(false)
  const [showErrorEmails, setShowErrorEmails] = useState(false)
  const [enabledLoadWorkingHours, setEnabledLoadWorkingHours] = useState(false)
  const [birth, setBirth] = useState(dayjs())

  const allSpecialities = useSelector((state) => state.speciality?.specialitiesList)
  const centreInsurances = useSelector((state) => state?.centreInsurances?.centreInsurancesDetail)
  const { insurancesList } = useSelector((state) => state?.insurances)
  const insurancesAvailable = !insurancesList?.length ? centreInsurances?.insurances || [] : insurancesList
  const centreConfiguration = useSelector((state) => state.centreConfigurations.centreConfigurationDetail)
  const centreDetail = useSelector((state) => state?.centres?.centreProfile)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected

  const [anchorElRooms, setAnchorElRooms] = useState(null)
  const open = Boolean(anchorElRooms)
  const [openTooltip, setOpenTooltip] = useState(false)

  const onLoad = async () => {
    const params = {
      centre: defaultCentre,
    }
    setIsLoading(true)
    await dispatch(getCurrentcentreInsurances(defaultCentre))
    await dispatch(getListSpecialities())
    await dispatch(getAllServices(params))
    setIsLoading(false)
  }

  useEffect(() => {
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (entity && enabledLoadWorkingHours) {
      addWorkTimes()
      setEnabledLoadWorkingHours(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity])

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      onlineTurns: entity?.onlineTurns || true,
      gender: 'female',
      hcAutosave: entity?.hcAutosave || false,
      firstname: '',
      lastname: '',
      email: '',
      repiteEmail: '',
      mobile: '',
      telephone: '',
      doctorNumber: '',
      doctorCategory: '',
      emailOnlineTurnCreation: entity?.emailOnlineTurnCreation || false,
      emailOnlineTurnCancellation: entity?.emailOnlineTurnCancellation || false,
      hideCentreInsurances: entity?.hideCentreInsurances || false,
    },
  })

  const checkboxHideCentreInsurances = watch('hideCentreInsurances')

  const onSubmit = async (data, e) => {
    if (!e) return
    e.preventDefault()

    let buttonClicked = e?.nativeEvent?.submitter?.name

    setIsLoading(true)

    if (data.repiteEmail) {
      if (data.email !== data.repiteEmail) {
        setShowErrorEmails(true)
        setIsLoading(false)
        return
      } else {
        setShowErrorEmails(false)
      }
    }

    data.centre = defaultCentre || entity.centre

    if (fromMyProfile) {
      data.birth = birth
    }

    delete data.repiteEmail

    try {
      if (fromMyProfile && buttonClicked === 'guardar') {
        const updatedDoctorCentre = await dispatch(updateDoctorCentres(data, extraData))
        socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedDoctorCentre)
        handleClose()
        return
      }

      if (entity && buttonClicked === 'cargar') {
        const updatedDoctorCentre = dispatch(updateDoctorCentres(data))
        socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedDoctorCentre)
        setEnabledLoadWorkingHours(true)
        return
      }

      if (entity && buttonClicked === 'guardar') {
        mxp.track(mxp.events.agenda.modifyAgenda.update)
        const updatedDoctorCentre = await dispatch(updateDoctorCentres(data))
        socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedDoctorCentre)
        handleClose()
        return
      }

      if (buttonClicked === 'cargar') {
        const createdDoctorCentre = await dispatch(postDoctorCentres(data))
        socket.emit('calendar_new_entity', `${defaultCentre}_calendar`, createdDoctorCentre)
        setEnabledLoadWorkingHours(true)
        return
      }

      if (buttonClicked === 'guardar') {
        const createdDoctorCentre = await dispatch(postDoctorCentres(data))
        socket.emit('calendar_new_entity', `${defaultCentre}_calendar`, createdDoctorCentre)
        handleClose()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (entity) {
      Object.keys(entity).forEach((k) => {
        if (k === 'specialities') {
          setValue('specialities', entity[k])
          return
        }

        if (k === 'birth') {
          setBirth(entity[k])
          return
        }

        if (k === 'mobile') {
          if (!entity[k]) {
            setValue(k, '')
            return
          }
        }
        if (k === 'telephone') {
          if (!entity[k]) {
            setValue(k, '')
            return
          }
        }

        setValue(k, entity[k])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity])

  const handleOpenMenuRooms = (event) => {
    setAnchorElRooms(event.currentTarget)
  }

  const handleCloseMenuRooms = () => {
    setAnchorElRooms(null)
  }

  const handleSettingRoom = async (room) => {
    let auxRoom = {
      ...room,
      people: [
        {
          name: entity?.name ? entity?.name : `${entity?.lastname}, ${entity?.firstname}`,
          _id: entity?._id,
        },
      ],
    }

    try {
      await dispatch(updateRoom(auxRoom))
      handleCloseMenuRooms()
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  const handleFocus = () => {
    setOpenTooltip(true)
  }

  const handleBlur = () => {
    setOpenTooltip(false)
  }

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <form className={s.containerForm} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p className={s.subTitle}>Datos personales</p>
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="firstname"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                  type="text"
                  label="Nombre(s)*"
                  variant="standard"
                  fullWidth
                  onKeyDown={handleKeyDown}
                />
              )}
              rules={{ required: true }}
            />
            {errors.firstname?.type === 'required' && <p className={s.formAlert}>Nombre(s) requerido(s)</p>}
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="lastname"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                  type="text"
                  label="Apellido(s)*"
                  variant="standard"
                  fullWidth
                  onKeyDown={handleKeyDown}
                />
              )}
              rules={{ required: true }}
            />
            {errors.lastname?.type === 'required' && <p className={s.formAlert}>Apellido(s) requerido(s)</p>}
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel>Género</InputLabel>
                  <Select {...field} label="Sexo">
                    <MenuItem value={'female'}>Femenino</MenuItem>
                    <MenuItem value={'male'}>Masculino</MenuItem>
                    <MenuItem value={'other'}>Prefiero no decirlo</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          {fromMyProfile && (
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <DatePicker
                  label="Fecha de nacimiento"
                  value={dayjs(birth)}
                  format="DD/MM/YYYY"
                  timezone="UTC"
                  onChange={(newDate) => {
                    setBirth(dayjs(newDate).toISOString())
                  }}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      fullWidth: true,
                      helperText: 'Ingresar la fecha en formato DD/MM/YYYY',
                    },
                  }}
                />
              </FormControl>
            </Grid>
          )}

          {!fromMyProfile && (
            <Grid item xs={12} md={entity ? 12 : 6}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Tooltip
                    open={openTooltip}
                    title="El mail es un dato único que no puede repetirse con otro usuario creado previamente en el sistema"
                    placement="top"
                    arrow
                  >
                    <TextField
                      {...field}
                      onKeyDown={handleKeyDown}
                      type="email"
                      label="Email*"
                      variant="standard"
                      disabled={entity && entityInLocalStorage?.role !== 'admin'}
                      fullWidth
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                  </Tooltip>
                )}
                rules={{ required: true }}
              />
              {errors.email?.type === 'required' && <p className={s.formAlert}>Email requerido</p>}
              {entity && entityInLocalStorage?.role !== 'admin' && (
                <p className={s.disabledFieldEmail}>Este campo no se puede modificar.</p>
              )}
            </Grid>
          )}

          {entity ? null : (
            <Grid item xs={12} md={6}>
              <Controller
                name="repiteEmail"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onKeyDown={handleKeyDown}
                    type="email"
                    label="Repite email*"
                    variant="standard"
                    disabled={entity && entityInLocalStorage?.role !== 'admin'}
                    fullWidth
                  />
                )}
                rules={{ required: true }}
              />

              {errors.repiteEmail?.type === 'required' && <p className={s.formAlert}>Email requerido</p>}
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <Controller
              name="mobile"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Celular"
                  variant="standard"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="telephone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Teléfono fijo"
                  variant="standard"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <p className={s.subTitle}>Datos profesionales</p>
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="doctorNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  onKeyDown={handleKeyDown}
                  type="text"
                  label="Matrícula"
                  variant="standard"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="doctorCategory"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  onKeyDown={handleKeyDown}
                  type="text"
                  label="Categoría"
                  variant="standard"
                  fullWidth
                />
              )}
            />
          </Grid>

          {/* especialidades */}
          <Grid item xs={12}>
            {isLoading ? (
              <LoaderCircle size={30} />
            ) : (
              <Controller
                name="specialities"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    disableCloseOnSelect
                    options={allSpecialities}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option._id}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.name}
                      </li>
                    )}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label="Especialidad(es)" variant="standard" />}
                    onChange={(event, selectedValues) => {
                      setValue('specialities', selectedValues)
                    }}
                  />
                )}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="subspecialities"
              control={control}
              render={({ field }) => (
                <TextField {...field} multiline type="text" label="Subespecialidad" variant="standard" fullWidth />
              )}
            />
          </Grid>

          {!fromMyProfile && (
            <>
              <Grid item xs={12}>
                <Controller
                  name="hideCentreInsurances"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked)
                            }}
                          />
                        }
                        label={
                          <p className={s.labelCheckbox}>{`Ocultar ${t('insuranceLabel.p')} del centro de salud`}</p>
                        }
                      />
                    </>
                  )}
                />
                {!checkboxHideCentreInsurances && centreInsurances?.insurances?.length > 0 && (
                  <p className={s.centreInsurances}>
                    {centreInsurances?.insurances?.map((insurance, i) => (
                      <span key={insurance._id}>
                        {insurance.name} {i < centreInsurances?.insurances?.length - 1 ? ', ' : ''}
                      </span>
                    ))}
                  </p>
                )}
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            {isLoading ? (
              <LoaderCircle size={30} />
            ) : (
              <AutoCompleteChedoc
                name="insurances"
                multiple={true}
                control={control}
                setValue={setValue}
                stateRedux={insurancesAvailable}
                functionToDispatch={getListInsurances}
                inputLabel={`${t('insuranceLabel.S')}`}
                className={s.inputWidth98}
              />
            )}
          </Grid>

          {/* nota turnos online  */}
          {!fromMyProfile && (
            <Grid item xs={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    label={`Nota para ${t('turnLabel.p')} online`}
                    multiline
                    rows={3}
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </Grid>
          )}

          {/* sitio web  */}
          {!fromMyProfile && (
            <Grid item xs={12}>
              <Controller
                name="web"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onKeyDown={handleKeyDown}
                    type="url"
                    label="Sitio web"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </Grid>
          )}

          {/* nota del profesional  */}
          {!fromMyProfile && (
            <Grid item xs={12}>
              <Controller
                name="internalNote"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    label="Nota del profesional"
                    multiline
                    rows={3}
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </Grid>
          )}
          {entity && (
            <div>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOpenMenuRooms}
                className={s.MenuButton}
                variant="outlined"
                type="submit"
              >
                Asignar consultorio
                <KeyboardArrowDownIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElRooms}
                open={open}
                onClose={handleCloseMenuRooms}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {centreDetail?.rooms?.map((room) => {
                  return (
                    <MenuItem key={room._id} onClick={() => handleSettingRoom(room)}>
                      {room?.name}
                      {room?.people[0]?._id === entity?._id && <DoneIcon />}
                    </MenuItem>
                  )
                })}
                {!centreDetail?.rooms?.length && <MenuItem>No hay consultorios disponibles</MenuItem>}
              </Menu>
            </div>
          )}
          {!fromMyProfile && (
            <Grid item xs={12} container>
              <Grid item xs={12}>
                <Controller
                  name="onlineTurns"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormControlLabel
                        control={<Checkbox {...field} defaultChecked={field.value} />}
                        label={
                          <p className={s.labelCheckbox}>{`Profesional visible en listado de ${t(
                            'turnLabel.p',
                          )} online:`}</p>
                        }
                      />
                      <>
                        {field.value ? (
                          <span className={s.spanCheckboxYes}>
                            <i>
                              <AiFillCheckCircle /> Habilitado
                            </i>
                          </span>
                        ) : (
                          <span className={s.spanCheckboxNo}>
                            <i>
                              <AiFillCloseCircle /> Deshabilitado
                            </i>
                          </span>
                        )}
                      </>
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="hcAutosave"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormControlLabel
                        control={<Checkbox {...field} defaultChecked={field.value} />}
                        label={<p className={s.labelCheckbox}>{`Autoguardado de fichas de historia clínica:`}</p>}
                      />
                      <>
                        {field.value ? (
                          <span className={s.spanCheckboxYes}>
                            <i>
                              <AiFillCheckCircle /> Habilitado
                            </i>
                          </span>
                        ) : (
                          <span className={s.spanCheckboxNo}>
                            <i>
                              <AiFillCloseCircle /> Deshabilitado
                            </i>
                          </span>
                        )}
                      </>
                    </>
                  )}
                />
              </Grid>
            </Grid>
          )}

          {fromMyProfile && (
            <Grid item xs={12} container>
              <Grid item xs={12}>
                <Controller
                  name="emailOnlineTurnCreation"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked)
                            }}
                          />
                        }
                        label={
                          <p className={s.labelCheckbox}>{`Recibir email al crearse ${t('turnLabel.sa')} online:`}</p>
                        }
                      />
                      <>
                        {field.value ? (
                          <span className={s.spanCheckboxYes}>
                            <i>
                              <AiFillCheckCircle /> Si
                            </i>
                          </span>
                        ) : (
                          <span className={s.spanCheckboxNo}>
                            <i>
                              <AiFillCloseCircle /> No
                            </i>
                          </span>
                        )}
                      </>
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="emailOnlineTurnCancellation"
                  control={control}
                  render={({ field }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked)
                            }}
                          />
                        }
                        label={
                          <p className={s.labelCheckbox}>{`Recibir email al cancelarse ${t(
                            'turnLabel.sa',
                          )} online:`}</p>
                        }
                      />
                      <>
                        {field.value ? (
                          <span className={s.spanCheckboxYes}>
                            <i>
                              <AiFillCheckCircle /> Si
                            </i>
                          </span>
                        ) : (
                          <span className={s.spanCheckboxNo}>
                            <i>
                              <AiFillCloseCircle /> No
                            </i>
                          </span>
                        )}
                      </>
                    </>
                  )}
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        {showErrorEmails && (
          <div className={s.containerShowErrors}>
            <b>Los emails deben coincidir</b>
          </div>
        )}

        <div className={s.containerButtonsForm}>
          {!fromMyProfile && !centreConfiguration?.hideDoctorWorkTimes && (
            <CaluButton
              color="primary"
              type="submit"
              name="cargar"
              inverted="true"
              size="medium"
              onClick={addWorkTimes}
            >
              Cargar horarios laborales
            </CaluButton>
          )}

          <CaluButton color="primary" type="submit" size="medium" name="guardar" loading={isLoading}>
            Guardar
          </CaluButton>
        </div>
      </form>
    </div>
  )
}

export default PersonalInformation
