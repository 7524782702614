import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import CaluInput from '@/components/styledComponents/CaluInput'
import TitlePopUp from '@/components/TitlePopUp'
import { useSocket } from '@/context/SocketProvider'
import { useUser } from '@/context/UserProvider'
import { updateCentre } from '@/store/centres/actions'
import { updateATurn } from '@/store/turns/actions'
import { formatDate } from '@/utils/functions/formatDate'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'

import s from './styles.module.scss'

const ConfirmHolidaysPopUp = ({ open, setOpen, startDate, finishDate, filterActiveTurns, dataToUpdateCentre }) => {
  const dispatch = useDispatch()
  const { socket } = useSocket()
  const { defaultCentre } = useUser()
  const { t } = useTranslation('global')

  const [cancellingTurns, setCancellingTurns] = useState(false)
  const [wordToCancel, setWordToCancel] = useState('')
  const [disabledButton, setDisabledButton] = useState(true)

  const handleClose = () => {
    setOpen(!open)
  }

  const updateArrayTurns = async (turns) => {
    for (let i = 0; i < turns.length; i++) {
      let auxTurnData = {
        ...turns[i],
        state: 'cancelled',
        cancelledInfo: 'doctor',
      }

      try {
        const turnUpdated = await dispatch(updateATurn(auxTurnData, true))
        socket.emit('calendar_edit_turn', `${defaultCentre}_calendar`, turnUpdated)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const confirmAddHolidays = async () => {
    setCancellingTurns(true)

    try {
      if (filterActiveTurns?.length) {
        await updateArrayTurns(filterActiveTurns)
      }

      await dispatch(updateCentre(dataToUpdateCentre))
      // socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedEntity)
      handleClose()
      setCancellingTurns(false)
    } catch (error) {
      console.log(error)
      setCancellingTurns(false)
    }
  }

  const handleDisabledButton = (e) => {
    setWordToCancel(e.target.value)
    if (e.target.value === 'confirmar') return setDisabledButton(false)
    setDisabledButton(true)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" open={open} fullWidth onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle={'Agregar días no laborales'} handleClose={handleClose} />
        </DialogTitle>
        <DialogContent className="animate__animated animate__fadeIn" style={{ padding: '0px' }}>
          <div className={s.containerPopUp}>
            <p>
              Estás agregando días no laborales desde el <b>{formatDate(startDate)}</b> hasta el{' '}
              <b>{formatDate(finishDate)}</b> inclusive.
            </p>

            {filterActiveTurns?.length > 0 && (
              <div>
                <hr />
                <p>
                  Se cancelará{filterActiveTurns?.length === 1 ? '' : 'n'} <b>{filterActiveTurns?.length}</b>{' '}
                  {filterActiveTurns?.length === 1 ? t('turnLabel.s') : t('turnLabel.p')}. Esta acción no se podrá
                  restaurar.
                </p>

                <p className={s.boldParagraph}>Escribe a continuación "confirmar" para continuar con esta acción.</p>

                <CaluInput
                  id="input"
                  placeholder="confirmar"
                  type="text"
                  value={wordToCancel}
                  onChange={(e) => handleDisabledButton(e)}
                  className={s.searchBarContainer}
                />
              </div>
            )}

            <div className={s.containerButtons}>
              <div>
                <CaluButton inverted="true" size="medium" onClick={() => handleClose()}>
                  Cancelar
                </CaluButton>
              </div>

              <div>
                <CaluButton
                  color="primary"
                  size="medium"
                  onClick={confirmAddHolidays}
                  loading={cancellingTurns}
                  disabled={filterActiveTurns?.length ? disabledButton : false}
                >
                  Confirmar
                </CaluButton>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ConfirmHolidaysPopUp
