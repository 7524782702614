import React from 'react'
import { useSelector } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormAdminFieldHC from '../formAdminFieldHC'

const PopUpAdminFieldsHC = ({ open, setOpen }) => {
  const { selectedHCField } = useSelector((state) => state.fieldsHC)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="sm" fullWidth open={open} onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={selectedHCField ? 'Edición de campo de HC' : 'Nuevo campo de HC'}
            handleClose={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <FormAdminFieldHC formValues={selectedHCField} onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpAdminFieldsHC
