import React from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { Button, Grid } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = () => {
  const watchName = useWatch({ name: 'name' })
  const { t } = useTranslation('global')

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <Grid container spacing={2} className={s.containerForm}>
        <Grid item xs={12}>
          <CustomField label={`${t('insuranceLabel.S')}`} name="name" fullWidth />
        </Grid>

        <Grid item xs={12} className={s.containerButtonsForm}>
          <Button disableElevation disabled={!watchName} variant="contained" className={s.buttonSave} type="submit">
            Confirmar
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
