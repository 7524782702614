import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import useValidateURLId from '@/hooks/useValidateURLId'
import { postPatientCentres, updatePatientCentres } from '@/store/patientCentres/actions'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const FormCreatePatient = ({ formValues, onClose }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const centreID = useValidateURLId()
  const centreDetail = useSelector((state) => state?.centres?.centreProfile)
  const country = formValues?.country || centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  if (formValues) {
    let insurancePlan = {},
      policyNumbers = {}
    formValues.insurances?.forEach((insurance, i) => {
      insurancePlan[insurance._id] = formValues.insurancePlan[i]
      policyNumbers[insurance._id] = formValues.policyNumbers[i]
    })
    formValues = { ...formValues, insurancePlan, policyNumbers }
  }

  const handleSubmit = async (data) => {
    setIsLoading(true)
    if (data.dni === '') data.dni = crypto.randomUUID().replaceAll('-', '').slice(0, 15)
    data.insurancePlan = data.insurances.map(({ _id }) => data.insurancePlan[_id])
    data.policyNumbers = data.insurances.map(({ _id }) => data.policyNumbers[_id])
    data.centre = centreID

    try {
      if (formValues) {
        await dispatch(updatePatientCentres(data))
      } else {
        await dispatch(postPatientCentres(data))
        mxp.track(mxp.events.agenda.newPatient.done)
      }
      onClose()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={{ ...formDefaultValues, ...formValues, country: country }}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent isLoading={isLoading} onClose={onClose} />
      </ContainerForm>
    </div>
  )
}

export default FormCreatePatient
