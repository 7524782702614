export const calculateFinalServicePrice = (service, insurance, insurancesPriceList) => {
  let finalServicePrice
  if (insurance !== '-') {
    const insurancePrice = insurancesPriceList?.filter(
      (insuranceItem) => insuranceItem?.insurance?._id === insurance && insuranceItem?.service?._id === service?._id,
    )
    finalServicePrice = insurancePrice?.[0]?.price ? insurancePrice?.[0]?.price : service?.price ? service?.price : 0
  } else {
    finalServicePrice = service?.price ? service?.price : 0
  }

  return finalServicePrice
}

export const getInsurancePrice = (service, insurance, insurancesPriceList) => {
  if (insurance === '-') return

  const insurancePrice = insurancesPriceList?.find(
    (insuranceItem) => insuranceItem?.insurance?._id === insurance && insuranceItem?.service?._id === service?._id,
  )
  return insurancePrice
}
