import { useSelector } from 'react-redux'

const useCheckAvailableCreateAgendas = () => {
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { allMachines } = useSelector((state) => state.machines)

  const subscriptionAgendasQuantity = centreDetail?.centreConfiguration?.subscriptionAgendasQuantity
  const currentAgendasCreated = allMachines?.length

  const handleCheckAllowCreateSchedules = (subscriptionAgendasQuantity, currentAgendasCreated) => {
    const agendasDifference = subscriptionAgendasQuantity - currentAgendasCreated

    if (!subscriptionAgendasQuantity) return { shouldCreateAgenda: true, remainingAgendas: 0 }

    if (agendasDifference <= 0) {
      return { shouldCreateAgenda: false, remainingAgendas: 0 }
    } else {
      return { shouldCreateAgenda: true, remainingAgendas: agendasDifference }
    }
  }

  return handleCheckAllowCreateSchedules(subscriptionAgendasQuantity, currentAgendasCreated)
}

export default useCheckAvailableCreateAgendas
