const calculateTurnAmountWithoutExtraDiscount = (turn) => {
  let amountWithoutExtraDiscount = 0
  // Legacy doctorCentre price (consulta / primera consulta)
  if (!turn.machine) {
    if (turn?.service?._id === 'consultation') {
      amountWithoutExtraDiscount = Number(turn?.doctorCentre?.price || 0)
    } else if (turn?.service?._id === 'firstConsultation') {
      amountWithoutExtraDiscount = Number(turn?.doctorCentre?.priceFirstTime || 0)
    } else {
      amountWithoutExtraDiscount = Number(turn?.service?.price || 0)
      turn?.extraServices?.forEach((s) => (amountWithoutExtraDiscount += s?.price || 0))
    }
  } else {
    amountWithoutExtraDiscount = Number(turn?.service?.price || 0)
    turn?.extraServices?.forEach((s) => (amountWithoutExtraDiscount += s?.price || 0))
  }

  if (turn.insurancePrice) {
    // pisa el valor de servicio, extra servicio
    amountWithoutExtraDiscount = turn?.insurancePrice?.price || 0
  }

  if (turn?.products) {
    turn.products?.forEach((p) => (amountWithoutExtraDiscount += p?.price * p?.quantity || 0))
  }

  if (turn?.treatment) {
    // si hay tratamiento, el valor pisa al amount
    amountWithoutExtraDiscount = turn?.treatment?.price
  }

  return amountWithoutExtraDiscount
}

export { calculateTurnAmountWithoutExtraDiscount }
