import React, { useEffect, useState } from 'react'
import { sortArrayAlphabetically } from '@/utils/functions/sortArrayAlphabetically'
import { FormControl, MenuItem, Select, TextField } from '@mui/material'

import PropTypes from 'prop-types'

import s from './SelectWithInput.module.scss'

const SelectWithInput = ({
  inputLabel,
  valueSelect,
  onChange,
  firstOptionValue,
  firstOptionText,
  options,
  defaultName,
  className,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [filteredOptions, setFilteredOptions] = useState([])

  useEffect(() => {
    searchEntity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, options])

  const searchEntity = () => {
    const items = options?.filter(
      (option) =>
        option?.lastname
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '')
          ?.toLowerCase()
          ?.includes(
            inputValue
              ?.normalize('NFD')
              ?.replace(/[\u0300-\u036f]/g, '')
              ?.toLowerCase(),
          ) ||
        option?.firstname
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '')
          ?.toLowerCase()
          ?.includes(
            inputValue
              ?.normalize('NFD')
              ?.replace(/[\u0300-\u036f]/g, '')
              ?.toLowerCase(),
          ) ||
        option?.name
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '')
          ?.toLowerCase()
          ?.includes(
            inputValue
              ?.normalize('NFD')
              ?.replace(/[\u0300-\u036f]/g, '')
              ?.toLowerCase(),
          ),
    )
    const auxItems = sortArrayAlphabetically(items)
    setFilteredOptions(auxItems)
  }

  const stopImmediatePropagation = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 400,
        width: 550,
      },
    },
  }

  return (
    <div className={s.inputContainer}>
      {inputLabel && <div className={s.titleInput}>{inputLabel}</div>}
      <FormControl variant="standard" fullWidth>
        <Select
          sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '2px solid #0F1333' } }}
          value={valueSelect}
          onChange={onChange}
          MenuProps={MenuProps}
          className={className || ''}
          variant={'outlined'}
          inputProps={{
            classes: {
              icon: s.iconSelect,
            },
          }}
        >
          <div className={s.searchBarContainer}>
            <TextField
              onClickCapture={stopImmediatePropagation}
              onKeyDown={(e) => e.stopPropagation()}
              className={s.searchInput}
              id="search"
              size="small"
              autoComplete="off"
              label="Buscar..."
              variant="standard"
              fullWidth={true}
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onFocus={() => setInputValue('')}
              autoFocus={true}
            />
          </div>

          {firstOptionValue && firstOptionText && <MenuItem value={firstOptionValue}>{firstOptionText}</MenuItem>}

          {filteredOptions?.map((elem, index) => (
            <MenuItem key={elem._id || index} value={elem._id}>
              {elem?.lastname
                ? `${elem?.lastname?.toUpperCase()}, ${elem?.firstname?.toUpperCase()}`
                : elem?.name?.toUpperCase() || `${defaultName} ${index + 1}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectWithInput

SelectWithInput.propTypes = {
  inputLabel: PropTypes.string,
  valueSelect: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  firstOptionValue: PropTypes.string || null,
  firstOptionText: PropTypes.string || null,
  options: PropTypes.array.isRequired,
  defaultName: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
}

SelectWithInput.defaultProps = {
  defaultName: `${'Tabla'.toUpperCase()}`,
}
