import React, { memo, useCallback, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Box, CircularProgress } from '@mui/material'

import debounce from 'debounce'

const AutoSave = memo(({ defaultValues, onSubmit }) => {
  const methods = useFormContext()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(() => {
      methods.handleSubmit(onSubmit)()
    }, 5000),
    [],
  )

  const watchedData = useWatch({
    control: methods.control,
    defaultValue: defaultValues,
  })

  useEffect(() => {
    if (methods.formState.isDirty) {
      debouncedSave()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watchedData)])

  return (
    <Box style={{ display: 'none' }}>
      {methods.formState.isSubmitting && <CircularProgress color="inherit" size={0} />}
    </Box>
  )
})

AutoSave.displayName = 'AutoSave'

export default AutoSave
