import { addNewService, deleteService, fetchServices, fetchSingleService, putService } from '@/services/services'
import {
  FETCH_SERVICES,
  LOADING_SERVICES,
  RESET_SERVICE_SELECTED,
  SERVICE_CREATED,
  SERVICE_DELETE,
  SERVICE_SELECTED,
  SERVICE_UPDATED,
} from '@/store/services'
import { sortObjectsByNameWithoutAccents } from '@/utils/functions/sortObjectsByNameWithoutAccents'
import { showToast } from '@/utils/toast'

const getAllServices = (params) => async (dispatch) => {
  try {
    if (params?.paginate) {
      const { docs: allServices, ...paginationData } = await fetchServices(params)
      const sortServices = sortObjectsByNameWithoutAccents(allServices)
      dispatch(FETCH_SERVICES({ sortServices, paginationData }))
    } else {
      const allServices = await fetchServices(params)
      const sortServices = sortObjectsByNameWithoutAccents(allServices)
      dispatch(FETCH_SERVICES(sortServices))
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const postService = (data, hideAlert) => async (dispatch) => {
  try {
    const newService = await addNewService(data)
    dispatch(SERVICE_CREATED(newService))
    if (!hideAlert) {
      showToast('Servicio agregado correctamente', 'success')
    }
    return newService
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const deleteServiceAdded = (id) => async (dispatch) => {
  try {
    await deleteService(id)
    dispatch(SERVICE_DELETE(id))
    showToast('Servicio Eliminado correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateService = (data) => async (dispatch) => {
  try {
    const serviceUpdated = await putService(data)
    dispatch(SERVICE_UPDATED(serviceUpdated))

    if (serviceUpdated) {
      showToast('Servicio actualizado correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedService = (id) => async (dispatch) => {
  try {
    const singleService = await fetchSingleService(id)
    dispatch(SERVICE_SELECTED(singleService))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedService = () => async (dispatch) => {
  try {
    dispatch(RESET_SERVICE_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const handlePaginationChangeServices = (newPage, newLimit, searchText) => async (dispatch, getState) => {
  const { centreProfile } = getState().centres
  const params = {
    centre: centreProfile?._id,
    page: newPage,
    limit: newLimit,
    search: searchText,
    paginate: true,
  }
  dispatch(LOADING_SERVICES(true))
  const { docs: allServices, ...paginationData } = await fetchServices(params)
  const sortServices = sortObjectsByNameWithoutAccents(allServices)
  dispatch(FETCH_SERVICES({ sortServices, paginationData }))
  dispatch(LOADING_SERVICES(false))
}

export {
  deleteServiceAdded,
  getAllServices,
  handlePaginationChangeServices,
  postService,
  resetSelectedService,
  selectedService,
  updateService,
}
