import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import AutoCompleteChedoc from '@/components/AutoCompleteChedoc'
import LoaderCircle from '@/components/LoaderCircle'
import CaluButton from '@/components/styledComponents/CaluButton'
import { useSocket } from '@/context/SocketProvider'
import { useUser } from '@/context/UserProvider'
import { getCurrentcentreInsurances } from '@/store/centreInsurances/actions'
import { getListInsurances } from '@/store/insurances/actions'
import { postMachine, updateMachine } from '@/store/machines/actions'
import { updateRoom } from '@/store/rooms/actions'
import { getAllServices } from '@/store/services/actions'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import DoneIcon from '@mui/icons-material/Done'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Button, Checkbox, FormControlLabel, Grid, Menu, MenuItem, TextField } from '@mui/material'

import s from './styles.module.scss'

const PersonalInformation = ({ handleClose, addWorkTimes, entity }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const { defaultCentre } = useUser()
  const { socket } = useSocket()

  const [isLoading, setIsLoading] = useState(false)
  const [enabledLoadWorkingHours, setEnabledLoadWorkingHours] = useState(false)

  const machineSelected = useSelector((state) => state.machines.machineSelected) || entity
  const centreInsurances = useSelector((state) => state?.centreInsurances?.centreInsurancesDetail)
  const { insurancesList } = useSelector((state) => state?.insurances)
  const insurancesAvailable = !insurancesList?.length ? centreInsurances?.insurances || [] : insurancesList
  const centreDetail = useSelector((state) => state?.centres?.centreProfile)
  const [anchorElRooms, setAnchorElRooms] = useState(null)
  const open = Boolean(anchorElRooms)

  const onLoad = async () => {
    const params = { centre: defaultCentre }
    setIsLoading(true)
    await dispatch(getCurrentcentreInsurances(defaultCentre))
    await dispatch(getAllServices(params))
    setIsLoading(false)
  }

  useEffect(() => {
    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (machineSelected && enabledLoadWorkingHours) {
      addWorkTimes()
      setEnabledLoadWorkingHours(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineSelected])

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      onlineTurns: machineSelected?.onlineTurns || true,
      name: '',
      description: '',
      note: '',
      internalNote: '',
      hideCentreInsurances: entity?.hideCentreInsurances || false,
    },
  })

  const checkboxHideCentreInsurances = watch('hideCentreInsurances')

  const onSubmit = async (data, e) => {
    if (!e) return
    e.preventDefault()
    let buttonClicked = e?.nativeEvent?.submitter?.name

    setIsLoading(true)

    data.centre = defaultCentre || entity.centre

    try {
      if (machineSelected && buttonClicked === 'cargar') {
        const updatedMachine = await dispatch(updateMachine(data))
        socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedMachine)
        setEnabledLoadWorkingHours(true)
        return
      }

      if (machineSelected && buttonClicked === 'guardar') {
        mxp.track(mxp.events.agenda.modifyAgenda.update)
        const updatedMachine = await dispatch(updateMachine(data))
        socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedMachine)
        handleClose()
        return
      }

      if (buttonClicked === 'cargar') {
        const createdMachine = await dispatch(postMachine(data))
        socket.emit('calendar_new_entity', `${defaultCentre}_calendar`, createdMachine)
        setEnabledLoadWorkingHours(true)
        return
      }

      if (buttonClicked === 'guardar') {
        const createdMachine = await dispatch(postMachine(data))
        socket.emit('calendar_new_entity', `${defaultCentre}_calendar`, createdMachine)
        handleClose()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (machineSelected) {
      Object.keys(machineSelected).forEach((k) => {
        setValue(k, machineSelected[k])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineSelected])

  const handleOpenMenuRooms = (event) => {
    setAnchorElRooms(event.currentTarget)
  }

  const handleCloseMenuRooms = () => {
    setAnchorElRooms(null)
  }

  const handleSettingRoom = async (room) => {
    let auxRoom = {
      ...room,
      people: [
        {
          name: entity?.name ? entity?.name : `${entity?.lastname}, ${entity?.firstname}`,
          _id: entity?._id,
        },
      ],
    }

    try {
      await dispatch(updateRoom(auxRoom))
      handleCloseMenuRooms()
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <form className={s.containerForm} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  inputProps={{ style: { textTransform: 'capitalize' } }}
                  type="text"
                  label="Nombre *"
                  variant="standard"
                  fullWidth
                  onKeyDown={handleKeyDown}
                />
              )}
              rules={{ required: true }}
            />
            {errors.name?.type === 'required' && <p className={s.formAlert}>Nombre requerido</p>}
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField {...field} multiline type="text" label="Descripción" variant="standard" fullWidth />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="hideCentreInsurances"
              control={control}
              render={({ field }) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked)
                        }}
                      />
                    }
                    label={<p className={s.labelCheckbox}>{`Ocultar ${t('insuranceLabel.p')} del centro de salud`}</p>}
                  />
                </>
              )}
            />
            {!checkboxHideCentreInsurances && centreInsurances?.insurances?.length > 0 && (
              <p className={s.centreInsurances}>
                {centreInsurances?.insurances?.map((insurance, i) => (
                  <span key={insurance._id}>
                    {insurance.name} {i < centreInsurances?.insurances?.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </p>
            )}
          </Grid>

          <Grid item xs={12}>
            {isLoading ? (
              <LoaderCircle size={30} />
            ) : (
              <AutoCompleteChedoc
                name="insurances"
                multiple={true}
                control={control}
                setValue={setValue}
                stateRedux={insurancesAvailable}
                functionToDispatch={getListInsurances}
                inputLabel={`${t('insuranceLabel.S')}`}
                className={s.inputWidth98}
              />
            )}
          </Grid>
          {entity && (
            <div>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOpenMenuRooms}
                className={s.MenuButton}
                variant="outlined"
              >
                Asignar consultorio
                <KeyboardArrowDownIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElRooms}
                open={open}
                onClose={handleCloseMenuRooms}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {centreDetail?.rooms?.map((room) => {
                  return (
                    <MenuItem key={room._id} onClick={() => handleSettingRoom(room)}>
                      {room?.name}
                      {room?.people[0]?._id === entity?._id && <DoneIcon />}
                    </MenuItem>
                  )
                })}
                {!centreDetail?.rooms.length && <MenuItem>No hay consultorios disponibles</MenuItem>}
              </Menu>
            </div>
          )}

          <Grid item xs={12}>
            <Controller
              name="onlineTurns"
              control={control}
              render={({ field }) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox {...field} defaultChecked={machineSelected ? machineSelected.onlineTurns : true} />
                    }
                    label={
                      <p className={s.labelCheckbox}>{`Agenda visible en listado de ${t('turnLabel.p')} online:`}</p>
                    }
                  />
                  <>
                    {field.value ? (
                      <span className={s.spanCheckboxYes}>
                        <i>
                          <AiFillCheckCircle /> Habilitado
                        </i>
                      </span>
                    ) : (
                      <span className={s.spanCheckboxNo}>
                        <i>
                          <AiFillCloseCircle /> Deshabilitado
                        </i>
                      </span>
                    )}
                  </>
                </>
              )}
            />
          </Grid>
        </Grid>
        <div className={s.containerButtonsForm}>
          <CaluButton color="primary" type="submit" name="cargar" inverted="true" size="medium">
            Cargar horarios laborales
          </CaluButton>

          <CaluButton color="primary" type="submit" name="guardar" size="medium" loading={isLoading}>
            Guardar
          </CaluButton>
        </div>
      </form>
    </div>
  )
}

export default PersonalInformation
