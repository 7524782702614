import { addNewCentreProvider, deleteCentreProvider, putCentreProvider } from '@/services/centreProvider'
import {
  CENTRE_PROVIDER_CREATED,
  CENTRE_PROVIDER_DELETE,
  CENTRE_PROVIDER_SELECTED,
  CENTRE_PROVIDER_UPDATED,
  FETCH_CENTRE_PROVIDERS,
  RESET_CENTRE_PROVIDER_SELECTED,
} from '@/store/centreProvider'
import { showToast } from '@/utils/toast'

const getAllCentreProviders = (fetchAllCentreProviders) => async (dispatch) => {
  dispatch(FETCH_CENTRE_PROVIDERS(fetchAllCentreProviders))
}

const postNewCentreProvider = (data) => async (dispatch) => {
  try {
    let newCentreProvider = await addNewCentreProvider(data)
    dispatch(CENTRE_PROVIDER_CREATED(newCentreProvider))
    if (newCentreProvider) {
      showToast('Proveedor creado correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const deleteCentreProviderAdded = (id) => async (dispatch) => {
  try {
    await deleteCentreProvider(id)
    dispatch(CENTRE_PROVIDER_DELETE(id))
    showToast('Proveedor eliminado correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateCentreProvider = (data) => async (dispatch) => {
  try {
    let centreProviderUpdated = await putCentreProvider(data)
    dispatch(CENTRE_PROVIDER_UPDATED(centreProviderUpdated))

    if (centreProviderUpdated) {
      showToast('Proveedor actualizado correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedCentreProvider = (data) => async (dispatch) => {
  try {
    dispatch(CENTRE_PROVIDER_SELECTED(data))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedCentreProvider = () => async (dispatch) => {
  try {
    dispatch(RESET_CENTRE_PROVIDER_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export {
  deleteCentreProviderAdded,
  getAllCentreProviders,
  postNewCentreProvider,
  resetSelectedCentreProvider,
  selectedCentreProvider,
  updateCentreProvider,
}
