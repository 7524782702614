import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CaluInput from '@/components/styledComponents/CaluInput'
import CaluRawDatePicker from '@/components/styledComponents/CaluRawDatePicker'
import useDebounce from '@/hooks/useDebounce'
import useValidateURLId from '@/hooks/useValidateURLId'
import { LOADING } from '@/store/debts'
import { getTurnsDebts, getTurnsDebtsTotals } from '@/store/debts/actions'
import { clearPatientCentres, getPatientsCentreByInputSearch } from '@/store/patientCentres/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { Autocomplete, CircularProgress, Grid } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const HeaderSearchBarTurnsDebts = ({
  inputSearch,
  setInputSearch,
  loadingPatients,
  setLoadingPatients,
  patientValue,
  setPatientValue,
  startDate,
  setStartDate,
  finishDate,
  setFinishDate,
}) => {
  const dispatch = useDispatch()
  const centreID = useValidateURLId()
  const { t } = useTranslation('global')
  const DELAY_TIME = 300

  const { patientCentres } = useSelector((state) => state.patientCentres)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const inputDebounceValue = useDebounce(inputSearch, DELAY_TIME)

  useEffect(() => {
    const searchPatient = async () => {
      setPatientValue(null)
      if (inputDebounceValue && inputDebounceValue.length >= 2) {
        const params = {
          centre: centreID,
          search: inputDebounceValue,
        }
        setLoadingPatients(true)
        await dispatch(getPatientsCentreByInputSearch(params))
        setLoadingPatients(false)
      } else {
        dispatch(clearPatientCentres())
      }
    }
    searchPatient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDebounceValue])

  const setInit = async (params) => {
    dispatch(LOADING(true))
    await dispatch(getTurnsDebts(params))

    params.totals = true
    await dispatch(getTurnsDebtsTotals(params))

    dispatch(LOADING(false))
  }

  useEffect(() => {
    let params = {
      centre: centreID,
      start: toCustomTz(startDate, 'utc', true),
      finish: toCustomTz(finishDate, 'utc', true),
      patientCentre: !patientValue ? null : patientValue?._id,
      // doctorCentre: '',
    }

    setInit(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, finishDate, patientValue])

  const handleSetStartDate = (newDate) => {
    if (dayjs(newDate).startOf('day').format() === 'Invalid Date') return
    setStartDate(dayjs(newDate).startOf('day').format())
    if (dayjs(newDate).startOf('day').format('DD-MM-YYYY') > dayjs(finishDate).startOf('day').format('DD-MM-YYYY')) {
      setFinishDate(dayjs(newDate).add(1, 'day').endOf('day').format())
    }
  }

  const handleSetFinishDate = (newDate) => {
    if (dayjs(newDate).startOf('day').format() === 'Invalid Date') return
    const selectedDate = dayjs(newDate).startOf('day').format('DD-MM-YYYY')
    const selectedStartDate = dayjs(startDate).startOf('day').format('DD-MM-YYYY')
    if (dayjs(selectedDate).isBefore(dayjs(selectedStartDate))) return
    setFinishDate(dayjs(newDate).endOf('day').format())
  }

  const valueOfPatient = (e, patient) => {
    setPatientValue(patient)
  }

  return (
    <Grid
      container
      spacing={1}
      className={s.mainContainer}
      sx={{
        '& .MuiGrid-root.MuiGrid-item': {
          padding: '0px',
          paddingTop: '3px',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#009688', // Solid underline on focus
        },
      }}
    >
      <Grid item xs={12} md={4} className={s.searchBarContainer}>
        <Autocomplete
          fullWidth
          freeSolo
          loading={loadingPatients}
          loadingText="Cargando..."
          disablePortal
          size="small"
          id="combo-box-demo"
          disableClearable
          noOptionsText="No se encontraron resultados"
          onChange={valueOfPatient}
          options={patientCentres?.map((option) => option)}
          getOptionLabel={(option) =>
            option?.dni?.length < 15
              ? `${option?.lastname?.toUpperCase()}, ${option?.firstname?.toUpperCase()} (DNI: ${option?.dni})`
              : `${option?.lastname?.toUpperCase()}, ${option?.firstname?.toUpperCase()}`
          }
          isOptionEqualToValue={(option) => option._id}
          className={s.notchedOutline}
          renderInput={(params) => (
            <CaluInput
              {...params}
              autoFocus
              labelText={`Buscar ${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}`}
              InputProps={{
                ...params.InputProps,
                endAdornment: loadingPatients ? <CircularProgress className={s.circularProgress} /> : null,
              }}
              value={inputSearch}
              onChange={(e) => setInputSearch(e.target.value)}
            />
          )}
        />
      </Grid>
      <Grid container item xs={12} md={4} className={s.containerDatePickers}>
        <Grid item xs={12} sm={5}>
          <CaluRawDatePicker labelText="Desde" value={startDate} onChange={handleSetStartDate} />
        </Grid>
        <Grid item xs={12} sm={5}>
          <CaluRawDatePicker
            labelText="Hasta"
            value={finishDate}
            onChange={handleSetFinishDate}
            minDate={dayjs(startDate).add(1, 'day')}
            disableHighlightToday={true}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default HeaderSearchBarTurnsDebts
