import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import { useSocket } from '@/context/SocketProvider'
import useValidateURLId from '@/hooks/useValidateURLId'
import { updatePatientTreatment } from '@/store/treatments/actions'
import { postNewTurn } from '@/store/turns/actions'
import { Grid } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const AvailableTurnsList = ({ entity, service, searchingFor, treatment, centre, setOpenPopUp }) => {
  const dispatch = useDispatch()
  const centreId = useValidateURLId()
  const { socket } = useSocket()
  const [openPopUpToConfirmSession, setOpenPopUpToConfirmSession] = useState(false)
  const [infoSession, setInfoSession] = useState({})
  const entityAvailability = useSelector(
    (state) => state.centreAvailability?.[searchingFor]?.[service?._id]?.availability?.[entity?._id],
  )
  let availableDays = entityAvailability?.availableDays

  const turnsPerDay = (day, entityAvailability) => {
    for (const key in entityAvailability) {
      if (key === day) {
        return entityAvailability[key]
      }
    }
  }

  const formatDate = (date) => {
    const dayNumber = dayjs(date).format('D')
    const dayName = dayjs(date).format('ddd').toUpperCase()
    const month = dayjs(date).format('MMM').toUpperCase()
    return `${dayName} ${dayNumber} ${month}.`
  }

  const handleAddNewSession = (day, elem) => {
    const date = dayjs(day).utc().format('YYYY-MM-DD')
    let hourAndMinuteTurn = elem.startHour.split(':')
    const startHour = dayjs(date)
      .utc()
      .hour(hourAndMinuteTurn[0], 'hour')
      .minute(hourAndMinuteTurn[1], 'minute')
      .format()
    const finishHour = dayjs(startHour).utc().add(elem.turnDuration, 'm').format()
    const objectDate = {
      date: dayjs(date).utc().format(),
      hourTurn: elem.startHour,
      startHour: startHour,
      finishHour: finishHour,
      turnDuration: elem.turnDuration,
    }

    setOpenPopUpToConfirmSession(true)
    setInfoSession(objectDate)
  }

  const handleCreateSession = async () => {
    const auxCreateTurn = {
      centre: centreId,
      date: dayjs(infoSession.date).utc().format('YYYY-MM-DD'),
      patientCentre: treatment.patientCentre,
      finishHour: infoSession.finishHour,
      startHour: infoSession.startHour,
      treatment: treatment?._id || treatment,
      consultationDuration: infoSession.turnDuration,
      turnDuration: infoSession.turnDuration,
      service: service,
    }

    entity.firstname ? (auxCreateTurn.doctorCentre = entity) : (auxCreateTurn.machine = entity)

    if (service._id === 'consultation') {
      auxCreateTurn.firstTime = false
      auxCreateTurn.service = null
    } else if (service._id === 'firstConsultation') {
      auxCreateTurn.firstTime = true
      auxCreateTurn.service = null
    } else {
      auxCreateTurn.service = service
    }

    const newTurn = await dispatch(postNewTurn(auxCreateTurn, true))
    newTurn.service = auxCreateTurn.service
    const patientTreatment = {
      ...treatment,
      turns: [...treatment.turns, newTurn],
    }

    const updatedPatientTreatment = await dispatch(updatePatientTreatment(patientTreatment))

    const createdTurnWithTreatment = {
      ...newTurn,
      treatment: updatedPatientTreatment,
    }
    socket.emit('calendar_new_turn', `${centreId}_calendar`, createdTurnWithTreatment)
    setOpenPopUp(false)
  }
  const showTitle = () => {
    const startHour = dayjs(infoSession.startHour).utc().format('HH:mm')
    const date = dayjs(infoSession.date).utc().format('dddd D MMMM')
    return `¿Realmente quiere agregar una sesion el dias  ${date} a las ${startHour} hs ?`
  }
  return (
    <>
      {availableDays ? (
        <Grid className={s.mainContainer}>
          {availableDays?.map((day, i) => (
            <div key={i} className={s.listTurnsDay}>
              <p className={s.turnsDay}>{formatDate(day)}</p>
              <span className={s.detailTurns}>
                <div>
                  {turnsPerDay(day, entityAvailability).map((elem, i) => (
                    <div key={i}>
                      <div className={s.itemTurn} onClick={() => handleAddNewSession(day, elem)}>
                        <span>{elem.startHour} hs</span>
                      </div>
                    </div>
                  ))}
                </div>
              </span>
            </div>
          ))}
        </Grid>
      ) : null}
      {openPopUpToConfirmSession && infoSession && (
        <AlertDialogSlide
          open={openPopUpToConfirmSession}
          setOpen={setOpenPopUpToConfirmSession}
          title="Confirmación de creación de sesión"
          textContent={showTitle()}
          textBackButton="Cancelar"
          textConfirmationButton={'Crear sesión'}
          onClose={handleCreateSession}
        />
      )}
    </>
  )
}

export default AvailableTurnsList
