import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'insurances',
  initialState: {
    insurancesList: [],
    insuranceSelected: null,
    insurancesPrice: [],
  },
  reducers: {
    INSURANCES_UPDATED: (state, { payload }) => {
      state.insurancesList = payload.insurancesList
    },
    INSURANCE_SELECTED: (state, { payload }) => {
      state.insuranceSelected = payload
    },
    INSURANCES_PRICE: (state, { payload }) => {
      state.insurancesPrice = payload
    },
    RESET_INSURANCE_SELECTED: (state, { payload }) => {
      state.insuranceSelected = null
    },
    INSURANCE_CREATED: (state, { payload }) => {
      state.insurancesList = [...(state?.insurancesList || []), payload]
    },
    INSURANCE_UPDATED: (state, { payload }) => {
      state.insurancesList = state?.insurancesList?.map((insurance) => {
        if (insurance?._id === payload?._id) {
          insurance = payload
        }
        return insurance
      })
      state.insuranceSelected = payload
    },
    INSURANCE_DELETE: (state, { payload }) => {
      state.insurancesList = state?.insurancesList?.filter((insurance) => insurance?._id !== payload)
      state.insuranceSelected = null
    },
  },
})

export const {
  INSURANCES_UPDATED,
  INSURANCE_SELECTED,
  INSURANCES_PRICE,
  RESET_INSURANCE_SELECTED,
  INSURANCE_CREATED,
  INSURANCE_UPDATED,
  INSURANCE_DELETE,
} = slice.actions
export default slice.reducer
