import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'fieldTables',
  initialState: {
    allFieldTables: [],
    selectedFieldTable: null,
  },
  reducers: {
    FETCH_FIELD_TABLES: (state, { payload }) => {
      state.allFieldTables = payload
    },
    FIELD_TABLE_UPDATED: (state, { payload }) => {
      state.allFieldTables = state.allFieldTables.map((fieldTable) => {
        if (fieldTable._id === payload._id) {
          fieldTable = payload
        }
        return fieldTable
      })
      state.selectedFieldTable = payload
    },
    FIELD_TABLE_DELETE: (state, { payload }) => {
      state.allFieldTables = state.allFieldTables.filter((fieldTable) => fieldTable._id !== payload)
      state.selectedFieldTable = null
    },
    FIELD_TABLE_CREATED: (state, { payload }) => {
      state.allFieldTables = [...(state.allFieldTables || []), payload]
      state.selectedFieldTable = payload
    },
    FIELD_TABLE_SELECTED: (state, { payload }) => {
      state.selectedFieldTable = payload
    },
    RESET_FIELD_TABLE_SELECTED: (state, { payload }) => {
      state.selectedFieldTable = null
    },
  },
})

export const {
  FETCH_FIELD_TABLES,
  FIELD_TABLE_UPDATED,
  FIELD_TABLE_DELETE,
  FIELD_TABLE_CREATED,
  FIELD_TABLE_SELECTED,
  RESET_FIELD_TABLE_SELECTED,
} = slice.actions
export default slice.reducer
