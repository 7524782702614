import { useDispatch } from 'react-redux'

export const useDeleteImage = () => {
  const dispatch = useDispatch()

  const deleteProfileImage = async (str, prevState, updateFunction, extraData, updateCentreProfileState = false) => {
    let auxData = {}

    if (str === 'logo') {
      auxData = {
        ...prevState,
        logo: '',
      }
    }

    if (str === 'image') {
      auxData = {
        ...prevState,
        image: '',
      }
    }

    try {
      if (updateCentreProfileState) {
        await dispatch(updateFunction(auxData, extraData, null, true))
      } else {
        await dispatch(updateFunction(auxData, extraData))
      }
    } catch (e) {
      console.log(e)
    }
  }
  return {
    deleteProfileImage,
  }
}
