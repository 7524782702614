export const findEntityInfo = (user, idEntity, role) => {
  let entity
  if (role === 'doctor') {
    user.doctorCentres.forEach((doctor) => {
      if (doctor._id === idEntity) entity = doctor
    })
  }
  if (role === 'secretary') {
    user.secretaryCentres.forEach((secretary) => {
      if (secretary._id === idEntity) entity = secretary
    })
  }
  return entity
}
