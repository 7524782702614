import React from 'react'
import { useDispatch } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { resetSelectedDoctorCentre } from '@/store/doctorCentres/actions'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormCreateDoctor from '../formCreateDoctorCentre.jsx'

const DoctorCentresPopUp = ({
  openDoctorPopUp,
  setOpenDoctorPopUp,
  entity,
  setOpenAutocompleteDoctorCentres = undefined,
}) => {
  const dispatch = useDispatch()

  const handleCloseDoctorPopUp = () => {
    setOpenDoctorPopUp(false)
    dispatch(resetSelectedDoctorCentre())
    if (setOpenAutocompleteDoctorCentres) setOpenAutocompleteDoctorCentres(true)
  }

  return (
    <div>
      <Dialog
        disableEscapeKeyDown={true}
        maxWidth="md"
        fullWidth
        open={openDoctorPopUp}
        onClose={handleCloseDoctorPopUp}
        scroll="paper"
      >
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={entity ? 'Configuración de profesional' : 'Nuevo profesional'}
            handleClose={handleCloseDoctorPopUp}
          />
        </DialogTitle>
        <DialogContent style={{ padding: '0px' }}>
          <FormCreateDoctor handleClose={handleCloseDoctorPopUp} entity={entity} openDoctorPopUp={openDoctorPopUp} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default DoctorCentresPopUp
