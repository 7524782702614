import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'
import { reducePayload } from './reducePayload'

const fetchSubscriptionPayments = async (params) => {
  const route = `api/subscriptionPayments`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchSingleSubscriptionPayment = async (id) => {
  const route = `api/subscriptionPayments/${id}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchSubscriptionPaymentsDebt = async (params) => {
  const route = `api/subscriptionPayments/checkDebt`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchSubscriptionPaymentsBackup = async (params) => {
  const route = `api/subscriptionPayments/backup`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewSubscriptionPayment = async (data) => {
  const route = `api/subscriptionPayments`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteSubscriptionPayment = async (id) => {
  const route = `api/subscriptionPayments/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putSubscriptionPayment = async (data) => {
  const auxData = reducePayload(data)

  const route = `api/subscriptionPayments`
  return API.put(route, auxData)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export {
  addNewSubscriptionPayment,
  deleteSubscriptionPayment,
  fetchSingleSubscriptionPayment,
  fetchSubscriptionPayments,
  fetchSubscriptionPaymentsBackup,
  fetchSubscriptionPaymentsDebt,
  putSubscriptionPayment,
}
