import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'

import s from './styles.module.scss'

const DetailServicesAndExtraServicesPrice = ({
  selectedService,
  finalMainServicePrice,
  country,
  extraServices,
  selectedInsurance,
  allInsurancePrices,
  totalServicesPrice,
}) => {
  return (
    <div className={s.serviceDescription}>
      <ul>
        <li className={s.itemService}>
          <b>{selectedService?.name?.toUpperCase()}</b>{' '}
          {finalMainServicePrice > 0 && (
            <span>
              {' '}
              : {handleSetMoneySign(country)}
              {finalMainServicePrice}
            </span>
          )}
          {selectedService?.description && <span> : {selectedService?.description}</span>}
        </li>

        {extraServices.map((extraService) => {
          return (
            <li key={extraService._id} className={s.itemService}>
              <b>{extraService.name.toUpperCase()}</b>{' '}
              {extraService?.price && (
                <span>
                  ({handleSetMoneySign(country)}
                  {extraService?.price})
                </span>
              )}
              {extraService?.description && <span> : {extraService?.description}</span>}
            </li>
          )
        })}
      </ul>
      {totalServicesPrice > 0 && (
        <div className={s.totalAmountServices}>
          Total:{' '}
          <span>
            {handleSetMoneySign(country)} {totalServicesPrice}
          </span>
        </div>
      )}
    </div>
  )
}

export default DetailServicesAndExtraServicesPrice
