import React from 'react'
import Pagination from '@mui/material/Pagination'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

const ButtonsPagination = ({ paginationData, handleChangePaginationNumber }) => {
  const { totalPages, page } = paginationData || {}

  return (
    <>
      {totalPages > 1 && (
        <div className={s.buttonSection}>
          <Pagination onChange={(e, value) => handleChangePaginationNumber(value)} count={totalPages} page={page} />
        </div>
      )}
    </>
  )
}

export default ButtonsPagination

ButtonsPagination.propTypes = {
  paginationData: PropTypes.object.isRequired,
  handleChangePagination: PropTypes.func,
}
