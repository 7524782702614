import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import DividerSection from '@/components/DividerSection'
import CaluButton from '@/components/styledComponents/CaluButton'
import { extrasAmount } from '@/helpers/constants'
import { getExtraAmountTypeText } from '@/utils/functions/getExtraAmountTypeText'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'

import s from '../../styles.module.scss'

const PaymentDataForm = (props) => {
  const [totalAmountToPay, setTotalAmountToPay] = useState(0)
  const [typeExtraAmount, setTypeExtraAmount] = useState('plusCash')
  const [addExtraAmount, setAddExtraAmpunt] = useState(null)

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const totalAmount = () => {
    let totalAmount = props.amount
    let totalDebt = props.debt
    if (props.addExtraAmount) {
      if (typeExtraAmount === 'plusCash') {
        totalAmount = props.addExtraAmount + totalAmount
        totalDebt = props.addExtraAmount + totalDebt
      } else if (typeExtraAmount === 'plusPercentage') {
        totalAmount = (totalAmount * props.addExtraAmount) / 100 + totalAmount
        totalDebt = (totalDebt * props.addExtraAmount) / 100 + totalDebt
      } else if (typeExtraAmount === 'minusCash') {
        totalAmount = totalAmount - props.addExtraAmount
        totalDebt = totalDebt - props.addExtraAmount
      } else if (typeExtraAmount === 'minusPercentage') {
        totalAmount = totalAmount - (totalAmount * props.addExtraAmount) / 100
        totalDebt = totalDebt - (totalDebt * props.addExtraAmount) / 100
      }
      props.setExtraAmount(totalAmount)
      props.setExtraDebt(totalDebt)
    }
    return totalAmount
  }

  useEffect(() => {
    setTotalAmountToPay(() => totalAmount())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addExtraAmount, props.amount, typeExtraAmount, props.debt])

  const totalDebt = () => {
    let totalDebt = totalAmountToPay
    if (props.addExtraAmount) {
      totalDebt = totalAmount()
    }
    if (props.paymentHistory.length >= 1) {
      let payment = props.paymentHistory.map((e, i) => e.amount).reduce((a, b) => a + b)
      totalDebt = totalAmount() - payment
    }
    return totalDebt
  }

  //suma los pagos realizados en la seccion 'agregar pago'
  const totalPayments = () => {
    let totalPayment
    if (props.paymentHistory.length >= 1) {
      totalPayment = props.paymentHistory?.map((e) => e.amount).reduce((a, p) => a + p)
    } else {
      totalPayment = 0
    }
    return totalPayment
  }

  const extraQuantity = (value) => {
    if (value) {
      props.setAddExtraAmount(parseInt(value))
      props.setQuantityExtraAmount(parseInt(value))
    } else {
      props.setQuantityExtraAmount(0)
      props.setAddExtraAmount(0)
      props.setDebt(props.debt)
      props.setExtraDebt(null)
      props.setExtraAmount(null)
    }
  }
  return (
    <div>
      <DividerSection label="Datos del pago" />
      {!addExtraAmount && (
        <Grid className={s.addExtraAmountContainer}>
          <CaluButton size="small" inverted="true" onClick={() => setAddExtraAmpunt(true)} className={s.addExtraButton}>
            Agregar extra
          </CaluButton>
        </Grid>
      )}

      {addExtraAmount && (
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Controller
              name="typeExtraAmount"
              control={props.control}
              defaultValue={typeExtraAmount}
              render={({ field }) => (
                <FormControl variant="standard" fullWidth className={s.inputWidth90}>
                  <InputLabel>Tipo de extras o descuentos</InputLabel>
                  <Select
                    {...field}
                    onChange={(e) => {
                      setTypeExtraAmount(e.target.value)
                      props.setValue('typeExtraAmount', e.target.value)
                    }}
                  >
                    {extrasAmount?.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="extraAmount"
              control={props.control}
              defaultValue={typeExtraAmount}
              render={() => (
                <TextField
                  type="number"
                  label={getExtraAmountTypeText(typeExtraAmount)}
                  variant="standard"
                  className={s.inputWidth90}
                  onChange={(e) => extraQuantity(e.target.value)}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Controller
              name="reasonExtraAmount"
              control={props.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  type="text"
                  label={`Motivo`}
                  variant="standard"
                  className={s.inputWidth90}
                />
              )}
              defaultValue=""
            />
          </Grid>
        </Grid>
      )}
      <br />
      <div className={s.paymentDetails}>
        <div className={s.amounts}>
          Monto a pagar:{' '}
          <b>
            {handleSetMoneySign(country)}
            {totalAmountToPay}
          </b>
        </div>
        <div className={s.amounts}>
          Monto pagado:{' '}
          <b>
            {handleSetMoneySign(country)}
            {totalPayments()}
          </b>{' '}
        </div>
        <div className={s.amounts}>
          Deuda:{' '}
          <b>
            {handleSetMoneySign(country)}
            {totalDebt()}
          </b>{' '}
        </div>
      </div>

      <br />
    </div>
  )
}

export default PaymentDataForm
