import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import notificationLogo from '@/assets/images/notification.png'
import { selectedInsuranceByList } from '@/store/insurances/actions'

import s from './styles.module.scss'

const ContentSideBar = ({ listItem }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  return (
    <div>
      {!listItem?.length ? (
        <p className={s.emptyList}>{`No hay ${t('insuranceLabel.p')} cargados aún`}</p>
      ) : (
        listItem?.map((item) => (
          <div key={item._id} className={s.item} onClick={async () => await dispatch(selectedInsuranceByList(item))}>
            <img src={notificationLogo} alt={`${t('insuranceLabel.p')}`} />
            <div className={s.containerInfoService}>
              <p>
                <span className={s.bold}>Nombre: </span> <span> {item?.name}</span>
              </p>
              <p>
                <span className={s.bold}>Id: </span>
                <span>{item?._id}</span>
              </p>
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default ContentSideBar
