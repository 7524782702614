import React from 'react'
import { MenuItem, Select } from '@mui/material'

import s from '../styles.module.scss'

const SelectComponent = ({
  valueSelect,
  setValueSelect,
  setCsvValues,
  doneImportation,
  dateValidFormats,
  dateFormat,
  setDateFormat,
}) => {
  return (
    <div className={s.contSelects}>
      <Select
        value={valueSelect}
        onChange={(e) => {
          setValueSelect(e.target.value)
          setCsvValues([])
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        variant="outlined"
        className={s.selectView}
        disabled={doneImportation}
      >
        <MenuItem value={'-'}>----</MenuItem>
        <MenuItem value={'services'}>Servicios</MenuItem>
        <MenuItem value={'patients'}>Pacientes</MenuItem>
      </Select>

      {valueSelect === 'patients' && (
        <Select
          value={dateFormat}
          onChange={(e) => {
            setDateFormat(e.target.value)
            setCsvValues([])
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          variant="outlined"
          className={s.selectView}
          disabled={doneImportation}
        >
          {dateValidFormats.map((elem, i) => (
            <MenuItem key={i} value={elem}>
              {elem}
            </MenuItem>
          ))}
        </Select>
      )}
    </div>
  )
}

export default SelectComponent
