import React from 'react'
import { useSelector } from 'react-redux'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import FormPatientTreatment from './formPatientTreatment/formPatientTreatment/index.jsx'
import HeaderPatientTreatment from './headerPatientTreatment/index.jsx'

import s from './styles.module.scss'

const PatientTreatmentItem = ({ setOpenPopUp, setTreatmentSection, setLoading, handleClose }) => {
  const patientTreatments = useSelector((state) => state.treatments?.patientTreatments)
  const treatments = [...patientTreatments]

  treatments?.sort(function (a, b) {
    if (a.creationDate > b.creationDate) {
      return -1
    }
    if (a.creationDate < b.creationDate) {
      return 1
    }
    return 0
  })

  return (
    <>
      {treatments?.map((treatment) => (
        <Accordion className={s.accordionContainer} key={treatment._id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={s.itemColor}
          >
            <HeaderPatientTreatment treatment={treatment} />
          </AccordionSummary>
          <AccordionDetails>
            <FormPatientTreatment
              formValues={treatment}
              setOpenPopUp={setOpenPopUp}
              setTreatmentSection={setTreatmentSection}
              setLoading={setLoading}
              handleClose={handleClose}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}

export default PatientTreatmentItem
