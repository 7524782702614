import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'secretaryCentre',
  initialState: {
    allSecretaries: [],
    paginationData: {
      page: 1,
      limit: 30,
    },
    secretaryCentreSelected: null,
  },
  reducers: {
    FETCH_ALL_SECRETARIES: (state, { payload }) => {
      let sortSecretaries = payload.sort((a, b) => (a.lastname.toLowerCase() > b.lastname.toLowerCase() ? 1 : -1))
      state.allSecretaries = sortSecretaries
    },
    ALL_SECRETARIES_PAGINATION: (state, { payload }) => {
      state.allSecretaries = payload.allSecretaries
      state.paginationData = payload.paginationData
    },
    SECRETARY_CENTRE_UPDATED: (state, { payload }) => {
      state.allSecretaries = state.allSecretaries.map((secretary) => {
        if (secretary._id === payload._id) {
          secretary = payload
        }
        return secretary
      })
      state.secretaryCentreSelected = payload
    },
    SECRETARY_CENTRE_DELETE: (state, { payload }) => {
      state.allSecretaries = state.allSecretaries.filter((secretary) => secretary._id !== payload)
      state.secretaryCentreSelected = null
    },
    SECRETARY_CENTRE_CREATED: (state, { payload }) => {
      let allSecretaries = [...(state.allSecretaries || []), payload]
      let sortSecretaries = allSecretaries.sort((a, b) =>
        a.lastname.toLowerCase() > b.lastname.toLowerCase() ? 1 : -1,
      )
      state.allSecretaries = sortSecretaries
      state.secretaryCentreSelected = payload
    },
    SECRETARY_CENTRE_SELECTED: (state, { payload }) => {
      state.secretaryCentreSelected = payload
    },
    RESET_SECRETARY_CENTRE_SELECTED: (state, { payload }) => {
      state.secretaryCentreSelected = null
    },
  },
})

export const {
  ALL_SECRETARIES_PAGINATION,
  FETCH_ALL_SECRETARIES,
  SECRETARY_CENTRE_UPDATED,
  SECRETARY_CENTRE_DELETE,
  SECRETARY_CENTRE_CREATED,
  SECRETARY_CENTRE_SELECTED,
  RESET_SECRETARY_CENTRE_SELECTED,
} = slice.actions
export default slice.reducer
