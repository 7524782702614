import React, { useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import { CustomField } from '@/components/Form/customFields/CustomField'
import CaluButton from '@/components/styledComponents/CaluButton'
import { removeInternalCentreNote } from '@/store/internalCentreNotes/actions'
import { Grid } from '@mui/material'

import s from '../../styles.module.scss'

export const FormContent = ({ isLoading = false, handleClosePopUp }) => {
  const dispatch = useDispatch()

  const { internalCentreNoteSelected } = useSelector((state) => state.internalCentreNotes)

  const [openAlertDelete, setOpenAlertDelete] = useState(false)

  const titleWatch = useWatch({ name: 'title' })
  const messageWatch = useWatch({ name: 'message' })

  const handleDeleteNote = async () => {
    await dispatch(removeInternalCentreNote(internalCentreNoteSelected?._id))
    handleClosePopUp()
  }

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <Grid container spacing={2} className={s.containerForm}>
        <Grid item xs={12}>
          <CustomField label="Título *" name="title" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <CustomField label="Mensaje *" name="message" fullWidth multiline rows={3} />
        </Grid>
        <Grid item xs={12} className={s.mainContainerButtons}>
          {internalCentreNoteSelected?._id && (
            <>
              <CaluButton
                color="error"
                size="medium"
                loading={isLoading}
                onClick={() => setOpenAlertDelete(!openAlertDelete)}
              >
                Eliminar
              </CaluButton>
              <AlertDialogSlide
                open={openAlertDelete}
                title={'Eliminar nota'}
                setOpen={setOpenAlertDelete}
                textContent={'¿Realmente quiere eliminar esta nota? Esta acción no se podrá restaurar.'}
                textBackButton="Cancelar"
                textConfirmationButton={'Eliminar'}
                onClose={handleDeleteNote}
              />
            </>
          )}
          <CaluButton
            color="primary"
            type="submit"
            size="medium"
            loading={isLoading}
            disabled={!titleWatch || !messageWatch}
          >
            Guardar
          </CaluButton>
        </Grid>
      </Grid>
    </div>
  )
}
