import React from 'react'
import { useDispatch } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { resetSelectedHCFragment } from '@/store/clinicHistoryFragments/actions'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormCreateHCFragment from '../formCreateHCFragment'

const MainPopUp = ({ open, setOpen, selectedFragment, entityId, doctorCentreId, isDoctor }) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(resetSelectedHCFragment())
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        disableEscapeKeyDown={true}
        maxWidth="md"
        open={open}
        fullWidth
        onClose={handleClose}
        scroll="paper"
        disableEnforceFocus
      >
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={
              selectedFragment
                ? 'Modificar Fragmento para ficha de historia clínica'
                : 'Fragmento para ficha de historia clínica'
            }
            handleClose={handleClose}
          />
        </DialogTitle>
        <DialogContent style={{ padding: '0px' }} className="animate__animated animate__fadeIn">
          <FormCreateHCFragment
            onClose={handleClose}
            selectedFragment={selectedFragment}
            entityId={entityId}
            doctorCentreId={doctorCentreId}
            isDoctor={isDoctor}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default MainPopUp
