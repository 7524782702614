import { configureStore } from '@reduxjs/toolkit'

import { combineReducers } from 'redux'
import appReducer from './app'
import bankTransferInfoReducer from './bankTransferInfo'
import cashboxItemReducer from './cashboxItems'
import centreAvailabilityReducer from './centreAvailability'
import centreConfigurationsReducer from './centreConfigurations'
import centreInsurancesReducer from './centreInsurances'
import centreMercadoPagoInfoReducer from './centreMercadoPagoInfo'
import centreProviderReducer from './centreProvider'
import centresReducer from './centres'
import changeViewCalendarReducer from './changeViewCalendar'
import citiesReducer from './cities'
import clinicHistoryReducer from './clinicHistory'
import fieldsHCReducer from './clinicHistoryField'
import clinicHistoryFragmentsReducer from './clinicHistoryFragments'
import templateHCReducer from './clinicHistoryTemplate'
import copyTurnReducer from './copyTurn'
import debts from './debts'
import doctorCentresReducer from './doctorCentres'
import doctorProfileReducer from './doctorProfile'
import fieldTablesReducer from './fieldTables'
import insurancePricesReducer from './insurancePrices'
import insurancesReducer from './insurances'
import internalCentreNotesReducer from './internalCentreNotes'
import machinesReducer from './machines'
import myProfileReducer from './myProfile'
import notificationsReducer from './notifications'
import patientCentresReducer from './patientCentres'
import patientsReducer from './patients'
import patientTagsReducer from './patientTags'
import planPricesReducer from './planPrices'
import prescriptionsReducer from './prescriptions'
import productsReducer from './products'
import roomsReducer from './rooms'
import secretaryCentreReducer from './secretaryCentre'
import servicesReducer from './services'
import shiftsReducer from './shifts'
import specialityReducer from './specialities'
import statisticsReducer from './statistics'
import subscriptionPaymentsReducer from './subscriptionPayments'
import subscriptionPlansReducer from './subscriptionPlans'
import treatmentsReducer from './treatments'
import turnsReducer from './turns'
import usersReducer from './users'
import wappReducer from './wapp'
import wappMessageTemplatesReducer from './wappMessageTemplates'

const allReducers = combineReducers({
  app: appReducer,
  bankTransferInfo: bankTransferInfoReducer,
  cashboxItems: cashboxItemReducer,
  centreAvailability: centreAvailabilityReducer,
  centreConfigurations: centreConfigurationsReducer,
  centreInsurances: centreInsurancesReducer,
  centreMercadoPagoInfo: centreMercadoPagoInfoReducer,
  centreProvider: centreProviderReducer,
  centres: centresReducer,
  changeViewCalendar: changeViewCalendarReducer,
  cities: citiesReducer,
  clinicHistory: clinicHistoryReducer,
  fieldsHC: fieldsHCReducer,
  clinicHistoryFragments: clinicHistoryFragmentsReducer,
  templateHC: templateHCReducer,
  copyTurn: copyTurnReducer,
  debts: debts,
  doctorCentres: doctorCentresReducer,
  doctorProfile: doctorProfileReducer,
  fieldTables: fieldTablesReducer,
  insurancePrices: insurancePricesReducer,
  insurances: insurancesReducer,
  machines: machinesReducer,
  myProfile: myProfileReducer,
  notifications: notificationsReducer,
  patientCentres: patientCentresReducer,
  patients: patientsReducer,
  patientTags: patientTagsReducer,
  planPrices: planPricesReducer,
  prescriptions: prescriptionsReducer,
  products: productsReducer,
  rooms: roomsReducer,
  secretaryCentre: secretaryCentreReducer,
  services: servicesReducer,
  shifts: shiftsReducer,
  speciality: specialityReducer,
  statistics: statisticsReducer,
  subscriptionPayments: subscriptionPaymentsReducer,
  subscriptionPlans: subscriptionPlansReducer,
  treatments: treatmentsReducer,
  turns: turnsReducer,
  users: usersReducer,
  wapp: wappReducer,
  wappMessageTemplates: wappMessageTemplatesReducer,
  internalCentreNotes: internalCentreNotesReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined
  }

  return allReducers(state, action)
}

const preloadedState = {}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()],
  devTools: import.meta.env.NODE_ENV !== 'production',
  preloadedState,
})

export default store
