import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'treatments',
  initialState: {
    allTreatments: [],
    treatmentSelected: null,
    patientTreatments: [],
  },
  reducers: {
    GET_ALL_TREATMENTS: (state, { payload }) => {
      state.allTreatments = payload
    },
    GET_SINGLE_TREATMENT: (state, { payload }) => {
      state.treatmentSelected = payload
    },
    CREAT_NEW_TREATMENT: (state, { payload }) => {
      state.allTreatments = [...(state.allTreatments || []), payload]
    },
    UPDATE_TREATMENT: (state, { payload }) => {
      state.allTreatments = state.allTreatments.map((treatment) => {
        if (treatment._id === payload._id) {
          treatment = payload
        }
        return treatment
      })
      state.treatmentSelected = payload
    },
    DELETE_TREATMENT: (state, { payload }) => {
      state.allTreatments = state.allTreatments.filter((treatment) => treatment._id !== payload)
      state.treatmentSelected = null
    },
    RESET_ALL_TREATMENTS: (state) => {
      state.allTreatments = []
    },
    RESET_SINGLE_TREATMENT: (state) => {
      state.treatmentSelected = null
    },
    GET_PATIENT_TREATMENTS: (state, { payload }) => {
      state.patientTreatments = payload
    },
    NEW_PATIENT_TREATMENT: (state, { payload }) => {
      state.patientTreatments = [...state.patientTreatments, payload]
    },
    DELETE_PATIENT_TREATMENT: (state, { payload }) => {
      state.patientTreatments = state.patientTreatments.filter((treatment) => treatment._id !== payload)
    },
    UPDATE_PATIENT_TREATMENT: (state, { payload }) => {
      state.patientTreatments = state.patientTreatments.map((treatment) => {
        if (treatment._id === payload._id) {
          treatment = payload
        }
        return treatment
      })
    },
  },
})

export const {
  GET_ALL_TREATMENTS,
  GET_SINGLE_TREATMENT,
  RESET_ALL_TREATMENTS,
  RESET_SINGLE_TREATMENT,
  CREAT_NEW_TREATMENT,
  UPDATE_TREATMENT,
  DELETE_TREATMENT,
  GET_PATIENT_TREATMENTS,
  NEW_PATIENT_TREATMENT,
  DELETE_PATIENT_TREATMENT,
  UPDATE_PATIENT_TREATMENT,
} = slice.actions

export default slice.reducer
