import React from 'react'
import { useSelector } from 'react-redux'
import Noentities from '@/assets/images/no-agendas.png'
import { useUser } from '@/context/UserProvider'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'

import s from './styles.module.scss'

const CentreBlockPopUp = () => {
  const { user } = useUser()

  const { blockedCentre } = useSelector((state) => state.centres)

  const isAdmin = user?.roles?.includes('admin')

  const soporteNum = () => {
    return (
      <a href="https://wa.me/5492216175785" rel="noreferrer" target="_blank">
        <WhatsAppIcon className={s.fontIcon} /> Escribí al Whatsapp
      </a>
    )
  }

  if (isAdmin || !user) return <></>

  return (
    <Dialog
      disableEscapeKeyDown={true}
      open={true}
      maxWidth="sm"
      classes={{ paper: s.paperProps, root: 'centre-block-pop-up' }}
    >
      <DialogTitle className={s.title}>
        {blockedCentre?.info === 'demo' ? 'Tu prueba gratuita ha finalizado' : 'Tu suscripción se encuentra vencida'}
      </DialogTitle>
      {blockedCentre?.info === 'demo' ? (
        <DialogContent className={s.mainContainer}>
          <div className={s.mainP}>
            <p>La prueba gratuita lamentablemente llegó a su fin, pero desde Calu queremos que sigas con nosotros</p>
          </div>
          <div className={s.containerImg}>
            <img className={s.img} src={Noentities} alt="empty" />
          </div>
          <div className={s.mainP}>
            <p>
              Si bien tu cuenta se bloqueó temporalmente, te invitamos a extender tu suscripción contactándote con
              nosotros <br />{' '}
            </p>
            {soporteNum()}
          </div>
        </DialogContent>
      ) : (
        <DialogContent className={s.mainContainer}>
          <div className={s.mainP}>
            <p>Tu periodo de suscripción llegó a su fin, pero desde Calu queremos que sigas con nosotros</p>
          </div>
          <div className={s.containerImg}>
            <img className={s.img} src={Noentities} alt="empty" />
          </div>
          <div className={s.mainP}>
            <p>
              Si bien tu cuenta se bloqueó, te invitamos a reactivar tu suscripción contactándote con nosotros
              <br />{' '}
            </p>
            {soporteNum()}
          </div>
        </DialogContent>
      )}
    </Dialog>
  )
}

export default CentreBlockPopUp
