import React, { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AutoCompleteField } from '@/components/Form/customFields/AutoCompleteField'
import { CustomField } from '@/components/Form/customFields/CustomField'
import LoaderCircle from '@/components/LoaderCircle'
import CaluButton from '@/components/styledComponents/CaluButton'
import { getAllTemplatesHCField } from '@/controllers/clinicHistoryField'
import { getListFieldsHC } from '@/store/clinicHistoryField/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { Grid } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = ({ submitLoading = false }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const [isLoading, setIsLoading] = useState(false)
  const watchExtraFields = useWatch({ name: 'extraFields' })

  const { allFieldsHC } = useSelector((state) => state.fieldsHC)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const setInit = async () => {
    setIsLoading(true)
    const AllTemplatesHCFields = await getAllTemplatesHCField()
    dispatch(getListFieldsHC(AllTemplatesHCFields))

    setIsLoading(false)
  }

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <LoaderCircle />
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p className={s.subTitle}>
            Datos médicos del {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}{' '}
            <i>-opcionales-</i>
          </p>
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomField label="Grupo sanguíneo" name="blood" fullWidth multiline maxRows={15} />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomField label="Antecedentes quirúrgicos" name="surgicalHistory" fullWidth multiline maxRows={15} />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomField label="Antecedentes familiares" name="familyHistory" fullWidth multiline maxRows={15} />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomField label="Alergias" name="allergies" fullWidth multiline maxRows={15} />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomField label="Medicaciones crónicas" name="chronicMedications" fullWidth multiline maxRows={15} />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomField label="Padecimientos crónicos" name="chronicDiseases" fullWidth multiline maxRows={15} />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomField label="Hábitos" name="habits" fullWidth multiline maxRows={15} />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomField label="Otras observaciones" name="observations" fullWidth multiline maxRows={15} />
        </Grid>
        <Grid item xs={12}>
          <div className={s.divider}></div>
        </Grid>

        <Grid item xs={12}>
          <AutoCompleteField
            label="Campos extras"
            name="extraFields"
            options={allFieldsHC}
            fullWidth
            disabled={false}
          />
        </Grid>
        {watchExtraFields?.map((extraField) => (
          <Grid key={extraField._id} item xs={12} md={4}>
            <CustomField
              label={`${extraField.name}`}
              name={`extraFieldValues[${extraField._id}]`}
              type="text"
              fullWidth
              multiline
              maxRows={15}
            />
          </Grid>
        ))}

        <Grid item xs={12} className={s.containerButtonsForm}>
          <CaluButton color="primary" type="submit" size="medium" loading={submitLoading} disabled={submitLoading}>
            Guardar
          </CaluButton>
        </Grid>
      </Grid>
    </>
  )
}
