import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'
import { reducePayload } from './reducePayload'
import { removeWrongData } from './removeWrongData'

const fetchDoctorCentres = async (centre) => {
  const route = `api/doctorCentres`
  const params = { centre }
  return API.get(route, { params })
    .then((response) => {
      let auxDoctorCentres = response.data?.map((m) => removeWrongData(m))

      return Promise.resolve(auxDoctorCentres)
    })
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchDoctorCentresByInputSearch = async (params) => {
  const route = `api/doctorCentres`
  return API.get(route, { params })
    .then((response) => {
      let auxData = response.data || {}
      auxData.docs = auxData?.docs?.map((m) => removeWrongData(m))

      return Promise.resolve(auxData)
    })
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchDoctorCentre = async (id) => {
  if (!id) return

  const route = `api/doctorCentres/${id}`
  return API.get(route)
    .then((response) => {
      const auxMachine = removeWrongData(response.data)

      return Promise.resolve(auxMachine)
    })
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewDoctorCentres = async (doctorCentre) => {
  const auxDoctorCentre = reducePayload(doctorCentre)

  const route = `/api/doctorCentres`
  return API.post(route, auxDoctorCentre)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putDoctorCentres = async (doctorCentre) => {
  const auxDoctorCentre = reducePayload(doctorCentre)

  const route = `/api/doctorCentres`
  return API.put(route, auxDoctorCentre)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteDoctorCentres = async (id) => {
  const route = `/api/doctorCentres/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export {
  addNewDoctorCentres,
  deleteDoctorCentres,
  fetchDoctorCentre,
  fetchDoctorCentres,
  fetchDoctorCentresByInputSearch,
  putDoctorCentres,
}
