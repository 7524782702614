import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'

import dayjs from 'dayjs'
import HeaderSearchBarTreatmentsDebts from './components/headerSearchBarTreatmentsDebts'
import TableTreatmentsDebts from './components/tableTreatmentsDebts'

import s from '../../styles.module.scss'

const TreatmentsDebtsTab = ({ directionOfAnimation }) => {
  const [inputSearch, setInputSearch] = useState('')
  const [loadingPatients, setLoadingPatients] = useState(false)
  const [patientValue, setPatientValue] = useState(null)
  const [startDate, setStartDate] = useState(dayjs(new Date()).startOf('week').format())
  const [finishDate, setFinishDate] = useState(dayjs(new Date()).endOf('week').format())

  const { treatmentsDebts, isLoading } = useSelector((state) => state.debts)

  return (
    <div className={directionOfAnimation}>
      <HeaderSearchBarTreatmentsDebts
        inputSearch={inputSearch}
        setInputSearch={setInputSearch}
        loadingPatients={loadingPatients}
        setLoadingPatients={setLoadingPatients}
        patientValue={patientValue}
        setPatientValue={setPatientValue}
        startDate={startDate}
        setStartDate={setStartDate}
        finishDate={finishDate}
        setFinishDate={setFinishDate}
      />
      {isLoading ? (
        <LoaderCircle />
      ) : treatmentsDebts?.length === 0 ? (
        <p className={s.noItems}>No hay items para las fechas solicitadas</p>
      ) : (
        <div>
          <TableTreatmentsDebts patientValue={patientValue} startDate={startDate} finishDate={finishDate} />
        </div>
      )}
    </div>
  )
}

export default TreatmentsDebtsTab
