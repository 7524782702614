// @ts-nocheck
import mixpanel from 'mixpanel-browser'

const mixpanelToken = import.meta.env.REACT_APP_MIXPANEL_TOKEN

if (mixpanelToken) {
  mixpanel.init(mixpanelToken)
}

export const mixpanelInstance = {
  identify: (uniqueId) => {
    if (!mixpanelToken) return
    mixpanel.identify(uniqueId)
  },
  track: (event, properties) => {
    if (!mixpanelToken) return
    mixpanel.track(event, properties)
  },
  // alias: (newId, existingId) => {
  //   if (!mixpanelToken) return
  //   mixpanel.alias(newId, existingId)
  // },
  // people: {
  //   set: (props) => {
  //     if (!mixpanelToken) return
  //     mixpanel.people.set(props)
  //   },
  // },
  events: {
    navbar: {
      logoClicked: 'navbar.logo_clicked',
      agendaClicked: 'navbar.agenda_clicked',
      patientsClicked: 'navbar.patients_clicked',
      cashboxClicked: 'navbar.cashbox_clicked',
      profileClicked: 'navbar.profile_clicked',
      myTurnsClicked: 'navbar.my_turns_clicked',
      loginClicked: 'navbar.login_clicked',
      configAgendasClicked: 'navbar.config_agendas_clicked',
      configUsersClicked: 'navbar.config_users_clicked',
      configServicesClicked: 'navbar.config_services_clicked',
      configPricesClicked: 'navbar.config_prices_clicked',
      configTreatmentsClicked: 'navbar.config_treatments_clicked',
      configHCTemplatesClicked: 'navbar.config_hc_templates_clicked',
      configFragmentsClicked: 'navbar.config_fragments_clicked',
      configGeneralClicked: 'navbar.config_general_clicked',
      roleSelected: 'navbar.role_selected',
      notificationsClicked: 'navbar.notifications_clicked',
      notificationsRead: 'navbar.notifications_read',
      supportClicked: 'navbar.support_clicked',
      faqsClicked: 'navbar.faqs_clicked',
      changePasswordClicked: 'navbar.change_password_clicked',
      logoutClicked: 'navbar.logout_clicked',
      logoutConfirmed: 'navbar.logout_confirmed',
    },
    agenda: {
      newAgenda: {
        clicked: 'agenda.new_agenda.clicked',
        continue: 'agenda.new_agenda.continue',
        created: 'agenda.new_agenda.created',
      },
      todayButton: {
        clicked: 'agenda.today_button.clicked',
      },
      newTurn: {
        open: 'agenda.new_turn.open',
        created: 'agenda.new_turn.created',
        turnPasted: 'agenda.new_turn.turn_pasted',
      },
      editTurn: {
        open: 'agenda.edit_turn.open',
        update: 'agenda.edit_turn.updated',
      },
      noDateTurn: {
        open: 'agenda.new_no_date_turn.open',
        created: 'agenda.new_no_date_turn.created',
      },
      modifyAgenda: {
        open: 'agenda.modify_agenda.open',
        update: 'agenda.modify_agenda.updated',
      },
      filter: {
        changed: 'agenda.agenda_filter.changed',
      },
      viewSelector: {
        changed: 'agenda.view_selector.changed',
      },
      turnsOptions: {
        print: 'agenda.turns_options.print',
        openDownloadTurns: 'agenda.turns_options.download.open',
        doneDownloadTurns: 'agenda.turns_options.download.done',
        doneDownloadReminders: 'agenda.turns_options.download.reminders.done',
      },
      newPatient: {
        open: 'agenda.new_patient.open',
        done: 'agenda.new_patient.done',
      },
      search: {
        treatmentOpen: 'agenda.search_treatment.open',
        turnOpen: 'agenda.search_turn.open',
      },
      centreInfo: {
        open: 'agenda.centre_info.open',
        noteUpdated: 'agenda.centre_info.note_updated',
      },
      turnEvent: {
        whatsappClicked: 'agenda.turn_event.whatsapp_clicked',
        statusChanged: 'agenda.turn_event.status_changed',
        rescheduleOpen: 'agenda.turn_event.reschedule_open',
        rescheduleDone: 'agenda.turn_event.reschedule_done',
        copied: 'agenda.turn_event.copied',
      },
    },
    centreLanding: {
      filters: {
        reseted: 'centre_landing.filters_reseted',
        serviceSelected: 'centre_landing.filters.service_selected',
        specialitySelected: 'centre_landing.filters.speciality_selected',
        insuranceSelected: 'centre_landing.filters.insurance_selected',
        agendaSelected: 'centre_landing.filters.agenda_selected',
      },
      extraServices: {
        open: 'centre_landing.extra_services.open',
        confirmed: 'centre_landing.extra_services.confirmed',
      },
      agenda: {
        availabilityRequested: 'centre_landing.agenda.availability_requested',
        dateSelected: 'centre_landing.agenda.date_selected',
      },
      steps: {
        loginDone: 'centre_landing.login_step.done',
        registerDone: 'centre_landing.register_step.done',
        patientDataDone: 'centre_landing.patient_data_step.done',
        turnDataDone: 'centre_landing.turn_data_step.done',
        turnConfirmDone: 'centre_landing.turn_confirm_step.done',
      },
    },
  },
}
