import { fetchFieldTables } from '@/services/fieldTables'

export const setAllFieldTablesController = async (params) => {
  try {
    const fetchAllFieldTables = await fetchFieldTables(params)
    return fetchAllFieldTables
  } catch (error) {
    console.log(error.message)
    return error
  }
}
