import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout1 from '@/layouts/Layout1'
import { getAllPlanPrices, isLoadedPlanPrices } from '@/store/planPrices/actions'
import { getAllSubscriptionPlans } from '@/store/subscriptionPlans/actions'
import { CircularProgress } from '@mui/material'

import PlanPricesSearchBar from './components/planPricesSearchBar'
import PlanPricesTable from './components/planPricesTable'
import PopUpPlanPrices from './components/popUpPlanPrices'

import s from './styles.module.scss'

const AdminPlanPricesPage = () => {
  const dispatch = useDispatch()

  const [openPopUp, setOpenPopUp] = useState(false)

  const { allPlanPrices, isLoaded, selectedPlan } = useSelector((state) => state?.planPrices)

  const setInit = async () => {
    dispatch(isLoadedPlanPrices(true))
    await dispatch(getAllPlanPrices())
    await dispatch(getAllSubscriptionPlans())

    dispatch(isLoadedPlanPrices(false))
  }

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClosePopUp = () => {
    setOpenPopUp(!openPopUp)
  }

  return (
    <Layout1 navbar>
      <div className={`${s.animatedContainer} animate__animated animate__fadeIn`}>
        <PlanPricesSearchBar setOpen={setOpenPopUp} />
        {isLoaded ? (
          <div className={s.loader}>
            <CircularProgress size={50} color="inherit" />
          </div>
        ) : allPlanPrices?.length === 0 ? (
          <p className={s.noItems}>No hay planes cargados aún</p>
        ) : (
          <PlanPricesTable setOpen={setOpenPopUp} />
        )}
      </div>

      {openPopUp && <PopUpPlanPrices open={openPopUp} planSelected={selectedPlan} onClose={handleClosePopUp} />}
    </Layout1>
  )
}

export default AdminPlanPricesPage
