import React, { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AddPaymentsModule from '@/components/AddPaymentsModule'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { DateField } from '@/components/Form/customFields/DateField'
import { SelectField } from '@/components/Form/customFields/SelectField'
import PatientBrowserField from '@/components/Form/patientBrowserField'
import InvoiceTurn from '@/components/InvoiceTurn'
import { deleteCashboxItemAdded } from '@/store/cashboxItems/actions'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { LoadingButton } from '@mui/lab'
import { Button, Grid, MenuItem } from '@mui/material'

import TreatmentInfo from './TreatmentInfo'

import s from '../styles.module.scss'

export const FormContent = ({
  isLoading = false,
  onClose,
  formValues,
  auxPaymentHistory,
  setAuxPaymentHistory,
  isAddingExtraAmount,
  setIsAddingExtraAmount,
  amount,
  setAmount,
  debt,
  setDebt,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const [error, setError] = useState(true)
  const [openAlertDelete, setOpenAlertDelete] = useState(false)
  const [disabledAddPaymentBtn, setDisabledAddPaymentBtn] = useState(true)
  const [auxTotalPayments, setAuxTotalPayments] = useState(0)
  const { allCentreProviders } = useSelector((state) => state.centreProvider)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()
  const typeAllWatch = useWatch({ name: 'type' })
  const amountWatch = useWatch({ name: 'amount' })
  const descriptionWatch = useWatch({ name: 'description' })

  const itemToDelete = formValues?.category === 'other' ? 'entrada' : `${t('turnLabel.s')}`

  useEffect(() => {
    if (formValues?.paymentHistory) setAuxPaymentHistory(formValues?.paymentHistory)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeleteCentreItem = () => {
    dispatch(deleteCashboxItemAdded(formValues))
    onClose()
  }

  const updateTotalPayments = (auxPaymentHistory) => {
    let sum = auxPaymentHistory?.reduce((accum, obj) => {
      return accum + obj?.amount
    }, 0)
    return sum
  }

  useEffect(() => {
    if (amountWatch !== '' && descriptionWatch !== '') {
      setDisabledAddPaymentBtn(false)
      setError(false)
      setAmount(Number(amountWatch))
      setDebt(Number(amountWatch) - Number(auxTotalPayments))
      return
    }

    if (formValues !== null && formValues?.state && amountWatch !== '') {
      setDisabledAddPaymentBtn(false)
      setError(false)
      return
    }

    if (amountWatch === '' || descriptionWatch === '') {
      setDisabledAddPaymentBtn(true)
      setError(true)
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountWatch, descriptionWatch])

  useEffect(() => {
    let totalPayments = updateTotalPayments(auxPaymentHistory)
    setAuxTotalPayments(totalPayments)
  }, [auxPaymentHistory])

  return (
    <>
      {formValues?.docType === 'treatment' ? (
        <TreatmentInfo formInfo={formValues} isTreatment={true} />
      ) : (
        <Grid container spacing={2} className={s.containerForm}>
          {formValues === null || formValues?.docType === 'cashboxItem' ? (
            <>
              <Grid item xs={12} md={4}>
                <SelectField label="Tipo" name="type" fullWidth disabled={formValues !== null ? true : false}>
                  <MenuItem value={'income'}>INGRESO</MenuItem>
                  <MenuItem value={'outcome'}>EGRESO - COSTO FIJO</MenuItem>
                  <MenuItem value={'outcome-variable-cost'}>EGRESO - COSTO VARIABLE</MenuItem>
                  <MenuItem value={'outcome-tax'}>EGRESO - IMPUESTO</MenuItem>
                </SelectField>
              </Grid>
              <Grid item xs={12} md={4}>
                <SelectField label="Categoría" name="category" fullWidth>
                  <MenuItem className={s.selectItem} value={'other'}>
                    OTROS
                  </MenuItem>
                </SelectField>
              </Grid>

              {typeAllWatch === 'income' ? (
                <Grid item xs={12} md={4}>
                  <PatientBrowserField name={'patientCentre'} />
                </Grid>
              ) : (
                <Grid item xs={12} md={4}>
                  <SelectField label="Proveedor" name="centreProvider[_id]" fullWidth>
                    {allCentreProviders?.map((elem) => (
                      <MenuItem key={elem._id} className={s.selectItem} value={elem._id}>
                        {elem.name} {elem?.cuit !== '' && `; CUIT: ${elem?.cuit}`}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
              )}

              <Grid item xs={12}>
                <CustomField label="Descripción *" name="description" fullWidth />
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} md={5} className={s.datePicker}>
                  <DateField
                    label="Fecha"
                    name="date"
                    variant="standard"
                    fullWidth
                    margin={false}
                    className={s.inputWidth}
                  />
                </Grid>
                <Grid item xs={12} md={5} className={s.containerAmount}>
                  <CustomField
                    label={`${handleSetMoneySign(country)} Monto a pagar *`}
                    name="amount"
                    fullWidth
                    type="number"
                    className={s.inputWidth}
                  />
                </Grid>
                <Grid item xs={12} md={2} className={s.debtsItem}>
                  <span className={s.propName}>
                    Deuda:{' '}
                    <b>
                      {handleSetMoneySign(country)} {amountWatch - auxTotalPayments}
                    </b>
                  </span>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InvoiceTurn />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <TreatmentInfo
                formInfo={formValues}
                isTreatment={false}
                auxTotalPayments={auxTotalPayments}
                amount={amount}
                debt={debt}
                setIsAddingExtraAmount={setIsAddingExtraAmount}
                setAmount={setAmount}
                setDebt={setDebt}
              />
            </Grid>
          )}

          <div className={s.divider}></div>

          <Grid item xs={12}>
            <AddPaymentsModule
              paymentHistory={auxPaymentHistory}
              setPaymentHistory={setAuxPaymentHistory}
              disabled={disabledAddPaymentBtn}
              setDebt={setDebt}
            />
          </Grid>

          <Grid item xs={12} className={s.containerButtonsForm}>
            {formValues !== null && (
              <>
                <Button
                  className={s.deleteButton}
                  variant="contained"
                  disableElevation
                  onClick={() => setOpenAlertDelete(true)}
                >
                  {formValues?.category === 'other' ? 'Eliminar' : `Cancelada por error al cargar`}
                </Button>
                <AlertDialogSlide
                  open={openAlertDelete}
                  setOpen={setOpenAlertDelete}
                  title="Eliminar entrada"
                  textContent={`¿Realmente quiere eliminar esta ${itemToDelete}? Esta acción no se podrá restaurar.`}
                  textConfirmationButton={'Eliminar'}
                  onClose={handleDeleteCentreItem}
                />
              </>
            )}
            <LoadingButton
              type="submit"
              className={s.buttonSave}
              variant="contained"
              disableElevation
              loading={isLoading}
              disabled={error}
            >
              Guardar
            </LoadingButton>
          </Grid>
        </Grid>
      )}
    </>
  )
}
