import React, { useEffect, useState } from 'react'
import { useController, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import AttachedImageField from '@/components/Form/customFields/AttachedImageField'
import { AutoCompleteField } from '@/components/Form/customFields/AutoCompleteField'
import { CheckboxField } from '@/components/Form/customFields/CheckboxField'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { DateField } from '@/components/Form/customFields/DateField'
import { TextEditorField } from '@/components/Form/customFields/TextEditorField'
import LoaderCircle from '@/components/LoaderCircle'
import LoadingSaveButton from '@/components/LoadingSaveButton'
import Odontogram from '@/components/Odontogram'
import { useUploadFile } from '@/hooks/useUploadFile'
import { deleteClinicHistoryAdded } from '@/store/clinicHistory/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { Button, Grid, TextField } from '@mui/material'

import downloadHC from '../../../../downloadHC'
import PopUpSharedHC from '../../../../popUpSharedHC'
import FragmentsDropDown from '../fragmentsDropDown'
import AutoSave from './Autosave'

import s from '../../../styles.module.scss'

export const FormContent = ({
  isLoadingOriginalButton,
  isLoadingDraftButton,
  detailHC,
  attached,
  setAttached,
  isNewTurn,
  index,
  canvasBase64,
  setCanvasBase64,
  onSubmit,
  defaultValues,
  allowEditHC,
  maxAttachedFiles,
}) => {
  const dispatch = useDispatch()
  const { uploadFile, loadingFile } = useUploadFile()
  const { t } = useTranslation('global')
  const checkBoxColor = '#f8ac59'

  const [openAlertDelete, setOpenAlertDelete] = useState(false)
  const [openSharedPopUp, setOpenSharedPopUp] = useState(false)
  const [showFragmentInput, setShowFragmentInput] = useState(false)

  const { totalDoctorCentres } = useSelector((state) => state.doctorCentres)
  const { selectedTurn } = useSelector((state) => state.turns)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const patientSelected = useSelector((state) => state.patientCentres?.patientSelected)
  const { allClinicHistories } = useSelector((state) => state?.clinicHistory)

  const shareWithAllWatch = useWatch({ name: 'shareWithAll' })
  const sharedWatch = useWatch({ name: 'shared' })

  const { field: fieldShared } = useController({ name: 'shared' })
  const { field: fieldShareWithAll } = useController({ name: 'shareWithAll' })
  const { field: fieldIsPinned } = useController({ name: 'isPinned' })
  const { field: fieldAttached } = useController({ name: 'attached' })

  useEffect(() => {
    fieldShared.onChange(detailHC.shared)
    fieldShareWithAll.onChange(detailHC.shareWithAll)
    fieldIsPinned.onChange(detailHC.isPinned)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailHC])

  useEffect(() => {
    fieldAttached.onChange(attached)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attached])

  if (loadingFile) {
    return <LoaderCircle />
  }

  const handleDeleteAttached = (i) => {
    let filteredAttached = attached.filter((_, index) => index !== i)
    setAttached(filteredAttached)
  }

  const validateTypeOfField = (field) => {
    if (field.type === 'date' || field.type === 'number') return field.value

    if (
      (field.type === 'textarea' || field.type === 'text' || !field.type) &&
      field.value &&
      !(field.name === 'Odontograma' || field.name === 'Odontograma - adultos/niños')
    )
      return (
        <TextField
          value={field.value}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
          fullWidth
          multiline
        />
      )

    if (field.value && (field.name === 'Odontograma' || field.name === 'Odontograma - adultos/niños'))
      return (
        <div>
          <img className={s.odontogramaImg} src={field.value} alt="odontograma" />
        </div>
      )
  }

  const handleSharedButton = () => {
    return (
      <Grid item xs={12} md={1.5}>
        <Button className={s.sharedBtn} onClick={() => setOpenSharedPopUp(true)}>
          Compartir
        </Button>
        <PopUpSharedHC
          openPopUp={openSharedPopUp}
          setOpenPopUp={setOpenSharedPopUp}
          doctorsToShareHC={sharedWatch}
          shareWithAll={shareWithAllWatch}
          singleClinicHistory={detailHC}
          globalShared={false}
          allClinicHistories={allClinicHistories}
        />
      </Grid>
    )
  }

  const handleDownloadHC = async () => {
    let centreSignImage = centreDetail?.signImage
    let centreLogoImg = centreDetail?.logo

    downloadHC(detailHC, selectedTurn ? selectedTurn?.patientCentre : patientSelected, centreLogoImg, centreSignImage)
  }

  const insuranceName = detailHC?.isDraft
    ? selectedTurn?.patientCentre?.insurances?.[0]?.name || patientSelected?.insurances?.[0]?.name
    : detailHC?.insurance?.name

  const policyNuymber = detailHC?.isDraft
    ? selectedTurn?.patientCentre?.policyNumbers?.[0] || patientSelected?.policyNumbers?.[0]
    : detailHC?.insurance?.policyNumber

  return (
    <>
      {insuranceName && (
        <div className={s.containerInsurance}>
          <p>
            <span className={s.keyInsurance}>{`${t('insuranceLabel.S')}: `}</span>
            <span>{insuranceName}</span>
            {policyNuymber && <span>: {policyNuymber}</span>}
          </p>
        </div>
      )}
      <small className={s.identifier}>{`IDENTIFICADOR ${detailHC?._id.slice(-8)}`}</small>
      <Grid container spacing={2} className={s.containerInfoHC}>
        <Grid item xs={4}>
          {detailHC?.isDraft && !isNewTurn ? (
            <div className={s.dateVisit}>
              <DateField label="" name="visit" fullWidth />
            </div>
          ) : (
            detailHC?.visit && (
              <span className={s.propName}>
                <b>Fecha de visita:</b> {toCustomTz(detailHC?.visit, undefined, true, 'DD/MM/YYYY')}
              </span>
            )
          )}
        </Grid>
        <Grid item xs={8}>
          {detailHC?.isDraft && !isNewTurn ? (
            <CustomField label="Asunto" name="reason" fullWidth />
          ) : (
            detailHC?.reason && (
              <span className={s.propName}>
                <b>Asunto: </b> {detailHC?.reason}
              </span>
            )
          )}
        </Grid>

        {detailHC?.clinicHistoryFields?.map((field, i) => (
          <Grid key={field._id} item xs={12}>
            {!detailHC?.isDraft ? (
              field.value ? (
                <span className={s.propName}>
                  <b>{field.name}: </b> {validateTypeOfField(field)}
                </span>
              ) : (
                <></>
              )
            ) : field.name === 'Odontograma' || field.name === 'Odontograma - adultos/niños' ? (
              !field.value ? (
                <div>
                  {
                    <Odontogram
                      field={field}
                      canvasBase64={canvasBase64}
                      setCanvasBase64={setCanvasBase64}
                      name={`canvas[${index}][${i}]`}
                    />
                  }
                </div>
              ) : (
                <span className={s.propName}>
                  <b>{field.name}: </b> {validateTypeOfField(field)}
                </span>
              )
            ) : (
              !isNewTurn && (
                <CustomField
                  label={`${field.name}`}
                  name={`clinicHistoryFields[${i}].value`} //Así llega del back
                  type={field.type}
                  fullWidth
                  multiline={field.type === 'textarea' || field.type === 'text' || !field.type ? true : false}
                  maxRows={15}
                />
              )
            )}
          </Grid>
        ))}

        <Grid item xs={12}>
          {detailHC?.isDraft && !isNewTurn ? (
            <>
              <p className={s.subtitle}>Observaciones</p>
              <TextEditorField name={'observations'} />
            </>
          ) : (
            detailHC?.observations && (
              <div className={s.observationsContainer}>
                <p className={s.subtitle}>Observaciones</p>

                <div
                  id="observations"
                  className={s.observations}
                  dangerouslySetInnerHTML={{ __html: detailHC?.observations }}
                />
              </div>
            )
          )}
        </Grid>
        <Grid item xs={12} container className={s.containerAttachedImage}>
          {attached?.map((file, index) => {
            return (
              <Grid key={index} item xs={12} className={s.attachedItem}>
                <AttachedImageField
                  name={`attached[${index}]`}
                  file={file}
                  onChange={handleDeleteAttached}
                  index={index}
                  hideDeleteButton={!detailHC?.isDraft}
                />
              </Grid>
            )
          })}
        </Grid>
        <div className={s.divider}></div>
        {detailHC?.isOwner && !isNewTurn && (
          <>
            <Grid item xs={12} className={s.subtitle}>
              Compartir esta ficha
            </Grid>

            <Grid item md={8} xs={12}>
              <AutoCompleteField
                label="Compartir ficha con otros profesionales"
                name="shared"
                options={totalDoctorCentres}
                fullWidth
                disabled={shareWithAllWatch}
              />
            </Grid>

            <Grid item md={4} xs={12} display="flex" justifyContent="center">
              <CheckboxField label="Marcar todos" name="shareWithAll" checkBoxColor={checkBoxColor} />
            </Grid>
          </>
        )}
        {!isNewTurn ? (
          detailHC?.isDraft ? (
            <>
              <Grid container spacing={1} className={s.mainContainerBtns}>
                <Grid item xs={12} md={2.5}>
                  <LoadingSaveButton
                    isLoading={isLoadingDraftButton}
                    textButton={'Guardar borrador'}
                    className={s.save}
                    type="submit"
                    name="borrador"
                    variant="contained"
                    loadingContainer={s.setButtons}
                    disabled={!allowEditHC}
                  />
                </Grid>
                <Grid item xs={12} md={2.5}>
                  <LoadingSaveButton
                    isLoading={isLoadingOriginalButton}
                    textButton={'Guardar original'}
                    className={s.saveOriginal}
                    type="submit"
                    name="original"
                    variant="contained"
                    loadingContainer={s.setButtons}
                    disabled={!allowEditHC}
                  />
                </Grid>
                {detailHC?.isOwner && handleSharedButton()}
                <Grid item xs={12} md={1.5}>
                  <Button
                    className={!allowEditHC ? s.disabledButton : s.delete}
                    onClick={() => setOpenAlertDelete(!openAlertDelete)}
                    disabled={!allowEditHC}
                  >
                    Eliminar
                  </Button>
                  <AlertDialogSlide
                    title="Eliminar borrador"
                    open={openAlertDelete}
                    setOpen={setOpenAlertDelete}
                    textContent={'Realmente quiere eliminar el borrador? No se podrá restaurar'}
                    textBackButton="Cancelar"
                    textConfirmationButton={'Eliminar'}
                    onClose={() => dispatch(deleteClinicHistoryAdded(detailHC?._id))}
                  />
                </Grid>
                <Grid item xs={12} md={1.5}>
                  <Button
                    className={!allowEditHC || attached?.length >= maxAttachedFiles ? s.disabledButton : s.attached}
                    onClick={() => document.getElementById(`uploadAttached${detailHC?._id}`).click()}
                    disabled={!allowEditHC || attached?.length >= maxAttachedFiles}
                  >
                    <input
                      type="file"
                      id={`uploadAttached${detailHC?._id}`}
                      hidden
                      onChange={(e) => uploadFile(e, attached, setAttached)}
                      multiple
                    ></input>
                    Adjuntar
                  </Button>
                </Grid>

                <Grid item xs={12} md={2}>
                  <Button
                    className={!allowEditHC ? s.disabledButton : s.attached}
                    onClick={() => setShowFragmentInput(!showFragmentInput)}
                    disabled={!allowEditHC}
                  >
                    Fragmentos
                  </Button>
                </Grid>
              </Grid>

              {showFragmentInput && (
                <Grid item xs={12}>
                  <FragmentsDropDown
                    dataPatient={selectedTurn?.patientCentre || patientSelected}
                    allowEditHC={allowEditHC}
                  />
                </Grid>
              )}
            </>
          ) : (
            <Grid container spacing={1} className={s.doneContainerBtns}>
              <Grid item xs={12} md={2}>
                <Button className={s.download} onClick={handleDownloadHC}>
                  Descargar
                </Button>
              </Grid>
              {detailHC?.isOwner && handleSharedButton()}
            </Grid>
          )
        ) : detailHC?.isDraft ? (
          <p className={s.alertMsg}>
            Para descargar, compartir y/o modificar esta ficha debe hacerlo desde {t('turnLabel.sa')} existente o desde
            la sección {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.Patients`)}
          </p>
        ) : (
          <p className={s.alertMsg}>
            Para descargar esta ficha debe hacerlo desde {t('turnLabel.sa')} existente o desde la sección{' '}
            {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.Patients`)}
          </p>
        )}
      </Grid>
      {(selectedTurn?.doctorCentre?.hcAutosave || detailHC?.doctorCentre?.hcAutosave) && (
        <AutoSave defaultValues={defaultValues} onSubmit={onSubmit} />
      )}
    </>
  )
}
