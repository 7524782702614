import React, { useState } from 'react'
import defaultUserImage from '@/assets/images/default.png'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import ImageCircle from '@/components/imageCircle'
import SignatureRecord from '@/components/SignatureRecord/SignatureRecord'
import TitlePopUp from '@/components/TitlePopUp'
import { useDeleteImage } from '@/hooks/useDeleteImage'
import { useUploadImage } from '@/hooks/useUploadImage'
import FormDoctor from '@/pages/AdministracionPage/components/formCreateDoctorCentre.jsx/components/personalInformation'
import { updateDoctorCentres } from '@/store/doctorCentres/actions'
import { Button, DialogContent, DialogTitle, Grid } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import s from './styles.module.scss'

const DetailDoctor = ({ selectedDoctor, openDoctorPopUp, setOpenDoctorPopUp, modifyDoctorProfile }) => {
  const { loadingImage, uploadImage } = useUploadImage()
  const { deleteProfileImage } = useDeleteImage()
  const extraData = { addInMyProfile: true }

  const [openDeleteImage, setOpenDeleteImage] = useState(false)
  const [openSignatureRecord, setOpenSignatureRecord] = useState(false)

  const handleClose = () => {
    setOpenDoctorPopUp(false)
  }

  return (
    <Grid container className={`${s.container} animate__animated animate__fadeIn`}>
      <Grid item container md={8} className={s.leftContainer}>
        <Grid item xs={12}>
          <div className={s.containerImg}>
            <ImageCircle
              image={selectedDoctor?.image}
              defaultImage={defaultUserImage}
              uploadImage={(e) => uploadImage(e, 'image', updateDoctorCentres, selectedDoctor, true)}
              loading={loadingImage}
              className="changeProfileImage"
              id={'doctorImage'}
            />
          </div>
          {selectedDoctor?.image && (
            <small className={s.deleteImageButton} onClick={() => setOpenDeleteImage(true)}>
              Eliminar foto de perfil
            </small>
          )}
          <AlertDialogSlide
            open={openDeleteImage}
            setOpen={setOpenDeleteImage}
            textContent={'¿Confirma que quiere eliminar la imagen?'}
            textBackButton="Cancelar"
            textConfirmationButton={'Confirmar'}
            onClose={() => deleteProfileImage('image', selectedDoctor, updateDoctorCentres, true)}
          />
        </Grid>
        <Grid item xs={12} className={s.title}>
          <p>
            {selectedDoctor?.lastname.toUpperCase()}, {selectedDoctor?.firstname.toUpperCase()}
          </p>
        </Grid>
        <Grid item xs={12} className={s.subTitle}>
          <p>Perfil de profesional en centro de salud:</p>
          <b>{selectedDoctor?.centre?.name}</b>
        </Grid>
      </Grid>

      <Grid item md={4} className={s.rightContainer}>
        <Button onClick={modifyDoctorProfile} fullWidth disableElevation className={s.button}>
          Modificar
        </Button>
        <Button fullWidth disableElevation className={s.button2} onClick={() => setOpenSignatureRecord(true)}>
          Registrar firma
        </Button>
      </Grid>
      <Dialog
        disableEscapeKeyDown={true}
        maxWidth="md"
        fullWidth
        open={openDoctorPopUp}
        onClose={handleClose}
        scroll="paper"
      >
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle="Modificar perfil de doctor" handleClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <FormDoctor handleClose={handleClose} entity={selectedDoctor} addWorkTimes={false} fromMyProfile={true} />
        </DialogContent>
      </Dialog>

      <SignatureRecord
        open={openSignatureRecord}
        setOpen={setOpenSignatureRecord}
        entityState={selectedDoctor}
        updateFunction={updateDoctorCentres}
        extraData={extraData}
      />
    </Grid>
  )
}

export default DetailDoctor
