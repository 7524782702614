import React, { useState } from 'react'
import { useController } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import DividerSection from '@/components/DividerSection'
import CaluFormInput from '@/components/formComponents/customFields/CaluFormInput'
import CaluButton from '@/components/styledComponents/CaluButton'
import TextFieldWithList from '@/components/TextFieldWithList'
import { getCentreDetail } from '@/services/centres'
import { getCentreByInputSearch } from '@/store/centres/actions'
import { getDoctorCentresByInputSearch } from '@/store/doctorCentres/actions'
import { getSecretaryCentreByInputSearch } from '@/store/secretaryCentre/actions'
import { SELECTED_USER } from '@/store/users'
import { deleteUserAdded, updateUser } from '@/store/users/actions'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { showToast } from '@/utils/toast'
import { Grid } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false, onClose, auxFormValues }) => {
  const dispatch = useDispatch()
  const { selectedUser } = useSelector((state) => state.users)
  const [loadingButton, setLoadingButton] = useState(false)
  const [openAlertDelete, setOpenAlertDelete] = useState(false)

  const { field: centresField } = useController({ name: 'centres' })
  const { field: doctorCentresField } = useController({ name: 'doctorCentres' })
  const { field: secretaryCentresField } = useController({ name: 'secretaryCentres' })

  const [searchCentre, setSearchCentre] = useState('')
  const [searchDoctorCentre, setSearchDoctorCentre] = useState('')
  const [searchSecretaryCentre, setSearchSecretaryCentre] = useState('')

  const [userCentres, setUserCentres] = useState([...auxFormValues?.centres])
  const [userDoctorCentres, setUserDoctorCentres] = useState([...auxFormValues?.doctorCentres])
  const [userSecretaryCentres, setUserSecretaryCentres] = useState([...auxFormValues?.secretaryCentres])

  const { allCentres } = useSelector((state) => state.centres)
  const { doctorCentres: allDoctorCentres } = useSelector((state) => state.doctorCentres)
  const { allSecretaries } = useSelector((state) => state.secretaryCentre)

  const errorMessage2 = 'El elemento que intenta guardar ya existe'

  const roles = [
    { name: 'paciente', value: 'patient' },
    { name: 'centro', value: 'centre' },
    { name: 'profesional', value: 'doctor' },
    { name: 'asistente', value: 'secretary' },
    { name: 'moderador', value: 'moderator' },
    { name: 'extensión', value: 'extension' },
  ]

  const deleteUser = () => {
    try {
      dispatch(deleteUserAdded(selectedUser?._id))
      setOpenAlertDelete(false)
      onClose()
    } catch (error) {
      console.log(error)
    }
  }

  const handleBlockedUser = async () => {
    const auxData = {
      ...selectedUser,
      blocked: selectedUser.blocked ? false : true,
    }
    const userId = selectedUser._id
    setLoadingButton(true)
    const user = await dispatch(updateUser(auxData, userId))
    await dispatch(SELECTED_USER(user))
    setLoadingButton(false)
  }

  const checkExistElement = (arrayOfElements, idElem) => {
    const findElement = arrayOfElements?.find((elem) => elem._id === idElem)
    if (findElement) return showToast(errorMessage2, 'warning')
  }

  const addNewItem = async (input) => {
    if (input === 'centre') {
      if (!checkExistElement(userCentres, searchCentre)) {
        const filteredCentre = allCentres?.filter((c) => c._id === searchCentre)[0]
        const auxCentre = {
          _id: filteredCentre._id,
          name: filteredCentre.name,
        }
        setUserCentres([auxCentre, ...userCentres])
        centresField.onChange([auxCentre, ...userCentres])
      }
    }
    if (input === 'doctorCentre') {
      if (!checkExistElement(userDoctorCentres, searchDoctorCentre)) {
        const filteredDoctorCentre = allDoctorCentres?.filter((c) => c._id === searchDoctorCentre)[0]
        const centre = await getCentreDetail(filteredDoctorCentre.centre)

        const auxDoctorCentre = {
          _id: filteredDoctorCentre._id,
          lastname: filteredDoctorCentre.lastname,
          firstname: filteredDoctorCentre.firstname,
          centre: centre,
        }

        setUserDoctorCentres([auxDoctorCentre, ...userDoctorCentres])
        doctorCentresField.onChange([auxDoctorCentre, ...userDoctorCentres])
      }
    }
    if (input === 'secretaryCentre') {
      if (!checkExistElement(userSecretaryCentres, searchSecretaryCentre)) {
        const filteredSecretaryCentre = allSecretaries?.filter((c) => c._id === searchSecretaryCentre)[0]
        const centre = await getCentreDetail(filteredSecretaryCentre.centre)

        const auxSecretaryCentre = {
          _id: filteredSecretaryCentre._id,
          lastname: filteredSecretaryCentre.lastname,
          firstname: filteredSecretaryCentre.firstname,
          centre: centre,
        }
        setUserSecretaryCentres([auxSecretaryCentre, ...userSecretaryCentres])
        secretaryCentresField.onChange([auxSecretaryCentre, ...userSecretaryCentres])
      }
    }
  }

  const deleteCentre = (id) => {
    let deletedCentre = centresField.value?.filter((c) => c._id !== id)
    setUserCentres(deletedCentre)
    centresField.onChange(deletedCentre)
  }

  const deleteDoctorCentre = (id) => {
    let deletedDoctor = doctorCentresField.value?.filter((d) => d._id !== id)
    setUserDoctorCentres(deletedDoctor)
    doctorCentresField.onChange(deletedDoctor)
  }
  const deleteSecretaryCentre = (id) => {
    let deletedSecretary = secretaryCentresField.value?.filter((s) => s._id !== id)
    setUserSecretaryCentres(deletedSecretary)
    secretaryCentresField.onChange(deletedSecretary)
  }

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CaluFormInput labelText="Apellido*" type="text" name="lastname" />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CaluFormInput labelText="Nombre*" type="text" name="firstname" />
        </Grid>

        <Grid item xs={12} sm={12}>
          <CaluFormInput labelText="Email*" type="text" name="email" />
        </Grid>

        <DividerSection label="Gestión de roles" />

        <TextFieldWithList
          inputLabel="Centros"
          listItems={userCentres || []}
          searchText={searchCentre}
          setSearchText={setSearchCentre}
          stateRedux={allCentres}
          deleteItem={deleteCentre}
          addNewItem={() => addNewItem('centre')}
          functionToDispatch={getCentreByInputSearch}
        />
        <TextFieldWithList
          inputLabel="Profesionales"
          listItems={userDoctorCentres || []}
          searchText={searchDoctorCentre}
          setSearchText={setSearchDoctorCentre}
          stateRedux={allDoctorCentres}
          deleteItem={deleteDoctorCentre}
          addNewItem={() => addNewItem('doctorCentre')}
          functionToDispatch={getDoctorCentresByInputSearch}
        />

        <TextFieldWithList
          inputLabel="Asistentes"
          listItems={userSecretaryCentres || []}
          searchText={searchSecretaryCentre}
          setSearchText={setSearchSecretaryCentre}
          stateRedux={allSecretaries}
          addNewItem={() => addNewItem('secretaryCentre')}
          deleteItem={deleteSecretaryCentre}
          functionToDispatch={getSecretaryCentreByInputSearch}
        />

        <Grid item xs={12}>
          <CaluFormInput labelText="Paciente" type="text" name="patient" className={s.inputWidth} />
        </Grid>

        {auxFormValues?.roles?.length > 0 && (
          <Grid item xs={12} className={s.roles}>
            <p>
              <b>Roles activos</b>
            </p>
            <p>
              [{' '}
              {auxFormValues?.roles?.map((rol, i) => (
                <span key={i} className={s.info}>
                  {convertValueToName(roles, rol)} {i < auxFormValues?.roles?.length - 1 ? ', ' : ''}
                </span>
              ))}{' '}
              ]
            </p>
          </Grid>
        )}

        <Grid item xs={12} className={s.containerButtonsForm}>
          <CaluButton
            color="secondary"
            size="small"
            onClick={() => handleBlockedUser()}
            loading={loadingButton}
            disabled={loadingButton}
          >
            {selectedUser?.blocked ? 'Desbloquear acceso' : 'Bloquear acceso'}
          </CaluButton>

          <CaluButton color="error" size="small" onClick={() => setOpenAlertDelete(!openAlertDelete)}>
            Eliminar
          </CaluButton>
          <CaluButton color="primary" size="small" type="submit" loading={isLoading} disabled={isLoading}>
            Guardar
          </CaluButton>
        </Grid>
      </Grid>

      <AlertDialogSlide
        title="Eliminar usuario"
        open={openAlertDelete}
        setOpen={setOpenAlertDelete}
        textContent={'¿Está seguro que quiere eliminar este usuario?'}
        textContentExtraOptional="Esta acción no se podrá deshacer."
        textBackButton="Cancelar"
        textConfirmationButton={'Eliminar'}
        onClose={deleteUser}
      />
    </div>
  )
}
