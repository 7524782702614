import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FormLogin from '@/components/FormLogin'
import LoaderCircle from '@/components/LoaderCircle'
import NavBar from '@/components/NavBar'
import { apiCheck } from '@/services/apiCheck'
import { setBackURLMessage } from '@/store/app/actions'
import { showToast } from '@/utils/toast'

import s from './styles.module.scss'

const LoginPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)
  const [apiResponse, setApiResponse] = useState(null)

  const { backURLMessage } = useSelector((state) => state.app)

  const checkApi = async () => {
    const apiResponse = await apiCheck()
    setApiResponse(apiResponse)
    setIsLoading(false)
  }

  const goToStatusPage = () => {
    navigate('/estado')
  }

  useEffect(() => {
    if (backURLMessage) {
      showToast(backURLMessage, 'success', 8000)
      dispatch(setBackURLMessage(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (apiResponse === null) {
      checkApi()
    }
  }, [apiResponse])

  return (
    <div className={s.loginPage}>
      <NavBar />

      {isLoading ? (
        <LoaderCircle size={50} text="Cargando..." />
      ) : (
        <>{apiResponse ? <FormLogin /> : goToStatusPage()}</>
      )}
    </div>
  )
}

export default LoginPage
