import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import NoAgendas from '@/assets/images/no-agendas.png'
import { useUser } from '@/context/UserProvider'
import DoctorCentresPopUp from '@/pages/AdministracionPage/components/popUp/DoctorCentresPopUp'
import ConsultTurns from '@/pages/AgendaPage/components/OptionsAgenda/ConsultTurns'
import UsefulInformation from '@/pages/AgendaPage/components/OptionsAgenda/usefulInformation'
import { getDoctorCentre } from '@/store/doctorCentres/actions'
import { Button } from '@mui/material'

import s from './styles.module.scss'

const NoCalendarsForToday = () => {
  const { user } = useUser()
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const doctorCentreSelected = useSelector((state) => state.myProfile.doctorSelected)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isDoctor, entityInLocalStorage } = usersProfileConnected

  useEffect(() => {
    if (user.roles.includes('doctor') && !user.roles.includes('centre') && isDoctor)
      dispatch(getDoctorCentre(entityInLocalStorage.doctorCentreId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [openUsefulInformation, setOpenUsefulInformation] = useState(false)
  const [openChangeHours, setOpenChangeHours] = useState(false)
  const [openConsultTurns, setOpenConsultTurns] = useState(false)

  return (
    <div className={s.mainContainer}>
      <div className={s.containerImg}>
        <img className={s.img} src={NoAgendas} alt="empty" />
      </div>
      <p className={s.mainP}>No hay agendas cargadas en el día seleccionado</p>
      <p className={s.secondP}>
        Cambiá a un día que tenga horarios laborales cargados para ver las agendas correspondientes y poder cargar
        turnos.
      </p>
      <div className={s.containerButtons}>
        <div>
          <Button className={s.usefulInfo} onClick={() => setOpenUsefulInformation(true)}>
            Información útil del centro
          </Button>
        </div>
        {user.roles.includes('doctor') && !user.roles.includes('centre') && (
          <div>
            <Button className={s.otherBtns} onClick={() => setOpenChangeHours(true)}>
              Modificar mis datos
            </Button>
          </div>
        )}
        <div>
          <Button className={s.otherBtns} onClick={() => setOpenConsultTurns(true)}>
            {`Consultar ${t('turnLabel.p')}`}
          </Button>
        </div>
      </div>

      {!!openUsefulInformation && (
        <UsefulInformation open={openUsefulInformation} onClose={() => setOpenUsefulInformation(false)} />
      )}
      {!!openChangeHours && (
        <DoctorCentresPopUp
          openDoctorPopUp={openChangeHours}
          setOpenDoctorPopUp={setOpenChangeHours}
          entity={doctorCentreSelected}
        />
      )}
      {!!openConsultTurns && <ConsultTurns open={openConsultTurns} onClose={setOpenConsultTurns} />}
    </div>
  )
}

export default NoCalendarsForToday
