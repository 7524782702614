export const formDefaultValues = {
  patientCentre: {
    dni: '',
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    telephone: '',
    note: '',
    country: 'ARG',
    gender: '',
    brith: '',
    insurancesPlan: [],
    policyNumbers: [],
    blockOnlineTurns: false,
  },
  comment: '',
  doctorCentre: null,
  service: null,
  firstTime: false,
  extraServices: {},
  insurance: null,
  insurancePrice: null,
  invoice: '',
  selectedProduct: null,
  productQuantity: 1,
  extraAmount: {
    description: '',
    type: 'plusCash',
    value: 0,
  },
  paymentMethod: { name: 'Efectivo', value: 'ARScash' },
}
