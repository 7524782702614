import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { deviceDetector } from '@/helpers/deviceDetector'
import { clearPatientCentres, resetSelectedPatientCentre } from '@/store/patientCentres/actions'
import { cleanSelectedTurn } from '@/store/turns/actions'
import { SELECTED_ENTITY } from '@/store/turns/index.js'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import DialogButtonsAction from './DialogContentTurn/tabs/TurnInfoTab/formContent/components/DialogButtonsAction'
import DialogContenTurn from './DialogContentTurn'
import DialogHeader from './DialogHeader'

import s from './styles.module.scss'
const UpdateTurnDialog = ({ open, setOpen }) => {
  let isMobile = deviceDetector()
  const dispatch = useDispatch()
  const buttonFormRef = useRef()

  const [isLoading, setIsLoading] = useState(false)
  const [expandPopUp, setExpandPopUp] = useState(false)
  const [tabValue, setTabValue] = useState('1')
  const [showAllForm, setShowAllForm] = useState(null)

  const handleClose = async () => {
    setOpen(false)
    dispatch(resetSelectedPatientCentre())
    dispatch(clearPatientCentres())
    dispatch(cleanSelectedTurn())
    dispatch(SELECTED_ENTITY(null))
  }

  const activeButtonForm = () => {
    if (buttonFormRef.current) {
      buttonFormRef.current.click()
    }
  }

  return (
    <Dialog
      disableEscapeKeyDown={true}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      scroll="paper"
      disableEnforceFocus
      fullScreen={isMobile || expandPopUp}
    >
      <DialogTitle className={s.dialogTitle}>
        <DialogHeader
          setOpen={setOpen}
          expandPopUp={expandPopUp}
          setExpandPopUp={setExpandPopUp}
          tabValue={tabValue}
          handleClose={handleClose}
        />
      </DialogTitle>
      <DialogContent className={s.dialogTitle}>
        <DialogContenTurn
          buttonFormRef={buttonFormRef}
          tabValue={tabValue}
          setTabValue={setTabValue}
          setOpen={setOpen}
          handleClose={handleClose}
          showAllForm={showAllForm}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </DialogContent>
      <DialogActions className="animate__animated animate__fadeIn">
        <DialogButtonsAction
          activeButtonForm={activeButtonForm}
          tabValue={tabValue}
          showAllForm={showAllForm}
          setShowAllForm={setShowAllForm}
          handleClose={handleClose}
          isLoading={isLoading}
        />
      </DialogActions>
    </Dialog>
  )
}

export default UpdateTurnDialog
