import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent, DialogTitle } from '@mui/material'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import PropTypes from 'prop-types'
import FormUpdateAdmin from '../formUpdateAdmin'
import InfoInternalCentreNotes from '../InfoInternalCentreNotes'
import InfoPayments from '../InfoPayments'
import InfoSubscription from '../InfoSubscription'
import InfoUser from '../infoUser'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const PopUpUpdateAdmin = ({ open, setOpen, loadingDataUser }) => {
  const [valuePanel, setValuePanel] = useState(0)

  const centreAdminDetail = useSelector((state) => state.centres.centreAdminDetail)

  const handleChangePanel = (event, newValue) => {
    setValuePanel(newValue)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} open={open} maxWidth="md" fullWidth onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle={'Editar centro'} handleClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          {loadingDataUser ? (
            <LoaderCircle size={50} />
          ) : (
            <div>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={valuePanel}
                    onChange={handleChangePanel}
                    aria-label="PopUp panel admin"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                      '& .MuiTab-root.Mui-selected': {
                        color: '#009688',
                      },
                      [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                      },
                    }}
                  >
                    <Tab label="Configuración" />
                    <Tab label="Información usuario" />
                    <Tab label="Suscripción" />
                    <Tab label="Pagos" />
                    <Tab label="Notas" />
                  </Tabs>
                </Box>
              </Box>

              <TabPanel value={valuePanel} index={0}>
                <FormUpdateAdmin onClose={handleClose} formValues={centreAdminDetail} />
              </TabPanel>
              <TabPanel value={valuePanel} index={1}>
                <InfoUser />
              </TabPanel>
              <TabPanel value={valuePanel} index={2}>
                <InfoSubscription />
              </TabPanel>
              <TabPanel value={valuePanel} index={3}>
                <InfoPayments />
              </TabPanel>
              <TabPanel value={valuePanel} index={4}>
                <InfoInternalCentreNotes />
              </TabPanel>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpUpdateAdmin
