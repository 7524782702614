const getAmountWithoutExtraDiscount = (amount, extraAmount) => {
  // $1000, $0 --> $1000
  if (!extraAmount?.value) return amount

  let amountWithoutExtraDiscount

  // $1000, +$200 --> $800
  if (extraAmount?.type === 'plusCash') {
    amountWithoutExtraDiscount = amount - extraAmount?.value
  }

  // $1000, -$200 --> $1200
  if (extraAmount?.type === 'minusCash') {
    amountWithoutExtraDiscount = amount + extraAmount?.value
  }

  // $1000, +10% --> $900
  if (extraAmount?.type === 'plusPercentage') {
    amountWithoutExtraDiscount = Math.round(amount * (1 - extraAmount?.value / 100))
  }

  // $1000, -10% --> $1100
  if (extraAmount?.type === 'minusPercentage') {
    amountWithoutExtraDiscount = Math.round(amount * (1 - extraAmount?.value / 100))
  }

  return amountWithoutExtraDiscount
}

export { getAmountWithoutExtraDiscount }
