import { fetchTemplatesHC } from '@/services/clinicHistoryTemplate'

export const setAllTemplatesHCController = async (params) => {
  try {
    const fetchAllTemplatesHC = await fetchTemplatesHC(params)
    return fetchAllTemplatesHC
  } catch (error) {
    console.log(error.message)
    return error
  }
}
