export const formDefaultValues = {
  dni: '',
  lastname: '',
  firstname: '',
  email: '',
  mobile: '',
  telephone: '',
  note: '',
  country: '',
  address: '',
  city: '',
  gender: 'female',
  insurances: [],
  insurancePlan: '',
  policyNumber: '',
  patientTags: [],
  referrer: 'google',
  blockOnlineTurns: false,
}
