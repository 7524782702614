import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { BsTrash } from 'react-icons/bs'
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { CheckboxField } from '@/components/Form/customFields/CheckboxField'
import { SelectHourField } from '@/components/Form/customFields/SelectHourField'
import { TextOverButtons } from '@/components/TextOverButtons'
import TitlePopUp from '@/components/TitlePopUp'
import { updateDoctorProfile } from '@/store/doctorProfile/actions'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const WorkingHours = ({ workPlace, open, setOpen, doctorProfile, index }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [auxEntity, setAuxEntity] = useState(JSON.parse(JSON.stringify(doctorProfile)))
  const { field: m } = useController({ name: '1' })
  const { field: tu } = useController({ name: '2' })
  const { field: th } = useController({ name: '3' })
  const { field: we } = useController({ name: '4' })
  const { field: fr } = useController({ name: '5' })
  const { field: sa } = useController({ name: '6' })
  const { field: su } = useController({ name: '7' })
  const { field: from } = useController({ name: 'from' })
  const { field: to } = useController({ name: 'to' })
  const { field: fieldAddress } = useController({ name: `workPlaces[${index}][address]` })
  const { field: fieldCity } = useController({ name: `workPlaces[${index}][city]` })
  const workDays = [m, tu, th, we, fr, sa, su]
  const checkBoxColor = '#f8ac59'

  useEffect(() => {
    setAuxEntity(JSON.parse(JSON.stringify(doctorProfile)))
  }, [doctorProfile])

  const handleClose = () => {
    setOpen(false)
  }

  const addWorkingHours = async () => {
    await setLoading(true)

    const b = doctorProfile?.workPlaces[index]?.businessHours
    const startHour = from?.value?.split(':')[0] || '09'
    const startMinutes = from?.value?.split(':')[1] || '00'

    const finishHour = to?.value?.split(':')[0] || '18'
    const finishMinutes = to?.value?.split(':')[1] || '00'

    const startTime = dayjs().utc().hour(startHour).minute(startMinutes).format()
    const finishTime = dayjs().utc().hour(finishHour).minute(finishMinutes).format()

    const valuesFiltered = workDays?.filter((v) => v.value === true)
    const days = []

    valuesFiltered?.map((v) => days.push(v.name))
    const businessHours = { days: days, startHour: startTime, finishHour: finishTime }

    const workPlace = {
      address: fieldAddress.value,
      city: fieldCity.value,
      businessHours: b ? [...b, businessHours] : [businessHours],
    }

    const updateDoctor = {
      ...doctorProfile,
      workPlaces: doctorProfile.workPlaces.map((work, i) => (i === index ? workPlace : work)),
    }
    if (auxEntity?.workPlaces[index]) {
      await dispatch(updateDoctorProfile(updateDoctor))
      setLoading(false)
    } else {
      const data = {
        ...doctorProfile,
        workPlaces: [...doctorProfile.workPlaces, workPlace],
      }
      await dispatch(updateDoctorProfile(data))
      setLoading(false)
      setOpen(false)
    }
  }

  const deleteWorkTime = (i) => {
    const deleteWorkingHours = workPlace.businessHours.filter((w, index) => index !== i)
    const newBusinessHour = {
      ...workPlace,
      businessHours: deleteWorkingHours,
    }
    const updateDoctorProfileData = {
      ...doctorProfile,
      workPlaces: doctorProfile.workPlaces.map((w, i) => (i === index ? newBusinessHour : w)),
    }

    dispatch(updateDoctorProfile(updateDoctorProfileData))
  }

  const removePropWhenClick = (i) => {
    let auxData = { ...auxEntity }
    auxData.workPlaces[index].businessHours[i].showConfirm = false
    setAuxEntity(auxData)
  }

  const addPropWhenClick = (i) => {
    let auxData = { ...auxEntity }
    auxData.workPlaces[index].businessHours[i].showConfirm = true
    setAuxEntity(auxData)
  }

  const showWorkDays = (work) => {
    const workingDays = work.days.map((w) => dayjs().day(w).format('dddd'))
    return workingDays.toString().replace(/,/g, ', ')
  }

  return (
    <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={open} onClose={handleClose} scroll="paper">
      <DialogTitle style={{ padding: '0px' }}>
        <TitlePopUp textTitle="Modificar horarios de atención" handleClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <p style={{ padding: '10px' }}>
          Defina sus horarios de atención en <b>{fieldAddress.value}</b>
        </p>

        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid item xs={12} md={4} style={{ padding: '20px' }}>
            <Grid container>
              <Grid item md={6}>
                <CheckboxField name="1" label="Lunes" checkBoxColor={checkBoxColor} />
              </Grid>
              <Grid item md={6}>
                <CheckboxField name="2" label="Martes" checkBoxColor={checkBoxColor} />
              </Grid>
              <Grid item md={6}>
                <CheckboxField name="3" label="Miércoles" checkBoxColor={checkBoxColor} />
              </Grid>

              <Grid item md={6}>
                <CheckboxField name="4" label="Jueves" checkBoxColor={checkBoxColor} />
              </Grid>

              <Grid item md={6}>
                <CheckboxField name="5" label="Viernes" checkBoxColor={checkBoxColor} />
              </Grid>

              <Grid item md={6}>
                <CheckboxField name="6" label="Sábado" checkBoxColor={checkBoxColor} />
              </Grid>

              <Grid item md={6}>
                <CheckboxField name="7" label="Domingo" checkBoxColor={checkBoxColor} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} style={{ display: 'flex', marginBottom: '50px' }}>
            <Grid item xs={6}>
              <SelectHourField label="desde" name="from" initialValue="09:00" />
            </Grid>
            <Grid item xs={6}>
              <SelectHourField label="hasta" name="to" initialValue="18:00" />
            </Grid>
          </Grid>

          <Grid item md={2} style={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
            <LoadingButton
              loading={loading}
              disabled={loading}
              variant="contained"
              onClick={addWorkingHours}
              className={s.loadingButton}
            >
              AGREGAR
            </LoadingButton>
          </Grid>
          {auxEntity?.workPlaces[index] ? (
            <Grid item xs={12} md={12}>
              <div className={s.containerWorkTimes}>
                {auxEntity?.workPlaces[index]?.businessHours.length === 0 ? (
                  <p className={s.emptyList}>No hay horarios laborales cargados aún.</p>
                ) : (
                  auxEntity?.workPlaces[index].businessHours.map((work, i) => (
                    <div key={work?._id} className={s.itemWorkTimes}>
                      <span style={{ display: 'flex', padding: '10px' }}>
                        <b>{showWorkDays(work)}</b>

                        <div style={{ paddingLeft: '5px' }}>
                          <span> de</span>
                          <b>{` ${toCustomTz(work?.startHour, undefined, true, 'HH:mm')} hs `}</b>
                          <span> hasta </span>
                          <b>{` ${toCustomTz(work?.finishHour, undefined, true, 'HH:mm')} hs `}</b>
                        </div>
                      </span>

                      {work.showConfirm ? (
                        <div className={s.containerButtonsDelete}>
                          <TextOverButtons title="Si, eliminar">
                            <div className={s.btnsDeleteIcons}>
                              <IoIosCheckmarkCircleOutline onClick={() => deleteWorkTime(i)} />
                            </div>
                          </TextOverButtons>
                          <TextOverButtons title="No, cancelar">
                            <div className={s.btnsCancelIcons}>
                              <IoIosCloseCircleOutline onClick={() => removePropWhenClick(i)} />
                            </div>
                          </TextOverButtons>
                        </div>
                      ) : (
                        <TextOverButtons title="Eliminar">
                          <Button onClick={() => addPropWhenClick(i)} className={s.trashIcon}>
                            <BsTrash />
                          </Button>
                        </TextOverButtons>
                      )}
                    </div>
                  ))
                )}
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} md={12}>
              <div className={s.containerWorkTimes}>
                <p className={s.emptyList}>No hay horarios laborales cargados aún.</p>
              </div>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default WorkingHours
