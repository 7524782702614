import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import useValidateURLId from '@/hooks/useValidateURLId'
import { postNewCentreProvider, updateCentreProvider } from '@/store/centreProvider/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from '../../styles.module.scss'

const FormCreateProvider = ({ formValues, onClose }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const centreID = useValidateURLId()

  const handleSubmit = (data) => {
    setIsLoading(true)

    data.centre = centreID

    try {
      if (formValues) {
        dispatch(updateCentreProvider(data))
      } else {
        dispatch(postNewCentreProvider(data))
      }
      onClose()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={{ ...formDefaultValues, ...formValues }}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent isLoading={isLoading} onClose={onClose} formValues={formValues} />
      </ContainerForm>
    </div>
  )
}

export default FormCreateProvider
