import React from 'react'
import { Button, CircularProgress } from '@mui/material'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

const LoadingSaveButton = ({
  textButton,
  onClick,
  isLoading,
  type,
  name,
  className,
  variant,
  loadingContainer,
  size,
  disabled = false,
}) => {
  return (
    <Button
      className={isLoading || disabled ? loadingContainer || s.isLoadingButton : className || s.buttonSave}
      disabled={isLoading || disabled}
      onClick={onClick}
      type={type}
      name={name}
      variant={variant}
      size={size || 'medium'}
    >
      {isLoading && (
        <span className={s.loadingContainer}>
          <CircularProgress color="inherit" size={10} />
        </span>
      )}

      <span>{textButton}</span>
    </Button>
  )
}

export default LoadingSaveButton

LoadingSaveButton.propTypes = {
  textButton: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  loadingContainer: PropTypes.string,
  size: PropTypes.string,
}
