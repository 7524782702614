import { forwardRef } from 'react'
import { Controller, useController } from 'react-hook-form'
import CaluRawAutoComplete from '@/components/styledComponents/CaluRawAutoComplete'

export const CaluFormAutoComplete = forwardRef((props, ref) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name: props?.name })

  return (
    <Controller
      name={props.name}
      defaultValue={[]}
      render={() => (
        <CaluRawAutoComplete {...props} error={error} onChange={onChange} onBlur={onBlur} value={value} ref={ref} />
      )}
    />
  )
})
