export const getChedocClassWithoutBg = (state) => {
  if (state === 'init') return 'chedoc-b'
  if (state === 'finished') return 'chedoc-c'
  if (state === 'confirmed') return 'chedoc-a'
  if (state === 'arrived') return 'chedoc-d'
  if (state === 'happening' || state === 'active-video') return 'chedoc-g'
  if (state === 'absent') return 'chedoc-e'
  if (state === 'cancelled') return 'chedoc-e'
  if (state === 'turn-patient-deleted') return 'chedoc-turn-patient-deleted'
  if (state === 'cancelled-turn') return 'chedoc-e'
  if (state === 'pending') return 'chedoc-c'
  return ''
}
