import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import DividerSection from '@/components/DividerSection'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { SelectField } from '@/components/Form/customFields/SelectField'
import CaluButton from '@/components/styledComponents/CaluButton'
import { extrasAmount } from '@/helpers/constants'
import useValidateURLId from '@/hooks/useValidateURLId'
import { fetchMercadoPagoPaymentLink } from '@/store/mercadoPago/actions'
import { getPatientCentreTurns } from '@/store/turns/actions'
import { patchSelectedTurn } from '@/store/turns/actions'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { treatmentDebt, treatmentPaymentAmount } from '@/utils/functions/treatmentAmounts'
import { Grid, MenuItem } from '@mui/material'

import MercadoPagoPaymentLinkModule from './MercadoPagoPaymentLink'

import s from './styles.module.scss'

const PaymentDataForm = () => {
  const dispatch = useDispatch()
  const centreID = useValidateURLId()

  const { selectedTurn } = useSelector((state) => state.turns)
  const treatment = selectedTurn?.treatment
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { detail: centreMercadoPagoInfo } = useSelector((state) => state?.centreMercadoPagoInfo)
  const [showExtraAmountField, setShowExtraAmountField] = useState(selectedTurn?.extraAmount?.value > 0)
  const [mercadoPagoPaymentLink, setMercadoPagoPaymentLink] = useState(null)

  const { field: extraAmountValueField, fieldState: extraAmountValueState } = useController({
    name: 'extraAmount[value]',
  })
  const { field: extraAmountType } = useController({ name: 'extraAmount[type]' })

  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const moneySign = handleSetMoneySign(country)

  useEffect(() => {
    dispatch(getPatientCentreTurns(centreID, selectedTurn?.patientCentre?._id))

    if (centreMercadoPagoInfo) {
      const params = {
        turnId: selectedTurn?._id,
        centreId: centreDetail?._id,
      }
      handleFetchMercadoPagoPaymentLink(params)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (!selectedTurn?.extraAmount?.type) {
      extraAmountType.onChange('plusCash')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFetchMercadoPagoPaymentLink = async (params) => {
    const response = await dispatch(fetchMercadoPagoPaymentLink(params))
    setMercadoPagoPaymentLink(response)
  }

  useEffect(() => {
    if (!extraAmountValueState?.isDirty) return

    dispatch(
      // @ts-ignore
      patchSelectedTurn({
        extraAmount: {
          value: parseInt(extraAmountValueField?.value || 0),
          type: extraAmountType?.value,
        },
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraAmountValueField.value, extraAmountType.value, extraAmountValueState.isDirty])

  return (
    <>
      <DividerSection label="Datos del pago" />
      {!showExtraAmountField && (
        <Grid className={s.addExtraAmountContainer}>
          <CaluButton
            size="small"
            inverted="true"
            onClick={() => setShowExtraAmountField(true)}
            className={s.addButton}
          >
            Agregar extra
          </CaluButton>
        </Grid>
      )}
      {showExtraAmountField && (
        <Grid container>
          <Grid item xs={12} sm={4}>
            <SelectField
              label="Tipo de extras o descuentos"
              name="extraAmount[type]"
              type="text"
              className={s.inputWidth90}
              fullWidth
            >
              {extrasAmount.map((e) => (
                <MenuItem key={e.value} value={e.value}>
                  {e.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomField
              label="monto a pagar"
              name="extraAmount[value]"
              type="number"
              fullWidth
              className={s.inputWidth90}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <CustomField label="descripcion" name="extraAmount[description]" type="text" className={s.inputWidth90} />
          </Grid>
        </Grid>
      )}

      <br />

      <div className={s.paymentDetails}>
        {treatment ? (
          <>
            <div className={s.amounts}>{`Monto pagado del tratamiento: ${treatmentPaymentAmount(
              moneySign,
              treatment,
            )}`}</div>
            <div className={s.amounts}>{`Deuda del tratamiento: ${treatmentDebt(moneySign, treatment)}`}</div>
          </>
        ) : (
          <>
            <div className={s.amounts}>{`Monto a pagar: ${moneySign} ${selectedTurn?.amount} `}</div>
            <div className={s.amounts}>{`Monto pagado: ${moneySign}${selectedTurn?.totalPayments || 0}`}</div>
            <div className={s.amounts}>{`Deuda: ${moneySign} ${selectedTurn?.debt}`}</div>
          </>
        )}
      </div>
      <MercadoPagoPaymentLinkModule
        selectedTurn={selectedTurn}
        centreDetail={centreDetail}
        mercadoPagoPaymentLink={mercadoPagoPaymentLink}
      />
    </>
  )
}

export default PaymentDataForm
