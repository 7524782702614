import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useValidateURLId from '@/hooks/useValidateURLId'
import { getDoctorCentres } from '@/store/doctorCentres/actions'
import { getSecretaryCentre } from '@/store/secretaryCentre/actions'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import PropTypes from 'prop-types'
import Doctors from './components/doctors'
import Secretaries from './components/secretaries'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const AccessToMedicalRecords = () => {
  const dispatch = useDispatch()
  const centreId = useValidateURLId()
  const [valuePanel, setValuePanel] = useState(0)

  const handleChange = (event, newValue) => {
    setValuePanel(newValue)
  }

  const allSecretaries = useSelector((state) => state.secretaryCentre?.allSecretaries)
  const allDoctorsCentre = useSelector((state) => state.doctorCentres.doctorCentres)

  useEffect(() => {
    if (centreId) {
      dispatch(getSecretaryCentre(centreId))
      dispatch(getDoctorCentres(centreId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centreId])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={valuePanel}
          onChange={handleChange}
          aria-label="Acceso a historias clínicas"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            '& .MuiTab-root.Mui-selected': {
              color: '#009688',
            },
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          <Tab label="Asistentes" />
          <Tab label="Profesionales" />
        </Tabs>
      </Box>

      <div>
        <TabPanel value={valuePanel} index={0}>
          <Secretaries allSecretaries={allSecretaries} />
        </TabPanel>
        <TabPanel value={valuePanel} index={1}>
          <Doctors allDoctorsCentre={allDoctorsCentre} />
        </TabPanel>
      </div>
    </Box>
  )
}

export default AccessToMedicalRecords
