// @ts-nocheck
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSocket } from '@/context/SocketProvider'
import { saveSelectedTurnId } from '@/store/copyTurn/actions'
import { handleSetCountryCode } from '@/utils/functions/handleSetCountryCode'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { showToast } from '@/utils/toast'
import { MenuItem, SubMenu } from '@szhsin/react-menu'

import { rootMenuItems } from './menuItemsConfig'
import { SControlledMenu } from './styles'

const ContextMenu = ({ menuProps, menuAnchorPoint, toggleMenu, turnInfo, setOpenRescheduleTurn }) => {
  const dispatch = useDispatch()
  const { socket } = useSocket()
  const { t } = useTranslation('global')

  const { centreProfile: centreDetail } = useSelector((state) => state.centres)

  const country = turnInfo?.country || 'ARG'
  const countryCode = handleSetCountryCode(country)
  const { status: wappStatus } = useSelector((state) => state.wapp)

  const handleClickCopyTurn = () => {
    mxp.track(mxp.events.agenda.turnEvent.copied)
    dispatch(saveSelectedTurnId(turnInfo._id))
    showToast(`${t('turnLabel.scop')}`, 'success', 2000)
  }

  const changeBackgroundColor = (event, color = '#e6e6e6') => {
    event.target.style.background = color
    event.target.style.color = color === '#e6e6e6' ? 'initial' : 'white'
  }

  const recoverBackgroundColor = (event) => {
    event.target.style.background = 'white'
    event.target.style.color = 'black'
  }

  const menuData = {
    turnInfo,
    centreDetail,
    selectedTurn: { ...turnInfo, centre: centreDetail },
    countryCode,
    socket,
    dispatch,
    setOpenRescheduleTurn,
    handleClickCopyTurn,
    wappStatus,
  }

  return (
    <SControlledMenu
      {...menuProps}
      menuClassName={'contextMenu'}
      anchorPoint={menuAnchorPoint}
      transition
      viewScroll={'close'}
      onClose={() => toggleMenu(false)}
    >
      {rootMenuItems(menuData)?.map((element, i) =>
        element.subMenuItems ? (
          <SubMenu key={i} label={element.content}>
            {element.subMenuItems()?.map((subElement, i) => (
              <MenuItem
                key={i}
                onClick={subElement.onClick}
                onMouseOver={(event) => changeBackgroundColor(event, subElement.color)}
                onMouseLeave={(event) => recoverBackgroundColor(event)}
              >
                {subElement.content}
              </MenuItem>
            ))}
          </SubMenu>
        ) : (
          <MenuItem
            key={i}
            onClick={element.onClick}
            onMouseOver={(event) => changeBackgroundColor(event, element.color)}
            onMouseLeave={(event) => recoverBackgroundColor(event)}
          >
            {element.content}
          </MenuItem>
        ),
      )}
    </SControlledMenu>
  )
}

export default ContextMenu
