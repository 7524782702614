import React from 'react'

import s from './styles.module.scss'

const CaluCard = ({ title, children }) => {
  return (
    <div className={s.mainContainer}>
      <h4 className={s.title}>{title}</h4>
      <div>{children}</div>
    </div>
  )
}

export default CaluCard
