import React from 'react'
import { useDispatch } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { CLEAN_SELECTED_TEMPLATE } from '@/store/wappMessageTemplates'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormCreateBirthdayTemplate from './formCreateBirthdayTemplate'

const MainPopUp = ({ open, setOpen }) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(CLEAN_SELECTED_TEMPLATE())
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        disableEscapeKeyDown={true}
        maxWidth="md"
        open={open}
        fullWidth
        onClose={handleClose}
        scroll="paper"
        disableEnforceFocus
      >
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle="Mensaje personalizado" handleClose={handleClose} />
        </DialogTitle>
        <DialogContent style={{ padding: '0px' }} className="animate__animated animate__fadeIn">
          <FormCreateBirthdayTemplate handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default MainPopUp
