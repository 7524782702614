import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import centreLogo from '@/assets/images/centre-logo.jpg'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import { CustomField } from '@/components/Form/customFields/CustomField'
import LoadingSaveButton from '@/components/LoadingSaveButton'
import { deletePrescriptionAdded } from '@/store/prescriptions/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { LoadingButton } from '@mui/lab'
import { Button, Grid } from '@mui/material'

import SharePdfModule from './SharePdfModule'

import s from '../../../styles.module.scss'

export const FormContentPrescriptions = ({
  submitLoading = false,
  detailPrescription,
  isNewPrescription,
  isSecretary,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const [openAlertDelete, setOpenAlertDelete] = useState(false)

  return (
    <Grid container className={s.formContainer}>
      <Grid item container>
        <Grid item md={3} className={s.imageDoctorPrescription}>
          <img src={detailPrescription?.centreLogo || centreLogo} alt="doctor" />
        </Grid>

        <Grid item xs={6} md={4} className={s.itemCentreName}>
          <p>{detailPrescription?.centreName}</p>
          <small>
            {detailPrescription?.centreStreet} - {detailPrescription?.centreCity} | {detailPrescription?.centrePhone}
          </small>
        </Grid>

        <Grid item xs={6} md={4} className={s.itemDate}>
          <small className={s.identifier}>{`Fecha: ${toCustomTz(
            detailPrescription?.creationDate || new Date(),
            undefined,
            false,
            'DD/MM/YYYY',
          )} `}</small>
        </Grid>
      </Grid>

      <Grid item xs={12} className={s.infoPatient}>
        <p>
          {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.Patient`)}:{' '}
          <b>{detailPrescription?.patientFullName?.toUpperCase()}</b> (DNI: {detailPrescription?.patientDni})
        </p>
        <p>
          {`${t('insuranceLabel.S')}`}: {detailPrescription?.patientInsurance}{' '}
          {detailPrescription?.patientInsurancePlan && `(${detailPrescription?.patientInsurancePlan})`}{' '}
          {detailPrescription?.patientPolicyNumber && `: ${detailPrescription?.patientPolicyNumber}`}
        </p>
      </Grid>

      <Grid item xs={12}>
        {isNewPrescription || detailPrescription?.state === 'draft' ? (
          <CustomField label="Diagnóstico *" name="diagnosis" type="textarea" fullWidth multiline />
        ) : (
          detailPrescription?.diagnosis && (
            <span className={s.propName}>
              <b>Diagnóstico: </b> {detailPrescription?.diagnosis}
            </span>
          )
        )}
      </Grid>

      <Grid item xs={12}>
        {isNewPrescription || detailPrescription?.state === 'draft' ? (
          <CustomField label="Medicación *" name="medication" type="textarea" fullWidth multiline />
        ) : (
          detailPrescription?.medication && (
            <span className={s.propName}>
              <b>Medicación: </b> {detailPrescription?.medication}
            </span>
          )
        )}
      </Grid>

      <Grid item xs={12}>
        {isNewPrescription || detailPrescription?.state === 'draft' ? (
          <CustomField label="Cantidad *" name="quantity" type="textarea" fullWidth />
        ) : (
          detailPrescription?.quantity && (
            <span className={s.propName}>
              <b>Cantidad: </b> {detailPrescription?.quantity}
            </span>
          )
        )}
      </Grid>

      <Grid item xs={12}>
        {isNewPrescription || detailPrescription?.state === 'draft' ? (
          <CustomField
            label={`Indicación para el ${t(
              `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`,
            )}`}
            name="indications"
            type="textarea"
            fullWidth
            multiline
          />
        ) : (
          detailPrescription?.indications && (
            <span className={s.propName}>
              <b>
                Indicación para el {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}:{' '}
              </b>{' '}
              {detailPrescription?.indications}
            </span>
          )
        )}
      </Grid>

      <Grid xs={12} item container spacing={1} className={s.mainContainerBtns}>
        <Grid item xs={12} md={2}>
          {!isNewPrescription && !isSecretary && (
            <>
              <Button className={s.delete} onClick={() => setOpenAlertDelete(!openAlertDelete)}>
                Eliminar
              </Button>
              <AlertDialogSlide
                title="Eliminar receta"
                open={openAlertDelete}
                setOpen={setOpenAlertDelete}
                textContent={'¿Realmente quiere eliminar el borrador? No se podrá restaurar'}
                textConfirmationButton={'Eliminar'}
                onClose={() => dispatch(deletePrescriptionAdded(detailPrescription?._id))}
              />
            </>
          )}
        </Grid>

        <Grid item xs={12} md={10} container spacing={1} display="flex" justifyContent="flex-end">
          <Grid item xs={12} md={2}>
            {!isNewPrescription && !isSecretary && (
              <LoadingButton
                disabled={submitLoading}
                loading={submitLoading}
                variant="contained"
                disableElevation
                className={s.copy}
                type="submit"
                name="copia"
              >
                Crear copia
              </LoadingButton>
            )}
          </Grid>

          {(isNewPrescription || detailPrescription?.state === 'draft') && !isSecretary && (
            <>
              <Grid item xs={12} md={3}>
                <LoadingSaveButton
                  isLoading={submitLoading}
                  textButton={'Generar receta'}
                  className={s.saveOriginal}
                  type="submit"
                  name="original"
                  variant="contained"
                  loadingContainer={s.setButtons}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <LoadingSaveButton
                  isLoading={submitLoading}
                  textButton={'Guardar borrador'}
                  className={s.save}
                  type="submit"
                  name="borrador"
                  variant="contained"
                  loadingContainer={s.setButtons}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      {detailPrescription?.state === 'done' && (
        <Grid item xs={12}>
          <SharePdfModule detailPrescription={detailPrescription} />
        </Grid>
      )}
    </Grid>
  )
}
