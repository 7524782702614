import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useDebounce from '@/hooks/useDebounce'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { CircularProgress, TextField } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'

import PropTypes from 'prop-types'
import { AutoCompleteStyles } from './styles'

import s from './styles.module.scss'

const CaluRawAutoComplete = ({ functiontodispatch, isform, stateredux, ...rest }) => {
  // @ts-ignore
  const dispatch = useDispatch()
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  const DELAY_TIME = 300
  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)

  useEffect(() => {
    searchEntity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, inputDebounceValue])

  const searchEntity = async () => {
    let params = {
      search: inputDebounceValue,
    }
    if (rest?.centre) {
      params.centre = rest.centre
    }

    setLoading(true)
    await dispatch(functiontodispatch(params))
    setLoading(false)
  }

  const filterOptions = (options, inputValue) => {
    if (rest?.name === 'users' && inputValue?.inputValue) {
      return options?.filter((option) => {
        const searchText = inputValue?.inputValue?.toLowerCase()?.trim()
        return (
          option.email?.toLowerCase().includes(searchText) ||
          option.name?.toLowerCase().includes(searchText) ||
          (option.lastname &&
            option.firstname &&
            `${option.lastname} ${option.firstname}`.toLowerCase().includes(searchText)) ||
          option.dni?.toLowerCase().includes(searchText)
        )
      })
    }
    if (rest?.name === 'insurance') {
      return options?.filter((option) => {
        const searchText = inputValue?.inputValue?.toLowerCase()?.trim()
        return option.name?.toLowerCase().includes(searchText)
      })
    }

    return options
  }

  return (
    <>
      {isform && <div className={s.titleInput}>{rest.inputlabel}</div>}

      <AutoCompleteStyles
        {...rest}
        onChange={(event, newValue) => {
          rest.onChange(newValue)
          setSearchText('')
        }}
        groupBy={() => (rest?.name === 'insurance' ? 'Buscar obra social...' : '')}
        autoSelect={rest?.name === 'insurance'}
        className={rest.className || ''}
        id="checkboxes-tags-demo"
        multiple={rest?.multiple}
        loading={loading}
        loadingText="Cargando..."
        noOptionsText={!loading ? 'Sin opciones disponibles' : false}
        options={!loading ? stateredux : []}
        disableCloseOnSelect={rest?.multiple ? true : false}
        getOptionLabel={(option) =>
          option.name ||
          `${capitalizeFirstLetters(option.lastname)}, ${capitalizeFirstLetters(option.firstname)} (${option?._id})` ||
          ''
        }
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option?._id}>
            <>
              {props.multiple ? (
                <>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option?.name ||
                    `${capitalizeFirstLetters(option.lastname)}, ${capitalizeFirstLetters(option.firstname)} (${
                      option?._id
                    })`}
                </>
              ) : (
                option.name ||
                `${capitalizeFirstLetters(option.lastname)}, ${capitalizeFirstLetters(option.firstname)} (${
                  option?._id
                })`
              )}
            </>
          </li>
        )}
        filterOptions={filterOptions}
        isOptionEqualToValue={(option, value) => option?._id === value?._id}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            sx={
              isform
                ? {
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: '2px solid #0F1333',

                      '&:hover fieldset': {
                        borderColor: '#0f1333',
                      },
                    },
                  }
                : {}
            }
            variant={isform ? 'outlined' : 'standard'}
            onChange={(e) => setSearchText(e.target.value)}
            label={!isform ? rest.inputlabel : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  )
}

export default CaluRawAutoComplete

CaluRawAutoComplete.propTypes = {
  stateredux: PropTypes.array.isRequired,
  inputlabel: PropTypes.string.isRequired,
  functiontodispatch: PropTypes.func.isRequired,
  className: PropTypes.string,
  centre: PropTypes.string,
  multiple: PropTypes.bool,
  isform: PropTypes.string,
  onChange: PropTypes.func,
}
