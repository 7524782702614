import { toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import './styles.scss'

export const showToast = (text, type, duration = 4000) => {
  if (type === 'info') {
    return toast.info(text, {
      position: 'top-right',
      autoClose: duration,
      theme: 'dark',
    })
  }

  if (type === 'warning') {
    return toast.warning(text, {
      position: 'top-right',
      autoClose: duration,
      theme: 'dark',
    })
  }

  if (type === 'error') {
    return toast.error(text, {
      position: 'top-right',
      autoClose: duration,
      theme: 'dark',
    })
  }

  if (type === 'success') {
    return toast.success(text, {
      position: 'top-right',
      autoClose: duration,
      theme: 'dark',
    })
  }
}

export const showToastInternetDisconnected = (customId) => {
  toast.warning('Sin conexión a Internet. Los cambios efectuados ahora no se guardarán', {
    toastId: customId,
    position: 'top-right',
    theme: 'dark',
    autoClose: false,
    closeOnClick: false,
    draggable: false,
    closeButton: false,
    progress: undefined,
  })
}
