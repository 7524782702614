import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import { getClinicHistoriesBackup } from '@/store/clinicHistory/actions'
import { RESET_BACKUP_PATIENTS } from '@/store/patientCentres'
import convertCSVToExcelAndDownload from '@/utils/functions/convertCSVToExcelAndDownload'
import { showToast } from '@/utils/toast'

import BackupPatients from './backupPatients'

import s from './styles.module.scss'

const TabBackups = () => {
  const dispatch = useDispatch()

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { isLoading } = useSelector((state) => state.clinicHistory)

  const [openPopUpBackupPatients, setOpenPopUpBackupPatients] = useState(false)

  const handleDownloadBackupHC = async () => {
    const csvContent = await dispatch(getClinicHistoriesBackup(centreDetail?._id))
    if (csvContent) {
      try {
        convertCSVToExcelAndDownload(csvContent, `backup_fichas_${centreDetail?.name}`)
      } catch (error) {
        showToast('Se produjo un error en la descarga', 'error')
      }
    }
  }

  const handleClosePopUpBackupPatients = async (event, reason) => {
    if (reason && reason === 'backdropClick') return
    setOpenPopUpBackupPatients(false)
    dispatch(RESET_BACKUP_PATIENTS())
  }

  return (
    <div className={s.mainContainer}>
      <div className={s.itemContainer}>
        <p>Backup de Historias Clínicas</p>
        <div className={s.contCaluButton}>
          <CaluButton color="primary" size="medium" onClick={handleDownloadBackupHC} loading={isLoading}>
            Descargar
          </CaluButton>
        </div>
      </div>
      <div className={s.itemContainer}>
        <p>Backup de Pacientes</p>
        <div className={s.contCaluButton}>
          <CaluButton color="primary" size="medium" onClick={() => setOpenPopUpBackupPatients(true)}>
            Descargar
          </CaluButton>
        </div>
      </div>

      <BackupPatients open={openPopUpBackupPatients} handleClose={handleClosePopUpBackupPatients} />
    </div>
  )
}

export default TabBackups
