import { cancelTurn, cancelTurnByError, changeTurnState } from '@/utils/functions/updateTurns'

export const menuItems = (data) => {
  return [
    {
      content: 'Confirmado',
      state: 'init',
      visibleState: ['init', 'arrived', 'happening', 'finished', 'absent'],
      function: () => {
        changeTurnState('confirmed', data)
        data.handleClose()
      },
      color: '#ADF39E',
    },
    {
      content: 'Llegó',
      state: 'arrived',
      visibleState: ['init', 'confirmed'],
      function: () => {
        changeTurnState('arrived', data)
        data.handleClose()
      },
      color: '#7FD5F4',
    },
    {
      content: 'Pasó',
      state: 'happening',
      visibleState: ['init', 'arrived', 'confirmed'],
      function: () => {
        changeTurnState('happening', data)
        data.handleClose()
      },
      color: '#4C5EFE',
    },
    {
      content: 'Finalizó',
      state: 'finished',
      visibleState: ['init', 'arrived', 'happening', 'confirmed'],
      function: () => {
        changeTurnState('finished', data)
        data.handleClose()
      },
      color: '#0F1333',
    },
    {
      content: 'Restaurar',
      state: 'init',
      visibleState: ['arrived', 'happening', 'finished', 'absent', 'cancelled', 'confirmed'],
      function: () => {
        changeTurnState('init', data)
        data.handleClose()
      },
      color: '#F0CB9B',
    },
    {
      content: 'Ausente',
      state: 'absent',
      visibleState: ['init', 'confirmed'],
      function: () => {
        changeTurnState('absent', data)
        data.handleClose()
      },
      color: '#EA9EB5',
    },

    {
      content: 'Cancelados por paciente',
      visibleState: ['init', 'arrived', 'happening', 'finished', 'confirmed'],
      function: () => {
        cancelTurn('patient', data)
        data.handleClose()
      },
      color: '#EA9EB5',
    },
    {
      content: 'Cancelados por profesional',
      visibleState: ['init', 'arrived', 'happening', 'finished', 'confirmed'],
      function: () => {
        cancelTurn('doctor', data)
        data.handleClose()
      },
      color: '#EA9EB5',
    },
    {
      content: 'Cancelados por error al cargar',
      visibleState: ['init', 'arrived', 'happening', 'finished', 'confirmed'],
      function: () => {
        cancelTurnByError(data)
        data.handleClose()
      },
      color: '#EA9EB5',
    },
  ]
}
