import React from 'react'

import PropTypes from 'prop-types'
import MyTurnsTab from './components/myTurnsTab'

import s from './styles.module.scss'

const MyTurnsPanel = ({ setOpenPopUp, defaultParams, startDate, finishDate }) => {
  return (
    <div className={s.mainContainer}>
      <MyTurnsTab
        setOpenPopUp={setOpenPopUp}
        defaultParams={defaultParams}
        startDate={startDate}
        finishDate={finishDate}
      />
    </div>
  )
}

export default MyTurnsPanel

MyTurnsPanel.propTypes = {
  allCentres: PropTypes.array,
}
