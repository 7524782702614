import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchInsurancePrices = async (centre) => {
  const route = `api/insurancePrices`
  const params = { centre }
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewInsurancePrice = async (data) => {
  const route = `api/insurancePrices`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteInsurancePrice = async (id) => {
  const route = `api/insurancePrices/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putInsurancePrice = async (data) => {
  const route = `api/insurancePrices`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchSingleInsurancePrice = async (id) => {
  const route = `api/insurancePrices/${id}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export {
  addNewInsurancePrice,
  deleteInsurancePrice,
  fetchInsurancePrices,
  fetchSingleInsurancePrice,
  putInsurancePrice,
}
