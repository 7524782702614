import React, { useEffect, useState } from 'react'
import { useController, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DateField } from '@/components/Form/customFields/DateField'
import CaluFormInput from '@/components/formComponents/customFields/CaluFormInput'
import { CaluFormSelect } from '@/components/formComponents/customFields/CaluFormSelect'
import CaluButton from '@/components/styledComponents/CaluButton'
import { countryCodes, genders } from '@/helpers/constants'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { handleSetCountryCode } from '@/utils/functions/handleSetCountryCode'
import { handleSetDniLabel } from '@/utils/functions/handleSetDniLabel'
import { sortArrayAlphabetically } from '@/utils/functions/sortArrayAlphabetically'
import { Grid } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false, userData }) => {
  const { t } = useTranslation('global')
  const [countryCode, setCountryCode] = useState('549')
  const [dniLabel, setDniLabel] = useState('DNI')
  const centreDetail = useSelector((state) => state?.centres?.centreProfile)
  const COUNTRY = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY
  const { entityData, turnData } = useSelector((state) => state.turns?.onLineTurns)

  const watchCountry = useWatch({ name: 'country', defaultValue: COUNTRY })
  const { field: fieldCountry } = useController({ name: 'country' })

  useEffect(() => {
    if (COUNTRY) fieldCountry.onChange(COUNTRY)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const countryCode = handleSetCountryCode(watchCountry)
    const dniLabel = handleSetDniLabel(watchCountry)
    if (countryCode) setCountryCode(countryCode)
    if (dniLabel) setDniLabel(dniLabel)
  }, [watchCountry])

  return (
    <Grid container spacing={2} className={s.container}>
      <div>
        <h2>Hola {capitalizeFirstLetters(userData?.firstname)} </h2>
        <p>
          Aún hay datos faltantes para terminar de crear tu{' '}
          <b>perfil de {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}</b> y utilizar
          esta cuenta para reservar {t('turnLabel.p')} online
        </p>
        <div className={s.divider}></div>
      </div>

      <Grid item md={12} className={s.subtitle}>
        Datos personales
      </Grid>
      <Grid item xs={12}>
        <CaluFormSelect
          items={sortArrayAlphabetically(countryCodes)}
          labelText="País"
          name="country"
          type="text"
          className={s.input}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CaluFormInput labelText={`${dniLabel} *`} name="dni" className={s.input} />
      </Grid>
      <Grid item xs={12} md={4}>
        <CaluFormInput labelText="Nombre(s) *" name="firstname" className={s.input} />
      </Grid>
      <Grid item xs={12} md={4}>
        <CaluFormInput labelText="Apellido(s) *" name="lastname" className={s.input} />
      </Grid>
      <Grid item xs={12} md={6}>
        <CaluFormInput labelText="Email* " name="email" className={s.input} />
      </Grid>

      <Grid item xs={12} md={6}>
        <CaluFormInput
          labelText="Celular *"
          name="mobile"
          type="number"
          className={s.input}
          countryCode={countryCode}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DateField label="Fecha de nacimiento (DD/MM/YYYY)" name="birth" fullWidth className={s.datePicker} />
      </Grid>

      <Grid item xs={12} md={6}>
        <CaluFormSelect items={genders} labelText="Género" name="gender" type="text" className={s.input} />
      </Grid>

      <div className={s.buttonContainer}>
        <CaluButton color="primary" type="submit" size="large" loading={isLoading}>
          Crear perfil de {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}
        </CaluButton>
      </div>
      {!entityData && !turnData && (
        <div className={s.containerSmall}>
          <small>
            <i>
              El <b>perfil de {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}</b> son
              los datos que verá el centro de salud en el que reserves {t('turnLabel.p')} online
            </i>
          </small>
        </div>
      )}
    </Grid>
  )
}
