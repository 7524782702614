import { fetchCentreTargetAvailabilities, fetchCentreTargetTurns } from '@/services/centreAvailability'
import {
  CENTRE_AVAILABILITIES_ERROR,
  FETCH_CENTRE_AVAILABILITIES,
  RESET_CENTRE_AVAILABILITIES,
  RESET_SPECIFIC_AVAILABILITY,
} from '@/store/centreAvailability'
import { showToast } from '@/utils/toast'

const getCentreTargetAvailabilities = (centreId, data) => async (dispatch) => {
  try {
    const centreTargetData = await fetchCentreTargetAvailabilities(centreId, data)
    dispatch(FETCH_CENTRE_AVAILABILITIES(centreTargetData))
  } catch {
    showToast('Se produjo un error', 'error')
    dispatch(CENTRE_AVAILABILITIES_ERROR())
  }
}

const getEntityAvailability = (centreId, data) => async (dispatch, getState) => {
  try {
    const entityAvailability = await fetchCentreTargetTurns(centreId, data) // have entity availability
    const { entityId } = data
    const state = getState()
    let auxAvailability = JSON.parse(JSON.stringify(state.centreAvailability))
    Object.keys(entityAvailability).forEach((group) => {
      Object.keys(entityAvailability[group]).forEach((groupItem) => {
        auxAvailability[group][groupItem]['availability'][entityId] = {
          ...(auxAvailability[group][groupItem]['availability'][entityId] || {}),
          ...(entityAvailability[group][groupItem]['availability']?.[entityId] || {}),
        }
      })
    })

    dispatch(FETCH_CENTRE_AVAILABILITIES(auxAvailability))
  } catch {
    showToast('Se produjo un error', 'error')
    dispatch(CENTRE_AVAILABILITIES_ERROR())
  }
}

const resetCentreTargetAvailabilities = () => async (dispatch) => {
  try {
    dispatch(RESET_CENTRE_AVAILABILITIES())
  } catch {
    showToast('Se produjo un error', 'error')
    dispatch(CENTRE_AVAILABILITIES_ERROR())
  }
}

const resetSpecificAvailability = (service, entities) => async (dispatch) => {
  let data = {
    service,
    entities,
  }
  try {
    dispatch(RESET_SPECIFIC_AVAILABILITY(data))
  } catch (error) {
    console.log('3', error)
    showToast('Se produjo un error', 'error')
    dispatch(CENTRE_AVAILABILITIES_ERROR())
  }
}

export {
  getCentreTargetAvailabilities,
  getEntityAvailability,
  resetCentreTargetAvailabilities,
  resetSpecificAvailability,
}
