import React from 'react'
import Noentities from '@/assets/images/no-doctors.png'
import { Button } from '@mui/material'

import s from './styles.module.scss'

const NoEntitiesMessage = ({ refCreateAgendaButton }) => {
  const handleCreateAgendaButton = () => {
    refCreateAgendaButton.current.click()
  }
  return (
    <div className={s.mainContainer}>
      <p className={s.title}>Bienvenid@ a Calu</p>
      <div className={s.divider}></div>
      <p className={s.subtitle}>
        Te vas a sorprender con lo fácil que es y en cómo te va a facilitar la gestión diaria de tu centro de salud
      </p>
      <div className={s.containerImg}>
        <img className={s.img} src={Noentities} alt="empty" />
      </div>
      <p className={s.mainP}>Aún no tienes agendas cargadas</p>
      <p className={s.secondP}>Para comenzar agrega una nueva agenda</p>
      <div className={s.containerButtons}>
        <div>
          <Button className={s.usefulInfo} onClick={() => handleCreateAgendaButton()}>
            Crear agenda
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NoEntitiesMessage
