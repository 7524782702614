import * as React from 'react'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormCreateNewProduct from '../formCreateNewProduct'

export default function FormDialog({ open, selectedProduct, onClose }) {
  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="sm" fullWidth open={open} onClose={onClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle={selectedProduct ? 'Datos del producto' : 'Nuevo producto'} handleClose={onClose} />
        </DialogTitle>
        <DialogContent>
          <FormCreateNewProduct onClose={onClose} formValues={selectedProduct} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
