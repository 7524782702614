// @ts-check
import { getAllTreatmentDebts, getAllTurnDebts } from '@/services/debts'
import { putPatientTreatment } from '@/services/treatments'
import { FETCH_TURNS_DEBTS_TOTALS, GET_ALL_TREATMENTS_DEBTS, GET_ALL_TURNS_DEBTS } from '@/store/debts'
import { UPDATE_PATIENT_TREATMENT } from '@/store/treatments'
import { showToast } from '@/utils/toast'

const getTurnsDebts = (params) => async (dispatch) => {
  try {
    let { docs: turnsDebts, ...paginationData } = await getAllTurnDebts(params)
    dispatch(GET_ALL_TURNS_DEBTS({ turnsDebts, paginationData }))
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error')
  }
}

const getTurnsDebtsTotals = (params) => async (dispatch) => {
  try {
    let totals = await getAllTurnDebts(params)
    dispatch(FETCH_TURNS_DEBTS_TOTALS(totals))
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error')
  }
}

const getTreatmentDebts = (params) => async (dispatch) => {
  try {
    let { docs: treatmentsDebts, ...paginationData } = await getAllTreatmentDebts(params)
    dispatch(GET_ALL_TREATMENTS_DEBTS({ treatmentsDebts, paginationData }))
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error')
  }
}

const deleteTreatmentDebt = (updatedTreatment) => async (dispatch) => {
  const turns = updatedTreatment.turns
  const patientCentre = updatedTreatment.patientCentre
  try {
    const updatedTreatmentCreated = await putPatientTreatment(updatedTreatment)
    updatedTreatmentCreated.turns = turns
    updatedTreatmentCreated.patientCentre = patientCentre
    dispatch(UPDATE_PATIENT_TREATMENT(updatedTreatmentCreated))
    showToast('Sesión actualizada', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error')
  }
}

export { deleteTreatmentDebt, getTreatmentDebts, getTurnsDebts, getTurnsDebtsTotals }
