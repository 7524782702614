import React, { useEffect, useState } from 'react'
import { useController, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { DateField } from '@/components/Form/customFields/DateField'
import CaluFormInput from '@/components/formComponents/customFields/CaluFormInput'
import { CaluFormSelect } from '@/components/formComponents/customFields/CaluFormSelect'
import CaluButton from '@/components/styledComponents/CaluButton'
import { genders } from '@/helpers/constants'
import { handleSetCountryCode } from '@/utils/functions/handleSetCountryCode'
import { handleSetDniLabel } from '@/utils/functions/handleSetDniLabel'
import { Grid } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false }) => {
  const [countryCode, setCountryCode] = useState('549')
  const [dniLabel, setDniLabel] = useState('DNI')
  const centreDetail = useSelector((state) => state?.centres?.centreProfile)
  const COUNTRY = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY
  const watchCountry = useWatch({ name: 'country', defaultValue: COUNTRY })
  const { field: patientDni } = useController({ name: 'dni' })
  const { field: fieldCountry } = useController({ name: 'country' })

  useEffect(() => {
    if (patientDni.value.length === 15) {
      patientDni.onChange('')
    }
    if (COUNTRY) fieldCountry.onChange(COUNTRY)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const countryCode = handleSetCountryCode(watchCountry)
    const dniLabel = handleSetDniLabel(watchCountry)
    if (countryCode) setCountryCode(countryCode)
    if (dniLabel) setDniLabel(dniLabel)
  }, [watchCountry])

  return (
    <Grid container spacing={2} className={s.container}>
      <Grid item md={12} className={s.subtitle}>
        Datos personales
      </Grid>
      <Grid item xs={12} md={6} className={s.gridItem}>
        <CaluFormInput labelText="Nombre(s) *" name="firstname" className={s.input} />
      </Grid>
      <Grid item xs={12} md={6}>
        <CaluFormInput labelText="Apellido(s) *" name="lastname" className={s.input} />
      </Grid>
      <Grid item xs={12} md={6} className={s.gridItem}>
        <CaluFormInput labelText={`${dniLabel} *`} name="dni" className={s.input} />
      </Grid>
      <Grid item xs={12} md={6}>
        <CaluFormInput
          labelText="Celular *"
          name="mobile"
          type="number"
          className={s.input}
          countryCode={countryCode}
        />
      </Grid>

      <Grid item xs={12} md={6} className={s.gridItem}>
        <CaluFormSelect items={genders} labelText="Género" name="gender" type="text" fullWidth={true} />
      </Grid>

      <Grid item xs={12} md={6} className={s.datePicker}>
        <DateField label="Fecha de nacimiento (DD/MM/YYYY)" name="birth" fullWidth className={s.input} />
      </Grid>

      <div className={s.buttonContainer}>
        <CaluButton color="primary" size="large" type="submit" loading={isLoading}>
          Continuar
        </CaluButton>
      </div>
    </Grid>
  )
}
