import { removeTurnById, updateATurn } from '@/store/turns/actions'

import { handleWappReminder } from '../handleWappReminders'

export const changeTurnState = async (state, data) => {
  if (!data.selectedTurn) return

  const patientMobile = data.selectedTurn?.patientCentre?.mobile
  const { socket, selectedTurn } = data
  const { centre } = selectedTurn
  const { centreConfiguration } = centre

  let auxTurn = {
    ...data.selectedTurn,
    state,
  }

  if (!auxTurn?.service?._id) {
    auxTurn.service = null
  }

  let auxData = {
    ...data,
  }

  try {
    const updatedTurn = await data.dispatch(updateATurn(auxTurn))
    socket.emit('calendar_edit_turn', `${selectedTurn?.centre?._id || data.selectedTurn?.centre}_calendar`, updatedTurn)
    if (state === 'init' && centreConfiguration?.shouldSendNotificationOnTurnCreate && patientMobile) {
      auxData.state = 'turnCreation'
      handleWappReminder(auxData)
    }
    if (state === 'finished' && centreConfiguration?.shouldSendNotificationOnTurnFinish && patientMobile) {
      auxData.state = 'finished'
      handleWappReminder(auxData)
    }

    if (data.handleClose) data.handleClose(null)
  } catch (e) {
    console.log(e)
  }
}

export const cancelTurn = async (cancelledInfo, data) => {
  const { selectedTurn, socket, dispatch, handleClose } = data
  if (!selectedTurn) return
  const { centre } = selectedTurn
  const patientMobile = selectedTurn?.patientCentre?.mobile

  const auxTurn = {
    ...selectedTurn,
    state: 'cancelled',
    cancelledInfo: cancelledInfo,
  }
  const auxData = {
    ...data,
    state: 'cancelled',
  }

  try {
    const updatedTurn = await dispatch(updateATurn(auxTurn))
    socket.emit('calendar_edit_turn', `${centre?._id}_calendar`, updatedTurn)
    if (centre.centreConfiguration.shouldSendNotificationOnTurnCancel && patientMobile) {
      handleWappReminder(auxData)
    }

    if (handleClose) handleClose()
  } catch (e) {
    console.log(e)
  }
}

export const cancelTurnByError = (data) => {
  const { selectedTurn, socket, dispatch, handleClose } = data
  if (!selectedTurn) return

  dispatch(removeTurnById(selectedTurn?._id))
  socket.emit('calendar_delete_turn', `${selectedTurn?.centre?._id || selectedTurn?.centre}_calendar`, selectedTurn)

  if (handleClose) handleClose()
}
