import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSaveButton from '@/components/LoadingSaveButton'
import { generateMongoObjectId } from '@/helpers/generateMongoObjectId'
import { postRoom, updateRoom } from '@/store/rooms/actions'
import { Grid, TextField } from '@mui/material'

import s from './styles.module.scss'

const FormCreateRoom = ({ handleClose }) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const roomSelected = useSelector((state) => state.rooms?.roomSelected)

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
    },
  })

  const onSubmit = async (data, e) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      if (roomSelected) {
        dispatch(updateRoom(data))
      } else {
        data._id = generateMongoObjectId()
        dispatch(postRoom(data))
      }
      handleClose()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (roomSelected) {
      Object.keys(roomSelected[0]).forEach((k) => {
        setValue(k, roomSelected[0][k])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomSelected])

  return (
    <div>
      <div className={s.containerForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} className={s.subTitle}>
              <p>Datos del espacio</p>
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField {...field} type="text" label="Nombre del espacio *" variant="standard" fullWidth />
                )}
                rules={{ required: true }}
              />
              {errors.name?.type === 'required' && <p className={s.formAlert}>Nombre requerido</p>}
            </Grid>

            <div className={s.containerBtnSave}>
              <LoadingSaveButton type="submit" isLoading={isLoading} textButton="Guardar" />
            </div>
          </Grid>
        </form>
      </div>
    </div>
  )
}

export default FormCreateRoom
