import React from 'react'
import { useSelector } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import FormCreateRoom from '@/pages/RoomsPage/components/formCreateRoom'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

const PopUpRoom = ({ open, setOpen }) => {
  const roomSelected = useSelector((state) => state.rooms?.roomSelected)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} open={open} maxWidth="md" fullWidth onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle={roomSelected ? 'Edición de espacio' : 'Nuevo espacio'} handleClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <FormCreateRoom handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpRoom
