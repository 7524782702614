import React from 'react'
import { useTranslation } from 'react-i18next'
import { BsCheck } from 'react-icons/bs'
import { IoClose } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import defaultImage from '@/assets/images/default.png'
import { setCentreEntity } from '@/store/doctorCentres/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'

import s from './styles.module.scss'

const ContentSideBar = ({ listItem }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  return (
    <div>
      {!listItem?.length ? (
        <p className={s.emptyList}>No hay agendas cargadas aún</p>
      ) : (
        listItem?.map((item) => (
          <div className={s.machines} key={item._id} onClick={async () => await dispatch(setCentreEntity(item._id))}>
            <img
              src={item?.image || defaultImage}
              className={item?.image ? s.machineLogo : s.defaultImage}
              alt="Agenda"
            />

            <div className={s.containerInfoMachine}>
              <p className={s.titleMachine}>{capitalizeFirstLetters(item?.name)}</p>
              {item.onlineTurns ? (
                <p className={s.onlineTurnsTrue}>
                  <BsCheck />
                  <span>{t('turnLabel.p')} online</span>
                </p>
              ) : (
                <p className={s.onlineTurnsFalse}>
                  <IoClose />
                  <span>{t('turnLabel.p')} online</span>
                </p>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  )
}

export default ContentSideBar
