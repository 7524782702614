import React, { useEffect, useState } from 'react'
import Logo from '@/assets/images/logo.png'
import LoaderCircle from '@/components/LoaderCircle'
import NavBar from '@/components/NavBar'
import { apiCheck } from '@/services/apiCheck'
import { Paper } from '@mui/material'

import StatusBlock from './components/StatusBlock'

import s from './styles.module.scss'

const StatusPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [apiResponse, setApiResponse] = useState(null)

  const checkApi = async () => {
    const apiResponse = await apiCheck()
    setApiResponse(apiResponse)
    setIsLoading(false)
  }

  useEffect(() => {
    if (apiResponse === null) {
      checkApi()
    }
  }, [apiResponse])

  return (
    <div className={s.body}>
      <NavBar />
      <div className={s.container}>
        <Paper className={s.content}>
          <div className={s.contentBlock}>
            <img className={s.logo} src={Logo} alt="Calu" />

            {isLoading ? <LoaderCircle /> : <StatusBlock apiResponse={apiResponse} />}
          </div>
        </Paper>
      </div>
    </div>
  )
}

export default StatusPage
