export const formDefaultValues = {
  firstname: '',
  lastname: '',
  email: '',
  repeatEmail: '',
  password: '',
  repeatPassword: '',
  dni: '',
  doctorNumber: '',
  workPlaces: [
    {
      city: '',
      address: '',
    },
  ],
  address: '',
  city: '',
  specialities: [],
  mobile: '',
  telephone: '',
}
