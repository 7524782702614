import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from '@/context/UserProvider'
import { setAllHCFragmentsController } from '@/controllers/clinicHistoryFragments'
import useDebounce from '@/hooks/useDebounce'
import { LOADING_FRAGMENTS } from '@/store/clinicHistoryFragments'
import { getAllHCFragments } from '@/store/clinicHistoryFragments/actions'
import { getAge } from '@/utils/functions/getAge'
import { Autocomplete, TextField } from '@mui/material'

export default function FragmentsDropDown({ dataPatient, allowEditHC = false }) {
  const { t } = useTranslation('global')
  const dispatch = useDispatch()
  const { defaultCentre } = useUser()
  const [searchText, setSearchText] = useState('')
  const [selectedValue, setSelectedValue] = useState(null)
  const DELAY_TIME = 200
  const MIN_CHARACTERS = 0
  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)
  const { allHCFragments, isLoading } = useSelector((state) => state.clinicHistoryFragments)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isDoctor, entityInLocalStorage } = usersProfileConnected

  const {
    field: { value, onChange },
  } = useController({ name: 'observations' })

  const handleSetFragment = (fragment) => {
    setSelectedValue(fragment)
    let validReplacements = {
      '[apellido]': dataPatient && dataPatient?.lastname.toUpperCase(),
      '[nombre]': dataPatient && dataPatient?.firstname.toUpperCase(),

      [`${t('insuranceLabel.frag')}`]:
        dataPatient && dataPatient?.insurances && dataPatient?.insurances[0]
          ? dataPatient?.insurances[0]?.name
          : `sin ${t('insuranceLabel.s')}`,

      '[nro-afiliado]': dataPatient && dataPatient?.policyNumbers ? dataPatient?.policyNumbers[0] : 'sin nro afiliado',
      '[edad]': dataPatient && dataPatient?.birth && getAge(dataPatient.birth),
      '[DNI]': dataPatient && dataPatient?.dni === 15 ? null : dataPatient?.dni,
    }

    let frag = fragment?.body

    Object.keys(validReplacements).forEach(function (r) {
      frag = frag.replaceAll(r, validReplacements[r])
    })

    if (value) {
      onChange(`${value}${frag}`)
    } else {
      onChange(frag)
    }
  }

  const getFragmentBySearch = async () => {
    let params
    if (isDoctor) {
      params = {
        doctorCentre: entityInLocalStorage?.doctorCentreId,
      }
    } else {
      params = {
        centre: defaultCentre,
      }
    }
    params.search = inputDebounceValue

    dispatch(LOADING_FRAGMENTS(true))
    const fetchAllHCFragments = await setAllHCFragmentsController(params)
    dispatch(getAllHCFragments(fetchAllHCFragments))
    dispatch(LOADING_FRAGMENTS(false))
  }

  useEffect(() => {
    inputSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDebounceValue])

  const inputSearch = async () => {
    if (inputDebounceValue?.length >= MIN_CHARACTERS) {
      getFragmentBySearch()
    }
  }

  return (
    <Autocomplete
      disabled={!allowEditHC}
      options={allHCFragments || []}
      noOptionsText="No hay fragmentos disponibles."
      loading={isLoading}
      loadingText="Buscando..."
      ListboxProps={{ style: { maxHeight: '15rem', fontSize: '14px' } }}
      getOptionLabel={(fragment) => fragment?.name?.toUpperCase()}
      renderOption={(props, fragment, { selected }) => (
        <li {...props} key={fragment?._id}>
          {`${fragment?.name?.toUpperCase()}`}
        </li>
      )}
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      fullWidth
      value={selectedValue}
      renderInput={(params) => (
        <TextField {...params} label="Fragmentos" variant="standard" onChange={(e) => setSearchText(e.target.value)} />
      )}
      onChange={(event, selectedValues) => {
        handleSetFragment(selectedValues)
      }}
    />
  )
}
