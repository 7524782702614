import React from 'react'
import { useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'

import HeaderSearchBar from '../headerSearchBar'
import ManagementTable from '../managementTable'
import SummaryTable from '../summaryTable'

import s from '../../styles.module.scss'

const PaymentsTab = ({
  setOpenAddEntry,
  inputSearch,
  setInputSearch,
  directionOfAnimation,
  loadingPatients,
  setLoadingPatients,
  patientValue,
  setPatientValue,
}) => {
  const { cashboxEntries, cashLoading } = useSelector((state) => state.cashboxItems)

  return (
    <div className={directionOfAnimation}>
      <HeaderSearchBar
        billingTab={false}
        setOpenAddEntry={setOpenAddEntry}
        inputSearch={inputSearch}
        setInputSearch={setInputSearch}
        loadingPatients={loadingPatients}
        setLoadingPatients={setLoadingPatients}
        patientValue={patientValue}
        setPatientValue={setPatientValue}
      />
      {cashLoading ? (
        <LoaderCircle size={50} />
      ) : cashboxEntries?.length === 0 ? (
        <p className={s.noItems}>No hay items para las fechas solicitadas</p>
      ) : (
        <>
          <ManagementTable billingTab={false} setOpenAddEntry={setOpenAddEntry} patientValue={patientValue} />
          <div>
            <SummaryTable billingTab={false} />
          </div>
        </>
      )}
    </div>
  )
}

export default PaymentsTab
