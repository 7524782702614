import React from 'react'
import { useTranslation } from 'react-i18next'
import ErrorCard from '@/components/ErrorCard'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'

import s from './styles.module.scss'

const ErrorCards = ({ centre, centreError, centreAvailabilityError }) => {
  const { t } = useTranslation('global')
  return (
    <div className={s.container}>
      {!centre?.visible && centre !== null && (
        <div className={s.containerErrorCard}>
          <ErrorCard
            title={'Ups! Ha ocurrido un error'}
            text1={` Actualmente ${capitalizeFirstLetters(centre?.name)} no permite reservar ${t(
              'turnLabel.p',
            )} online`}
            text2={
              centre?.phones[0] &&
              `Para reservar ${t('turnLabel.p')} comunicate teléfonicamente al ${centre?.phones[0]}`
            }
            textButton={'Volver al inicio'}
            path={'/'}
          />
        </div>
      )}

      {(centreError || centreAvailabilityError) && (
        <div className={s.containerErrorCard}>
          <ErrorCard
            title={'Ups! Ha ocurrido un error'}
            text1={`En estos momentos no podemos recuperar la disponibilidad de ${t(
              'turnLabel.p',
            )}. Intente nuevamente en unos minutos.`}
            text2={' Si el problema persiste comunicate teléfonicamente con tu centro de salud.'}
            textButton={'Volver al inicio'}
            path={'/'}
          />
        </div>
      )}
    </div>
  )
}

export default ErrorCards
