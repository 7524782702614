import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateWappStatus } from '@/store/wapp/actions'

import CardWappConnection from '../CardWappConnection'

import s from './styles.module.scss'

const TabConnection = ({ isLoading, setIsLoading, handleTabChange }) => {
  const dispatch = useDispatch()
  const { status: statusWapp } = useSelector((state) => state.wapp)
  const { centreProfile } = useSelector((state) => state?.centres)
  const navigate = useNavigate()
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected
  const deviceId = entityInLocalStorage?._id

  const setInit = async () => {
    await dispatch(updateWappStatus(deviceId))
  }

  useEffect(() => {
    if (!centreProfile?.centreConfiguration?.hasWappModule) navigate('/')
    if (statusWapp === null && deviceId) setInit()

    const interval = setInterval(() => {
      dispatch(updateWappStatus(deviceId))
    }, 5000)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId])

  return (
    <div className={s.container}>
      <CardWappConnection handleTabChange={handleTabChange} />
    </div>
  )
}

export default TabConnection
