import i18next from '@/configs/i18next'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { handleSetCountryCode } from '@/utils/functions/handleSetCountryCode'
import { handleSetDniLabel } from '@/utils/functions/handleSetDniLabel'

import dayjs from 'dayjs'

export const adapterToExcel = (patient, extraFields, centreCountry) => {
  const showPatientTags = (patientTags) => {
    let tags = patientTags?.map((tag) => tag.name).join(' / ')
    return tags
  }

  const dataExcel = {
    Celular: patient?.mobile ? patient?.mobile : null,
    Apellido: capitalizeFirstLetters(patient?.lastname),
    Nombre: capitalizeFirstLetters(patient?.firstname),
    Mail: patient?.email ? patient?.email : null,
    Teléfono: patient?.telephone ? patient?.telephone : null,
    'Fecha nacimiento': patient?.birth ? dayjs(patient?.birth).format('DD-MM-YYYY') : null,
    'Mes cumpleaños': patient?.birth ? dayjs(patient?.birth).format('MMMM') : null,
    'Info adicional plan 1': patient?.insurancePlan && patient?.insurancePlan?.[0] ? patient?.insurancePlan?.[0] : null,
    'Nro de afiliado 1': patient?.policyNumbers && patient?.policyNumbers?.[0] ? patient?.policyNumbers?.[0] : null,
    'Info adicional plan 2': patient?.insurancePlan && patient?.insurancePlan?.[1] ? patient?.insurancePlan?.[1] : null,
    'Nro de afiliado 2': patient?.policyNumbers && patient?.policyNumbers?.[1] ? patient?.policyNumbers?.[1] : null,
    Etiquetas: patient?.patientTags?.length > 0 ? showPatientTags(patient?.patientTags) : null,
    Nota: patient?.note ? patient?.note : null,
  }

  dataExcel[`${i18next.t('insuranceLabel.S', { ns: 'global' })} 1`] = patient?.insurances?.[0]
    ? patient?.insurances?.[0]?.name
    : null
  dataExcel[`${i18next.t('insuranceLabel.S', { ns: 'global' })} 2`] = patient?.insurances?.[1]
    ? patient?.insurances?.[1]?.name
    : null
  dataExcel[`${i18next.t('turnLabel.su', { ns: 'global' })}`] =
    patient?.lastTurn && dayjs(patient.lastTurn).add(3, 'hours').format('YYYY-MM')
  dataExcel[handleSetDniLabel(patient?.country)] = !patient?.dni || patient?.dni?.length === 15 ? null : patient?.dni

  // Extra fields
  if (extraFields) {
    extraFields?.forEach((field) => {
      if (!patient?.extraFieldValues) {
        dataExcel[field?.name] = null
        return
      }

      if (dataExcel[field?.name]) {
        dataExcel[field?.name + '_2'] = patient?.extraFieldValues[field?._id] || null
      } else {
        dataExcel[field?.name] = patient?.extraFieldValues[field?._id] || null
      }
    })
  }

  // Nombre&Celular field

  dataExcel['País'] = handleSetCountryCode(patient?.country || centreCountry)

  dataExcel['Nombre&Celular'] = dataExcel['Celular']
    ? dataExcel['Nombre'] + ',' + dataExcel['País'] + dataExcel['Celular']
    : null
  dataExcel['Celular'] = dataExcel['Celular'] ? dataExcel['País'] + dataExcel['Celular'] : null

  return dataExcel
}
