import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'

import PropTypes from 'prop-types'

export const SwitchField = ({ name, label, isCheckedInverted }) => {
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({ name })

  const [isChecked, setIsChecked] = useState(isCheckedInverted ? !value : value)

  useEffect(() => {
    setIsChecked(isCheckedInverted ? !value : value)
  }, [isCheckedInverted, value])

  const handleSwitchChange = () => {
    const newValue = !isChecked
    setIsChecked(newValue)
    onChange(isCheckedInverted ? !newValue : newValue)
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch onBlur={onBlur} ref={ref} checked={isChecked} onChange={handleSwitchChange} />}
        label={label}
      />
    </FormGroup>
  )
}
SwitchField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isCheckedInverted: PropTypes.bool,
}

SwitchField.defaultProps = {}
