import * as React from 'react'
import { Link } from 'react-router-dom'
import CaluButton from '@/components/styledComponents/CaluButton'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

export default function ErrorCard({ title, text1, text2, textButton, path }) {
  return (
    <div className={s.mainContainer}>
      <div className={s.containerCard}>
        <h3 className={s.title}>{title}</h3>
        <div className={s.containerTexts}>
          <p>{text1}</p>
          <p>{text2}</p>
        </div>
        <div className={s.containerButton}>
          <Link to={path}>
            <CaluButton type="link" size="medium">
              {textButton}
            </CaluButton>
          </Link>
        </div>
      </div>
    </div>
  )
}

ErrorCard.propTypes = {
  title: PropTypes.string.isRequired,
  text1: PropTypes.string,
  text2: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  textButton: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

ErrorCard.defaultProps = {
  textButton: 'Volver al inicio',
  path: '/',
}
