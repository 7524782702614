import { apiErrorHandler } from '@/helpers/errorHandlers'
import { reducePayload } from '@/services/treatments/reducePayload'

import API from '../client'

const getTemplateTreatments = async (centreId) => {
  const route = `api/treatments?centre=${centreId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getSingleTemplateTreatment = async (treatmentId) => {
  const route = `api/treatments/${treatmentId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postTemplateTreatment = async (newTreatment) => {
  const route = 'api/treatments'
  return API.post(route, newTreatment)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteTemplateTreatment = async (treatmentId) => {
  const route = `api/treatments/${treatmentId}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putTemplateTreatment = async (updatedTreatment) => {
  const route = `api/treatments`
  return API.put(route, updatedTreatment)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getPatientTreatments = async (centreId, patientId) => {
  const route = `api/treatments?centre=${centreId}&patientCentre=${patientId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postPatientTreatment = async (newTreatment) => {
  const route = 'api/treatments'
  return API.post(route, newTreatment)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deletePatientTreatment = async (treatmentId) => {
  const route = `api/treatments/${treatmentId}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putPatientTreatment = async (updatedTreatment) => {
  const reducedTreatmentTurns = reducePayload(updatedTreatment.turns)
  const updatedTreatmentWithTurns = { ...updatedTreatment, turns: reducedTreatmentTurns }

  const route = `api/treatments`
  return API.put(route, updatedTreatmentWithTurns)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const treatmentReport = async (treatment) => {
  const route = `api/mail/treatmentReport`
  return API.post(route, treatment)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export {
  deletePatientTreatment,
  deleteTemplateTreatment,
  getPatientTreatments,
  getSingleTemplateTreatment,
  getTemplateTreatments,
  postPatientTreatment,
  postTemplateTreatment,
  putPatientTreatment,
  putTemplateTreatment,
  treatmentReport,
}
