import i18next from '@/configs/i18next'
import {
  addNewCentreInsurances,
  deleteCentreInsurances,
  getCentreInsurances,
  putCentreInsurances,
} from '@/services/centreInsurances'
import {
  CENTRE_INSURANCES_CREATED,
  CENTRE_INSURANCES_DELETE,
  CENTRE_INSURANCES_ERROR,
  CENTRE_INSURANCES_SELECTED,
  CENTRE_INSURANCES_UPDATED,
} from '@/store/centreInsurances'
import { showToast } from '@/utils/toast'

const getCurrentcentreInsurances = (centreId) => async (dispatch) => {
  try {
    const centreInsurances = await getCentreInsurances(centreId)
    dispatch(CENTRE_INSURANCES_SELECTED(centreInsurances))
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Se produjo un error', 'error')
    dispatch(CENTRE_INSURANCES_ERROR())
  }
}

const postcentreInsurances = (data) => async (dispatch) => {
  try {
    const newCentreInsurances = await addNewCentreInsurances(data)
    dispatch(CENTRE_INSURANCES_CREATED(newCentreInsurances))
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const deletecentreInsurances = (centreId) => async (dispatch) => {
  try {
    await deleteCentreInsurances(centreId)
    dispatch(CENTRE_INSURANCES_DELETE(centreId))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updatecentreInsurances = (data) => async (dispatch) => {
  const insurances = data.insurances
  try {
    let centreInsurancesUpdated = await putCentreInsurances(data)
    centreInsurancesUpdated.insurances = insurances
    dispatch(CENTRE_INSURANCES_UPDATED(centreInsurancesUpdated))
    if (centreInsurancesUpdated) {
      showToast(`${i18next.t('insuranceLabel.P', { ns: 'global' })} actualizadas`, 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export { deletecentreInsurances, getCurrentcentreInsurances, postcentreInsurances, updatecentreInsurances }
