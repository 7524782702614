import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import CaluButton from '@/components/styledComponents/CaluButton'
import { RESET_INTERNAL_CENTRE_NOTE_SELECTED } from '@/store/internalCentreNotes'
import { getAllInternalCentreNotes } from '@/store/internalCentreNotes/actions'

import InternalCentreNotePopUp from './InternalCentreNotePopUp'
import InternalCentreNotesTable from './InternalCentreNotesTable'

import s from './styles.module.scss'

const InfoInternalCentreNotes = () => {
  const dispatch = useDispatch()

  const [openNotePopUp, setOpenNotePopUp] = useState(false)

  const { centreAdminDetail } = useSelector((state) => state?.centres)
  const { isLoading, internalCentreNoteSelected } = useSelector((state) => state?.internalCentreNotes)

  const setInit = async () => {
    const params = {
      centre: centreAdminDetail._id,
      paginate: true,
      page: 1,
    }
    await dispatch(getAllInternalCentreNotes(params))
  }

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCreateNewNote = () => {
    setOpenNotePopUp(true)
    dispatch(RESET_INTERNAL_CENTRE_NOTE_SELECTED())
  }

  return (
    <div>
      {isLoading ? (
        <LoaderCircle size={50} />
      ) : (
        <div>
          <InternalCentreNotesTable setOpenNotePopUp={setOpenNotePopUp} />
          <div className={s.contButtons}>
            <CaluButton color="primary" size="medium" onClick={handleCreateNewNote}>
              Nueva nota
            </CaluButton>
          </div>
        </div>
      )}

      {openNotePopUp && (
        <InternalCentreNotePopUp
          open={openNotePopUp}
          setOpen={setOpenNotePopUp}
          dataNote={internalCentreNoteSelected}
        />
      )}
    </div>
  )
}

export default InfoInternalCentreNotes
