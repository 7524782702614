import React, { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import { CheckboxField } from '@/components/Form/customFields/CheckboxField'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { SelectField } from '@/components/Form/customFields/SelectField'
import CaluButton from '@/components/styledComponents/CaluButton'
import { billingConditions, extrasAmount, mercadoPagoStatus, subscriptionPaymentType, tax } from '@/helpers/constants'
import { handleCalculateFinalPrice } from '@/pages/AdminMainPage/components/functions/handleCalculateFinalPrice'
import { handleFindPlanPrice } from '@/pages/AdminMainPage/components/functions/handleFindPlanPrice'
import { fetchMercadoPagoSubscription, updateMercadoPagoSubscription } from '@/store/mercadoPago/actions'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import HelpIcon from '@mui/icons-material/Help'
import { Chip, Grid, IconButton, MenuItem, Tooltip } from '@mui/material'

import s from '../../../styles.module.scss'

export const FormContent = ({ isLoading = false, suggestedPrice, setSuggestedPrice, finalPrice, setFinalPrice }) => {
  const dispatch = useDispatch()

  const demoColor = '#ffea8a'
  const activeColor = '#63d2bc'
  const cancelledColor = '#ea9eb5'

  const { allSubscriptionPlans } = useSelector((state) => state?.subscriptionPlans)
  const { allPlanPrices } = useSelector((state) => state?.planPrices)
  const { centreAdminDetail } = useSelector((state) => state?.centres)

  const [openAlertDelete, setOpenAlertDelete] = useState(false)
  const [dataSubscription, setDataSubscription] = useState(null)
  const [loadingSubscription, setLoadingSubscription] = useState(false)

  const country = centreAdminDetail?.country || 'ARG'

  const subscriptionPlanWatch = useWatch({ name: 'centreConfiguration.subscriptionPlan' })
  const subscriptionAgendasQuantityWatch = useWatch({ name: 'centreConfiguration.subscriptionAgendasQuantity' })
  const extraAmountTypeWatch = useWatch({ name: 'centreConfiguration.subscriptionExtraAmount.type' })
  const extraAmountValueWatch = useWatch({ name: 'centreConfiguration.subscriptionExtraAmount.value' })
  const subscriptionPaymentTypeWatch = useWatch({ name: 'centreConfiguration.subscriptionPaymentType' })
  const paymentLinkWatch = useWatch({ name: 'paymentLink' })
  const taxWatch = useWatch({ name: 'centreConfiguration.tax' })

  const isMercadoPagoSubscription =
    centreAdminDetail?.centreConfiguration?.subscriptionPaymentType === 'mercadoPagoSubscription'

  const hasMPSubscriptionActive =
    centreAdminDetail?.centreConfiguration?.subscriptionPaymentType === 'mercadoPagoSubscription' &&
    subscriptionPaymentTypeWatch === 'mercadoPagoSubscription' &&
    dataSubscription?.status === 'authorized'

  const discriminatoryTax =
    extraAmountTypeWatch === 'finalPrice'
      ? Math.round((finalPrice - extraAmountValueWatch) * 100) / 100
      : Math.round((finalPrice - suggestedPrice) * 100) / 100

  const handleShowFinalPriceDetail = () => {
    if (extraAmountTypeWatch === 'finalPrice') {
      return `($${extraAmountValueWatch} + $${discriminatoryTax} IVA)`
    }
    return `($${suggestedPrice} + $${discriminatoryTax} IVA)`
  }

  const setInit = async () => {
    setLoadingSubscription(true)

    if (isMercadoPagoSubscription) {
      const subscriptionFetch = await dispatch(
        fetchMercadoPagoSubscription({
          centreId: centreAdminDetail?._id,
          mercadoPagoPreapprovalPlanId: centreAdminDetail?.centreConfiguration?.mercadoPagoPreapprovalPlanId,
        }),
      )

      if (subscriptionFetch) setDataSubscription(subscriptionFetch)
    }

    setLoadingSubscription(false)
  }

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (subscriptionPlanWatch && subscriptionAgendasQuantityWatch) {
      handleFindPlanPrice(
        allPlanPrices,
        country,
        subscriptionPlanWatch,
        subscriptionAgendasQuantityWatch,
        setSuggestedPrice,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionPlanWatch, subscriptionAgendasQuantityWatch, country])

  useEffect(() => {
    if (subscriptionPlanWatch && subscriptionAgendasQuantityWatch) {
      handleCalculateFinalPrice(suggestedPrice, extraAmountTypeWatch, extraAmountValueWatch, setFinalPrice, taxWatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraAmountTypeWatch, extraAmountValueWatch, suggestedPrice, taxWatch])

  const handleDisabledSaveButton = () => {
    if (subscriptionPaymentTypeWatch === 'paymentLink' && !paymentLinkWatch) return true

    if (
      subscriptionPaymentTypeWatch !== 'paymentLink' &&
      (!subscriptionPlanWatch || !subscriptionAgendasQuantityWatch || !finalPrice)
    )
      return true

    return false
  }

  const handleCancelSubscription = async () => {
    setLoadingSubscription(true)
    const cancelSubscription = {
      mercadoPagoPreapprovalPlanId: centreAdminDetail?.centreConfiguration?.mercadoPagoPreapprovalPlanId,
      body: {
        status: 'cancelled',
      },
    }
    try {
      const newDataSubscription = await dispatch(updateMercadoPagoSubscription(cancelSubscription))
      setDataSubscription(newDataSubscription)
      setLoadingSubscription(false)
    } catch (error) {
      console.log(error)
      setLoadingSubscription(false)
    }
  }

  const helpIcon = () => {
    return (
      <Tooltip
        title={'Para poder seleccionar otro medio de pago primero debe cancelar la suscripción activa'}
        placement="bottom-start"
      >
        <IconButton size="small">
          <HelpIcon style={{ fontSize: '16px' }} />
        </IconButton>
      </Tooltip>
    )
  }

  const getChipPropsMPStatus = (params) => {
    if (params === 'authorized') {
      return {
        label: convertValueToName(mercadoPagoStatus, params),
        style: {
          backgroundColor: activeColor,
        },
      }
    }

    if (params === 'pending') {
      return {
        label: convertValueToName(mercadoPagoStatus, params),
        style: {
          backgroundColor: demoColor,
        },
      }
    }

    if (['cancelled', 'paused'].includes(params)) {
      return {
        label: convertValueToName(mercadoPagoStatus, params),
        style: {
          backgroundColor: cancelledColor,
        },
      }
    }
  }

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p className={s.subTitle}>Datos de facturación</p>
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomField label="CUIT" name="centreConfiguration.billingNumber" fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomField label="Razón social" name="centreConfiguration.billingName" fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectField
            label="Condición frente al IVA"
            name="centreConfiguration.billingCondition"
            type="text"
            fullWidth
          >
            {billingConditions?.map((condition) => (
              <MenuItem key={condition.value} value={condition.value}>
                {condition.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectField label="% IVA" name="centreConfiguration.tax" type="text" fullWidth>
            {tax?.map((percentaje) => (
              <MenuItem key={percentaje.value} value={percentaje.value}>
                {percentaje.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        <Grid item xs={12}>
          <p className={s.subTitle}>Datos de la suscripción</p>
        </Grid>

        <Grid item xs={12} md={suggestedPrice ? 4 : 6}>
          <SelectField label="Plan" name="centreConfiguration.subscriptionPlan" type="text" fullWidth>
            {allSubscriptionPlans?.map((plan) => (
              <MenuItem key={plan._id} value={plan._id}>
                {plan.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12} md={suggestedPrice ? 4 : 6}>
          <CustomField
            label="Cantidad de agendas"
            name="centreConfiguration.subscriptionAgendasQuantity"
            type="number"
            fullWidth
          />
        </Grid>

        {suggestedPrice && (
          <Grid item xs={12} md={4}>
            <div className={s.firstPrice}>
              <span>Precio de lista:</span>{' '}
              {suggestedPrice === '-' ? (
                <span className={s.suggestedPrice}>No hay valor cargado</span>
              ) : (
                <span className={s.suggestedPrice}>
                  {handleSetMoneySign(country)} {suggestedPrice}
                </span>
              )}
            </div>
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <SelectField
            label="Tipo de extras o descuentos"
            name="centreConfiguration.subscriptionExtraAmount.type"
            type="text"
            fullWidth
          >
            {extrasAmount?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
            <MenuItem value={'finalPrice'}>Precio final</MenuItem>
          </SelectField>
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomField
            label="Cantidad fija de extras o descuentos"
            name="centreConfiguration.subscriptionExtraAmount.value"
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomField label="Motivo" name="centreConfiguration.subscriptionExtraAmount.description" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <CustomField label="Nota adicional" name="centreConfiguration.subscriptionNote" fullWidth multiline />
        </Grid>

        <Grid item xs={12}>
          <div className={s.contDataPayments}>
            <p className={s.subTitle}>Datos del pago</p>
            <span className={s.helpIcon}>{helpIcon()}</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <SelectField
            label="Medio de pago"
            disabled={hasMPSubscriptionActive}
            name="centreConfiguration.subscriptionPaymentType"
            type="text"
            fullWidth
          >
            {subscriptionPaymentType.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.name}
              </MenuItem>
            ))}
            {country === 'ARG' && (
              <MenuItem key={'mercadoPago'} value={'mercadoPagoSubscription'}>
                Suscripción Mercado pago
              </MenuItem>
            )}
          </SelectField>
        </Grid>

        <Grid item xs={12}>
          <CheckboxField label={`Paga anualmente`} name="centreConfiguration.isAnualSubscription" />
        </Grid>

        {subscriptionPaymentTypeWatch === 'paymentLink' && (
          <Grid item xs={12} container>
            <Grid item xs={12} className={s.contTypePaymentLink}>
              <CustomField label="Link de pago" name="paymentLink" fullWidth />
            </Grid>
            <Grid item xs={12} className={s.contTypePaymentLink}>
              <CustomField label="Descripción del pago" name="paymentDescription" fullWidth multiline />
            </Grid>
            <Grid item xs={12} className={s.contTypePaymentLink}>
              <CustomField label="Nota" name="paymentNote" fullWidth multiline />
            </Grid>
          </Grid>
        )}

        {finalPrice && (
          <Grid item xs={12}>
            <div className={s.finalPrice}>
              <span>Precio final:</span>{' '}
              <span className={s.suggestedPrice}>
                {handleSetMoneySign(country)} {finalPrice}
              </span>
              {centreAdminDetail?.country === 'ARG' && taxWatch !== '0' && <span>{handleShowFinalPriceDetail()}</span>}
            </div>
          </Grid>
        )}

        <Grid item xs={12} className={hasMPSubscriptionActive ? s.mainContainerButtons : s.mainContainerButtons2}>
          {isMercadoPagoSubscription && dataSubscription?.status && (
            <div>
              <span>Estado de suscripción:</span>{' '}
              <Chip variant="filled" size="small" {...getChipPropsMPStatus(dataSubscription?.status)} />
            </div>
          )}
          <div className={s.containerButtonsForm}>
            {hasMPSubscriptionActive ? (
              <div className={s.cancelSubscription}>
                <CaluButton
                  color="error"
                  size="medium"
                  loading={loadingSubscription}
                  onClick={() => setOpenAlertDelete(!openAlertDelete)}
                >
                  Cancelar suscripción
                </CaluButton>
                <AlertDialogSlide
                  open={openAlertDelete}
                  title={'Cancelar suscripción a Calu'}
                  setOpen={setOpenAlertDelete}
                  textContent={'¿Realmente quiere cancelar esta suscripción? Esta acción no se podrá restaurar.'}
                  textBackButton="Volver"
                  textConfirmationButton={'Si, cancelar'}
                  onClose={handleCancelSubscription}
                />
              </div>
            ) : (
              !hasMPSubscriptionActive &&
              isMercadoPagoSubscription &&
              dataSubscription?.status === 'cancelled' && (
                <span className={s.cancelledSubscriptionText}>Suscripción cancelada</span>
              )
            )}
            <CaluButton
              color="primary"
              type="submit"
              size="medium"
              loading={isLoading}
              disabled={handleDisabledSaveButton()}
            >
              Guardar
            </CaluButton>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
