import {
  deleteInternalCentreNotes,
  fetchAllInternalCentreNotes,
  postInternalCentreNotes,
  putInternalCentreNotes,
} from '@/services/internalCentreNotes'
import {
  FETCH_ALL_INTERNAL_CENTRE_NOTES,
  INTERNAL_CENTRE_NOTE_CREATED,
  INTERNAL_CENTRE_NOTE_DELETED,
  INTERNAL_CENTRE_NOTE_UPDATED,
  LOADED_INTERNAL_CENTRE_NOTE,
} from '@/store/internalCentreNotes'
import { showToast } from '@/utils/toast'

const getAllInternalCentreNotes = (params) => async (dispatch) => {
  dispatch(LOADED_INTERNAL_CENTRE_NOTE(true))
  try {
    let { docs: allInternalCentreNotes, ...paginationData } = await fetchAllInternalCentreNotes(params)
    dispatch(FETCH_ALL_INTERNAL_CENTRE_NOTES({ allInternalCentreNotes, paginationData }))
  } catch (error) {
    const { message } = error
    console.log(message)
  } finally {
    dispatch(LOADED_INTERNAL_CENTRE_NOTE(false))
  }
}

const createNewInternalCentreNote =
  (data, hideAlert = false) =>
  async (dispatch) => {
    try {
      const newNote = await postInternalCentreNotes(data)
      dispatch(INTERNAL_CENTRE_NOTE_CREATED(newNote))
      if (newNote && !hideAlert) {
        showToast('Nota agregada correctamente', 'success')
      }
      return newNote
    } catch (error) {
      const { message } = error
      console.log('Error message: ', message)
      showToast('Error al crear la nota', 'error')
    }
  }

const removeInternalCentreNote = (id) => async (dispatch) => {
  try {
    await deleteInternalCentreNotes(id)
    dispatch(INTERNAL_CENTRE_NOTE_DELETED(id))
    showToast('Nota eliminada', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateInternalCentreNote = (data, hideAlert) => async (dispatch) => {
  try {
    const updatedNote = await putInternalCentreNotes(data)
    dispatch(INTERNAL_CENTRE_NOTE_UPDATED(updatedNote))
    if (updatedNote && !hideAlert) {
      showToast('Nota actualizada correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const handlePaginationChangeInternalCentreNotes = (newPage, newLimit) => async (dispatch, getState) => {
  const { centreAdminDetail } = getState().centres
  const params = {
    centre: centreAdminDetail?._id,
    page: newPage,
    limit: newLimit,
    paginate: true,
  }

  dispatch(LOADED_INTERNAL_CENTRE_NOTE(true))
  await dispatch(getAllInternalCentreNotes(params))
  dispatch(LOADED_INTERNAL_CENTRE_NOTE(false))
}

export {
  createNewInternalCentreNote,
  getAllInternalCentreNotes,
  handlePaginationChangeInternalCentreNotes,
  removeInternalCentreNote,
  updateInternalCentreNote,
}
