import i18next from '@/configs/i18next'
import { showToast } from '@/utils/toast'

const adapterTurns = async (allTurns = []) => {
  if (!allTurns.length) {
    showToast(`No hay ${i18next.t('turnLabel.p', { ns: 'global' })} para imprimir`, 'warning')
    return false
  }

  let auxAllTurns = [...allTurns]

  const filterCancelledTurns = auxAllTurns?.filter((turn) => turn.state !== 'cancelled')
  const sortTurns = filterCancelledTurns.sort((a, b) => (a.startHour > b.startHour ? 1 : -1))

  const groupBy = (allTurns) => {
    return allTurns.reduce((result, turn) => {
      if (turn.doctorCentre && !turn.machine) {
        ;(result[turn['doctorCentre']['_id']] = result[turn['doctorCentre']['_id']] || []).push(turn)
      }

      if (turn.machine) {
        ;(result[turn['machine']['_id']] = result[turn['machine']['_id']] || []).push(turn)
      }

      return result
    }, {})
  }
  return groupBy(sortTurns)
}

export default adapterTurns
