import React from 'react'
import { useDispatch } from 'react-redux'
import { RESET_PLAN_PRICES_SELECTED } from '@/store/planPrices'
import { Button, Grid } from '@mui/material'

import s from './styles.module.scss'

const PlanPricesSearchBar = ({ setOpen }) => {
  const dispatch = useDispatch()

  const handleOpenPopUp = () => {
    setOpen(true)
    dispatch(RESET_PLAN_PRICES_SELECTED())
  }

  return (
    <Grid
      container
      spacing={1}
      className={s.mainContainer}
      sx={{
        '& .MuiGrid-root.MuiGrid-item': {
          padding: '0px',
          paddingTop: '5px',
        },
        '& .MuiInput-underline:before': {
          borderBottomColor: '#ffffff8f',
        },

        '& .MuiInput-underline:after': {
          borderBottomColor: '#009688', // Solid underline on focus
        },
      }}
    >
      <Grid item xs={12}>
        <Button className={s.buttonNewEntry} onClick={handleOpenPopUp}>
          Nuevo precio de plan
        </Button>
      </Grid>
    </Grid>
  )
}

export default PlanPricesSearchBar
