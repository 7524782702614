import { fetchDoctorCentre } from '@/services/doctorCentres'
import { fetchOnePatient } from '@/services/patients'
import { fetchSingleSecretaryCentre } from '@/services/secretaryCentre'
import {
  CENTRE_SELECTED,
  DOCTOR_PROFILE_SELECTED,
  PATIENT_SELECTED,
  RESET_ENTITY_SELECTED,
  RESET_STATE,
  SECRETARY_SELECTED,
} from '@/store/myProfile'

const getCentreSelected = (centre) => async (dispatch) => {
  try {
    await dispatch(RESET_ENTITY_SELECTED())
    dispatch(CENTRE_SELECTED(centre))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const getDoctorDetailAndSelected = (doctorId) => async (dispatch) => {
  try {
    const doctorCentre = await fetchDoctorCentre(doctorId)
    await dispatch(RESET_ENTITY_SELECTED())
    dispatch(DOCTOR_PROFILE_SELECTED(doctorCentre))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const getSecretaryDetailAndSelected = (secretaryId) => async (dispatch) => {
  try {
    const secretaryCentre = await fetchSingleSecretaryCentre(secretaryId)
    await dispatch(RESET_ENTITY_SELECTED())
    dispatch(SECRETARY_SELECTED(secretaryCentre))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const getPatientDetailAndSelected = (patientId) => async (dispatch) => {
  try {
    const singlePatient = await fetchOnePatient(patientId)
    await dispatch(RESET_ENTITY_SELECTED())
    dispatch(PATIENT_SELECTED(singlePatient))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetMyProfileState = () => async (dispatch) => {
  try {
    dispatch(RESET_STATE())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export {
  getCentreSelected,
  getDoctorDetailAndSelected,
  getPatientDetailAndSelected,
  getSecretaryDetailAndSelected,
  resetMyProfileState,
}
