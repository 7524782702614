export const removeWrongData = (machine) => {
  if (!machine) return {}
  // to force deep copy
  let auxMachine = JSON.parse(JSON.stringify(machine))

  // remove invalid workTimes
  if (auxMachine?.workTimes) {
    auxMachine.workTimes = auxMachine.workTimes.filter((wt) => {
      const startDay = wt?.frequency?.startDay?.toLowerCase()
      return startDay !== 'invalid date'
    })
  }

  // sort noWorkTimes
  if (auxMachine?.noWorkTimes) {
    auxMachine.noWorkTimes = auxMachine.noWorkTimes.sort((a, b) => {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
    })
  }

  return auxMachine
}
