import React, { useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { TextOverButtons } from '@/components/TextOverButtons'
import { paymentMethods } from '@/helpers/constants'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const AddPaymentsModule = ({ paymentHistory, setPaymentHistory, disabled, setDebt }) => {
  const [date, setDate] = useState(dayjs().utc(true).format())
  const [amount, setAmount] = useState('')
  const [method, setMethod] = useState('ARScash')
  const [error, setError] = useState(false)

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const handleResetStates = () => {
    setDate(dayjs().utc().format())
    setAmount('')
    setMethod('ARScash')
  }

  const handleAddPayment = () => {
    let errorFlag = false
    if (amount === '' || date === '') {
      setError(true)
      errorFlag = true
    }

    if (amount !== '' && date !== '') {
      setError(false)
      errorFlag = false
    }

    let payment = {
      date,
      amount: Number(amount),
      method,
    }

    if (!errorFlag) {
      setPaymentHistory([...paymentHistory, payment])
      setDebt((prev) => prev - payment.amount)
      handleResetStates()
    }
  }

  const addPropWhenClick = (i) => {
    let auxData = [...paymentHistory]
    auxData[i] = {
      ...auxData[i],
      showConfirm: true,
    }
    setPaymentHistory(auxData)
  }

  const deleteItemPaymentHistory = (itemToDelete) => {
    let paymentItem = paymentHistory.filter((index) => index !== itemToDelete)
    setPaymentHistory(paymentItem)
    setDebt((prev) => prev + itemToDelete.amount)
  }

  const removePropWhenClick = (i) => {
    let auxData = [...paymentHistory]
    auxData[i] = {
      ...auxData[i],
      showConfirm: false,
    }
    setPaymentHistory(auxData)
  }

  return (
    <div>
      <div className={s.containerSubtitle}>
        <p className={s.subtitle}>Agregar pago</p>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3.5}>
          <FormControl fullWidth variant="standard">
            <>
              <DatePicker
                label="Fecha *"
                value={dayjs(date)}
                format="DD/MM/YYYY"
                onChange={(newDate) => {
                  setDate(dayjs(newDate).utc().format())
                }}
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
              />
              {error && date === '' && <p className={s.errorMsg}>Debe agregar una fecha</p>}
            </>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3.5}>
          <>
            <TextField
              label={`${handleSetMoneySign(country)} Monto pagado *`}
              value={amount}
              type="number"
              onChange={(e) => setAmount(e.target.value)}
              fullWidth
              variant="standard"
              onWheel={(e) => e.target.blur()}
            />
            {error && amount === '' && <p className={s.errorMsg}>Debe agregar un pago</p>}
          </>
        </Grid>

        <Grid item xs={12} md={3.5}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>Medio de pago</InputLabel>
            <Select onChange={(e) => setMethod(e.target.value)} defaultValue={'ARScash'}>
              {paymentMethods?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={1.5} className={s.containerButton}>
          <Button onClick={handleAddPayment} disabled={disabled} className={s.addButton}>
            Agregar
          </Button>
        </Grid>
      </Grid>

      {paymentHistory?.length > 0 && (
        <div className={s.containerPayments}>
          <p className={s.subtitle}>Pagos realizados</p>
          <ul>
            {paymentHistory?.map((payment, i) => (
              <li key={i}>
                <span className={s.boldClass}>{toCustomTz(payment?.date, undefined, true, 'DD/MM/YYYY')}</span>{' '}
                {handleSetMoneySign(country)} {payment?.amount} ({convertValueToName(paymentMethods, payment?.method)})
                {payment.showConfirm ? (
                  <div className={s.containerButtonsDelete}>
                    <TextOverButtons title="Si, eliminar">
                      <div className={s.btnsDeleteIcons}>
                        <IoIosCheckmarkCircleOutline onClick={() => deleteItemPaymentHistory(payment)} />
                      </div>
                    </TextOverButtons>
                    <TextOverButtons title="No, cancelar">
                      <div className={s.btnsCancelIcons}>
                        <IoIosCloseCircleOutline onClick={() => removePropWhenClick(i)} />
                      </div>
                    </TextOverButtons>
                  </div>
                ) : (
                  <TextOverButtons title="Eliminar">
                    <Button onClick={() => addPropWhenClick(i)} className={s.trashIcon}>
                      <BsTrash />
                    </Button>
                  </TextOverButtons>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default AddPaymentsModule
