import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchCentreMercadoPagoInfo = async (centreId) => {
  const route = `api/centreMercadoPagoInfo/${centreId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteCentreMercadopagoInfo = async (centreMercadoPagoInfoId) => {
  const route = `api/centreMercadoPagoInfo/${centreMercadoPagoInfoId}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const refreshMercadoPagoToken = async (centreId) => {
  const route = `api/mercadoPago/refreshToken/${centreId}`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { deleteCentreMercadopagoInfo, fetchCentreMercadoPagoInfo, refreshMercadoPagoToken }
