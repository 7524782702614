import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import HolidaysModule from '@/components/holidaysModule'
import WorkingHoursModule from '@/components/workingHoursModule'
import TurnsWithoutDate from '@/pages/AgendaPage/components/turnsWithoutDate'
import { updateMachine } from '@/store/machines/actions'
import { directionOfAnimation } from '@/utils/functions/directionOfAnimation'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import PropTypes from 'prop-types'
import PersonalInformation from './components/personalInformation'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const FormCreateMachine = ({ handleClose, entity }) => {
  const { t } = useTranslation('global')
  let editFromCalendar = document.getElementById('Edit from calendar')

  const [valuePanel, setValuePanel] = useState(entity && editFromCalendar ? 1 : 0)
  const [prevValuePanel, setPrevValuePanel] = useState(0)

  const machineSelected = useSelector((state) => state.machines.machineSelected) || entity

  const handleChangePanel = (event, newValue) => {
    setPrevValuePanel(valuePanel)
    setValuePanel(newValue)
  }

  const addWorkTimes = () => {
    setValuePanel(1)
  }

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={valuePanel}
            onChange={handleChangePanel}
            aria-label="Formulario de creación de agenda"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              '& .MuiTab-root.Mui-selected': {
                color: '#009688',
              },
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            <Tab label="Información personal" />
            <Tab disabled={machineSelected ? false : true} label="Horarios de atención" />
            <Tab disabled={machineSelected ? false : true} label="Bloqueo de calendario" />
            <Tab disabled={machineSelected ? false : true} label={`Ver ${t('turnLabel.p')} sin fecha`} />
          </Tabs>
        </Box>
      </Box>

      <TabPanel value={valuePanel} index={0}>
        <PersonalInformation entity={machineSelected} handleClose={handleClose} addWorkTimes={addWorkTimes} />
      </TabPanel>

      <TabPanel value={valuePanel} index={1}>
        <WorkingHoursModule
          entity={machineSelected}
          updateFunction={updateMachine}
          directionOfAnimation={directionOfAnimation(valuePanel, prevValuePanel)}
        />
      </TabPanel>

      <TabPanel value={valuePanel} index={2}>
        <HolidaysModule
          entity={machineSelected}
          updateFunction={updateMachine}
          directionOfAnimation={directionOfAnimation(valuePanel, prevValuePanel)}
        />
      </TabPanel>

      <TabPanel value={valuePanel} index={3}>
        <TurnsWithoutDate entity={machineSelected} />
      </TabPanel>
    </div>
  )
}

export default FormCreateMachine
