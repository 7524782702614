import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'clinicHistoryFragments',
  initialState: {
    allHCFragments: [],
    selectedFragment: null,
    paginationData: {
      page: 1,
      limit: 30,
    },
    isLoading: false,
  },
  reducers: {
    FETCH_HC_FRAGMENTS: (state, { payload }) => {
      state.allHCFragments = payload.allHCFragments
      state.paginationData = payload.paginationData
    },
    HC_FRAGMENT_UPDATED: (state, { payload }) => {
      state.allHCFragments = state.allHCFragments.map((fragment) => {
        if (fragment._id === payload._id) {
          fragment = payload
        }
        return fragment
      })
      state.selectedFragment = payload
    },
    HC_FRAGMENT_DELETE: (state, { payload }) => {
      state.allHCFragments = state.allHCFragments.filter((fragment) => fragment._id !== payload)
      state.selectedFragment = null
    },
    HC_FRAGMENT_CREATED: (state, { payload }) => {
      state.allHCFragments = [...(state.allHCFragments || []), payload]
      state.selectedFragment = payload
    },
    HC_FRAGMENT_SELECTED: (state, { payload }) => {
      state.selectedFragment = payload
    },
    RESET_HC_FRAGMENT_SELECTED: (state, { payload }) => {
      state.selectedFragment = null
    },
    LOADING_FRAGMENTS: (state, { payload }) => {
      state.isLoading = payload
    },
  },
})

export const {
  FETCH_HC_FRAGMENTS,
  HC_FRAGMENT_UPDATED,
  HC_FRAGMENT_DELETE,
  HC_FRAGMENT_CREATED,
  HC_FRAGMENT_SELECTED,
  RESET_HC_FRAGMENT_SELECTED,
  LOADING_FRAGMENTS,
} = slice.actions
export default slice.reducer
