import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { IconButton } from '@mui/material'

import s from './styles.module.scss'

const WhatsappIconButton = ({ countryCode, numberMobile }) => {
  return (
    <a href={`https://wa.me/${countryCode}${numberMobile}`} target="_blank" rel="noopener noreferrer">
      <IconButton className={s.iconButton}>
        <WhatsAppIcon className={s.whatsappIcon} />
      </IconButton>
    </a>
  )
}

export default WhatsappIconButton
