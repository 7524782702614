import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectWithInput from '@/components/SelectWithInput'
import { selectedEntity } from '@/store/changeViewCalendar/actions'
import { Grid, TextField } from '@mui/material'

import AgendaNavBarButtons from './AgendaNavBarButtons'

import s from '../../styles.module.scss'

export default function AgendaNavbar({ entity, inputValue, inputOnChange, inputLabel }) {
  const dispatch = useDispatch()

  const { dailyView, entitySelected, calendarEntities } = useSelector((state) => state.changeViewCalendar)

  const handleChangeEntitySelected = (e) => {
    let newEntityIdSelected = e.target.value
    let auxCalendarEntities = [...calendarEntities]
    let newEntity = auxCalendarEntities.filter((entity) => entity._id === newEntityIdSelected)

    if (newEntity) {
      dispatch(selectedEntity(newEntity[0]))
    }
  }

  return (
    <Grid item sm={12} className={s.titleContainer}>
      {dailyView ? (
        inputOnChange && (
          <TextField
            id="search"
            size="small"
            autoComplete="off"
            label={inputLabel}
            variant="outlined"
            className={s.filterEntityInput}
            type="text"
            value={inputValue}
            onChange={inputOnChange}
            sx={{ maxWidth: '500px' }}
          />
        )
      ) : (
        <div className={s.scheduleSelected}>
          <SelectWithInput
            valueSelect={entitySelected?._id}
            options={calendarEntities}
            onChange={handleChangeEntitySelected}
            className={s.selectAgendaInput}
            variant="standard"
          />
        </div>
      )}
      <AgendaNavBarButtons entity={entity} />
    </Grid>
  )
}
