import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CaluAutoComplete } from '@/components/styledComponents/CaluAutoComplete'
import CaluButton from '@/components/styledComponents/CaluButton'
import TitlePopUp from '@/components/TitlePopUp'
import { getCentreByInputSearch } from '@/store/centres/actions'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import s from './styles.module.scss'

const RequestNewTurn = ({ allCentres, open, handleClose }) => {
  const { t } = useTranslation('global')

  const [searchText, setSearchText] = useState('')

  return (
    <Dialog disableEscapeKeyDown={true} maxWidth="md" open={open} fullWidth onClose={handleClose}>
      <DialogTitle className={s.dialogTitle}>
        <TitlePopUp textTitle={`Nueva ${t('turnLabel.s')}`} handleClose={handleClose} />
      </DialogTitle>
      <DialogContent className={`${s.mainContainer} animate__animated animate__fadeIn`}>
        <h3>¿En qué centro de salud quieres reservar {t('turnLabel.sa')}?</h3>
        <div className={s.containerSelect}>
          <CaluAutoComplete
            isform={'true'}
            inputlabel="Centro de Salud"
            onChange={(e) => setSearchText(e._id)}
            stateredux={allCentres}
            functiontodispatch={getCentreByInputSearch}
          />
        </div>

        {searchText && (
          <div className={s.containerButton}>
            <Link className={s.link} to={`/centro/${searchText}`}>
              <CaluButton color="primary" size="large" type="link">
                Ver disponibilidad de {t('turnLabel.p')}
              </CaluButton>
            </Link>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default RequestNewTurn
