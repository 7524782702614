export const handleTimeConvert = (duration = 60) => {
  let hours = duration / 60
  let rhours = Math.floor(hours)
  let minutes = (hours - rhours) * 60
  let rminutes = Math.round(minutes)
  let totalHours, totalMinutes

  if (rhours < 10) {
    totalHours = `0${rhours}`
  } else {
    totalHours = rhours
  }

  if (rminutes < 10) {
    totalMinutes = `0${rminutes}`
  } else {
    totalMinutes = rminutes
  }

  return `${totalHours}:${totalMinutes}:00`
}
