import { toLocalTz } from '@/utils/functions/toLocalTz'
import { DatePicker } from '@mui/x-date-pickers'

import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { STextField } from './styles'

import s from './styles.module.scss'

const CaluRawDatePicker = ({ labelText, ...rest }) => {
  const date = rest.value
  const parsedDate = dayjs(date)?.isUTC() ? dayjs(toLocalTz(date)) : dayjs(date)

  const getSvgColor = (isDisabled) => (isDisabled ? 'inherit' : '#0f1333')

  return (
    <div>
      {labelText && <div className={s.titleInput}>{labelText}</div>}
      <div className={s.defaultStyles}>
        <DatePicker
          {...rest}
          disabled={rest.disabled}
          format="DD/MM/YYYY"
          onChange={(newDate) => {
            if (newDate && dayjs(newDate).isValid()) rest.onChange(dayjs(newDate))
          }}
          value={parsedDate}
          slots={{
            textField: STextField,
          }}
          slotProps={{
            textField: {
              helperText: Boolean(rest?.error) && rest?.error?.message,
              sx: {
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '2px solid #0f1333',
                  },
                  '&:hover fieldset': {
                    borderColor: getSvgColor(rest?.disabled),
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#0f1333',
                  },
                  '& .MuiInputBase-input': {
                    height: '8px',
                  },
                  svg: { color: getSvgColor(rest?.disabled) },
                },
              },
            },
          }}
        />
      </div>
    </div>
  )
}

export default CaluRawDatePicker

CaluRawDatePicker.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  labelText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  minDate: PropTypes.object,
  disableHighlightToday: PropTypes.bool,
}

CaluRawDatePicker.defaultProps = {
  fullWidth: false,
  initValue: null,
}
