import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import { PageHearedTitle } from '@/components/pages'
import { setCurrentCentre } from '@/controllers/centres'
import useValidateURLId from '@/hooks/useValidateURLId'
import BaseSideBar from '@/layouts/Layout1/components/BaseSideBar'
import ContentSidebar from '@/pages/RoomsPage/components/contentSideBar'
import { getCurrentCentre } from '@/store/centres/actions'
import { closeAllRooms, closeSingleRoom, deleteRoomAdded, getAllRooms, resetSelectedRoom } from '@/store/rooms/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { Button, Paper } from '@mui/material'

import PopUpRoom from './components/popUpRoom'
import Layout1 from '../../layouts/Layout1/index'

import s from './styles.module.scss'

const RoomsPage = () => {
  const dispatch = useDispatch()
  const centreID = useValidateURLId()

  const [open, setOpen] = useState(false)
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [openAlertCloseRoom, setOpenAlertCloseRoom] = useState(false)
  const [openAlertCloseAllRooms, setOpenAlertCloseAllRooms] = useState(false)
  const [openAlertDeleteRoom, setOpenAlertDeleteRoom] = useState(false)

  const [roomsFiltered, setRoomsFiltered] = useState([])

  const allRooms = useSelector((state) => state.rooms?.allRooms)

  const roomSelected = useSelector((state) => state.rooms?.roomSelected)

  const setInit = async () => {
    const currentCentre = await setCurrentCentre(centreID)
    await dispatch(getCurrentCentre(currentCentre))
    await dispatch(resetSelectedRoom())
    await dispatch(getAllRooms(centreID))
  }

  useEffect(() => {
    setInit()
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filteredRooms = allRooms?.filter((room) =>
      room?.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .includes(
          input
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
        ),
    )
    setRoomsFiltered(filteredRooms)
  }, [allRooms, input])

  const addNewRoom = () => {
    dispatch(resetSelectedRoom())
    setOpen(!open)
  }

  const modifyRoom = () => {
    setOpen(!open)
  }

  const deleteRoom = () => {
    dispatch(deleteRoomAdded(roomSelected[0]._id))
  }

  const handleChange = (e) => {
    setInput(e.target.value)
  }

  const closeSingleRoomSelected = () => {
    dispatch(closeSingleRoom(roomSelected[0]._id))
    dispatch(resetSelectedRoom())
  }

  const closeAllRoomsSelected = () => {
    dispatch(closeAllRooms())
    dispatch(resetSelectedRoom())
  }

  const waitingRoomView = () => {
    window.open(`/centro/${centreID}/sala-de-espera`)
  }

  return (
    <Layout1
      navbar
      isLoading={isLoading}
      leftSidebar={
        <BaseSideBar
          inputLabel={'Buscar espacio'}
          inputValue={input}
          inputOnChange={handleChange}
          buttonLabel={'Nuevo espacio'}
          buttonLabel2={'vista sala de espera'}
          buttonOnClick={addNewRoom}
          buttonOnClick2={waitingRoomView}
          content={<ContentSidebar listItem={roomsFiltered} allRooms={allRooms} />}
        />
      }
    >
      <div className={s.mainContainer}>
        <div className={s.contentContainer}>
          <PageHearedTitle titleText="Consultorios" />

          {roomSelected !== null ? (
            <Paper elevation={3} className={`${s.cardContainer} animate__animated animate__fadeIn`}>
              <div className={s.mainViewContent}>
                <div className={s.leftContent}>
                  <div className={s.titleRoom}>{capitalizeFirstLetters(roomSelected[0]?.name)}</div>
                  <div className={s.containerInfoRoomSelected}>
                    {roomSelected[0]?.people?.length > 0 ? (
                      <p className={s.doctorName}>{roomSelected[0]?.people[0]?.name?.toUpperCase()} asignado</p>
                    ) : (
                      <p className={s.doctorName}>NADIE asignado</p>
                    )}
                    <div className={s.containerButtonsInfoSelected}>
                      <Button
                        variant="contained"
                        disableElevation
                        className={s.btnCloseRoom}
                        onClick={() => setOpenAlertCloseRoom(!openAlertCloseRoom)}
                      >
                        Cerrar espacio
                      </Button>
                      <AlertDialogSlide
                        open={openAlertCloseRoom}
                        setOpen={setOpenAlertCloseRoom}
                        textContent={'¿Esta seguro que quiere cerrar este espacio?'}
                        textBackButton="Cancelar"
                        textConfirmationButton={'Confirmar'}
                        onClose={closeSingleRoomSelected}
                      />
                      <Button
                        variant="outlined"
                        disableElevation
                        className={s.btnCloseAllRooms}
                        onClick={() => setOpenAlertCloseAllRooms(!openAlertCloseAllRooms)}
                      >
                        Cerrar todos los espacios
                      </Button>
                      <AlertDialogSlide
                        open={openAlertCloseAllRooms}
                        setOpen={setOpenAlertCloseAllRooms}
                        textContent={'¿Esta seguro que quiere cerrar todos los espacios?'}
                        textBackButton="Cancelar"
                        textConfirmationButton={'Confirmar'}
                        onClose={closeAllRoomsSelected}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={s.rightContent}>
                <div className={s.containerButtons}>
                  <Button
                    fullWidth={true}
                    disableElevation
                    variant="contained"
                    className={s.editButton}
                    onClick={modifyRoom}
                  >
                    Modificar
                  </Button>
                  <div>
                    <Button
                      variant="contained"
                      disableElevation
                      className={s.deleteButton}
                      onClick={() => setOpenAlertDeleteRoom(!openAlertDeleteRoom)}
                    >
                      Eliminar
                    </Button>
                    <AlertDialogSlide
                      open={openAlertDeleteRoom}
                      setOpen={setOpenAlertDeleteRoom}
                      textContent={'¿Esta seguro que quiere eliminar este espacio?'}
                      textBackButton="Cancelar"
                      textConfirmationButton={'Confirmar'}
                      onClose={deleteRoom}
                    />
                  </div>
                </div>
              </div>
            </Paper>
          ) : (
            <Paper elevation={3} className={`${s.cardContainer} animate__animated animate__fadeIn`}>
              <p className={s.message}>Seleccione un espacio del listado para ver su información</p>
            </Paper>
          )}
        </div>

        <PopUpRoom open={open} setOpen={setOpen} />
      </div>
    </Layout1>
  )
}

export default RoomsPage
