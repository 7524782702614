import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import { setCurrentCentre } from '@/controllers/centres'
import useDebounce from '@/hooks/useDebounce'
import useValidateURLId from '@/hooks/useValidateURLId'
import Layout1 from '@/layouts/Layout1'
import ConfigPatientTags from '@/pages/PerfilPage/components/detailProfile/components/configPatientTags'
import { getCurrentCentre } from '@/store/centres/actions'
import { getDoctorCentres } from '@/store/doctorCentres/actions'
import { getPatientsCentreByInputSearch, resetSelectedPatientCentre } from '@/store/patientCentres/actions'
import { getAllPatientTags } from '@/store/patientTags/actions'
import { getPatientCentreTurns } from '@/store/turns/actions'
import { getWappMessageTemplates } from '@/store/wappMessageTemplates/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'

import MainPopUp from './components/mainPopUp'
import PatientsSearchBar from './components/patientsSearchBar'
import PatientsTable from './components/patientsTable'

import s from './styles.module.scss'

const PatientCentresPage = () => {
  const dispatch = useDispatch()
  const centreID = useValidateURLId()
  const { t } = useTranslation('global')

  const DELAY_TIME = 300
  const MIN_CHARACTERS = 0
  const initialBirthObj = {
    birthStart: '',
    birthFinish: '',
  }

  const [searchText, setSearchText] = useState('')
  const [selectedTag, setSelectedTag] = useState('-')
  const [selectedBirth, setSelectedBirth] = useState(initialBirthObj)
  const [birthSelectValue, setBirthSelectValue] = useState('-')
  const [open, setOpen] = useState(false)
  const [loadingPatientCentres, setLoadingPatientCentres] = useState(false)
  const [openPopUpTags, setOpenPopUpTags] = useState(false)

  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const patientCentresTurns = useSelector((state) => state.turns.patientCentresTurns)
  const patientCentres = useSelector((state) => state.patientCentres.patientCentres)
  const patientSelected = useSelector((state) => state.patientCentres?.patientSelected)
  const params = {
    centre: centreID,
  }

  const getPatientBySearch = async () => {
    const params = {
      centre: centreID,
      search: inputDebounceValue ? inputDebounceValue : null,
      patientTag: selectedTag === '-' ? null : selectedTag,
      birthStart: selectedBirth?.birthStart ? selectedBirth?.birthStart : null,
      birthFinish: selectedBirth?.birthFinish ? selectedBirth?.birthFinish : null,
    }

    setLoadingPatientCentres(true)
    await dispatch(getPatientsCentreByInputSearch(params))
    setLoadingPatientCentres(false)
  }

  useEffect(() => {
    const inputSearch = async () => {
      if (inputDebounceValue?.length >= MIN_CHARACTERS) {
        getPatientBySearch()
      }
    }
    inputSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDebounceValue, selectedTag, birthSelectValue])

  useEffect(() => {
    const setInit = async () => {
      const currentCentre = await setCurrentCentre(centreID)
      dispatch(getCurrentCentre(currentCentre))
      dispatch(getAllPatientTags(centreID))
      dispatch(getWappMessageTemplates(params))
    }
    setInit()
    dispatch(getDoctorCentres(centreID))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centreID])

  useEffect(() => {
    if (patientSelected) {
      dispatch(getPatientCentreTurns(centreID, patientSelected?._id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSelected])

  const addNewPatient = () => {
    dispatch(resetSelectedPatientCentre())
    setOpen(!open)
  }

  return (
    <Layout1 navbar>
      <div className={`${s.animatedContainer} animate__animated animate__fadeIn`}>
        <PatientsSearchBar
          addNewPatient={addNewPatient}
          inputSearch={searchText}
          setInputSearch={setSearchText}
          setOpenPopUpTags={setOpenPopUpTags}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
          setSelectedBirth={setSelectedBirth}
          birthSelectValue={birthSelectValue}
          setBirthSelectValue={setBirthSelectValue}
        />
        {loadingPatientCentres ? (
          <LoaderCircle size={50} />
        ) : patientCentres?.length === 0 ? (
          <p className={s.noItems}>
            No hay {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`)} cargados aún
          </p>
        ) : (
          <PatientsTable
            setOpen={setOpen}
            searchText={searchText}
            selectedTag={selectedTag}
            selectedBirth={selectedBirth}
          />
        )}
      </div>

      <MainPopUp
        open={open}
        patientSelected={patientSelected}
        setOpen={setOpen}
        patientCentresTurns={patientCentresTurns}
      />

      {openPopUpTags && (
        <ConfigPatientTags openConfigPatientTags={openPopUpTags} setOpenConfigPatientTags={setOpenPopUpTags} />
      )}
    </Layout1>
  )
}

export default PatientCentresPage
