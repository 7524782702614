import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import sessionLogo from '@/assets/images/session.png'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import { useSocket } from '@/context/SocketProvider'
import { updatePatientTreatment } from '@/store/treatments/actions'
import { updateATurn } from '@/store/turns/actions'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import Button from '@mui/material/Button'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const TreatmentItemDetail = ({ turn, treatment, index, handleClose, setLoading }) => {
  const dispatch = useDispatch()
  const { socket } = useSocket()
  const dateTurn = dayjs(turn?.startHour).utc().format('dddd DD/MM/YYYY HH:mm')
  const [deleteAlert, setDeleteAlert] = useState(false)
  const selectedTurn = useSelector((state) => state.turns.selectedTurn)

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const checkStateTurn = () => {
    if (turn.state === 'finished') return <b className={s.colorFinished}>FINALIZADO</b>
    if (turn.state === 'cancelled') return <b className={s.colorCancelled}>CANCELADO</b>
    if (turn.state === 'init') return <b className={s.colorInit}>PROGRAMADO</b>
  }

  const showNumberSession = () => {
    const turnsCount = treatment?.turnsCount
    const sessionNumber = treatment?.turns?.findIndex((e) => e._id === turn._id) + 1
    return `sesion ${sessionNumber} de ${turnsCount}`
  }

  const showTagColor = () => {
    if (turn.state === 'finished') return '#34495E'
    if (turn.state === 'cancelled') return '#ED5564'
    if (turn.state === 'init') return '#F8AC59'
  }

  const showturnService = () => {
    if (turn?.service) return `(${turn.service.name})`
    if (!turn?.service && turn?.firstTime === false) return '(Consulta)'
    if (!turn?.service && turn?.firstTime === true) return '(Primera consulta)'
  }

  const deleteSession = async () => {
    const filteredTurns = treatment.turns.filter((t) => t._id !== turn._id)
    const hideAlert = true

    const auxTreatment = {
      ...treatment,
      turns: filteredTurns,
    }

    const auxTurn = {
      ...turn,
      state: 'cancelled',
      patientCentre: treatment.patientCentre,
      cancelledInfo: 'doctor',
      treatment: null,
    }
    try {
      const updatedTurn = await dispatch(updateATurn(auxTurn, hideAlert))
      dispatch(updatePatientTreatment(auxTreatment))
      socket.emit('calendar_edit_turn', `${updatedTurn.centre}_calendar`, updatedTurn)
      if (selectedTurn) {
        if (updatedTurn?._id === selectedTurn._id) {
          setLoading(true)
          handleClose()
          setLoading(false)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const entityName = () => {
    if (turn?.doctorCentre?.lastname)
      return turn.doctorCentre.lastname.toUpperCase() + ', ' + turn.doctorCentre.firstname.toUpperCase()
    if (turn?.machine?.name) return turn.machine.name.toUpperCase()
  }

  return (
    <>
      <div className={s.divDetail} style={{ borderLeft: `15px solid ${showTagColor()}` }}>
        <div className={s.div2}>
          <div className={s.divImage}>
            <img src={sessionLogo} alt="" />
          </div>
          <div className={s.treatmentDetail}>
            <div>
              {showNumberSession()} - <b>{checkStateTurn()}</b> - pagado en esta sesion {handleSetMoneySign(country)}{' '}
              {turn?.totalPayments}
            </div>
            <div>{`${entityName()} ${showturnService()}`}</div>
            <b>{dateTurn} hs</b>
          </div>
        </div>

        <Button size="small" className={s.buttonX} onClick={() => setDeleteAlert(true)}>
          x
        </Button>
      </div>
      <AlertDialogSlide
        open={deleteAlert}
        title="Eliminar sesión"
        setOpen={setDeleteAlert}
        textContent={'¿Realmente quiere eliminar esta sesión? Se marcará como cancelada.'}
        textBackButton="Cancelar"
        textConfirmationButton={'Eliminar'}
        onClose={deleteSession}
      />
    </>
  )
}

export default TreatmentItemDetail
