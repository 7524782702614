import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import PropTypes from 'prop-types'
import { SLoadingButton } from './styles'

const CaluButton = (props, ref) => {
  return (
    <SLoadingButton {...props} ref={ref} loading={props.loading}>
      {props?.type === 'add' ? <AddIcon /> : null}
      {props?.children}
      {props?.type === 'link' ? <KeyboardArrowRightIcon /> : null}
    </SLoadingButton>
  )
}

export default React.forwardRef(CaluButton)

CaluButton.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  inverted: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
}
