import React from 'react'

import Papa from 'papaparse'

import s from '../styles.module.scss'

const ImportFilesComponent = ({ keysOptions, csvValues, setCsvValues, doneImportation }) => {
  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const valuesArray = results.data.map((d) => Object.values(d))

        setCsvValues(valuesArray)
      },
    })
  }

  return (
    <div className={s.contImportFiles}>
      <p>
        Seleccione un archivo <span className={s.boldClass}>.CSV</span> que tenga las siguientes columnas en el orden
        solicitado.
      </p>
      <div className={s.squareOptions}>
        <p>{keysOptions.csvColumns.reduce((previous, current) => previous + ', ' + current)}</p>
      </div>

      <div className={s.inputFileContainer}>
        <div>
          <input type="file" name="file" accept=".csv" onChange={changeHandler} disabled={doneImportation} />
        </div>
        <p>
          Registros a actualizar: <span className={s.boldClass}>{csvValues?.length || 0}</span>
        </p>
      </div>
    </div>
  )
}

export default ImportFilesComponent
