export const reducePayload = (turns) => {
  if (!turns) return []

  return turns.map((turn) => {
    let auxTurn = JSON.parse(JSON.stringify(turn))

    if (auxTurn?.extraServices) {
      auxTurn.extraServices = auxTurn.extraServices.map((s) => (s?._id ? s._id : s))
    }

    if (auxTurn?.centre?.services) {
      auxTurn.centre.services = auxTurn.centre.services.map((s) => (s?._id ? s._id : s))
    }

    if (auxTurn?.doctorCentre?._id) {
      auxTurn.doctorCentre = {
        _id: auxTurn.doctorCentre._id,
        firstname: auxTurn.doctorCentre.firstname,
        lastname: auxTurn.doctorCentre.lastname,
      }
    }

    if (auxTurn?.patientCentre?._id) {
      auxTurn.patientCentre = {
        _id: auxTurn.patientCentre._id,
        firstname: auxTurn.patientCentre.firstname,
        lastname: auxTurn.patientCentre.lastname,
        email: auxTurn.patientCentre.email,
        dni: auxTurn.patientCentre.dni,
      }
    }

    if (auxTurn?.machine?._id) {
      auxTurn.machine = {
        _id: auxTurn.machine._id,
        name: auxTurn.machine.name,
      }
    }

    // reduce turn.logs
    if (auxTurn?.logs?.length > 100) {
      auxTurn.logs = auxTurn?.logs?.slice(-100)
    }

    return auxTurn
  })
}
