import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import LoaderCircle from '@/components/LoaderCircle'
import NavBar from '@/components/NavBar'
import { cancelTurn, cancelTurnTime, confirmTurn } from '@/services/turns'
import { showToast } from '@/utils/toast'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const ConfirmOrCancelTurn = () => {
  const { id } = useParams()
  const { t } = useTranslation('global')
  const { pathname } = useLocation()
  const [messageTurnSuccess, setMessageConfirmTurnSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [messageErrorLess4Hours, setMessageErrorLess4Hours] = useState(null)
  const [hours, setHours] = useState(null)
  const [popUpCancelTurn, setPopUpCancelTurn] = useState(null)
  const [messageTurnNotCancelled, setMessageTurnNotCancelled] = useState(null)
  const errorText = `${t('turnLabel.Sl')} no fue encontrada, verifica que el enlace sea el correcto`

  const confirmTurnById = async () => {
    try {
      const infoConfirmedTurn = await confirmTurn(id)
      setMessageConfirmTurnSuccess(infoConfirmedTurn)
    } catch (error) {
      setError(errorText)
    }
  }

  const checkCancelTurnById = async () => {
    try {
      const checkCancelTurnTime = await cancelTurnTime(id)
      let now = dayjs().utc('Z').format()
      let startHour = dayjs(checkCancelTurnTime.startHour).utc().format()
      let hourToCancelTurn = checkCancelTurnTime?.hoursToCancelTurn
      let diffHours = dayjs(startHour).diff(now, 'hour')

      if (diffHours < 0) {
        setError(`Ya no puedes cancelar la fecha de su ${t('turnLabel.s')} por que su fecha ya pasó.`)
      } else if (diffHours < hourToCancelTurn) {
        setHours(hourToCancelTurn)
        setMessageErrorLess4Hours(true)
      } else {
        setPopUpCancelTurn(true)
      }
    } catch (error) {
      setError(errorText)
    }
  }

  useEffect(() => {
    if (!id) setError(errorText)

    if (pathname?.includes('confirmar') && id) {
      confirmTurnById()
    }
    if (pathname?.includes('cancelar') && id) {
      checkCancelTurnById()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, id])

  const cancelTurnById = async () => {
    try {
      const canceledTurn = await cancelTurn(id)
      setMessageConfirmTurnSuccess(canceledTurn)
    } catch (e) {
      setError('Algo salió mal, recargue la página e intente nuevamente')
      showToast('Algo salió mal, recargue la página e intente nuevamente', 'error', 3000)
    } finally {
      setPopUpCancelTurn(false)
    }
  }

  const handlePressBackButton = () => {
    setMessageTurnNotCancelled(true)
  }

  return (
    <div className={s.divContainer}>
      <NavBar />
      <div className={s.cardMessage}>
        {messageTurnSuccess && (
          <div className={s.divItem}>
            {messageTurnSuccess.icon === 'error' ? (
              <ClearIcon className={s.iconError} />
            ) : (
              <CheckIcon className={s.iconSuccess} />
            )}
            <p className={s.text}> {messageTurnSuccess.message}</p>
          </div>
        )}

        {error && (
          <div className={s.divItem}>
            <ClearIcon className={s.iconError} />
            <p className={s.text}>{error}</p>
          </div>
        )}

        {messageErrorLess4Hours && (
          <div className={s.divItem}>
            <ClearIcon className={s.iconError} />
            <p className={s.text}>{`Ya no puedes cancelar ${t('turnLabel.se')} por este medio.`}</p>
            <p
              className={s.subtitle}
            >{`Porque faltan menos de ${hours} horas para la misma. Comunícate telefónicamente/WhatsApp con tu centro. `}</p>
          </div>
        )}

        {messageTurnNotCancelled && (
          <div className={s.divItem}>
            <ClearIcon className={s.iconError} />
            <p className={s.text}>{`${t('turnLabel.Sl')} no fue cancelada`}</p>
            <p className={s.subtitle}>{`Para mas información comunícate telefónicamente/WhatsApp con tu centro`}</p>
          </div>
        )}

        {!messageTurnSuccess && !error && !messageErrorLess4Hours && !messageTurnNotCancelled && <LoaderCircle />}

        {popUpCancelTurn && (
          <AlertDialogSlide
            open={popUpCancelTurn}
            setOpen={setPopUpCancelTurn}
            textBoldFont={`⚠️ Cancelacion de ${t('turnLabel.s')}`}
            textContent={`¿Realmente quiere cancelar su ${t('turnLabel.s')}?`}
            textBackButton="No"
            textConfirmationButton="Sí, cancelar"
            onClose={cancelTurnById}
            extraFunction={handlePressBackButton}
          />
        )}
      </div>
    </div>
  )
}

export default ConfirmOrCancelTurn
