import * as React from 'react'
import CaluButton from '@/components/styledComponents/CaluButton'
import { deviceDetector } from '@/helpers/deviceDetector'
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material'

import PropTypes from 'prop-types'

import s from './AlertDialogSlide.module.scss'

const Transition = React.forwardRef(function Transiion(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />
})

export default function AlertDialogSlide({
  open,
  setOpen,
  title,
  textBoldFont,
  textContent,
  textContentExtraOptional,
  textBackButton,
  textConfirmationButton,
  onClose,
  navigateToHome,
  extraFunction = null,
}) {
  let isMobile = deviceDetector()

  const handleClose = () => {
    if (navigateToHome) {
      navigateToHome()
    } else {
      setOpen(false)
    }
  }

  const handleConfirmation = () => {
    onClose()
    setOpen(false)
  }

  //If you want to do something when press back button
  const handleExtraFunction = () => {
    if (extraFunction) extraFunction()
    handleClose()
  }

  return (
    <Dialog
      open={open}
      // @ts-ignore
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      {title && <DialogTitle className={s.title}>{title}</DialogTitle>}

      <DialogContent id="alert-dialog-slide-description">
        <div className={s.textInformation}>
          <div className={s.textBoldFont}>{textBoldFont}</div>
          <div>{textContent}</div>
          <div>{textContentExtraOptional}</div>
        </div>
      </DialogContent>
      <DialogActions className={s.divButtons}>
        <div>
          <CaluButton inverted="true" onClick={handleExtraFunction || handleClose} size={isMobile ? 'medium' : 'large'}>
            {textBackButton}
          </CaluButton>
        </div>
        <div>
          <CaluButton color="primary" onClick={handleConfirmation} size={isMobile ? 'medium' : 'large'}>
            {textConfirmationButton}
          </CaluButton>
        </div>
      </DialogActions>
    </Dialog>
  )
}

AlertDialogSlide.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  textContent: PropTypes.string.isRequired,
  textContentExtraOptional: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  textBoldFont: PropTypes.string,
  textBackButton: PropTypes.string,
  textConfirmationButton: PropTypes.string,
  navigateToHome: PropTypes.string,
  extraFunction: PropTypes.func,
}

AlertDialogSlide.defaultProps = {
  textBackButton: 'Cancelar',
  textConfirmationButton: 'Confirmar',
}
