import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'wapp',
  initialState: {
    status: null,
    isLoading: false,
    wappTurnReminders: [],
    originalWappReminderAvailableToSend: 0,
    paginationData: {
      page: 1,
      limit: 30,
    },
    wappHistory: {
      turnHistory: [],
      remindersHistory: [],
      selectedTurnReminder: null,
      selectedReminder: null,
      paginationData: {
        page: 1,
        limit: 30,
      },
    },
  },
  reducers: {
    UPDATE_WAPP_STATUS: (state, { payload }) => {
      state.status = payload
    },
    CLEAN_WAPP_STATUS: (state) => {
      state.status = null
    },
    LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    GET_WAPP_TURN_REMINDERS: (state, { payload }) => {
      state.wappTurnReminders = payload.allTurns
      state.paginationData = payload.paginationData
      let count = 0
      payload.allTurns.forEach((turn) => {
        if(!turn?.wappReminder) count++
        if (turn?.wappReminder?.attempts?.length === 1) {
            count++;
        } 
      });
      state.originalWappReminderAvailableToSend = count
    },

    GET_WAPP_TURN_HISTORY: (state, { payload }) => {
      state.wappHistory.turnHistory = payload.allTurns
      state.wappHistory.paginationData = payload.paginationData
      state.wappHistory.remindersHistory = []
    },
    GET_WAPP_REMINDERS_HISTORY: (state, { payload }) => {
      state.wappHistory.remindersHistory = payload.allReminders
      state.wappHistory.paginationData = payload.paginationData
      state.wappHistory.turnHistory = []
    },
    UPDATE_WAPP_TURN_HISTORY: (state, { payload }) => {
      state.wappHistory.turnHistory = state.wappHistory.turnHistory.map((t) => {
        if (t._id === payload._id) {
          t = payload
        }
        return t
      })
    },
    UPDATE_WAPP_REMINDER_HISTORY: (state, { payload }) => {
      state.wappHistory.remindersHistory = state.wappHistory.remindersHistory.map((t) => {
        if (t._id === payload._id) {
          t = payload
        }
        return t
      })
    },

    SELECTED_TURN_REMINDER: (state, { payload }) => {
      state.wappHistory.selectedTurnReminder = payload
    },
    SELECTED_REMINDER: (state, { payload }) => {
      state.wappHistory.selectedReminder = payload
    },

    RESET_SELECTED_REMINDER: (state) => {
      state.wappHistory.selectedTurnReminder = null
      state.wappHistory.selectedReminder = null
    },

    CREATED_WAPP_REMINDER: (state, { payload }) => {
      state.wappTurnReminders = state.wappTurnReminders.map((turn) => {
        if (turn._id === payload.turn) {
          if (turn.wappReminder) {
            turn.wappReminder.attempts = payload.attempt
          }
          turn.wappReminder = payload
        }
        return turn
      })
    },

    SET_SENT_REMINDERS_QUANTITY: (state, { payload }) => {
      state.originalWappReminderAvailableToSend = state.originalWappReminderAvailableToSend - payload
    },
  },
})

export const {
  UPDATE_WAPP_STATUS,
  LOADING,
  GET_WAPP_TURN_REMINDERS,
  CREATED_WAPP_REMINDER,
  SET_SENT_REMINDERS_QUANTITY,
  GET_WAPP_TURN_HISTORY,
  SELECTED_TURN_REMINDER,
  SELECTED_REMINDER,
  RESET_SELECTED_REMINDER,
  UPDATE_WAPP_TURN_HISTORY,
  UPDATE_WAPP_REMINDER_HISTORY,
  GET_WAPP_REMINDERS_HISTORY,
  CLEAN_WAPP_STATUS,
} = slice.actions
export default slice.reducer
