import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { resetSpecificAvailability } from '@/store/centreAvailability/actions'
import { SELECTED_ONLINE_EXTRA_SERVICES } from '@/store/turns'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { Autocomplete, Button, Checkbox, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material'

import s from '../popUpAddExtraService/styles.module.scss'

const PopUpAddExtraService = ({
  open,
  onClose,
  allServices,
  selectedExtraServices,
  setSelectedExtraServices,
  filteredEntities,
  selectedService,
}) => {
  const dispatch = useDispatch()
  const icon = <CheckBoxOutlineBlank fontSize="small" />
  const checkedIcon = <CheckBox fontSize="small" />

  const [allServicesWithoutSelected, setAllServicesWithoutSelected] = useState([])

  useEffect(() => {
    let extraServices = allServices?.filter((s) => s?._id !== selectedService?._id)
    setAllServicesWithoutSelected(extraServices)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return
    onClose()
  }

  const handleConfirm = async () => {
    // await dispatch(resetSpecificAvailability(selectedService, filteredEntities))
    mxp.track(mxp.events.centreLanding.extraServices.confirmed)
    handleClose()
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={open} onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle="Agregar servicios adicionales" handleClose={handleClose} hideCloseButton />
        </DialogTitle>
        <DialogContent>
          <div>
            <p className={s.info}>
              Puedes seleccionar servicios adicionales a tu turno para evitar tener que reservar múltiples turnos
            </p>
            <div className={s.containerSelect}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={allServicesWithoutSelected}
                noOptionsText="No hay servicios disponibles."
                ListboxProps={{ style: { maxHeight: '15rem', fontSize: '14px' } }}
                getOptionLabel={(option) => option?.name?.toUpperCase()}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option._id}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {`${option?.name?.toUpperCase()}`} {option.duration && `- Duración: ${option.duration}`}{' '}
                    {option.price && `- Precio: $${option.price}`}
                  </li>
                )}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                fullWidth
                value={selectedExtraServices}
                renderInput={(params) => <TextField {...params} label="Servicios" variant="standard" />}
                onChange={(event, selectedValues) => {
                  setSelectedExtraServices(selectedValues)
                  dispatch(SELECTED_ONLINE_EXTRA_SERVICES(selectedValues))
                  dispatch(resetSpecificAvailability(selectedService, filteredEntities))
                }}
              />
            </div>
            <div className={s.containerBtn}>
              <Button className={s.confirmBtn} onClick={handleConfirm}>
                Confirmar
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpAddExtraService
