import React from 'react'
import Img404 from '@/assets/images/404.svg'
import Logo from '@/assets/images/logo.png'
import CaluButton from '@/components/styledComponents/CaluButton'

import s from './notfoundpage.module.scss'

const Error404 = () => {
  return (
    <div className={s.mainContainer}>
      <div className={s.itemContainer}>
        <div className={s.containerLogo}>
          <img className={s.logoCalu} src={Logo} alt="Logo Calu" />
        </div>
        <div className={s.containerImg404}>
          <img src={Img404} alt="" />
        </div>
        <div className={s.containerH3}>
          <h3>Parece que no hay nada por aquí</h3>
        </div>
        <div className={s.containerButton}>
          <div className={s.itemButton}>
            <CaluButton color="primary" size="large" onClick={() => (window.location.href = '/')}>
              Volver
            </CaluButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error404
