import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'planPrices',
  initialState: {
    allPlanPrices: [],
    selectedPlan: null,
    error: false,
    isLoaded: null,
  },
  reducers: {
    FETCH_ALL_PLAN_PRICES: (state, { payload }) => {
      state.allPlanPrices = payload
    },
    PLAN_PRICES_UPDATED: (state, { payload }) => {
      state.allPlanPrices = state.allPlanPrices.map((plan) => {
        if (plan._id === payload._id) {
          plan = payload
        }
        return plan
      })
    },
    PLAN_PRICES_DELETE: (state, { payload }) => {
      state.allPlanPrices = state.allPlanPrices.filter((plan) => plan._id !== payload)
      state.selectedPlan = null
    },
    PLAN_PRICES_CREATED: (state, { payload }) => {
      state.allPlanPrices = [...(state.allPlanPrices || []), payload]
    },
    PLAN_PRICES_SELECTED: (state, { payload }) => {
      state.selectedPlan = payload
    },
    RESET_PLAN_PRICES_SELECTED: (state) => {
      state.selectedPlan = null
    },
    LOADED_PLAN_PRICES: (state, { payload }) => {
      state.isLoaded = payload
    },
  },
})

export const {
  FETCH_ALL_PLAN_PRICES,
  PLAN_PRICES_UPDATED,
  PLAN_PRICES_DELETE,
  PLAN_PRICES_CREATED,
  LOADED_PLAN_PRICES,
  PLAN_PRICES_SELECTED,
  RESET_PLAN_PRICES_SELECTED,
} = slice.actions
export default slice.reducer
