import * as yup from 'yup'

export const formValidationSchema = yup.object({
  lastname: yup.string().required('Campo obligatorio'),
  firstname: yup.string().required('Campo obligatorio'),
  dni: yup.string().required('Campo obligatorio'),
  mobile: yup
    .string()
    .test('maxLen', 'Este campo debe tener máximo 20 caracteres.', (val) => val.length <= 20)
    .test(
      'without_0_15',
      'Debe ir con característica, sin 0 y sin 15. Ejemplo: 1156789012.',
      (val) => !/^(15|0)/.test(val),
    )
    .required(),
})
