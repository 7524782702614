import dayjs from 'dayjs'

export const showSelectedDateFullName = (date) => {
  if (!date) return

  let dateName = dayjs(date).format('dddd')
  let dateNumber = dayjs(date).format('D')
  let dateMonth = dayjs(date).format('MMMM')
  let dateYear = dayjs(date).format('YYYY')
  let fullDateName = `${dateName} ${dateNumber} de ${dateMonth} de ${dateYear}`

  return fullDateName
}
