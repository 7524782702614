import React, { useEffect, useState } from 'react'
import { AiOutlinePaperClip } from 'react-icons/ai'
import logoHC from '@/assets/images/clinicHistory.png'
import logoDraftHC from '@/assets/images/clinicHistoryDraft.png'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'

import Form from './components/Form'

import s from './styles.module.scss'

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#eee',
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'initial',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    overflow: 'hidden',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor: 'white',
}))

export default function AccordionItem({ detailHC, index, collapseAll, isNewTurn, allowEditHC }) {
  const [expanded, setExpanded] = useState('')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  useEffect(() => {
    if (collapseAll) {
      setExpanded('')
      return
    }
    setExpanded(`panel${index}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseAll])

  return (
    <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className={s.summary}>
          <div className={s.imageFont}>
            <img src={detailHC?.isDraft ? logoDraftHC : logoHC} alt="HC" className={s.imageFont} />
          </div>

          {detailHC?.attached?.length > 0 && (
            <span className={s.paperClip}>
              {detailHC?.attached?.length}
              <i>
                <AiOutlinePaperClip />
              </i>
            </span>
          )}
          <div className={s.detailItem}>
            <span>
              {toCustomTz(detailHC?.visit, undefined, true, 'D')} de{' '}
              {toCustomTz(detailHC?.visit, undefined, true, 'MMMM')} de{' '}
              {toCustomTz(detailHC?.visit, undefined, true, 'YYYY')}{' '}
            </span>

            {detailHC?.createdBy && (
              <span>
                {' '}
                - Creada por: <b> {capitalizeFirstLetters(detailHC?.createdBy)}</b>
              </span>
            )}

            {detailHC?.reason && <p className={s.reason}>Asunto: {detailHC?.reason}</p>}
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <Form detailHC={detailHC} isNewTurn={isNewTurn} index={index} allowEditHC={allowEditHC} />
      </AccordionDetails>
    </Accordion>
  )
}
