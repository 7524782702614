import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAge } from '@/utils/functions/getAge'
import { handleSetDniLabel } from '@/utils/functions/handleSetDniLabel'
import { toCustomTz } from '@/utils/functions/toCustomTz'

const TurnInfoPatient = ({ doctorCentres }) => {
  const { t } = useTranslation('global')
  const { selectedTurn } = useSelector((state) => state.turns)
  const { machineSelected } = useSelector((state) => state.machines)
  const patientCentre = selectedTurn?.patientCentre
  const country = patientCentre?.country
  let selectedDate = selectedTurn?.startHour

  return (
    <>
      {selectedTurn ? (
        patientCentre ? (
          <h4 className="title">
            {`${patientCentre?.lastname?.toUpperCase()}, ${patientCentre?.firstname?.toUpperCase()}`}

            {patientCentre?.dni?.length < 15 && (
              <span className="small">{`${handleSetDniLabel(country)} ${patientCentre?.dni}`}</span>
            )}

            <span className="small">
              {patientCentre?.birth && (
                <span>
                  {toCustomTz(patientCentre?.birth, 'utc', false, 'DD/MM/YYYY')} ({getAge(patientCentre?.birth)} años)
                </span>
              )}
              {patientCentre?.mobile && patientCentre?.telephone ? (
                <span>
                  {' '}
                  - Tel: {patientCentre?.mobile} / {patientCentre?.telephone}
                </span>
              ) : patientCentre?.mobile ? (
                <span> - Tel: {patientCentre?.mobile}</span>
              ) : (
                patientCentre?.telephone && <span> - Tel: {patientCentre?.telephone}</span>
              )}
            </span>
          </h4>
        ) : (
          <h4 className="title">❌ PACIENTE ELIMINADO</h4>
        )
      ) : (
        <h4 className="title">
          {selectedDate ? t('turnLabel.Nt') : `${t('turnLabel.Nt')} sin fecha`}
          {machineSelected ? (
            <span className="small">{`con ${machineSelected?.name?.toUpperCase()}`}</span>
          ) : (
            <span className="small">{`con ${doctorCentres?.lastname?.toUpperCase()}, ${doctorCentres?.firstname?.toUpperCase()}`}</span>
          )}
        </h4>
      )}
    </>
  )
}

export default TurnInfoPatient
