import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BsTrash } from 'react-icons/bs'
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import { TextOverButtons } from '@/components/TextOverButtons'
import { useSocket } from '@/context/SocketProvider'
import { useUser } from '@/context/UserProvider'
import { defaultServices } from '@/helpers/constants'
import { workTimeFrequencies } from '@/helpers/constants'
import { weekDays } from '@/helpers/constants'
import useValidateURLId from '@/hooks/useValidateURLId'
import { getDoctorCentres } from '@/store/doctorCentres/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { formatTime } from '@/utils/functions/formatTime'
import { getDayCode } from '@/utils/functions/getDayCode'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { toLocalTz } from '@/utils/functions/toLocalTz'
import { isWorkTimeValid } from '@/utils/functions/workTimeValid'
import { showToast } from '@/utils/toast'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Button, Grid } from '@mui/material'

import dayjs from 'dayjs'
import moment from 'moment-timezone'
import PopUpFormCreateWT from './components/popUpFormCreateWT'

import s from './styles.module.scss'

const WorkingHoursModule = ({ entity, updateFunction, directionOfAnimation }) => {
  const dispatch = useDispatch()
  const centreID = useValidateURLId()
  const { socket } = useSocket()
  const { defaultCentre } = useUser()

  const [startDateDeferred, setStartDateDeferred] = useState(dayjs().format())
  const [finishDateDeferred, setFinishDateDeferred] = useState(null)
  const [showDeferred, setShowDeferred] = useState(true)
  const [each15Days, setEach15Days] = useState(false)
  const [uniqueDay, setUniqueDay] = useState(false)
  const [selectedDay, setSelectedDay] = useState('')
  const [showUpdateButton, setShowUpdateButton] = useState(false)
  const [auxEntity, setAuxEntity] = useState(JSON.parse(JSON.stringify(entity)))
  const [selectedWorkTime, setSelectedWorkTime] = useState({})
  const [openFormCreateWT, setOpenFormCreateWT] = useState(false)
  const [weekDaysSelectedArray, setWeekDaysSelectedArray] = useState([])
  const [duplicateWT, setDuplicateWT] = useState(false)

  const { doctorCentres: allDoctorsCentre, isLoaded } = useSelector((state) => state.doctorCentres)

  useEffect(() => {
    if (!entity) return
    let copyEntity = JSON.parse(JSON.stringify(entity))

    let weeklyWT = copyEntity.workTimes
      .filter((wt) => wt.frequency.code === 0)
      .sort((a, b) =>
        toCustomTz(a?.startTime, undefined, false, 'HH:mm') > toCustomTz(b?.startTime, undefined, false, 'HH:mm')
          ? -1
          : 1,
      )
      .sort((a, b) => (a?.weekDay?.code > b?.weekDay?.code ? 1 : -1))
    let othersWT = copyEntity.workTimes.filter((wt) => wt.frequency.code !== 0)

    othersWT = othersWT
      .sort((a, b) => (a?.frequency?.startDay > b?.frequency?.startDay ? 1 : -1))
      .sort((a, b) => (a?.frequency?.code > b?.frequency?.code ? 1 : -1))

    let totalWT = [...weeklyWT, ...othersWT]
    copyEntity.workTimes = totalWT
    setAuxEntity(copyEntity)
  }, [entity])

  const defaultValues = {
    frequency: {
      code: 0,
    },
    onlineTurns: auxEntity?.onlineTurns || true,
    deferred: auxEntity?.deferred || false,
    doctorCentres: allDoctorsCentre && [],
    startTime: dayjs().tz('America/Argentina/Buenos_Aires').format('09:00'),
    finishTime: dayjs().tz('America/Argentina/Buenos_Aires').format('18:00'),
    weekDay: {
      code: '',
    },
    turnDuration: '',
    services: entity?.name ? [] : [...defaultServices],
    consultation: false,
    consultationFirstTime: false,
  }

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  })

  const checkboxDeferred = watch('deferred')
  const frequencyWatch = watch('frequency.code')
  const startTimeSelected = watch('startTime')

  useEffect(() => {
    if (checkboxDeferred === false) {
      setStartDateDeferred(new Date())
      setFinishDateDeferred(null)
    }
  }, [checkboxDeferred])

  useEffect(() => {
    if (frequencyWatch === 2) {
      setShowDeferred(false)
      setEach15Days(false)
      setUniqueDay(true)
    } else if (frequencyWatch === 1) {
      setShowDeferred(false)
      setUniqueDay(false)
      setEach15Days(true)
    } else {
      setShowDeferred(true)
      setEach15Days(false)
      setUniqueDay(false)
      setSelectedDay('')
    }
  }, [frequencyWatch])

  const onSubmit = async (data, e) => {
    if (!e) return
    e.preventDefault()
    let buttonClicked = e?.nativeEvent?.submitter?.name
    const { code } = data?.frequency

    if (buttonClicked === 'guardar') {
      if (code === 0 && !weekDaysSelectedArray?.length) return showToast('Debe seleccionar al menos un día', 'warning')
      if ((code === 1 || code === 2) && !selectedDay) return showToast('Debe seleccionar un día', 'warning', 3000)
    }

    if (showUpdateButton || duplicateWT) {
      data.weekDay = {
        ...data.weekDay,
        name: convertValueToName(weekDays, data.weekDay.code),
      }
    } else {
      data.weekDay = weekDaysSelectedArray
    }

    if (uniqueDay) {
      data.frequency = {
        ...data.frequency,
        startDay: dayjs(selectedDay).utc(true).format(), // 2023-02-10T00:00:00Z Select 10/02/2023
      }

      data.weekDay = [
        {
          code: 8,
          name: capitalizeFirstLetters(toCustomTz(data?.frequency?.startDay, 'utc', false, 'dddd')),
        },
      ]

      delete data.deferred
    }

    if (each15Days) {
      data.frequency = {
        ...data.frequency,
        startDay: dayjs(selectedDay).utc(true).format(),
      }

      let day = dayjs(selectedDay).format('dddd')
      data.weekDay = [
        {
          name: day,
          code: getDayCode(day),
        },
      ]

      delete data.deferred
    }

    let isValid = true

    const consultation = data?.services?.filter((s) => s._id === 'consultation')
    const firstConsultation = data?.services?.filter((s) => s._id === 'firstConsultation')

    if (consultation?.length) {
      data.consultation = true
      data.services = data.services.filter((service) => service._id !== 'consultation')
    }
    if (!consultation?.length) {
      data.consultation = false
    }

    if (firstConsultation?.length) {
      data.consultationFirstTime = true
      data.services = data.services.filter((service) => service._id !== 'firstConsultation')
    }
    if (!firstConsultation?.length) {
      data.consultationFirstTime = false
    }

    if (data.deferred) {
      data.deferred = {
        from: startDateDeferred ? dayjs(startDateDeferred).utc(true).hour(0).minute(0).format() : null,
        to: finishDateDeferred ? dayjs(finishDateDeferred).utc(true).hour(0).minute(0).format() : null,
      }
    }

    let workTimeData = { ...data }
    workTimeData.startTime = toCustomTz(formatTime(workTimeData.startTime), undefined, true)
    workTimeData.finishTime = toCustomTz(formatTime(workTimeData.finishTime), undefined, true)
    let lastUpdateWT = auxEntity?.workTimes || []
    let newWorkTimeData = { ...workTimeData }

    if (buttonClicked === 'guardar') {
      workTimeData?.weekDay?.forEach(async (day) => {
        newWorkTimeData = {
          ...workTimeData,
          weekDay: { ...day },
        }

        const isWTValid = isWorkTimeValid(newWorkTimeData, entity.workTimes)
        if (!isWTValid)
          return showToast(
            `Error! El horario de atención ${day?.name?.toUpperCase()} se superpone con otro horario de atención`,
            'error',
          )
        lastUpdateWT = [...lastUpdateWT, newWorkTimeData]
      })

      let auxDataToUpdate = {
        ...auxEntity,
        workTimes: lastUpdateWT,
      }

      try {
        const updatedEntity = await dispatch(updateFunction(auxDataToUpdate, true))
        socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedEntity)
        reset()
        setWeekDaysSelectedArray([])
        setOpenFormCreateWT(false)
      } catch (error) {
        console.log(error)
      } finally {
        setShowUpdateButton(false)
      }
    }

    let auxWorkTimes
    if (buttonClicked === 'actualizar') {
      auxWorkTimes = auxEntity?.workTimes || []
      auxWorkTimes = auxWorkTimes?.map((workTime) => {
        if (workTime._id === workTimeData._id) {
          if (isWorkTimeValid(workTimeData, entity.workTimes)) {
            let auxWeekDay = Array.isArray(workTimeData.weekDay) ? workTimeData.weekDay[0] : workTimeData.weekDay
            workTimeData.weekDay = auxWeekDay
            workTime = workTimeData
          } else {
            isValid = false
          }
        }
        return workTime
      })
      if (isValid) {
        let auxDataToUpdate = {
          ...auxEntity,
          workTimes: auxWorkTimes,
        }

        try {
          const updatedEntity = await dispatch(updateFunction(auxDataToUpdate, true))
          socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedEntity)
          reset()
          setWeekDaysSelectedArray([])
          setOpenFormCreateWT(false)
        } catch (error) {
          console.log(error)
        } finally {
          setShowUpdateButton(false)
        }
      } else {
        showToast('Error! El horario de atención se superpone con otro horario de atención', 'error')
      }
    }

    if (buttonClicked === 'duplicar') {
      newWorkTimeData = {
        ...workTimeData,
      }
      delete newWorkTimeData._id

      const isWTValid = isWorkTimeValid(newWorkTimeData, entity.workTimes)
      if (!isWTValid)
        return showToast('Error! El horario de atención se superpone con otro horario de atención', 'error')
      lastUpdateWT = [...lastUpdateWT, newWorkTimeData]

      let auxDataToUpdate = {
        ...auxEntity,
        workTimes: lastUpdateWT,
      }

      try {
        const updatedEntity = await dispatch(updateFunction(auxDataToUpdate, true))
        socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedEntity)
        reset()
        setWeekDaysSelectedArray([])
        setOpenFormCreateWT(false)
      } catch (error) {
        console.log(error)
      } finally {
        setShowUpdateButton(false)
      }
    }
  }

  useEffect(() => {
    if (entity?.name && !isLoaded) {
      dispatch(getDoctorCentres(centreID))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteWorkTime = async (workTime) => {
    let workTimeDelete = auxEntity?.workTimes?.filter((h) => h._id !== workTime._id)
    let auxDataToUpdate = {
      ...auxEntity,
      workTimes: workTimeDelete,
    }
    try {
      const updatedDoctorCentre = await dispatch(updateFunction(auxDataToUpdate))
      socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedDoctorCentre)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (Object.keys(selectedWorkTime)?.length > 0) {
      Object.keys(selectedWorkTime)?.forEach((k) => {
        if (k === 'frequency') {
          if ('frequency.startDay') {
            const localStartDay = moment(selectedWorkTime[k].startDay)
              .add(moment().utcOffset() * -1, 'minutes')
              .format()

            setSelectedDay(localStartDay)
          }
          setValue('frequency.code', selectedWorkTime[k].code)
          return
        }

        if (k === 'weekDay') {
          setValue('weekDay.code', selectedWorkTime[k].code)
          return
        }

        if (k === 'services') {
          let newServices = []
          if (selectedWorkTime?.consultation) {
            newServices.push({ _id: 'consultation', name: 'Consulta' })
          }
          if (selectedWorkTime?.consultationFirstTime) {
            newServices.push({ _id: 'firstConsultation', name: 'Primera consulta' })
          }

          setValue('services', newServices.concat(selectedWorkTime[k]))
          return
        }

        if (k === 'doctorCentres') {
          setValue('doctorCentres', selectedWorkTime[k])
          return
        }

        if (k === 'startTime') {
          let startTime = dayjs(selectedWorkTime[k]).tz('America/Argentina/Buenos_Aires').format('HH:mm')
          setValue('startTime', startTime)
          return
        }

        if (k === 'finishTime') {
          let finishTime = dayjs(selectedWorkTime[k]).tz('America/Argentina/Buenos_Aires').format('HH:mm')
          setValue('finishTime', finishTime)
          return
        }

        if (k === 'deferred') {
          if (selectedWorkTime.hasOwnProperty('deferred')) {
            setValue(k, true)
            if (selectedWorkTime[k].from) {
              setStartDateDeferred(toLocalTz(selectedWorkTime[k].from))
            } else {
              setStartDateDeferred(null)
            }
            if (selectedWorkTime[k].to) {
              setFinishDateDeferred(toLocalTz(selectedWorkTime[k].to))
            } else {
              setFinishDateDeferred(null)
            }
            return
          }
        }

        if (!selectedWorkTime.hasOwnProperty('deferred')) {
          setValue('deferred', false)
        }

        setValue(k, selectedWorkTime[k])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkTime])

  const addPropWhenClick = (i) => {
    let auxData = { ...auxEntity }
    auxData.workTimes[i].showConfirm = true
    setAuxEntity(auxData)
  }

  const removePropWhenClick = (i) => {
    let auxData = { ...auxEntity }
    auxData.workTimes[i].showConfirm = false
    setAuxEntity(auxData)
  }

  const handleCreateNewWT = () => {
    setSelectedWorkTime({})
    setShowUpdateButton(false)
    setDuplicateWT(false)
    reset()
    setOpenFormCreateWT(true)
  }

  const handleEditSelectedWT = (wt) => {
    setShowUpdateButton(true)
    setSelectedWorkTime(wt)
    setOpenFormCreateWT(true)
    setDuplicateWT(false)
  }

  const handleDuplicateWT = (wt) => {
    setDuplicateWT(true)
    setShowUpdateButton(false)
    setSelectedWorkTime(wt)
    setOpenFormCreateWT(true)
  }

  return (
    <div className={directionOfAnimation}>
      <div className={s.containerBtnCreateWT}>
        <CaluButton color="primary" size="medium" onClick={handleCreateNewWT}>
          Crear horario laboral
        </CaluButton>
      </div>
      <Grid item xs={12}>
        <div>
          {auxEntity?.workTimes?.length === 0 ? (
            <p className={s.emptyList}>No hay horarios laborales cargados aún.</p>
          ) : (
            auxEntity?.workTimes?.map((time, i) => (
              <div key={time?._id} className={s.itemWorkTimes}>
                <p>
                  {convertValueToName(workTimeFrequencies, time?.frequency?.code)}
                  {time?.weekDay?.code === 8 ? (
                    <b className={s.formatText}>
                      {' '}
                      - {toCustomTz(time?.frequency?.startDay, undefined, true, 'dddd DD/MM/YYYY')}
                    </b>
                  ) : time?.frequency?.startDay ? (
                    <>
                      {' '}
                      desde
                      <b className={s.formatText}>
                        {' '}
                        - {toCustomTz(time?.frequency?.startDay, undefined, true, 'dddd DD/MM/YYYY')}
                      </b>
                    </>
                  ) : (
                    <b className={s.formatText}> - {convertValueToName(weekDays, time?.weekDay?.code)}</b>
                  )}
                  - {toCustomTz(time.startTime, undefined, false, 'HH:mm')} -{' '}
                  {toCustomTz(time.finishTime, undefined, false, 'HH:mm')} ({time.turnDuration} min.){' '}
                  {time.deferred && (
                    <small>
                      {!time.deferred.from && !time.deferred.to
                        ? `()`
                        : time.deferred.from !== null
                          ? time.deferred.to !== null
                            ? `(Desde ${toCustomTz(time.deferred.from, undefined, true, 'DD/MM/YYYY')} Hasta ${toCustomTz(
                                time.deferred.to,
                                undefined,
                                true,
                                'DD/MM/YYYY',
                              )})`
                            : `(Desde ${toCustomTz(time.deferred.from, undefined, true, 'DD/MM/YYYY')})`
                          : `(Hasta ${toCustomTz(time.deferred.to, undefined, true, 'DD/MM/YYYY')})`}
                    </small>
                  )}
                </p>

                {time.showConfirm ? (
                  <div className={s.containerButtonsDelete}>
                    <TextOverButtons title="Si, eliminar">
                      <div className={s.btnsDeleteIcons}>
                        <IoIosCheckmarkCircleOutline onClick={() => deleteWorkTime(time)} />
                      </div>
                    </TextOverButtons>
                    <TextOverButtons title="No, cancelar">
                      <div className={s.btnsCancelIcons}>
                        <IoIosCloseCircleOutline onClick={() => removePropWhenClick(i)} />
                      </div>
                    </TextOverButtons>
                  </div>
                ) : (
                  <div>
                    <TextOverButtons title="Duplicar">
                      <Button className={s.trashIcon} onClick={() => handleDuplicateWT(time)}>
                        <ContentCopyIcon />
                      </Button>
                    </TextOverButtons>
                    <TextOverButtons title="Modificar">
                      <Button className={s.trashIcon} onClick={() => handleEditSelectedWT(time)}>
                        <EditOutlinedIcon />
                      </Button>
                    </TextOverButtons>
                    <TextOverButtons title="Eliminar">
                      <Button onClick={() => addPropWhenClick(i)} className={s.trashIcon}>
                        <BsTrash />
                      </Button>
                    </TextOverButtons>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </Grid>

      <PopUpFormCreateWT
        openPopUp={openFormCreateWT}
        setOpenPopUp={setOpenFormCreateWT}
        control={control}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        showDeferred={showDeferred}
        each15Days={each15Days}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        errors={errors}
        startTimeSelected={startTimeSelected}
        entity={entity}
        setValue={setValue}
        startDateDeferred={startDateDeferred}
        setStartDateDeferred={setStartDateDeferred}
        finishDateDeferred={finishDateDeferred}
        setFinishDateDeferred={setFinishDateDeferred}
        showUpdateButton={showUpdateButton}
        selectedWorkTime={selectedWorkTime}
        weekDaysSelectedArray={weekDaysSelectedArray}
        setWeekDaysSelectedArray={setWeekDaysSelectedArray}
        uniqueDay={uniqueDay}
        duplicateWT={duplicateWT}
      />
    </div>
  )
}

export default WorkingHoursModule
