import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import {
  createNewPatientTag,
  getAllPatientTags,
  removePatientTag,
  updatePatientTag,
} from '@/store/patientTags/actions.js'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { showToast } from '@/utils/toast'
import EditIcon from '@mui/icons-material/Edit'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField } from '@mui/material'

import s from './styles.module.scss'

const ConfigPatientTags = ({ openConfigPatientTags, setOpenConfigPatientTags }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const [tagName, setTagName] = useState('')
  const [tagColorSelected, setTagColorSelected] = useState('#61bd4f')
  const [tagSelectedId, setTagSelectedId] = useState(null)
  const [editPatientTag, setEditPatientTag] = useState(false)
  const [isLoadingButton, setIsLoadingButton] = useState(false)
  const [isLoadingUpdateTagButton, setIsLoadingUpdateTagButton] = useState(false)
  const centreId = useSelector((state) => state?.centreConfigurations?.centreConfigurationDetail?.centre)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const allPatientTags = useSelector((state) => state?.patientTags?.all)
  const patientTagColors = [
    '#61bd4f',
    '#f2d600',
    '#ff9f1a',
    '#eb5a46',
    '#c377e0',
    '#0079bf',
    '#00c2e0',
    '#51e898',
    '#ff78cb',
    '#344563',
  ]

  useEffect(() => {
    dispatch(getAllPatientTags(centreId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = () => {
    setOpenConfigPatientTags(false)
  }

  const createTagButton = async () => {
    if (!tagName) return showToast('Debe ingresar un nombre de etiqueta', 'warning')

    setIsLoadingButton(true)
    const objectToCreatePatientTag = {
      centre: centreId,
      color: tagColorSelected,
      name: tagName,
    }
    try {
      await dispatch(createNewPatientTag(objectToCreatePatientTag))
      setIsLoadingButton(false)
      setTagName('')
      setTagColorSelected('#61bd4f')
    } catch (e) {
      console.log('error')
    }
  }

  const selectedTagToEdit = (tag) => {
    setEditPatientTag(true)
    setTagName(tag.name)
    setTagColorSelected(tag.color)
    setTagSelectedId(tag._id)
  }

  const updateTag = async () => {
    const objectTagToUpdate = {
      _id: tagSelectedId,
      centre: centreId,
      name: tagName,
      color: tagColorSelected,
    }

    if (!objectTagToUpdate.name) return showToast('Debe ingresar un nombre de etiqueta', 'warning')
    setIsLoadingUpdateTagButton(true)

    await dispatch(updatePatientTag(objectTagToUpdate))
    setIsLoadingUpdateTagButton(false)
    setEditPatientTag(false)
    setTagName('')
    setTagColorSelected('#61bd4f')
  }

  const deletePatientTag = async () => {
    setIsLoadingButton(true)
    await dispatch(removePatientTag(tagSelectedId))
    setIsLoadingButton(false)
    setEditPatientTag(false)
    setTagName('')
    setTagColorSelected('#61bd4f')
  }

  const handleReset = () => {
    setEditPatientTag(false)
    setTagName('')
    setTagColorSelected('#61bd4f')
  }

  return (
    <>
      {centreId && (
        <Dialog
          disableEscapeKeyDown={true}
          maxWidth="md"
          fullWidth
          open={openConfigPatientTags}
          onClose={handleClose}
          scroll="paper"
        >
          <DialogTitle style={{ padding: '0px' }}>
            <TitlePopUp
              textTitle={`Etiqueta para asignar a ${t(
                `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`,
              )}`}
              handleClose={handleClose}
            />
          </DialogTitle>
          <DialogContent className={`animate__animated animate__fadeIn`}>
            <Grid className={s.mainContainer}>
              <h5>Creación de etiquetas</h5>
            </Grid>
            <Grid container className={s.containerTextfieldAndTags}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Nombre de la etiqueta"
                  variant="standard"
                  fullWidth={true}
                  value={tagName}
                  onChange={(e) => setTagName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} className={s.containerTags}>
                {patientTagColors.map((color, index) => (
                  <span
                    key={index}
                    style={{ background: color }}
                    className={tagColorSelected === color ? `${s.tag} ${s.patientTagSelected}` : s.tag}
                    onClick={() => setTagColorSelected(color)}
                  ></span>
                ))}
              </Grid>
            </Grid>
            <Grid container spacing={1} className={s.gridButton}>
              {!editPatientTag ? (
                <Grid item>
                  <LoadingButton
                    loading={isLoadingButton}
                    disabled={isLoadingButton}
                    disableElevation
                    variant="outlined"
                    className={s.createTagButton}
                    onClick={createTagButton}
                  >
                    Crear etiqueta
                  </LoadingButton>
                </Grid>
              ) : (
                <>
                  <Grid item>
                    <LoadingButton
                      loading={isLoadingButton}
                      disabled={isLoadingButton}
                      variant="outlined"
                      className={s.deletePatientTagButton}
                      onClick={deletePatientTag}
                    >
                      Eliminar
                    </LoadingButton>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" className={s.comeBackButton} onClick={handleReset}>
                      Volver
                    </Button>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      loading={isLoadingUpdateTagButton}
                      disabled={isLoadingUpdateTagButton}
                      variant="outlined"
                      className={s.updatePatientTagButton}
                      onClick={updateTag}
                    >
                      Actualizar etiqueta
                    </LoadingButton>
                  </Grid>
                </>
              )}
            </Grid>
            <hr className={s.divider} />
            <Grid className={s.createdTags}>
              <h5>Etiquetas creadas</h5>

              {allPatientTags.map((tag, index) => (
                <Grid key={index} className={s.tagDetail}>
                  <Grid style={{ display: 'flex' }}>
                    <Grid style={{ background: tag.color, width: '15px', marginRight: '5px' }}></Grid>
                    <Grid>{tag.name.toUpperCase()}</Grid>
                  </Grid>
                  <Grid>
                    <IconButton onClick={() => selectedTagToEdit(tag)}>
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default ConfigPatientTags
