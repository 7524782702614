import * as React from 'react'
import { useSelector } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormUser from '../FormUser'

import s from './styles.module.scss'

export default function FormDialog({ open, selectedUser, onClose }) {
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isAdmin } = usersProfileConnected
  const { blocked } = selectedUser

  const checkBlockedUser = () => {
    if (isAdmin && blocked) return true
  }

  return (
    <Dialog disableEscapeKeyDown={true} maxWidth="sm" fullWidth open={open} onClose={onClose} scroll="paper">
      <DialogTitle className={s.dialogTitle}>
        <TitlePopUp textTitle={'Datos del usuario'} handleClose={onClose} checkBlockedUser={checkBlockedUser()} />
      </DialogTitle>
      <DialogContent>
        <FormUser onClose={onClose} formValues={selectedUser} />
      </DialogContent>
    </Dialog>
  )
}
