import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AutoCompleteCityField } from '@/components/Form/customFields/AutoCompleteCityField'
import { AutoCompleteField } from '@/components/Form/customFields/AutoCompleteField'
import { CustomField } from '@/components/Form/customFields/CustomField'
import LoadingSaveButton from '@/components/LoadingSaveButton'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { Grid, Paper } from '@mui/material'

import s from './styles.module.scss'

export const FormContent = ({ isLoading }) => {
  const { t } = useTranslation('global')

  const specialities = useSelector((state) => state.speciality.specialitiesList)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  return (
    <Paper className={s.formContainer}>
      <Grid item className={s.formTitle}>
        <h3>Solicitud de alta de perfil profesional</h3>
        <p className={s.textContent}>
          Complete el siguiente formulario con sus datos profesionales.
          <br />
          Luego de que verifiquemos sus datos, aparecerá en los listados de profesionales de las búsquedas que los
          {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`)} realicen en{' '}
          <a href="www.calu.app" className={s.colorLink}>
            www.calu.app
          </a>
        </p>
      </Grid>
      <Grid container spacing={2} className={s.paddingForm}>
        <Grid item xs={12} sm={6}>
          <CustomField label="Nombre*" name="firstname" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Apellido*" name="lastname" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Email" name="email" type="email" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Repite Email" name="repeatEmail" type="email" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Contraseña" name="password" type="password" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Repite contraseña" name="repeatPassword" type="password" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="DNI" name="dni" type="text" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Matrícula" name="doctorNumber" type="text" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Dirección" name={`workPlaces[${0}][address]`} type="text" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutoCompleteCityField label="Ciudad" name={`workPlaces[${0}][city]`} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AutoCompleteField label="Especialidades" name="specialities" options={specialities} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Teléfono Móvil*" name="mobile" type="number" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Teléfono fijo" name="telephone" type="number" fullWidth />
        </Grid>

        <div className={s.containerButtonsForm}>
          <LoadingSaveButton
            textButton="Solicitar perfil profesional"
            type="submit"
            isLoading={isLoading}
            className={s.buttonSave}
            variant="contained"
          />
        </div>
      </Grid>
      <div className={s.infoDiv}>
        Dentro de las próximas 48 horas te avisaremos por email si tu <b> perfil profesional </b> fue aprobado o no.
      </div>
    </Paper>
  )
}
