// @ts-nocheck
import packageJson from '../../package.json'

const version = packageJson.version
const isDebug = import.meta.env.REACT_APP_DEBUG === 'true'
const currentEnv = import.meta.env.REACT_APP_ENVIRONMENT
const isDevelopment = currentEnv === 'development'
const isStaging = currentEnv === 'staging'
const isProduction = currentEnv === 'production'

const apiURL = import.meta.env.REACT_APP_API_BASE_URL
const baseURL = import.meta.env.REACT_APP_BASE_URL
const landingURL = import.meta.env.REACT_APP_LANDING_URL
const apiWappURL = import.meta.env.REACT_APP_API_WAPP_URL

export { apiURL, apiWappURL, baseURL, currentEnv, isDebug, isDevelopment, isProduction, isStaging, landingURL, version }
