import React from 'react'

import CaluRawInput from '../CaluRawInput'

const CaluInput = (props) => {
  return (
    <CaluRawInput
      {...props}
      error={Boolean(props.error)}
      multiline={props?.multiline}
      id="filled-multiline-flexible"
      maxRows={props?.maxRows || 15}
    />
  )
}

export default CaluInput
