import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const uploadFile = async (url) => {
  const route = `api/upload`
  return API.post(route, url)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { uploadFile }
