import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ErrorCard from '@/components/ErrorCard'
import { ContainerForm } from '@/components/Form/ContainerForm'
import NavBar from '@/components/NavBar'
import { useUser } from '@/context/UserProvider'
import { addNewDoctorProfile, getDoctorProfile, updateDoctorProfile } from '@/store/doctorProfile/actions'
import { showToast } from '@/utils/toast'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const FormDoctorProfileReview = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useUser()
  const doctorProfile = useSelector((state) => state.doctorProfile.doctorProfile)
  const [isLoading, setIsLoading] = useState(false)
  const [hideDoctorProfile, setHideDoctorProfile] = useState(false)

  const { reset } = useForm()

  const verifyDoctorProfile = async () => {
    if (user.centres.length || user.doctorCentres.length || user.secretaryCentres.length) {
      setHideDoctorProfile(true)
    } else {
      await dispatch(getDoctorProfile(user.email))
    }
  }

  useEffect(() => {
    if (doctorProfile) {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorProfile])

  useEffect(() => {
    if (user) {
      verifyDoctorProfile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleSubmit = (data) => {
    const createDoctorProfile = async () => {
      data.status = 'reviewing'
      if (!doctorProfile) {
        setIsLoading(true)
        const doctorProfileCreated = await dispatch(addNewDoctorProfile(data))
        if (doctorProfileCreated._id) {
          dispatch(getDoctorProfile(doctorProfileCreated.email))
          navigate(`/perfil-profesional/${doctorProfileCreated.email}`)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          showToast('Error al crear el perfil profesional, intente nuevamente', 'error')
        }
      } else {
        const workPlaces = doctorProfile.workPlaces
        const auxData = {
          ...data,
          workPlaces: workPlaces,
        }
        setIsLoading(true)
        await dispatch(updateDoctorProfile(auxData))
        setIsLoading(false)
      }
    }
    createDoctorProfile()
  }

  return (
    <>
      <div className={s.screenContainer}>
        <div>
          <NavBar />
        </div>
        {!hideDoctorProfile ? (
          <div className={s.divForm}>
            <div>
              <ContainerForm
                className={s.containerForm}
                onSubmit={(data) => handleSubmit(data)}
                defaultValues={{ ...formDefaultValues, ...user, ...doctorProfile }}
                resolver={yupResolver(formValidationSchema)}
              >
                <FormContent isLoading={isLoading} />
              </ContainerForm>
            </div>
          </div>
        ) : (
          <div className={s.infoCard}>
            <ErrorCard
              title="Tu perfil profesional ya está activo"
              text1="Si tienes dudas contáctate con soporte."
              path="/"
            />
          </div>
        )}
      </div>
    </>
  )
}

export default FormDoctorProfileReview
