import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'

import 'dayjs/locale/es'

export const initializeDayjs = () => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(localizedFormat)
  dayjs.extend(updateLocale)
  dayjs.extend(isBetween)
  dayjs.extend(isTomorrow)

  dayjs.locale('es')
  dayjs.updateLocale('es', {
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
    weekStart: 1,
  })
}
