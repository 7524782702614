import dayjs from 'dayjs'
import moment from 'moment-timezone'

export const isWorkTimeValid = (w, workTimes) => {
  let isValid = true

  if (!w.frequency) {
    return false
  }

  workTimes.forEach(function (i) {
    if (w._id && w._id === i._id) {
      return
    }

    // analyze saved workTimes with code 0 (weekly interval)
    if (i.frequency && i.frequency.code === 0) {
      let iStart = moment(moment(i.startTime).tz('America/Argentina/Buenos_Aires').format().slice(11, 16), 'HH:mm')
      let iFinish = moment(moment(i.finishTime).tz('America/Argentina/Buenos_Aires').format().slice(11, 16), 'HH:mm')
      let newDate = moment(w.frequency.startDay).utc().day()
      let newStart = moment(moment(w.startTime).tz('America/Argentina/Buenos_Aires').format().slice(11, 16), 'HH:mm')
      let newFinish = moment(moment(w.finishTime).tz('America/Argentina/Buenos_Aires').format().slice(11, 16), 'HH:mm')

      // compare only minutes, dates may differ
      let startOk =
        !newStart.isBetween(iStart, iFinish, undefined, '[)') && !iStart.isBetween(newStart, newFinish, undefined, '()')
      let finishOk =
        !newFinish.isBetween(iStart, iFinish, undefined, '(]') &&
        !iFinish.isBetween(newStart, newFinish, undefined, '()')

      let condCode0 = w.frequency.code === 0 && w.weekDay.code === i.weekDay.code // weekly interval
      let condCode1 = w.frequency.code === 1 && w.weekDay.code === i.weekDay.code // 15-days interval
      let condCode2 = w.frequency.code === 2 && newDate === i.weekDay.code // unique interval
      let hasSameCode = condCode0 || condCode1 || condCode2
      let isTimeValid = startOk && finishOk

      // check deferred intervals
      let isDateValid = false
      if (i.deferred && (i.deferred.from || i.deferred.to)) {
        let iFrom = i.deferred.from && moment(i.deferred.from).add(moment().utcOffset(), 'm').toISOString().slice(0, 10)
        let iTo = i.deferred.to && moment(i.deferred.to).add(moment().utcOffset(), 'm').toISOString().slice(0, 10)
        let condDeferred = false

        if (condCode0 && w.deferred && (w.deferred.from || w.deferred.to)) {
          let wFrom = w.deferred.from && moment(w.deferred.from).toISOString().slice(0, 10)
          let wTo = w.deferred.to && moment(w.deferred.to).toISOString().slice(0, 10)

          if (wFrom === null && iTo === null) {
            condDeferred = false
          } else if (wTo === null && iFrom === null) {
            condDeferred = false
          } else {
            condDeferred = wFrom >= iTo || wTo <= iFrom
          }
        }

        if (condCode1) {
          let wDate = dayjs(w?.frequency?.startDay).utc().format('YYYY-MM-DD')

          const maxWeeks = 60
          condDeferred = true
          for (let i = 1; i < maxWeeks; i++) {
            const auxCondDeferred = wDate >= iTo || wDate <= iFrom
            if (!auxCondDeferred) condDeferred = false

            wDate = dayjs(wDate)
              .add(14 * i, 'day')
              .format('YYYY-MM-DD')
          }
        }

        if (condCode2) {
          let wDate = moment(w.frequency.startDay).utc().toISOString().slice(0, 10)
          condDeferred = wDate >= iTo || wDate <= iFrom
        }

        isDateValid = condDeferred

        if (hasSameCode && !(isTimeValid || isDateValid)) {
          isValid = false
        }
      } else {
        // no deferred
        if (hasSameCode && !isTimeValid) {
          isValid = false
        }
      }
    }

    // analyze saved workTimes with code 1 (15-days interval)
    if (i.frequency && i.frequency.code === 1) {
      //TODO
    }

    // analyze saved workTimes with code 2 (unique interval)
    if (i.frequency && i.frequency.code === 2) {
      //TODO
    }
  })

  return isValid
}
