import React from 'react'
import { Link } from 'react-router-dom'
import CaluButton from '@/components/styledComponents/CaluButton'

import PropTypes from 'prop-types'

import s from './CardMsgWithIcon.module.scss'

const CardMsgWithIcon = ({ icon, title, text, textButton, path, iconClass }) => {
  return (
    <div className={s.mainContainer}>
      <div className={s.containerCard}>
        <i className={iconClass === 'greenColor' ? s.greenColor : s.defaultColor}>{icon}</i>
        <h3 className={s.title}>{title}</h3>
        <p className={s.text}>{text}</p>
        <div className={s.containerButton}>
          <Link to={path}>
            <CaluButton color="primary" size="medium">
              {textButton}
            </CaluButton>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CardMsgWithIcon

CardMsgWithIcon.propTypes = {
  icon: PropTypes.element,
  iconClass: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  textButton: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

CardMsgWithIcon.defaultProps = {
  iconClass: 'defaultColor',
}
