import { Controller, useController } from 'react-hook-form'
import TextEditor from '@/components/TextEditor'

import PropTypes from 'prop-types'

export const TextEditorField = ({ fullWidth, label, name, initValue, showLinkButton }) => {
  const {
    field: { onChange, value = initValue },
  } = useController({ name })

  return (
    <Controller
      name={name}
      render={() => <TextEditor onChange={onChange} value={value} showLinkButton={showLinkButton} />}
    />
  )
}

TextEditorField.propTypes = {
  className: PropTypes.string,
  initValue: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
}

TextEditorField.defaultProps = {
  fullWidth: false,
  initValue: null,
}
