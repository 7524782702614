import { useState } from 'react'
import { postFile } from '@/controllers/postFile'
import { showToast } from '@/utils/toast'

const formatFile = (image) => {
  if (!image) return
  let splitImage = image.split('.')
  return splitImage[splitImage.length - 1]
}

const SIZELIMIT = 52428800
const MAX_FILES = 4

export const useUploadFile = () => {
  const [loadingFile, setLoadingFile] = useState(false)

  const uploadFile = async (event, attached, setAttached) => {
    const files = event?.target?.files
    if (!files || files.length === 0) {
      return
    }

    if (attached?.length + files?.length > MAX_FILES) {
      showToast(`No puedes subir más de ${MAX_FILES} archivos`, 'error')
      return
    }

    let exceededSize = false
    const newAttached = [...attached]

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > SIZELIMIT) {
        showToast(`El archivo ${files[i].name} es demasiado grande`, 'error')
        exceededSize = true
        continue
      }

      setLoadingFile(true)
      const formData = new FormData()
      formData.append('file', files[i], files[i].name)
      let fileUpload = await postFile(formData)
      fileUpload = fileUpload?.split(' ')?.join('%20')

      let auxData = {
        format: formatFile(fileUpload),
        name: files[i].name,
        url: fileUpload,
      }

      newAttached.push(auxData)
    }

    setAttached(newAttached)
    setLoadingFile(false)

    if (exceededSize) {
      showToast('Algunos archivos son demasiado grandes y no se subieron.', 'warning')
    }
  }

  return {
    loadingFile,
    uploadFile,
  }
}
