import dayjs from 'dayjs'

export const toCustomTz = (s, tz = 'America/Argentina/Buenos_Aires', isFixed, format) => {
  if (!s) return
  // auxEntity?.workTimes?.[6]?.frequency?.startDay                                    ORIGINAL 2023-08-29T00:00:00Z
  // toCustomTz(auxEntity?.workTimes?.[6]?.frequency?.startDay, undefined, true, '')            2023-08-29T00:00:00-03:00
  // toCustomTz(auxEntity?.workTimes?.[6]?.frequency?.startDay, 'utc', true, '')                2023-08-28T21:00:00Z
  // toCustomTz(auxEntity?.workTimes?.[6]?.frequency?.startDay, undefined, false, '')           2023-08-28T21:00:00-03:00
  // toCustomTz(auxEntity?.workTimes?.[6]?.frequency?.startDay, 'utc', false, '')               2023-08-29T00:00:00Z

  if (isFixed) {
    // this changes the TZ **WITHOUT** changing date/time
    if (tz?.toLowerCase() === 'utc') {
      return dayjs(s).utc(true).format(format) // 1
    }
    return dayjs.tz(s, tz).format(format) // 2
  }
  // this changes the TZ changing date/time
  if (tz?.toLowerCase() === 'utc') {
    return dayjs(s).utc().format(format) // 3
  }
  return dayjs(s).tz(tz).format(format) // 4
}

// const string = '2019-08-01T05:00:00+12:00'
// console.log('Original string', string)
// const dateToSave = toArgTz(string, true)
// console.log('Date to save', dateToSave)

// const s2 = '2021-12-15T17:00:03.000Z'
// console.log(toArgTz(s2, false, 'HH:mm'))

// TODO ==> Tener soporte para cada pais
// const localStartDay = moment(selectedWorkTime[k].startDay)
//   .add(moment().utcOffset() * -1, 'minutes')
//   .format()
