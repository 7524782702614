import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import useDebounce from '@/hooks/useDebounce'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { Autocomplete, Box, Checkbox, Divider, Grid, Popper, styled, TextField } from '@mui/material'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

const MultipleItemsAutoComplete = ({
  name,
  multiple,
  control,
  setValue,
  stateRedux,
  functionToDispatch,
  inputLabel,
  className,
  noPaginate,
  popperComponentText,
}) => {
  const dispatch = useDispatch()

  const icon = <CheckBoxOutlineBlank fontSize="small" /> // Autocomplete Insurances
  const checkedIcon = <CheckBox fontSize="small" />

  const [allItemsChecked, setAllItemsChecked] = useState(false)
  const [openAutocompleteItems, setOpenAutocompleteItems] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  const DELAY_TIME = 300
  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)

  const PopperStyledComponent = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'}`,
  }))

  useEffect(() => {
    searchEntity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, inputDebounceValue])

  const searchEntity = async () => {
    const params = {
      search: inputDebounceValue,
    }

    if (noPaginate) {
      params.noPaginate = true
    }

    setLoading(true)
    await dispatch(functionToDispatch(params))
    setLoading(false)
  }

  const getItemsPopperComponent = (params) => (
    <PopperStyledComponent {...params}>
      <Box {...params} />
      <Divider />
      <div className={s.containerPopper}>
        <Box
          sx={{
            backgroundColor: 'white',
            height: '45px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <Checkbox
            checked={allItemsChecked}
            onChange={handleCheckAllItems}
            id="check-all"
            onMouseDown={(e) => e.preventDefault()}
          />
          {`Marcar ${popperComponentText}`}
        </Box>
      </div>
    </PopperStyledComponent>
  )

  const handleCheckAllItems = (e) => {
    setAllItemsChecked(e.target.checked)
    if (e.target.checked) {
      setValue(name, stateRedux)
    } else {
      setValue(name, [])
    }
  }

  return (
    <Grid item xs={12}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <Autocomplete
              {...field}
              multiple={multiple}
              className={className || ''}
              loading={loading}
              loadingText="Cargando..."
              options={stateRedux}
              open={openAutocompleteItems}
              onOpen={() => setOpenAutocompleteItems(true)} //cambiar de nbombre
              onClose={() => setOpenAutocompleteItems(false)} //cambiar nbombre
              disableCloseOnSelect={multiple ? true : false}
              ListboxProps={{
                style: {
                  maxHeight: '200px',
                },
              }}
              noOptionsText={'Sin opciones disponibles'}
              getOptionLabel={(option) => `${capitalizeFirstLetters(option?.name)} `}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option?._id}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected || allItemsChecked}
                  />
                  {option?.name}
                </li>
              )}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={inputLabel}
                  variant="standard"
                  sx={{
                    '&.Mui-InputBase-root': {
                      maxHeight: '100px',
                      overflow: 'scroll',
                    },
                    '&.MuiAutocomplete-endAdornment': {
                      right: '10px',
                    },
                    '&.MuiTextField-root': {
                      maxHeight: '170px',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    },
                  }}
                />
              )}
              onChange={(event, selectedValues, reason) => {
                if (reason === 'selectOption') {
                  setValue(name, selectedValues)
                } else if (reason === 'removeOption') {
                  setAllItemsChecked(false)
                  setValue(name, selectedValues)
                } else if (reason === 'clear') {
                  setValue(name, [])
                  setAllItemsChecked(false)
                }
                setSearchText('')
              }}
              PopperComponent={getItemsPopperComponent}
            />
          </>
        )}
      />
    </Grid>
  )
}

export default MultipleItemsAutoComplete

MultipleItemsAutoComplete.propTypes = {
  popperComponentText: PropTypes.string,
}

MultipleItemsAutoComplete.defaultProps = {
  popperComponentText: 'Marcar todos los items',
}
