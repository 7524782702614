import { FormProvider, useForm } from 'react-hook-form'

export const ContainerForm = ({
  children,
  className,
  id,
  onReset,
  onSubmit,
  mode,
  defaultValues,
  resolver,
  cleanOnSubmit = false,
  context,
  criteriaMode,
  delayError,
  shouldFocusError,
  shouldUnregister,
  shouldUseNativeValidation,
  reValidateMode = 'onChange',
}) => {
  const methods = useForm({
    mode,
    reValidateMode: 'onChange',
    defaultValues,
    resolver,
    context,
    criteriaMode,
    shouldFocusError,
    shouldUnregister,
    shouldUseNativeValidation,
    delayError,
  })

  const onError = (error) => {
    console.log({ error })
  }

  const onSubmitPrevent = (data, e) => {
    e.preventDefault()
    onSubmit(data, e)
  }

  return (
    <FormProvider {...methods}>
      <form
        className={className}
        id={id}
        onSubmit={methods.handleSubmit(onSubmitPrevent, onError)}
        onReset={() => {
          onReset?.()
          methods.reset()
        }}
        noValidate
      >
        {children}
      </form>
    </FormProvider>
  )
}
