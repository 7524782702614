import React from 'react'
import { default as Pad } from 'react-signature-canvas'

import s from './styles.module.scss'

const SignaturePad = ({ sigPad, handleMouseUp }) => {
  return (
    <div className={s.container}>
      <div className={s.sigContainer} onMouseUp={handleMouseUp}>
        <Pad canvasProps={{ className: s.sigPad }} ref={sigPad} />
      </div>
    </div>
  )
}

export default SignaturePad
