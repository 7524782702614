import dayjs from 'dayjs'
import { toCustomTz } from '../toCustomTz'

export const isNoWorkTimeValid = (w, noWorkTimes) => {
  let isNewValid = true

  noWorkTimes.forEach(function (i) {
    let iStart = dayjs(toCustomTz(i.startDate)).second(0)
    let iFinish = dayjs(toCustomTz(i.finishDate)).second(0)
    let newStart = dayjs(toCustomTz(w.startDate)).second(0)
    let newFinish = dayjs(toCustomTz(w.finishDate)).second(0)

    let startOk =
      !newStart.isBetween(iStart, iFinish, undefined, '[)') && !iStart.isBetween(newStart, newFinish, undefined, '()')
    let finishOk =
      !newFinish.isBetween(iStart, iFinish, undefined, '(]') && !iFinish.isBetween(newStart, newFinish, undefined, '()')

    let isCurrentValid = startOk && finishOk

    if (!isCurrentValid) {
      isNewValid = false
    }
  })

  return isNewValid
}
