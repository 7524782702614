import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import logo from '@/assets/images/turn.png'
import ButtonsPagination from '@/components/ButtonsPaginations'
import LinealProgress from '@/components/LinealProgress'
import LoaderCircle from '@/components/LoaderCircle'
import { useSocket } from '@/context/SocketProvider'
import useValidateURLId from '@/hooks/useValidateURLId'
import { getPatientCentreTurns, removeTurnById, updatePatienCentreTurn } from '@/store/turns/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { getChedocClassWithState } from '@/utils/functions/getChedocClassWithState'
import { getTurnStateName } from '@/utils/functions/getTurnStateName'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { showToast } from '@/utils/toast'
import { Button, Checkbox, Grid, Menu, MenuItem } from '@mui/material'

import dayjs from 'dayjs'
import { getChedocClassWithoutBg } from '../../utils/functions/getChedocClassWithoutBg'

import s from './styles.module.scss'

const TurnsHistory = ({ patientCentresTurns, directionOfAnimation = null, patientCentre }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const { socket } = useSocket()
  const centreID = useValidateURLId()

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()
  const { paginationData } = useSelector((state) => state.turns)
  const [checked, setChecked] = useState([])
  const [cancelingTurn, setCancelingTurn] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [loadingTurns, setLoadingTurns] = useState(false)
  const open = Boolean(anchorEl)
  const patientCentreId = patientCentre?._id

  const formatTurnDate = (startHour) => {
    let date = dayjs(startHour).utc().format('dddd DD/MM/YYYY HH:mm ')
    return date
  }

  const handleToggle = (turn) => () => {
    const currentIndex = checked.indexOf(turn)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(turn)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleClickCancelTurns = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenuCancelTurns = () => {
    setAnchorEl(null)
  }

  const cancelTurn = async (cancelledInfo) => {
    if (checked.length > 1) {
      setCancelingTurn(true)
      const hideAlert = true
      checked.forEach(async (turn, i) => {
        const auxTurn = {
          ...turn,
          state: 'cancelled',
          cancelledInfo: cancelledInfo,
        }
        let responseApi = await dispatch(updatePatienCentreTurn(auxTurn, hideAlert))
        socket.emit('calendar_edit_turn', `${centreID}_calendar`, responseApi)

        if (checked.length - 1 === i) {
          setAnchorEl(null)
          setCancelingTurn(false)
          setChecked([])
          showToast('Citas canceladas correctamente', 'success', 3000)
        }
      })
    } else {
      setCancelingTurn(true)
      const auxTurn = {
        ...checked[0],
        state: 'cancelled',
        cancelledInfo: cancelledInfo,
      }
      let responseApi = await dispatch(updatePatienCentreTurn(auxTurn))
      socket.emit('calendar_edit_turn', `${centreID}_calendar`, responseApi)
      setAnchorEl(null)
      setCancelingTurn(false)
      setChecked([])
    }
  }

  const cancelTurnByError = async () => {
    if (checked.length > 1) {
      setCancelingTurn(true)
      let hideAlert = true

      checked.forEach(async (turn, i) => {
        const removedTurn = await dispatch(removeTurnById(turn._id, hideAlert))
        if (removedTurn === turn._id) {
          socket.emit('calendar_delete_turn', `${centreID}_calendar`, turn)
        }
        if (checked.length - 1 === i) {
          setAnchorEl(null)
          setCancelingTurn(false)
          setChecked([])
          showToast('Citas eliminadas correctamente', 'success', 3000)
        }
      })
    } else {
      setCancelingTurn(true)
      const removedTurn = await dispatch(removeTurnById(checked[0]._id))
      if (removedTurn === checked[0]._id) {
        socket.emit('calendar_delete_turn', `${centreID}_calendar`, checked[0])
      }
      setAnchorEl(null)
      setCancelingTurn(false)
      setChecked([])
    }
  }

  // counters
  let turnsInit = 0
  let turnsAssistance = 0
  let turnsAbsent = 0
  let turnsCancelledDoctor = 0
  let turnsCancelledPatient = 0

  patientCentresTurns?.forEach((turn) => {
    // count turn state
    if (turn.state === 'init' || turn.state === 'confirmed') {
      turnsInit += 1
    } else if (turn.state === 'arrived' || turn.state === 'happening' || turn.state === 'finished') {
      turnsAssistance += 1
    } else if (turn.state === 'absent') {
      turnsAbsent += 1
    } else if (turn.state === 'cancelled') {
      if (turn.cancelledInfo === 'doctor') {
        turnsCancelledDoctor += 1
      } else if (turn.cancelledInfo === 'patient') {
        turnsCancelledPatient += 1
      }
    }
  })

  const showTurnService = (turn) => {
    const service = turn.service
    const extraServices = turn.extraServices
      .map((e) => e.name)
      .toString()
      .toUpperCase()

    if (!service && turn.firstTime === false) return '(CONSULTA)'
    if (!service && turn.firstTime === true) return '(PRIMERA CONSULTA)'
    if (service?._id) return '(' + service.name.toUpperCase() + ',' + extraServices + ')'
  }

  const showDoctorCentreName = (turn) => {
    if (turn?.doctorName) {
      return turn?.doctorName?.toUpperCase()
    } else if (turn?.machineName) {
      return turn?.machineName?.toUpperCase()
    } else if (turn?.doctorCentre?.lastname) {
      return `${turn?.doctorCentre?.lastname?.toUpperCase()}, ${turn?.doctorCentre?.firstname?.toUpperCase()}`
    } else {
      return 'Agenda eliminada'
    }
  }

  const showTitleState = (turn) => {
    if (turn?.state === 'pending') return s.pendingState
    return s[`${getChedocClassWithoutBg(turn.state)}`]
  }

  const getTurnClassName = (turn) => {
    if (turn.state === 'cancelled') {
      return 'chedoc-e-bg'
    } else {
      return getChedocClassWithState(turn?.state)
    }
  }

  const handleChangePaginationNumber = async (page) => {
    setLoadingTurns(true)
    await dispatch(getPatientCentreTurns(centreID, patientCentreId, page))
    setLoadingTurns(false)
  }

  return patientCentresTurns === null ? (
    <LoaderCircle />
  ) : patientCentresTurns?.length === 0 ? (
    <p className={s.infoSearch}>
      El {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)} seleccionado aún no tiene{' '}
      {t('turnLabel.p')}.
    </p>
  ) : (
    <Grid container className={`${s.containerDetailTurns} ${directionOfAnimation}`}>
      <Grid item md={4} xs={12} className={s.assistedTurns}>
        <h4>{`${((turnsAssistance / patientCentresTurns?.length) * 100).toFixed(1)} %`}</h4>
        <h6>
          <b>{turnsAssistance}</b> {t('turnLabel.p')} asistió
        </h6>
      </Grid>
      <Grid item md={4} xs={12} className={s.absentsTurns}>
        <h6>
          <b>{((turnsAbsent / patientCentresTurns?.length) * 100).toFixed(1)} %</b> ({turnsAbsent}) {t('turnLabel.p')}{' '}
          ausentes
        </h6>
        <h6>
          <b>{((turnsCancelledPatient / patientCentresTurns?.length) * 100).toFixed(1)} %</b> ({turnsCancelledPatient})
          {t('turnLabel.pc')} por el {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}
        </h6>
        <h6>
          <b>{((turnsCancelledDoctor / patientCentresTurns?.length) * 100).toFixed(1)} %</b> ({turnsCancelledDoctor})
          {t('turnLabel.pc')} por el profesional`
        </h6>
      </Grid>
      <Grid item md={4} xs={12} className={s.futureTurns}>
        <h4>{`${((turnsInit / patientCentresTurns?.length) * 100).toFixed(1)} %`}</h4>
        <h6>
          <b>{turnsInit}</b> {t('turnLabel.ppr')}
        </h6>
      </Grid>

      {cancelingTurn ? (
        <LinealProgress />
      ) : checked?.length === 0 ? null : (
        <div className={s.btnCancelTurns}>
          <Button
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClickCancelTurns}
            variant="outlined"
          >
            Cancelar {t('turnLabel.psl')}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenuCancelTurns}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem className={s.options} onClick={() => cancelTurn('patient')}>
              Cancelados por {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}
            </MenuItem>
            <MenuItem className={s.options} onClick={() => cancelTurn('doctor')}>
              Cancelados por profesional
            </MenuItem>
            <MenuItem className={s.options} onClick={cancelTurnByError}>
              Cancelados por error al cargar
            </MenuItem>
          </Menu>
        </div>
      )}

      <Grid item container spacing={2} className={s.turnsList}>
        {loadingTurns ? (
          <LoaderCircle />
        ) : (
          <>
            {patientCentresTurns?.map((turn) => (
              <Grid item xs={12} key={turn._id} className={s.itemListTurn}>
                <div className={`${s[getTurnClassName(turn)]} ${s.borderNone}`}>
                  <div className={turn?.state === 'pending' ? s.pendingItem : s.item}>
                    <div className={s.itemDetail}>
                      <img src={logo} alt="turn" className={s.imageFont} />

                      <div className={s.data}>
                        <small>
                          <span>{t('turnLabel.s')}</span> -{' '}
                          <span className={showTitleState(turn)}>{getTurnStateName(turn?.state)}</span>
                        </small>
                        <div className={s.doctor}>
                          <b>{showDoctorCentreName(turn)}</b>
                          <i>{showTurnService(turn)}</i>
                          {turn?.totalPayments > 0 && (
                            <span className={s.totalPayments}>
                              <b>Pago total:</b> {handleSetMoneySign(country)} {turn?.totalPayments}
                            </span>
                          )}
                        </div>
                        <div className={s.date}>
                          <b>{formatTurnDate(turn?.startHour)} hs</b>
                        </div>
                      </div>
                    </div>
                    {turn?.state === 'arrived' || turn?.state === 'init' ? (
                      <div>
                        <Checkbox edge="end" onChange={handleToggle(turn)} checked={checked.indexOf(turn) !== -1} />
                      </div>
                    ) : null}
                  </div>
                </div>
              </Grid>
            ))}
          </>
        )}
      </Grid>

      <ButtonsPagination paginationData={paginationData} handleChangePaginationNumber={handleChangePaginationNumber} />
    </Grid>
  )
}

export default TurnsHistory
