import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'
import { reducePayload } from './reducePayload'

const fetchClinicHistories = async (params) => {
  const route = `api/clinicHistories`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchSingleClinicHistory = async (id) => {
  const route = `api/clinicHistories/${id}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchClinicHistoryBackup = async (centreId) => {
  const route = `api/clinicHistories/backup/${centreId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewClinicHistory = async (clinicHistory) => {
  const auxClinicHistory = reducePayload(clinicHistory)

  const route = `api/clinicHistories`
  return API.post(route, auxClinicHistory)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putClinicHistory = async (clinicHistory) => {
  const auxClinicHistory = reducePayload(clinicHistory)

  const route = `api/clinicHistories`
  return API.put(route, auxClinicHistory)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteClinicHistory = async (id) => {
  const route = `api/clinicHistories/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export {
  addNewClinicHistory,
  deleteClinicHistory,
  fetchClinicHistories,
  fetchClinicHistoryBackup,
  fetchSingleClinicHistory,
  putClinicHistory,
}
