export const reducePayload = (machine) => {
  if (!machine) return {}
  // to force deep copy
  let auxMachine = JSON.parse(JSON.stringify(machine))

  // reduce machine.services
  if (auxMachine?.services) {
    auxMachine.services = auxMachine.services.map((s) => (s?._id ? s._id : s))
  }

  // reduce machine.workTimes.services
  if (auxMachine?.workTimes) {
    auxMachine.workTimes = auxMachine.workTimes.map((wt) => {
      const auxWt = {
        ...wt,
        services: wt.services?.map((s) => (s?._id ? s._id : s)),
        doctorCentres: wt.doctorCentres?.map((d) => (d?._id ? d._id : d)),
      }
      return auxWt
    })
  }

  // reduce machine.centre.services
  if (auxMachine?.centre?.services) {
    auxMachine.centre.services = auxMachine.centre.services.map((s) => (s?._id ? s._id : s))
  }

  return auxMachine
}
