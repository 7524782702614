import { createSlice } from '@reduxjs/toolkit'

const handleSortNotifications = (arrayNotifications) => {
  if (!arrayNotifications?.length) return
  let sortNotifications = arrayNotifications
    .sort((a, b) => (a.creationDate > b.creationDate ? 1 : -1))
    .sort((a, b) => (a.highlight < b.highlight ? 1 : -1))
  return sortNotifications
}

const slice = createSlice({
  name: 'notifications',
  initialState: {
    notificationsState: false,
    allNotifications: [],
    notificationReads: null,
    notifications: null,
    notificationSelected: null,
    availableNotifications: true,
  },
  reducers: {
    GET_NOTIFICATIONS: (state, { payload }) => {
      const sortNotifications = handleSortNotifications(payload)
      state.notifications = sortNotifications
    },
    GET_NOTIFICATION_READS: (state, { payload }) => {
      state.notificationReads = payload
    },
    SAVE_FILTERED_NOTIFICATIONS: (state, { payload }) => {
      state.allNotifications = payload
    },
    MARK_NOTIFICATION_AS_READ: (state, { payload }) => {
      const index = state.allNotifications.findIndex((notification) => notification?._id === payload.notification)
      index >= 0 && (state.allNotifications[index]['isRead'] = true)
    },
    SET_NOTIFICATIONS_STATE: (state, { payload }) => {
      state.notificationsState = payload
    },
    NOTIFICATION_CREATED: (state, { payload }) => {
      const allNotifications = [payload, ...(state.notifications || [])]
      const sortNotifications = handleSortNotifications(allNotifications)
      state.notifications = sortNotifications
    },
    NOTIFICATION_UPDATED: (state, { payload }) => {
      const allNotifications = state.notifications.map((notification) => {
        if (notification._id === payload._id) {
          notification = payload
        }
        return notification
      })
      const sortNotifications = handleSortNotifications(allNotifications)
      state.notifications = sortNotifications
      state.notificationSelected = payload
    },
    NOTIFICATION_DELETE: (state, { payload }) => {
      state.notifications = state.notifications.filter((notification) => notification._id !== payload)
      state.notificationSelected = null
    },
    NOTIFICATION_SELECTED: (state, { payload }) => {
      state.notificationSelected = payload
    },
    RESET_NOTIFICATION_SELECTED: (state, { payload }) => {
      state.notificationSelected = null
    },
    SET_AVAILABLE_NOTIFICATIONS: (state, { payload }) => {
      state.availableNotifications = payload
    },
  },
})

export const {
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_READS,
  SAVE_FILTERED_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ,
  SET_NOTIFICATIONS_STATE,
  NOTIFICATION_CREATED,
  NOTIFICATION_UPDATED,
  NOTIFICATION_DELETE,
  NOTIFICATION_SELECTED,
  RESET_NOTIFICATION_SELECTED,
  SET_AVAILABLE_NOTIFICATIONS,
} = slice.actions
export default slice.reducer
