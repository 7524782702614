import React from 'react'
import QRCode from 'react-qr-code'
import { useDispatch, useSelector } from 'react-redux'
import videoTutorialWP from '@/assets/videos/escanearQr.mp4'
import LoaderCircle from '@/components/LoaderCircle'
import LoadingCalu from '@/components/LoadingCalu'
import CaluButton from '@/components/styledComponents/CaluButton'
import { CLEAN_WAPP_STATUS } from '@/store/wapp'
import { linkDownDevice, linkUpDevice } from '@/store/wapp/actions'
import AddLinkIcon from '@mui/icons-material/AddLink'
import { Paper } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const CardWappConnection = ({ handleTabChange }) => {
  const dispatch = useDispatch()
  const { status: statusWapp, isLoading } = useSelector((state) => state.wapp)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isAdmin, entityInLocalStorage } = usersProfileConnected
  const deviceId = entityInLocalStorage?._id

  const statusNames = {
    disconnected: 'Sin conexión',
    scanQr: 'Aguardando escaneo de QR',
    connected: 'Conectado',
    connecting: 'Conectando...',
  }
  const currentStatus = statusWapp?.status

  const iconColor = () => {
    if (currentStatus === 'scanQr') return 'iconQr'
    if (currentStatus === 'connected') return 'iconConnected'
    if (currentStatus === 'disconnected') return 'iconDisconnected'
    if (currentStatus === 'connecting') return 'iconConnecting'
  }

  const handleLogOut = () => {
    dispatch(CLEAN_WAPP_STATUS())
    dispatch(linkDownDevice(deviceId))
  }

  const handleLinkDevice = () => {
    dispatch(CLEAN_WAPP_STATUS())
    dispatch(linkUpDevice(deviceId))
  }
  return (
    <div className={s.body}>
      {!isLoading && statusWapp !== null ? (
        <Paper className={currentStatus === 'scanQr' ? `${s.contentPaper} ${s.contentPaperQr}` : s.contentPaper}>
          <div className={s.containerWappStatus}>
            <AddLinkIcon className={s[`${iconColor()}`]} /> Estado:
            <span className={s[`${currentStatus}`]}>{statusNames[currentStatus]} </span>
          </div>
          {currentStatus !== 'connected' && (
            <div className={s.containerWappStatus}>
              {currentStatus === 'disconnected'
                ? 'Para poder enviar recordatorios de manera automática es necesario que enlace su dispositivo móvil con WhatsApp.'
                : 'Por favor escanea el código desde tu WhatsApp'}
            </div>
          )}

          {currentStatus === 'connected' && (
            <div className={s.containerWappInfo}>
              <div>
                {`Celular Conectado: `}
                <strong>{statusWapp?.phoneNumber}</strong>
                {` (${statusWapp?.phoneName})`}
              </div>
              <div>{`Última conexión: ${dayjs(statusWapp?.phoneLastSync).format(
                'DD/MM/YYYY [a las] HH:mm [hs]',
              )}`}</div>
            </div>
          )}

          {currentStatus === 'connecting' && (
            <div className={s.loadingQrContainer}>
              <LoaderCircle size={40} />
            </div>
          )}

          {currentStatus === 'scanQr' && (
            <div className={s.containerQrComponent}>
              <div className={s.contItemQr}>
                <QRCode size={200} value={statusWapp?.connection?.qr} viewBox={`0 0 256 256`} />

                <video width="350" autoPlay muted loop controls>
                  <source src={videoTutorialWP} type="video/mp4" />
                </video>
              </div>
            </div>
          )}

          {currentStatus === 'disconnected' && (
            <div className={s.containerButton}>
              <CaluButton color="primary" size="small" disabled={isAdmin} onClick={handleLinkDevice}>
                Enlazar dispositivo
              </CaluButton>
            </div>
          )}

          {currentStatus === 'connecting' && (
            <div className={s.containerButton}>
              <CaluButton
                color="secondary"
                size="small"
                disabled={isAdmin}
                onClick={() => dispatch(linkDownDevice(deviceId))}
              >
                No conecta? Prueba conectar nuevamente
              </CaluButton>
            </div>
          )}

          {currentStatus === 'connected' && (
            <div className={s.containerButtons}>
              <CaluButton color="error" size="small" onClick={handleLogOut}>
                Cerrar conexión
              </CaluButton>
              <CaluButton color="primary" size="small" onClick={() => handleTabChange(null, 1)}>
                Ir a recordatorios
              </CaluButton>
            </div>
          )}
        </Paper>
      ) : (
        <LoadingCalu loadingText="Cargando..." />
      )}
    </div>
  )
}

export default CardWappConnection
