import React, { useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { TextEditorField } from '@/components/Form/customFields/TextEditorField'
import CaluButton from '@/components/styledComponents/CaluButton'
import { deleteWappTemplate } from '@/store/wappMessageTemplates/actions'
import { Grid } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = ({ handleClose, submitLoading = false }) => {
  const dispatch = useDispatch()

  const [openAlertDelete, setOpenAlertDelete] = useState(false)

  const { selectedTemplate } = useSelector((state) => state.wappMessageTemplates)

  const fieldBody = useWatch({ name: 'body' })

  const deleteTemplate = async () => {
    dispatch(deleteWappTemplate(selectedTemplate._id))
    handleClose()
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomField label="Nombre identificatorio *" name="name" disabled fullWidth />
        </Grid>
        <Grid item xs={12} className={s.message}>
          <p>
            Para personalizar la plantilla, puedes utilizar los siguientes campos/variables:
            <br />
            <b>[apellido]</b>, <b>[nombre]</b>, <b>[nombre-centro]</b>
            <br />
            <br />
            Ejemplo: "Hola [nombre], desde [nombre-centro] te deseamos un Feliz Cumpleaños!"
          </p>
        </Grid>

        <Grid item xs={12}>
          <p className={s.labelEditor}>Mensaje personalizado</p>
          <TextEditorField name="body" showLinkButton={false} />
        </Grid>

        <Grid item xs={12}>
          <div className={s.divider}></div>
        </Grid>

        <Grid item xs={12} className={s.containerButtonsForm}>
          {selectedTemplate && (
            <CaluButton size="medium" color="error" onClick={() => setOpenAlertDelete(!openAlertDelete)}>
              Eliminar
            </CaluButton>
          )}
          <CaluButton size="medium" color="primary" type="submit" loading={submitLoading} disabled={!fieldBody}>
            Guardar
          </CaluButton>
        </Grid>
        <AlertDialogSlide
          open={openAlertDelete}
          setOpen={setOpenAlertDelete}
          textContent={'¿Realmente quiere eliminar este cuerpo de mensaje?'}
          textBackButton="Cancelar"
          textConfirmationButton={'Eliminar'}
          onClose={deleteTemplate}
        />
      </Grid>
    </>
  )
}
