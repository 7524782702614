import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'cashboxItems',
  initialState: {
    allCashboxItemsCombined: [],
    cashboxEntries: [], // Parsed state with turns and cashboxItems
    cashLoading: false,
    paginationData: {
      page: 1,
      limit: 30,
    },
    lastPaymentSearch: {
      from: null,
      to: null,
    },
    selectedCashboxItem: null,
    selectedServiceInFilter: null,
    selectedEntryType: null,
    totals: null,
  },
  reducers: {
    FETCH_CASHBOX_ITEMS_COMBINED: (state, { payload }) => {
      state.allCashboxItemsCombined = payload.fetchAllCashboxItemsCombined
      state.paginationData = payload.paginationData
    },
    FETCH_CASHBOX_ENTRIES: (state, { payload }) => {
      state.cashboxEntries = payload
    },
    FETCH_CASHBOX_ITEMS_COMBINED_TOTALS: (state, { payload }) => {
      state.totals = payload
    },
    CASHBOX_ITEM_COMBINED_UPDATED: (state, { payload }) => {
      state.allCashboxItemsCombined = state.allCashboxItemsCombined.map((cashboxItem) => {
        if (cashboxItem?._id === payload?._id) {
          cashboxItem = payload
        }
        return cashboxItem
      })
    },
    CASHBOX_ITEM_DELETE: (state, { payload }) => {
      state.allCashboxItemsCombined = state.allCashboxItemsCombined.filter((cashboxItem) => cashboxItem._id !== payload)
    },
    CASHBOX_ITEM_COMBINED_CREATED: (state, { payload }) => {
      state.allCashboxItemsCombined = [...(state.allCashboxItemsCombined || []), payload]
    },
    CASH_LOADING: (state, { payload }) => {
      state.cashLoading = payload
    },
    LAST_PAYMENT_SEARCH: (state, { payload }) => {
      state.lastPaymentSearch = payload
    },
    CASHBOX_ITEM_SELECTED: (state, { payload }) => {
      state.selectedCashboxItem = payload
    },
    RESET_CASHBOX_ITEM_SELECTED: (state, { payload }) => {
      state.selectedCashboxItem = null
    },
    SELECTED_SERVICE_IN_FILTER: (state, { payload }) => {
      state.selectedServiceInFilter = payload
    },
    SELECTED_ENTRY_TYPE: (state, { payload }) => {
      state.selectedEntryType = payload
    },
  },
})

export const {
  FETCH_CASHBOX_ITEMS_COMBINED,
  FETCH_CASHBOX_ITEMS_COMBINED_TOTALS,
  CASHBOX_ITEM_COMBINED_UPDATED,
  CASHBOX_ITEM_DELETE,
  CASHBOX_ITEM_COMBINED_CREATED,
  FETCH_CASHBOX_ENTRIES,
  CASH_LOADING,
  LAST_PAYMENT_SEARCH,
  CASHBOX_ITEM_SELECTED,
  RESET_CASHBOX_ITEM_SELECTED,
  SELECTED_SERVICE_IN_FILTER,
  SELECTED_ENTRY_TYPE,
} = slice.actions
export default slice.reducer
