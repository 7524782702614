import { fetchClinicHistoriesFragments } from '@/services/clinicHistoryFragments'

export const setAllHCFragmentsController = async (params) => {
  try {
    const fetchAllHCFragments = await fetchClinicHistoriesFragments(params)
    return fetchAllHCFragments
  } catch (error) {
    console.log(error.message)
    return error
  }
}
