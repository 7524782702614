import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { selectedInsurancePrice } from '@/store/insurancePrices/actions'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Button, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import PopUpCreateInsurance from './components/PopUpCreateInsurance'
import SearchBar from '../searchBar'

import s from '../../styles.module.scss'

const InsurancesTab = ({ indexTab }) => {
  const { t } = useTranslation('global')
  const dispatch = useDispatch()

  const [insurancePricesFiltered, setInsurancePricesFiltered] = useState([])
  const [inputSearchInsurances, setInputSearchInsurances] = useState('')
  const [openPopUp, setOpenPopUp] = useState(false)
  const [pageSize, setPageSize] = useState(20)

  const { allInsurancePrices } = useSelector((state) => state.insurancePrices)

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  }

  const columns = [
    { field: 'code', headerName: 'Código', width: 180, sortable: false, ...stylesColumns },

    {
      field: 'insurance',
      headerName: `${t('insuranceLabel.S')}`,
      sortable: false,
      minWidth: 300,
      ...stylesColumns,
    },
    {
      field: 'service',
      headerName: `Servicio`,
      sortable: false,
      minWidth: 300,
      ...stylesColumns,
    },
    {
      field: 'description',
      headerName: 'Descripción',
      minWidth: 300,
      sortable: false,
      ...stylesColumns,
    },
    {
      field: 'price',
      headerName: `Precio (${handleSetMoneySign(country)})`,
      minWidth: 300,
      type: 'number',
      sortable: false,
      ...stylesColumns,
    },
    {
      field: 'button',
      headerName: '',
      minWidth: 80,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header',
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title="Editar">
          <Button onClick={() => handleClick(params?.row)} className={s.rowButton} tabIndex={params.hasFocus ? 0 : -1}>
            <EditOutlinedIcon />
          </Button>
        </Tooltip>
      ),
    },
  ]

  const rows = insurancePricesFiltered?.map((plan) => ({
    id: plan?._id,
    code: plan?.code,
    insurance: plan?.insurance?.name || '',
    service: plan?.service?.name?.toUpperCase() || '',
    description: plan?.description || '',
    price: String(plan?.price || 0),
    raw: plan,
  }))

  const handleClick = async (data) => {
    const { insurance } = insurancePricesFiltered?.filter((i) => i._id === data.id)[0]

    const auxData = {
      ...data,
      insurance: insurance,
    }
    await dispatch(selectedInsurancePrice(auxData?.raw?._id))
    setOpenPopUp(true)
  }

  const handlePaginationChange = ({ pageSize }) => {
    setPageSize(pageSize)
  }

  return (
    <div>
      <SearchBar
        inputSearch={inputSearchInsurances}
        setInputSearch={setInputSearchInsurances}
        prevState={allInsurancePrices}
        setFilteredState={setInsurancePricesFiltered}
        openPopUp={openPopUp}
        setOpenPopUp={setOpenPopUp}
        indexTab={indexTab}
      />
      {allInsurancePrices?.length === 0 ? (
        <p className={s.emptyList}>{`No hay ${t('insuranceLabel.p')}`}</p>
      ) : (
        <div>
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: pageSize,
                },
              },
            }}
            rows={rows || []}
            columns={columns}
            pagination
            onPaginationModelChange={handlePaginationChange}
            pageSizeOptions={[20, 50, 100]}
            slotProps={{
              pagination: {
                labelRowsPerPage: 'Entradas por página:',
                labelDisplayedRows: (props) => `${props.from} - ${props.to} de ${props.count}`,
              },
            }}
            disableRowSelectionOnClick={true}
            rowHeight={45}
            autoHeight={true}
            onCellClick={(params) => handleClick(params?.row)}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            sx={{
              px: 0,
              '& .header': {
                fontSize: '14px',
              },
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
            }}
          />
        </div>
      )}

      <PopUpCreateInsurance open={openPopUp} setOpen={setOpenPopUp} />
    </div>
  )
}

export default InsurancesTab
