import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import LoaderCircle from '@/components/LoaderCircle'
import useDebounce from '@/hooks/useDebounce'
import { deletePatientAdded, getPatients, resetSelectedPatient } from '@/store/patients/actions'

import AdminPatientsSearchBar from './components/AdminPatientsSearchBar'
import AdminPatientsTable from './components/AdminPatientsTable'
import Layout1 from '../../layouts/Layout1/index'

import s from './styles.module.scss'

const AdminPatientsPage = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const DELAY_TIME = 300
  const MIN_CHARACTERS = 0
  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)
  const { allPatients, patientSelected: patientId } = useSelector((state) => state.patients)

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const inputSearch = async () => {
      if (inputDebounceValue?.length >= MIN_CHARACTERS) {
        getPatientBySearch()
      }
    }
    inputSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDebounceValue])

  const setInit = async () => {
    setIsLoading(true)
    await dispatch(getPatients())
    setIsLoading(false)
  }

  const getPatientBySearch = async () => {
    const params = {
      search: inputDebounceValue,
    }
    setIsLoading(true)
    await dispatch(getPatients(params))
    setIsLoading(false)
  }

  const deletePatient = async () => {
    await dispatch(deletePatientAdded(patientId))
    dispatch(resetSelectedPatient())
    setOpen(false)
  }

  return (
    <Layout1 navbar>
      <div className={`${s.animatedContainer} animate__animated animate__fadeIn`}>
        <AdminPatientsSearchBar inputSearch={searchText} setInputSearch={setSearchText} />
        {isLoading ? (
          <LoaderCircle size={50} />
        ) : allPatients?.length === 0 ? (
          <p className={s.noItems}>No hay perfil de pacientes cargados aún</p>
        ) : (
          <AdminPatientsTable setOpen={setOpen} searchText={searchText} />
        )}
      </div>
      <AlertDialogSlide
        title="Eliminar perfil de paciente"
        open={open}
        setOpen={setOpen}
        textContent={'¿Está seguro que quiere eliminar este perfil de paciente?'}
        textContentExtraOptional="Esta acción no se podrá deshacer."
        textBackButton="Cancelar"
        textConfirmationButton={'Eliminar'}
        onClose={deletePatient}
      />
    </Layout1>
  )
}

export default AdminPatientsPage
