import { css } from 'styled-components'

export const FullCalendarStyles = css`
  .fc-toolbar {
    position: fixed;
    right: 35%;
    margin-top: -55px;
    z-index: 2;

    @media (max-width: 950px) {
      right: 5%;
    }
  }

  .fc-agendaDay-view {
    .fc-head {
      display: none;
    }
  }

  .fc-agendaWeek-view {
    .fc-head {
      background: ${(props) => props.theme.colors.base.white};
      position: absolute;
      top: -6px;
      padding: 5px 0px;
      z-index: 2;
    }

    .fc-body {
      display: inline-block;
      margin-top: 25px;
    }
  }

  .fc-bg {
    .fc-widget-content {
      background-color: ${(props) => props.theme.colors.base.white};
    }
  }

  .fc-head-container {
    border: none !important;
    border-bottom: 1px solid;

    .fc-axis {
      border-color: transparent !important;
    }

    .fc-day-header {
      border-color: transparent !important;
    }
  }

  .fc-direction-ltr .fc-timegrid-col-events {
    margin: 0 12% 0 2px;
  }

  .fc-event {
    border: none;

    .fc-event-main {
      color: #34495e;
    }

    &.turn {
      background-color: ${(props) => props.theme.colors.turn.bg};

      &.init {
        .event-status {
          background-color: ${(props) => props.theme.colors.turn.init};
        }
      }

      &.confirmed {
        .event-status {
          background-color: ${(props) => props.theme.colors.turn.confirmed};
        }
      }

      &.finished {
        .event-status {
          background-color: ${(props) => props.theme.colors.turn.finished};
        }
      }

      &.cancelled {
        p {
          text-decoration: line-through;
          opacity: 0.5;
        }

        .event-status {
          background-color: ${(props) => props.theme.colors.turn.cancelled};
        }
      }

      &.arrived {
        .event-status {
          background-color: ${(props) => props.theme.colors.turn.arrived};
        }
      }

      &.happening {
        .event-status {
          background-color: ${(props) => props.theme.colors.turn.happening};
        }
      }

      &.absent {
        .event-status {
          background-color: ${(props) => props.theme.colors.turn.absent};
        }
      }

      &.patient-deleted {
        p {
          opacity: 0.4;
        }
      }
    }
  }

  /* Remove that awful yellow color and border from today in Schedule */
  .fc-today {
    background-color: ${(props) => props.theme.colors.base.white} !important;
  }

  /* Styling for each event from Schedule */
  // Event item
  .fc .fc-timegrid-event {
    border-radius: 3px;
    box-shadow: none;
    margin: 0px 2px 0px 0px;
    box-shadow: 0px 0px 1px 1px rgb(198 202 210);
  }

  .fc .fc-timegrid-now-indicator-line {
    position: absolute;
    z-index: 4;
    left: 0;
    right: 0;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.a} !important;
    border-width: 1px 0 0;
  }

  .fc .fc-timegrid-now-indicator-arrow {
    position: absolute;
    z-index: 4;
    margin-top: -5px; /* vertically center on top coordinate */
    border-style: solid;
    border-color: ${(props) => props.theme.colors.a};
  }

  .fc-direction-ltr .fc-timegrid-now-indicator-arrow {
    left: 0;

    /* triangle pointing right. TODO: mixin */
    border-width: 5px 0 5px 6px;
    border-top-color: transparent;
    border-bottom-color: transparent;
  }

  .fc-day-today {
    background-color: ${(props) => props.theme.colors.base.white} !important;
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    border: none;
  }

  .fc-theme-standard .fc-scrollgrid {
    border: none;
    border-top: 1px solid #ddd;
  }

  .fc .fc-timegrid-slot-label-cushion {
    padding: 0 4px;
    font-size: 13px;
  }

  .fc-bg-event {
    background-color: #777 !important;
    cursor: not-allowed;
    opacity: 0.6;
    border: none;
  }

  .fc-timegrid-bg-harness {
    border: none !important;
    background-color: #777 !important;
    opacity: 0.6;
  }

  .fc-non-business {
    cursor: not-allowed;
    background-color: transparent !important;
  }

  .fc-timegrid-slot {
    line-height: 2.5em;
  }

  .fc-timegrid-cols {
    width: 100%;
  }
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 1px solid #ddd;
    border: 1px solid var(--fc-border-color, #ddd);
  }

  .fc-col-header-cell-cushion {
    font-size: 12px;
    font-weight: 500;
  }
`
