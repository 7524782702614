import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import HolidaysModule from '@/components/holidaysModule'
import WorkingHoursModule from '@/components/workingHoursModule'
import TurnsWithoutDate from '@/pages/AgendaPage/components/turnsWithoutDate'
import { updateDoctorCentres } from '@/store/doctorCentres/actions'
import { directionOfAnimation } from '@/utils/functions/directionOfAnimation'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab, tabsClasses } from '@mui/material'

import Amounts from './components/amounts'
import PersonalInformation from './components/personalInformation'

import s from './styles.module.scss'

const FormCreateDoctorCentre = ({ handleClose, entity, openDoctorPopUp }) => {
  const { t } = useTranslation('global')
  let editFromCalendar = document.getElementById('Edit from calendar')

  const [prevValuePanel, setPrevValuePanel] = useState('0')

  const centreConfiguration = useSelector((state) => state.centreConfigurations.centreConfigurationDetail)
  const hideDoctorWorkTimes = centreConfiguration?.hideDoctorWorkTimes

  const handleSetValuePanel = () => {
    if (entity && !editFromCalendar) return '1'
    if (entity && editFromCalendar && !hideDoctorWorkTimes) return '2'
    if (entity && editFromCalendar && hideDoctorWorkTimes) return '1'
    return '1'
  }
  const [valuePanel, setValuePanel] = useState(handleSetValuePanel())

  const handleChangePanel = (event, newValue) => {
    setPrevValuePanel(valuePanel)
    setValuePanel(newValue)
  }

  const addWorkTimes = () => {
    setValuePanel('1')
  }

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <TabContext value={valuePanel}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChangePanel}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="Pestaña historias clínicas"
              sx={{
                '& .MuiTab-root.Mui-selected': {
                  color: '#009688',
                },
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
            >
              <Tab label="Información personal" value="1" className={s.paddingTab} />

              {!hideDoctorWorkTimes && (
                <Tab disabled={entity ? false : true} label="Horarios de atención" value="2" className={s.paddingTab} />
              )}

              {!hideDoctorWorkTimes && (
                <Tab
                  disabled={entity ? false : true}
                  label="Bloqueo de calendario"
                  value="3"
                  className={s.paddingTab}
                />
              )}

              <Tab disabled={entity ? false : true} label="Montos y 1° visita" value="4" className={s.paddingTab} />
              <Tab
                disabled={entity ? false : true}
                label={`Ver ${t('turnLabel.p')} sin fecha`}
                value="5"
                className={s.paddingTab}
              />
            </TabList>
          </Box>

          <TabPanel value="1">
            <PersonalInformation
              entity={entity}
              handleClose={handleClose}
              addWorkTimes={addWorkTimes}
              fromMyProfile={false}
            />
          </TabPanel>

          {!hideDoctorWorkTimes && (
            <TabPanel value="2">
              <WorkingHoursModule
                entity={entity}
                updateFunction={updateDoctorCentres}
                directionOfAnimation={directionOfAnimation(valuePanel, prevValuePanel)}
              />
            </TabPanel>
          )}

          <TabPanel value="3">
            <HolidaysModule
              entity={entity}
              updateFunction={updateDoctorCentres}
              directionOfAnimation={directionOfAnimation(valuePanel, prevValuePanel)}
            />
          </TabPanel>

          <TabPanel value="4">
            <Amounts entity={entity} handleClose={handleClose} addWorkTimes={addWorkTimes} />
          </TabPanel>

          <TabPanel value="5">
            <TurnsWithoutDate entity={entity} />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  )
}

export default FormCreateDoctorCentre
