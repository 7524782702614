import { fetchClinicHistories } from '@/services/clinicHistory'

export const setAllClinicHistoriesController = async (params) => {
  try {
    const { docs: allClinicHistories, ...paginationData } = await fetchClinicHistories(params)
    const fetchAllClinicHistories = {
      allClinicHistories: allClinicHistories,
      paginationData: paginationData,
    }
    return fetchAllClinicHistories
  } catch (error) {
    console.log(error.message)
    return error
  }
}
