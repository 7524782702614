import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useUser } from '@/context/UserProvider'
import { blockedCentreAction } from '@/store/centres/actions'
import { resetApp } from '@/store/resetApp'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import DoneIcon from '@mui/icons-material/Done'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import ListSubheader from '@mui/material/ListSubheader'

import s from '../../styles.module.scss'

export default function SelectEntity() {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const { user } = useUser()
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const navigate = useNavigate()
  const hasCentre = user?.centres
  const hasDoctorCentres = user?.doctorCentres
  const hasSecretaryCentres = user?.secretaryCentres
  const hasPatient = user?.patient
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeEntity = async (value) => {
    dispatch(blockedCentreAction({ status: false, info: '' }))
    mxp.track(mxp.events.navbar.roleSelected)
    let entity = {}

    if (hasCentre?.map((e) => e._id)?.includes(value._id)) {
      entity = {
        _id: value._id,
        role: 'centre',
      }
    }

    if (hasDoctorCentres?.map((e) => e._id)?.includes(value._id)) {
      entity = {
        _id: value.centre._id,
        doctorCentreId: value._id,
        role: 'doctor',
      }
    }

    if (hasSecretaryCentres?.map((e) => e._id)?.includes(value._id)) {
      entity = {
        _id: value.centre._id,
        secretaryCentreId: value._id,
        role: 'secretary',
      }
    }

    if (hasPatient?._id === value._id) {
      entity = {
        _id: user.patient._id,
        role: 'patient',
      }
    }

    dispatch(resetApp())

    const selectedEntity = JSON.stringify(entity)
    await localStorage.setItem('selectedEntity', selectedEntity)

    if (entity.role === 'patient') return navigate(`/turnos`)
    navigate(`/agenda/centro/${entity._id}`)
    window.location.reload()
  }

  return (
    <>
      <Tooltip title="Perfil seleccionado">
        <IconButton className={s.iconButton} onClick={handleClick}>
          <ChangeCircleIcon />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {hasCentre?.length >= 1 && <ListSubheader>CENTROS</ListSubheader>}
        {hasCentre?.map((e) => (
          <MenuItem key={e._id} value={e} className={s.hoverText} onClick={() => changeEntity(e)}>
            {capitalizeFirstLetters(e.name)}
            {entityInLocalStorage?._id === e._id && <DoneIcon />}
          </MenuItem>
        ))}
        {hasDoctorCentres?.length >= 1 && <ListSubheader>PROFESIONALES</ListSubheader>}
        {hasDoctorCentres?.map((e) => (
          <MenuItem key={e._id} value={e} className={s.hoverText} onClick={() => changeEntity(e)}>
            {capitalizeFirstLetters(`${e.lastname}, ${e.firstname}`)} {`(${e?.centre?.name?.toUpperCase()})`}
            {entityInLocalStorage?._id === e._id && <DoneIcon />}
          </MenuItem>
        ))}

        {hasSecretaryCentres?.length >= 1 && <ListSubheader>ASISTENTES</ListSubheader>}
        {hasSecretaryCentres?.map((e) => (
          <MenuItem key={e._id} value={e} className={s.hoverText} onClick={() => changeEntity(e)}>
            {capitalizeFirstLetters(`${e.lastname}, ${e.firstname}`)} {`(${e?.centre?.name?.toUpperCase()})`}
            {entityInLocalStorage?._id === e._id && <DoneIcon />}
          </MenuItem>
        ))}

        {hasPatient && (
          <ListSubheader>
            {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`).toUpperCase()}
          </ListSubheader>
        )}
        {hasPatient && (
          <MenuItem
            key={hasPatient._id}
            value={hasPatient}
            className={s.hoverText}
            onClick={() => changeEntity(hasPatient)}
          >
            {capitalizeFirstLetters(`${hasPatient.lastname}, ${hasPatient.firstname}`)}
            {entityInLocalStorage?._id === hasPatient._id && <DoneIcon />}
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
