import React from 'react'
import { useTranslation } from 'react-i18next'
import { handleSetDniLabel } from '@/utils/functions/handleSetDniLabel'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const FullCalendarEventTooltip = ({ selectedTurn }) => {
  const { t } = useTranslation('global')

  const patientCentre = selectedTurn?.patientCentre
  const service = selectedTurn?.service
  const extraServices = selectedTurn?.extraServices
  const startHourTurn = dayjs(selectedTurn?.startHour).utc().format('HH:mm')
  const finishHourTurn = dayjs(selectedTurn?.finishHour).utc().format('HH:mm')
  const noDNI =
    selectedTurn?.patientCentre?.dni?.length === 15 ? `--SIN ${handleSetDniLabel(patientCentre.country)}-- ` : ''
  const mobile = patientCentre?.mobile
  const telephone = patientCentre?.telephone
  const notAssigned = !selectedTurn.doctorCentre && 'SIN ASIGNAR'

  const showFullNamePatient = () => {
    if (patientCentre) {
      return noDNI + patientCentre?.lastname?.toUpperCase() + ', ' + patientCentre?.firstname?.toUpperCase()
    }
    return '❌ PACIENTE ELIMINADO'
  }

  const showContactInformation = () => {
    if (patientCentre) {
      if (mobile && telephone) return 'Teléfono Móvil: ' + mobile
      if (mobile && !telephone) return 'Teléfono Móvil: ' + mobile
      if (!mobile && telephone) return 'Teléfono: ' + telephone
    }
  }

  const showService = () => {
    if (!service && !selectedTurn?.machine && selectedTurn.firstTime === false) return 'CONSULTA'
    if (!service && !selectedTurn?.machine && selectedTurn.firstTime === true) return 'PRIMERA CONSULTA'
    if (service?._id) {
      return `${service?.name?.toUpperCase()}${extraServices?.length ? ', ' : ''} ${
        extraServices?.length
          ? extraServices
              ?.map((s) => s.name)
              .join(', ')
              .toUpperCase()
          : ''
      }`
    }
  }

  const showPatientTags = () => {
    return (
      <div>
        {patientCentre?.patientTags?.map((tag, index) => (
          <div key={index} className={s.tagTooltipContainer}>
            <span className={s.itemTagTooltip} style={{ backgroundColor: tag.color, color: tag.color }} />
            <span className={s.tagNameTooltip}>{tag.name}</span>
          </div>
        ))}
      </div>
    )
  }

  const payment = selectedTurn.totalPayments > 0 && `Monto pagado: $${selectedTurn.totalPayments}`
  const patientContact = showContactInformation()
  const services = patientCentre && showService() ? 'Servicios: ' + showService() : ''
  const arrivedState = selectedTurn?.state === 'arrived'
  const insurance = selectedTurn?.insurance?.name ? `${t('insuranceLabel.S')}: ${selectedTurn?.insurance?.name}` : ''
  const arrivedTimeTurn = arrivedState
    ? 'Llegó: ' + dayjs(selectedTurn?.modificationDate).utc().add(-3, 'hours').format('HH:mm') + ' hs'
    : ''
  const comment = selectedTurn?.comment ? `Comentarios: ${selectedTurn?.comment}` : ''

  let eventItems
  if (payment) {
    eventItems = [
      startHourTurn + '-' + finishHourTurn,
      showFullNamePatient(),
      payment,
      patientContact,
      services,
      insurance,
      arrivedTimeTurn,
      comment,
    ].filter((e) => e !== '')
  } else {
    eventItems = [
      startHourTurn + '-' + finishHourTurn,
      showFullNamePatient(),
      patientContact,
      services,
      insurance,
      arrivedTimeTurn,
      comment,
    ].filter((e) => e !== '')
  }

  if (selectedTurn.treatment) {
    eventItems.push('--')
    eventItems.push('Sesión de grupo de sesiones')
  }

  if (!selectedTurn.doctorCentre) {
    eventItems.unshift('-------')
    eventItems.unshift(notAssigned)
  }

  const tooltipEvent = eventItems.join('\n')

  if (selectedTurn?.state === 'pending') {
    return (
      <div>
        Un usuario seleccionó este horario en citas online. Una vez terminado el proceso podrá ver la información de la
        cita
      </div>
    )
  }

  return (
    <div className={s.tooltipText}>
      {tooltipEvent}
      {patientCentre?.patientTags?.length > 0 && <span>{showPatientTags()}</span>}
    </div>
  )
}

export default FullCalendarEventTooltip
