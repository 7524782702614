import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import defaultUserImage from '@/assets/images/default.png'
import { AutoCompleteCityField } from '@/components/Form/customFields/AutoCompleteCityField'
import { AutoCompleteField } from '@/components/Form/customFields/AutoCompleteField'
import { CustomField } from '@/components/Form/customFields/CustomField'
import ImageCircle from '@/components/imageCircle'
import LoadingSaveButton from '@/components/LoadingSaveButton'
import { useUser } from '@/context/UserProvider'
import { useUploadImage } from '@/hooks/useUploadImage'
import { updateDoctorProfile } from '@/store/doctorProfile/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { Button, Grid, Paper } from '@mui/material'

import HeaderForm from './components/headerForm'
import PlaceToWork from './components/placeToWork'

import s from './styles.module.scss'

export const FormContent = ({ isLoading }) => {
  const { user } = useUser()
  const { t } = useTranslation('global')
  const specialities = useSelector((state) => state.speciality.specialitiesList)
  const insurances = useSelector((state) => state.insurances.insurancesList)
  const doctorProfile = useSelector((state) => state.doctorProfile.doctorProfile)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const [inputDisabled, setInputDisabled] = useState(false)
  const logoUpload = useUploadImage()
  const [workPlaces, setWorkPlaces] = useState([])

  useEffect(() => {
    setWorkPlaces(doctorProfile?.workPlaces)
  }, [doctorProfile])

  const addNewWorkPlace = () => {
    setWorkPlaces([
      ...workPlaces,
      {
        city: '',
        address: '',
      },
    ])
  }

  useEffect(() => {
    if (doctorProfile || user) {
      setInputDisabled(true)
    }
  }, [doctorProfile, user])

  return (
    <Paper className={s.formContent}>
      <HeaderForm doctorProfile={doctorProfile} />
      <Grid className={s.perfilTitleContainer}>
        <b className={s.formTitle}>Perfil profesional</b>
        {doctorProfile && (
          <div className={s.containerLogo}>
            <ImageCircle
              image={doctorProfile?.image}
              defaultImage={defaultUserImage}
              uploadImage={(e) => logoUpload.uploadImage(e, 'image', updateDoctorProfile, doctorProfile, true)}
              loading={logoUpload.loadingImage}
              className="changeProfileImage"
              id={'profesionalImage'}
            />
          </div>
        )}
      </Grid>
      <Grid container spacing={2} className={s.padding}>
        <Grid item xs={12} sm={4}>
          <CustomField label="Nombre*" name="firstname" fullWidth disabled={inputDisabled} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomField label="Apellido*" name="lastname" fullWidth disabled={inputDisabled} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomField label="Email" name="email" type="email" fullWidth disabled={inputDisabled} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Sitio web" name="web" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="DNI" name="dni" type="text" fullWidth disabled={inputDisabled} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Matrícula" name="doctorNumber" type="text" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Categoria" name="doctorCategory" type="text" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Teléfono Móvil*" name="mobile" type="number" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Teléfono fijo" name="telephone" type="number" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AutoCompleteField label="Especialidades" name="specialities" options={specialities} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <CustomField label="Subespecialidad(es)" name="subspecialities" fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AutoCompleteField label="Obra(s) social(es)" name="insurances" options={insurances} fullWidth />
        </Grid>
        {workPlaces?.length > 0 ? (
          <Grid item xs={12} sm={12}>
            {workPlaces.map((work, index) => (
              <PlaceToWork doctorProfile={doctorProfile} workPlace={work} index={index} key={index} />
            ))}

            <Grid>
              <Button size="small" variant="contained" className={s.addPlaceToWorkButton} onClick={addNewWorkPlace}>
                Agregar otro lugar de trabajo
              </Button>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={6}>
              <CustomField label="Dirección" name={`workPlaces[${0}][address]`} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutoCompleteCityField label="Ciudad" name={`workPlaces[${0}][city]`} />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12}>
          <CustomField
            label={`Nota para los ${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`)} *`}
            name="note"
            fullWidth
          />
        </Grid>
        <div className={s.containerButtonsForm}>
          <LoadingSaveButton
            isLoading={isLoading}
            textButton={!doctorProfile ? 'Solicitar perfil profesional' : 'Actualizar perfil profesional'}
            type="submit"
            className={s.buttonSave}
            variant="contained"
          />
        </div>
      </Grid>
      <div className={s.infoDiv}>
        Dentro de las próximas 48 horas te avisaremos por email si tu <b> perfil profesional </b> fue aprobado o no.
      </div>
    </Paper>
  )
}
