import React from 'react'
import { FaBolt, FaComment, FaLaptop, FaStethoscope } from 'react-icons/fa'
import Tooltip from '@mui/material/Tooltip'

import FullCalendarEventTooltip from './FullCalendarEventTooltip'

import s from './styles.module.scss'

const FullCalendarEventContent = ({ event }) => {
  let turn = event?.extendedProps
  const patientFullName = event?.title
  const doctorCentre = turn?.doctorCentre?._id ? turn?.doctorCentre : null

  const doctorFullName =
    doctorCentre && `${doctorCentre?.lastname?.toUpperCase()} ${doctorCentre?.firstname?.toUpperCase()}: `

  const { service, comment, origin, extraServices } = turn
  const patientTags = turn?.patientCentre?.patientTags
  const totalSessionsTreatment = turn?.treatment?.turnsCount

  if (doctorCentre) {
    turn = {
      ...turn,
      doctorCentre: doctorCentre,
    }
  }

  const showSessionsTreatment = () => {
    let sessionNumber
    if (turn?.treatment) {
      sessionNumber = turn?.treatment?.turns?.findIndex((e, i) => (e?._id || e) === turn?._id) + 1
      return sessionNumber + '/' + totalSessionsTreatment
    }
  }

  const showServices = () => {
    let serviceTurn
    if (!service && !turn.machine) {
      serviceTurn = !turn.firstTime ? 'Consulta' : 'Primera consulta'
    }

    if (service) {
      serviceTurn = service.name
    }

    let serviceNames =
      serviceTurn &&
      `${serviceTurn?.toUpperCase()}${extraServices?.length ? ', ' : ''}${extraServices
        ?.map((s) => s.name)
        .join(', ')
        .toUpperCase()}`

    return serviceNames
  }

  const handleSetOriginIcon = () => {
    if (origin === 'centre') return
    if (origin === 'doctor') {
      return <FaStethoscope className={s.icon} />
    }

    if (origin === 'patient') {
      return <FaLaptop className={s.icon} />
    }

    if (origin === 'fastCreateDoctor' || origin === 'fastCreateCentre') {
      return <FaBolt className={s.icon} />
    }
  }

  if (event?.display === 'background') return <span />

  if (turn?.state === 'pending') {
    return (
      <Tooltip title={<FullCalendarEventTooltip selectedTurn={turn} />} placement="top">
        <span className={s.contentContainer}>
          <span className={`event-status ${s.eventStatus}`} />

          <span className={s.itemContainer}>
            <p className={s.primaryText}>Reserva en curso</p>
          </span>
        </span>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={<FullCalendarEventTooltip selectedTurn={turn} />} placement="top">
      <span className={s.contentContainer}>
        <span className={`event-status ${s.eventStatus}`} />

        <span className={s.itemContainer}>
          <p className={s.primaryText}>
            {turn.treatment && <span className={s.treatmentInfo}>{showSessionsTreatment()}</span>}
            {origin && handleSetOriginIcon()}
            {comment && <FaComment className={s.icon} />}

            {patientFullName}
          </p>

          <p className={s.secondaryText}>
            {turn?.machine && doctorCentre && doctorFullName}

            {turn.patientCentre && showServices()}
          </p>
        </span>

        <span className={s.itemContainerExtra}>
          <span className={s.tagsContainer}>
            {patientTags?.map((tag, index) => (
              <span key={index} className={s.tag} style={{ backgroundColor: tag.color, color: tag.color }} />
            ))}
          </span>
        </span>
      </span>
    </Tooltip>
  )
}

export default FullCalendarEventContent
