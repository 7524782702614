import { initReactI18next } from 'react-i18next'

import i18next from 'i18next'
import { en, es } from '../assets/translations'

i18next.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  lng: import.meta.env.REACT_APP_LANGUAGE,
  resources: {
    es: {
      global: { ...es.DEFAULT, ...es[import.meta.env.REACT_APP_COUNTRY] },
    },
    en: {
      global: { ...en.DEFAULT, ...en[import.meta.env.REACT_APP_COUNTRY] },
    },
  },
})

export default i18next
