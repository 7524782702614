import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchCashboxItems = async (params) => {
  const route = `api/cashboxItems`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewCashboxItem = async (data) => {
  const route = `api/cashboxItems`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteCashboxItem = async (id) => {
  const route = `api/cashboxItems/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putCashboxItem = async (data) => {
  const route = `api/cashboxItems`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchCashboxItemsCombined = async (params) => {
  const route = `api/cashboxItems/combined`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { addNewCashboxItem, deleteCashboxItem, fetchCashboxItems, fetchCashboxItemsCombined, putCashboxItem }
