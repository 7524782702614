import {
  addNewCentre,
  deleteCentre,
  fetchCentreByInputSearch,
  fetchCentres,
  fetchCentresPaymentsInfo,
  getCentreDetail,
  getCentreDetailBySlug,
  getInfoUserInCentreAdmin,
  putCentre,
  putCentresPaymentInfo,
} from '@/services/centres'
import { CENTRE_CONFIGURATION_SELECTED } from '@/store/centreConfigurations'
import {
  BLOCKED_CENTRE,
  CENTRE_ADMIN_UPDATED,
  CENTRE_CREATED,
  CENTRE_DELETE,
  CENTRE_ERROR,
  CENTRE_SELECTED,
  CENTRE_TARGET,
  CENTRE_UPDATED,
  FETCH_CENTRES,
  INFO_USER_CENTRE_ADMIN_SELECTED,
  IS_LOADING_CENTRES,
  LOADED_CENTRE_ENTITIES,
  RESET_CENTRE_SELECTED,
  RESET_CENTRE_TARGET,
} from '@/store/centres'
import { CENTRE_SELECTED as CENTRE_SELECTED_MY_PROFILE } from '@/store/myProfile'
import { showToast } from '@/utils/toast'

const getAllCentres = (page, limit) => async (dispatch) => {
  try {
    dispatch(LOADED_CENTRE_ENTITIES(true))
    let { docs: allCentres, ...paginationData } = await fetchCentres(page, limit)

    dispatch(FETCH_CENTRES({ allCentres, paginationData }))
  } catch (error) {
    dispatch(LOADED_CENTRE_ENTITIES(false))
    const { message } = error
    console.log(message)
  }
}

const getCentreByInputSearch = (params) => async (dispatch) => {
  try {
    dispatch(IS_LOADING_CENTRES(true))
    let { docs: allCentres, ...paginationData } = await fetchCentreByInputSearch(params)

    dispatch(FETCH_CENTRES({ allCentres, paginationData }))
    dispatch(IS_LOADING_CENTRES(false))
  } catch (error) {
    const { message } = error
    console.log(message)
    dispatch(IS_LOADING_CENTRES(false))
  }
}

const handlePaginationChangeCentres = (newPage, newLimit, searchText, selectedStatus) => (dispatch) => {
  const params = {
    page: newPage,
    limit: newLimit,
    search: searchText,
    status: selectedStatus === 'all' ? null : selectedStatus,
  }

  dispatch(getCentreByInputSearch(params))
}

const getCurrentCentre =
  (centre, addInMyProfile = false) =>
  async (dispatch) => {
    try {
      if (addInMyProfile) {
        dispatch(CENTRE_SELECTED_MY_PROFILE(centre))
      } else {
        dispatch(CENTRE_SELECTED(centre))
      }

      if (centre?.centreConfiguration && centre?.centreConfiguration?._id) {
        dispatch(CENTRE_CONFIGURATION_SELECTED(centre?.centreConfiguration))
      }
    } catch (error) {
      const { message } = error
      console.log(message)
    }
  }

const getCentreTarget = (id) => async (dispatch) => {
  try {
    const centreTarget = await getCentreDetail(id)
    dispatch(CENTRE_TARGET(centreTarget))
    if (!centreTarget) dispatch(CENTRE_ERROR())
    return centreTarget
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Se produjo un error', 'error')
    dispatch(CENTRE_ERROR())
  }
}

const getCentreBySlug = (slug) => async (dispatch) => {
  try {
    const centreDetailBySlug = await getCentreDetailBySlug(slug)
    return centreDetailBySlug
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Se produjo un error', 'error')
    dispatch(CENTRE_ERROR())
  }
}

const getCentresPaymentsInfo = () => async (dispatch) => {
  try {
    const centresPaymentsInfo = await fetchCentresPaymentsInfo()
    return centresPaymentsInfo
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Se produjo un error', 'error')
    dispatch(CENTRE_ERROR())
  }
}

const postCentre =
  (data, hideAlert = false) =>
  async (dispatch) => {
    const centreConfiguration = data.centreConfiguration
    try {
      const newCentre = await addNewCentre(data)
      newCentre.centreConfiguration = centreConfiguration
      dispatch(CENTRE_CREATED(newCentre))
      if (newCentre && !hideAlert) {
        showToast('Centro agregado correctamente', 'success')
      }
      return newCentre
    } catch (error) {
      const { message } = error
      console.log('Error message: ', message)
      showToast('Error al crear Centro', 'error')
    }
  }

const deleteCentreAdded = (id) => async (dispatch) => {
  try {
    await deleteCentre(id)
    dispatch(CENTRE_DELETE(id))
    showToast('Centro Eliminado', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateCentre = (data, extraData) => async (dispatch) => {
  const { centreConfiguration } = data

  try {
    let centreUpdated = await putCentre(data)
    let auxCentreUpdated = { ...centreUpdated }
    auxCentreUpdated.centreConfiguration = { ...centreConfiguration }
    dispatch(CENTRE_UPDATED(auxCentreUpdated))

    if (centreUpdated && !extraData?.hideAlert) {
      showToast('Centro actualizado correctamente', 'success')
    }

    if (extraData?.addInMyProfile) {
      dispatch(CENTRE_SELECTED_MY_PROFILE(centreUpdated))
    }

    if (extraData?.callback) extraData?.callback()
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Error al actualizar datos', 'error')
  }
}

const updateCentresPaymentsInfo = (body, callback) => async (dispatch) => {
  const dataUpdatePayments = { data: body }
  dispatch(LOADED_CENTRE_ENTITIES(true))
  try {
    let centreUpdated = await putCentresPaymentInfo(dataUpdatePayments)
    if (centreUpdated) {
      showToast('Pagos actualizados correctamente', 'success')
    }

    if (callback) callback()
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Error al actualizar datos', 'error')
  } finally {
    dispatch(LOADED_CENTRE_ENTITIES(false))
  }
}

const updateCentreAdmin = (data, hideAlert) => async (dispatch) => {
  const dataCentreConfiguration = data.centreConfiguration
  delete data.centreConfiguration
  try {
    const centreUpdated = await putCentre(data)
    centreUpdated.centreConfiguration = dataCentreConfiguration
    dispatch(CENTRE_ADMIN_UPDATED(centreUpdated))
    if (centreUpdated && !hideAlert) {
      showToast('Centro actualizado correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Error al actualizar datos del centro', 'error')
  }
}

const resetSelectedCentre = () => async (dispatch) => {
  try {
    dispatch(RESET_CENTRE_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetCentreTarget = () => async (dispatch) => {
  try {
    dispatch(RESET_CENTRE_TARGET())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const isLoadedCentreEntities = (data) => async (dispatch) => {
  try {
    dispatch(LOADED_CENTRE_ENTITIES(data))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const getDetailInfoUserCentreAdmin = (id) => async (dispatch) => {
  try {
    const infoUser = await getInfoUserInCentreAdmin(id)
    dispatch(INFO_USER_CENTRE_ADMIN_SELECTED(infoUser))
    return infoUser
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Se produjo un error', 'error')
    dispatch(CENTRE_ERROR())
  }
}

const blockedCentreAction = (data) => async (dispatch) => {
  try {
    dispatch(BLOCKED_CENTRE(data))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export {
  blockedCentreAction,
  deleteCentreAdded,
  getAllCentres,
  getCentreByInputSearch,
  getCentreBySlug,
  getCentresPaymentsInfo,
  getCentreTarget,
  getCurrentCentre,
  getDetailInfoUserCentreAdmin,
  handlePaginationChangeCentres,
  isLoadedCentreEntities,
  postCentre,
  resetCentreTarget,
  resetSelectedCentre,
  updateCentre,
  updateCentreAdmin,
  updateCentresPaymentsInfo,
}
