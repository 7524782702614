import React from 'react'
import TitlePopUp from '@/components/TitlePopUp'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'

import FormUsefulInformation from './formUsefulInformation'

const UsefulInformation = ({ open, onClose }) => {
  return (
    <div>
      <Dialog
        disableEscapeKeyDown={true}
        maxWidth="md"
        fullWidth
        open={open}
        onClose={onClose}
        scroll="paper"
        disableEnforceFocus
      >
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle="Información de Horarios" handleClose={onClose} />
        </DialogTitle>
        <DialogContent style={{ padding: '0px' }} className="animate__animated animate__fadeIn">
          <FormUsefulInformation handleClose={onClose} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default UsefulInformation
