import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import ErrorCard from '@/components/ErrorCard'
import NavBar from '@/components/NavBar/index.jsx'
import { useUser } from '@/context/UserProvider'
import { getDoctorProfile } from '@/store/doctorProfile/actions'
import { getListSpecialities } from '@/store/specialities/actions'

import FormDoctorProfileReview from './formDoctorProfileReview'
import FormRegisterProfesional from './formRegisterDoctorProfile'

import s from './styles.module.scss'

const DoctorProfile = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openPopUp, setOpenPopUp] = useState(true)
  const { user } = useUser()
  const [showFormReview, setShowFormReview] = useState(false)
  const [hideDoctorProfile, setHideDoctorProfile] = useState(false)

  useEffect(() => {
    dispatch(getListSpecialities())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const verifyDoctorProfile = async () => {
      if (user) {
        if (user.centres.length || user.doctorCentres.length || user.secretaryCentres.length) {
          setHideDoctorProfile(true)
        } else {
          const doctorProfile = await dispatch(getDoctorProfile(user.email))

          if (doctorProfile) {
            navigate(`/perfil-profesional/${doctorProfile.email}`)
          } else {
            setShowFormReview(true)
          }
        }
      } else {
        setOpenPopUp(true)
      }
    }
    verifyDoctorProfile()
  }, [dispatch, navigate, user])

  const continueWithRegistration = () => {
    setOpenPopUp(false)
  }

  const backToHome = () => {
    navigate('/')
  }

  return (
    <>
      {!showFormReview ? (
        <div className={s.screenContainer}>
          <div>
            <NavBar />
          </div>

          <div className={s.formContainer}>
            {!user && (
              <div>
                <FormRegisterProfesional />
              </div>
            )}
          </div>

          {user && hideDoctorProfile && (
            <div className={s.infoCard}>
              <ErrorCard
                title="Tu perfil profesional ya está activo"
                text1="Si tienes dudas contáctate con soporte."
                path="/"
              />
            </div>
          )}
        </div>
      ) : (
        <FormDoctorProfileReview />
      )}

      {openPopUp && (
        <AlertDialogSlide
          open={openPopUp}
          setOpen={setOpenPopUp}
          title="¡ATENCIÓN!"
          textBoldFont="Si tu centro de salud ya está utilizando Calu debes contactarte con ellos para crear tu perfil profesional."
          textContent="Completa este formulario solamente si tu centro de salud no utiliza Calu."
          textBackButton="Volver "
          textConfirmationButton="Continuar"
          onClose={continueWithRegistration}
          navigateToHome={backToHome}
        />
      )}
    </>
  )
}

export default DoctorProfile
