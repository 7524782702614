import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchFieldTables = async (params) => {
  const route = `api/fieldTables`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewFieldTable = async (data) => {
  const route = `api/fieldTables`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteFieldTable = async (id) => {
  const route = `api/fieldTables/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putFieldTable = async (data) => {
  const route = `api/fieldTables`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { addNewFieldTable, deleteFieldTable, fetchFieldTables, putFieldTable }
