import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import Layout1 from '@/layouts/Layout1'
import { addNewPatientCentre } from '@/services/patientCentres'
import { addNewService } from '@/services/services'
import { getListInsurances } from '@/store/insurances/actions'
import { Checkbox, FormControlLabel, Paper } from '@mui/material'

import moment from 'moment'
import ImportFilesComponent from './components/ImportFilesComponent'
import JsonViewer from './components/JsonViewer'
import SelectComponent from './components/SelectComponent'

import s from './styles.module.scss'

// REMEMBER: check year is 4-digit to avoid errors!!!
moment.parseTwoDigitYear = function (yearStr) {
  return parseInt(yearStr) + (parseInt(yearStr) > 23 ? 1900 : 2000)
}

const AdminPanelCentre = () => {
  const dispatch = useDispatch()

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const allInsurances = useSelector((state) => state.insurances?.insurancesList)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected

  const keyOptions = {
    services: {
      csvColumns: ['Nombre', 'Duración', 'Precio', 'Descripción'],
      modelFields: ['name', 'duration', 'price', 'description'],
      name: 'Servicios',
    },
    patients: {
      csvColumns: [
        'DNI',
        'Apellidos',
        'Nombres',
        'Email',
        'Obra social',
        'Nro afiliado',
        'Celular',
        'Teléfono',
        'Dirección',
        'Ciudad',
        'Fecha de nacimiento',
        'Nota del paciente',
      ],
      modelFields: [
        'dni',
        'lastname',
        'firstname',
        'email',
        'insurance',
        'policyNumber',
        'mobile',
        'telephone',
        'address',
        'city',
        'birth',
        'note',
      ],
      name: 'Pacientes',
    },
  }

  const dateValidFormats = ['DD/MM/YYYY', 'MM/DD/YYYY', 'DD/MM/YY', 'MM/DD/YY']

  const [valueSelect, setValueSelect] = useState('-')
  const [dateFormat, setDateFormat] = useState(dateValidFormats[0])
  const [csvValues, setCsvValues] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [checked, setChecked] = useState(false)
  const [doneImportation, setDoneImportation] = useState(false)
  const [modelValues, setModelValues] = useState([])

  const resetStates = () => {
    setValueSelect('-')
    setCsvValues([])
    setIsLoading(false)
    setChecked(false)
    setDoneImportation(false)
  }

  useEffect(() => {
    if (!entityInLocalStorage?._id || centreDetail?._id !== entityInLocalStorage?._id) {
      window.location.href = '/admin/centros'
    }
    dispatch(getListInsurances())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const auxValues = csvValues.map((csvValue) => {
      if (valueSelect === 'services') {
        return {
          centre: centreDetail?._id,
          name: csvValue[0]?.trim()?.toLowerCase(),
          duration: csvValue[1] ? Number(csvValue[1]) : null,
          price: csvValue[2] ? Number(csvValue[2]) : null,
          description: csvValue[3]?.trim(),
        }
      }

      if (valueSelect === 'patients') {
        let insurance =
          csvValue[4] &&
          allInsurances.find((i) => {
            return i.name.toLowerCase() === csvValue[4]?.trim()?.toLowerCase()
          })

        let patientCentre = {
          centre: centreDetail?._id,
          dni: csvValue[0] || crypto.randomUUID().replaceAll('-', '').slice(0, 15),
          lastname: csvValue[1]?.trim()?.toLowerCase(),
          firstname: csvValue[2]?.trim()?.toLowerCase(),
          email: csvValue[3]?.trim()?.toLowerCase(),
          insurances: insurance ? [insurance] : [],
          policyNumbers: insurance && csvValue[5] ? [csvValue[5].trim()] : [],
          mobile: csvValue[6] ? Number(csvValue[6].trim()) : null,
          telephone: csvValue[7] ? Number(csvValue[7].trim()) : null,
          address: csvValue[8]?.trim(),
          city: csvValue[9]?.trim(),
          birth: csvValue[10] ? moment(csvValue[10], dateFormat).toISOString() : null,
          note: csvValue[11]?.trim(),
        }
        return patientCentre
      }
      return {}
    })

    setModelValues(auxValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvValues])

  const handleSaveModelObjects = async () => {
    setIsLoading(true)
    for (const value of modelValues) {
      await new Promise((resolve) =>
        setTimeout(async () => {
          try {
            if (valueSelect === 'patients') {
              await addNewPatientCentre(value)
            }
            if (valueSelect === 'services') {
              await addNewService(value)
            }
          } catch (error) {
          } finally {
            resolve()
          }
        }, 200),
      )
    }
    setIsLoading(false)
    setDoneImportation(true)
  }

  return (
    <Layout1 navbar>
      <div className={s.mainContainer}>
        <Paper elevation={3} className={`${s.paperContainer} animate__animated animate__fadeIn`}>
          <h5 className={s.title}>
            Importando CSV para centro: <span className={s.boldClass}>{centreDetail?.name}</span>
          </h5>
          <div className={s.divider}></div>
          <div className={s.contSelect}>
            <p>Seleccione una opción para importar</p>
            <SelectComponent
              valueSelect={valueSelect}
              setValueSelect={setValueSelect}
              setCsvValues={setCsvValues}
              doneImportation={doneImportation}
              dateValidFormats={dateValidFormats}
              dateFormat={dateFormat}
              setDateFormat={setDateFormat}
            />
          </div>

          {valueSelect !== '-' && (
            <ImportFilesComponent
              keysOptions={keyOptions[valueSelect]}
              csvValues={csvValues}
              setCsvValues={setCsvValues}
              doneImportation={doneImportation}
            />
          )}

          {csvValues?.length > 0 && valueSelect !== '-' && (
            <div className={s.contParsedValues}>
              <JsonViewer data={modelValues} />
              <div className={s.contImportBtn}>
                <FormControlLabel
                  control={<Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />}
                  label="Los datos son correctos"
                />
                <div>
                  {doneImportation ? (
                    <CaluButton
                      color="primary"
                      inverted="true"
                      size="medium"
                      onClick={() => {
                        resetStates()
                      }}
                    >
                      Reset
                    </CaluButton>
                  ) : (
                    <CaluButton
                      color="primary"
                      size="medium"
                      onClick={handleSaveModelObjects}
                      loading={isLoading}
                      disabled={!checked}
                    >
                      Importar {keyOptions[valueSelect].name}
                    </CaluButton>
                  )}
                </div>
              </div>
            </div>
          )}
        </Paper>
      </div>
    </Layout1>
  )
}

export default AdminPanelCentre
