import React, { useEffect, useState } from 'react'
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import DividerSection from '@/components/DividerSection'
import SelectWithTextField from '@/components/SelectWithTextField'
import CaluButton from '@/components/styledComponents/CaluButton'
import { TextOverButtons } from '@/components/TextOverButtons'
import { useUser } from '@/context/UserProvider'
import useDebounce from '@/hooks/useDebounce'
import { getAllProducts, isLoadedProducts } from '@/store/products/actions.js'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { showToast } from '@/utils/toast'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Grid, IconButton, TextField } from '@mui/material'

import dayjs from 'dayjs'

import s from '../../styles.module.scss'

const ProductsForm = ({ showAllForm, productsForm, setProductsForm, amount, setAmount }) => {
  const dispatch = useDispatch()
  const { defaultCentre } = useUser()
  const [productValue, setProductValue] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [totalAmount, setTotalAmount] = useState(0)
  const [unities, setUnities] = useState(1)
  const { allProducts, isLoaded } = useSelector((state) => state.products)
  const [inputSearch, setInputSearch] = useState('')
  const DELAY_TIME = 300
  const inputDebounceValue = useDebounce(inputSearch, DELAY_TIME)
  const now = dayjs().format()

  useEffect(() => {
    if (selectedProduct) {
      const amount = selectedProduct.price * unities || 0
      setTotalAmount(amount)
    }
  }, [selectedProduct, unities])

  useEffect(() => {
    getProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDebounceValue])

  const getProducts = async () => {
    let params
    if (inputDebounceValue.length === 0) {
      params = {
        centre: defaultCentre,
      }
    } else {
      params = {
        centre: defaultCentre,
        search: inputDebounceValue,
      }
    }
    dispatch(isLoadedProducts(true))
    await dispatch(getAllProducts(params))
    dispatch(isLoadedProducts(false))
  }

  const handleSelectedProduct = (id) => {
    setProductValue(id)
    const product = allProducts.filter((p) => p._id === id)[0]
    setSelectedProduct(product)
  }

  const saveSelectedProduct = () => {
    if (!selectedProduct?.name) return showToast('Debe seleccionar un producto', 'warning', 2000)
    if (selectedProduct) {
      const newProduct = {
        ...selectedProduct,
        quantity: unities,
        creationDate: now,
      }
      setProductsForm([...productsForm, newProduct])
      const addAmount = newProduct.price * newProduct.quantity
      setAmount((prev) => prev + addAmount)
    }
    setSelectedProduct(null)
    setProductValue(null)
    setUnities(1)
    setTotalAmount(0)
  }
  const addPropWhenClick = (i) => {
    let auxData = [...productsForm]
    auxData[i].showConfirm = true
    setProductsForm(auxData)
  }

  const deleteItemProductsList = (itemToDelete) => {
    let productItem = productsForm.filter((index) => index !== itemToDelete)
    const removedProductPrice = itemToDelete.price * itemToDelete.quantity
    setProductsForm(productItem)
    setAmount((prev) => prev - removedProductPrice)
  }

  const removePropWhenClick = (i) => {
    let auxData = [...productsForm]
    delete auxData[i].showConfirm
    setProductsForm(auxData)
  }

  return (
    <div>
      {showAllForm && <DividerSection label="Agregar productos" />}
      <Grid container className={s.paymentTurnContainer}>
        <Grid item xs={4} className={s.marginTextFields}>
          <SelectWithTextField
            isLoading={isLoaded}
            inputSearch={inputSearch}
            setInputSearch={setInputSearch}
            className={s.inputWidth90}
            inputLabel="Productos"
            onChange={(e) => handleSelectedProduct(e.target.value)}
            valueSelect={productValue}
            options={allProducts}
          />
        </Grid>
        <Grid item xs={3} className={s.marginTextFields}>
          <TextField
            type="number"
            label="Unidades"
            variant="standard"
            onChange={(e) => setUnities(parseInt(e.target.value))}
            value={unities}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item xs={3} className={s.totalAmountProducts}>
          {`Total: $${totalAmount}`}
        </Grid>
        <Grid item xs={2}>
          <CaluButton size="small" inverted="true" onClick={saveSelectedProduct} className={s.addPaymentButton}>
            Agregar
          </CaluButton>
        </Grid>
      </Grid>

      <Grid className={s.payments}>
        {productsForm.length > 0 ? <b className={s.bTag}>Productos agregados</b> : null}
        <ul className={s.paymentsUl}>
          {productsForm?.map((product, i) => (
            <li key={product._id} className={s.paymentsList}>
              <span>{`${product.name} (unidades: ${product.quantity}) - total: $${
                product.price * product.quantity
              }`}</span>

              {!product.showConfirm ? (
                <TextOverButtons title=" eliminar" placement="right">
                  <IconButton onClick={() => addPropWhenClick(i)}>
                    <DeleteOutlineIcon className={s.deleteIcon} />
                  </IconButton>
                </TextOverButtons>
              ) : (
                <Grid className={s.containerButtonsDelete}>
                  <TextOverButtons title="Si, eliminar" position="top">
                    <Grid className={s.btnsDeleteIcons}>
                      <IoIosCheckmarkCircleOutline onClick={() => deleteItemProductsList(product)} />
                    </Grid>
                  </TextOverButtons>
                  <TextOverButtons title="No, cancelar">
                    <Grid className={s.btnsCancelIcons}>
                      <IoIosCloseCircleOutline onClick={() => removePropWhenClick(i)} />
                    </Grid>
                  </TextOverButtons>
                </Grid>
              )}
            </li>
          ))}
        </ul>
      </Grid>
    </div>
  )
}

export default ProductsForm
