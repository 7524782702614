import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import JsonViewer from '@/pages/AdminPanelCentre/components/JsonViewer'
import { updateCentresPaymentsInfo } from '@/store/centres/actions'
import { Checkbox, FormControlLabel } from '@mui/material'

import Papa from 'papaparse'

import s from '../styles.module.scss'

const ImportCentresPaymentsContent = ({ handleClosePopUp }) => {
  const dispatch = useDispatch()
  const fileReader = new FileReader()

  const { isLoaded } = useSelector((state) => state.centres)

  const [csvValues, setCsvValues] = useState([])
  const [modelValues, setModelValues] = useState([])
  const [checked, setChecked] = useState(false)
  const [fileCSV, setFileCSV] = useState()

  useEffect(() => {
    const auxValues = csvValues
      ?.map((csvValue) => {
        return {
          Actualizar: csvValue[0],
          id: csvValue[1],
          name: csvValue[2],
          status: csvValue[3],
          Plan: csvValue[4],
          'Cantidad agendas': Number(csvValue[5]),
          'Tipo de extras o descuentos': csvValue[6],
          'Cantidad fija de extras o descuentos': Number(csvValue[7]),
          IVA: csvValue[8],
          'Último monto a pagar': Number(csvValue[9]),
          'Motivo del extra o descuento': csvValue[10],
          'Nota adicional': csvValue[11],
          'Medio de pago': csvValue[12],
        }
      })
      .filter((d) => d['Actualizar'].toLowerCase() === 'si')

    setModelValues(auxValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvValues])

  const changeHandler = (event) => {
    setFileCSV(event.target.files[0])
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const valuesArray = results.data.map((d) => Object.values(d))

        setCsvValues(valuesArray)
      },
    })
  }

  const handleSaveModelObjects = () => {
    if (fileCSV) {
      fileReader.onload = async function (event) {
        let csvOutput = event.target.result
        await dispatch(updateCentresPaymentsInfo(csvOutput, handleClosePopUp))
      }
      fileReader.readAsText(fileCSV)
    }
  }

  return (
    <div className={s.contImportFiles}>
      <p>
        Seleccione un archivo <span className={s.boldClass}>.CSV</span> con los centros a actualizar
      </p>

      <div className={s.inputFileContainer}>
        <div>
          <input type="file" name="file" accept=".csv" onChange={changeHandler} />
        </div>
        <p>
          Registros a actualizar: <span className={s.boldClass}>{modelValues?.length || 0}</span>
        </p>
      </div>

      {modelValues?.length > 0 && (
        <div className={s.contParsedValues}>
          <JsonViewer data={modelValues} />
          <div className={s.contImportBtn}>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />}
              label="Los datos son correctos"
            />
            <div>
              <CaluButton
                color="primary"
                size="medium"
                onClick={handleSaveModelObjects}
                loading={isLoaded}
                disabled={!checked}
              >
                Actualizar centros
              </CaluButton>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ImportCentresPaymentsContent
