import { fetchFieldsHC } from '@/services/clinicHistoryField'

export const getAllTemplatesHCField = async () => {
  try {
    const fieldsHCList = await fetchFieldsHC()
    return fieldsHCList
  } catch (error) {
    console.log(error.message)
    return error
  }
}
