import React, { useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { CaluFormAutoComplete } from '@/components/formComponents/customFields/CaluFormAutoComplete'
import CaluButton from '@/components/styledComponents/CaluButton'
import useValidateURLId from '@/hooks/useValidateURLId'
import { deleteInsurancePriceAdded } from '@/store/insurancePrices/actions'
import { getListInsurances } from '@/store/insurances/actions'
import { getAllServices } from '@/store/services/actions'
import { Grid } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false, formValues, onClose }) => {
  const { t } = useTranslation('global')
  const dispatch = useDispatch()
  const centreID = useValidateURLId()

  const [openAlertDelete, setOpenAlertDelete] = useState(false)

  const allInsurances = useSelector((state) => state.insurances.insurancesList)
  const allServices = useSelector((state) => state.services?.allServices)
  const { insurancePriceSelected } = useSelector((state) => state.insurancePrices)

  const watchCode = useWatch({ name: 'code' })
  const watchInsurance = useWatch({ name: 'insurance' })

  const handleDeleteItem = async () => {
    await dispatch(deleteInsurancePriceAdded(insurancePriceSelected?._id))
    onClose()
  }

  return (
    <>
      <Grid container spacing={2} className={s.containerForm}>
        <Grid item xs={12} md={6}>
          <CustomField label="Código" type="text" name="code" fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <CaluFormAutoComplete
            name="insurance"
            stateredux={allInsurances}
            functiontodispatch={getListInsurances}
            inputlabel={`${t('insuranceLabel.S')}`}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <CaluFormAutoComplete
            name="service"
            stateredux={allServices}
            functiontodispatch={getAllServices}
            inputlabel={`Servicio`}
            centre={centreID}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomField label="Precio" type="number" name="price" fullWidth />
        </Grid>

        <Grid item xs={12}>
          <CustomField label="Descripción" type="text" name="description" fullWidth />
        </Grid>

        <Grid item xs={12} className={s.contBtn}>
          {formValues && (
            <div>
              <CaluButton
                color="error"
                size="medium"
                onClick={() => setOpenAlertDelete(!openAlertDelete)}
                loading={isLoading}
              >
                Eliminar
              </CaluButton>
              <AlertDialogSlide
                open={openAlertDelete}
                title={'Eliminar plan'}
                setOpen={setOpenAlertDelete}
                textContent={'¿Realmente quiere eliminar este plan? Esta acción no se podrá restaurar.'}
                textBackButton="Cancelar"
                textConfirmationButton={'Eliminar'}
                onClose={handleDeleteItem}
              />
            </div>
          )}
          <div>
            <CaluButton
              color="primary"
              type="submit"
              size="medium"
              disabled={!watchCode || !watchInsurance}
              loading={isLoading}
            >
              Guardar
            </CaluButton>
          </div>
        </Grid>
      </Grid>
    </>
  )
}
