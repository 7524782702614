import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { getAge } from '@/utils/functions/getAge'

import dayjs from 'dayjs'

export const downloadPatientsTableAdapter = (entry) => {
  const handleTagsData = (arrayTags) => {
    let arrayTagsNames = arrayTags?.map((tag) => tag.name)
    return arrayTagsNames.join().replace(/,/g, ', ')
  }

  let buildTable = {
    Teléfono: entry?.raw.mobile || '-',
    Paciente: `${capitalizeFirstLetters(entry?.raw.lastname)} ${capitalizeFirstLetters(entry?.raw.firstname)}`,
    DI: entry?.raw.dni?.length === 15 ? '-' : entry?.raw.dni,
    Email: capitalizeFirstLetters(entry?.raw.email) || '-',
    'Fecha de nacimiento': entry?.raw.birth ? `${dayjs(entry?.raw.birth).utc().format('DD-MM-YYYY')}` : '-',
    Edad: entry.raw.birth ? ` ${getAge(entry?.raw.birth) !== 0 ? `${getAge(entry?.raw.birth)} Años` : '-'}` : '-',
    Etiquetas: entry?.raw.patientTags?.length === 0 ? '-' : handleTagsData(entry?.raw.patientTags),
  }

  return buildTable
}
