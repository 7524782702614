import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import defaultUserImage from '@/assets/images/default.png'
import TagState from '@/components/TagState'
import { Button } from '@mui/material'

import s from './styles.module.scss'

const CardInformation = ({ entity, allServices, setSelectedService }) => {
  const { t } = useTranslation('global')

  const [showMoreInsurances, setShowMoreInsurances] = useState(false)
  const [showMoreServices, setShowMoreServices] = useState(false)

  const maxLengthServices = 5
  const maxLengthInsurances = 5
  const entityWorkTimeServicesUniques = entity?.workTimeServices?.filter((value, i, list) => list.indexOf(value) === i)

  const getServiceName = (serviceId) => {
    let name = ''
    allServices?.forEach((s) => {
      if (s._id === serviceId) {
        name = s.name.toUpperCase()
      }
    })
    return name
  }

  return (
    <div className={s.cardContainer}>
      <div className={s.headerContainer}>
        <img className={s.perfilImg} src={entity?.image ? entity.image : defaultUserImage} alt="Imagen de perfil" />

        <div className={s.cardHeader}>
          <div className={s.entityName}>
            {entity?.firstname
              ? `${entity?.lastname.toUpperCase()}, ${entity?.firstname.toUpperCase()}`
              : entity?.name.toUpperCase()}
          </div>

          {entity?.specialities?.length > 0 && (
            <div className={s.infoItem}>
              {entity?.specialities.map((speciality, i) => (
                <span key={i} className={s.info}>
                  {speciality?.name} {i < entity?.specialities.length - 1 ? ', ' : ''}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className={s.subheaderContainer}>
        <div className={s.subHeaderTitle}>Servicios</div>

        {entityWorkTimeServicesUniques?.length ? (
          <div className={s.tagsContainer}>
            {entityWorkTimeServicesUniques
              ?.slice(0, !showMoreServices ? maxLengthServices : undefined)
              ?.map((serv, i) =>
                getServiceName(serv) ? (
                  <Button key={i} className={s.buttonTag} onClick={() => setSelectedService(serv)}>
                    {getServiceName(serv)}
                  </Button>
                ) : null,
              )}

            {!showMoreServices && entityWorkTimeServicesUniques?.length > maxLengthServices && (
              <TagState text={'Ver más'} icon={'add'} onClick={() => setShowMoreServices(true)} />
            )}
          </div>
        ) : (
          <div className={s.noSpecificText}>Sin especificar</div>
        )}
      </div>

      <div className={s.subheaderContainer}>
        <div className={s.subHeaderTitle}>{t('insuranceLabel.P')}</div>

        {entity?.insurances?.length ? (
          <div className={s.tagsContainer}>
            {entity?.insurances
              ?.slice(0, !showMoreInsurances ? maxLengthInsurances : undefined)
              ?.map((insurance, i) => (
                <TagState key={i} text={insurance?.name} />
              ))}

            {!showMoreInsurances && entity?.insurances?.length > maxLengthInsurances && (
              <TagState text={'Ver más'} icon={'add'} onClick={() => setShowMoreInsurances(true)} />
            )}
          </div>
        ) : (
          <div className={s.noSpecificText}>Sin especificar</div>
        )}
      </div>

      {(entity.note || entity.description) && (
        <div className={s.agendaNote}>
          <p>{entity.note || entity.description}</p>
        </div>
      )}
    </div>
  )
}

export default CardInformation
