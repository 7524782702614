import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import LoaderCircle from '@/components/LoaderCircle'
import { useUser } from '@/context/UserProvider'
import { getAllTemplatesHCField } from '@/controllers/clinicHistoryField'
import { setAllTemplatesHCController } from '@/controllers/clinicHistoryTemplate'
import useDebounce from '@/hooks/useDebounce'
import useValidateURLId from '@/hooks/useValidateURLId'
import { getListFieldsHC } from '@/store/clinicHistoryField/actions'
import { resetSelectedTemplateHC, setAllTemplatesHC } from '@/store/clinicHistoryTemplate/actions'

import MainPopUp from './components/mainPopUp'
import TemplateHcSearchBar from './components/TemplateHcSearchBar'
import TemplateHcTable from './components/TemplateHcTable'

import s from './styles.module.scss'

const CreateTemplateHC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const entityId = useValidateURLId()
  const { user } = useUser()

  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isDoctor, entityInLocalStorage } = usersProfileConnected
  const doctorCentreId = entityInLocalStorage?.doctorCentreId

  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const { allTemplatesHC, templateHCSelected } = useSelector((state) => state.templateHC)
  const { allFieldsHC } = useSelector((state) => state.fieldsHC)
  const DELAY_TIME = 300

  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)

  const setInit = async () => {
    const AllTemplatesHCFields = await getAllTemplatesHCField()
    dispatch(getListFieldsHC(AllTemplatesHCFields))
  }

  useEffect(() => {
    if (!user.roles.includes('doctor') && !user.roles.includes('centre') && !user.roles.includes('admin')) {
      navigate('/')
      return
    }

    setInit()

    return () => {
      dispatch(resetSelectedTemplateHC())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addNewTemplateHC = () => {
    dispatch(resetSelectedTemplateHC())
    setOpen(!open)
  }

  useEffect(() => {
    if (!entityInLocalStorage) return
    const inputSearch = async () => {
      setIsLoading(true)
      let params = {}

      if (isDoctor) {
        params.doctorCentre = doctorCentreId
      } else {
        params.centre = entityId
      }

      if (inputDebounceValue?.length !== 0) {
        params.search = inputDebounceValue
      }

      const fetchAllTemplatesHC = await setAllTemplatesHCController(params)
      dispatch(setAllTemplatesHC(fetchAllTemplatesHC))

      setIsLoading(false)
    }
    inputSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDebounceValue, entityInLocalStorage])

  return (
    <>
      <div className={`${s.animatedContainer} animate__animated animate__fadeIn`}>
        <TemplateHcSearchBar
          addNewTemplateHC={addNewTemplateHC}
          inputSearch={searchText}
          setInputSearch={setSearchText}
        />

        {isLoading ? (
          <LoaderCircle size={50} />
        ) : allTemplatesHC?.length === 0 ? (
          <div className={s.noItems}>No hay tipos de fichas cargadas aún</div>
        ) : (
          <TemplateHcTable setOpen={setOpen} searchText={searchText} />
        )}
      </div>

      <MainPopUp
        open={open}
        setOpen={setOpen}
        templateHCSelected={templateHCSelected}
        allFieldsHC={allFieldsHC}
        entityId={entityId}
        doctorCentreId={doctorCentreId}
        isDoctor={isDoctor}
      />
    </>
  )
}

export default CreateTemplateHC
