import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import useDebounce from '@/hooks/useDebounce'
import Layout1 from '@/layouts/Layout1'
import { RESET_CENTRE_ADMIN_SELECTED } from '@/store/centres'
import { getCentreByInputSearch } from '@/store/centres/actions'
import { getAllPlanPrices } from '@/store/planPrices/actions'
import { getAllSubscriptionPlans } from '@/store/subscriptionPlans/actions'

import AdminSearchBar from './components/adminSearchBar'
import AdminTable from './components/adminTable'
import PopUpCreateAdmin from './components/popUpCreateAdmin'
import PopUpUpdateAdmin from './components/popUpUpdateAdmin'

import s from './styles.module.scss'

const AdminMainPage = () => {
  const dispatch = useDispatch()

  const DELAY_TIME = 300
  const MIN_CHARACTERS = 0

  const [searchText, setSearchText] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('all')
  const [openCreateCentre, setOpenCreateCentre] = useState(false)
  const [openUpdateCentre, setOpenUpdateCentre] = useState(false)
  const [loadingCentres, setLoadingCentres] = useState(false)
  const [loadingDataUser, setLoadingDataUser] = useState(false)

  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)

  const { allCentres } = useSelector((state) => state?.centres)

  const getCentresBySearch = async () => {
    const params = {
      search: inputDebounceValue,
      status: selectedStatus === 'all' ? null : selectedStatus,
    }
    setLoadingCentres(true)
    await dispatch(getCentreByInputSearch(params))
    setLoadingCentres(false)
  }

  const setInit = async () => {
    await dispatch(getAllPlanPrices())
    await dispatch(getAllSubscriptionPlans())
  }

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const inputSearch = async () => {
      if (inputDebounceValue?.length >= MIN_CHARACTERS) {
        getCentresBySearch()
      }
    }
    inputSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDebounceValue, selectedStatus])

  const addNewCentre = () => {
    dispatch(RESET_CENTRE_ADMIN_SELECTED())
    setOpenCreateCentre(!openCreateCentre)
  }

  return (
    <Layout1 navbar>
      <div className={`${s.animatedContainer} animate__animated animate__fadeIn`}>
        <AdminSearchBar
          addNewCentre={addNewCentre}
          inputSearch={searchText}
          setInputSearch={setSearchText}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
        {loadingCentres ? (
          <LoaderCircle size={50} />
        ) : allCentres?.length === 0 ? (
          <p className={s.noItems}>No hay centros cargados aún</p>
        ) : (
          <AdminTable
            setOpen={setOpenUpdateCentre}
            searchText={searchText}
            selectedStatus={selectedStatus}
            setLoadingDataUser={setLoadingDataUser}
          />
        )}
      </div>

      {openCreateCentre && <PopUpCreateAdmin open={openCreateCentre} setOpen={setOpenCreateCentre} />}
      {openUpdateCentre && (
        <PopUpUpdateAdmin open={openUpdateCentre} setOpen={setOpenUpdateCentre} loadingDataUser={loadingDataUser} />
      )}
    </Layout1>
  )
}

export default AdminMainPage
