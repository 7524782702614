import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'centreInsurances',
  initialState: {
    centreInsurancesDetail: null,
    error: false,
  },
  reducers: {
    CENTRE_INSURANCES_SELECTED: (state, { payload }) => {
      state.centreInsurancesDetail = payload
      state.error = false
    },
    CENTRE_INSURANCES_UPDATED: (state, { payload }) => {
      if (state.centreInsurancesDetail._id === payload._id) {
        state.centreInsurancesDetail = payload
      }
      // state.centreConfigurationDetail = payload
    },
    CENTRE_INSURANCES_DELETE: (state, { payload }) => {
      state.centreInsurancesDetail = null
    },
    CENTRE_INSURANCES_CREATED: (state, { payload }) => {
      state.centreInsurancesDetail = payload
    },
    CENTRE_INSURANCES_ERROR: (state) => {
      state.error = true
    },
  },
})

export const {
  CENTRE_INSURANCES_SELECTED,
  CENTRE_INSURANCES_UPDATED,
  CENTRE_INSURANCES_DELETE,
  CENTRE_INSURANCES_CREATED,
  CENTRE_INSURANCES_ERROR,
} = slice.actions
export default slice.reducer
