import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PLAN_PRICES_SELECTED } from '@/store/planPrices'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Button, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import s from '../../styles.module.scss'

const PlanPricesTable = ({ setOpen }) => {
  const dispatch = useDispatch()

  const [pageSize, setPageSize] = useState(50)

  const { allPlanPrices, isLoaded } = useSelector((state) => state?.planPrices)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const handleClick = (data) => {
    dispatch(PLAN_PRICES_SELECTED(data))
    setOpen(true)
  }

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Nombre del plan',
      minWidth: 70,
      maxWidth: 150,
      ...stylesColumns,
    },
    {
      field: 'country',
      headerName: 'País',
      minWidth: 150,
      ...stylesColumns,
    },
    {
      field: 'minAgendas',
      headerName: 'Min. Agendas',
      minWidth: 100,
      type: 'number',
      ...stylesColumns,
    },
    {
      field: 'maxAgendas',
      headerName: 'Max. Agendas',
      minWidth: 100,
      type: 'number',
      ...stylesColumns,
    },
    {
      field: 'price',
      headerName: `Precio`,
      minWidth: 130,
      type: 'number',
      ...stylesColumns,
    },
    {
      field: 'tax',
      headerName: '% TAX',
      minWidth: 120,
      type: 'number',
      ...stylesColumns,
    },
    {
      field: 'raw',
      headerName: '',
      minWidth: 80,
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title="Editar">
          <Button
            onClick={() => handleClick(params?.value)}
            className={s.rowButton}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            <EditOutlinedIcon />
          </Button>
        </Tooltip>
      ),
    },
  ]

  const rows = allPlanPrices?.map((plan) => {
    return {
      id: plan?._id,
      name: plan?.subscriptionPlan?.name || '',
      country: plan?.country || 'Default',
      minAgendas: plan?.minAgendas || 0,
      maxAgendas: plan?.maxAgendas || 0,
      price: `${handleSetMoneySign(country)} ${plan?.price || '0'}`,
      tax: plan?.tax || 0,
      raw: plan,
    }
  })

  return (
    <Box
      sx={{
        px: 0,
        '& .header': {
          fontSize: '14px',
        },
        '& .blueColor': {
          color: '#1c83c6',
          fontWeight: 800,
        },
        '& .greenColor': {
          color: '#1ab395',
          fontWeight: 800,
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
      }}
    >
      <DataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        rows={rows}
        loading={isLoaded}
        columns={columns}
        getCellClassName={(params) => {
          if (params.row.name === 'Premium' && params.field === 'name') {
            return 'blueColor'
          }

          if (params.row.name === 'Business' && params.field === 'name') {
            return 'greenColor'
          }
        }}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[50, 100]}
        disableRowSelectionOnClick={true}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        slotProps={{
          pagination: {
            labelRowsPerPage: 'Entradas por página:',
            labelDisplayedRows: (props) => `${props.from} - ${props.to} de ${props.count}`,
          },
        }}
        sx={{ fontSize: '13px' }}
        rowHeight={45}
        autoHeight={true}
      />
    </Box>
  )
}

export default PlanPricesTable
