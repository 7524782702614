import * as React from 'react'
import { useSelector } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormCreateInsurance from './FormCreateInsurance'

const PopUpCreateInsurance = ({ open, setOpen }) => {
  const { insurancePriceSelected } = useSelector((state) => state.insurancePrices)

  const handleClose = () => {
    setOpen(!open)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={open} onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={insurancePriceSelected ? `Editar plan` : `Agregar nuevo plan`}
            handleClose={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <FormCreateInsurance formValues={insurancePriceSelected} onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpCreateInsurance
