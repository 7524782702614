import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CaluButton from '@/components/styledComponents/CaluButton'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import s from '../styles.module.scss'

const CancelledPayment = () => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()

  return (
    <>
      <div className={s.statusTitle}>Pago cancelado</div>
      <div className={s.title}>No pudimos procesar su pago, su {t('turnLabel.s')} aún no ha sido reservada</div>

      <div className={s.containerCard}>
        <HighlightOffIcon className={s.checkIcon} />

        <CaluButton color="primary" size="large" onClick={() => navigate('/turnos')}>
          Ir a mis {t('turnLabel.p')}
        </CaluButton>
      </div>
    </>
  )
}

export default CancelledPayment
