import React, { useEffect, useRef, useState } from 'react'
import SignaturePad from '@/components/SignaturePad/SignaturePad'
import CaluButton from '@/components/styledComponents/CaluButton'
import TitlePopUp from '@/components/TitlePopUp'
import { useUploadImage } from '@/hooks/useUploadImage'
import { Redo, RestartAlt, Undo } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'

import s from './styles.module.scss'

const SignatureRecord = ({ open, setOpen, entityState, updateFunction, extraData }) => {
  const [padState, setPadState] = useState([])
  const [postPadState, setPostPadState] = useState([])
  const [imageData, setImageData] = useState(null)

  const { uploadImage: uploadImageSignature, loadingImage: loadingImageSignature } = useUploadImage()
  const { uploadImage, loadingImage } = useUploadImage()

  const sigPad = useRef({})
  const inputFile = useRef(null)

  useEffect(() => {
    if (imageData) {
      sigPad.current.off()
    }

    if (sigPad.current.on && !imageData) {
      sigPad.current.on()
    }
  }, [imageData])

  const handleMouseUp = () => {
    setPostPadState([])
    let newPadState = [...padState] || []
    newPadState.push(sigPad.current.toDataURL())
    setPadState(newPadState)
  }

  const handleUndo = () => {
    if (padState.length) {
      let newPadState = [...padState] || []
      setPadState(newPadState)

      let postState = [...postPadState] || []
      postState.push(newPadState.pop())
      setPostPadState(postState)

      sigPad.current.clear()
      sigPad.current.fromDataURL(padState[newPadState.length - 1])
    }
  }

  const handleRedo = () => {
    if (postPadState.length) {
      let postState = [...postPadState]
      const newPadState = postState.pop()
      setPostPadState(postState)

      let prevPadState = [...padState] || []
      prevPadState.push(newPadState)
      setPadState(prevPadState)

      sigPad.current.clear()
      sigPad.current.fromDataURL(newPadState)
    }
  }

  const handleRestart = () => {
    sigPad.current.clear()
    setPostPadState([])
    setPadState([])
    setImageData(null)
    inputFile.current.value = ''
  }

  const handleClick = async () => {
    const res1 = await fetch(sigPad.current.getTrimmedCanvas().toDataURL('image/png'))
    const res2 = await res1.blob()
    let file = new File([res2], 'sign.png', { type: 'image/png' })
    await uploadImageSignature(file, 'signImage', updateFunction, entityState, extraData)
    handleRestart()
  }

  const handleSaveImage = async () => {
    await uploadImage(imageData, 'signImage', updateFunction, entityState, extraData)
    handleRestart()
  }

  const handleClosePopUp = () => {
    setOpen(false)
    setImageData(null)
    setPadState([])
    setPostPadState([])
  }

  const handleDisableButton = () => {
    if (padState?.length > 0 && imageData) return false
    if (padState?.length > 0) return true
    return false
  }

  return (
    <div>
      <Dialog
        disableEscapeKeyDown={true}
        maxWidth="md"
        open={open}
        fullWidth
        onClose={() => setOpen(false)}
        scroll="paper"
      >
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle={'Registro de firma'} handleClose={handleClosePopUp} />
        </DialogTitle>
        <DialogContent style={{ padding: '0px' }} className="animate__animated animate__fadeIn">
          <Grid container justifyContent="center" margin="auto" className={s.mainContainer}>
            <h5 className={s.header}>
              Registre dentro del rectángulo blanco la firma que será utilizada en sus recetas o seleccione un archivo
              para generarla automáticamente.
            </h5>
            <Grid item xs={12} sm={6} justifyContent="center" marginBottom={2} style={{ display: 'flex' }}>
              <input
                className={s.file}
                type="file"
                id="signatureImage"
                ref={inputFile}
                accept="image/png, image/jpeg"
                onChange={(e) => setImageData(e.target.files[0])}
                disabled={handleDisableButton()}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6} justifyContent="center" marginBottom={2} style={{ display: 'flex' }}>
              {entityState?.signImage ? (
                <>
                  <p className={s.paragraph}>Su firma actual:</p>
                  <img style={{ height: '75px', marginLeft: '10px' }} alt="Signature" src={entityState?.signImage} />
                </>
              ) : (
                <p className={s.paragraph}>Actualmente no tiene firma cargada.</p>
              )}
            </Grid>
            <SignaturePad sigPad={sigPad} handleMouseUp={handleMouseUp} />
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item xs={4}>
                <Undo className={s.icon} onClick={() => handleUndo()} />
                <Redo className={s.icon} onClick={() => handleRedo()} />
                <RestartAlt className={s.icon} onClick={() => handleRestart()} />
              </Grid>
              <Grid item xs={12} className={s.contButton}>
                <CaluButton
                  color="primary"
                  size="medium"
                  onClick={() => (imageData ? handleSaveImage() : handleClick())}
                  loading={loadingImage || loadingImageSignature}
                  disabled={!imageData && padState.length === 0}
                >
                  Guardar firma
                </CaluButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default SignatureRecord
