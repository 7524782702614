import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import CaluModal from '@/components/CaluModal'
import CaluButton from '@/components/styledComponents/CaluButton'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const MercadoPagoIsAboutToExpire = () => {
  const navigate = useNavigate()
  const [mercadoPagoIsAboutToExpire, setMercadoPagoIsAboutToExpire] = useState(false)
  const { detail } = useSelector((state) => state.centreMercadoPagoInfo)
  const creationDateMp = dayjs(detail?.creationDate).format('YYYY-MM-DD')
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isCentre } = usersProfileConnected

  useEffect(() => {
    const currentDate = dayjs().format('YYYY-MM-DD')
    const diff = dayjs(currentDate).diff(creationDateMp, 'days')
    if (diff >= 173 && diff <= 176) {
      return setMercadoPagoIsAboutToExpire(true)
    }
    setMercadoPagoIsAboutToExpire(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = () => {
    navigate(`/perfil?tab=pagos`)
    setMercadoPagoIsAboutToExpire(false)
  }
  return (
    <>
      {mercadoPagoIsAboutToExpire && isCentre && (
        <CaluModal
          open={mercadoPagoIsAboutToExpire}
          setOpen={setMercadoPagoIsAboutToExpire}
          title={'Aviso actualización de módulo'}
        >
          <div>
            <p>
              Te informamos que hay una nueva actualización en nuestro módulo de pagos. Para asegurar que todo funcione
              correctamente, es necesario volver a vincular tu cuenta de Mercado Pago.
            </p>
            <div className={s.containerLink}>
              <CaluButton color="primary" size="large" onClick={handleClick}>
                Ir a pagos
              </CaluButton>
            </div>
          </div>
        </CaluModal>
      )}
    </>
  )
}

export default MercadoPagoIsAboutToExpire
