import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { updateUser } from '@/store/users/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const FormUser = ({ formValues, onClose }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  let auxFormValues = {
    ...formValues,
    patient: formValues?.patient?._id ? formValues?.patient?._id : null,
  }

  const handleSubmit = (data = {}) => {
    setIsLoading(true)
    const { centres, doctorCentres, secretaryCentres, patient } = data
    let roles = [...data.roles]

    if (centres?.length && !roles?.includes('centre')) roles.push('centre')
    if (!centres?.length) roles = roles.filter((rol) => rol !== 'centre')

    if (doctorCentres?.length && !roles?.includes('doctor')) roles.push('doctor')
    if (!doctorCentres?.length) roles = roles.filter((rol) => rol !== 'doctor')

    if (secretaryCentres?.length && !roles?.includes('secretary')) roles.push('secretary')
    if (!secretaryCentres?.length) roles = roles.filter((rol) => rol !== 'secretary')

    if (patient && !roles?.includes('patient')) roles.push('patient')

    if (roles?.includes('extension')) roles = roles?.filter((elem) => elem !== 'extension')

    data.roles = roles
    delete data.extensionRole

    const userId = data?._id
    const auxCentres = data?.centres?.map((c) => {
      return {
        _id: c._id,
      }
    })
    const auxDoctorCentres = data?.doctorCentres?.map((d) => {
      return {
        _id: d._id,
      }
    })
    const auxSecretaryCentres = data?.secretaryCentres?.map((s) => {
      return {
        _id: s._id,
      }
    })
    const auxData = {
      ...data,
      centres: auxCentres,
      doctorCentres: auxDoctorCentres,
      secretaryCentres: auxSecretaryCentres,
    }
    try {
      if (formValues) {
        dispatch(updateUser(auxData, userId))
      }
      onClose()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={{ ...formDefaultValues, ...auxFormValues }}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent isLoading={isLoading} onClose={onClose} auxFormValues={auxFormValues} />
      </ContainerForm>
    </div>
  )
}

export default FormUser
