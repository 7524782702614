import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'

import PropTypes from 'prop-types'

const TextEditor = ({ onChange, value, showLinkButton = true }) => {
  const config = {
    toolbar: ['bold', 'italic', showLinkButton ? 'link' : '', '|', 'undo', 'redo'],
  }

  return (
    <CKEditor
      editor={ClassicEditor}
      data={value}
      config={config}
      onChange={(event, editor) => {
        const data = editor.getData()
        onChange(data)
      }}
    />
  )
}

export default TextEditor

TextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
