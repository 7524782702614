import React from 'react'
import { useDispatch } from 'react-redux'
import notificationLogo from '@/assets/images/notification.png'
import notificationBlackLogo from '@/assets/images/notification-black.png'
import { selectedNotification } from '@/store/notifications/actions'

import s from './styles.module.scss'

const ContentSideBar = ({ listItem }) => {
  const dispatch = useDispatch()

  return (
    <div>
      {!listItem?.length ? (
        <p className={s.emptyList}>No hay notificaciones cargadas aún</p>
      ) : (
        listItem?.map((item) => (
          <div key={item._id} className={s.item} onClick={async () => await dispatch(selectedNotification(item))}>
            <img src={item?.visible ? notificationLogo : notificationBlackLogo} alt="notificaciones" />
            {item?.highlight && <span className={s.highlight}></span>}
            <div className={s.containerInfoService} dangerouslySetInnerHTML={{ __html: item?.message }} />
          </div>
        ))
      )}
    </div>
  )
}

export default ContentSideBar
