import * as XLSX from 'xlsx/xlsx.mjs'

export const xlsxFromJson = (data, fileProps) => {
  const fileName = fileProps?.fileName || 'report'
  const workSheet = XLSX.utils.json_to_sheet(data)
  const workBook = XLSX.utils.book_new()

  XLSX.utils.book_append_sheet(workBook, workSheet, fileName)
  XLSX.writeFile(workBook, `${fileName}.xlsx`)
}
