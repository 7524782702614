import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import ErrorCard from '@/components/ErrorCard'
import { useUser } from '@/context/UserProvider'
import Layout1 from '@/layouts/Layout1'
import HeaderOnLineTurns from '@/pages/InfoPatientPage/components/headerOnLineTurns'
import { removeTurnById } from '@/store/turns/actions'
import { getPatientTurnsInOtherCentre } from '@/store/turns/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { Grid } from '@mui/material'

import dayjs from 'dayjs'
import CardInsurance from './components/cardInsurance'

import s from './styles.module.scss'

const InfoTurnPage = () => {
  const centreID = useParams().id
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const { user } = useUser()

  const [isLoading, setIsLoading] = useState(false)
  const [allowTurnReservation, setAllowTurnReservation] = useState(true)
  const [userError, setUserError] = useState(false)

  const { patientCentresTurns } = useSelector((state) => state.turns)
  const {
    centreTargetProfile,
    entityData,
    turnData,
    confirmedTurn,
    turnDetail,
    patientCentres: selectedPatient,
  } = useSelector((state) => state.turns?.onLineTurns)

  const maxTurnsQuantity = centreTargetProfile?.patientOnlineTurns || 1

  const loadPatientTurns = async () => {
    if (user) {
      await dispatch(getPatientTurnsInOtherCentre(centreID, user?.dni, 'patient'))
    }
  }

  useEffect(() => {
    if (selectedPatient?.blockOnlineTurns) {
      //si tiene bloqueo elimina el turno
      removePendingTurnById()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatient?.blockOnlineTurns])

  useEffect(() => {
    setIsLoading(true)
    if (!entityData || !turnData || !selectedPatient) {
      navigate(`/centro/${centreID}`)
    }

    if (user?.dni) {
      loadPatientTurns()
      setIsLoading(false)
      return
    }

    setUserError(true)
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedPatient?.blockOnlineTurns) {
      setAllowTurnReservation(false)
      return
    }

    if (patientCentresTurns?.length > 0) {
      let filteredFutureTurns = patientCentresTurns?.filter((turn) => {
        if (dayjs(turn?.startHour).utc().format() > dayjs().utc(true).format()) {
          if (!['cancelled', 'pending'].includes(turn?.state)) return turn
        }
        return false
      })

      if (filteredFutureTurns?.length >= maxTurnsQuantity) {
        setAllowTurnReservation(false)
      } else {
        setAllowTurnReservation(true)
      }
    }

    return () => setAllowTurnReservation(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientCentresTurns])

  const removePendingTurnById = async () => {
    await dispatch(removeTurnById(turnDetail?._id, true))
  }

  const text2ErrorCard = (
    <React.Fragment>
      También puedes cancelar {t('turnLabel.p')} actuales desde{' '}
      <a className={s.anchorTag} href="/turnos">
        <b>Mis {t('turnLabel.P')}</b>
      </a>{' '}
      para reservar {t('turnLabel.so')}
    </React.Fragment>
  )

  return (
    <Layout1 navbar isLoading={isLoading}>
      {!allowTurnReservation ? (
        <div className={s.containerErrorCard}>
          <ErrorCard
            title={`Has alcanzado el límite máximo de ${t('turnLabel.p')} online reservados`}
            text1={` Podrás reservar ${t('turnLabel.so')} en ${capitalizeFirstLetters(
              centreTargetProfile?.name,
            )} luego de que finalicen tus ${t('turnLabel.pp')}`}
            text2={text2ErrorCard}
            textButton={`Volver a disponibilidad de ${t('turnLabel.p')}`}
            path={`/centro/${centreID}`}
          />
        </div>
      ) : userError ? (
        <div className={s.containerErrorCard}>
          <ErrorCard
            title={`Hay datos en tu cuenta que son incorrectos`}
            text1={`Contactate con soporte para validarlos`}
            textButton={`Volver a disponibilidad de ${t('turnLabel.p')}`}
            path={`/centro/${centreID}`}
          />
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} className={s.headerTabs}>
            <HeaderOnLineTurns
              isAuthenticated={!!user}
              selectedPatient={selectedPatient}
              confirmedTurn={confirmedTurn}
            />
          </Grid>

          <Grid item xs={12}>
            <CardInsurance />
          </Grid>
        </Grid>
      )}
    </Layout1>
  )
}

export default InfoTurnPage
