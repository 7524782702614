import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { useUser } from '@/context/UserProvider'
import { getOrCreatePatientCentre } from '@/store/patientCentres/actions'
import { RESET_ONLINE_TURNS } from '@/store/turns'
import { checkAvailabilityAndCreateOrUpdateTurn } from '@/store/turns/actions'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const FormCreatePatient = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const centreID = useParams().id
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useUser()

  const { centreTargetProfile, turnDetail } = useSelector((state) => state.turns?.onLineTurns)

  const handleSubmit = async (patientCentreData) => {
    mxp.track(mxp.events.centreLanding.steps.patientDataDone)

    patientCentreData.centre = centreID

    setIsLoading(true)
    try {
      const auxPatient = { ...patientCentreData, email: patientCentreData?.email || user.email }
      const auxPatientCentre = await dispatch(getOrCreatePatientCentre(auxPatient, user))
      let dataTurn = {
        ...turnDetail,
        centre: centreTargetProfile._id,
        dni: auxPatient?.dni,
        patientCentre: auxPatientCentre,
      }
      const validateAndCreateOrUpdateTurn = await dispatch(checkAvailabilityAndCreateOrUpdateTurn(dataTurn))
      if (validateAndCreateOrUpdateTurn) {
        setIsLoading(false)
        navigate(`/turnos/info-turno/${centreID}`)
      } else {
        setIsLoading(false)
        dispatch(RESET_ONLINE_TURNS())
        navigate(`/centro/${centreTargetProfile._id}`)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ContainerForm
      className={s.containerForm}
      onSubmit={(data) => handleSubmit(data)}
      defaultValues={{ ...formDefaultValues }}
      resolver={yupResolver(formValidationSchema)}
    >
      <FormContent isLoading={isLoading} />
    </ContainerForm>
  )
}

export default FormCreatePatient
