export const handleDetectProfileConnected = (user, defaultCentre, entityInLocalStorage) => {
  if (!user || !entityInLocalStorage) return
  let isCentre, isDoctor, isSecretary, isAdmin, isPatient

  if (defaultCentre) {
    isCentre =
      entityInLocalStorage?.role === 'centre' && user?.centres?.map((c) => c?._id)?.includes(entityInLocalStorage?._id)

    isDoctor =
      entityInLocalStorage?.role === 'doctor' &&
      user?.doctorCentres?.map((d) => d?._id)?.includes(entityInLocalStorage?.doctorCentreId) &&
      defaultCentre === entityInLocalStorage?._id

    isSecretary =
      entityInLocalStorage?.role === 'secretary' &&
      user?.secretaryCentres?.map((d) => d?._id)?.includes(entityInLocalStorage?.secretaryCentreId) &&
      defaultCentre === entityInLocalStorage?._id
  }

  isPatient = entityInLocalStorage?.role === 'patient' && user?.patient?._id === entityInLocalStorage._id

  isAdmin = user?.roles?.includes('admin') && entityInLocalStorage?.role === 'admin'

  const userProfile = { isCentre, isDoctor, isSecretary, isAdmin, isPatient, entityInLocalStorage }

  return userProfile
}
