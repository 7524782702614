import React from 'react'
import DividerSection from '@/components/DividerSection/index.jsx'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { Grid } from '@mui/material'

import s from './styles.module.scss'
const InvoiceTurn = () => {
  return (
    <>
      <DividerSection label="Factura/comprobante" />
      <Grid container>
        <Grid item xs={12}>
          <CustomField label="Nº de factura/comprobante" name="invoice" type="text" className={s.inputWidth98} />
        </Grid>
      </Grid>
    </>
  )
}

export default InvoiceTurn
