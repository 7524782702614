import React, { useEffect, useState } from 'react'
import CaluModal from '@/components/CaluModal'
import { apiURL } from '@/helpers/env'
import { version } from '@/helpers/env'

import s from './styles.module.scss'

const NewVersionAvailablePopUp = () => {
  const [open, setOpen] = useState(false)

  const getVersion = async () => {
    const response = await fetch(apiURL).then((response) => response.json())
    if (response?.frontendVersion > version) {
      setOpen(true)
    }
  }

  useEffect(() => {
    getVersion()
  }, [])

  const showTitle = () => {
    return <div>Nueva versión disponible 🎉</div>
  }

  return (
    <CaluModal open={open} setOpen={setOpen} title={showTitle()}>
      <div>Hay una nueva versión disponible. Recarga el sitio para cargarla.</div>
      <br />
      <span className={s.text2}>
        {` Si al recargar sigue apareciendo este mensaje quieras debas borrar el caché de tu navegador. `}
        <a
          href="https://drive.google.com/file/d/1Q_t_BXxl96ZteDZOiBOxIPVcx6QaEJEw/view?usp=share_link"
          target="_blank"
          rel="noopener noreferrer"
          className={s.tagA}
        >
          ¿Cómo borrar caché?
        </a>
      </span>
    </CaluModal>
  )
}

export default NewVersionAvailablePopUp
