import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import HeaderProviderTab from './components/HeaderProvidersTab'
import PopUpCreateProvider from './components/PopUpCreateProvider'
import ProvidersTable from './components/ProvidersTable'

import s from './styles.module.scss'

const ProvidersTab = (directionOfAnimation) => {
  const [openPopUp, setOpenPopUp] = useState(false)
  const [input, setInput] = useState('')
  const [providersFiltered, setProvidersFiltered] = useState([])

  const { allCentreProviders, selectedProvider } = useSelector((state) => state.centreProvider)

  useEffect(() => {
    const filteredProviders = allCentreProviders?.filter((provider) =>
      provider?.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .includes(
          input
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
        ),
    )
    setProvidersFiltered(filteredProviders)
  }, [allCentreProviders, input])

  const handleClose = () => {
    setOpenPopUp(!openPopUp)
  }

  return (
    <div className={directionOfAnimation.directionOfAnimation}>
      <HeaderProviderTab input={input} setInput={setInput} setOpenPopUp={setOpenPopUp} />
      {allCentreProviders?.length === 0 ? (
        <p className={s.emptyList}>No hay proveedores cargados aún</p>
      ) : (
        <div className={s.containerProviderTable}>
          <ProvidersTable setOpenPopUp={setOpenPopUp} providersFiltered={providersFiltered} />
        </div>
      )}

      <PopUpCreateProvider open={openPopUp} onClose={handleClose} centreProviderSelected={selectedProvider} />
    </div>
  )
}

export default ProvidersTab
