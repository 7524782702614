import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Layout1 from '@/layouts/Layout1'
import { Grid } from '@mui/material'

import AprovedTurn from './components/aprovedTurn'

import s from './styles.module.scss'

const TurnDonePage = () => {
  const centreID = useParams().id
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const {
    confirmedTurn,
    entityData,
    turnData,
    patientCentres: selectedPatient,
  } = useSelector((state) => state.turns?.onLineTurns)

  useEffect(() => {
    setIsLoading(true)
    if (!entityData || !turnData || !selectedPatient || !confirmedTurn) {
      navigate(`/centro/${centreID}`)
    }
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout1 navbar isLoading={isLoading}>
      <Grid className={s.mainContainer}>
        <AprovedTurn />
      </Grid>
    </Layout1>
  )
}

export default TurnDonePage
