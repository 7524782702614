import React from 'react'
import { Route, Routes } from 'react-router-dom'
import FormChangeUserPassword from '@/components/FormChangeUserPassword'
import FormRecoverUserPassword from '@/components/FormRecoverUserPassword'
import useGoogleAnalytics from '@/hooks/useAnalytics'
import AdministracionPage from '@/pages/AdministracionPage'
import AdminMainPage from '@/pages/AdminMainPage'
import AdminNotificationsPage from '@/pages/AdminNotificationsPage'
import AdminPanelBankTransferInfo from '@/pages/AdminPanelBankTransferInfo'
import AdminPanelCentre from '@/pages/AdminPanelCentre'
import AdminPanelHCFields from '@/pages/AdminPanelHCFields'
import AdminPanelInsurances from '@/pages/AdminPanelInsurances'
import AdminPanelSpecialities from '@/pages/AdminPanelSpecialities'
import AdminPatientsPage from '@/pages/AdminPatientsPage'
import PlanPricesPage from '@/pages/AdminPlanPricesPage'
import ScheduleLayout from '@/pages/AgendaPage'
import UndatedTurn from '@/pages/AgendaPage/components/undatedTurn/UndatedTurn'
import CashRegister from '@/pages/CashRegister'
import ClinicHistories from '@/pages/ClinicHistories'
import ConfirmOrCancelTurn from '@/pages/ConfirmOrCancelTurn'
import ProfessionalProfile from '@/pages/DoctorProfile'
import FormDoctorProfileReview from '@/pages/DoctorProfile/formDoctorProfileReview'
import FAQsPage from '@/pages/FAQsPage'
import InfoPatientPage from '@/pages/InfoPatientPage'
import InfoTurnPage from '@/pages/InfoTurnPage'
import LandingCentreTargetPage from '@/pages/LandingCentreTargetPage'
import LinkDoctorOrSecretaryRegisterPage from '@/pages/LinkDoctorOrSecretaryRegisterPage'
import LinkDoctorOrSecretaryVerificationPage from '@/pages/LinkDoctorOrSecretaryVerificationPage'
import LoginPage from '@/pages/LoginPage'
import MachinesPage from '@/pages/MachinesPage'
import MainPage from '@/pages/MainPage'
import MyPaysPage from '@/pages/MyPaysPage'
import MyTurnsPage from '@/pages/MyTurnsPage'
import NotFoundPage from '@/pages/NotFoundPage'
import PatientCentresPage from '@/pages/PatientCentresPage'
import PendingPaymentPage from '@/pages/PendingPaymentPage'
import PerfilPage from '@/pages/PerfilPage'
import PriceAndInsurancesPage from '@/pages/PricesAndInsurancesPage'
import Products from '@/pages/ProductsPage'
import RegisterPage from '@/pages/RegisterPage'
import RoomsPage from '@/pages/RoomsPage'
import ServicesPage from '@/pages/ServicesPage'
import SlugPage from '@/pages/SlugPage'
import StatusPage from '@/pages/StatusPage'
import SuccessPaymentPage from '@/pages/SuccessPaymentPage'
import TreatmentTemplate from '@/pages/TreatmentTemplate'
import TurnDonePage from '@/pages/TurnDonePage'
import UsersPage from '@/pages/UsersPage'
import WaitingRoomView from '@/pages/WaitingRoomView'
import WappPage from '@/pages/WappPage'

import CancelledPaymentPage from '../pages/CancelledPaymentPage'
import InfoPaymentOnlineTurn from '../pages/InfoPaymentOnlineTurn'
import CustomRoute from './CustomRoute'

function Router() {
  useGoogleAnalytics()

  return (
    <Routes>
      {/* No Auth routes */}
      <Route path="/" element={<CustomRoute component={MainPage} />} />
      <Route path="/registro" element={<CustomRoute component={RegisterPage} />} />
      <Route path="/estado" element={<CustomRoute component={StatusPage} />} />
      <Route path="/ingresar" element={<CustomRoute component={LoginPage} />} />
      <Route path="/recuperar" element={<CustomRoute component={FormRecoverUserPassword} />} />
      <Route path="/turnos/definir-horario/:id" element={<CustomRoute component={UndatedTurn} />} />
      <Route path="/turnos/conectate/:id" element={<CustomRoute component={InfoPatientPage} />} />
      <Route path="/turnos/confirmar/:id" element={<CustomRoute component={ConfirmOrCancelTurn} />} />
      <Route path="/turnos/cancelar/:id" element={<CustomRoute component={ConfirmOrCancelTurn} />} />
      <Route path="/centro/:id" element={<CustomRoute component={LandingCentreTargetPage} />} />
      <Route path="/perfil-profesional" element={<CustomRoute component={ProfessionalProfile} />} />
      <Route path="/perfil-profesional/:email" element={<CustomRoute auth component={FormDoctorProfileReview} />} />
      <Route
        path="/link/registro/usuario/profesional/:TOKEN"
        element={<CustomRoute component={LinkDoctorOrSecretaryRegisterPage} />}
      />
      <Route
        path="/link/registro/usuario/secretaria/:TOKEN"
        element={<CustomRoute component={LinkDoctorOrSecretaryRegisterPage} />}
      />
      <Route
        path="/link/verificar/profesional/:TOKEN"
        element={<CustomRoute component={LinkDoctorOrSecretaryVerificationPage} />}
      />
      <Route
        path="/link/verificar/secretaria/:TOKEN"
        element={<CustomRoute component={LinkDoctorOrSecretaryVerificationPage} />}
      />
      <Route path="/pago-exitoso" element={<CustomRoute component={SuccessPaymentPage} />} />
      <Route path="/pago-pendiente" element={<CustomRoute component={PendingPaymentPage} />} />
      <Route path="/pago-cancelado" element={<CustomRoute component={CancelledPaymentPage} />} />

      {/* Auth routes */}
      <Route path="/ajustes" element={<CustomRoute auth component={FormChangeUserPassword} />} />
      <Route path="/perfil" element={<CustomRoute auth component={PerfilPage} />} />
      <Route path="/turnos" element={<CustomRoute auth component={MyTurnsPage} />} />
      <Route path="/turnos/info-paciente/:id" element={<CustomRoute auth component={InfoPatientPage} />} />
      <Route path="/turnos/info-turno/:id" element={<CustomRoute auth component={InfoTurnPage} />} />
      <Route path="/turnos/info-pago/:id" element={<CustomRoute auth component={InfoPaymentOnlineTurn} />} />
      <Route path="/turnos/listo/:id" element={<CustomRoute auth component={TurnDonePage} />} />
      <Route path="/preguntas-frecuentes" element={<CustomRoute auth component={FAQsPage} />} />

      {/* Centre, Secretary routes */}
      <Route
        path="/administracion/centro/:id"
        element={<CustomRoute auth roles={['centre', 'secretary', 'admin']} component={AdministracionPage} />}
      />
      <Route
        path="/agendas/centro/:id"
        element={<CustomRoute auth roles={['centre', 'secretary', 'admin']} component={MachinesPage} />}
      />
      <Route
        path="/centro/:id/espacios"
        element={<CustomRoute auth roles={['centre', 'secretary', 'admin']} component={RoomsPage} />}
      />
      <Route
        path="/precios/centro/:id"
        element={<CustomRoute roles={['centre', 'secretary', 'admin']} component={PriceAndInsurancesPage} />}
      />
      <Route
        path="/caja/:id"
        element={<CustomRoute auth roles={['centre', 'secretary', 'admin']} component={CashRegister} />}
      />
      <Route
        path="/centro/:id/sala-de-espera"
        element={<CustomRoute auth roles={['centre', 'secretary', 'admin']} component={WaitingRoomView} />}
      />
      <Route
        path="/recordatorios"
        element={<CustomRoute auth roles={['centre', 'secretary', 'admin']} component={WappPage} />}
      />
      <Route path="/pagos" element={<CustomRoute auth roles={['centre', 'admin']} component={MyPaysPage} />} />

      {/* Centre, Secretary, Doctor routes */}
      <Route
        path="/agenda/centro/:id"
        element={<CustomRoute auth roles={['centre', 'secretary', 'doctor', 'admin']} component={ScheduleLayout} />}
      />
      <Route
        path="/pacientes/centro/:id"
        element={<CustomRoute auth roles={['centre', 'secretary', 'doctor', 'admin']} component={PatientCentresPage} />}
      />
      <Route
        path="/servicios/centro/:id"
        element={<CustomRoute auth roles={['centre', 'secretary', 'doctor', 'admin']} component={ServicesPage} />}
      />
      <Route
        path="tratamientos/centro/:id"
        element={<CustomRoute auth roles={['centre', 'secretary', 'doctor', 'admin']} component={TreatmentTemplate} />}
      />
      <Route
        path="/productos/centro/:id"
        element={<CustomRoute auth roles={['centre', 'secretary', 'doctor', 'admin']} component={Products} />}
      />

      {/* Centre, Doctor routes */}
      <Route
        path="/historias-clinicas/:id"
        element={<CustomRoute auth roles={['centre', 'doctor', 'admin']} component={ClinicHistories} />}
      />

      {/* Admin routes */}
      <Route path="/admin/centros" element={<CustomRoute auth roles={['admin']} component={AdminMainPage} />} />
      <Route path="/admin/usuarios" element={<CustomRoute auth roles={['admin']} component={UsersPage} />} />
      <Route path="/admin/pacientes" element={<CustomRoute auth roles={['admin']} component={AdminPatientsPage} />} />
      <Route
        path="/admin/notificaciones"
        element={<CustomRoute auth roles={['admin']} component={AdminNotificationsPage} />}
      />
      <Route
        path="/admin/precio-de-planes"
        element={<CustomRoute auth roles={['admin']} component={PlanPricesPage} />}
      />
      <Route
        path="/admin/panel/camposHC"
        element={<CustomRoute auth roles={['admin']} component={AdminPanelHCFields} />}
      />
      <Route
        path="/admin/panel/obras-sociales"
        element={<CustomRoute auth roles={['admin']} component={AdminPanelInsurances} />}
      />
      <Route
        path="/admin/panel/especialidades"
        element={<CustomRoute auth roles={['admin']} component={AdminPanelSpecialities} />}
      />
      <Route
        path="/admin/panel/datosbancarios"
        element={<CustomRoute auth roles={['admin']} component={AdminPanelBankTransferInfo} />}
      />
      <Route path="/admin/panel/centro" element={<CustomRoute auth roles={['admin']} component={AdminPanelCentre} />} />

      <Route path="/no/existe" element={<CustomRoute component={NotFoundPage} />} />
      <Route path="/:slug" element={<CustomRoute component={SlugPage} />} />

      {/* 404 */}
      <Route path="/*" element={<CustomRoute component={NotFoundPage} />} />
    </Routes>
  )
}

export default Router
