import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { paymentStatus } from '@/helpers/constants'
import AttachedPopUp from '@/pages/MyPaysPage/components/Payments/AttachedPopUp'
import { SUBSCRIPTION_PAYMENT_SELECTED } from '@/store/subscriptionPayments'
import { handlePaginationChangeSubscriptionPayments } from '@/store/subscriptionPayments/actions'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import DownloadIcon from '@mui/icons-material/Download'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Box, Button, Chip, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import ReceiptPopUp from './ReceiptPopUp'

import s from './styles.module.scss'

const PaymentsTable = ({ setOpenVoucherPopUp }) => {
  const dispatch = useDispatch()
  const { allSubscriptionPayments, paginationData, isLoading, selectedPayment } = useSelector(
    (state) => state?.subscriptionPayments,
  )

  const [openAttachedPopUp, setOpenAttachedPopUp] = useState(false)
  const [openReceiptPopUp, setOpenReceiptPopUp] = useState(false)

  const { limit, totalDocs, page } = paginationData || {}

  const pendingColor = '#ffea8a'
  const paidColor = '#63d2bc'
  const reviewColor = '#7fd5f4'

  const getChipProps = (params) => {
    if (params?.row?.raw?.paymentStatus === 'pending') {
      return {
        label: params.value,
        style: {
          backgroundColor: pendingColor,
        },
      }
    }

    if (['paid', 'partiallyPaid'].includes(params?.row?.raw?.paymentStatus)) {
      return {
        label: params.value,
        style: {
          backgroundColor: paidColor,
        },
      }
    }

    if (params?.row?.raw?.paymentStatus === 'review') {
      return {
        label: params.value,
        style: {
          backgroundColor: reviewColor,
        },
      }
    }
  }

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  }

  const columns = [
    {
      field: 'period',
      headerName: 'Periodo',
      minWidth: 100,
      cellClassName: 'periodStyle',
      ...stylesColumns,
    },
    {
      field: 'amount',
      headerName: 'Monto',
      minWidth: 100,
      ...stylesColumns,
    },
    {
      field: 'dueDate',
      headerName: 'Fecha de vencimiento',
      minWidth: 100,
      ...stylesColumns,
    },
    {
      field: 'status',
      headerName: 'Estado',
      minWidth: 170,
      renderCell: (params) => {
        if (params?.row?.raw?.paymentStatus) {
          return <Chip variant="filled" size="small" {...getChipProps(params)} />
        }
      },
      ...stylesColumns,
    },
    {
      field: 'paymentDate',
      headerName: 'Fecha de pago',
      minWidth: 100,
      ...stylesColumns,
    },
    {
      field: 'attached',
      headerName: 'Comprobante de pago',
      minWidth: 100,
      maxWidth: 140,
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title="Adjuntar comprobante">
          <Button
            onClick={() => handleClickEditAttached(params?.row)}
            className={s.rowButton}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            {params?.row?.raw?.attached?.length > 0 ? (
              <DownloadIcon fontSize="small" />
            ) : (
              <AttachFileIcon fontSize="small" />
            )}
          </Button>
        </Tooltip>
      ),
    },
    {
      field: 'receipt',
      headerName: 'Factura',
      minWidth: 100,
      maxWidth: 140,
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title="Adjuntar factura">
          <Button
            onClick={() => handleClickUploadReceipt(params?.row)}
            className={s.rowButton}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            {params?.row?.raw?.receipt?.length > 0 ? (
              <DownloadIcon fontSize="small" />
            ) : (
              <UploadFileIcon fontSize="small" />
            )}
          </Button>
        </Tooltip>
      ),
    },
    {
      field: 'raw',
      headerName: '',
      minWidth: 100,
      maxWidth: 140,
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title="Editar">
          <Button
            onClick={() => handleClickEdit(params?.value)}
            className={s.rowButton}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            <EditOutlinedIcon />
          </Button>
        </Tooltip>
      ),
    },
  ]

  const rows = allSubscriptionPayments?.map((entry) => {
    return {
      id: entry?._id,
      period: toCustomTz(entry?.period, undefined, true, `${entry?.isAnual ? 'YYYY' : 'MMMM YYYY'}`).toUpperCase(),
      amount: `${entry?.currency === 'ARG' || 'ARS' ? '$' : 'USD'} ${entry?.amount}`,
      dueDate: toCustomTz(entry?.dueDate, undefined, true, 'YYYY-MM-DD'),
      status: convertValueToName(paymentStatus, entry?.paymentStatus),
      paymentDate: entry?.paymentDate ? toCustomTz(entry?.paymentDate, undefined, true, 'YYYY-MM-DD') : '-',
      attached: '',
      receipt: '',
      raw: entry,
    }
  })

  const handleClickEdit = async (data) => {
    dispatch(SUBSCRIPTION_PAYMENT_SELECTED(data))
    setOpenVoucherPopUp(true)
  }

  const handleClickUploadReceipt = (data) => {
    dispatch(SUBSCRIPTION_PAYMENT_SELECTED(data))
    setOpenReceiptPopUp(true)
  }

  const handleClickEditAttached = (data) => {
    dispatch(SUBSCRIPTION_PAYMENT_SELECTED(data))
    setOpenAttachedPopUp(true)
  }

  const handlePaginationChange = ({ page, pageSize }) => {
    dispatch(handlePaginationChangeSubscriptionPayments(page + 1, pageSize))
  }

  return (
    <div>
      {allSubscriptionPayments?.length === 0 ? (
        <p>No hay cupones de pago generados</p>
      ) : (
        <Box
          sx={{
            px: 0,
            '& .header': {
              fontSize: '14px',
            },
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
            '& .centreName': {
              fontWeight: 'bold',
            },
            '& .periodStyle': {
              fontWeight: '600',
            },
          }}
        >
          <DataGrid
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: limit,
                },
              },
            }}
            disableColumnMenu={true}
            disableColumnReorder={true}
            rows={rows || []}
            rowCount={totalDocs || 0}
            loading={isLoading}
            columns={columns}
            paginationMode="server"
            pagination
            onPaginationModelChange={handlePaginationChange}
            pageSizeOptions={[30, 50, 100]}
            disableRowSelectionOnClick={true}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            slotProps={{
              pagination: {
                labelRowsPerPage: 'Entradas por página:',
                labelDisplayedRows: () =>
                  `${(page - 1) * limit + 1} - ${page * limit > totalDocs ? totalDocs : page * limit} de ${totalDocs}`,
              },
            }}
            sx={{ fontSize: '13px', marginBottom: '20px' }}
            rowHeight={45}
            autoHeight={true}
            sortModel={[{ field: 'dueDate', sort: 'desc' }]}
          />
        </Box>
      )}

      {openAttachedPopUp && (
        <AttachedPopUp open={openAttachedPopUp} setOpen={setOpenAttachedPopUp} dataVoucher={selectedPayment} />
      )}

      {openReceiptPopUp && (
        <ReceiptPopUp open={openReceiptPopUp} setOpen={setOpenReceiptPopUp} dataVoucher={selectedPayment} />
      )}
    </div>
  )
}

export default PaymentsTable
