export const formDefaultValues = {
  slug: '',
  hideMoreOnlineTurns: false,
  onlineTurnsStart: 15,
  timezone: '',
  patientOnlineTurns: 1,
  onlineTurnsLimit: 60,
  other1: '',
  centreConfiguration: {
    hoursToCancelTurn: 24,
  },
  socialNetworks: {
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: '',
  },
}
