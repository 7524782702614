export const formDefaultValues = {
  name: '',
  lastname: '',
  firstname: '',
  email: '',
  password: '',
  country: 'ARG',
  status: 'demo',
  category: 'medicine',
}
