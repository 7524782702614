import React from 'react'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

const TitleCard = ({ title, className }) => {
  return <div className={`${s.title} ${className}`}>{title}</div>
}

export default TitleCard

TitleCard.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}
