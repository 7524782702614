import React, { useEffect, useState } from 'react'
import { Controller, useController } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from '@/context/UserProvider'
import useDebounce from '@/hooks/useDebounce'
import { getPatientsCentreByInputSearch } from '@/store/patientCentres/actions'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'

import s from './styles.module.scss'

export default function PatientBrowserField({ name }) {
  const dispatch = useDispatch()
  const { defaultCentre } = useUser()

  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)

  const DELAY_TIME = 300
  const MIN_CHARACTERS = 2
  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)
  const { patientCentres } = useSelector((state) => state.patientCentres)

  useEffect(() => {
    const searchingPatients = async () => {
      if (inputDebounceValue?.length >= MIN_CHARACTERS) {
        const params = {
          centre: defaultCentre,
          search: inputDebounceValue,
        }
        setLoading(true)
        await dispatch(getPatientsCentreByInputSearch(params))
        setLoading(false)
      }
    }
    searchingPatients()
  }, [defaultCentre, dispatch, inputDebounceValue])

  const getOptionLabel = (option) => {
    if (!option) return ''
    let DNI
    DNI = option?.dni?.length < 15 ? `DNI: ${option?.dni}` : ''
    return `${option?.lastname?.toUpperCase()}, ${option?.firstname?.toUpperCase()} (${DNI})`
  }

  const {
    field: { onChange, value },
  } = useController({ name })

  return (
    <Controller
      name={name}
      defaultValue={[]}
      render={() => (
        <Autocomplete
          loading={loading}
          loadingText="Cargando..."
          disablePortal
          id="combo-box-demo"
          disableClearable
          value={value}
          noOptionsText="No se encontraron resultados"
          onChange={(event, newValue) => onChange(newValue)}
          options={patientCentres?.map((option) => option)}
          getOptionLabel={(option) => getOptionLabel(option)}
          isOptionEqualToValue={(option, value) =>
            option._id ? option._id === value._id : option.value ? option.value === value.value : option === value
          }
          renderInput={(params) => (
            <>
              <TextField
                variant="standard"
                {...params}
                label="Buscar paciente"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: loading ? <CircularProgress className={s.circularProgress} /> : null,
                  type: 'search',
                }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </>
          )}
        />
      )}
    />
  )
}
