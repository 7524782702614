import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'changeViewCalendar',
  initialState: {
    dailyView: true,
    calendarEntities: [],
    filteredEntities: [],
    entitySelected: null,
    dateRange: null,
  },
  reducers: {
    CALENDAR_ENTITIES_UPDATED: (state, { payload }) => {
      state.calendarEntities = payload
    },
    ENTITY_SELECTED: (state, { payload }) => {
      state.entitySelected = payload
    },
    RESET_SELECTED_ENTITY: (state, { payload }) => {
      state.entitySelected = null
    },
    CHANGE_CALENDAR_VIEW: (state, { payload }) => {
      state.dailyView = payload
    },
    SELECTED_RANGE: (state, { payload }) => {
      state.dateRange = payload
    },
    FILTERED_ENTITIES: (state, { payload }) => {
      state.filteredEntities = payload
    },
  },
})

export const {
  CALENDAR_ENTITIES_UPDATED,
  ENTITY_SELECTED,
  RESET_SELECTED_ENTITY,
  CHANGE_CALENDAR_VIEW,
  SELECTED_RANGE,
  FILTERED_ENTITIES,
} = slice.actions
export default slice.reducer
