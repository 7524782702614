import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useUser } from '@/context/UserProvider'
import { isDebug } from '@/helpers/env'
import { setSyncDeleteEntity, setSyncNewEntity, setSyncUpdateEntity } from '@/store/doctorCentres/actions'
import {
  getAllTurns,
  setDaysMonth,
  setSyncDeleteTurn,
  setSyncNewTurn,
  setSyncUpdateTurn,
  syncTurnPayment,
} from '@/store/turns/actions'
import { showInternetDisconnectedAlert } from '@/utils/alert'
import { showToastInternetDisconnected } from '@/utils/toast'

import dayjs from 'dayjs'
import io from 'socket.io-client'

// @ts-ignore
const SocketContext = createContext(null)

export default function SocketProvider(props) {
  const dispatch = useDispatch()
  const toastId = useRef(null)
  const customId = 'custom-id-prevent-duplicate'
  const { defaultCentre, user } = useUser()
  const [socket, setSocket] = useState(null)
  const [isConnected, setIsConnected] = useState(null)
  const [isReconnect, setIsReconnect] = useState(false)

  const { allMachines } = useSelector((state) => state.machines)
  const { selectedCalendarDate } = useSelector((state) => state.turns)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected

  const toastInternetDisconnected = () => {
    if (!toast.isActive(toastId.current)) {
      return (toastId.current = showToastInternetDisconnected(customId))
    }
  }
  const hideToastInternetDisconnected = () => toast.dismiss(toastId.current)

  const reconnectSocket = () => {
    if (isDebug) console.log('reconnectSocket', socket?.connected, socket, defaultCentre)
    if (!socket || socket?.connected) {
      return
    }

    if (defaultCentre) {
      socket.connect()
      // socket.emit('join', `${defaultCentre}_calendar`)
      setIsConnected(true)
    } else {
      socket.disconnect()
      setIsConnected(false)
    }
  }

  const createSocket = () => {
    if (socket) return

    if (isDebug) console.log('[SOCKET] createSocket', socket, defaultCentre)
    const API = import.meta.env.REACT_APP_API_BASE_URL
    const auxSocket = io(API, { forceNew: true })
    auxSocket.emit('join', `${defaultCentre}_calendar`)

    auxSocket.on('calendar_spread_new_turn', (turn) => dispatch(setSyncNewTurn(turn)))
    auxSocket.on('calendar_spread_edit_turn', (turn) => dispatch(setSyncUpdateTurn(turn)))
    auxSocket.on('calendar_spread_delete_turn', (turn) => dispatch(setSyncDeleteTurn(turn._id)))
    auxSocket.on('calendar_spread_new_payment', (turn) => dispatch(syncTurnPayment(turn)))

    auxSocket.on('calendar_spread_new_entity', (entity) => dispatch(setSyncNewEntity(entity)))
    auxSocket.on('calendar_spread_edit_entity', (entity) => dispatch(setSyncUpdateEntity(entity)))
    auxSocket.on('calendar_spread_delete_entity', (entity) => dispatch(setSyncDeleteEntity(entity)))

    auxSocket.on('connect', () => {
      if (isDebug) console.log('[SOCKET] connect', auxSocket, defaultCentre)
      auxSocket.emit('join', `${defaultCentre}_calendar`)
      hideToastInternetDisconnected()
      setIsConnected(true)
    })

    auxSocket.on('connect_error', () => {
      if (isDebug) console.log('[SOCKET] connect_error', auxSocket, defaultCentre)
      setIsConnected(false)

      setTimeout(() => {
        if (isDebug) console.log('[SOCKET] call connect()', auxSocket, defaultCentre)
        auxSocket.connect()
      }, 1000)
    })

    auxSocket.on('disconnect', (reason) => {
      if (!user) return

      if (isDebug) console.log('[SOCKET] disconnect', auxSocket, defaultCentre)
      toastInternetDisconnected()
      showInternetDisconnectedAlert()
      setIsConnected(false)
      setIsReconnect(true)

      setTimeout(() => {
        if (isDebug) console.log('[SOCKET] call connect()', auxSocket, defaultCentre)
        auxSocket.connect()
      }, 1000)
    })

    auxSocket.on('reconnect', () => {
      if (isDebug) console.log('[SOCKET] reconnect', auxSocket, defaultCentre)
      auxSocket.emit('join', `${defaultCentre}_calendar`)
      setIsConnected(true)
    })

    setSocket(auxSocket)
    setIsConnected(true)
  }

  useEffect(() => {
    if (!defaultCentre) {
      if (socket) socket.disconnect()
      setSocket(null)
      setIsConnected(null)
      setIsReconnect(false)
      return
    }

    createSocket()
  }, [defaultCentre]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isReconnect && isConnected && window?.location?.pathname?.startsWith('/agenda/centro')) {
      let turnsParams = {
        centre: defaultCentre,
        start: dayjs(selectedCalendarDate).utc().hour(0).minute(0).second(0).toISOString(),
        finish: dayjs(selectedCalendarDate).add(31, 'days').utc().hour(23).minute(59).second(0).toISOString(),
        selectedRole: entityInLocalStorage?.role,
      }

      if (entityInLocalStorage?.role === 'doctor') {
        turnsParams.doctorMachines = allMachines?.map((m) => m?._id)
      }

      let start = dayjs(turnsParams.start).utc().format('YYYY-MM-DD')
      const end = dayjs(turnsParams.finish).utc().format('YYYY-MM-DD')
      const diff = dayjs(end).diff(start, 'day')
      const arrayOfDays = []
      arrayOfDays.push(start)
      for (let i = 0; i < diff; i++) {
        arrayOfDays.push(dayjs(start).add(1, 'days').format('YYYY-MM-DD'))
        start = dayjs(start).add(1, 'days').format('YYYY-MM-DD')
      }
      dispatch(setDaysMonth(arrayOfDays))
      dispatch(getAllTurns(turnsParams))
      setIsReconnect(false)
    }
  }, [isReconnect, isConnected]) // eslint-disable-line react-hooks/exhaustive-deps

  const value = { socket, reconnectSocket, isConnected }
  return <SocketContext.Provider value={value} {...props} />
}

export const useSocket = () => {
  const context = useContext(SocketContext)
  if (!context) {
    throw new Error('This hook only can used into the SocketContext')
  }
  return context
}
