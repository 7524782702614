import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import { setAllCentreProvidersController } from '@/controllers/centreProvider'
import useValidateURLId from '@/hooks/useValidateURLId'
import { getAllCentreProviders } from '@/store/centreProvider/actions'

import HeaderSearchBar from '../headerSearchBar'
import ManagementTable from '../managementTable'
import SummaryTable from '../summaryTable'

import s from '../../styles.module.scss'

const BillingTab = ({
  setOpenAddEntry,
  inputSearch,
  setInputSearch,
  loadingPatients,
  setLoadingPatients,
  patientValue,
  setPatientValue,
}) => {
  const dispatch = useDispatch()
  const centreID = useValidateURLId()

  const { cashboxEntries, cashLoading } = useSelector((state) => state.cashboxItems)

  const setInit = async (params) => {
    const fetchAllCentreProviders = await setAllCentreProvidersController(params)
    dispatch(getAllCentreProviders(fetchAllCentreProviders))
  }

  useEffect(() => {
    let params = {
      centre: centreID,
    }

    setInit(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <HeaderSearchBar
        billingTab={true}
        setOpenAddEntry={setOpenAddEntry}
        inputSearch={inputSearch}
        setInputSearch={setInputSearch}
        loadingPatients={loadingPatients}
        setLoadingPatients={setLoadingPatients}
        patientValue={patientValue}
        setPatientValue={setPatientValue}
      />
      {cashLoading ? (
        <LoaderCircle size={50} />
      ) : cashboxEntries?.length === 0 ? (
        <p className={s.noItems}>No hay items para las fechas solicitadas</p>
      ) : (
        <>
          <ManagementTable billingTab={true} setOpenAddEntry={setOpenAddEntry} patientValue={patientValue} />
          <div>
            <SummaryTable billingTab={true} />
          </div>
        </>
      )}
    </div>
  )
}

export default BillingTab
