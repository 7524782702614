import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CaluModal from '@/components/CaluModal'
import LoaderCircle from '@/components/LoaderCircle'
import { getCentreTarget } from '@/store/centres/actions'
import { RESET_SELECTED_REMINDER } from '@/store/wapp'
import {
  createWappReminder,
  getWappRemindersHistory,
  getWappTurnHistory,
  sendWappMessage,
  updateWappReminderHistory,
  updateWappTurnHistory,
} from '@/store/wapp/actions'
import { getMessageTypeReminder } from '@/utils/functions/getMessageTypeReminder'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { showToast } from '@/utils/toast'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import Tooltip from '@mui/material/Tooltip'

import dayjs from 'dayjs'
import HeaderManagement from './components/HeaderManagement'
import ManagementTable from './components/ManagementTable'

import s from './styles.module.scss'

const TabTurnsHistory = () => {
  const dispatch = useDispatch()
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected
  const deviceId = entityInLocalStorage._id
  const { wappHistory, isLoading } = useSelector((state) => state.wapp)
  const { selectedTurnReminder, selectedReminder, turnHistory, remindersHistory } = wappHistory
  const auxSelectedReminder = selectedTurnReminder || selectedReminder
  const [open, setOpen] = useState(false)
  const [startDate, setStartDate] = useState(dayjs().subtract(8, 'day').startOf('day').format())
  const [finishDate, setFinishDate] = useState(dayjs().subtract(1, 'day').endOf('day').format())
  const [isResendingReminder, setIsResendingReminder] = useState(false)
  const [selectValue, setSelectValue] = useState(1)
  const [filterOptionsValue, setFilterOptionValue] = useState(2)

  const setInit = async () => {
    let params = {
      centre: deviceId,
      start: toCustomTz(startDate, 'utc', true),
      finish: toCustomTz(finishDate, 'utc', true),
      page: 1,
      limit: 30,
    }
    if (selectValue === 1) {
      params.sent = true
      dispatch(getWappTurnHistory(params))
      return
    }

    const messageType = getMessageTypeReminder(filterOptionsValue)
    if (messageType) {
      params.messageType = messageType
    }

    dispatch(getWappRemindersHistory(params))
  }

  useEffect(() => {
    dispatch(getCentreTarget(deviceId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (!open) {
      handleOnclose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, finishDate, selectValue, filterOptionsValue])

  const handleOnclose = () => {
    dispatch(RESET_SELECTED_REMINDER())
  }

  const resendReminder = async () => {
    setIsResendingReminder(true)
    const attempt = auxSelectedReminder.attempts?.slice(-1)[0]

    const { turn } = auxSelectedReminder
    const auxMessageData = {
      text: attempt.message,
      number: attempt.mobile,
    }
    try {
      const sentStatusInfo = await dispatch(sendWappMessage(deviceId, auxMessageData))

      const attemptData = {
        centre: deviceId,
        patientCentre: turn.patientCentre._id,
        turn: turn._id,
        message: attempt?.message,
        status: sentStatusInfo?.status === 1 ? 'sent' : 'error',
        mobile: attempt?.mobile,
        senderNumber: attempt.senderNumber,
        messageType: attempt.messageType,
        _id: auxSelectedReminder?.turn?.wappReminder?._id || auxSelectedReminder.id,
      }
      setIsResendingReminder(false)
      const createdWappReminder = await dispatch(createWappReminder(attemptData))
      if (auxSelectedReminder?.turn?.wappReminder) {
        const auxTurn = {
          ...turn,
          wappReminder: createdWappReminder,
        }
        await dispatch(updateWappTurnHistory(auxTurn))
      } else {
        const auxReminder = {
          ...createdWappReminder,
          patientCentre: turn?.patientCentre,
        }
        await dispatch(updateWappReminderHistory(auxReminder))
      }

      setIsResendingReminder(false)
      showToast('Recordatorio reenviado correctamente', 'success', 2000)
    } catch (e) {
      setIsResendingReminder(false)
      setOpen(false)
    }
  }

  const renderDateReminder = (a) => {
    const formatedDate = dayjs(a?.date).format('DD/MM/YYYY [a las] HH:mm [hs]')
    const status = a.status
    let icon = null

    if (status === 'sent') {
      icon = (
        <Tooltip title="Enviado">
          <CheckIcon className={s.checkIcon} />
        </Tooltip>
      )
    }
    if (status === 'error') {
      icon = (
        <Tooltip title="Error al enviar">
          <CloseIcon className={s.errorIcon} />
        </Tooltip>
      )
    }

    return (
      <div className={s.iconContainer}>
        <b>Fecha de envío </b>
        <span className={s.formatedDate}>
          {icon && icon}
          {formatedDate}
        </span>
      </div>
    )
  }

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <HeaderManagement
        startDate={startDate}
        setStartDate={setStartDate}
        finishDate={finishDate}
        setFinishDate={setFinishDate}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        filterOptionsValue={filterOptionsValue}
        setFilterOptionValue={setFilterOptionValue}
        isLoadingRemindersHistory={isLoading}
      />
      {isLoading ? (
        <LoaderCircle size={50} />
      ) : turnHistory?.length === 0 && remindersHistory?.length === 0 ? (
        <div className={s.noItems}>No hay items para las fechas solicitadas</div>
      ) : (
        <ManagementTable
          startDate={startDate}
          finishDate={finishDate}
          deviceId={deviceId}
          setOpen={setOpen}
          selectValue={selectValue}
          filterOptionsValue={filterOptionsValue}
        />
      )}

      <CaluModal
        open={open}
        setOpen={setOpen}
        title="Detalle recordatorios"
        maxWidth="lg"
        textConfirmationButton={'Reenviar mensaje'}
        onClose={resendReminder}
        isLoading={isResendingReminder}
        disabled={isResendingReminder}
      >
        {auxSelectedReminder?.attempts
          ?.slice()
          .sort((a, b) => dayjs(b.date).diff(dayjs(a.date)))
          .map((a) => (
            <ul>
              <li key={a._id}>
                {renderDateReminder(a)}
                <div>
                  <b>Tipo de mensaje </b>
                  {a?.messageType || '-'}
                </div>
                <div>
                  <b>Mensaje </b>
                  {a.message}
                </div>
              </li>
            </ul>
          ))}
      </CaluModal>
    </div>
  )
}

export default TabTurnsHistory
