import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { useUser } from '@/context/UserProvider'
import { addNewDoctorProfile, getDoctorProfile } from '@/store/doctorProfile/actions'
import { showToast } from '@/utils/toast'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const FormRegiterProfesional = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { register } = useUser()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (data) => {
    const createDoctorProfile = async () => {
      if (data.email !== data.repeatEmail && data.password !== data.repeatPassword) {
        showToast('tanto los emails como las contraseñas no coinciden', 'error')
      } else if (data.email !== data.repeatEmail) {
        showToast('los emails no coinciden', 'error')
      } else if (data.password !== data.repeatPassword) {
        showToast('Las contraseñas no coinciden', 'error')
      } else {
        setIsLoading(true)
        delete data.repeatPassword
        delete data.repeatEmail
        const role = 'doctor_no_centre'

        const userCreated = await register(data, null, role)
        if (userCreated) {
          const doctorProfileCreated = await dispatch(addNewDoctorProfile(data))
          if (doctorProfileCreated._id) {
            await dispatch(getDoctorProfile(doctorProfileCreated.email))
            navigate(`/perfil-profesional/${doctorProfileCreated.email}`)
          }
          setIsLoading(false)
        } else {
          showToast('El mail ingresado ya esta en uso.', 'error')
          setIsLoading(false)
        }
      }
    }
    createDoctorProfile()
  }

  return (
    <div>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={{ ...formDefaultValues }}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent isLoading={isLoading} />
      </ContainerForm>
    </div>
  )
}

export default FormRegiterProfesional
