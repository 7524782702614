import React from 'react'
import { useTranslation } from 'react-i18next'
import { BsCheck } from 'react-icons/bs'
import { FaPhoneAlt } from 'react-icons/fa'
import { FaMobileAlt } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import defaultImage from '@/assets/images/default.png'
import LoaderCircle from '@/components/LoaderCircle'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { Button } from '@mui/material'

import s from './styles.module.scss'

const ListItemsEntity = ({ listItemsPerEntity, listTitle, selectedEntityFunction, messaje, loadingEntity }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  return (
    <div>
      <p className={s.listTitle}>{listTitle}</p>
      {loadingEntity ? (
        <LoaderCircle />
      ) : listItemsPerEntity?.length === 0 ? (
        <p className={s.message}>{capitalizeFirstLetters(messaje)}</p>
      ) : (
        listItemsPerEntity?.map((item) => (
          <div key={item._id}>
            <Button
              className={s.containerInfoEntity}
              onClick={async () => await dispatch(selectedEntityFunction(item._id))}
            >
              <div>
                <img
                  src={item?.image || defaultImage}
                  className={item?.image ? s.imageEntity : s.defaultImage}
                  alt="Imagen de perfil"
                />
              </div>
              <div className={s.itemEntity}>
                <p className={s.titleEntity}>
                  {capitalizeFirstLetters(item?.lastname)}, {capitalizeFirstLetters(item?.firstname)}
                </p>
                <div className={s.infoExtra}>
                  {item?.telephone ? (
                    <p>
                      <FaPhoneAlt />
                      {` ${item?.telephone}`}
                    </p>
                  ) : null}
                  {item?.mobile ? (
                    <p>
                      <FaMobileAlt />
                      {` ${item?.mobile}`}
                    </p>
                  ) : null}
                </div>
                {item?.onlineTurns === undefined ? null : item?.onlineTurns ? (
                  <p className={s.onlineTurnsTrue}>
                    <BsCheck />
                    <span>{capitalizeFirstLetters(t('turnLabel.p'))} online</span>
                  </p>
                ) : (
                  <p className={s.onlineTurnsFalse}>
                    <IoClose />
                    <span>{capitalizeFirstLetters(t('turnLabel.p'))} online</span>
                  </p>
                )}
              </div>
            </Button>
          </div>
        ))
      )}
    </div>
  )
}

export default ListItemsEntity
