import React, { useEffect, useState } from 'react'
import { useUser } from '@/context/UserProvider'

import AcordionQuestions from './components/acordionQuestions'
import data from './components/data'
import SearchQuestion from './components/searchQuestion'

import s from './styles.module.scss'

const FAQs = () => {
  const { user } = useUser()

  const [input, setInput] = useState('')
  const [initialQuestions, setInitialQuestions] = useState([])
  const [questionsFiltered, setQuestionsFiltered] = useState([])

  const handleChange = (e) => {
    setInput(e.target.value)
  }

  useEffect(() => {
    if (user?.roles?.length === 1 && user?.roles[0] === 'patient') {
      setInitialQuestions(data.filter((elem) => elem.validRoles.includes('patient')))
    }

    if (user?.roles?.includes('doctor')) {
      setInitialQuestions(data.filter((elem) => elem.validRoles.includes('doctor')))
    }

    if (user?.roles?.includes('centre' || 'secretary')) {
      setInitialQuestions(data)
    }
  }, [user])

  useEffect(() => {
    if (initialQuestions.length > 0) {
      const filteredQuestions = initialQuestions.filter((question) =>
        question.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            input
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase(),
          ),
      )
      setQuestionsFiltered(filteredQuestions)
    }
  }, [input, initialQuestions])

  return (
    <div className={s.contentBlock}>
      <p>
        Si las preguntas frecuentes no responden tus dudas podés escribirnos un mensaje abajo a la derecha y nosotros te
        ayudamos.
      </p>

      <SearchQuestion input={input} handleChange={handleChange} />
      <AcordionQuestions questionsFiltered={questionsFiltered} />
    </div>
  )
}

export default FAQs
