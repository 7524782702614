import React from 'react'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

const InformationCardItem = ({ icon, header, subheader1, subheader2 }) => {
  return (
    <div className={s.itemContainer}>
      <div className={s.itemInformation}>
        <div className={s.icon}>{icon}</div>
        <div className={s.header}>{header}</div>
      </div>
      <div className={s.subHeader}>
        <div>{subheader1 || ''}</div>
        <div>{subheader2}</div>
      </div>
    </div>
  )
}

export default InformationCardItem

InformationCardItem.propTypes = {
  icon: PropTypes.object,
  header: PropTypes.string.isRequired,
  subheader1: PropTypes.string.isRequired,
  subheader2: PropTypes.string,
}
