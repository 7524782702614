import * as React from 'react'
import LockIcon from '@mui/icons-material/Lock'
import { Button } from '@mui/material'

import PropTypes from 'prop-types'

import s from './titlePopUp.module.scss'

export default function TitlePopUp({ textTitle, handleClose, checkBlockedUser = false, hideCloseButton = false }) {
  return (
    <div className={s.divContainer}>
      <div className={s.itemContainer}>
        {checkBlockedUser && <LockIcon className={s.lockIcon} />}
        <div className={s.title}>{textTitle}</div>
      </div>

      {!hideCloseButton && (
        <Button onClick={handleClose} className={s.closeButton}>
          X
        </Button>
      )}
    </div>
  )
}

TitlePopUp.propTypes = {
  textTitle: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  checkBlockedUser: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
}
