export const removeWrongData = (doctorCentre) => {
  if (!doctorCentre) return {}
  // to force deep copy
  let auxDoctorCentre = JSON.parse(JSON.stringify(doctorCentre))

  // remove invalid workTimes
  if (auxDoctorCentre?.workTimes) {
    auxDoctorCentre.workTimes = auxDoctorCentre.workTimes.filter((wt) => {
      const startDay = wt?.frequency?.startDay?.toLowerCase()
      return startDay !== 'invalid date'
    })
  }

  // sort noWorkTimes
  if (auxDoctorCentre?.noWorkTimes) {
    auxDoctorCentre.noWorkTimes = auxDoctorCentre.noWorkTimes.sort((a, b) => {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
    })
  }

  return auxDoctorCentre
}
