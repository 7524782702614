import React from 'react'
import { Link } from 'react-router-dom'
import CaluFormInput from '@/components/formComponents/customFields/CaluFormInput'
import NavBar from '@/components/NavBar'
import CaluButton from '@/components/styledComponents/CaluButton'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false, sentEmail }) => {
  return (
    <>
      <NavBar />
      <div className={s.cardContainer}>
        <div className={s.loginTitleContainer}>
          <div className={s.loginTitle}>Recuperar contraseña</div>
        </div>

        <div className={s.formContainer}>
          <div className={s.inputWidth}>
            <CaluFormInput
              labelText="Email"
              type="text"
              name="email"
              className={s.inputWidth}
              autoFocus
              enterToSubmit
            />
          </div>

          <CaluButton color="primary" type="submit" size="large" loading={isLoading}>
            Ingresar
          </CaluButton>

          <div className={s.registerDiv}>
            <div>
              <Link to={'/ingresar'} className={s.tagA}>
                Ya recordé mi contraseña, quiero iniciar sesión{' '}
              </Link>
            </div>
            <div>
              ¿No tienes cuenta?{' '}
              <Link to={'/registro'} className={s.tagA}>
                Registrate Gratis
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
