import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'products',
  initialState: {
    allProducts: [],
    paginationData: {
      page: 1,
      limit: 30,
    },
    selectedProduct: null,
    error: false,
    isLoaded: false,
  },
  reducers: {
    PRODUCT_CREATED: (state, { payload }) => {
      state.allProducts = [...(state.allProducts || []), payload]
    },
    FETCH_ALL_PRODUCTS: (state, { payload }) => {
      state.allProducts = payload.allProducts
      state.paginationData = payload.paginationData
    },
    PRODUCT_UPDATED: (state, { payload }) => {
      state.allProducts = state.allProducts.map((plan) => {
        if (plan._id === payload._id) {
          plan = payload
        }
        return plan
      })
    },
    PRODUCT_DELETED: (state, { payload }) => {
      state.allProducts = state.allProducts.filter((plan) => plan._id !== payload)
      state.selectedProduct = null
    },

    PRODUCT_SELECTED: (state, { payload }) => {
      state.selectedProduct = payload
    },
    RESET_PRODUCT_SELECTED: (state) => {
      state.selectedProduct = null
    },
    LOADED_PRODUCTS: (state, { payload }) => {
      state.isLoaded = payload
    },
  },
})

export const {
  PRODUCT_CREATED,
  FETCH_ALL_PRODUCTS,
  PRODUCT_UPDATED,
  PRODUCT_DELETED,
  PRODUCT_SELECTED,
  RESET_PRODUCT_SELECTED,
  LOADED_PRODUCTS,
} = slice.actions
export default slice.reducer
