// example:
// const obj1 = { a: 123, b: 123 }
// const obj2 = { b: 321, c: 321 }
// --> { a: 123, b: 321 }

const updateObjectWithCommonKeys = (obj1, obj2) => {
  const updatedObject = { ...obj1 }

  Object.keys(obj1).forEach((key) => {
    if (obj2?.hasOwnProperty(key)) {
      updatedObject[key] = obj2[key]
    }
  })
  return updatedObject
}

export default updateObjectWithCommonKeys
