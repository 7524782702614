// prettier-ignore
import { handleSetCountryCode } from '@/utils/functions/handleSetCountryCode'
import { sendWhatsappToRemindTurn } from '@/utils/functions/whatsappMessages'

export const adapterTurnsReminderToExcel = (turns, centre) => {
  return turns.map((turn) => {
    let message = sendWhatsappToRemindTurn(turn, centre)
    let countryCode = turn?.patientCentre?.country || centre?.country || 'ARG'

    return {
      Número: handleSetCountryCode(countryCode) + turn?.patientCentre?.mobile,
      Recordatorio: message,
    }
  })
}
