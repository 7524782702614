import {
  addNewDoctorCentres,
  deleteDoctorCentres,
  fetchDoctorCentre,
  fetchDoctorCentres,
  fetchDoctorCentresByInputSearch,
  putDoctorCentres,
} from '@/services/doctorCentres'
import {
  DOCTOR_CENTRE_CREATED,
  DOCTOR_CENTRE_DELETE,
  DOCTOR_CENTRE_SELECTED,
  DOCTOR_CENTRE_UPDATED,
  DOCTOR_CENTRES_PAGINATION,
  DOCTOR_CENTRES_UPDATED,
  RESET_DOCTOR_CENTRE_SELECTED,
  RESET_DOCTOR_CENTRE_STATE,
  TOTAL_DOCTOR_CENTRES,
} from '@/store/doctorCentres'
import { MACHINE_CREATED, MACHINE_DELETE, MACHINE_SELECTED, MACHINE_UPDATED } from '@/store/machines'
import { DOCTOR_PROFILE_SELECTED } from '@/store/myProfile'
import {
  RESET_SECRETARY_CENTRE_SELECTED,
  SECRETARY_CENTRE_CREATED,
  SECRETARY_CENTRE_DELETE,
  SECRETARY_CENTRE_UPDATED,
} from '@/store/secretaryCentre'
import { isDoctorCentreValidAgenda } from '@/utils/functions/isDoctorCentreValidAgenda'
import { showToast } from '@/utils/toast'

const getDoctorCentres = (centre) => async (dispatch) => {
  try {
    let doctorCentres = await fetchDoctorCentres(centre)
    dispatch(TOTAL_DOCTOR_CENTRES(doctorCentres))

    doctorCentres = doctorCentres.filter((doctor) => isDoctorCentreValidAgenda(doctor))

    dispatch(DOCTOR_CENTRES_UPDATED(doctorCentres))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const getDoctorCentresByInputSearch = (params) => async (dispatch) => {
  const auxParams = {
    ...params,
    paginate: true,
  }
  try {
    let { docs: doctorCentres, ...paginationData } = await fetchDoctorCentresByInputSearch(auxParams)
    dispatch(DOCTOR_CENTRES_PAGINATION({ doctorCentres, paginationData }))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const getDoctorCentre = (id) => async (dispatch) => {
  try {
    const doctorCentre = await fetchDoctorCentre(id)
    dispatch(DOCTOR_PROFILE_SELECTED(doctorCentre))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const postDoctorCentres =
  (data, isResendingInvitation, hideAlert = false) =>
  async (dispatch) => {
    const insurances = data.insurances
    const services = data.services
    const specialities = data.specialities
    try {
      let newDoctorCentres = await addNewDoctorCentres(data)
      newDoctorCentres.insurances = insurances
      newDoctorCentres.services = services
      newDoctorCentres.specialities = specialities

      if (isResendingInvitation) {
        showToast('Invitación reenviada', 'success', undefined)
      } else if (!hideAlert) {
        showToast('Profesional agregado correctamente', 'success', undefined)
      }

      return newDoctorCentres
    } catch (error) {
      console.log(error)
    }
  }

const deleteDoctorAdded = (id) => async () => {
  try {
    await deleteDoctorCentres(id)
    showToast('Profesional eliminado correctamente', 'success', undefined)
    return id
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateDoctorCentres = (data, extraData) => async (dispatch) => {
  const insurances = data.insurances
  const services = data.services
  const specialities = data.specialities
  try {
    let doctorUpdated = await putDoctorCentres(data)
    doctorUpdated.insurances = insurances
    doctorUpdated.services = services
    doctorUpdated.specialities = specialities

    if (extraData?.addInMyProfile) {
      dispatch(DOCTOR_PROFILE_SELECTED(doctorUpdated))
    } else {
      dispatch(DOCTOR_CENTRE_UPDATED(doctorUpdated))
    }

    showToast('Profesional actualizado', 'success', 2000)
    return doctorUpdated
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const setCentreEntity = (entitySelectedID) => async (dispatch, getState) => {
  try {
    const state = getState()
    const doctorCentres = state.doctorCentres.doctorCentres
    const machines = state.machines.allMachines

    doctorCentres.forEach((doctorCentre) => {
      if (entitySelectedID === doctorCentre._id) {
        dispatch(RESET_SECRETARY_CENTRE_SELECTED())
        dispatch(DOCTOR_CENTRE_SELECTED(doctorCentre))
      }
    })
    machines.forEach((machine) => {
      if (entitySelectedID === machine._id) {
        dispatch(MACHINE_SELECTED(machine))
      }
    })
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedDoctorCentre = () => async (dispatch) => {
  try {
    dispatch(RESET_DOCTOR_CENTRE_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const setSyncUpdateEntity = (updatedEntity) => (dispatch) => {
  try {
    if (updatedEntity.firstname) {
      if (updatedEntity.hasOwnProperty('doctorNumber')) {
        dispatch(DOCTOR_CENTRE_UPDATED(updatedEntity))
      } else {
        dispatch(SECRETARY_CENTRE_UPDATED(updatedEntity))
      }
    } else {
      dispatch(MACHINE_UPDATED(updatedEntity))
    }
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const setSyncNewEntity = (createdEntity) => (dispatch) => {
  try {
    if (createdEntity?.firstname) {
      if (createdEntity.hasOwnProperty('doctorNumber')) {
        dispatch(DOCTOR_CENTRE_CREATED(createdEntity))
      } else {
        dispatch(SECRETARY_CENTRE_CREATED(createdEntity))
      }
    } else {
      dispatch(MACHINE_CREATED(createdEntity))
    }
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const setSyncDeleteEntity = (entity) => (dispatch) => {
  try {
    if (entity.firstname) {
      if (entity.hasOwnProperty('doctorNumber')) {
        dispatch(DOCTOR_CENTRE_DELETE(entity._id))
      } else {
        dispatch(SECRETARY_CENTRE_DELETE(entity._id))
      }
    } else {
      dispatch(MACHINE_DELETE(entity._id))
    }
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 3000)
  }
}

const resetDoctorCentreState = () => (dispatch) => {
  dispatch(RESET_DOCTOR_CENTRE_STATE())
}

export {
  deleteDoctorAdded,
  getDoctorCentre,
  getDoctorCentres,
  getDoctorCentresByInputSearch,
  postDoctorCentres,
  resetDoctorCentreState,
  resetSelectedDoctorCentre,
  setCentreEntity,
  setSyncDeleteEntity,
  setSyncNewEntity,
  setSyncUpdateEntity,
  updateDoctorCentres,
}
