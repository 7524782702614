import React, { useEffect, useState } from 'react'
import { Controller, useController } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import LinealProgress from '@/components/LinealProgress'
import { getSearchedCities, resetSearchedCities } from '@/store/cities/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { Autocomplete, TextField } from '@mui/material'

import PropTypes from 'prop-types'

export const AutoCompleteCityField = ({ label, name }) => {
  const dispatch = useDispatch()
  const cities = useSelector((state) => state?.cities?.searchedCities)
  const [loadingCities, setLoadingCities] = useState(false)
  const [options, setOptions] = useState([])
  const [searchCity, setSearchCity] = useState('')

  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error },
  } = useController({ name })

  useEffect(() => {
    if (searchCity.length >= 5) {
      setLoadingCities(true)
      setTimeout(() => dispatch(getSearchedCities(searchCity)), 800)
    } else {
      setLoadingCities(false)
      dispatch(resetSearchedCities())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCity])

  useEffect(() => {
    if (cities.length > 0) {
      setOptions(cities)
      setLoadingCities(false)
    } else {
      setLoadingCities(false)
    }
  }, [cities])

  return (
    <Controller
      name={name}
      defaultValue={''}
      render={() => (
        <>
          <Autocomplete
            getOptionLabel={(option) => capitalizeFirstLetters(option)}
            isOptionEqualToValue={(option, value) => option === value}
            freeSolo
            onBlur={onBlur}
            onChange={(event, newValue) => onChange(newValue)}
            value={value}
            options={options}
            ref={ref}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option}>
                {capitalizeFirstLetters(option)}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                onChange={(e) => setSearchCity(e.target.value)}
                value={value}
                {...params}
                error={Boolean(error)}
                label={label}
                variant="standard"
              />
            )}
          />

          {loadingCities && <LinealProgress />}
        </>
      )}
    />
  )
}

AutoCompleteCityField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
}
