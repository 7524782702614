import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchSpecialities = async () => {
  const route = `api/specialities`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewSpeciality = async (data) => {
  const route = `api/specialities`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteSpeciality = async (id) => {
  const route = `api/specialities/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putSpeciality = async (data) => {
  const route = `api/specialities`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchSingleSpeciality = async (id) => {
  const route = `api/specialities/${id}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { addNewSpeciality, deleteSpeciality, fetchSingleSpeciality, fetchSpecialities, putSpeciality }
