import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { updateDoctorCentres } from '@/store/doctorCentres/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const FormEditDoctorCentre = ({ formValues, onClose }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (data) => {
    setIsLoading(true)
    let doctorSelected = { ...formValues?.raw }

    doctorSelected.price = Number(data.price) || 0
    doctorSelected.priceFirstTime = Number(data.priceFirstTime) || 0

    try {
      const doctorUpdated = dispatch(updateDoctorCentres(doctorSelected))
      if (doctorUpdated) onClose()
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={{ ...formDefaultValues, ...formValues }}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent isLoading={isLoading} formValues={formValues} />
      </ContainerForm>
    </div>
  )
}

export default FormEditDoctorCentre
