import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import TitlePopUp from '@/components/TitlePopUp'
import { useSocket } from '@/context/SocketProvider'
import { useUser } from '@/context/UserProvider'
import { updateATurn } from '@/store/turns/actions'
import { formatDate } from '@/utils/functions/formatDate'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'

import s from './styles.module.scss'

const PopUpConfirmNoWorkTime = ({
  openPopUp,
  setOpenPopUp,
  dataToUpdate,
  dataStartDate,
  dataFinishDate,
  updateFunction,
  filterActiveTurns,
  allDay,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const { socket } = useSocket()
  const { defaultCentre } = useUser()

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    setOpenPopUp(false)
  }

  const updateArrayTurns = async (turns) => {
    for (let i = 0; i < turns.length; i++) {
      let auxTurnData = {
        ...turns[i],
        state: 'cancelled',
        cancelledInfo: 'doctor',
      }

      try {
        const turnUpdated = await dispatch(updateATurn(auxTurnData, true))
        socket.emit('calendar_edit_turn', `${defaultCentre}_calendar`, turnUpdated)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const confirmAddNoWorkTime = async () => {
    setIsLoading(true)

    try {
      if (filterActiveTurns?.length) {
        updateArrayTurns(filterActiveTurns)
      }
      const updatedEntity = await dispatch(updateFunction(dataToUpdate))
      socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedEntity)
      handleClose()
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={openPopUp} onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle="Agregar bloqueo de calendario" handleClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <div className={s.containerPopUp}>
            {allDay ? (
              <p>
                Estás bloqueando el calendario desde el <b>{formatDate(dataStartDate)}</b> hasta el{' '}
                <b>{formatDate(dataFinishDate)}</b> inclusive.
              </p>
            ) : (
              <p>
                Estás bloqueando el calendario el día <b>{formatDate(dataStartDate)}</b> de{' '}
                <b>{toCustomTz(dataStartDate, undefined, false, 'HH:mm')}</b> hs. a{' '}
                <b>{toCustomTz(dataFinishDate, undefined, false, 'HH:mm')}</b> hs.
              </p>
            )}

            {filterActiveTurns?.length === 1 && (
              <p>
                Se cancelará {filterActiveTurns?.length} {t('turnLabel.s')} que está dentro del intervalo del horario no
                laboral que está a punto de agregar
              </p>
            )}
            {filterActiveTurns?.length > 1 && (
              <p>
                Se cancelarán {filterActiveTurns?.length} {t('turnLabel.p')} que están dentro del intervalo del horario
                no laboral que está a punto de agregar
              </p>
            )}
            <div className={s.containerButtons}>
              <div>
                <CaluButton inverted="true" size="medium" onClick={() => handleClose()}>
                  Cancelar
                </CaluButton>
              </div>

              <div>
                <CaluButton color="primary" size="medium" onClick={confirmAddNoWorkTime} loading={isLoading}>
                  Confirmar
                </CaluButton>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpConfirmNoWorkTime
