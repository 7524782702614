import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { Provider as ReduxProvider } from 'react-redux'
import App from '@/App'
import i18next from '@/configs/i18next'
import reportWebVitals from '@/reportWebVitals'
import * as serviceWorker from '@/serviceWorker'
import store from '@/store'

import 'animate.css'
import '@/assets/styles/index.scss'

const root = createRoot(document.getElementById('root'))

root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </ReduxProvider>
  </StrictMode>,
)

serviceWorker.unregister()

reportWebVitals()
