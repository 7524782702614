import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PageHearedTitle } from '@/components/pages'
import BaseSideBar from '@/layouts/Layout1/components/BaseSideBar'
import ContentSidebar from '@/pages/AdminPanelSpecialities/components/contentSideBar'
import { deleteSpecialityAdded, resetSelectedSpeciality } from '@/store/specialities/actions'
import { getListSpecialities } from '@/store/specialities/actions'
import { Paper } from '@mui/material'

import CardInfo from './components/cardInfo'
import PopUpAdminSpecialities from './components/popUpAdminSpecialities'
import Layout1 from '../../layouts/Layout1/index'

import s from './styles.module.scss'

const AdminPanelSpecialities = () => {
  const dispatch = useDispatch()

  const [openSpecialitiesPopUp, setOpenSpecialitiesPopUp] = useState(false)
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [openAlertDelete, setOpenAlertDelete] = useState(false)

  const [specialitiesFiltered, setSpecialitiesFiltered] = useState([])

  const { specialitiesList, specialitySelected } = useSelector((state) => state.speciality)

  const setInit = async (params) => {
    setIsLoading(true)
    await dispatch(getListSpecialities())

    setIsLoading(false)
  }

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filteredSpecialities = specialitiesList?.filter((speciality) =>
      speciality.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .includes(
          input
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
        ),
    )
    setSpecialitiesFiltered(filteredSpecialities)
  }, [specialitiesList, input])

  const addNewSpeciality = () => {
    dispatch(resetSelectedSpeciality())
    setOpenSpecialitiesPopUp(!openSpecialitiesPopUp)
  }

  const modifySpeciality = () => {
    setOpenSpecialitiesPopUp(!openSpecialitiesPopUp)
  }

  const deleteSpeciality = () => {
    dispatch(deleteSpecialityAdded(specialitySelected?._id))
    setOpenAlertDelete(false)
  }

  const handleChange = (e) => {
    setInput(e.target.value)
  }

  return (
    <Layout1
      navbar
      isLoading={isLoading}
      leftSidebar={
        <BaseSideBar
          inputLabel={'Buscar especialidades'}
          inputValue={input}
          inputOnChange={handleChange}
          buttonLabel={'Nueva especialidad'}
          buttonOnClick={addNewSpeciality}
          content={<ContentSidebar listItem={specialitiesFiltered} />}
          panelAdmin={true}
        />
      }
    >
      <div className={s.mainContainer}>
        <div className={s.contentContainer}>
          <PageHearedTitle titleText="Especialidades" />
          {specialitySelected !== null ? (
            <CardInfo
              modifyItem={modifySpeciality}
              openAlertDelete={openAlertDelete}
              setOpenAlertDelete={setOpenAlertDelete}
              deleteItem={deleteSpeciality}
            />
          ) : (
            <Paper elevation={3} className={`${s.cardContainer} animate__animated animate__fadeIn`}>
              <p>Seleccione una especialidad del listado para ver su información</p>
            </Paper>
          )}
        </div>
      </div>

      {openSpecialitiesPopUp && (
        <PopUpAdminSpecialities open={openSpecialitiesPopUp} setOpen={setOpenSpecialitiesPopUp} />
      )}
    </Layout1>
  )
}

export default AdminPanelSpecialities
