import React from 'react'
import { useNavigate } from 'react-router'
import CaluButton from '@/components/styledComponents/CaluButton'

import s from './styles.module.scss'

const NoMercadoPagoConnectionAlert = () => {
  const navigate = useNavigate()

  return (
    <div className={s.containerMPAlert}>
      <div className={s.mainItemMPAlert}>
        <p>Recordá que debes suscribirte a Mercado Pago a través de</p>
        <div className={s.contButton}>
          <CaluButton color="error" size="medium" onClick={() => navigate('/pagos')}>
            Mis pagos
          </CaluButton>
        </div>
      </div>
    </div>
  )
}

export default NoMercadoPagoConnectionAlert
