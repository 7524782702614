import React from 'react'
import { IoIosCheckmark, IoIosClose } from 'react-icons/io'
import { version } from '@/helpers/env'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const StatusBlock = ({ apiResponse }) => {
  return (
    <div className={s.statusBlock}>
      <div className={`${s.statusDiv} ${!apiResponse && s.error} `}>
        {apiResponse ? <IoIosCheckmark className={s.icon} /> : <IoIosClose className={s.icon} />}
        <p>{apiResponse ? 'Todos los sistemas están operacionales' : 'Volvemos pronto'}</p>
      </div>

      {!apiResponse && (
        <div className={s.errorDiv}>
          <p>
            Estamos experimentando una caida temporal del sistema.
            <br />
            Estamos trabajando para solucionarlo a la brevedad. Agradecemos su paciencia y les pedimos disculpas por las
            molestias ocasionadas.
          </p>
        </div>
      )}

      <ul className={s.listItems}>
        <li>
          <b>Versión v{version}</b>
        </li>

        <hr />
        {apiResponse && (
          <li>
            Hora actual: <b>{dayjs(apiResponse?.date).format('LLLL')}</b>
          </li>
        )}
        <li>Servidor principal: {apiResponse ? <span>Activo</span> : <span className={s.error}>Inactivo</span>}</li>
        <li>Base de datos: {apiResponse ? <span>Activo</span> : <span className={s.error}>Inactivo</span>}</li>

        <hr />
        <li>Envío de emails: {apiResponse ? <span>Activo</span> : <span className={s.error}>Inactivo</span>}</li>
        <li>Envío de SMS: {apiResponse ? <span>Activo</span> : <span className={s.error}>Inactivo</span>}</li>
      </ul>
    </div>
  )
}

export default StatusBlock
