import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchFieldsHC = async () => {
  const route = `api/clinicHistoryFields`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewFieldHC = async (data) => {
  const route = `api/clinicHistoryFields`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putFieldHC = async (data) => {
  const route = `api/clinicHistoryFields`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteFieldHC = async (id) => {
  const route = `api/clinicHistoryFields/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { addNewFieldHC, deleteFieldHC, fetchFieldsHC, putFieldHC }
