import React from 'react'
import { useWatch } from 'react-hook-form'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { SelectField } from '@/components/Form/customFields/SelectField'
import { Button, Grid, MenuItem } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false, onClose }) => {
  const watchName = useWatch({ name: 'name' })

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <Grid container spacing={2} className={s.containerForm}>
        <Grid item xs={12}>
          <CustomField label="Campo de historia clínica *" name="name" fullWidth />
        </Grid>

        <Grid item xs={12}>
          <SelectField label="Tipo de campo" name="type" type="text" fullWidth>
            <MenuItem value={'text'}>Texto</MenuItem>
            <MenuItem value={'textarea'}>Texto multilínea</MenuItem>
            <MenuItem value={'number'}>Numérico</MenuItem>
            <MenuItem value={'date'}>Fecha</MenuItem>
            <MenuItem value={'boolean'}>Si/No</MenuItem>
          </SelectField>
        </Grid>

        <Grid item xs={12} className={s.containerButtonsForm}>
          <Button disableElevation disabled={!watchName} variant="contained" className={s.buttonSave} type="submit">
            Confirmar
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
