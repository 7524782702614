import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCopy } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import logo from '@/assets/images/turn.png'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import ButtonsPagination from '@/components/ButtonsPaginations'
import LoaderCircle from '@/components/LoaderCircle'
import { baseURL } from '@/helpers/env'
import useDebounce from '@/hooks/useDebounce'
import { clearPatientCentres, getPatientsCentreByInputSearch } from '@/store/patientCentres/actions'
import { deleteTurnWithoutDate, getTurnsWithoutDate } from '@/store/turns/actions'
import { sendWappMessage } from '@/store/wapp/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { handleSetCountryCode } from '@/utils/functions/handleSetCountryCode'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { sendWhatsappSetTurnHour } from '@/utils/functions/whatsappMessages'
import { showToast } from '@/utils/toast'
import { Autocomplete, Button, CircularProgress, TextField } from '@mui/material'

import s from './styles.module.scss'

const TurnsWithoutDate = ({ entity }) => {
  const dispatch = useDispatch()
  const DELAY_TIME = 300
  const { t } = useTranslation('global')

  const [searchText, setSearchText] = useState(null)
  const [loadingPatients, setLoadingPatients] = useState(false)
  const [patientValue, setPatientValue] = useState(null)
  const [openDeleteTurn, setOpenDeleteTurn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedTurn, setSelectedTurn] = useState('')

  const { turnsWithoutDate, paginationDataTurnsWithoutDate } = useSelector((state) => state.turns)
  const { centreProfile } = useSelector((state) => state.centres)
  const { patientCentres } = useSelector((state) => state.patientCentres)
  const { status: wappStatus } = useSelector((state) => state.wapp)

  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)

  useEffect(() => {
    const searchPatient = async () => {
      setPatientValue(null)
      if (inputDebounceValue && inputDebounceValue.length >= 2) {
        const params = {
          centre: entity?.centre,
          search: inputDebounceValue,
        }
        setLoadingPatients(true)
        await dispatch(getPatientsCentreByInputSearch(params))
        setLoadingPatients(false)
      } else {
        dispatch(clearPatientCentres())
      }
    }
    searchPatient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDebounceValue])

  const getTurns = async () => {
    setLoading(true)
    let params = {
      centre: entity?.centre,
      doctorCentre: entity?.firstname ? entity?._id : null,
      machine: entity?.name ? entity?._id : null,
      patientCentre: !patientValue ? null : patientValue?._id,
    }

    await dispatch(getTurnsWithoutDate(params))
    setLoading(false)
  }

  useEffect(() => {
    if (entity) {
      getTurns()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, patientValue])

  const valueOfPatient = (e, patient) => {
    setPatientValue(patient)
  }

  const handleDeleteTurn = (turn) => {
    setSelectedTurn(turn?._id)
    setOpenDeleteTurn(true)
  }

  const resendWhatsappLink = (turn) => {
    const { patientCentre } = turn
    const countryCode = handleSetCountryCode(
      patientCentre?.country || centreProfile?.country || import.meta.env.REACT_APP_COUNTRY,
    )
    if (wappStatus?.status === 'connected') {
      const isWappConnected = true
      const text = sendWhatsappSetTurnHour(patientCentre, centreProfile, turn, isWappConnected)
      const auxMessageData = {
        text: text,
        number: `${countryCode}${patientCentre.mobile}`,
      }
      if (auxMessageData?.number) {
        const showAlert = true
        dispatch(sendWappMessage(entity?.centre, auxMessageData, showAlert))
      }
      return
    }
    sendWhatsappSetTurnHour(patientCentre, centreProfile, turn)
  }

  const handleChangePaginationNumber = async (page) => {
    setLoading(true)
    let params = {
      centre: entity?.centre,
      doctorCentre: entity?.firstname ? entity?._id : null,
      machine: entity?.name ? entity?._id : null,
      patientCentre: !patientValue ? null : patientValue?._id,
      page,
    }
    await dispatch(getTurnsWithoutDate(params))
    setLoading(false)
  }

  const handleCopyToClipboard = (turn) => {
    navigator.clipboard.writeText(`${baseURL}turnos/definir-horario/${turn?._id}`)
    showToast('Enlace copiado', 'success', 2000)
  }

  return (
    <div className={s.containerContent}>
      <div className={s.containerAutocomplete}>
        <Autocomplete
          fullWidth
          freeSolo
          loading={loadingPatients}
          loadingText="Cargando..."
          disablePortal
          id="combo-box-demo"
          disableClearable
          noOptionsText="No se encontraron resultados"
          onChange={valueOfPatient}
          options={patientCentres?.map((option) => option)}
          getOptionLabel={(option) =>
            option?.dni?.length < 15
              ? `${option?.lastname?.toUpperCase()}, ${option?.firstname?.toUpperCase()} (DNI: ${option?.dni})`
              : `${option?.lastname?.toUpperCase()}, ${option?.firstname?.toUpperCase()}`
          }
          isOptionEqualToValue={(option) => option._id}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label={`Buscar ${t(`subCategoryLabel.${getCentreSubCategory(centreProfile?.category)}.patient`)}`}
              InputLabelProps={{ style: { fontSize: 14 } }}
              InputProps={{
                ...params.InputProps,
                endAdornment: loadingPatients ? <CircularProgress className={s.circularProgress} /> : null,
                type: 'search',
              }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          )}
        />
      </div>

      {loading ? (
        <LoaderCircle />
      ) : turnsWithoutDate?.length === 0 ? (
        <p className={s.infoSearch}>No hay {t('turnLabel.p')} sin fecha.</p>
      ) : (
        <div className={s.containerTurnsWithoutDate}>
          <div className={s.divider}></div>
          {turnsWithoutDate.map((turn) => (
            <div key={turn?._id}>
              <div className={s.item}>
                <div className={s.itemDetail}>
                  <div>
                    <img src={logo} alt="turn" />
                  </div>
                  <div className={s.data}>
                    <div className={s.doctor}>
                      {`${turn?.patientCentre?.lastname?.toUpperCase()}, ${turn?.patientCentre?.firstname?.toUpperCase()} `}
                    </div>
                    <small className={s.date}>
                      <span>
                        {turn?.service?.name?.toUpperCase() || 'CONSULTA'} --- Fecha de creación:{' '}
                        <span className={s.bold}>
                          {toCustomTz(turn?.creationDate, undefined, false, 'dddd DD/MM/YYYY HH:mm')} hs
                        </span>
                      </span>
                    </small>
                    <small className={s.copyLink} onClick={() => handleCopyToClipboard(turn)}>
                      Copiar enlace para definir horario <FaCopy />
                    </small>
                  </div>
                </div>

                <div className={s.containerButtons}>
                  <Button size="small" className={`chedoc-c-bg ${s.margin}`} onClick={() => resendWhatsappLink(turn)}>
                    Reenviar link
                  </Button>
                  <Button size="small" className={s.margin && 'chedoc-e-bg '} onClick={() => handleDeleteTurn(turn)}>
                    Eliminar
                  </Button>
                </div>
              </div>

              <AlertDialogSlide
                open={openDeleteTurn}
                setOpen={setOpenDeleteTurn}
                title="Eliminar turno"
                textContent={'¿Realmente quiere eliminar el turno? Esta acción no se podrá restaurar.'}
                textBackButton="Volver"
                textConfirmationButton={'Eliminar'}
                onClose={() => dispatch(deleteTurnWithoutDate(selectedTurn))}
              />
            </div>
          ))}

          <ButtonsPagination
            paginationData={paginationDataTurnsWithoutDate}
            handleChangePaginationNumber={handleChangePaginationNumber}
          />
        </div>
      )}
    </div>
  )
}

export default TurnsWithoutDate
