import {
  deleteWappMessageTemplate,
  fetchWappMessageTemplates,
  postWappMessageTemplate,
  putWappMessageTemplate,
} from '@/services/wappMessageTemplates'
import {
  CREATE_WAPP_MESSAGE_TEMPLATE,
  DELETE_TEMPLATE,
  GET_WAPP_MESSAGE_TEMPLATES,
  IS_LOADING,
  UPDATE_WAPP_MESSAGE_TEMPLATE,
} from '@/store/wappMessageTemplates'
import { showToast } from '@/utils/toast'

const getWappMessageTemplates = (params) => async (dispatch) => {
  try {
    dispatch(IS_LOADING(true))
    let { docs: allTemplates, ...paginationData } = await fetchWappMessageTemplates(params)
    dispatch(GET_WAPP_MESSAGE_TEMPLATES({ allTemplates, paginationData }))
    dispatch(IS_LOADING(false))
  } catch (e) {
    console.log(e)
  }
}

const createWappMessageTemplate = (params) => async (dispatch) => {
  try {
    const createdWappTemplate = await postWappMessageTemplate(params)
    dispatch(CREATE_WAPP_MESSAGE_TEMPLATE(createdWappTemplate))
    showToast('Mensaje personalizado agregado correctamente', 'success')
  } catch (e) {
    console.log(e)
    return e
  }
}

const updateWappMessageTemplate = (params) => async (dispatch) => {
  try {
    dispatch(IS_LOADING(true))
    let updatedWappTemplate = await putWappMessageTemplate(params)
    dispatch(UPDATE_WAPP_MESSAGE_TEMPLATE(updatedWappTemplate))
    dispatch(IS_LOADING(false))
    showToast('Mensaje personalizado actualizado correctamente', 'success')
  } catch (e) {
    console.log(e)
  }
}

const deleteWappTemplate = (id) => async (dispatch) => {
  try {
    await deleteWappMessageTemplate(id)
    dispatch(DELETE_TEMPLATE(id))
    showToast('Mensaje personalizado eliminado correctamente', 'success')
  } catch (e) {
    console.log(e)
    return e
  }
}

export { createWappMessageTemplate, deleteWappTemplate, getWappMessageTemplates, updateWappMessageTemplate }
