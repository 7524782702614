import { toLocalTz } from '@/utils/functions/toLocalTz/index.js'

import dayjs from 'dayjs'
import { getUniqueListBy } from '../getUniqueListBy'
import { sortObjectsByNameWithoutAccents } from '../sortObjectsByNameWithoutAccents'
import { toCustomTz } from '../toCustomTz'

export const getEntityServicesByDate = (selectedTimeDateTurn, entity) => {
  //date: format 2023-02-07
  const weekDay = dayjs(selectedTimeDateTurn).day()
  let defaultServices = []
  const filterWorkTimeByWeekDay = entity?.workTimes
    ?.filter((e) => {
      if (
        e?.weekDay?.code === 8 &&
        dayjs(toLocalTz(selectedTimeDateTurn)).format('YYYY-MM-DD') ===
          dayjs(toLocalTz(e?.frequency?.startDay)).format('YYYY-MM-DD')
      ) {
        return true
      }
      return e?.weekDay?.code === weekDay
    })
    ?.map((e) => {
      if (e.consultation) {
        defaultServices.push({ _id: 'consultation', name: 'Consulta', price: entity?.price })
      }
      if (e.consultationFirstTime) {
        defaultServices.push({ _id: 'firstConsultation', name: 'Primera consulta', price: entity?.priceFirstTime })
      }

      return e
    })
    ?.flat()

  const filterWorkTimeByTimeRange = filterWorkTimeByWeekDay?.filter((obj) => {
    const startTime = toCustomTz(obj?.startTime, undefined, false, 'HH:mm')
    const finishTime = toCustomTz(obj?.finishTime, undefined, false, 'HH:mm')
    const selectedTime = dayjs(selectedTimeDateTurn).utc().format('HH:mm')
    return selectedTime >= startTime && selectedTime <= finishTime
  })

  const availableServices = filterWorkTimeByTimeRange?.map((e) => e.services)?.flat() || []
  const sortAvailableDataServices = sortObjectsByNameWithoutAccents(availableServices) || []
  const allServices = [...defaultServices, ...sortAvailableDataServices]
  const filteredRepeatedServices = getUniqueListBy(allServices, '_id')
  return filteredRepeatedServices
}

export const getMachineDoctorsByDate = (selectedTimeDateTurn, machine) => {
  //date: format 2023-02-07
  const weekDay = dayjs(selectedTimeDateTurn).day()
  const filterWorkTimeByWeekDay = machine?.workTimes?.filter((e) => {
    if (
      e?.weekDay?.code === 8 &&
      dayjs(toLocalTz(selectedTimeDateTurn)).format('YYYY-MM-DD') ===
        dayjs(toLocalTz(e?.frequency?.startDay)).format('YYYY-MM-DD')
    ) {
      return true
    }
    return e?.weekDay?.code === weekDay
  })

  const filterWorkTimeByTimeRange = filterWorkTimeByWeekDay?.filter((obj) => {
    const startTime = toCustomTz(obj?.startTime, undefined, false, 'HH:mm')
    const finishTime = toCustomTz(obj?.finishTime, undefined, false, 'HH:mm')
    const selectedTime = dayjs(selectedTimeDateTurn).utc().format('HH:mm')
    return selectedTime >= startTime && selectedTime <= finishTime
  })
  const availableMachineDoctors = filterWorkTimeByTimeRange?.map((e) => e.doctorCentres)?.flat() || []
  const filteredRepeatedAvailableMachineDoctors = getUniqueListBy(availableMachineDoctors, '_id')

  return filteredRepeatedAvailableMachineDoctors
}

export const getAllEntityServices = (entity) => {
  let defaultServices = []
  const auxServices = entity?.workTimes
    ?.map((w) => {
      if (w.consultation) {
        defaultServices.push({ _id: 'consultation', name: 'Consulta' })
      }
      if (w.consultationFirstTime) {
        defaultServices.push({ _id: 'firstConsultation', name: 'Primera consulta' })
      }
      return w.services
    })
    ?.flat()
  const sortAuxServices = sortObjectsByNameWithoutAccents(auxServices) || []
  const allServices = [...defaultServices, ...sortAuxServices]
  const filteredRepeatedServices = getUniqueListBy(allServices, '_id')

  return filteredRepeatedServices
}
