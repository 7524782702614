import React, { useEffect } from 'react'
import { useController, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { CheckboxField } from '@/components/Form/customFields/CheckboxField'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { SelectField } from '@/components/Form/customFields/SelectField'
import CaluButton from '@/components/styledComponents/CaluButton'
import {
  billingConditions,
  countryCodes,
  extrasAmount,
  subscriptionPaymentType,
  subscriptionStatus,
  tax,
  typeOfCategories,
} from '@/helpers/constants'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { sortArrayAlphabetically } from '@/utils/functions/sortArrayAlphabetically'
import { Grid, MenuItem } from '@mui/material'

import { handleCalculateFinalPrice } from '../../functions/handleCalculateFinalPrice'
import { handleFindPlanPrice } from '../../functions/handleFindPlanPrice'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false, suggestedPrice, setSuggestedPrice, finalPrice, setFinalPrice }) => {
  const { allSubscriptionPlans } = useSelector((state) => state?.subscriptionPlans)
  const { allPlanPrices } = useSelector((state) => state?.planPrices)

  const nameWatch = useWatch({ name: 'name' })
  const firstnameWatch = useWatch({ name: 'firstname' })
  const lastnameWatch = useWatch({ name: 'lastname' })
  const emailWatch = useWatch({ name: 'email' })
  const passwordWatch = useWatch({ name: 'password' })
  const repitePasswordWatch = useWatch({ name: 'repitePassword' })
  const subscriptionPlanWatch = useWatch({ name: 'centreConfiguration.subscriptionPlan' })
  const subscriptionAgendasQuantityWatch = useWatch({ name: 'centreConfiguration.subscriptionAgendasQuantity' })
  const countryWatch = useWatch({ name: 'country' })
  const extraAmountTypeWatch = useWatch({ name: 'centreConfiguration.subscriptionExtraAmount.type' })
  const extraAmountValueWatch = useWatch({ name: 'centreConfiguration.subscriptionExtraAmount.value' })
  const subscriptionPaymentTypeWatch = useWatch({ name: 'centreConfiguration.subscriptionPaymentType' })
  const paymentLinkWatch = useWatch({ name: 'paymentLink' })
  const taxWatch = useWatch({ name: 'centreConfiguration.tax' })

  const { field: fieldSubscriptionPaymentType } = useController({ name: 'centreConfiguration.subscriptionPaymentType' })
  const { field: fieldTax } = useController({ name: 'centreConfiguration.tax' })

  const discriminatoryTax =
    extraAmountTypeWatch === 'finalPrice'
      ? Math.round((finalPrice - extraAmountValueWatch) * 100) / 100
      : Math.round((finalPrice - suggestedPrice) * 100) / 100

  const handleShowFinalPriceDetail = () => {
    if (extraAmountTypeWatch === 'finalPrice') {
      return `($${extraAmountValueWatch} + $${discriminatoryTax} IVA)`
    }
    return `($${suggestedPrice} + $${discriminatoryTax} IVA)`
  }

  useEffect(() => {
    fieldSubscriptionPaymentType.onChange('bankTransfer')
    fieldTax.onChange('0.21')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (subscriptionPlanWatch && subscriptionAgendasQuantityWatch) {
      handleFindPlanPrice(
        allPlanPrices,
        countryWatch,
        subscriptionPlanWatch,
        subscriptionAgendasQuantityWatch,
        setSuggestedPrice,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionPlanWatch, subscriptionAgendasQuantityWatch, countryWatch])

  useEffect(() => {
    if (subscriptionPlanWatch && subscriptionAgendasQuantityWatch) {
      handleCalculateFinalPrice(suggestedPrice, extraAmountTypeWatch, extraAmountValueWatch, setFinalPrice, taxWatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraAmountTypeWatch, extraAmountValueWatch, suggestedPrice, taxWatch])

  const handleDisabledSaveButton = () => {
    if (subscriptionPaymentTypeWatch === 'paymentLink' && !paymentLinkWatch) return true

    if (
      !nameWatch ||
      !firstnameWatch ||
      !lastnameWatch ||
      !emailWatch ||
      !passwordWatch ||
      !repitePasswordWatch ||
      !subscriptionPlanWatch ||
      !subscriptionAgendasQuantityWatch ||
      !finalPrice ||
      passwordWatch !== repitePasswordWatch
    )
      return true

    return false
  }

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomField label="Nombre del centro *" name="name" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <SelectField label="Estado" name="status" type="text" fullWidth>
            {subscriptionStatus?.map((plan) => (
              <MenuItem key={plan.value} value={plan.value}>
                {`${plan.name}`}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12}>
          <SelectField label="Categoría" name="category" type="text" fullWidth>
            {typeOfCategories?.map((category) => (
              <MenuItem key={category.value} value={category.value}>
                {`${category.name}`}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12}>
          <p className={s.subTitle}>Creación cuenta administradora</p>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomField label="Nombre *" name="firstname" fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomField label="Apellido *" name="lastname" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <CustomField label="Email *" name="email" type="email" fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomField label="Contraseña *" name="password" type="password" fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomField label="Repite Contraseña *" name="repitePassword" type="password" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <SelectField label="País" name="country" type="text" fullWidth>
            {sortArrayAlphabetically(countryCodes).map((code) => (
              <MenuItem key={code.value} value={code.value}>
                {code.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        <Grid item xs={12}>
          <p className={s.subTitle}>Datos de facturación</p>
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomField label="CUIT" name="centreConfiguration.billingNumber" fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomField label="Razón social" name="centreConfiguration.billingName" fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectField
            label="Condición frente al IVA"
            name="centreConfiguration.billingCondition"
            type="text"
            fullWidth
          >
            {billingConditions?.map((condition) => (
              <MenuItem key={condition.value} value={condition.value}>
                {condition.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectField label="% IVA" name="centreConfiguration.tax" type="text" fullWidth>
            {tax?.map((percentaje) => (
              <MenuItem key={percentaje.value} value={percentaje.value}>
                {percentaje.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        <Grid item xs={12}>
          <p className={s.subTitle}>Datos de la suscripción</p>
        </Grid>

        <Grid item xs={12} md={suggestedPrice ? 4 : 6}>
          <SelectField label="Plan" name="centreConfiguration.subscriptionPlan" type="text" fullWidth>
            {allSubscriptionPlans?.map((plan) => (
              <MenuItem key={plan._id} value={plan._id}>
                {plan.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12} md={suggestedPrice ? 4 : 6}>
          <CustomField
            label="Cantidad de agendas"
            name="centreConfiguration.subscriptionAgendasQuantity"
            type="number"
            fullWidth
          />
        </Grid>

        {suggestedPrice && (
          <Grid item xs={12} md={4}>
            <div className={s.firstPrice}>
              <span>Precio de lista:</span>{' '}
              {suggestedPrice === '-' ? (
                <span className={s.suggestedPrice}>No hay valor cargado</span>
              ) : (
                <span className={s.suggestedPrice}>
                  {handleSetMoneySign(countryWatch || 'ARG')} {suggestedPrice}
                </span>
              )}
            </div>
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <SelectField
            label="Tipo de extras o descuentos"
            name="centreConfiguration.subscriptionExtraAmount.type"
            type="text"
            fullWidth
          >
            {extrasAmount?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
            <MenuItem value={'finalPrice'}>Precio final</MenuItem>
          </SelectField>
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomField
            label="Cantidad fija de extras o descuentos"
            name="centreConfiguration.subscriptionExtraAmount.value"
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomField label="Motivo" name="centreConfiguration.subscriptionExtraAmount.description" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <CustomField label="Nota adicional" name="centreConfiguration.subscriptionNote" fullWidth multiline />
        </Grid>

        <Grid item xs={12}>
          <p className={s.subTitle}>Datos del pago</p>
        </Grid>
        <Grid item xs={12}>
          <SelectField label="Medio de pago" name="centreConfiguration.subscriptionPaymentType" type="text" fullWidth>
            {subscriptionPaymentType.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.name}
              </MenuItem>
            ))}
            {countryWatch === 'ARG' && (
              <MenuItem key={'mercadoPagoSubscription'} value={'mercadoPagoSubscription'}>
                Suscripción MercadoPago
              </MenuItem>
            )}
          </SelectField>
        </Grid>

        <Grid item xs={12}>
          <CheckboxField label={`Paga anualmente`} name="centreConfiguration.isAnualSubscription" />
        </Grid>

        {subscriptionPaymentTypeWatch === 'paymentLink' && (
          <Grid item xs={12} container>
            <Grid item xs={12} className={s.contTypePaymentLink}>
              <CustomField label="Link de pago" name="paymentLink" fullWidth />
            </Grid>
            <Grid item xs={12} className={s.contTypePaymentLink}>
              <CustomField label="Descripción del pago" name="paymentDescription" fullWidth multiline />
            </Grid>
            <Grid item xs={12} className={s.contTypePaymentLink}>
              <CustomField label="Nota" name="paymentNote" fullWidth multiline />
            </Grid>
          </Grid>
        )}

        {finalPrice && (
          <Grid item xs={12}>
            <div className={s.finalPrice}>
              <span>Precio final:</span>{' '}
              <span className={s.suggestedPrice}>
                {handleSetMoneySign(countryWatch || 'ARG')} {finalPrice}{' '}
              </span>
              {countryWatch === 'ARG' && taxWatch !== '0' && <span>{handleShowFinalPriceDetail()}</span>}
            </div>
          </Grid>
        )}

        <Grid item xs={12} className={s.containerButtonsForm}>
          <CaluButton
            color="primary"
            type="submit"
            size="medium"
            loading={isLoading}
            disabled={handleDisabledSaveButton()}
          >
            Guardar
          </CaluButton>
        </Grid>
      </Grid>
    </div>
  )
}
