export const datesBetween = (startDay, finishDay) => {
  //start = 2022-12-06  finish = 2022-12-08
  let start = new Date(startDay)
  let finish = new Date(finishDay)
  let datesArray = []

  while (finish.getTime() >= start.getTime()) {
    start.setDate(start.getDate() + 1)

    let day = start.getDate()
    let month = start.getMonth() + 1
    let year = start.getFullYear()
    let date = ''

    if (month < 10) {
      date = `${year}-0${month}-${day}`
    } else {
      date = `${year}-${month}-${day}`
    }

    datesArray.push(date)
  }
  return datesArray
}

// [] = 2022-12-06, 2022-12-07, 2022-12-08
