import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useValidateURLId from '@/hooks/useValidateURLId'
import Layout1 from '@/layouts/Layout1'
import { getDoctorCentres } from '@/store/doctorCentres/actions'
import { getAllInsurancePrices } from '@/store/insurancePrices/actions'
import { getListInsurances } from '@/store/insurances/actions'
import { getAllServices } from '@/store/services/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { directionOfAnimation } from '@/utils/functions/directionOfAnimation'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import PropTypes from 'prop-types'
import DoctorCentresTab from './components/doctorCentresTab'
import InsurancesTab from './components/insurancesTab'
import ServicesTab from './components/servicesTab'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const PriceAndInsurances = () => {
  const centreID = useValidateURLId()
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const [valuePanel, setValuePanel] = useState(0)
  const [prevValuePanel, setPrevValuePanel] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  const handleChangePanel = (event, newValue) => {
    setPrevValuePanel(valuePanel)
    setValuePanel(newValue)
  }

  const init = async () => {
    const params = { centre: centreID }
    await dispatch(getDoctorCentres(centreID))
    await dispatch(getAllServices(params))
    await dispatch(getAllInsurancePrices(centreID))
    dispatch(getListInsurances())
    setIsLoading(false)
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centreID])

  return (
    <Layout1 navbar isLoading={isLoading}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={valuePanel}
            onChange={handleChangePanel}
            aria-label="Precios"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              '& .MuiTab-root.Mui-selected': {
                color: '#4C5EFE',
              },
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            <Tab label={`${capitalizeFirstLetters(t('insuranceLabel.S'))}`} />
            <Tab label="Profesionales particular" />
            <Tab label="Servicios" />
          </Tabs>
        </Box>
      </Box>

      <TabPanel value={valuePanel} index={0}>
        <InsurancesTab indexTab={0} />
      </TabPanel>
      <TabPanel value={valuePanel} index={1}>
        <DoctorCentresTab directionOfAnimation={directionOfAnimation(valuePanel, prevValuePanel)} indexTab={1} />
      </TabPanel>
      <TabPanel value={valuePanel} index={2}>
        <ServicesTab indexTab={2} />
      </TabPanel>
    </Layout1>
  )
}

export default PriceAndInsurances
