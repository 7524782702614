import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import LoaderCircle from '@/components/LoaderCircle'
import { setAllHCFragmentsController } from '@/controllers/clinicHistoryFragments'
import useDebounce from '@/hooks/useDebounce'
import useValidateURLId from '@/hooks/useValidateURLId'
import { LOADING_FRAGMENTS } from '@/store/clinicHistoryFragments'
import { getAllHCFragments, resetSelectedHCFragment } from '@/store/clinicHistoryFragments/actions'

import FragmentsSearchBar from './components/FragmentsSearchBar'
import FragmentsTable from './components/FragmentsTable'
import MainPopUp from './components/MainPopUp'

import s from './styles.module.scss'

const FragmentsClinicHistory = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const entityId = useValidateURLId()

  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isCentre, isDoctor, isAdmin, entityInLocalStorage } = usersProfileConnected
  const { doctorCentreId } = entityInLocalStorage

  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const DELAY_TIME = 300
  const MIN_CHARACTERS = 0
  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)

  const { allHCFragments, selectedFragment, isLoading } = useSelector((state) => state.clinicHistoryFragments)

  const addNewFragment = () => {
    dispatch(resetSelectedHCFragment())
    setOpen(!open)
  }

  const getFragmentBySearch = async () => {
    let params
    if (isDoctor) {
      params = {
        doctorCentre: doctorCentreId,
      }
    } else {
      params = {
        centre: entityId,
      }
    }
    params.search = inputDebounceValue

    dispatch(LOADING_FRAGMENTS(true))
    const fetchAllHCFragments = await setAllHCFragmentsController(params)
    dispatch(getAllHCFragments(fetchAllHCFragments))
    dispatch(LOADING_FRAGMENTS(false))
  }

  useEffect(() => {
    if (!isCentre && !isDoctor && !isAdmin) navigate('/')

    const inputSearch = async () => {
      if (inputDebounceValue?.length >= MIN_CHARACTERS) {
        getFragmentBySearch()
      }
    }
    inputSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDebounceValue])

  return (
    <>
      <div className={`${s.animatedContainer} animate__animated animate__fadeIn`}>
        <FragmentsSearchBar addNewFragment={addNewFragment} inputSearch={searchText} setInputSearch={setSearchText} />
        {isLoading ? (
          <LoaderCircle size={50} />
        ) : allHCFragments?.length === 0 ? (
          <p className={s.noItems}>No hay fragmentos cargados aún</p>
        ) : (
          <FragmentsTable setOpen={setOpen} searchText={searchText} />
        )}
      </div>

      <MainPopUp
        open={open}
        setOpen={setOpen}
        selectedFragment={selectedFragment}
        entityId={entityId}
        doctorCentreId={doctorCentreId}
        isDoctor={isDoctor}
      />
    </>
  )
}

export default FragmentsClinicHistory
