import { useController } from 'react-hook-form'
import { CaluRawSelect } from '@/components/styledComponents/CaluRawSelect'

export const CaluFormSelect = (props) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name: props?.name })

  return <CaluRawSelect {...props} error={Boolean(error)} onChange={onChange} onBlur={onBlur} value={value} />
}

CaluFormSelect.defaultProps = {
  value: '',
}
