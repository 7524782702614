import React from 'react'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}))

const DividerSection = ({ label, size = '', className = '' }) => {
  return (
    <Root className={`${s.divider} ${s?.[size]} ${className}`}>
      <Divider>{label && <Chip label={label} />}</Divider>
    </Root>
  )
}

export default DividerSection

DividerSection.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
}
