import React from 'react'
import Lottie from 'react-lottie-player'
import loadingCaluData from '@/assets/images/loading.json'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

const LoadingCalu = ({ loadingText }) => {
  return (
    <div className={s['container']}>
      <Lottie play loop animationData={loadingCaluData} style={{ width: 272, height: 272 }} />
      <h2>{loadingText}</h2>
    </div>
  )
}

export default LoadingCalu

LoadingCalu.propTypes = {
  loadingText: PropTypes.string,
}

LoadingCalu.defaultProps = {
  loadingText: 'Cargando...',
}
