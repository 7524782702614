import React from 'react'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import dayjs from 'dayjs'

import s from '../../styles.module.scss'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement,
  Filler,
)

const TotalTurnsChart = ({ statistics }) => {
  const { t } = useTranslation('global')

  const chartLabels = statistics?.map((elem) => {
    return dayjs(elem?.code.slice(0, -3)).format('MMM.YYYY').toUpperCase()
  })

  const chartData = statistics?.map((elem) => {
    return elem?.totalTurnsCount
  })

  return (
    <div className={s.itemChart}>
      <Line
        height={225}
        width={450}
        options={{
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: true,
              labels: {
                font: {
                  weight: '600',
                },
              },
            },
          },
        }}
        data={{
          labels: chartLabels,
          datasets: [
            {
              label: `Cantidad de ${t('turnLabel.p')} totales`,
              fill: true,
              data: chartData,
              borderColor: 'rgb(240, 185, 57)',
              backgroundColor: 'rgba(255, 205, 86, 0.2)',
              borderWidth: 3,
              tension: 0.5,
            },
          ],
        }}
      />
    </div>
  )
}

export default TotalTurnsChart
