import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PRODUCT_SELECTED } from '@/store/products'
import { handlePaginationChangeProducts } from '@/store/products/actions'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import s from '../../styles.module.scss'

const ProductsTable = ({ setOpen, inputSearch }) => {
  const dispatch = useDispatch()
  const { allProducts, isLoaded, paginationData } = useSelector((state) => state?.products)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { limit, totalDocs, page } = paginationData || {}
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const handleClick = (data) => {
    dispatch(PRODUCT_SELECTED(data))
    setOpen(true)
  }

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Nombre del producto',
      minWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'description',
      headerName: 'Descripción',
      minWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'price',
      headerName: `${handleSetMoneySign(country)} Precio`,
      minWidth: 130,
      ...stylesColumns,
    },

    {
      field: 'raw',
      headerName: '',
      minWidth: 80,
      ...stylesColumns,
      renderCell: (params) => (
        <Button onClick={() => handleClick(params.row)} className={s.rowButton} tabIndex={params.hasFocus ? 0 : -1}>
          <EditOutlinedIcon />
        </Button>
      ),
    },
  ]

  const rows = allProducts?.map((product) => {
    return {
      id: product?._id,
      _id: product?._id,
      centre: product?.centre,
      name: product?.name,
      description: product?.description,
      price: product?.price || 0,
    }
  })

  const handlePaginationChange = ({ page, pageSize }) => {
    dispatch(handlePaginationChangeProducts(page + 1, pageSize, inputSearch))
  }

  return (
    <Box
      sx={{
        px: 0,
        '& .header': {
          fontSize: '14px',
        },
        '& .blueColor': {
          color: '#1c83c6',
          fontWeight: 800,
        },
        '& .greenColor': {
          color: '#1ab395',
          fontWeight: 800,
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
      }}
    >
      <DataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          pagination: {
            paginationModel: {
              pageSize: limit,
            },
          },
        }}
        rows={rows || []}
        rowCount={totalDocs || 0}
        loading={isLoaded}
        paginationMode="server"
        columns={columns}
        pagination
        onPaginationModelChange={handlePaginationChange}
        getCellClassName={(params) => {
          if (params.row.name === 'Standard' && params.field === 'name') {
            return 'blueColor'
          }

          if (params.row.name === 'Premium' && params.field === 'name') {
            return 'greenColor'
          }
        }}
        pageSizeOptions={[30, 50, 100]}
        disableRowSelectionOnClick={true}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        slotProps={{
          pagination: {
            labelRowsPerPage: 'Entradas por página:',
            labelDisplayedRows: () =>
              `${(page - 1) * limit + 1} - ${page * limit > totalDocs ? totalDocs : page * limit} de ${totalDocs}`,
          },
        }}
        sx={{ fontSize: '13px', marginBottom: '100px' }}
        onCellClick={(params) => handleClick(params?.row)}
        rowHeight={45}
        autoHeight={true}
      />
    </Box>
  )
}

export default ProductsTable
