import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import { updateATurnDate } from '@/store/turns/actions'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const PopUpConfirmTurn = ({ open, setOpen, infoTurn, singleTurn, setShowConfirmedTurn }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const descriptionElementRef = useRef(null)

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const updatePatientTurn = async () => {
    const auxTurn = {
      ...singleTurn,
      startHour: dayjs(infoTurn.startHour).toISOString(),
      finishHour: dayjs(infoTurn.finishHour).toISOString(),
    }
    const hideAlert = true

    try {
      const updatedAuxTurn = await dispatch(updateATurnDate(auxTurn, hideAlert))
      if (updatedAuxTurn) {
        setShowConfirmedTurn(true)
        handleClose()
      }
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title" className={s.title}>
        Confirmación de dia y horario {t('turnLabel.sd')}
      </DialogTitle>
      <DialogContent className={s.dialogContent} dividers={true}>
        <div>Dia y horario seleccionado para {t('turnLabel.sl')}:</div>
        <b>{`${infoTurn.date} a las ${infoTurn.hourTurn} hs`}</b>
      </DialogContent>
      <DialogActions>
        <CaluButton color="primary" inverted="true" size="medium" onClick={handleClose}>
          Volver
        </CaluButton>
        <CaluButton color="primary" size="medium" onClick={updatePatientTurn}>
          Confirmar
        </CaluButton>
      </DialogActions>
    </Dialog>
  )
}

export default PopUpConfirmTurn
