import React, { useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BsDot } from 'react-icons/bs'
import { BsCheckLg } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import { CheckboxField } from '@/components/Form/customFields/CheckboxField'
import { SliderField } from '@/components/Form/customFields/SliderField'
import CaluFormInput from '@/components/formComponents/customFields/CaluFormInput'
import { CaluFormSelect } from '@/components/formComponents/customFields/CaluFormSelect'
import CaluButton from '@/components/styledComponents/CaluButton'
import CaluInput from '@/components/styledComponents/CaluInput'
import { serviceTypes, turnDurations } from '@/helpers/constants'
import { deleteServiceAdded } from '@/store/services/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { Button, Grid } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false, onClose, indications, setIndications }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected
  const { serviceSelected } = useSelector((state) => state.services)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const [openAlertDelete, setOpenAlertDelete] = useState(false)
  const [indicationInput, setIndicationInput] = useState(false)
  const [valueIndicationInput, setValueIndicationInput] = useState('')

  const watchPaymentPercentage = useWatch({ name: 'paymentPercentage' })

  const deleteService = () => {
    dispatch(deleteServiceAdded(serviceSelected._id))
    setOpenAlertDelete(false)
    onClose()
  }

  const addNewIndication = () => {
    setIndications([...indications, valueIndicationInput])
    setValueIndicationInput('')
    setIndicationInput(false)
  }

  const deleteIndication = (indication) => {
    let indicationDelete = indications.filter((ind) => ind !== indication)
    setIndications(indicationDelete)
  }

  const sliderMarks = [
    {
      value: 0,
      label: '0 %',
    },
    {
      value: 100,
      label: '100 %',
    },
  ]

  const labelComponent = (watchPaymentPercentage) => {
    return (
      <p>
        <span className={s.bold}>Porcentaje de pago: </span>
        {watchPaymentPercentage}%
      </p>
    )
  }

  return (
    <div>
      <div className={s.topInformation}>
        <p>
          <i>"Consulta"</i> y <i>"Primera consulta"</i> ya están creados,
          <br />
          precio y duración de los mismos se pueden modificar desde
          <br />
          la pestaña "Precios", en la configuración de los profesionales.
        </p>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CaluFormInput labelText="Nombre(s) *" name="name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CaluFormSelect
            items={turnDurations}
            labelText={`Duración de ${t('turnLabel.p')}`}
            name="duration"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CaluFormSelect items={serviceTypes} labelText="Tipo de servicio" name="type" fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CaluFormInput type="number" labelText="$ Precio" name="price" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CaluFormInput type="number" labelText="$ Porcentaje de comisión" name="centrePaymentPercentage" />
        </Grid>
        <Grid item xs={12}>
          <CaluFormInput labelText="Descripción" name="description" />
        </Grid>

        {centreDetail?.centreConfiguration?.enableTurnsPaymentLinkModule ? (
          <>
            <Grid item xs={12} className={s.requirePayment}>
              <CheckboxField label={`Requerir pago para reservar online`} name="requirePayment" checkBoxColor={''} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SliderField
                name="paymentPercentage"
                label={labelComponent(watchPaymentPercentage)}
                marks={sliderMarks}
                fullWidth
                initValue={100}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <CaluFormInput labelText="Link de pago" name="paymentLink" />
          </Grid>
        )}

        <Grid container rowSpacing={2} className={s.containerIndications}>
          <Grid item xs={12}>
            <p className={s.indicationsTitle}>
              <b>
                Indicaciones para el {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}
              </b>
            </p>
          </Grid>

          <div className={s.containerIndicationsList}>
            {indications?.length ? (
              <ul>
                {indications?.map((indication, index) => (
                  <li key={index} className={s.itemIndication}>
                    <div className={s.indicationName}>
                      <BsDot />
                      {indication}
                    </div>
                    <Button
                      className={s.btnDeleteIndication}
                      variant="contained"
                      disableElevation
                      onClick={() => deleteIndication(indication)}
                    >
                      X
                    </Button>
                  </li>
                ))}
              </ul>
            ) : (
              <ul>
                <li className={s.noIndications}>No hay indicaciones cargadas para este servicio</li>
              </ul>
            )}

            {indicationInput && (
              <div className={s.containerNewIndicationInput}>
                <CaluInput
                  labelText="Indicación"
                  value={valueIndicationInput}
                  onChange={(e) => setValueIndicationInput(e.target.value)}
                />

                <Button className={s.btnAddIndication} variant="contained" disableElevation onClick={addNewIndication}>
                  <BsCheckLg />
                </Button>
              </div>
            )}

            <CaluButton color="primary" inverted="true" size="medium" onClick={() => setIndicationInput(true)}>
              Nueva indicación
            </CaluButton>
          </div>
        </Grid>
        <Grid item xs={12} className={s.containerButtonsForm}>
          {serviceSelected && entityInLocalStorage?.role !== 'secretary' && (
            <>
              <CaluButton color="error" size="medium" onClick={() => setOpenAlertDelete(!openAlertDelete)}>
                Eliminar
              </CaluButton>
              <AlertDialogSlide
                title="Eliminar servicio"
                open={openAlertDelete}
                setOpen={setOpenAlertDelete}
                textContent={'¿Está seguro que quiere eliminar este servicio?'}
                textContentExtraOptional="Esta acción no se podrá deshacer."
                textBackButton="Cancelar"
                textConfirmationButton={'Eliminar'}
                onClose={deleteService}
              />
            </>
          )}

          <CaluButton color="primary" type="submit" size="medium" loading={isLoading} disabled={isLoading}>
            Guardar
          </CaluButton>
        </Grid>
      </Grid>
    </div>
  )
}
