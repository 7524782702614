import React from 'react'
import { useSelector } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormAdminSpecialities from '../formAdminSpecialities'

const PopUpAdminSpecialities = ({ open, setOpen }) => {
  const { specialitySelected } = useSelector((state) => state.speciality)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="sm" fullWidth open={open} onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={specialitySelected ? 'Edición de especialidad' : 'Nueva especialidad'}
            handleClose={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <FormAdminSpecialities formValues={specialitySelected} onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpAdminSpecialities
