import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'subscriptionPayments',
  initialState: {
    allSubscriptionPayments: [],
    paginationData: {
      page: 1,
      limit: 30,
    },
    selectedPayment: null,
    error: false,
    isLoading: null,
    debt: null,
  },
  reducers: {
    FETCH_ALL_SUBSCRIPTION_PAYMENTS: (state, { payload }) => {
      state.allSubscriptionPayments = payload.allSubscriptionPayments
      state.paginationData = payload.paginationData
    },
    FETCH_SINGLE_SUBSCRIPTION_PAYMENT: (state, { payload }) => {
      state.selectedPayment = payload
    },
    FETCH_DEBT: (state, { payload }) => {
      state.debt = payload
    },
    SUBSCRIPTION_PAYMENT_UPDATED: (state, { payload }) => {
      state.allSubscriptionPayments = state.allSubscriptionPayments.map((payment) => {
        if (payment._id === payload._id) {
          payment = payload
        }
        return payment
      })
    },
    SUBSCRIPTION_PAYMENT_DELETE: (state, { payload }) => {
      state.allSubscriptionPayments = state.allSubscriptionPayments.filter((payment) => payment._id !== payload)
      state.selectedPayment = null
    },
    SUBSCRIPTION_PAYMENT_CREATED: (state, { payload }) => {
      state.allSubscriptionPayments = [...(state.allSubscriptionPayments || []), payload]
    },
    SUBSCRIPTION_PAYMENT_SELECTED: (state, { payload }) => {
      state.selectedPayment = payload
    },
    RESET_SUBSCRIPTION_PAYMENT_SELECTED: (state) => {
      state.selectedPayment = null
    },
    LOADED_SUBSCRIPTION_PAYMENT: (state, { payload }) => {
      state.isLoading = payload
    },
  },
})

export const {
  FETCH_ALL_SUBSCRIPTION_PAYMENTS,
  SUBSCRIPTION_PAYMENT_UPDATED,
  SUBSCRIPTION_PAYMENT_DELETE,
  SUBSCRIPTION_PAYMENT_CREATED,
  LOADED_SUBSCRIPTION_PAYMENT,
  SUBSCRIPTION_PAYMENT_SELECTED,
  RESET_SUBSCRIPTION_PAYMENT_SELECTED,
  FETCH_SINGLE_SUBSCRIPTION_PAYMENT,
  FETCH_DEBT,
} = slice.actions
export default slice.reducer
