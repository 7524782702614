import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import CaluModal from '@/components/CaluModal'
import FAQs from '@/components/FAQs'
import { useUser } from '@/context/UserProvider'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import HelpIcon from '@mui/icons-material/Help'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'

import SupportPopUp from './supportPopUp'

import s from '../../styles.module.scss'

const HelpUser = () => {
  const { user } = useUser()

  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected

  const [anchorEl, setAnchorEl] = useState(null)
  const [popUpInformation, setPopUpInformation] = useState(false)
  const [openFaqs, setOpenFaqs] = useState(false)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const showPopUpInformation = () => {
    mxp.track(mxp.events.navbar.supportClicked)
    setPopUpInformation(!popUpInformation)
    handleClose()
  }

  const showFaqsButton = () => {
    if (user && entityInLocalStorage?.role !== 'patient') {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Tooltip title="Ayuda">
        <IconButton className={s.iconButton} onClick={handleClick}>
          <HelpIcon />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {showFaqsButton() && (
          <MenuItem
            className={s.hoverText}
            onClick={() => {
              setOpenFaqs(true)
              mxp.track(mxp.events.navbar.faqsClicked)
              handleClose()
            }}
          >
            Preguntas frecuentes
          </MenuItem>
        )}
        <MenuItem className={s.hoverText} onClick={showPopUpInformation}>
          Soporte
        </MenuItem>
      </Menu>

      <SupportPopUp open={popUpInformation} setOpen={setPopUpInformation} />

      <CaluModal open={openFaqs} setOpen={setOpenFaqs} title={'Preguntas frecuentes'}>
        <FAQs />
      </CaluModal>
    </>
  )
}

export default HelpUser
