import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useSocket } from '@/context/SocketProvider'
import { useUser } from '@/context/UserProvider'
import { updateDoctorCentres } from '@/store/doctorCentres/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import Checkbox from '@mui/material/Checkbox'

import s from './styles.module.scss'

const Doctors = ({ allDoctorsCentre }) => {
  const dispatch = useDispatch()
  const { defaultCentre } = useUser()
  const { socket } = useSocket()
  const { t } = useTranslation('global')

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const [checkedDoctor, setCheckedDoctor] = useState([])

  const handleToggleDoctors = (doctor) => async () => {
    const currentIndex = checkedDoctor.indexOf(doctor)
    const newChecked = [...checkedDoctor]

    if (currentIndex === -1) {
      newChecked.push(doctor)
      let auxDoctor = {
        ...doctor,
        hcAll: true,
      }
      const updatedDoctorCentre = await dispatch(updateDoctorCentres(auxDoctor))
      socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedDoctorCentre)
    } else {
      newChecked.splice(currentIndex, 1)
      let auxDoctor = {
        ...doctor,
        hcAll: false,
      }
      const updatedDoctorCentre = await dispatch(updateDoctorCentres(auxDoctor))
      socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedDoctorCentre)
    }
    setCheckedDoctor(newChecked)
  }

  useEffect(() => {
    const auxDoctors = []
    if (allDoctorsCentre) {
      allDoctorsCentre.forEach((k) => {
        if (k.hcAll === true) {
          auxDoctors.push(k)
        }
      })
    }
    setCheckedDoctor(auxDoctors)
  }, [allDoctorsCentre])

  return (
    <div className="animate__animated animate__fadeIn">
      <div className={s.textDetail}>
        <span>
          Marque los profesionales que quiere que{' '}
          <b>
            tengan permiso a todas las historias clínicas de todos los{' '}
            {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`)}.
          </b>
        </span>
      </div>

      <div className={s.containerDoctorList}>
        {allDoctorsCentre?.map((doctor) => (
          <div key={doctor._id} className={s.doctorItem}>
            <div
              className={s.nameDoctor}
            >{`${doctor?.lastname?.toUpperCase()}, ${doctor?.firstname?.toUpperCase()}`}</div>
            <div>
              <Checkbox
                edge="end"
                onChange={handleToggleDoctors(doctor)}
                checked={checkedDoctor.indexOf(doctor) !== -1}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Doctors
