import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormAdminInsurances from '../formAdminInsurances'

const PopUpAdminInsurances = ({ open, setOpen }) => {
  const { t } = useTranslation('global')

  const { insuranceSelected } = useSelector((state) => state.insurances)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="sm" fullWidth open={open} onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={insuranceSelected ? `Edición de ${t('insuranceLabel.s')}` : `Nuevo ${t('insuranceLabel.s')}`}
            handleClose={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <FormAdminInsurances formValues={insuranceSelected} onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpAdminInsurances
