import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { PageHearedTitle } from '@/components/pages'
import BaseSideBar from '@/layouts/Layout1/components/BaseSideBar'
import ContentSidebar from '@/pages/AdminPanelInsurances/components/contentSideBar'
import { deleteInsuranceAdded, getListInsurances, resetSelectedInsurance } from '@/store/insurances/actions'
import { Paper } from '@mui/material'

import CardInfo from './components/cardInfo'
import PopUpAdminInsurances from './components/popUpAdminInsurances'
import Layout1 from '../../layouts/Layout1/index'

import s from './styles.module.scss'

const AdminPanelInsurances = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const [openInsurancesPopUp, setOpenInsurancesPopUp] = useState(false)
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [openAlertDelete, setOpenAlertDelete] = useState(false)

  const [insurancesFiltered, setInsurancesFiltered] = useState([])

  const { insurancesList, insuranceSelected } = useSelector((state) => state.insurances)

  const setInit = async (params) => {
    setIsLoading(true)
    await dispatch(getListInsurances())

    setIsLoading(false)
  }

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filteredInsurances = insurancesList?.filter((field) =>
      field.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .includes(
          input
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
        ),
    )
    setInsurancesFiltered(filteredInsurances)
  }, [insurancesList, input])

  const addNewInsurance = () => {
    dispatch(resetSelectedInsurance())
    setOpenInsurancesPopUp(!openInsurancesPopUp)
  }

  const modifyInsurance = () => {
    setOpenInsurancesPopUp(!openInsurancesPopUp)
  }

  const deleteInsurance = () => {
    dispatch(deleteInsuranceAdded(insuranceSelected?._id))
    setOpenAlertDelete(false)
  }

  const handleChange = (e) => {
    setInput(e.target.value)
  }

  return (
    <Layout1
      navbar
      isLoading={isLoading}
      leftSidebar={
        <BaseSideBar
          inputLabel={`Buscar ${t('insuranceLabel.p')}`}
          inputValue={input}
          inputOnChange={handleChange}
          buttonLabel={`Nuevo ${t('insuranceLabel.s')}`}
          buttonOnClick={addNewInsurance}
          content={<ContentSidebar listItem={insurancesFiltered} />}
          panelAdmin={true}
        />
      }
    >
      <div className={s.mainContainer}>
        <div className={s.contentContainer}>
          <PageHearedTitle titleText={`${t('insuranceLabel.P')}`} />
          {insuranceSelected !== null ? (
            <CardInfo
              modifyItem={modifyInsurance}
              openAlertDelete={openAlertDelete}
              setOpenAlertDelete={setOpenAlertDelete}
              deleteItem={deleteInsurance}
            />
          ) : (
            <Paper elevation={3} className={`${s.cardContainer} animate__animated animate__fadeIn`}>
              <p>{`Seleccione un ${t('insuranceLabel.s')} del listado para ver su información`}</p>
            </Paper>
          )}
        </div>
      </div>

      {openInsurancesPopUp && <PopUpAdminInsurances open={openInsurancesPopUp} setOpen={setOpenInsurancesPopUp} />}
    </Layout1>
  )
}

export default AdminPanelInsurances
