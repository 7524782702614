import React, { useEffect, useState } from 'react'
import { useController, useWatch } from 'react-hook-form'
import { AiFillCloseCircle } from 'react-icons/ai'
import { AiFillCheckCircle } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import { CheckboxField } from '@/components/Form/customFields/CheckboxField'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { SelectField } from '@/components/Form/customFields/SelectField'
import CaluButton from '@/components/styledComponents/CaluButton'
import { countryCodes } from '@/helpers/constants'
import { deletePlanPricesAdded } from '@/store/planPrices/actions'
import { sortArrayAlphabetically } from '@/utils/functions/sortArrayAlphabetically'
import { Grid, MenuItem } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false, onClose, formValues }) => {
  const dispatch = useDispatch()
  const checkBoxColor = '#f8ac59'

  const [openAlertDelete, setOpenAlertDelete] = useState(false)

  const { selectedPlan } = useSelector((state) => state?.planPrices)
  const { allSubscriptionPlans } = useSelector((state) => state?.subscriptionPlans)

  const deletePlan = () => {
    dispatch(deletePlanPricesAdded(selectedPlan?._id))
    setOpenAlertDelete(false)
    onClose()
  }

  const { field: fieldSubscriptionPlan } = useController({ name: 'subscriptionPlan' })

  const watchVisible = useWatch({ name: 'visible' })
  const watchSubscriptionPlan = useWatch({ name: 'subscriptionPlan' })
  const watchPrice = useWatch({ name: 'price' })
  const watchMinAgendas = useWatch({ name: 'minAgendas' })
  const watchMaxAgendas = useWatch({ name: 'maxAgendas' })

  useEffect(() => {
    if (formValues) {
      fieldSubscriptionPlan.onChange(formValues?.subscriptionPlan?._id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <SelectField label="Nombre del plan *" name="subscriptionPlan" type="text" fullWidth>
            {allSubscriptionPlans?.map((plan) => (
              <MenuItem key={plan?._id} value={plan?._id}>
                {plan?.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={s.containerCheckbox}>
            <CheckboxField className={s.visible} label="Visible?" name="visible" checkBoxColor={checkBoxColor} />

            {watchVisible ? (
              <span className={s.spanCheckboxYes}>
                <i>
                  <AiFillCheckCircle /> Si
                </i>
              </span>
            ) : (
              <span className={s.spanCheckboxNo}>
                <i>
                  <AiFillCloseCircle /> No
                </i>
              </span>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="$ Precio *" name="price" type="number" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField
            label="% TAX"
            placeholder="0.1 (para 10%), 0.2 (para 20%), etc"
            name="tax"
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Desde (# agendas) *" name="minAgendas" type="number" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField label="Hasta (# agendas) *" name="maxAgendas" type="number" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <SelectField label="País" name="country" type="text" fullWidth>
            <MenuItem value={'-'}>Default</MenuItem>
            {sortArrayAlphabetically(countryCodes).map((code) => (
              <MenuItem key={code.value} value={code.value}>
                {code.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        <Grid item xs={12} className={s.containerButtonsForm}>
          {selectedPlan && (
            <>
              <CaluButton color="error" size="medium" onClick={() => setOpenAlertDelete(!openAlertDelete)}>
                Eliminar
              </CaluButton>
              <AlertDialogSlide
                title="Eliminar precio de plan"
                open={openAlertDelete}
                setOpen={setOpenAlertDelete}
                textContent={'¿Está seguro que quiere eliminar este precio de plan?'}
                textContentExtraOptional="Esta acción no se podrá deshacer."
                textBackButton="Cancelar"
                textConfirmationButton={'Eliminar'}
                onClose={deletePlan}
              />
            </>
          )}

          <CaluButton
            color="primary"
            type="submit"
            size="medium"
            loading={isLoading}
            disabled={!watchPrice || !watchSubscriptionPlan || !watchMinAgendas || !watchMaxAgendas}
          >
            Guardar
          </CaluButton>
        </Grid>
      </Grid>
    </div>
  )
}
