import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PopUpUnreadNotifications from '@/pages/AgendaPage/components/popUpUnreadNotifications/index'
import { saveFilteredUserNotifications } from '@/store/notifications/actions'

import dayjs from 'dayjs'

const UserNotifications = () => {
  const dispatch = useDispatch()
  const [availableToShowNotifications, setAvailableToShowNotifications] = useState(false)
  const [popUpUnreadNotifications, setPopUpUnreadNotifications] = useState(false)
  const [unreadNotifications, setUnreadNotifications] = useState([])

  const userNotifications = useSelector((state) => state.notifications.notifications)
  const userNotificationReads = useSelector((state) => state.notifications.notificationReads)
  const allNotifications = useSelector((state) => state.notifications.allNotifications)
  const allDoctorCentres = useSelector((state) => state.doctorCentres?.doctorCentres)
  const allMachines = useSelector((state) => state.machines?.allMachines)
  const { availableNotifications } = useSelector((state) => state.notifications)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isAdmin } = usersProfileConnected

  const currentDate = dayjs()
  const hasEntities = allMachines?.length > 0 || allDoctorCentres?.length > 0

  const diffDaysSinceCreationAccount = (currentDate, creationDate) => {
    let current = dayjs(currentDate).format('YYYY-MM-DD')
    const creation = dayjs(creationDate).format('YYYY-MM-DD')
    const diff = dayjs(current).diff(creation, 'day')
    return diff
  }

  useEffect(() => {
    let daysSinceCreationAccount = diffDaysSinceCreationAccount(currentDate, centreDetail?.creationDate)
    let TOTAL_DAYS = 2
    if (daysSinceCreationAccount > TOTAL_DAYS && hasEntities) {
      setAvailableToShowNotifications(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEntities])

  useEffect(() => {
    if (availableToShowNotifications) {
      if (userNotifications && userNotificationReads) {
        let allFilteredNotifications = []
        let notificationsUnread = userNotifications?.length
        userNotifications?.forEach((n) => {
          const notificationsRead = userNotificationReads?.filter((r) => n._id === r.notification._id)

          if (notificationsRead.length > 0) {
            allFilteredNotifications.push({ ...n, isRead: true })
            notificationsUnread = notificationsUnread - 1
            return
          }
          return allFilteredNotifications.push(n)
        })

        dispatch(saveFilteredUserNotifications(allFilteredNotifications))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userNotifications, userNotificationReads, availableToShowNotifications])

  useEffect(() => {
    if (availableToShowNotifications && allNotifications.length > 0) {
      const unreadNotificatation = allNotifications.filter((notification) => !notification.isRead)
      setUnreadNotifications(unreadNotificatation)
      setPopUpUnreadNotifications(true)
    }
  }, [allNotifications, availableToShowNotifications])

  return (
    <>
      {popUpUnreadNotifications && unreadNotifications.length > 0 && availableNotifications && !isAdmin && (
        <PopUpUnreadNotifications
          open={popUpUnreadNotifications}
          setOpen={setPopUpUnreadNotifications}
          unreadNotifications={unreadNotifications}
        />
      )}
    </>
  )
}

export default UserNotifications
