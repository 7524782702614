import {
  addNewSpeciality,
  deleteSpeciality,
  fetchSingleSpeciality,
  fetchSpecialities,
  putSpeciality,
} from '@/services/specialities'
import {
  FETCH_SPECIALITIES,
  RESET_SPECIALITY_SELECTED,
  SPECIALITY_CREATED,
  SPECIALITY_DELETE,
  SPECIALITY_SELECTED,
  SPECIALITY_UPDATED,
} from '@/store/specialities'
import { showToast } from '@/utils/toast'

const getListSpecialities = () => async (dispatch) => {
  try {
    const specialitiesList = await fetchSpecialities()
    dispatch(FETCH_SPECIALITIES(specialitiesList))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedSpeciality = (id) => async (dispatch) => {
  try {
    const singleSpeciality = await fetchSingleSpeciality(id)
    dispatch(SPECIALITY_SELECTED(singleSpeciality))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const postNewSpeciality = (data) => async (dispatch) => {
  try {
    const newSpeciality = await addNewSpeciality(data)
    dispatch(SPECIALITY_CREATED(newSpeciality))
    dispatch(SPECIALITY_SELECTED(newSpeciality))
    showToast('Especialidad agregada correctamente', 'success')

    return newSpeciality
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const updateSpeciality = (data) => async (dispatch) => {
  try {
    const specialityUpdated = await putSpeciality(data)
    dispatch(SPECIALITY_UPDATED(specialityUpdated))

    if (specialityUpdated) {
      showToast('Especialidad actualizada correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const deleteSpecialityAdded = (id) => async (dispatch) => {
  try {
    await deleteSpeciality(id)
    dispatch(SPECIALITY_DELETE(id))
    showToast('Especialidad eliminada correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedSpecialityByList = (speciality) => async (dispatch) => {
  try {
    dispatch(SPECIALITY_SELECTED(speciality))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedSpeciality = () => async (dispatch) => {
  try {
    dispatch(RESET_SPECIALITY_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export {
  deleteSpecialityAdded,
  getListSpecialities,
  postNewSpeciality,
  resetSelectedSpeciality,
  selectedSpeciality,
  selectedSpecialityByList,
  updateSpeciality,
}
