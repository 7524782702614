import { uploadFile } from '@/services/upload'

const postFile = async (url) => {
  try {
    const addFile = await uploadFile(url)

    return addFile
  } catch (error) {
    const { message } = error
    console.log(message, 'Error')
  }
}

export { postFile }
