import React, { useRef } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { SelectField } from '@/components/Form/customFields/SelectField'
import { SwitchField } from '@/components/Form/customFields/SwitchField'
import CaluButton from '@/components/styledComponents/CaluButton'
import { reminderDays } from '@/helpers/constants'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { Grid, MenuItem } from '@mui/material'

import s from './styles.module.scss'

export const FormContent = ({ isLoading = false }) => {
  const { t } = useTranslation('global')
  const { centreConfiguration } = useSelector((state) => state.centres.centreProfile)
  const watchShouldSendReminderEmail = useWatch({ name: 'centreConfiguration[shouldSendReminderEmail]' })
  const watchDisablePatientEmails = useWatch({ name: 'disablePatientEmails' })
  // const watchShouldSendWappReminder = useWatch({ name: 'centreConfiguration[shouldSendWappReminder]' })

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const refSubmitButton = useRef()

  return (
    <div className={`${s.containerForm} animate__animated animate__fadeIn`}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h5 className={s.titleSectionForm}>Opciones generales</h5>
        </Grid>

        <Grid item md={12}>
          <SwitchField
            label={`Mostrar botón "Confirmar ${t('turnLabel.s')}" en notificaciones y emails:`}
            name="noEmailConfirmButton"
            isCheckedInverted
          />
        </Grid>
        <Grid item md={12}>
          <SwitchField
            label={`Mostrar botón "Reprogramar ${t('turnLabel.s')}" en notificaciones y emails:`}
            name="noEmailModifyButton"
            isCheckedInverted
          />
        </Grid>
        <Grid item md={12}>
          <SwitchField
            label={`Mostrar botón "Cancelar ${t('turnLabel.s')}" en notificaciones y emails:`}
            name="noEmailCancelButton"
            isCheckedInverted
          />
        </Grid>
        <Grid item md={12}>
          <SwitchField
            label={`Mostrar detalle de pagos en email de ${t('turnLabel.s')} finalizada / detalle de ${t(
              'turnLabel.s',
            )}:`}
            name="showEmailTurnPaymentInfo"
          />
        </Grid>
        <Grid item md={12}>
          <SwitchField
            label={`Mostrar detalle de pagos en notificaciones de ${t('turnLabel.s')} finalizada / detalle de ${t(
              'turnLabel.s',
            )}:`}
            name="centreConfiguration[showWhatsAppTurnPaymentInfo]"
          />
        </Grid>

        <Grid item md={12} className={s.marginGrid}>
          <CustomField
            label={`Link a encuesta de satisfacción de ${t(
              `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`,
            )} (Se enviara en las citas finalizadas)`}
            name="turnSurvey"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <h5 className={s.titleSectionForm}>Whatsapp</h5>
        </Grid>

        <Grid item md={12} className={s.marginGrid}>
          <CustomField
            label="Mensaje personalizado para recordatorios por WhatsApp"
            name="centreConfiguration[extraWhatsAppReminderField]"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <h5 className={s.titleSectionForm}>Mensajes automáticos</h5>
          <p className={s.subTitle}>Habilitar mensaje automático de notificación</p>
        </Grid>

        <Grid item md={12}>
          <SwitchField
            label={`Creación de ${t('turnLabel.nt')} - Enviar mensaje con link para confirmar ${t('turnLabel.s')}`}
            name="centreConfiguration[shouldSendNotificationOnTurnCreate]"
          />
        </Grid>
        <Grid item md={12}>
          <SwitchField
            label={`Finalización de ${t('turnLabel.s')} - Enviar mensaje con detalle ${t('turnLabel.sd')}`}
            name="centreConfiguration[shouldSendNotificationOnTurnFinish]"
          />
        </Grid>
        <Grid item md={12}>
          <SwitchField
            label={`Cancelación de ${t('turnLabel.s')} - Enviar mensaje por cancelación ${t('turnLabel.sd')}`}
            name="centreConfiguration[shouldSendNotificationOnTurnCancel]"
          />
        </Grid>
        <Grid item md={12}>
          <SwitchField
            label={`Reprogramación de ${t('turnLabel.s')} - Enviar mensaje por reprogramación ${t('turnLabel.sd')}`}
            name="centreConfiguration[shouldSendNotificationOnTurnModify]"
          />
        </Grid>
        {centreConfiguration?.hasWappModule && (
          <Grid item md={12}>
            <SwitchField
              label={`Habilitar envíos automatizados de cumpleaños`}
              name="centreConfiguration[enableAutoWappBirthdayMessage]"
            />
          </Grid>
        )}

        {/* <Grid item md={watchShouldSendWappReminder ? 6 : 12}> */}
        {/* <Grid item md={12}>
          <SwitchField
            label={`Enviar mensaje de recordatorio de ${t('turnLabel.s')} automático un día antes`}
            name="centreConfiguration[shouldSendWappReminder]"
          />
        </Grid> */}
        {/* {watchShouldSendWappReminder ? (
          <Grid item md={6} xs={12}>
            <SelectField
              name="centreConfiguration[daysForWappReminder]"
              label="¿Cuándo quieres enviar el primer mensaje ?"
              fullWidth
            >
              {reminderDays.map((r) => (
                <MenuItem key={r.code} value={r.code}>
                  {r.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
        ) : null} */}

        <Grid item xs={12}>
          <h5 className={s.titleSectionForm}>Emails</h5>
        </Grid>

        <Grid item md={12} className={s.marginGrid}>
          <CustomField label="Mensaje personalizado para emails" name="extraMailField" fullWidth />
        </Grid>
      </Grid>

      <Grid item container md={12}>
        <Grid item md={12}>
          <SwitchField
            label={`Habilitar envíos de emails a ${t(
              `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`,
            )}`}
            name="disablePatientEmails"
            isCheckedInverted
          />
        </Grid>
        {!watchDisablePatientEmails && (
          <>
            <Grid item md={watchShouldSendReminderEmail ? 6 : 12}>
              <SwitchField label="Enviar primer recordatorio" name="centreConfiguration[shouldSendReminderEmail]" />
            </Grid>
            {watchShouldSendReminderEmail ? (
              <Grid item md={6} xs={12}>
                <SelectField
                  name="centreConfiguration[daysForReminderEmail]"
                  label="¿Cuándo quieres enviar el primer recordatorio ?"
                  fullWidth
                >
                  {reminderDays.map((r) => (
                    <MenuItem key={r.code} value={r.code}>
                      {r.name}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
            ) : null}
          </>
        )}
      </Grid>

      <div className={s.containerSaveButton}>
        <CaluButton color="primary" type="submit" size="medium" loading={isLoading} ref={refSubmitButton}>
          Guardar
        </CaluButton>
      </div>
    </div>
  )
}
