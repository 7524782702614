import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useUser } from '@/context/UserProvider'
import { SELECTED_CALENDAR_DATE } from '@/store/turns'
import { getAllTurns, getTurnFromSelectedDay, setDaysMonth } from '@/store/turns/actions'
import { handleCheckDisabledDates } from '@/utils/functions/handleCheckDisabledDates'
import { showToast } from '@/utils/toast'

import dayjs from 'dayjs'

// @ts-ignore
const CalendarContext = React.createContext()

export default function CalendarProvider(props) {
  const daysMonth = useSelector((state) => state.turns.daysMonthLoaded)
  const { defaultCentre } = useUser()
  const dispatch = useDispatch()

  const [filteredEntityText, setFilteredEntityText] = useState('')
  const [searchParams, setSearchParams] = useSearchParams({ fecha: dayjs(new Date()).format('YYYY-MM-DD') })
  let dateURL = searchParams.get('fecha')
  const [selectedDate, setSelectedDate] = useState(dateURL)

  const { isLoaded: isDoctorCentresLoaded } = useSelector((state) => state.doctorCentres)
  const { allMachines, isLoaded: isMachinesLoaded } = useSelector((state) => state.machines)
  const { dailyView } = useSelector((state) => state.changeViewCalendar)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  let centreHolidays = centreDetail?.holidays || []

  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected

  useEffect(() => {
    if (!defaultCentre) return
    if (!isDoctorCentresLoaded || !isMachinesLoaded) return

    if (!Date.parse(dateURL)) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dateURL = dayjs(new Date()).format('YYYY-MM-DD')
    }

    if (!daysMonth || daysMonth.length === 0) {
      setSelectedDate(dateURL)
      dispatch(SELECTED_CALENDAR_DATE(dateURL))
      let turnsParams = {
        centre: defaultCentre,
        start: dayjs(dateURL).utc().hour(0).minute(0).second(0).toISOString(),
        finish: dayjs(dateURL).add(31, 'days').utc().hour(23).minute(59).second(0).toISOString(),
        selectedRole: entityInLocalStorage?.role,
      }

      if (entityInLocalStorage?.role === 'doctor') {
        turnsParams.doctorMachines = allMachines?.map((m) => m?._id)
      }

      let start = dayjs(turnsParams.start).utc().format('YYYY-MM-DD')
      const end = dayjs(turnsParams.finish).utc().format('YYYY-MM-DD')
      const diff = dayjs(end).diff(start, 'day')
      const arrayOfDays = []
      arrayOfDays.push(start)
      for (let i = 0; i < diff; i++) {
        arrayOfDays.push(dayjs(start).add(1, 'days').format('YYYY-MM-DD'))
        start = dayjs(start).add(1, 'days').format('YYYY-MM-DD')
      }
      dispatch(setDaysMonth(arrayOfDays))
      dispatch(getAllTurns(turnsParams))
    }
  }, [isDoctorCentresLoaded, isMachinesLoaded, daysMonth])

  useEffect(() => {
    if (dateURL !== selectedDate && dailyView) {
      if (!defaultCentre) return
      const currentDate = dayjs(selectedDate).format('YYYY-MM-DD')

      const loadedTurns = daysMonth.filter(
        (t) => dayjs(t).format('YYYY-MM-DD') === dayjs(currentDate).format('YYYY-MM-DD'),
      )
      if (loadedTurns.length === 0) {
        let turnsParams = {
          centre: defaultCentre,
          start: dayjs(currentDate).utc(true).hour(0).minute(0).second(0).toISOString(),
          finish: dayjs(currentDate).utc(true).hour(23).minute(59).second(0).toISOString(),
          selectedRole: entityInLocalStorage?.role,
        }

        if (entityInLocalStorage?.role === 'doctor') {
          turnsParams.doctorMachines = allMachines?.map((m) => m?._id)
        }

        dispatch(getTurnFromSelectedDay(turnsParams))
        const newDayMonth = [...daysMonth, currentDate]

        dispatch(setDaysMonth(newDayMonth))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateURL, selectedDate, daysMonth])

  useEffect(() => {
    if (handleCheckDisabledDates(centreHolidays, dateURL)) {
      showToast('El día seleccionado el Centro está cerrado !!!', 'error')
      let date = dayjs(dateURL).add(1, 'day').format('YYYY-MM-DD')
      setSelectedDate(date)
      dispatch(SELECTED_CALENDAR_DATE(dateURL))
      setSearchParams({ fecha: date })
      return
    }
    let date = selectedDate
    setSearchParams({ fecha: date })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  const value = useMemo(
    () => ({ filteredEntityText, setFilteredEntityText, selectedDate, setSelectedDate }),
    [filteredEntityText, selectedDate],
  )
  return <CalendarContext.Provider value={value} {...props} />
}

export const useCalendar = () => {
  const context = React.useContext(CalendarContext)
  if (!context) {
    throw new Error('This hook only can used into the CalendarContext')
  }
  return context
}
