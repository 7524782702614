// @ts-nocheck
import React, { useEffect, useState } from 'react'
import LoaderCircle from '@/components/LoaderCircle'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import Typography from '@mui/material/Typography'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} color="primary" />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

export default function LoaderPercentage({ open, setOpen }) {
  const [progress, setProgress] = useState(10)

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10))
    }, 800)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ textAlign: 'center' }}>
          {open && (
            <div>
              <LoaderCircle size={30} />
              <div>{`${progress}% completado`}</div>
            </div>
          )}
          <Box sx={{ width: '100%' }}>
            <LinearProgressWithLabel value={progress} color="primary" />
          </Box>
          <DialogContentText id="alert-dialog-slide-description">Por favor espere...</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}
