import { css } from 'styled-components'

export const Mui = css`

  .MuiDateCalendar-root {
    width: ${(props) => props.isMobile ? '100%' : '226px'};
    overflow: hidden;
    margin: 0 auto;
    
   

    .MuiPickersDay-root {
      width: 28px;
      height: 28px;
      font-size: 13px;
    }

    .MuiPickersCalendarHeader-root {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 5px;
      margin: 4px 0px 8px;

      .MuiPickersCalendarHeader-labelContainer {
        color: ${(props) => props.theme.colors.b};
        font-size: 16px;
        font-weight: 500;
        margin: 0px;

        button {
          display: none;
        }
      }

      .MuiPickersArrowSwitcher-root {
        width: auto;

        .MuiPickersArrowSwitcher-spacer {
          width: 8px;
        }

        .MuiSvgIcon-root {
          fill: ${(props) => props.theme.colors.a};
          font-size: 22px;
        }
      }
    }

    > div:nth-child(2) {
      margin-bottom: 10px;

      > div { 
        flex-direction: column;
        
        .MuiDayCalendar-header {
          margin-left: 2px;

          .MuiDayCalendar-weekDayLabel {
            width: 28px;
            height: 28px;
            color: ${(props) => props.theme.colors.b};
            font-size: 13px;
            font-weight: 500;
            margin: 0px 2px;
          }
        }

        .MuiPickersSlideTransition-root {
          min-height: 190px;

          .MuiDayCalendar-monthContainer {
            .MuiDayCalendar-weekContainer {
              justify-content: space-around;

              .MuiPickersDay-dayOutsideMonth {
                color: #ccc;
              }
            }
          }
        }
      }
    }
  }

  // Padding in FormUpdateTurn
  .MuiTabPanel-root {
    padding: 0px;
  }

  //Lineal progress loader
  .MuiLinearProgress-bar {
    background-color: ${(props) => props.theme.colors.a};
  }

  .MuiLinearProgress-root {
    background-color: #b2dfdb;
  }

  .MuiAutocomplete-noOptions {
    font-size: 13px;
  }

  .MuiTextField-root {
    border-bottom: none;
  }

  .MuiButtonBase-root {
    white-space: nowrap;
    text-transform: unset;
  }

  .MuiPickersDay-root {
    font-size: 16px;
    &.Mui-selected,
    &.Mui-selected:focus,
    &.Mui-selected:hover,
    &.Mui-selected:active,
    &.Mui-selected:visited {
      background-color: ${(props) => props.theme.colors.a};
    }

    .MuiPickersDay-dayOutsideMonth {
      font-weight: 400;
      opacity: 0.5;
    }
    .MuiPickersDay-dayWithMargin {
      font-weight: 400;
    }
 
    .MuiDataGrid-footerContainer {
      display: flex;
      justify-content: center;
    }

    .MuiTabs-indicator {
      background-color: ${(props) => props.theme.colors.a};
    }

    .MuiInputBase-root {
      height: 48px;
      min-width: 140px;
      width: 100%;
    }

    .MuiAutocomplete-endAdornment {
      right: 10px;
    }

    .MuiFormControl-root {
      width: 100%;
    }

    

    .MuiStepLabel-iconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6.25px;
      background: ${(props) => props.theme.colors.base.white};
      width: 35px;
      height: 36.44px;
      border: 2px solid ${(props) => props.theme.colors.a};
      border-radius: 6.25px 6.25px 18.75px 6.25px;
    }
    .MuiStepIcon-root {
      color: ${(props) => props.theme.colors.base.white};
    }
    .MuiStepIcon-text {
      fill: ${(props) => props.theme.colors.a};
      font-weight: 600;
      font-size: 22.5px;
    }
   
  }

  .MuiStepIcon-root.Mui-active {
    color: ${(props) => props.theme.colors.a};
  }

  .MuiDialog-paper {
    width: 70%;
    max-height: 70%;

    @media (max-width: 768px) {
      width: 90%;
      max-height: 80%;
    }

    &.MuiDialog-paperFullScreen {
      width: inherit;
      max-height: inherit;
    }
  }
 
  .MuiCheckbox-root.Mui-checked {
    color: ${(props) => props.theme.colors.a};
  }

  .MuiRadio-root.Mui-checked {
      color: ${(props) => props.theme.colors.a};
    }
    
 .MuiDataGrid-row {
      cursor: pointer;
    }
   
   .MuiPaginationItem-root {
      color: ${(props) => props.theme.colors.a};
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      text-align: center;
      letter-spacing: -0.02em;

      &.Mui-selected {
        background: ${(props) => props.theme.colors.a};
        color: ${(props) => props.theme.colors.base.white};
        font-weight: 600;
        border-radius: 6.25px 6.25px 18.75px 6.25px;

        &:hover {
          background: ${(props) => props.theme.colors.a};
          color: ${(props) => props.theme.colors.base.white};
        }
      }
      &:hover {
        background: ${(props) => props.theme.colors.base.white};
        color: ${(props) => props.theme.colors.a};
        font-weight: 600;
        border-radius: 6.25px 6.25px 18.75px 6.25px;
        border: 2px solid ${(props) => props.theme.colors.a};
      }
    }

    .MuiDialog-root {
      &.centre-block-pop-up {
        z-index: 998;
      }
    }


    .MuiDialogContent-root {
      padding: 0px;
    }

    .MuiBox-root {
      padding: 0;
    }

    .MuiYearCalendar-root {
       width: ${(props) => !props.isMobile && '240px'};
    }

    //Calendar Month subscriptionPayments
    .MuiMonthCalendar-root {
      width: 100%;
    }
`
