import React from 'react'
import { useDispatch } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { resetSelectedMachine } from '@/store/machines/actions'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormCreateMachine from '../formCreateMachine'

const PopUpMachine = ({ open, setOpen, entity }) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    setOpen(false)
    dispatch(resetSelectedMachine())
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" open={open} fullWidth onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle={entity ? 'Configuración de agenda' : 'Nueva agenda'} handleClose={handleClose} />
        </DialogTitle>
        <DialogContent style={{ padding: '0px' }}>
          <FormCreateMachine handleClose={handleClose} entity={entity} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpMachine
