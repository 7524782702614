import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { Button, Grid, Paper } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const CardInfoNotification = ({ modifyNotification, openAlertDelete, setOpenAlertDelete, deleteNotification }) => {
  const { t } = useTranslation('global')

  const { notificationSelected } = useSelector((state) => state.notifications)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const ableByScope = (scope) => {
    if (scope === 'patients')
      return `Administradores, Profesionales y ${t(
        `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.Patients`,
      )}`
    if (scope === 'doctors') return `Administradores y Profesionales`
    if (scope === 'centres') return `Administradores`

    //TODO: Replace this function with constants file and translations
    if (scope === 'userOnly') return `Usuario en particular`
  }

  return (
    <Paper elevation={3} className={`${s.cardContainer} animate__animated animate__fadeIn`}>
      <Grid container>
        <Grid item sm={12} lg={8} className={s.containerInfo}>
          <p>
            <span>Última modificación: </span>
            <b>{dayjs(notificationSelected?.modificationDate).utc(true).format('dddd DD/MM/YYYY HH:mm ')} hs</b>
          </p>
          <p>
            <span>¿Quién la ve? </span>
            <b>{ableByScope(notificationSelected?.scope)}</b>
          </p>

          {notificationSelected?.scope === 'userOnly' && (
            <p>
              <span>Usuarios: </span>
              <b>
                {notificationSelected?.users?.map((user, index) => {
                  return (
                    <span key={index}>
                      {capitalizeFirstLetters(user?.lastname)} {capitalizeFirstLetters(user?.firstname)}
                      {index !== notificationSelected?.users?.length - 1 ? ', ' : ''}
                    </span>
                  )
                })}
              </b>
            </p>
          )}
          <p>
            <span>¿Visible? </span>
            {notificationSelected?.visible ? (
              <span className={s.spanCheckboxYes}>
                <i>
                  <AiFillCheckCircle /> Si
                </i>
              </span>
            ) : (
              <span className={s.spanCheckboxNo}>
                <i>
                  <AiFillCloseCircle /> No
                </i>
              </span>
            )}
          </p>
          <p>
            <span>¿Resaltar? </span>
            {notificationSelected?.highlight ? (
              <span className={s.spanCheckboxYes}>
                <i>
                  <AiFillCheckCircle /> Si
                </i>
              </span>
            ) : (
              <span className={s.spanCheckboxNo}>
                <i>
                  <AiFillCloseCircle /> No
                </i>
              </span>
            )}
          </p>
          <div className={s.messageItem}>
            <div className={s.messageItemTitle}>Mensaje de la notificación: </div>
            <div className={s.message} dangerouslySetInnerHTML={{ __html: notificationSelected?.message }} />
          </div>
        </Grid>
        <Grid
          item
          sm={12}
          lg={4}
          container
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <div className={s.containerButtons}>
            <div>
              <Button
                fullWidth={true}
                disableElevation
                variant="contained"
                className={s.editButton}
                onClick={modifyNotification}
              >
                Modificar
              </Button>
            </div>

            <div>
              <Button
                className={s.deleteButton}
                fullWidth={true}
                disableElevation
                variant="contained"
                onClick={() => setOpenAlertDelete(!openAlertDelete)}
              >
                Eliminar
              </Button>
              <AlertDialogSlide
                open={openAlertDelete}
                setOpen={setOpenAlertDelete}
                textContent={'¿Está seguro que quiere eliminar esta notificación?'}
                textBackButton="Cancelar"
                textConfirmationButton={'Eliminar'}
                onClose={deleteNotification}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CardInfoNotification
