import { version } from '@/helpers/env'
import * as Sentry from '@sentry/react'
const sentryDNS = import.meta.env.REACT_APP_SENTRY_DNS
const sentryProject = import.meta.env.REACT_APP_SENTRY_PROJECT

export const initSentry = () => {
  if (!sentryDNS || !sentryProject) return

  Sentry.init({
    dsn: sentryDNS,
    release: `${sentryProject}@${version}`,
    integrations: [
      Sentry.browserTracingIntegration({
        // add custom options here
      }),
    ],
    tracesSampleRate: 1.0,
  })
}
