import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SELECTED_USER } from '@/store/users'
import { handlePaginationChangeUsers } from '@/store/users/actions'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import LockIcon from '@mui/icons-material/Lock'
import { Box, Button, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import dayjs from 'dayjs'

import s from '../../styles.module.scss'

const UsersTable = ({ setOpen, inputSearch, roleSelected }) => {
  const dispatch = useDispatch()
  const { allUsers, paginationData, isLoaded } = useSelector((state) => state?.users)
  const { limit, totalDocs, page } = paginationData || {}

  const handleClick = (data) => {
    const filteredUser = allUsers?.filter((user) => user?._id === data?._id)[0]
    dispatch(SELECTED_USER(filteredUser))
    setOpen(true)
  }

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  }

  const columns = [
    {
      field: 'blocked',
      headerName: '',
      maxWidth: 70,
      ...stylesColumns,
      renderCell: (params) =>
        params.value && (
          <Tooltip title={'Bloqueado'}>
            <LockIcon className={s.lockIcon} />
          </Tooltip>
        ),
    },
    {
      field: 'dni',
      headerName: 'DNI',
      minWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'lastname',
      headerName: 'Apellido',
      minWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'firstname',
      headerName: 'Nombre',
      minWidth: 120,
      ...stylesColumns,
    },

    {
      field: 'email',
      headerName: 'Email',
      minWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'roles',
      headerName: 'Roles',
      minWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'creationDate',
      headerName: 'Fecha de creación',
      minWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      flex: 1,
    },
    {
      field: 'raw',
      headerName: '',
      minWidth: 80,
      ...stylesColumns,
      renderCell: (params) => (
        <Button onClick={() => handleClick(params.row)} className={s.rowButton} tabIndex={params.hasFocus ? 0 : -1}>
          <EditOutlinedIcon />
        </Button>
      ),
    },
  ]

  const showUserRoles = (user) => {
    const roles = user?.roles?.map((role) => {
      if (role.includes('centre')) return 'centro'
      if (role.includes('patient')) return role.replace('patient', 'paciente')
      if (role.includes('secretary')) return role.replace('secretary', 'ayudante')
      if (role.includes('doctor')) return role.replace('doctor', 'profesional')
      if (role.includes('extension')) return role.replace('extension', 'extensión')
      return ''
    })

    return roles
  }

  const rows = allUsers?.map((user) => {
    return {
      id: user?._id,
      _id: user?._id,
      dni: user?.dni ? (user?.dni?.length === 15 ? '-' : user?.dni) : '-',
      firstname: user?.firstname,
      lastname: user?.lastname,
      email: user?.email,
      roles: showUserRoles(user),
      creationDate: dayjs(user?.creationDate).format('YYYY-MM-DD'),
      blocked: user?.blocked,
    }
  })

  const handlePaginationChange = ({ page, pageSize }) => {
    dispatch(handlePaginationChangeUsers(page + 1, pageSize, inputSearch, roleSelected))
  }

  return (
    <Box
      sx={{
        px: 0,
        '& .header': {
          fontSize: '14px',
        },
        '& .blueColor': {
          color: '#1c83c6',
          fontWeight: 800,
        },
        '& .redColor': {
          color: '#e74c3c',
          fontWeight: 800,
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
      }}
    >
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: limit,
            },
          },
        }}
        rows={rows || []}
        rowCount={totalDocs || 0}
        loading={isLoaded}
        paginationMode="server"
        columns={columns}
        pagination
        onPaginationModelChange={handlePaginationChange}
        getCellClassName={(params) => {
          if (params?.row?.blocked && params.field === 'blocked') {
            return 'redColor'
          }
        }}
        pageSizeOptions={[30, 50, 100]}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        disableRowSelectionOnClick={true}
        slotProps={{
          pagination: {
            labelRowsPerPage: 'Entradas por página:',
            labelDisplayedRows: () =>
              `${(page - 1) * limit + 1} - ${page * limit > totalDocs ? totalDocs : page * limit} de ${totalDocs}`,
          },
        }}
        sx={{ fontSize: '13px' }}
        rowHeight={45}
        autoHeight={true}
      />
    </Box>
  )
}

export default UsersTable
