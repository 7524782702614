import React from 'react'
import { useController } from 'react-hook-form'
import CaluRawInput from '@/components/styledComponents/CaluRawInput'
import { parsedDniValue } from '@/utils/functions/parsedDniValue'

const CaluFormInput = (props) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({ name: props?.name })

  const isError = Boolean(error?.message)

  return (
    <CaluRawInput
      {...props}
      error={isError}
      helperText={isError && error?.message}
      onChange={onChange}
      onBlur={onBlur}
      value={props.name === 'dni' ? parsedDniValue(value) : value}
      multiline={props?.multiline}
      id="filled-multiline-flexible"
      maxRows={props?.maxRows || 15}
    />
  )
}

export default CaluFormInput

CaluFormInput.defaultProps = {
  value: '',
}
