import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import useValidateURLId from '@/hooks/useValidateURLId'
import { postNewCashboxItem, updateCashboxItem, updateTurnCashboxItem } from '@/store/cashboxItems/actions'

import dayjs from 'dayjs'
import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'

import s from './styles.module.scss'

const FormCreateCashboxItem = ({ formValues, onClose }) => {
  const dispatch = useDispatch()
  const centreID = useValidateURLId()
  const [isLoading, setIsLoading] = useState(false)
  const [isAddingExtraAmount, setIsAddingExtraAmount] = useState(false)
  const [amount, setAmount] = useState(formValues?.amount || 0)

  const [debt, setDebt] = useState(formValues?.debt || 0)
  const [auxPaymentHistory, setAuxPaymentHistory] = useState([])

  const { allCentreProviders } = useSelector((state) => state.centreProvider)

  const searchProvider = (id) => {
    let searchResult = allCentreProviders.find((elem) => elem._id === id)
    return searchResult
  }

  const handleSubmit = (data) => {
    setIsLoading(true)
    const extraAmountValue = data?.extraAmountValue === '' ? 0 : parseInt(data?.extraAmountValue)
    data.centre = centreID
    data.date = dayjs(data.date).utc(true).format()
    data.paymentHistory = auxPaymentHistory
    data.totalPayments = data?.paymentHistory?.reduce((accum, p) => accum + (p.amount || 0), 0)

    if (data.type === 'income') delete data.centreProvider
    if (data.type?.includes('outcome')) delete data.patientCentre

    if (data.centreProvider) {
      data.centreProvider = searchProvider(data.centreProvider._id)
    } else {
      delete data.centreProvider
    }

    if (!data.patientCentre) delete data.patientCentre
    if (extraAmountValue > 0) {
      data.extraAmount = {
        description: data.extraAmountDescription || '',
        type: data.typeExtraAmount || null,
        value: extraAmountValue,
      }
    }
    if (!formValues) {
      const sum = auxPaymentHistory?.reduce((accum, obj) => {
        return accum + obj?.amount
      }, 0)
      data.amount = parseInt(data.amount)
      data.debt = parseInt(data.amount) - sum
    }

    try {
      if (formValues) {
        data.amount = amount
        data.debt = debt
        if (data?.state) {
          delete data.extraAmountDescription
          delete data.extraAmountValue
          delete data.typeExtraAmount
          delete data.type
          delete data.entityType
          delete data.description
          dispatch(updateTurnCashboxItem(data))
        } else {
          dispatch(updateCashboxItem(data))
        }
      } else {
        dispatch(postNewCashboxItem(data))
      }
      onClose()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={{ ...formDefaultValues, ...formValues }}
      >
        <FormContent
          isLoading={isLoading}
          onClose={onClose}
          formValues={formValues}
          auxPaymentHistory={auxPaymentHistory}
          setAuxPaymentHistory={setAuxPaymentHistory}
          isAddingExtraAmount={isAddingExtraAmount}
          setIsAddingExtraAmount={setIsAddingExtraAmount}
          amount={amount}
          setAmount={setAmount}
          debt={debt}
          setDebt={setDebt}
        />
      </ContainerForm>
    </div>
  )
}

export default FormCreateCashboxItem
