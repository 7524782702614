import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const getAllTurnDebts = async (params) => {
  const route = `api/turns/debts`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getAllTreatmentDebts = async (params) => {
  const route = `api/treatments/debts`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { getAllTreatmentDebts, getAllTurnDebts }
