import ReactGA from 'react-ga'

function initTracking() {
  import.meta.env.REACT_APP_GA_ACTIVATE && import.meta.env.REACT_APP_GA_ID && ReactGA.initialize(import.meta.env.REACT_APP_GA_ID)
}

function sendEvent(payload) {
  ReactGA.event(payload)
}

function sendPageview(path) {
  const pathWhitoutID = removeIdInPath(path)
  ReactGA.set({ page: pathWhitoutID })
  ReactGA.pageview(pathWhitoutID)
}

function removeIdInPath(path) {
  const pathSplit = path.split('/')
  const lastSplit = pathSplit[pathSplit.length - 1]

  if (/[0-9a-f]{24}/.test(lastSplit)) pathSplit.pop()
  const result = pathSplit.join('/')

  return result
}

const analytics = { initTracking, sendEvent, sendPageview }
export default analytics
