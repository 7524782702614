import generatePrescriptionPdf from '@/components/ClinicHistory/components/prescriptions/components/prescriptionItem/components/prescriptionForm/generatePrescriptionPdf'
import { generateMongoObjectId } from '@/helpers/generateMongoObjectId'
import {
  addNewPrescription,
  deletePrescription,
  fetchSinglePrescription,
  prescriptionReport,
  putPrescription,
} from '@/services/prescriptions'
import {
  FETCH_PRESCRIPTIONS,
  PRESCRIPTION_CREATED,
  PRESCRIPTION_DELETE,
  PRESCRIPTION_SELECTED,
  PRESCRIPTION_UPDATED,
  RESET_PRESCRIPTION_SELECTED,
} from '@/store/prescriptions'
import { showToast } from '@/utils/toast'

const handleGeneratePdf = async (data) => {
  let fileUpload = await generatePrescriptionPdf(data)
  return fileUpload
}

const getAllPrescriptions = (fetchAllPrescriptions) => async (dispatch) => {
  dispatch(FETCH_PRESCRIPTIONS(fetchAllPrescriptions))
}

const postNewPrescriptions = (data) => async (dispatch) => {
  try {
    if (!data._id) {
      data._id = generateMongoObjectId()
    }

    if (data.state === 'done') {
      let urlFileGenerated = await handleGeneratePdf(data)
      data.generatedFileUrl = urlFileGenerated
    }

    let newPrescription = await addNewPrescription(data)

    dispatch(PRESCRIPTION_CREATED(newPrescription))
    if (updatePrescription) {
      showToast('Receta guardada correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const deletePrescriptionAdded = (id) => async (dispatch) => {
  try {
    await deletePrescription(id)
    dispatch(PRESCRIPTION_DELETE(id))
    showToast('Receta eliminada correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updatePrescription = (data, hideAlert) => async (dispatch) => {
  try {
    if (data.state === 'done') {
      let urlFileGenerated = await handleGeneratePdf(data)
      data.generatedFileUrl = urlFileGenerated
    }
    let prescriptionUpdated = await putPrescription(data)

    dispatch(PRESCRIPTION_UPDATED(prescriptionUpdated))

    if (prescriptionUpdated && !hideAlert) {
      showToast('Receta actualizada correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedPrescription = (id) => async (dispatch) => {
  try {
    const singlePrescription = await fetchSinglePrescription(id)
    dispatch(PRESCRIPTION_SELECTED(singlePrescription))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedPrescription = () => async (dispatch) => {
  try {
    dispatch(RESET_PRESCRIPTION_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const sendReportPrescriptionByEmail = (data) => async (dispatch) => {
  try {
    await prescriptionReport(data)
    showToast('La receta se ha enviado con éxito', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error')
  }
}

export {
  deletePrescriptionAdded,
  getAllPrescriptions,
  postNewPrescriptions,
  resetSelectedPrescription,
  selectedPrescription,
  sendReportPrescriptionByEmail,
  updatePrescription,
}
