import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchStatistics = async (params) => {
  const route = `api/statistics`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { fetchStatistics }
