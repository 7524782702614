import React from 'react'
import { Controller } from 'react-hook-form'
import { TextField } from '@mui/material'

import s from '../styles.module.scss'

const AgendaName = ({ control, helpIconFunction }) => {
  return (
    <div className={s.container1}>
      <div className={s.gridItem1}>
        <Controller
          name="agendaName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              inputProps={{ style: { textTransform: 'capitalize' } }}
              type="text"
              label="Nombre de la agenda*"
              variant="standard"
              className={s.agendaInput}
            />
          )}
          rules={{ required: true }}
        />
      </div>

      <span className={s.iconStep1}>{helpIconFunction()}</span>
    </div>
  )
}

export default AgendaName
