import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CaluButton from '@/components/styledComponents/CaluButton'
import TaskAltIcon from '@mui/icons-material/TaskAlt'

import s from '../styles.module.scss'

const SuccessPayment = () => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()

  return (
    <>
      <div className={s.confirmTitle}>¡Pago confirmado!</div>
      <div className={s.title}>Su {t('turnLabel.s')} está reservada</div>

      <div className={s.containerCard}>
        <TaskAltIcon className={s.checkIcon} />

        <CaluButton color="primary" size="large" onClick={() => navigate('/turnos')}>
          Ir a mis {t('turnLabel.p')}
        </CaluButton>
      </div>
    </>
  )
}

export default SuccessPayment
