import React from 'react'
import { Grid } from '@mui/material'
import { DateCalendar } from '@mui/x-date-pickers'

import dayjs from 'dayjs'
import { DateRangeDinamicStyles } from './DateRangeDinamicStyles'

import s from './styles.module.scss'

export default function DateRangeWithCalendar(props) {
  const startDate = dayjs(props.startSelectedDate).format('DD/MM/YYYY')
  const finishDate = dayjs(props.finishSelectedDate).format('DD/MM/YYYY')
  const today = dayjs().format('DD/MM/YYYY')

  const renderWeekPickerDay = (defaultProps) => {
    const { day, selectedDay, ...other } = defaultProps

    const dayIsBetween = day.isBetween(props?.startSelectedDate, props?.finishSelectedDate, 'day', '[]')
    const isFirstDay = day.isSame(props.startSelectedDate, 'day')
    const isLastDay = day.isSame(props.finishSelectedDate, 'day')

    return (
      <DateRangeDinamicStyles
        {...other}
        day={day}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    )
  }

  return (
    <Grid>
      <Grid item xs={12} container justifyContent="center" alignItems="center" spacing={3} className={s.datePickers}>
        <Grid item xs={12} md={6}>
          <p className={s.titlePicker}>
            Desde <span>{startDate === today ? '(HOY)' : startDate}</span>
          </p>
          <div>
            <DateCalendar
              value={dayjs(props.startSelectedDate)}
              onChange={props.handleStartSelectedDate}
              slots={{ day: renderWeekPickerDay }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <p className={s.titlePicker}>
            Hasta <span>{finishDate === today ? '(HOY)' : finishDate}</span>
          </p>
          <div>
            <DateCalendar
              value={dayjs(props.finishSelectedDate)}
              onChange={props.handleFinishSelectedDate}
              minDate={dayjs(props.startSelectedDate)}
              disableHighlightToday={true}
              slots={{ day: renderWeekPickerDay }}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}
