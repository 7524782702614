import i18next from '@/configs/i18next'

import dayjs from 'dayjs'

export const availableToChangeTurnTime = (turn) => {
  let now = dayjs().utc('Z').format()
  let startHour = dayjs(turn?.startHour).utc().format()
  let hourToCancelTurn = turn?.hoursToCancelTurn
  let diffHours = dayjs(startHour).diff(now, 'hour')

  if (diffHours < 0) {
    return {
      code: 1,
      text: `Ya no puedes modificar la fecha de su ${i18next.t('turnLabel.s', {
        ns: 'global',
      })} por que su fecha ya pasó`,
    }
  } else if (diffHours < hourToCancelTurn) {
    return {
      code: 2,
      text: `Ya no puedes modificar la fecha de su ${i18next.t('turnLabel.s', {
        ns: 'global',
      })} por que faltan menos de
      ${hourToCancelTurn}
       horas para ${i18next.t('turnLabel.el', {
         ns: 'global',
       })}`,
    }
  } else {
    return {
      code: 3,
      text: '',
    }
  }
}
