import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import useValidateURLId from '@/hooks/useValidateURLId'
import { postService, updateService } from '@/store/services/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const FormCreateService = ({ formValues, onClose }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [indications, setIndications] = useState(formValues?.indications || [])
  const centreID = useValidateURLId()

  const handleSubmit = (data) => {
    setIsLoading(true)
    data.centre = centreID
    data.indications = indications
    try {
      if (formValues) {
        dispatch(updateService(data))
      } else {
        dispatch(postService(data))
      }

      onClose()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={{ ...formDefaultValues, ...formValues }}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent
          isLoading={isLoading}
          onClose={onClose}
          indications={indications}
          setIndications={setIndications}
        />
      </ContainerForm>
    </div>
  )
}

export default FormCreateService
