import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'rooms',
  initialState: {
    allRooms: [],
    roomSelected: null,
    relatedCentre: null,
    isLoading: false,
  },
  reducers: {
    FETCH_ROOMS: (state, { payload }) => {
      state.allRooms = payload.rooms
      state.relatedCentre = payload.centre
    },
    ROOM_UPDATED: (state, { payload }) => {
      state.allRooms = payload.rooms
      state.relatedCentre = payload.centre
    },
    ROOM_DELETE: (state, { payload }) => {
      state.allRooms = payload.rooms
      state.relatedCentre = payload.centre
      state.roomSelected = null
    },
    ROOM_CREATED: (state, { payload }) => {
      state.allRooms = payload.rooms
      state.relatedCentre = payload.centre
    },
    ROOM_SELECTED: (state, { payload }) => {
      state.roomSelected = payload
    },
    RESET_ROOM_SELECTED: (state, { payload }) => {
      state.roomSelected = null
    },
  },
})

export const { FETCH_ROOMS, ROOM_UPDATED, ROOM_DELETE, ROOM_CREATED, ROOM_SELECTED, RESET_ROOM_SELECTED } =
  slice.actions
export default slice.reducer
