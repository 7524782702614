import React from 'react'
import { useDispatch } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { resetSelectedService } from '@/store/services/actions'
import { DialogContent } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormCreateService from '../formCreateService'

import s from './styles.module.scss'

const MainPopUp = ({ open, setOpen, serviceSelected, setOpenAutocompleteServices }) => {
  const dispatch = useDispatch()
  const scroll = 'paper'

  const handleClosePopUp = () => {
    setOpen(!open)
    dispatch(resetSelectedService())
    if (setOpenAutocompleteServices) setOpenAutocompleteServices(true)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={open} onClose={handleClosePopUp}>
        <TitlePopUp
          textTitle={serviceSelected ? 'Edición de servicio' : 'Crear nuevo servicio'}
          handleClose={handleClosePopUp}
        />
        <DialogContent dividers={scroll === 'paper'} className={s.dialogContent}>
          <FormCreateService formValues={serviceSelected} onClose={handleClosePopUp} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default MainPopUp
