import {
  addNewClinicHistoryFragment,
  deleteClinicHistoryFragment,
  fetchClinicHistoriesFragments,
  fetchSingleClinicHistoryFragment,
  putClinicHistoryFragment,
} from '@/services/clinicHistoryFragments'
import {
  FETCH_HC_FRAGMENTS,
  HC_FRAGMENT_CREATED,
  HC_FRAGMENT_DELETE,
  HC_FRAGMENT_SELECTED,
  HC_FRAGMENT_UPDATED,
  LOADING_FRAGMENTS,
  RESET_HC_FRAGMENT_SELECTED,
} from '@/store/clinicHistoryFragments'
import { showToast } from '@/utils/toast'

const getAllHCFragments = (fetchAllHCFragments) => async (dispatch) => {
  let { docs: allHCFragments, ...paginationData } = fetchAllHCFragments
  dispatch(FETCH_HC_FRAGMENTS({ allHCFragments, paginationData }))
}

const postNewHCFragment = (data) => async (dispatch) => {
  try {
    const newFragment = await addNewClinicHistoryFragment(data)
    dispatch(HC_FRAGMENT_CREATED(newFragment))
    if (newFragment) {
      showToast('Fragmento creado correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const deleteFragmentAdded = (id) => async (dispatch) => {
  try {
    await deleteClinicHistoryFragment(id)
    dispatch(HC_FRAGMENT_DELETE(id))
    showToast('Fragmento eliminado correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateHCFragment = (data, callback) => async (dispatch) => {
  try {
    const fragmentUpdated = await putClinicHistoryFragment(data)
    dispatch(HC_FRAGMENT_UPDATED(fragmentUpdated))

    if (fragmentUpdated) {
      showToast('Fragmento actualizado correctamente', 'success')
    }

    if (callback) {
      callback()
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedHCFragment = (id) => async (dispatch) => {
  try {
    const singleFragment = await fetchSingleClinicHistoryFragment(id)
    dispatch(HC_FRAGMENT_SELECTED(singleFragment))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedHCFragment = () => async (dispatch) => {
  try {
    dispatch(RESET_HC_FRAGMENT_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const handlePaginationChangeHCFragments = (newPage, newLimit, searchText) => async (dispatch, getState) => {
  const { centreProfile } = getState().centres
  const params = {
    centre: centreProfile?._id,
    page: newPage,
    limit: newLimit,
    search: searchText,
    paginate: true,
  }
  dispatch(LOADING_FRAGMENTS(true))
  let { docs: allHCFragments, ...paginationData } = await fetchClinicHistoriesFragments(params)
  dispatch(FETCH_HC_FRAGMENTS({ allHCFragments, paginationData }))
  dispatch(LOADING_FRAGMENTS(false))
}

export {
  deleteFragmentAdded,
  getAllHCFragments,
  handlePaginationChangeHCFragments,
  postNewHCFragment,
  resetSelectedHCFragment,
  selectedHCFragment,
  updateHCFragment,
}
