import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateSecretaryCentre } from '@/store/secretaryCentre/actions'
import Checkbox from '@mui/material/Checkbox'

import s from './styles.module.scss'

const Secretaries = ({ allSecretaries }) => {
  const dispatch = useDispatch()

  const [checkedSecretary, setCheckedSecretary] = useState([])

  const handleToggleSecretaries = (secretary) => () => {
    const currentIndex = checkedSecretary.indexOf(secretary)
    const newChecked = [...checkedSecretary]

    if (currentIndex === -1) {
      newChecked.push(secretary)
      let auxSecretary = {
        ...secretary,
        hcAccess: true,
      }
      dispatch(updateSecretaryCentre(auxSecretary))
    } else {
      newChecked.splice(currentIndex, 1)
      let auxSecretary = {
        ...secretary,
        hcAccess: false,
      }
      dispatch(updateSecretaryCentre(auxSecretary))
    }
    setCheckedSecretary(newChecked)
  }

  useEffect(() => {
    const auxCheckedSecretary = []
    if (allSecretaries) {
      allSecretaries.forEach((k) => {
        if (k.hcAccess === true) {
          auxCheckedSecretary.push(k)
        }
      })
    }
    setCheckedSecretary(auxCheckedSecretary)
  }, [allSecretaries])

  return (
    <div className="animate__animated animate__fadeIn">
      <div className={s.textDetail}>
        <span>
          Sólo tendrán acceso a Historias clínicas <b>asistentes habilitados</b> a continuación.
        </span>
      </div>

      <div className={s.containerSecretaryList}>
        {allSecretaries?.map((secretary) => (
          <div key={secretary._id} className={s.secretaryItem}>
            <div
              className={s.nameSecretary}
            >{`${secretary?.lastname?.toUpperCase()}, ${secretary?.firstname?.toUpperCase()}`}</div>
            <div>
              <Checkbox
                edge="end"
                onChange={handleToggleSecretaries(secretary)}
                checked={checkedSecretary.indexOf(secretary) !== -1}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Secretaries
