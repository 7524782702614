import {
  addNewSubscriptionPayment,
  deleteSubscriptionPayment,
  fetchSingleSubscriptionPayment,
  fetchSubscriptionPayments,
  fetchSubscriptionPaymentsBackup,
  fetchSubscriptionPaymentsDebt,
  putSubscriptionPayment,
} from '@/services/subscriptionPayments'
import {
  FETCH_ALL_SUBSCRIPTION_PAYMENTS,
  FETCH_DEBT,
  FETCH_SINGLE_SUBSCRIPTION_PAYMENT,
  LOADED_SUBSCRIPTION_PAYMENT,
  SUBSCRIPTION_PAYMENT_CREATED,
  SUBSCRIPTION_PAYMENT_DELETE,
  SUBSCRIPTION_PAYMENT_UPDATED,
} from '@/store/subscriptionPayments'
import { showToast } from '@/utils/toast'

const getAllSubscriptionPayments = (params) => async (dispatch) => {
  dispatch(LOADED_SUBSCRIPTION_PAYMENT(true))
  try {
    const { docs: allSubscriptionPayments, ...paginationData } = await fetchSubscriptionPayments(params)
    dispatch(FETCH_ALL_SUBSCRIPTION_PAYMENTS({ allSubscriptionPayments, paginationData }))
    dispatch(LOADED_SUBSCRIPTION_PAYMENT(false))
  } catch (error) {
    const { message } = error
    console.log(message)
    dispatch(LOADED_SUBSCRIPTION_PAYMENT(false))
  }
}

const getSingleSubscriptionPayment = (id) => async (dispatch) => {
  try {
    const singleSubscriptionPayment = await fetchSingleSubscriptionPayment(id)
    dispatch(FETCH_SINGLE_SUBSCRIPTION_PAYMENT(singleSubscriptionPayment))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const getSubscriptionPaymentsDebt = (params) => async (dispatch) => {
  const centreId = params
  try {
    const subscriptionPaymentsDebt = await fetchSubscriptionPaymentsDebt(centreId)
    if (subscriptionPaymentsDebt?.totalDebt) {
      dispatch(FETCH_DEBT(subscriptionPaymentsDebt))
    }
    return subscriptionPaymentsDebt
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const getSubscriptionPaymentsBackup = (params) => async (dispatch) => {
  dispatch(LOADED_SUBSCRIPTION_PAYMENT(true))
  try {
    const backupSubscriptionPayments = await fetchSubscriptionPaymentsBackup(params)
    return backupSubscriptionPayments
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Se produjo un error', 'error')
  } finally {
    dispatch(LOADED_SUBSCRIPTION_PAYMENT(false))
  }
}

const postNewSubscriptionPayment =
  (data, hideAlert = false) =>
  async (dispatch) => {
    try {
      const newPayment = await addNewSubscriptionPayment(data)
      dispatch(SUBSCRIPTION_PAYMENT_CREATED(newPayment))
      if (newPayment && !hideAlert) {
        showToast('Pago agregado correctamente', 'success')
      }
      return newPayment
    } catch (error) {
      console.log('Error message: ', error)
      showToast(error, 'error')
    }
  }

const deleteSubscriptionPaymentAdded = (id) => async (dispatch) => {
  try {
    await deleteSubscriptionPayment(id)
    dispatch(SUBSCRIPTION_PAYMENT_DELETE(id))
    showToast('Pago Eliminado', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Error al eliminar el pago', 'error')
  }
}

const updateSubscriptionPayment = (data, hideAlert) => async (dispatch) => {
  try {
    const paymentUpdated = await putSubscriptionPayment(data)
    dispatch(SUBSCRIPTION_PAYMENT_UPDATED(paymentUpdated))
    if (paymentUpdated && !hideAlert) {
      showToast('Pago actualizado correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Error al actualizar el pago', 'error')
  }
}

const handlePaginationChangeSubscriptionPayments = (newPage, newLimit, searchText) => async (dispatch, getState) => {
  const { centreAdminDetail } = getState().centres
  const params = {
    centre: centreAdminDetail?._id,
    page: newPage,
    limit: newLimit,
    search: searchText,
  }
  dispatch(LOADED_SUBSCRIPTION_PAYMENT(true))
  await dispatch(getAllSubscriptionPayments(params))
  dispatch(LOADED_SUBSCRIPTION_PAYMENT(false))
}

export {
  deleteSubscriptionPaymentAdded,
  getAllSubscriptionPayments,
  getSingleSubscriptionPayment,
  getSubscriptionPaymentsBackup,
  getSubscriptionPaymentsDebt,
  handlePaginationChangeSubscriptionPayments,
  postNewSubscriptionPayment,
  updateSubscriptionPayment,
}
