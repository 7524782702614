import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import TextEditor from '@/components/TextEditor'
import { updateCentre } from '@/store/centres/actions'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'

import s from './styles.module.scss'

const Notes = () => {
  const dispatch = useDispatch()
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const [isLoading, setIsLoading] = useState(false)
  const [internalNote, setInternalNote] = useState(centreDetail?.internalNote)

  const onSubmit = async () => {
    mxp.track(mxp.events.agenda.centreInfo.noteUpdated)
    if (!centreDetail) return

    setIsLoading(true)
    await dispatch(updateCentre({ ...centreDetail, internalNote }))
    setIsLoading(false)
  }

  return (
    <div className={s.mainContainer}>
      <form className={s.containerForm}>
        <div>
          <div className={s.infoTextField}>
            <p>Puedes utilizar este espacio como anotador del centro.</p>
            <p>Este texto sólo será visible por asistentes y administradores.</p>
          </div>

          <div>
            <TextEditor onChange={setInternalNote} value={internalNote} />
          </div>
        </div>

        <div className={s.containerButton}>
          <CaluButton color="primary" size="medium" onClick={onSubmit} loading={isLoading}>
            Guardar
          </CaluButton>
        </div>
      </form>
    </div>
  )
}

export default Notes
