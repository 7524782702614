export const formDefaultValues = {
  description: '',
  amount: '',
  category: 'other',
  date: new Date(),
  entityType: 'cashboxItem',
  paymentHistory: [],
  type: 'income',
  patientCentre: '',
  centreProvider: { _id: '' },
  typeExtraAmount: 'plusCash',
  extraAmountValue: 0,
  extraAmountDescription: '',
}
