import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import CaluButton from '@/components/styledComponents/CaluButton'
import { useUser } from '@/context/UserProvider'
import Layout1 from '@/layouts/Layout1'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { Grid } from '@mui/material'

import LoaderCircle from '../../components/LoaderCircle'
import HeaderOnLineTurns from '../InfoPatientPage/components/headerOnLineTurns'

import s from './styles.module.scss'

const InfoPaymentOnlineTurn = () => {
  const centreID = useParams().id
  const navigate = useNavigate()
  const { user } = useUser()
  const { t } = useTranslation('global')

  const {
    entityData,
    turnData,
    patientCentres: selectedPatient,
    selectedService,
    turnDetail,
  } = useSelector((state) => state.turns?.onLineTurns)

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const country = centreDetail?.country || 'ARG'

  useEffect(() => {
    if (!entityData || !turnData || !selectedPatient || !selectedService) {
      navigate(`/centro/${centreID}`)
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout1 navbar>
      <Grid container spacing={2}>
        <Grid item xs={12} className={s.HeaderTabs}>
          <HeaderOnLineTurns isAuthenticated={!!user} selectedPatient={selectedPatient} confirmedTurn={true} />
        </Grid>
        <Grid item xs={12} className={s.content}>
          <p className={s.contentP}>
            Para reservar su {t('turnLabel.s')} debe abonar {handleSetMoneySign(country)}{' '}
            {turnDetail?.paymentLinkAmount}{' '}
          </p>
          <div className={s.contentSpinner}>
            <LoaderCircle size={40} text="Esperando pago..." />
          </div>
        </Grid>
        <Grid item xs={12} className={s.containerButtons}>
          <CaluButton
            color="primary"
            inverted="true"
            size="large"
            onClick={() => navigate(`/turnos/info-turno/${centreID}`)}
          >
            Volver
          </CaluButton>
          <CaluButton color="primary" size="large" onClick={() => (window.location.href = turnDetail?.paymentLink)}>
            Ir a pagar
          </CaluButton>
        </Grid>
      </Grid>
    </Layout1>
  )
}

export default InfoPaymentOnlineTurn
