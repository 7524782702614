import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'app',
  initialState: {
    isPageLoading: false,
    error404: false,
    backURLMessage: null,
  },
  reducers: {
    PAGE_LOADING_UPDATED: (state, { payload }) => {
      state.isPageLoading = payload
    },
    SHOW_ERROR_404: (state, { payload }) => {
      state.error404 = payload
    },
    SET_BACKURL_MESSAGE: (state, { payload }) => {
      state.backURLMessage = payload
    },
  },
})

export const { PAGE_LOADING_UPDATED, SHOW_ERROR_404, SET_BACKURL_MESSAGE } = slice.actions
export default slice.reducer
