import React from 'react'
import { useDispatch } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import CaluInput from '@/components/styledComponents/CaluInput'
import { RESET_PRODUCT_SELECTED } from '@/store/products'
import { Grid } from '@mui/material'

import s from './styles.module.scss'

const ProductsSearchBar = ({ setOpen, inputSearch, setInputSearch }) => {
  const dispatch = useDispatch()

  const handleOpenPopUp = () => {
    setOpen(true)
    dispatch(RESET_PRODUCT_SELECTED())
  }

  return (
    <Grid container spacing={1} className={s.mainContainer}>
      <Grid item xs={12} md={3}>
        <CaluInput value={inputSearch} onChange={(e) => setInputSearch(e.target.value)} labelText="Buscar producto" />
      </Grid>

      <Grid item xs={12} md={3}>
        <CaluButton onClick={handleOpenPopUp} type="add" variant="contained" size="medium">
          Crear producto
        </CaluButton>
      </Grid>
    </Grid>
  )
}

export default ProductsSearchBar
