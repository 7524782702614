export const STEPS = [
  {
    content: <h2>Comencemos con el tour!</h2>,
    locale: { skip: <strong aria-label="skip">{'Saltar el tour'}</strong> },
    placement: 'center',
    target: 'body',
  },
  {
    content: 'Para personalizar tu agenda has click aquí',
    title: 'Editar agenda',
    target: '.tourStep1',
    placement: 'bottom',
    styles: {
      options: {
        width: 300,
        zIndex: 1000,
      },
    },
  },
  {
    content: 'Para asignar una cita has click sobre el horario deseado',
    placement: 'top-start',
    target: '.tourStep2',
    title: 'Asignar cita',
    styles: {
      options: {
        width: 300,
        zIndex: 1000,
      },
    },
  },
]
