import i18next from '@/configs/i18next'
import { asyncForEach } from '@/helpers/asyncForEach'
import { fetchImage } from '@/helpers/fetchImage'
import { getAge } from '@/utils/functions/getAge'
import { handleSetDniLabel } from '@/utils/functions/handleSetDniLabel'
import { toCustomTz } from '@/utils/functions/toCustomTz'

import dayjs from 'dayjs'
import { htmlToText } from 'html-to-text'
import jsPDF from 'jspdf'

const sortClinicHistories = (arrayHC) => {
  let arrayCopy = [...arrayHC]
  return arrayCopy.sort((a, b) => (a.visit > b.visit ? 1 : -1))
}

const downloadHC = async (patientHC, patientCentre, centreLogo, signImage, outputType) => {
  const doc = new jsPDF('p', 'pt', 'A4')
  doc.setFont('helvetica')

  let patientName = patientCentre?.lastname + ', ' + patientCentre?.firstname
  let isMultiple = Array.isArray(patientHC)
  let isBackup = outputType === 'blob'
  let sortedClinicHistories

  if (isMultiple) {
    sortedClinicHistories = sortClinicHistories(patientHC)
  } else {
    sortedClinicHistories = [patientHC]
  }

  await asyncForEach(sortedClinicHistories, async (clinicHistory, index) => {
    let signatureSpace = 150 + 20 // 150 for the signature line + 20 for the signature image
    let firstPageHeader = 126
    let pageHeight = 800
    let isFirstPage = true
    let pageSpaceLeft = null
    // header
    doc.setFontSize(8)
    doc.setTextColor(149, 165, 166)
    doc.setFont('Helvetica', 'bold')
    doc.text('Identificador '.toUpperCase() + clinicHistory._id.slice(-8).toUpperCase(), 440, 50)

    // centre info
    if (centreLogo) {
      try {
        const centreLogoImage = await fetchImage(centreLogo)
        doc.addImage(centreLogoImage, 'png', 50, 40, 74, 48)
      } catch (error) {
        console.error('Error loading centreLogo:', error)
      }
    }

    if (clinicHistory.centre) {
      doc.setFontSize(10)
      doc.setTextColor(52, 73, 94)
      doc.setFont('Helvetica', 'bolditalic')

      let centreName = clinicHistory.centre.name || ''
      doc.text(centreName.toUpperCase(), 50, 100)
      doc.setFont('Helvetica', 'italic')

      let centreCity = clinicHistory.centre?.city?.formatted_address || clinicHistory.centre?.city || ''
      let centreStreet = clinicHistory.centre.street || ''

      if (centreStreet && centreCity) {
        doc.text(centreStreet + ', ' + centreCity, 50, 114)
      }

      let _centrePhoneEmail = ''
      if (clinicHistory.centre.phones && clinicHistory.centre.phones.length) {
        _centrePhoneEmail += 'Tel: '
        _centrePhoneEmail += clinicHistory.centre.phones[0]
      }

      if (clinicHistory.centre.email) {
        _centrePhoneEmail += ' — '
        _centrePhoneEmail += clinicHistory.centre.email
      }
      doc.text(_centrePhoneEmail, 50, 126)

      // Adjust pageHeight for the first page
      if (isFirstPage) {
        isFirstPage = false
        pageSpaceLeft = pageHeight - firstPageHeader - signatureSpace + 80
      } else {
        pageSpaceLeft = pageHeight - signatureSpace
      }
    }

    // defaults
    doc.setFontSize(10)
    doc.setFont('Helvetica', 'normal')
    doc.setTextColor(44, 62, 80)

    // date
    let date = 'Fecha: ' + toCustomTz(clinicHistory?.visit, undefined, true, 'DD/MM/YYYY')
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(10)

    if (isMultiple) {
      date = 'Ficha ' + (index + 1) + ' de ' + sortedClinicHistories.length + ' - ' + date
      doc.text(date, 340, 140)
    } else {
      doc.text(date, 420, 140)
    }

    // patient name
    doc.setFontSize(16)
    doc.setFont('Helvetica', 'bold')
    doc.text(patientName.toUpperCase(), 40, 160)
    doc.line(40, 165, 546, 165)

    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(10)

    // dni
    let dni
    if (patientCentre.dni.length <= 9) {
      dni = handleSetDniLabel(patientCentre?.country) + ': ' + patientCentre.dni
      doc.text(dni, 48, 180)
    }

    // birthday
    let birth
    if (patientCentre.birth) {
      birth = 'Fecha de nacimiento: ' + toCustomTz(patientCentre.birth, undefined, true, 'DD/MM/YYYY')
      birth += ' (' + getAge(patientCentre?.birth) + ' años)'
      doc.text(birth, 48, 195)
    }

    // insurance
    let insurance = ''
    if (clinicHistory?.insurance) {
      if (clinicHistory?.insurance?.name) {
        insurance = `${i18next.t('insuranceLabel.S', {
          ns: 'global',
        })}: ${clinicHistory?.insurance?.name?.toUpperCase()}`
      }

      if (clinicHistory?.insurance?.insurancePlan) {
        insurance += ' (' + clinicHistory.insurance.insurancePlan + ')'
      }

      if (clinicHistory?.insurance?.policyNumber) {
        insurance += ': ' + clinicHistory?.insurance?.policyNumber
      }
    } else {
      insurance = `${i18next.t('insuranceLabel.S', { ns: 'global' })}: PARTICULAR`
    }
    doc.text(insurance, 48, 210)

    // line separator header
    doc.setDrawColor(0, 0, 0, 0.3)
    doc.setLineWidth(1)
    doc.line(40, 220, 546, 220)

    const patientInfoBlockHeight = 220 - firstPageHeader
    pageSpaceLeft -= patientInfoBlockHeight + 15

    // Footer
    doc.setFontSize(8)
    doc.setFont('Helvetica', 'bold')
    doc.setTextColor(149, 165, 166)
    doc.text('calu.app — Sistema de gestión de centros de salud', 40, 800)
    doc.setFontSize(10)
    doc.setTextColor(44, 62, 80)

    // reason
    let reason = clinicHistory.reason ? 'Asunto: ' + clinicHistory.reason.toUpperCase() : 'Asunto: -'
    doc.setFont('Helvetica', 'bold')
    let reasonSplit = doc.splitTextToSize(reason, 490)
    let reasonHeight = reasonSplit.length * doc.getLineHeight()
    doc.text(reasonSplit, 48, 235)

    let lastPosition = 235 + reasonHeight - 10
    let newPosition = lastPosition + 15

    pageSpaceLeft -= reasonHeight + 15

    let lineHeight = doc.getLineHeight()
    let pageLinesLeft = Math.floor(pageSpaceLeft / lineHeight)

    // other fields
    clinicHistory.clinicHistoryFields.forEach(function (field) {
      let fieldText
      let fieldTextSplit

      if (field.value) {
        lastPosition = newPosition
        fieldText = '• ' + field.name + ':'

        if (field.type === 'date') {
          fieldText += ' ' + dayjs(field.value).format('DD/MM/YYYY')
        } else {
          fieldText += ' ' + field.value.toString()
        }
        fieldTextSplit = doc.splitTextToSize(fieldText, 490)
        doc.setFont('Helvetica', 'normal')

        fieldTextSplit.forEach((line) => {
          if (!pageLinesLeft) {
            doc.addPage()
            newPosition = 70
            pageSpaceLeft = pageHeight - signatureSpace
            pageLinesLeft = Math.floor(pageSpaceLeft / lineHeight)

            //Footer
            doc.setFontSize(8)
            doc.setFont('Helvetica', 'bold')
            doc.setTextColor(149, 165, 166)
            doc.text('calu.app — Sistema de gestión de centros de salud', 40, 800)
            doc.setFontSize(10)
            doc.setFont('Helvetica', 'normal')
            doc.setTextColor(44, 62, 80)
          }

          doc.text(line, 48, newPosition)

          newPosition += lineHeight
          pageSpaceLeft -= lineHeight
          pageLinesLeft--
        })
      }
    })

    //Observations
    let observations = clinicHistory.observations
      ? '<div style="font-size: 10px; font-family: helvetica;"><style>p{margin: 0px;} a{color: #1C83C6 !important;}</style>' +
        clinicHistory.observations +
        '</div>'
      : '-'

    if (!observations.startsWith('<')) {
      observations = '<p>' + observations + '</p>'
    }

    observations = observations.replace('\n', '<br />')

    if (observations.includes('<!--StartFragment-->') || observations.includes('<!--EndFragment-->')) {
      observations = observations.replace(/<!--StartFragment-->/g, '')
      observations = observations.replace(/<!--EndFragment-->/g, '')
    }

    let observationsText = htmlToText(observations, { wordwrap: 110 })
    let observationsSplit = doc.splitTextToSize(observationsText, 490)

    //Add margin top before add observations
    newPosition += 15

    doc.setFont('Helvetica', 'bold')
    doc.text('Observaciones:', 48, newPosition)
    newPosition += lineHeight
    pageSpaceLeft -= lineHeight
    pageLinesLeft--

    observationsSplit?.forEach((line) => {
      if (!pageLinesLeft) {
        doc.addPage()
        newPosition = 70
        pageSpaceLeft = pageHeight - signatureSpace
        pageLinesLeft = Math.floor(pageSpaceLeft / lineHeight)

        //Footer
        doc.setFontSize(8)
        doc.setFont('Helvetica', 'bold')
        doc.setTextColor(149, 165, 166)
        doc.text('calu.app — Sistema de gestión de centros de salud', 40, 800)
        doc.setFontSize(10)
        doc.setFont('Helvetica', 'normal')
        doc.setTextColor(44, 62, 80)
      }

      doc.setFont('Helvetica', 'normal')
      doc.text(line, 48, newPosition)

      newPosition += lineHeight
      pageSpaceLeft -= lineHeight
      pageLinesLeft--
    })

    if (clinicHistory?.doctorCentre?.signImage) {
      try {
        const doctorSignImage = await fetchImage(clinicHistory?.doctorCentre?.signImage)
        doc.addImage(doctorSignImage, 'png', 315, 670, 95, 45)
      } catch (error) {
        console.error('Error loading doctorSignImage:', error)
      }
    } else if (signImage) {
      try {
        const signImageLoaded = await fetchImage(signImage)
        doc.addImage(signImageLoaded, 'png', 315, 670, 95, 45)
      } catch (error) {
        console.error('Error loading signImage:', error)
      }
    } else {
    }

    // sign line
    doc.setDrawColor(0, 0, 0, 0.3)
    doc.setLineWidth(0.5)
    doc.line(300, 720, 510, 720)

    // sign info
    doc.setFontSize(10)
    doc.setFont('Helvetica', 'bold')

    if (clinicHistory.doctorCentre) {
      let doctorName = clinicHistory.doctorCentre
        ? clinicHistory.doctorCentre.lastname + ', ' + clinicHistory.doctorCentre.firstname
        : 'sin datos'
      doc.setTextColor(44, 62, 80)
      doc.text(doctorName.toUpperCase(), 310, 735)

      if (clinicHistory.doctorCentre.doctorNumber) {
        doc.setFont('Helvetica', 'normal')
        doc.setTextColor(44, 62, 80)
        doc.text(clinicHistory.doctorCentre.doctorNumber, 310, 750)
      }
    } else {
      if (clinicHistory.createdBy) {
        doc.setTextColor(44, 62, 80)
        doc.text(clinicHistory.createdBy.toUpperCase(), 310, 735)
      }

      if (clinicHistory.centre.doctorNumber) {
        doc.setFont('Helvetica', 'normal')
        doc.setTextColor(44, 62, 80)
        doc.text(clinicHistory.centre.doctorNumber, 310, 750)
      }
    }

    if (index + 1 < sortedClinicHistories.length) {
      doc.addPage()
    }
  })

  let pdfName
  const patientDni = patientCentre?.dni?.length < 15 ? ` (${patientCentre?.dni})` : ''

  if (isMultiple || isBackup) {
    pdfName = `${patientName.toUpperCase()}${patientDni}.pdf`
  } else {
    pdfName = `Ficha ${patientName.toUpperCase()}${patientDni} - ${dayjs().format('DD-MM-YYYY')}`
  }

  doc.save(`${pdfName}`)

  return true
}

export default downloadHC
