import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CaluButton from '@/components/styledComponents/CaluButton'
import { Dialog, DialogContent } from '@mui/material'

import s from './styles.module.scss'

const PopUpTurnNotAvailable = ({ open }) => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()

  const { centreTargetProfile } = useSelector((state) => state.turns?.onLineTurns)

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={open} scroll="paper">
        <DialogContent className={s.mainContent}>
          <div className={s.containerPopUp}>
            <div className={s.detailTurn}>El horario de {t('turnLabel.s')} seleccionado ya no está disponible.</div>
          </div>
          <div className={s.divider}></div>
          <div className={s.containerButtonNoAvailableTurn}>
            <div className={s.itemButton}>
              <CaluButton
                color="primary"
                className={s.button}
                onClick={() => navigate(`/centro/${centreTargetProfile._id}`)}
                size="large"
              >
                Buscar {t('turnLabel.so')}
              </CaluButton>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpTurnNotAvailable
