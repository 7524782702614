import React, { useState } from 'react'
import ImgTutorial from '@/assets/images/tutorial.png'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import s from './styles.module.scss'

export default function ControlledAccordions({ questionsFiltered }) {
  const [expanded, setExpanded] = useState('')

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={s.containerQuestions}>
      <div className={s.containerQuestions}>
        {questionsFiltered !== 0 ? (
          questionsFiltered.map((q, i = 1) => (
            <Accordion key={i} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${i}bh-content`}
                id={`panel${i}bh-header`}
                className={s.acordionTitle}
              >
                <img src={ImgTutorial} alt="tutorial" />
                <div className={s.nameContainer}>
                  <div>{q.name}</div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <span>{q.description}</span>
                  {q.links?.map((e, i) => (
                    <span key={i}>
                      <a href={e.href} target="_blank" rel="noreferrer" className={s.link}>
                        {e.text}
                      </a>
                    </span>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <h1>CARGANDO....</h1>
        )}
      </div>
    </div>
  )
}
