import { apiErrorHandler } from '@/helpers/errorHandlers'

import APICalu from '../client'

const fetchWappMessageTemplates = async (params) => {
  const route = `api/wappMessageTemplates`
  return APICalu.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postWappMessageTemplate = async (params) => {
  const route = `api/wappMessageTemplates`
  return APICalu.post(route, params)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putWappMessageTemplate = async (params) => {
  const route = `api/wappMessageTemplates`
  return APICalu.put(route, params)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteWappMessageTemplate = async (id) => {
  const route = `api/wappMessageTemplates/${id}`
  return APICalu.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { deleteWappMessageTemplate, fetchWappMessageTemplates, postWappMessageTemplate, putWappMessageTemplate }
