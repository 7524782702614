import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'turns',
  initialState: {
    allTurns: [],
    paginationData: {
      page: 1,
      limit: 30,
    },
    daysMonthLoaded: [],
    selectedTurn: null,
    treatmentTurn: null,
    patientCentresTurns: null,
    onLineTurns: {
      turnData: null,
      entityData: null,
      centreTargetProfile: null,
      day: null,
      patientCentres: null,
      selectedService: null,
      selectedInsurance: null,
      confirmedTurn: null,
      extraServices: [],
      turnDetail: null,
      turnFinalAmount: null,
    },
    turnsWithoutDate: [],
    paginationDataTurnsWithoutDate: {
      page: 1,
      limit: 30,
    },
    isLoading: false,
    totals: null,
    selectedCalendarDate: null,
    selectedEntity: null,
  },
  reducers: {
    FETCH_TURNS: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.allTurns = payload
      } else {
        state.allTurns = payload.allTurns
        state.paginationData = payload.paginationData
        state.isLoading = false
      }
    },
    FETCH_TURNS_SELECTED_DATE: (state, { payload }) => {
      state.allTurns = [...(state?.allTurns || []), ...payload]
    },
    FETCH_TURNS_FROM_WEEK: (state, { payload }) => {
      state.allTurns = payload
    },
    SELECTED_TURN_UPDATED: (state, { payload }) => {
      state.selectedTurn = payload
    },
    GET_PATIENT_CENTRES_TURNS: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.patientCentresTurns = payload
      } else {
        state.patientCentresTurns = payload.patientCentreTurns
        state.paginationData = payload.paginationData
      }
    },
    TURN_PATIENT_CENTRE_UPDATED: (state, { payload }) => {
      state.patientCentresTurns = state.patientCentresTurns.map((turn) => {
        if (turn._id === payload._id) {
          turn = payload
        }
        return turn
      })
    },
    CLEAN_PATIENT_CENTRE_TURNS: (state, { payload }) => {
      state.patientCentresTurns = null
    },
    TURN_CENTRE_UPDATED: (state, { payload }) => {
      state.allTurns = state.allTurns.map((turn) => {
        if (turn._id === payload._id) {
          turn = payload
        }
        return turn
      })
    },
    NEW_TURN_CREATED: (state, { payload }) => {
      const turnFound = state?.allTurns?.find((turn) => turn?._id === payload._id)

      if (!turnFound) {
        state.allTurns = [...(state.allTurns || []), payload]
      }
    },
    REMOVE_TURN_BY_ID: (state, { payload }) => {
      state.allTurns = state.allTurns.filter((e) => e._id !== payload)
      state.patientCentresTurns = state?.patientCentresTurns?.filter((e) => e?._id !== payload)
    },
    SELECTED_ONLINE_TURN: (state, { payload }) => {
      state.onLineTurns = payload
      state.onLineTurns.patientCentres = null
      state.onLineTurns.confirmedTurn = null
      state.onLineTurns.selectedInsurance = null
    },
    SELECTED_ONLINE_TURN_PATIENT: (state, { payload }) => {
      state.onLineTurns.patientCentres = payload
    },
    SELECTED_ONLINE_TURN_INSURANCE: (state, { payload }) => {
      state.onLineTurns.selectedInsurance = payload
    },
    SELECTED_ONLINE_TURN_FINAL_AMOUNT: (state, { payload }) => {
      state.onLineTurns.turnFinalAmount = payload
    },
    SET_ONLINE_TURN_DETAIL: (state, { payload }) => {
      state.onLineTurns.turnDetail = payload
    },
    SELECTED_ONLINE_TURN_CONFIRM: (state, { payload }) => {
      state.onLineTurns.confirmedTurn = payload
    },
    SET_DATA_TURN_TREATMENT: (state, { payload }) => {
      state.treatmentTurn = payload
    },
    SELECTED_ONLINE_EXTRA_SERVICES: (state, { payload }) => {
      state.onLineTurns.extraServices = payload
    },
    SELECTED_CALENDAR_DATE: (state, { payload }) => {
      state.selectedCalendarDate = payload
    },
    RESET_TURN_TREATMENT: (state) => {
      state.treatmentTurn = null
    },
    GET_TURNS_WITHOUT_DATE: (state, { payload }) => {
      state.turnsWithoutDate = payload.turnsWithoutDate
      state.paginationDataTurnsWithoutDate = payload.paginationData
    },
    REMOVE_TURN_WITHOUT_DATE: (state, { payload }) => {
      state.turnsWithoutDate = state.turnsWithoutDate.filter((e) => e._id !== payload)
    },
    RESET_TURNS_WITHOUT_DATE: (state) => {
      state.turnsWithoutDate = []
      state.paginationDataTurnsWithoutDate = { page: 1, limit: 30 }
    },
    SET_DAYS_MONTH: (state, { payload }) => {
      state.daysMonthLoaded = payload
    },
    RESET_DAYS_MONTH: (state) => {
      state.daysMonthLoaded = []
    },
    TURNS_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    TURNS_TOTALS: (state, { payload }) => {
      state.totals = payload
    },
    RESET_ONLINE_TURNS: (state) => {
      state.onLineTurns.turnData = null
      state.onLineTurns.entityData = null
      state.onLineTurns.centreTargetProfile = null
      state.onLineTurns.day = null
      state.onLineTurns.patientCentres = null
      state.onLineTurns.selectedService = null
      state.onLineTurns.selectedInsurance = null
      state.onLineTurns.confirmedTurn = null
      state.onLineTurns.extraServices = []
      state.onLineTurns.turnDetail = null
      state.onLineTurns.turnFinalAmount = null
    },
    SELECTED_ENTITY: (state, { payload }) => {
      state.selectedEntity = payload
    },
  },
})

export const {
  FETCH_TURNS,
  TURN_CENTRE_UPDATED,
  NEW_TURN_CREATED,
  GET_PATIENT_CENTRES_TURNS,
  REMOVE_TURN_BY_ID,
  TURN_PATIENT_CENTRE_UPDATED,
  CLEAN_PATIENT_CENTRE_TURNS,
  SELECTED_ONLINE_TURN,
  SELECTED_ONLINE_TURN_PATIENT,
  SELECTED_ONLINE_TURN_INSURANCE,
  SELECTED_ONLINE_TURN_CONFIRM,
  SELECTED_CALENDAR_DATE,
  SET_DATA_TURN_TREATMENT,
  RESET_TURN_TREATMENT,
  GET_TURNS_WITHOUT_DATE,
  REMOVE_TURN_WITHOUT_DATE,
  FETCH_TURNS_SELECTED_DATE,
  SET_DAYS_MONTH,
  FETCH_TURNS_FROM_WEEK,
  RESET_DAYS_MONTH,
  SELECTED_TURN_UPDATED,
  SELECTED_ONLINE_EXTRA_SERVICES,
  TURNS_LOADING,
  TURNS_TOTALS,
  RESET_ONLINE_TURNS,
  SET_ONLINE_TURN_ID,
  SELECTED_ENTITY,
  SET_ONLINE_TURN_DETAIL,
  SELECTED_ONLINE_TURN_FINAL_AMOUNT,
} = slice.actions

export default slice.reducer
