import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useUser } from '@/context/UserProvider'

const useValidateURLId = () => {
  const { id: urlId } = useParams()
  const navigate = useNavigate()
  const { user } = useUser()

  const getSelectedEntityInLocalStorage = localStorage.getItem('selectedEntity')
  const entityInLocalStorage = JSON.parse(getSelectedEntityInLocalStorage)
  const { doctorCentreId } = entityInLocalStorage

  const isValidCentre = urlId && urlId === entityInLocalStorage?._id
  const isValidDoctor =
    user.roles.includes('doctor') &&
    user.doctorCentres.map((e) => e._id).includes(doctorCentreId) &&
    urlId === doctorCentreId
      ? true
      : false

  const [centreId, setCentreId] = useState(entityInLocalStorage?._id)

  useEffect(() => {
    if (!entityInLocalStorage?._id) window.location.href = '/'
    if (!isValidCentre && !isValidDoctor) navigate('/no/existe')

    if (isValidCentre) setCentreId(entityInLocalStorage?._id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlId, entityInLocalStorage])

  return centreId
}

export default useValidateURLId
