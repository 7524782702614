import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import SelectWithInput from '@/components/SelectWithInput'
import TitlePopUp from '@/components/TitlePopUp'
import {
  getCentreTargetAvailabilities,
  getEntityAvailability,
  resetCentreTargetAvailabilities,
} from '@/store/centreAvailability/actions'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Grid } from '@mui/material'

import AvailableTurnsList from '../avaiableTurnsList'

import s from './styles.module.scss'

const AddSessionPopUp = ({ open, setOpen, centre, treatment }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const doctorCentres = useSelector((state) => state.centreAvailability?.centreData?.doctorCentres)
  const machines = useSelector((state) => state.centreAvailability?.centreData?.machines)

  const { centreData, consultations, practices } = useSelector((state) => state.centreAvailability)
  const defaultConsultations = useSelector((state) => state.centreAvailability?.consultations?.all)
  const allPractices = useSelector((state) => state.centreAvailability?.practices?.all)
  const [filteredEntities, setFilteredEntities] = useState([])
  const [loadingAvailabilities, setLoadingAvailabilities] = useState(false)

  const [serviceId, setServiceId] = useState('consultation')
  const [serviceSelected, setServiceSelected] = useState({})
  const [selectedEntity, setSelectedEntity] = useState('-')
  const [filteredEntity, setFilteredEntity] = useState('')
  const [searchingFor, setSearchingFor] = useState(null)

  let allServices
  if (defaultConsultations) {
    allServices = [defaultConsultations[0], defaultConsultations[1], ...allPractices]
  }

  useEffect(() => {
    const data = {
      showAll: true,
    }
    dispatch(getCentreTargetAvailabilities(centre._id, data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!centreData) return

    const isConsultation = serviceId === 'consultation' || serviceId === 'firstConsultation'
    let entityIds
    if (isConsultation) {
      entityIds = Object.keys(consultations[serviceId]['availability'])
    } else {
      entityIds = Object.keys(practices[serviceId]['availability'])
    }
    let entities = entityIds.map((id) => {
      const entity = doctorCentres[id] || machines[id]
      return entity
    })
    setFilteredEntities(entities)
    if (serviceId === 'consultation') {
      setSearchingFor('consultations')
      setServiceSelected({ _id: 'consultation', name: 'Consulta' })
    } else if (serviceId === 'firstConsultation') {
      setSearchingFor('consultations')
      setServiceSelected({ _id: 'firstConsultation', name: 'Primera consulta' })
    } else {
      setSearchingFor('practices')
      let serviceData = allServices.filter((service) => service._id === serviceId)?.[0]
      setServiceSelected(serviceData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId, centreData])

  useEffect(() => {
    const showEntityAvailabilities = async () => {
      if (selectedEntity !== '-') {
        setLoadingAvailabilities(true)
        const arrayDoctorCentres = Object.values(doctorCentres)
        const arrayMachines = Object.values(machines)
        let entityFiltered
        let entityType

        arrayDoctorCentres?.filter((doctor) => {
          if (doctor._id === selectedEntity) {
            entityFiltered = doctor
            entityType = 'doctorCentre'
          }
          return false
        })
        arrayMachines?.filter((machine) => {
          if (machine._id === selectedEntity) {
            entityFiltered = machine
            entityType = 'machine'
          }
          return false
        })

        setFilteredEntity(entityFiltered)
        const dataToGetEntityAvailability = {
          entityId: selectedEntity,
          entityType: entityType,
          selectedService: serviceSelected,
          showAll: true,
        }

        await dispatch(getEntityAvailability(centre._id, dataToGetEntityAvailability))
        setLoadingAvailabilities(false)
      }
    }
    showEntityAvailabilities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntity])

  const handleClose = () => {
    setOpen(false)
    dispatch(resetCentreTargetAvailabilities())
  }

  return (
    <Dialog disableEscapeKeyDown={true} open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
      {filteredEntities ? (
        <>
          <DialogTitle id="scroll-dialog-title" className={s.dialogTitle}>
            <TitlePopUp textTitle={`Creación de sesión de grupo de sesiones`} handleClose={handleClose} />
          </DialogTitle>
          <DialogContent>
            <Grid container className={s.containerContent}>
              <h5>Selecciona el tipo de sesión y profesional / equipo</h5>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <SelectWithInput
                    inputLabel="Tipo de sesión"
                    valueSelect={serviceId}
                    onChange={(e) => setServiceId(e.target.value)}
                    options={allServices}
                    firstOptionValue="Consultation"
                    firstOptionText="Seleccione una opción"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectWithInput
                    inputLabel="Profesional/agenda"
                    valueSelect={selectedEntity}
                    onChange={(e) => setSelectedEntity(e.target.value)}
                    options={filteredEntities.sort()}
                    firstOptionValue="-"
                    firstOptionText="-"
                  />
                </Grid>
              </Grid>
              {loadingAvailabilities ? (
                <LoaderCircle text={`Buscando ${t('turnLabel.p')} disponibles`} />
              ) : (
                <AvailableTurnsList
                  entity={filteredEntity}
                  service={serviceSelected}
                  searchingFor={searchingFor}
                  centre={centre}
                  treatment={treatment}
                  setOpenPopUp={setOpen}
                />
              )}
            </Grid>
          </DialogContent>
        </>
      ) : (
        <LoaderCircle title="Cargando..." />
      )}
    </Dialog>
  )
}

export default AddSessionPopUp
