import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import LoadingSaveButton from '@/components/LoadingSaveButton'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'

import s from '../../styles.module.scss'

const SaveButtonTurn = (props) => {
  const [loadingButton, setLoadingButton] = useState(false)
  const copyTurn = useSelector((state) => state.copyTurn.selectedTurnId)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { t } = useTranslation('global')
  const watchLastname = props.watch('lastname')
  const watchFirstname = props.watch('firstname')
  const watchMobile = props.watch('mobile')
  const watchTelephone = props.watch('telephone')

  useEffect(() => {
    if (!props.entity.selectedDate) {
      verifyCompletedObligationFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchLastname, watchFirstname, watchMobile, watchTelephone])

  const verifyCompletedObligationFields = () => {
    if (watchLastname && watchFirstname && (watchMobile || watchTelephone)) return true
    return false
  }

  const triggerSubmitWhatsappLink = () => {
    mxp.track(mxp.events.agenda.noDateTurn.created)
    props.refSubmitButtonSendWhatsapp?.current.click()
    setLoadingButton(true)
  }

  const triggerSubmit = () => {
    mxp.track(mxp.events.agenda.newTurn.created)
    props.refSubmitButton?.current?.click()
  }

  const handlePasteTurn = () => {
    mxp.track(mxp.events.agenda.newTurn.turnPasted)
    props.setCopiedTurnValue(copyTurn)
  }
  return (
    <div className={copyTurn ? s.buttonsContainer1 : s.buttonsContainer2}>
      {copyTurn && (
        <div>
          <Button className={s.buttonMore} variant="contained" onClick={handlePasteTurn} size="small">
            Pegar {t('turnLabel.s')}
          </Button>
        </div>
      )}
      <div className={s.divButtonsItem}>
        {!props?.showAllForm && (
          <Button
            type="button"
            className={s.buttonMore}
            variant="contained"
            onClick={props.scrollToSection}
            size="small"
          >
            Ver más
          </Button>
        )}

        <div>
          {props.entity.selectedDate !== undefined ? (
            <LoadingSaveButton
              isLoading={props.isLoading}
              textButton={`Guardar ${t('turnLabel.s')}`}
              onClick={triggerSubmit}
              className={s.buttonSave}
              size="small"
            />
          ) : (
            <>
              {(props.patientSelected || verifyCompletedObligationFields()) && !props.entity.selectedDate ? (
                <LoadingButton
                  className={s.buttonSave}
                  variant="contained"
                  size={'small'}
                  onClick={triggerSubmitWhatsappLink}
                  loading={loadingButton}
                >
                  Enviar link al {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}
                </LoadingButton>
              ) : (
                <Button className={s.disabledButton} disabled variant="contained" size={'small'}>
                  Enviar link al {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default SaveButtonTurn
