import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPatientSelected } from '@/store/patients/actions'
import { handlePaginationChangePatients } from '@/store/patients/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import dayjs from 'dayjs'

import s from '../../styles.module.scss'

const AdminPatientsTable = ({ setOpen, searchText }) => {
  const dispatch = useDispatch()
  const [rowId, setRowId] = useState('')
  const { allPatients, paginationData, isLoading } = useSelector((state) => state.patients)
  const { limit, totalDocs, page } = paginationData || {}

  const handleClick = async (data) => {
    if (data) {
      await dispatch(setPatientSelected(data._id))
      setOpen(true)
    }
  }

  const handleCellClick = (params, event) => {
    handleClick(params?.row)
  }

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  }

  const columns = [
    {
      field: 'dni',
      headerName: 'DNI',
      minWidth: 160,
      flex: 1,
    },

    {
      field: 'lastname',
      headerName: 'Apellido',
      minWidth: 110,
      maxWidth: 150,
      ...stylesColumns,
    },
    {
      field: 'firstname',
      headerName: 'Nombre',
      minWidth: 160,
      ...stylesColumns,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'creationDate',
      headerName: 'Fecha de creación',
      minWidth: 120,
      ...stylesColumns,
    },
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 160,
      flex: 1,
    },
    {
      field: 'raw',
      headerName: '',
      minWidth: 150,
      maxWidth: 180,
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title="Eliminar" placement="top">
          <Button
            onClick={() => handleClick(params?.value)}
            className={rowId === params.row.id ? s.rowHoverButton : s.rowButton}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      ),
    },
  ]

  const rows = allPatients?.map((patient) => {
    return {
      _id: patient?._id,
      id: patient?._id,
      dni: patient?.dni,
      firstname: capitalizeFirstLetters(patient?.firstname),
      lastname: capitalizeFirstLetters(patient?.lastname),
      email: patient?.email,
      creationDate: dayjs(patient?.creationDate).format('YYYY-MM-DD'),
    }
  })

  const handleRowOver = (e) => {
    const rowId = e.currentTarget.dataset.id
    const row = rows.find((el) => el.id === rowId)
    setRowId(row._id)
  }

  const handlePaginationChange = ({ page, pageSize }) => {
    dispatch(handlePaginationChangePatients(page + 1, pageSize, searchText))
  }

  return (
    <Box
      sx={{
        px: 0,
        '& .header': {
          fontSize: '14px',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
          background: '#fff',
        },
      }}
    >
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: limit,
            },
          },
        }}
        rows={rows || []}
        rowCount={totalDocs || 0}
        loading={isLoading}
        paginationMode="server"
        columns={columns}
        pagination
        onCellClick={(params, event) => handleCellClick(params, event)}
        onPaginationModelChange={handlePaginationChange}
        pageSizeOptions={[30, 50, 100]}
        disableRowSelectionOnClick={true}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        slotProps={{
          pagination: {
            labelRowsPerPage: 'Entradas por página:',
            labelDisplayedRows: () =>
              `${(page - 1) * limit + 1} - ${page * limit > totalDocs ? totalDocs : page * limit} de ${totalDocs}`,
          },
          row: {
            onMouseEnter: handleRowOver,
          },
        }}
        sx={{
          fontSize: '13px',
          background: '#fff',
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell ': {
            display: 'flex',
            justifyContent: 'flex-start',
          },
        }}
        rowHeight={45}
        autoHeight={true}
      />
    </Box>
  )
}

export default AdminPatientsTable
