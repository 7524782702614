import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchServices = async (params) => {
  const route = `api/services`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewService = async (data) => {
  const route = `api/services`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteService = async (id) => {
  const route = `api/services/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putService = async (data) => {
  const route = `api/services`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchSingleService = async (id) => {
  const route = `api/services/${id}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { addNewService, deleteService, fetchServices, fetchSingleService, putService }
