import {
  deletePatientTreatment,
  deleteTemplateTreatment,
  getPatientTreatments,
  getSingleTemplateTreatment,
  getTemplateTreatments,
  postPatientTreatment,
  postTemplateTreatment,
  putPatientTreatment,
  putTemplateTreatment,
  treatmentReport,
} from '@/services/treatments'
import { showToast } from '@/utils/toast'

import {
  CREAT_NEW_TREATMENT,
  DELETE_PATIENT_TREATMENT,
  DELETE_TREATMENT,
  GET_ALL_TREATMENTS,
  GET_PATIENT_TREATMENTS,
  GET_SINGLE_TREATMENT,
  NEW_PATIENT_TREATMENT,
  RESET_SINGLE_TREATMENT,
  UPDATE_PATIENT_TREATMENT,
  UPDATE_TREATMENT,
} from '.'

const getAllCentreTreatments = (centreId) => async (dispatch) => {
  try {
    const fetchAllTurns = await getTemplateTreatments(centreId)
    dispatch(GET_ALL_TREATMENTS(fetchAllTurns))
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const selectedCentreTreatment = (treatmentId) => async (dispatch) => {
  try {
    const singleTreatment = await getSingleTemplateTreatment(treatmentId)
    dispatch(GET_SINGLE_TREATMENT(singleTreatment))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetTreatmentSelected = () => (dispatch) => {
  try {
    dispatch(RESET_SINGLE_TREATMENT())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const createNewTemplateTreatment = (newTreatment) => async (dispatch) => {
  try {
    const newTreatmentCreated = await postTemplateTreatment(newTreatment)
    dispatch(CREAT_NEW_TREATMENT(newTreatmentCreated))
    showToast('El grupo de sesiones se ha creado con éxito', 'success', 5000)
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const updateTemplateTreatment = (updatedTreatment) => async (dispatch) => {
  try {
    const updatedTreatmentCreated = await putTemplateTreatment(updatedTreatment)
    dispatch(UPDATE_TREATMENT(updatedTreatmentCreated))
    showToast('El grupo de sesiones se ha actualizado con éxito', 'success', 5000)
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const removeTemplateTreatment = (treatmentId) => async (dispatch) => {
  try {
    await deleteTemplateTreatment(treatmentId)
    dispatch(DELETE_TREATMENT(treatmentId))
    showToast('El grupo de sesiones se ha eliminado con éxito', 'success', 5000)
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const getAllPatientTreatments = (centreId, patientId) => async (dispatch) => {
  try {
    const fetchAllPatientTreatments = await getPatientTreatments(centreId, patientId)
    dispatch(GET_PATIENT_TREATMENTS(fetchAllPatientTreatments))
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const createNewPatientTreatment = (newTreatment) => async (dispatch) => {
  const patientCentre = newTreatment.patientCentre

  try {
    const newTreatmentCreated = await postPatientTreatment(newTreatment)
    newTreatmentCreated.patientCentre = patientCentre
    dispatch(NEW_PATIENT_TREATMENT(newTreatmentCreated))
    showToast('El grupo de sesiones se ha creado con éxito', 'success', 5000)
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const removePatientTreatment = (treatmentId) => async (dispatch) => {
  try {
    await deletePatientTreatment(treatmentId)
    dispatch(DELETE_PATIENT_TREATMENT(treatmentId))
    showToast('El grupo de sesiones se ha eliminado con éxito', 'success', 5000)
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const updatePatientTreatment = (patientTreatment, hideAlert) => async (dispatch) => {
  const turns = patientTreatment.turns
  const patientCentre = patientTreatment.patientCentre

  try {
    const updatedPatientTreatment = await putPatientTreatment(patientTreatment)
    updatedPatientTreatment.turns = turns
    updatedPatientTreatment.patientCentre = patientCentre
    dispatch(UPDATE_PATIENT_TREATMENT(updatedPatientTreatment))
    if (!hideAlert) {
      showToast('El grupo de sesiones se ha actualizado con éxito', 'success', 5000)
    }
    return updatedPatientTreatment
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}

const sendReportTreatmentByEmail = (treatment) => async (dispatch) => {
  try {
    await treatmentReport(treatment)
    showToast('El reporte del grupo de sesiones se ha enviado con éxito', 'success', 5000)
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('upps! Algo salió mal, intenta nuevamente', 'error', 5000)
  }
}
export {
  createNewPatientTreatment,
  createNewTemplateTreatment,
  getAllCentreTreatments,
  getAllPatientTreatments,
  removePatientTreatment,
  removeTemplateTreatment,
  resetTreatmentSelected,
  selectedCentreTreatment,
  sendReportTreatmentByEmail,
  updatePatientTreatment,
  updateTemplateTreatment,
}
