import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { Grid } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const MesaggeConfirmedSetDateTurn = () => {
  const singleTurn = useSelector((state) => state.turns.selectedTurn)
  const { t } = useTranslation('global')

  const showDateTurn = () => {
    const date = dayjs(singleTurn?.startHour || undefined)
      .utc()
      .format('dddd D MMMM')
    return date
  }

  const showHourTurn = () => {
    const hourTurn = dayjs(singleTurn?.startHour || undefined)
      .utc()
      .format('HH:mm')
    return hourTurn
  }

  return (
    <>
      {singleTurn ? (
        <div className={s.paperContainer}>
          <Grid className={s.title}>
            <i className={s.colorIcon}>
              <TaskAltIcon className={s.checkIcon} />
            </i>

            <h3>{t('turnLabel.S')} Confirmada!</h3>
          </Grid>
          <Grid>
            <h4>{`${showDateTurn()} a las ${showHourTurn()} hs`}</h4>
          </Grid>

          <p>Recibirás un email con los detalles {t('turnLabel.sd')}</p>
        </div>
      ) : null}
    </>
  )
}

export default MesaggeConfirmedSetDateTurn
