import React from 'react'
import { useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import { Grid, Tooltip } from '@mui/material'

import s from './styles.module.scss'

const ButtonContainer = ({ addNewFragment }) => {
  const { allTemplates } = useSelector((state) => state.wappMessageTemplates)

  return (
    <Grid container className={s.mainContainer}>
      <Tooltip title={allTemplates?.length > 0 ? 'No se puede crear plantilla, debe editar la existente' : ''}>
        <Grid item xs={12} md={4} className={s.containerButtonNewEntry}>
          <CaluButton
            color="primary"
            type="add"
            size="large"
            onClick={addNewFragment}
            disabled={allTemplates?.length > 0}
          >
            Crear plantilla personalizada
          </CaluButton>
        </Grid>
      </Tooltip>
    </Grid>
  )
}

export default ButtonContainer
