import React from 'react'
import { useTranslation } from 'react-i18next'
import { SwitchField } from '@/components/Form/customFields/SwitchField'
import CaluButton from '@/components/styledComponents/CaluButton'
import { Grid } from '@mui/material'

import s from '../../styles.module.scss'

export const FormContent = ({ isLoading = false }) => {
  const { t } = useTranslation('global')

  return (
    <div>
      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        <Grid item xs={12}>
          <h5>Preferencias</h5>
        </Grid>
        <Grid item xs={12}>
          <SwitchField
            label={`${t('turnLabel.PA')} ausentes generan deudas en el módulo de Gestión`}
            name="hideAbsentTurnsFromCashbox"
            isCheckedInverted
          />
        </Grid>
      </Grid>
      <div className={s.containerBtnSaveCashOptions}>
        <CaluButton color="primary" type="submit" size="medium" loading={isLoading}>
          Guardar
        </CaluButton>
      </div>
    </div>
  )
}
