export const reducePayload = (subscriptionPayment) => {
  if (!subscriptionPayment) return {}
  // to force deep copy
  let auxSubscriptionPayment = JSON.parse(JSON.stringify(subscriptionPayment))

  // reduce subscriptionPayment.logs
  if (auxSubscriptionPayment?.logs?.length > 100) {
    auxSubscriptionPayment.logs = auxSubscriptionPayment?.logs?.slice(-100)
  }

  return auxSubscriptionPayment
}
