import { useState } from 'react'
import Layout1 from '@/layouts/Layout1'
import { Grid } from '@mui/material'

import PendingPayment from './components/PendingPayment'

import s from './styles.module.scss'

const PendingPaymentPage = () => {
  const [isLoading] = useState(false)

  return (
    <Layout1 navbar isLoading={isLoading}>
      <Grid className={s.mainContainer}>
        <PendingPayment />
      </Grid>
    </Layout1>
  )
}

export default PendingPaymentPage
