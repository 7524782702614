import * as React from 'react'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormEditService from './FormEditService'

const PopUpEditService = ({ open, setOpen, selectedService }) => {
  const handleClose = () => {
    setOpen(!open)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="sm" fullWidth open={open} onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle={'Editar profesional'} handleClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <FormEditService formValues={selectedService} onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpEditService
