import React from 'react'
import { Navigate } from 'react-router-dom'
import LoadingCalu from '@/components/LoadingCalu'
import { useUser } from '@/context/UserProvider'

const CustomRoute = (props) => {
  const { user } = useUser()
  if (user === null) return <LoadingCalu />
  if (!!props.auth && user === false) return <Navigate to="/" />
  if (props.roles?.length > 0 && !props.roles.some((rol) => user?.roles?.includes(rol))) return <Navigate to="/" />

  return <props.component />
}

export default CustomRoute
