export const getChedocClassWithState = (state) => {
  if (state === 'init') return 'chedoc-b-bg'
  if (state === 'finished') return 'chedoc-c-bg'
  if (state === 'confirmed') return 'chedoc-a-bg'
  if (state === 'arrived') return 'chedoc-d-bg'
  if (state === 'happening' || state === 'active-video') return 'chedoc-g-bg'
  if (state === 'absent') return 'chedoc-e-bg'
  if (state === 'cancelled') return 'chedoc-e-bg'
  if (state === 'turn-patient-deleted') return 'chedoc-turn-patient-deleted'
  if (state === 'cancelled-turn') return 'chedoc-e-bg'
  if (state === 'pending') return 'chedoc-c-bg'
  return ''
}
