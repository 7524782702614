import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'copyTurn',
  initialState: {
    selectedTurnId: null,
  },
  reducers: {
    SAVE_TURN_ID: (state, { payload }) => {
      state.selectedTurnId = payload
    },
  },
})

export const { SAVE_TURN_ID } = slice.actions
export default slice.reducer
