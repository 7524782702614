import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import CaluButton from '@/components/styledComponents/CaluButton'
import { getEntityAvailability } from '@/store/centreAvailability/actions'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { Grid } from '@mui/material'

import AvailableTurnsList from './components/availableTurnsList'
import CardInformation from './components/cardInformation'

import s from './styles.module.scss'

const CardDoctorCentreTarget = ({
  entity,
  service,
  searchingFor,
  allServices,
  setSelectedService,
  selectedInsurance,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const centreId = useParams().id

  const [isLoading, setIsLoading] = useState(false)

  const entityAvailability = useSelector(
    (state) => state.centreAvailability?.[searchingFor]?.[service[0]?._id]?.availability?.[entity?._id],
  )

  const handleShowTurns = async () => {
    mxp.track(mxp.events.centreLanding.agenda.availabilityRequested)
    setIsLoading(true)
    const auxData = {
      entityId: entity._id,
      entityType: entity.name ? 'machine' : 'doctorCentre',
      selectedService: service,
    }
    await dispatch(getEntityAvailability(centreId, auxData))
    setIsLoading(false)
  }

  return (
    <Grid container className={s.containerCard} key={entity?._id}>
      <Grid item xs={12} md={6}>
        <CardInformation entity={entity} allServices={allServices} setSelectedService={setSelectedService} />
      </Grid>

      <Grid item xs={12} md={6} className={s.itemTurns}>
        {!service[0] ? (
          <p className={s.noServiceSelectedYet}>Seleccione un servicio para ver {t('turnLabel.pa')} disponibles</p>
        ) : !entityAvailability?.loaded ? (
          <CaluButton type="link" size="large" loading={isLoading} disabled={isLoading} onClick={handleShowTurns}>
            Ver {t('turnLabel.p')} disponibles
          </CaluButton>
        ) : entityAvailability?.availableDays.length ? (
          <AvailableTurnsList
            entityAvailability={entityAvailability}
            entity={entity}
            selectedService={service[0]}
            allServices={allServices}
            selectedInsurance={selectedInsurance}
          />
        ) : (
          <div className={s.info}>
            <b>Sin {t('turnLabel.p')} disponibles</b>
            <div> Contáctate con el centro de salud</div>
          </div>
        )}
      </Grid>
    </Grid>
  )
}

export default CardDoctorCentreTarget
