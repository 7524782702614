import { useCallback, useEffect, useMemo, useState } from 'react'
import Joyride, { STATUS } from 'react-joyride'

import Swal from 'sweetalert2'

const joyrideStyles = {
  options: {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    beaconSize: 36,
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    primaryColor: '#1ab395',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    textColor: '#333',
    zIndex: 100,
  },
  tooltip: {
    placement: 'auto',
    padding: '1.25rem',
    maxWidth: 'calc(100vh/13.75rem)',
    maxHeight: 'calc(100vw/16.875rem)',
    fontSize: '15px',
  },
  tooltipTitle: {
    fontSize: '16px',
  },
}

export default function useTour(steps, localStorageKey) {
  const [run, setRun] = useState(false)

  useEffect(
    function () {
      if (!localStorageKey) {
        setRun(true)
        return
      }
      const tourViewed = window.localStorage.getItem(localStorageKey)
      if (tourViewed) {
        return
      }
      window.localStorage.setItem(localStorageKey, '1')
      setRun(true)
    },
    [localStorageKey],
  )

  const handleJoyrideCallback = useCallback(function (data) {
    const { status } = data
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]

    if (finishedStatuses.includes(status)) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Tour finalizado',
        text: 'Bienvenido a Calu!',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [])

  const tour = useMemo(
    () => (
      <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        run={run}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={steps}
        styles={joyrideStyles}
        locale={{ back: 'Atrás', next: 'Siguiente', skip: 'Saltar', close: 'Cerrar', last: 'Finalizar' }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [steps, handleJoyrideCallback, run],
  )

  return tour
}
