import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const getPatientTags = async (centre) => {
  const route = `api/patientTags`
  const params = { centre }
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postNewPatientTag = async (patientTag) => {
  const route = `api/patientTags`
  return API.post(route, patientTag)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putPatientTag = async (patientTag) => {
  const route = `api/patientTags`
  return API.put(route, patientTag)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deletePatientTag = async (patientTagId) => {
  const route = `api/patientTags/${patientTagId}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { deletePatientTag, getPatientTags, postNewPatientTag, putPatientTag }
