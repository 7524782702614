import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { postNewHCFragment, updateHCFragment } from '@/store/clinicHistoryFragments/actions'
import { showToast } from '@/utils/toast'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const FormCreateHCFragment = ({ onClose, selectedFragment, entityId, doctorCentreId, isDoctor }) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const { allHCFragments } = useSelector((state) => state.clinicHistoryFragments)

  const handleSubmit = async (data) => {
    setIsLoading(true)

    let error = false

    if (isDoctor) {
      data.doctorCentre = doctorCentreId
    }

    if (!isDoctor) {
      data.centre = entityId
    }

    if (!selectedFragment) {
      allHCFragments.forEach((fragment) => {
        if (fragment?.name === data.name) {
          error = true
          return
        }
      })
    }

    try {
      if (error) {
        showToast('Ya existe un fragmento con el mismo nombre', 'error')
        return
      }

      if (selectedFragment) {
        dispatch(updateHCFragment(data))
      } else {
        dispatch(postNewHCFragment(data))
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <div>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={selectedFragment || formDefaultValues}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent onClose={onClose} submitLoading={isLoading} selectedFragment={selectedFragment} />
      </ContainerForm>
    </div>
  )
}

export default FormCreateHCFragment
