import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CaluButton from '@/components/styledComponents/CaluButton'
import TitlePopUp from '@/components/TitlePopUp'
import { SELECTED_ONLINE_TURN_PATIENT, SET_ONLINE_TURN_DETAIL } from '@/store/turns'
import { removeTurnById, selectedOnLineTurn } from '@/store/turns/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'

import dayjs from 'dayjs'

import s from '../restorePendingTurnPopUp/styles.module.scss'

const RestorePendingTurnPopUp = ({ open, setOpen, pendingTurnData, centreId, centreData }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('global')

  const [removingTurnLoader, setRemovingTurnLoader] = useState(false)

  const { centreTarget: centre } = useSelector((state) => state.centres)
  const { patientCentresTurns } = useSelector((state) => state.turns)

  const handleClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return
    setRemovingTurnLoader(true)
    await dispatch(removeTurnById(patientCentresTurns?.[0]?._id, true))
    setRemovingTurnLoader(false)
    setOpen(!open)
  }

  const restorePendingTurn = async (pendingTurnData) => {
    const startHourTurn = toCustomTz(pendingTurnData?.startHour, undefined, true, 'HH:mm')
    const finishHourTurn = toCustomTz(pendingTurnData?.finishHour, undefined, true, 'HH:mm')
    const durationTurn = dayjs(pendingTurnData?.finishHour).diff(dayjs(pendingTurnData?.startHour), 'minutes')
    const turnData = {
      startHour: startHourTurn, // 09:00
      finishHour: finishHourTurn, // 09:20
      turnDuration: durationTurn, // 20
    }

    const day = toCustomTz(pendingTurnData?.startHour, undefined, true, 'YYYYMMDD') //'20230928'
    const selectedService = pendingTurnData?.service // OJO service no llega con toda la info en los turnos
    const extraServices = pendingTurnData?.extraServices // Lo mismo que services

    await dispatch(selectedOnLineTurn(centreData, turnData, day, selectedService, extraServices))
    await dispatch(SET_ONLINE_TURN_DETAIL(pendingTurnData))

    if (pendingTurnData?.patientCentre) {
      await dispatch(SELECTED_ONLINE_TURN_PATIENT(pendingTurnData?.patientCentre))
      navigate(`/turnos/info-turno/${centreId}`)
    }

    navigate(`/turnos/info-paciente/${centreId}`)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={open} onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={`Tiene ${t('turnLabel.sa')} pendiente de confirmación`}
            handleClose={handleClose}
            hideCloseButton={true}
          />
        </DialogTitle>
        <DialogContent className={s.mainContainer}>
          <div className={s.question}>¿ Quiere continuar con la reserva ? </div>
          <div className={s.turnData}>
            <p>
              <span className={s.bold}>Día: </span>{' '}
              {toCustomTz(pendingTurnData?.startHour, undefined, true, 'DD-MM-YYYY')}
            </p>
            <p>
              <span className={s.bold}>Hora: </span> {toCustomTz(pendingTurnData?.startHour, undefined, true, 'HH:mm')}{' '}
              hs.
            </p>
            {pendingTurnData?.doctorCentre && (
              <p>
                <span className={s.bold}>Profesional: </span> {pendingTurnData?.doctorCentre?.lastname?.toUpperCase()}{' '}
                {pendingTurnData?.doctorCentre?.firstname?.toUpperCase()}
              </p>
            )}
            {pendingTurnData?.machine && (
              <p>
                <span className={s.bold}>Equipo: </span> {pendingTurnData?.machine?.name?.toUpperCase()}
              </p>
            )}
            {pendingTurnData?.patientCentre && (
              <p>
                <span className={s.bold}>
                  {t(`subCategoryLabel.${getCentreSubCategory(centre?.category)}.Patient`)}:{' '}
                </span>{' '}
                {pendingTurnData?.patientCentre?.lastname?.toUpperCase()}{' '}
                {pendingTurnData?.patientCentre?.firstname?.toUpperCase()}
              </p>
            )}
            <p>
              <span className={s.bold}>Precio: </span> {handleSetMoneySign(centre?.country)} {pendingTurnData?.amount}
            </p>
          </div>
          <div className={s.containerBtns}>
            <CaluButton color="error" size="medium" onClick={handleClose} loading={removingTurnLoader}>
              Cancelar reserva
            </CaluButton>
            <CaluButton color="primary" size="medium" onClick={() => restorePendingTurn(pendingTurnData)}>
              Continuar reserva
            </CaluButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default RestorePendingTurnPopUp
