import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import useDebounce from '@/hooks/useDebounce'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

const AutoCompleteChedoc = ({
  name,
  multiple,
  stateRedux,
  inputLabel,
  control,
  setValue,
  className,
  functionToDispatch,
  getAllInsurances = false,
  noPaginate = false,
}) => {
  const dispatch = useDispatch()
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  const DELAY_TIME = 300
  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)

  useEffect(() => {
    searchEntity()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, inputDebounceValue])

  const searchEntity = async () => {
    const params = {
      search: inputDebounceValue,
    }

    if (getAllInsurances) {
      params.getAllInsurances = true
    }
    if (noPaginate) {
      params.noPaginate = true
    }

    setLoading(true)
    await dispatch(functionToDispatch(params))
    setLoading(false)
  }

  const getReduxStateOptions = (stateRedux) => {
    if (name === 'insurance' || name === 'insurances') {
      let auxStateRedux = [{ name: 'Buscar obra social...' }, ...stateRedux]
      return auxStateRedux
    }
    return stateRedux
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          id="checkboxes-tags-demo"
          multiple={multiple}
          className={className || ''}
          loading={loading}
          loadingText="Cargando..."
          noOptionsText={!loading ? 'Sin opciones disponibles' : false}
          options={getReduxStateOptions(stateRedux)}
          getOptionDisabled={(option) => option.name === 'Buscar obra social...'}
          disableCloseOnSelect={multiple ? true : false}
          getOptionLabel={(option) => option.name}
          onChange={(event, selectedValues) => {
            setValue(name, selectedValues)
            setSearchText('')
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option?._id}>
              <>
                {multiple ? (
                  <>
                    {option?.name !== 'Buscar obra social...' && (
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    )}

                    {option?.name}
                  </>
                ) : (
                  option.name
                )}
              </>
            </li>
          )}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              className={s.inputWidth}
              variant="standard"
              onChange={(e) => setSearchText(e.target.value)}
              label={inputLabel}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="primary" size={20} className={s.circularStyles} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  )
}

export default AutoCompleteChedoc

AutoCompleteChedoc.propTypes = {
  name: PropTypes.string.isRequired,
  stateRedux: PropTypes.array.isRequired,
  inputLabel: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  functionToDispatch: PropTypes.func.isRequired,
  className: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  setValue: PropTypes.func,
}
