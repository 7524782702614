import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import { IS_LOADING } from '@/store/wappMessageTemplates'
import { getWappMessageTemplates } from '@/store/wappMessageTemplates/actions'

import BirthdayMessageTable from './components/BirthdayMessageTable'
import ButtonContainer from './components/ButtonContainer'
import MainPopUp from './components/MainPopUp'

import s from './styles.module.scss'

const TabBirthDayMessage = () => {
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useState(false)

  const { usersProfileConnected } = useSelector((state) => state.users)
  const { allTemplates, isLoading } = useSelector((state) => state.wappMessageTemplates)

  const { entityInLocalStorage } = usersProfileConnected
  const deviceId = entityInLocalStorage?._id

  const addNewFragment = () => {
    setOpen(!open)
  }

  const setInit = async () => {
    dispatch(IS_LOADING(true))
    const params = {
      centre: deviceId,
    }
    await dispatch(getWappMessageTemplates(params))
    dispatch(IS_LOADING(false))
  }

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className={`${s.animatedContainer} animate__animated animate__fadeIn`}>
        <ButtonContainer addNewFragment={addNewFragment} inputSearch={searchText} setInputSearch={setSearchText} />
        {isLoading ? (
          <LoaderCircle size={50} />
        ) : allTemplates?.length === 0 ? (
          <p className={s.noItems}>No hay mensaje de cumpleaños personalizado aún.</p>
        ) : (
          <BirthdayMessageTable setOpen={setOpen} searchText={searchText} />
        )}
      </div>

      <MainPopUp open={open} setOpen={setOpen} />
    </>
  )
}

export default TabBirthDayMessage
