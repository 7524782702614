import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'services',
  initialState: {
    allServices: [],
    paginationData: {
      page: 1,
      limit: 30,
    },
    serviceSelected: null,
    isLoading: false,
  },
  reducers: {
    FETCH_SERVICES: (state, { payload }) => {
      if (payload?.paginationData) {
        state.allServices = payload.sortServices
        state.paginationData = payload.paginationData
      } else {
        state.allServices = payload
      }
    },
    SERVICE_UPDATED: (state, { payload }) => {
      state.allServices = state.allServices.map((service) => {
        if (service._id === payload._id) {
          service = payload
        }
        return service
      })
      state.serviceSelected = payload
    },
    SERVICE_DELETE: (state, { payload }) => {
      state.allServices = state.allServices.filter((service) => service._id !== payload)
      state.serviceSelected = null
    },
    SERVICE_CREATED: (state, { payload }) => {
      state.allServices = [...(state.allServices || []), payload]
    },
    SERVICE_SELECTED: (state, { payload }) => {
      state.serviceSelected = payload
    },
    RESET_SERVICE_SELECTED: (state, { payload }) => {
      state.serviceSelected = null
    },
    LOADING_SERVICES: (state, { payload }) => {
      state.isLoading = payload
    },
  },
})

export const {
  FETCH_SERVICES,
  SERVICE_UPDATED,
  SERVICE_DELETE,
  SERVICE_CREATED,
  SERVICE_SELECTED,
  RESET_SERVICE_SELECTED,
  LOADING_SERVICES,
} = slice.actions
export default slice.reducer
