import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import useValidateURLId from '@/hooks/useValidateURLId'
import { postInsurancePrice, updateInsurancePrice } from '@/store/insurancePrices/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const FormCreateInsurance = ({ formValues, onClose }) => {
  const dispatch = useDispatch()
  const centreID = useValidateURLId()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmitSuccess = () => {
    onClose()
    setIsLoading(false)
  }

  const handleSubmit = async (data) => {
    setIsLoading(true)
    delete data.raw
    data.centre = centreID

    try {
      const result = formValues ? await dispatch(updateInsurancePrice(data)) : await dispatch(postInsurancePrice(data))

      result && handleSubmitSuccess()
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={{ ...formDefaultValues, ...formValues }}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent isLoading={isLoading} formValues={formValues} onClose={onClose} />
      </ContainerForm>
    </div>
  )
}

export default FormCreateInsurance
