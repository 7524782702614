import logoPDF from '@/assets/images/logo-gray.png'
import i18next from '@/configs/i18next'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { showToast } from '@/utils/toast'

import dayjs from 'dayjs'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

const printTurns = (groupedTurns, selectedCentre) => {
  if (!selectedCentre) {
    showToast('Error al generar impresión de citas', 'error')
    return
  }

  let setPageText
  let pdfDate = dayjs()

  const tableHeader = [
    { header: 'Horario', dataKey: 'schedule' },
    { header: '', dataKey: 'info' },
    { header: 'Paciente', dataKey: 'patient' },
    { header: `${i18next.t('insuranceLabel.S', { ns: 'global' })}`, dataKey: 'insurance' },
    { header: 'Teléfono', dataKey: 'telephone' },
    { header: 'Profesional', dataKey: 'doctorCentre' },
    { header: 'Monto', dataKey: 'amount' },
  ]

  let totalCount = 0
  const fillTableTurns = (turns = []) => {
    let prevTurn = {}

    const turnsBody = turns.map((turn = {}) => {
      const totalPayments = turn?.paymentHistory?.map((e) => e.amount).reduce((a, b) => a + b, 0)
      const startHour = toCustomTz(turn.startHour, 'utc', false, 'HH:mm')
      const schedule = `${startHour} hs`
      const patientCentre = turn.patientCentre

      let info = turn.firstTime ? '[N] ' : turn?.service ? `[ ${turn?.service?.name?.slice(0, 20).toUpperCase()} ]` : ''
      info += dayjs(turn.startHour).isBetween(prevTurn.startHour, prevTurn.finishHour, null, '[)') ? '[S] ' : ''
      prevTurn = turn

      const patient = patientCentre
        ? `${patientCentre?.lastname?.toUpperCase()} ${patientCentre?.firstname?.toUpperCase()}`.slice(0, 30)
        : 'Paciente eliminado'
      const insurance = capitalizeFirstLetters(turn?.insurance?.name).slice(0, 16)
      const telephone = turn?.patientCentre?.mobile || turn?.patientCentre?.telephone || '-'
      const doctorCentre = turn?.doctorCentre
        ? `${capitalizeFirstLetters(turn?.doctorCentre?.lastname)} ${capitalizeFirstLetters(
            turn?.doctorCentre?.firstname,
          )}`.slice(0, 30)
        : '-'
      const amount = totalPayments || '-'
      totalCount += parseInt(totalPayments) || 0

      return { schedule, info, patient, insurance, telephone, doctorCentre, amount }
    })

    return [...turnsBody]
  }

  // const pageData = (turns = {}, index = 0) => {
  const pageData = (entityData = {}, index) => {
    const { turns, calendarName } = entityData
    const numberPage = index + 1
    pdfDate = dayjs(turns[0].startHour).utc(true).hour(0).minute(0)
    // before Table
    doc.setFont('Helvetica', 'bold')
    doc.setTextColor(44, 62, 80)
    doc.setFontSize(12)
    doc.text(selectedCentre.name.toUpperCase(), 40, 50)
    doc.setFont('Helvetica', 'normal')
    doc.text(capitalizeFirstLetters(calendarName), 40, 80)
    doc.text(pdfDate.format('dddd DD-MM-YYYY'), 400, 80)

    // after Table
    doc.text(numberPage.toString(), 575, 830)
    doc.setFontSize(12)
    doc.addImage(logoPDF, 35, 780, 65, 32)
    doc.text('Total: $' + totalCount.toString(), 480, 810)
  }

  const doc = new jsPDF('p', 'pt', 'A4')

  groupedTurns.forEach((entityData, index) => {
    totalCount = 0
    doc.addPage()
    autoTable(doc, {
      theme: 'grid',
      styles: { overflow: 'linebreak' },
      margin: { top: 90 },
      willDrawCell: ({ row }) => {
        doc.setFontSize(8)
        doc.setFont('helvetica', 'normal')
        if (row.section === 'head') {
          doc.setFontSize(10)
          doc.setFillColor(100, 100, 100)
          doc.setFont('helvetica', 'bold')
        }
        if (row.section !== 'head' && row.index % 2 !== 0) {
          doc.setFillColor(245, 247, 251)
          doc.setFont('helvetica', 'normal')
        }
      },
      didDrawCell: () => {
        if (setPageText !== index) {
          setPageText = index
          pageData(entityData, index)
        }
      },
      columns: tableHeader,
      body: fillTableTurns(entityData?.turns),
    })
  })

  doc.deletePage(1)
  doc.save(`${pdfDate.format('DD-MM-YYYY')}.pdf`)
}

export default printTurns
