import { setAllTemplatesHCController } from '@/controllers/clinicHistoryTemplate'
import {
  addNewTemplateHC,
  deleteTemplateHC,
  fetchSingleTemplateHC,
  fetchTemplatesHC,
  putTemplateHC,
} from '@/services/clinicHistoryTemplate'
import {
  FETCH_TEMPLATES_HC,
  LOADING_TEMPLATES_HC,
  RESET_TEMPLATE_SELECTED,
  TEMPLATE_CREATED,
  TEMPLATE_DELETE,
  TEMPLATE_SELECTED,
  TEMPLATE_UPDATED,
} from '@/store/clinicHistoryTemplate'
import { showToast } from '@/utils/toast'

const setAllTemplatesHC = (fetchAllTemplatesHC) => async (dispatch) => {
  let { docs: allTemplatesHC, ...paginationData } = fetchAllTemplatesHC
  dispatch(FETCH_TEMPLATES_HC({ allTemplatesHC, paginationData }))
}

const getTemplatesHC = (params) => async (dispatch) => {
  const fetchAllTemplatesHC = await setAllTemplatesHCController(params)
  dispatch(setAllTemplatesHC(fetchAllTemplatesHC))
}

const postNewTemplateHC = (data) => async (dispatch) => {
  try {
    let auxFields = data.fields
    let newTemplate = await addNewTemplateHC(data)
    newTemplate = {
      ...newTemplate,
      fields: auxFields,
    }
    dispatch(TEMPLATE_CREATED(newTemplate))
    if (newTemplate) {
      showToast('Ficha agregada correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const deleteTemplateHCAdded = (id) => async (dispatch) => {
  try {
    await deleteTemplateHC(id)
    dispatch(TEMPLATE_DELETE(id))
    showToast('Ficha eliminada correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateTemplateHC = (data, callback) => async (dispatch) => {
  try {
    let auxFields = data.fields
    let templateUpdated = await putTemplateHC(data)
    templateUpdated = {
      ...templateUpdated,
      fields: auxFields,
    }
    dispatch(TEMPLATE_UPDATED(templateUpdated))

    if (templateUpdated) {
      showToast('Ficha actualizada correctamente', 'success')
    }

    if (callback) {
      callback()
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedTemplateHC = (id) => async (dispatch) => {
  try {
    const singleTemplate = await fetchSingleTemplateHC(id)

    dispatch(TEMPLATE_SELECTED(singleTemplate))
    return singleTemplate
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedTemplateHC = () => async (dispatch) => {
  try {
    dispatch(RESET_TEMPLATE_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const handlePaginationChangeHCTemplates = (newPage, newLimit, searchText) => async (dispatch, getState) => {
  const { centreProfile } = getState().centres
  const params = {
    centre: centreProfile?._id,
    page: newPage,
    limit: newLimit,
    search: searchText,
    paginate: true,
  }
  dispatch(LOADING_TEMPLATES_HC(true))
  const { docs: allTemplatesHC, ...paginationData } = await fetchTemplatesHC(params)
  dispatch(FETCH_TEMPLATES_HC({ allTemplatesHC, paginationData }))
  dispatch(LOADING_TEMPLATES_HC(false))
}

export {
  deleteTemplateHCAdded,
  getTemplatesHC,
  handlePaginationChangeHCTemplates,
  postNewTemplateHC,
  resetSelectedTemplateHC,
  selectedTemplateHC,
  setAllTemplatesHC,
  updateTemplateHC,
}
