// @ts-ignore
import React from 'react'
import QRCode from 'react-qr-code'
import LogoCalu from '@/assets/images/logo.png'
import CaluButton from '@/components/styledComponents/CaluButton'
import CloseIcon from '@mui/icons-material/Close'
import AppBar from '@mui/material/AppBar'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Toolbar from '@mui/material/Toolbar'
import { TransitionProps } from '@mui/material/transitions'

import s from './styles.module.scss'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ModalDowloadQR = ({ open, handleCloseModalQR, slug }) => {
  const handlePrint = () => {
    window.print()
  }
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleCloseModalQR}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          width: '100%',
          height: '100%',
        },
      }}
    >
      <AppBar sx={{ position: 'relative' }} className={s.appBar}>
        <Toolbar className={s.toolbar}>
          <IconButton edge="start" color="inherit" onClick={handleCloseModalQR} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div id="dialog-content" className={s.container}>
        <div className={s.itemContainer}>
          <div className={s.title}>Reserva tu próxima cita de forma online</div>

          <QRCode size={300} value={`https://citas.calu.app/${slug}`} viewBox={`0 0 256 256`} />

          <div>{`citas.calu.app/${slug}`}</div>
          <div className={s.buttonContainer}>
            <img src={LogoCalu} alt="LogoCalu" width={70} height={40} className={s.caluLogo} />
            <CaluButton color="primary" size="small" onClick={handlePrint} className={s.dowloadButton}>
              Descargar PDF
            </CaluButton>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ModalDowloadQR
