import React from 'react'
import CaluFormInput from '@/components/formComponents/customFields/CaluFormInput'
import NavBar from '@/components/NavBar'
import CaluButton from '@/components/styledComponents/CaluButton'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false }) => {
  return (
    <>
      <NavBar />

      <div className={s.cardContainer}>
        <div className={s.loginTitleContainer}>
          <div className={s.loginTitle}>Cambiar contraseña</div>
        </div>

        <div className={s.formContainer}>
          <div className={s.inputWidth}>
            <CaluFormInput
              labelText="Contraseña actual *"
              type="password"
              name="oldPassword"
              className={s.inputWidth}
            />
            <CaluFormInput labelText="Contraseña nueva *" type="password" name="newPassword" className={s.inputWidth} />
          </div>

          <CaluButton color="primary" type="submit" size="large" loading={isLoading}>
            Enviar
          </CaluButton>
        </div>
      </div>
    </>
  )
}
