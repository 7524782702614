import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'centreAvailability',
  initialState: {
    centreData: null,
    consultations: null,
    practices: null,
    error: false,
  },
  reducers: {
    FETCH_CENTRE_AVAILABILITIES: (state, { payload }) => {
      const { centreData, consultations, practices } = payload
      state.centreData = centreData
      state.consultations = consultations
      state.practices = practices
      state.error = false
    },
    CENTRE_AVAILABILITIES_ERROR: (state) => {
      state.error = true
    },
    RESET_CENTRE_AVAILABILITIES: (state) => {
      state.centreData = null
      state.consultations = null
      state.practices = null
      state.error = false
    },
    RESET_SPECIFIC_AVAILABILITY: (state, { payload }) => {
      payload.entities.forEach((entity) => {
        if (state.practices[payload.service._id]?.availability?.[entity._id]) {
          state.practices[payload.service._id].availability[entity._id] = {}
        }
      })
    },
  },
})

export const {
  FETCH_CENTRE_AVAILABILITIES,
  CENTRE_AVAILABILITIES_ERROR,
  RESET_CENTRE_AVAILABILITIES,
  RESET_SPECIFIC_AVAILABILITY,
} = slice.actions
export default slice.reducer
