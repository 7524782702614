import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ButtonsPagination from '@/components/ButtonsPaginations'
import LoaderCircle from '@/components/LoaderCircle'
import CaluButton from '@/components/styledComponents/CaluButton'
import CaluRawDatePicker from '@/components/styledComponents/CaluRawDatePicker'
import { CaluSelect } from '@/components/styledComponents/CaluSelect'
import { turnStatesType } from '@/helpers/constants'
import { TURNS_LOADING } from '@/store/turns'
import { getPatientCentreTurnsByDni } from '@/store/turns/actions'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { Grid } from '@mui/material'

import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import CardTurn from '../cardTurn'

import s from '@/pages/MyTurnsPage/styles.module.scss'

const MyTurnsTab = ({ setOpenPopUp, defaultParams, startDate, finishDate }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const { paginationData, patientCentresTurns, isLoading } = useSelector((state) => state.turns)
  const [selectedState, setSelectedState] = useState('-')
  const [newStartDate, setNewStartDate] = useState(startDate)
  const [newFinishDate, setNewFinishDate] = useState(finishDate)

  const handlePatientCentreTurns = async (params) => {
    dispatch(TURNS_LOADING(true))
    await dispatch(getPatientCentreTurnsByDni(params))
    dispatch(TURNS_LOADING(false))
  }

  const handleChangePaginationNumber = async (page) => {
    const params = {
      ...defaultParams,
      page: page,
    }
    handlePatientCentreTurns(params)
  }

  const handleSetStartDate = (newDate) => {
    let auxFinishDate = newFinishDate
    let auxNewDate = dayjs(newDate).startOf('day').format()
    if (auxNewDate === 'Invalid Date') return
    if (dayjs(auxNewDate).format('DD-MM-YYYY') > dayjs(auxFinishDate).format('DD-MM-YYYY')) {
      setNewFinishDate(dayjs(newDate).startOf('day').add(2, 'month').format())
      auxFinishDate = dayjs(auxNewDate).add(2, 'month').format()
    }
    const params = {
      ...defaultParams,
      start: toCustomTz(auxNewDate, 'utc', true),
      finish: toCustomTz(auxFinishDate, 'utc', true),
    }
    handlePatientCentreTurns(params)
    setNewStartDate(auxNewDate)
  }

  const handleSetFinishDate = (newDate) => {
    let auxFinishDate = dayjs(newDate).startOf('day').format()
    if (auxFinishDate === 'Invalid Date') return
    const params = {
      ...defaultParams,
      start: toCustomTz(newStartDate, 'utc', true),
      finish: toCustomTz(auxFinishDate, 'utc', true),
    }
    handlePatientCentreTurns(params)
    setNewFinishDate(auxFinishDate)
  }

  const handlePatientTurnsByState = (value) => {
    let params = {
      ...defaultParams,
    }
    if (value !== '-') {
      params.state = value
    }
    setSelectedState(value)
    handlePatientCentreTurns(params)
  }
  return (
    <div className={s.turnTab}>
      <Grid container className={s.selectSection}>
        <Grid item xs={12} md={2}>
          <CaluRawDatePicker labelText="Desde" value={newStartDate} onChange={handleSetStartDate} />
        </Grid>
        <Grid item xs={12} md={2}>
          <CaluRawDatePicker
            labelText="Hasta"
            value={newFinishDate}
            onChange={handleSetFinishDate}
            minDate={dayjs(newStartDate).add(2, 'month')}
            disableHighlightToday={true}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <CaluSelect
            labelText={`Filtrar por estado de ${t('turnLabel.p')}`}
            firstOptionValue={'init'}
            value={selectedState}
            onChange={(e) => handlePatientTurnsByState(e.target.value)}
            items={turnStatesType}
            className={s.inputsSelect}
          />
        </Grid>
      </Grid>

      {isLoading ? (
        <div className={s.circularProgress}>
          <LoaderCircle size={50} text={`Cargando ${t('turnLabel.pp')}...`} />
        </div>
      ) : (
        <div className={s.divCard}>
          {patientCentresTurns?.map((turn) => (
            <div key={turn._id}>
              <CardTurn turn={turn} />
            </div>
          ))}
          {patientCentresTurns?.length === 0 && (
            <div className={s.hasNoTurns}>
              <div>{`Parece que no tienes ${t('turnLabel.pds')}`}</div>
              <div>¿Deseas agendar una?</div>
              <div className={s.askForNewDateBtn}>
                <CaluButton size="medium" color="primary" onClick={() => setOpenPopUp(true)}>{`Solicitar ${t(
                  'turnLabel.s',
                )}`}</CaluButton>
              </div>
            </div>
          )}

          <div className={s.contPagination}>
            <ButtonsPagination
              paginationData={paginationData}
              handleChangePaginationNumber={handleChangePaginationNumber}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default MyTurnsTab

MyTurnsTab.propTypes = {
  patientSelected: PropTypes.object,
}
