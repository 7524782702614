import { Calu_a_bg, Calu_b_bg, Calu_delete_bg } from '@/assets/styles/CaluCustomStyles.jsx'
import { LoadingButton } from '@mui/lab'

import styled from 'styled-components'

//props: color, type, disabled
export const SLoadingButton = styled(LoadingButton)`
  color: ${(props) => props.theme.colors.base.white};
  background-color: ${(props) => props.theme.colors.a};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-color: ${(props) => props.theme.colors.a};
  font-family: 'Poppins';
  line-height: 150%;
  font-weight: 700;
  border-style: solid;
  ${(props) =>
    !props.size &&
    `
      width: 100%; 
      padding: 12px 24px;
      font-size: 15px;
      `}
  ${(props) =>
    props.size === 'small' &&
    `
      line-height: 1.75;
      min-width: 64px;
      padding: 3px 9px;
      font-size: 13px;
      font-weight: 600;
   
      `}
  ${(props) =>
    props.size === 'medium' &&
    `
      line-height: 1.75;
      min-width: 64px;
      padding: 5px 15px;
      font-weight: 600;
      font-size: 14px;
  
      `}
  ${(props) =>
    props?.size === 'large' &&
    `
      height: 40px;
      min-width: 154px; 
      font-size: 14px;
      `}

  &:hover {
    background-color: transparent;
  }

  svg {
    font-size: 18px;
    margin-right: 4px;
  }

  ${(props) => props.color === 'primary' && Calu_a_bg}
  ${(props) => props.color === 'secondary' && Calu_b_bg}
  ${(props) => props.color === 'error' && Calu_delete_bg}

  ${(props) =>
    (props.disabled || props.loading) &&
    `
    background-color: ${props.theme.colors.g}; 
    border: 1px solid ${props.theme.colors.base.silver};
    cursor: not-allowed;
  `}

  ${(props) =>
    props.type === 'link' &&
    `
    background-color: transparent; 
    border-color: transparent;
    color: ${props.color === 'secondary' ? props.theme.colors.b : props.theme.colors.a}; 
    &:hover {
      background-color: transparent;
      color: ${props.color === 'secondary' ? props.theme.colors.b : props.theme.colors.a}; 
    }
  `}

  ${(props) =>
    props.inverted &&
    `
    background-color: transparent; 
    border: 2px solid ${props.color === 'secondary' ? props.theme.colors.b : props.theme.colors.a};
    color: ${props.color === 'secondary' ? props.theme.colors.b : props.theme.colors.a}; 
    &:hover {
      background-color: transparent; 
      border-color: 2px solid ${props.color === 'secondary' ? props.theme.colors.b : props.theme.colors.a};
      color: ${props.color === 'secondary' ? props.theme.colors.b : props.theme.colors.a}; 
    }
  `}
  
  ${(props) =>
    props.variant === 'contained' &&
    `
    background-color: ${props.color === 'secondary' ? props.theme.colors.b : props.theme.colors.a}; 
    color: ${props.theme.colors.base.white};
    &:hover {
      background-color: ${props.color === 'secondary' ? props.theme.colors.b : props.theme.colors.a}; 
      color: ${props.theme.colors.base.white} ; 
    }
  `}
`
