import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTrashAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import SelectWithInput from '@/components/SelectWithInput'
import { useUser } from '@/context/UserProvider'
import Layout1 from '@/layouts/Layout1'
import {
  getCentreTargetAvailabilities,
  resetCentreTargetAvailabilities,
  resetSpecificAvailability,
} from '@/store/centreAvailability/actions'
import { getCurrentcentreInsurances } from '@/store/centreInsurances/actions'
import { getCentreTarget, resetCentreTarget } from '@/store/centres/actions'
import { getAllInsurancePrices } from '@/store/insurancePrices/actions'
import { SELECTED_ONLINE_EXTRA_SERVICES } from '@/store/turns'
import { getPatientCentreTurnsByDni } from '@/store/turns/actions'
import { getInsurancePrice } from '@/utils/functions/calculateFinalServicePrice'
import { getUniqueListBy } from '@/utils/functions/getUniqueListBy'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { Button, Grid } from '@mui/material'

import dayjs from 'dayjs'
import CardsDoctorsCentreTarget from './components/cardsDoctorsCentreTarget'
import ErrorCards from './components/errorCards'
import LandingHeader from './components/landingHeader'
import PopUpAddExtraService from './components/popUpAddExtraService'
import RestorePendingTurnPopUp from './components/restorePendingTurnPopUp'

import s from './styles.module.scss'

const LandingCentreTargetPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const { id: centreId } = useParams()
  const { user } = useUser()
  const { centreTarget: centre, error: centreError } = useSelector((state) => state.centres)
  const {
    centreData,
    consultations: consultationsData,
    practices: practicesData,
    error: centreAvailabilityError,
  } = useSelector((state) => state.centreAvailability)
  const country = centre?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase() || 'ARG'

  const centreInsurances = useSelector((state) => state?.centreInsurances?.centreInsurancesDetail)
  const { patientCentresTurns } = useSelector((state) => state?.turns)
  const { allInsurancePrices } = useSelector((state) => state.insurancePrices)

  const [isLoading, setIsLoading] = useState(true)
  const [addExtraServiceBtn, setAddExtraServiceBtn] = useState(false)
  const [openPopUpAddExtraService, setOpenPopUpAddExtraService] = useState(false)
  const [selectedExtraServices, setSelectedExtraServices] = useState([])

  const [openRestorePendingTurnPopUp, setOpenRestorePendingTurnPopUp] = useState(false)

  const [allEntities, setAllEntities] = useState([])
  const [filteredEntities, setFilteredEntities] = useState([])
  const [selectedEntity, setSelectedEntity] = useState('-')
  const [filteredEntitiesWithService, setFilteredEntitiesWithService] = useState([])

  const [allInsurances, setAllInsurances] = useState([])
  const [filteredInsurances, setFilteredInsurances] = useState([])
  const [selectedInsurance, setSelectedInsurance] = useState('-')

  const [allSpecialities, setAllSpecialities] = useState([])
  const [filteredSpecialities, setFilteredSpecialities] = useState([])
  const [selectedSpeciality, setSelectedSpeciality] = useState('-')

  const [allServices, setAllServices] = useState([])
  const [filteredServices, setFilteredServices] = useState([])
  const [selectedService, setSelectedService] = useState('-')
  const [selectedServiceData, setSelectedServiceData] = useState()
  const [searchingFor, setSearchingFor] = useState()
  const [showMoreFilters, setShowMoreFilters] = useState(false)

  // El insurancePrice solo aplicamos solo sobre el servciio principal
  // TODO: analizar si lo aplicamos para los extras tb
  const serviceInsurancePrice = getInsurancePrice(selectedServiceData, selectedInsurance, allInsurancePrices)
  const finalMainServicePrice = serviceInsurancePrice?.price || selectedServiceData?.price || 0
  const extraServicesPrice = selectedExtraServices?.reduce((a, b) => Number(a) + (Number(b?.price) || 0), 0)
  const totalServicesPrice = finalMainServicePrice + extraServicesPrice

  const shouldShowResetFilters =
    selectedEntity !== '-' || selectedInsurance !== '-' || selectedSpeciality !== '-' || selectedService !== '-'

  const setInitialValues = () => {
    // build entities
    const doctorCentres = Object.values(centreData?.doctorCentres) || []
    const machines = Object.values(centreData?.machines) || []
    const entities = doctorCentres.concat(machines)
    // Add centreInsurances to entity
    let entitiesWithInsurances = entities?.map((entity) => {
      let auxEntityInsurances = [...entity?.insurances]
      let auxUniqueInsurances

      if (!entity.hideCentreInsurances) {
        auxEntityInsurances =
          centreInsurances !== null ? auxEntityInsurances.concat(centreInsurances?.insurances) : auxEntityInsurances

        auxUniqueInsurances = getUniqueListBy(auxEntityInsurances, '_id')
        return (entity = {
          ...entity,
          insurances: auxUniqueInsurances,
        })
      }
      auxUniqueInsurances = getUniqueListBy(auxEntityInsurances, '_id')
      return (entity = {
        ...entity,
        insurances: auxUniqueInsurances,
      })
    })

    setAllEntities(entitiesWithInsurances)
    setFilteredEntities(entitiesWithInsurances)

    // build services and check if add 'consultation' and 'firstConsultation' to array of services
    let entitiesWithConsultation = Object.keys(consultationsData?.consultation?.availability || {})
    let auxEntitiesWithConsultation = entitiesWithInsurances?.filter((e) => entitiesWithConsultation?.includes(e?._id))
    let entitiesWithFirstConsultation = Object.keys(consultationsData?.firstConsultation?.availability || {})
    let auxEntitiesWithFirstConsultation = entitiesWithInsurances?.filter((e) =>
      entitiesWithFirstConsultation?.includes(e._id),
    )
    const hasConsultation = auxEntitiesWithConsultation?.length
    const hasFirstConsultation = auxEntitiesWithFirstConsultation?.length
    const defaultServices =
      hasConsultation && hasFirstConsultation
        ? [
            { _id: 'consultation', name: 'Consulta' },
            { _id: 'firstConsultation', name: 'Primera consulta' },
          ]
        : hasConsultation
          ? [{ _id: 'consultation', name: 'Consulta' }]
          : hasFirstConsultation
            ? [{ _id: 'firstConsultation', name: 'Primera consulta' }]
            : []

    setAllServices(defaultServices.concat(practicesData?.all))
    setFilteredServices(defaultServices.concat(practicesData?.all))
    // build specialities
    setAllSpecialities(centreData?.centreSpecialities)
    setFilteredSpecialities(centreData?.centreSpecialities)

    // build insurances
    const entityInsurances = entities
      ?.map((d) => {
        return d.insurances
      })
      .flat()

    let allInsurances =
      centreInsurances !== null ? entityInsurances?.concat(centreInsurances?.insurances) : entityInsurances

    const insurancesUniqueByKey = [...new Map(allInsurances?.map((item) => [item['_id'], item])).values()]

    setAllInsurances(insurancesUniqueByKey)
    setFilteredInsurances(insurancesUniqueByKey)
  }

  const onLoad = async () => {
    await dispatch(getCurrentcentreInsurances(centreId))
    await dispatch(getAllInsurancePrices(centreId))

    if (!centreData) {
      await dispatch(getCentreTargetAvailabilities(centreId))
    }
  }

  const handleCheckpendingTurn = async () => {
    const currentDate = dayjs(new Date()).utc(true)
    const startDate = currentDate.subtract(1, 'day')
    const finishDate = currentDate.add(6, 'months')
    const defaultParams = {
      centre: centreId,
      dni: user?.dni,
      start: startDate.format(),
      finish: finishDate.format(),
      state: 'pending',
      paginate: true,
    }
    const patientCentreTurns = await dispatch(getPatientCentreTurnsByDni(defaultParams))

    if (patientCentreTurns?.length) {
      setOpenRestorePendingTurnPopUp(true)
    }
  }

  const findPendingTurnInCentre = (pendingTurns, centreID) => {
    const pendingTurn = pendingTurns?.filter(
      (turn) => turn?.state === 'pending' && (turn?.centre?._id || turn?.centre) === centreID,
    )
    return pendingTurn[0]
  }

  useEffect(() => {
    dispatch(getCentreTarget(centreId))

    if (user) {
      handleCheckpendingTurn()
    }

    return () => {
      dispatch(resetCentreTarget())
      dispatch(resetCentreTargetAvailabilities())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if ((!centre && centreError) || centre?.status === 'blocked') window.location.href = `/no/existe`

    if (centre) {
      onLoad()
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centre, centreError])

  useEffect(() => {
    // only setInitialValues on first load, when no filters are selected
    if (centreData && !shouldShowResetFilters) {
      setInitialValues()
    }

    if (centreData && isLoading) {
      setIsLoading(false)
    }
  }, [centreData]) // eslint-disable-line react-hooks/exhaustive-deps

  const setEntitiesWithSelectedService = (shouldUpdateSearchingFor) => {
    const selectedServiceData = allServices?.filter((elem) => elem._id === selectedService)
    setFilteredServices(selectedServiceData)
    setFilteredSpecialities(allSpecialities)
    setFilteredInsurances(allInsurances)

    if (['consultation', 'firstConsultation'].includes(selectedService)) {
      setAddExtraServiceBtn(false)
    } else {
      setAddExtraServiceBtn(true)
      setSelectedExtraServices([])
      dispatch(SELECTED_ONLINE_EXTRA_SERVICES([]))
    }

    // filter entities for that service
    let auxEntities = []
    if (selectedService === 'consultation') {
      let entitiesWithConsultation = Object.keys(consultationsData?.consultation?.availability || {})
      auxEntities = allEntities?.filter((e) => entitiesWithConsultation?.includes(e?._id))
    } else if (selectedService === 'firstConsultation') {
      let entitiesWithFirstConsultation = Object.keys(consultationsData?.firstConsultation?.availability || {})
      auxEntities = allEntities?.filter((e) => entitiesWithFirstConsultation?.includes(e._id))
    } else {
      let idsEntitiesFound = Object.keys(practicesData?.[selectedService]?.availability || {})
      allEntities.forEach((entity) => {
        if (idsEntitiesFound.includes(entity._id)) {
          auxEntities.push(entity)
        }
      })
    }
    setFilteredEntities(auxEntities)

    if (selectedExtraServices.length === 0) setFilteredEntitiesWithService(auxEntities)

    if (shouldUpdateSearchingFor) {
      if (['consultation', 'firstConsultation'].includes(selectedService)) {
        setSearchingFor('consultations')
      } else {
        setSearchingFor('practices')
      }
    }
  }

  useEffect(() => {
    if (selectedEntity !== '-') {
      const selectedEntityData = allEntities.filter((elem) => elem._id === selectedEntity)
      setFilteredEntities(selectedEntityData)

      // filter services for that entity
      const auxServiceIds = selectedEntityData?.[0]?.workTimeServices || []
      setFilteredServices(allServices.filter((elem) => auxServiceIds.includes(elem._id)))

      // filter specialities for that entity
      const auxSpecialities = selectedEntityData?.[0]?.specialities || []
      setFilteredSpecialities(auxSpecialities)

      // filter insurances
      const auxInsurances = selectedEntityData?.[0]?.insurances || []
      setFilteredInsurances(auxInsurances)

      return
    }

    if (selectedEntity === '-' && selectedService !== '-') {
      setEntitiesWithSelectedService()
      return
    }

    if (selectedEntity === '-' && selectedService === '-') {
      setFilteredEntities(allEntities)
      setFilteredServices(allServices)
      setFilteredInsurances(allInsurances)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntity])

  useEffect(() => {
    setSelectedServiceData(findServiceData(selectedService))

    if (selectedService !== '-' && selectedEntity === '-') {
      setEntitiesWithSelectedService(true)
      return
    }

    if (selectedService === '-' && (selectedEntity === '-' || selectedEntity !== '-')) {
      dispatch(resetCentreTargetAvailabilities())
      setFilteredServices(allServices)
      setFilteredEntities(allEntities)
      setFilteredInsurances(allInsurances)
      setSelectedInsurance('-')
      setSelectedSpeciality('-')
      setAddExtraServiceBtn(false)
      setSelectedExtraServices([])
      dispatch(SELECTED_ONLINE_EXTRA_SERVICES([]))
      setSelectedEntity('-')
      dispatch(getCentreTargetAvailabilities(centreId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService])

  useEffect(() => {
    if (selectedService === '-') return
    if (selectedExtraServices.length > 0) {
      let extraServices = selectedExtraServices.map((service) => service._id)

      let newFilteredEntities = [...filteredEntitiesWithService]
      let serviceSelected

      let i = 0
      while (i < extraServices.length) {
        serviceSelected = extraServices[i]
        // eslint-disable-next-line no-loop-func
        newFilteredEntities = newFilteredEntities.filter((entity) => entity.workTimeServices?.includes(serviceSelected))
        i++
      }
      setFilteredEntities(newFilteredEntities)
    }

    if (selectedExtraServices.length === 0) setFilteredEntities(filteredEntitiesWithService)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExtraServices])

  useEffect(() => {
    if (selectedSpeciality === '-') {
      setFilteredSpecialities(allSpecialities)
      return
    }

    const selectedSpecialityData = allSpecialities?.filter((elem) => elem._id === selectedSpeciality)
    setFilteredSpecialities(selectedSpecialityData)

    if (selectedSpeciality !== '-') {
      const auxEntities = filteredEntities?.filter((e) => e.specialities?.some((s) => s._id === selectedSpeciality))
      setFilteredEntities(auxEntities)
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpeciality])

  useEffect(() => {
    const selectedInsuranceData = allInsurances?.filter((elem) => elem._id === selectedInsurance)
    setFilteredInsurances(selectedInsuranceData)

    if (selectedInsurance !== '-') {
      const auxEntities = filteredEntities?.filter((e) => e.insurances?.some((s) => s._id === selectedInsurance))
      setFilteredEntities(auxEntities)
      return
    }
    if (selectedInsurance === '-') {
      setFilteredEntities(allEntities)
    }

    setFilteredInsurances(allInsurances)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInsurance])

  const handleFilterEntity = (e) => {
    mxp.track(mxp.events.centreLanding.filters.agendaSelected)
    let selectedEntity = e.target.value
    setSelectedEntity(selectedEntity)
  }

  const handleFilterInsurance = (e) => {
    mxp.track(mxp.events.centreLanding.filters.insuranceSelected)
    let selectedInsurance = e.target.value
    setSelectedInsurance(selectedInsurance)
  }

  const handleFilterSpeciality = (e) => {
    mxp.track(mxp.events.centreLanding.filters.specialitySelected)
    let selectedSpeciality = e.target.value
    setSelectedSpeciality(selectedSpeciality)
  }

  const handleFilterService = (e) => {
    mxp.track(mxp.events.centreLanding.filters.serviceSelected)
    if (e.target.value === '-') setShowMoreFilters(false)
    let serviceSelected = e.target.value
    setSelectedService(serviceSelected)

    if (['consultation', 'firstConsultation'].includes(serviceSelected)) {
      setSearchingFor('consultations')
    } else {
      setSearchingFor('practices')
    }
  }

  const handleResetFilters = () => {
    mxp.track(mxp.events.centreLanding.filters.reseted)
    setInitialValues()
    setSelectedEntity('-')
    setSelectedInsurance('-')
    setSelectedSpeciality('-')
    setSelectedService('-')
    setAddExtraServiceBtn(false)
  }

  const findServiceData = (serviceId) => {
    if (serviceId === 'consultation') return { _id: 'consultation', name: 'Consulta' }
    if (serviceId === 'firstConsultation') return { _id: 'firstConsultation', name: 'Primera consulta' }
    let serviceData = allServices.filter((service) => service._id === serviceId)?.[0]
    return serviceData
  }

  const handleOpenPopUpExtraService = () => {
    mxp.track(mxp.events.centreLanding.extraServices.open)
    setOpenPopUpAddExtraService(true)
  }

  const handleDeleteService = (idService) => {
    let extraServices = selectedExtraServices.filter((elem) => elem._id !== idService)
    dispatch(resetSpecificAvailability(selectedServiceData, filteredEntities))
    setSelectedExtraServices(extraServices)
    dispatch(SELECTED_ONLINE_EXTRA_SERVICES(extraServices))
  }

  const handleDeleteExtraService = async (extraService) => {
    handleDeleteService(extraService._id)
  }

  if (!centre?.visible && centre !== null) {
    return <ErrorCards centre={centre} centreError={centreError} centreAvailabilityError={centreAvailabilityError} />
  }

  return (
    <Layout1 navbar isLoading={isLoading}>
      <div className={s.divContainer}>
        <LandingHeader centre={centre} />

        <div className={s.mainContainerSearchBar}>
          <Grid container className={s.searchBar}>
            <Grid spacing={2} container className={s.gridInputs}>
              {selectedService === '-' && (
                <Grid item md={6} className={s.alertMsg}>
                  Elige un servicio para ver {t('turnLabel.p')} disponibles
                </Grid>
              )}

              {selectedService !== '-' && !showMoreFilters && (
                <Grid item xs={12} md={6} className={s.gridFiltersButton}>
                  <Button className={s.filtersButtonColor} onClick={() => setShowMoreFilters(true)} variant="outlined">
                    Ver más filtros
                  </Button>
                </Grid>
              )}

              {selectedService !== '-' && showMoreFilters && (
                <>
                  <Grid item xs={12} lg={allInsurances?.length > 0 ? 3 : 4}>
                    <SelectWithInput
                      className={s.filterSelect}
                      inputLabel="Agenda"
                      firstOptionText="Ver todos"
                      firstOptionValue="-"
                      options={filteredEntities}
                      onChange={handleFilterEntity}
                      valueSelect={selectedEntity}
                    />
                  </Grid>
                  {allInsurances?.length > 0 && (
                    <Grid item xs={12} lg={3}>
                      <SelectWithInput
                        className={s.filterSelect}
                        inputLabel={`${t('insuranceLabel.S')}`}
                        firstOptionText="Particular"
                        firstOptionValue="-"
                        options={filteredInsurances}
                        onChange={handleFilterInsurance}
                        valueSelect={selectedInsurance}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} lg={allInsurances?.length > 0 ? 3 : 4}>
                    <SelectWithInput
                      className={s.filterSelect}
                      inputLabel="Especialidad"
                      firstOptionText="Todas las especialidades"
                      firstOptionValue="-"
                      options={filteredSpecialities}
                      onChange={handleFilterSpeciality}
                      valueSelect={selectedSpeciality}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} lg={!showMoreFilters ? 6 : allInsurances?.length > 0 ? 3 : 4}>
                <SelectWithInput
                  className={s.filterSelect}
                  inputLabel="Servicios"
                  firstOptionText="Ver todos los servicios"
                  firstOptionValue="-"
                  options={filteredServices}
                  onChange={handleFilterService}
                  valueSelect={selectedService}
                />
              </Grid>
            </Grid>

            {shouldShowResetFilters && (
              <Grid item xs={12} className={s.itemResetBtn}>
                <Button className={s.resetBtn} onClick={handleResetFilters}>
                  <i>
                    <FaTrashAlt />
                  </i>{' '}
                  Borrar filtros
                </Button>
              </Grid>
            )}
          </Grid>

          {addExtraServiceBtn && (
            <div className={s.serviceDescription}>
              <ul>
                <li className={s.itemService}>
                  <b>{selectedServiceData?.name?.toUpperCase()}</b>{' '}
                  {/* {selectedServiceData?.price && (
                    <span className={finalMainServicePrice > 0 ? s.lineThrough : ''}>
                      ({handleSetMoneySign(country)}
                      {selectedServiceData?.price})
                    </span>
                  )} */}
                  {finalMainServicePrice > 0 && (
                    <span>
                      {' '}
                      : {handleSetMoneySign(country)}
                      {finalMainServicePrice}
                    </span>
                  )}
                </li>

                {selectedExtraServices.map((extraService) => {
                  return (
                    <li key={extraService?._id} className={s.itemService}>
                      <b>{extraService?.name.toUpperCase()}</b>{' '}
                      {extraService?.price && (
                        <span>
                          {handleSetMoneySign(country)}
                          {extraService?.price}
                        </span>
                      )}
                      {extraService?.description && <span> : {extraService?.description}</span>}
                      <span className={s.deleteItem} onClick={() => handleDeleteExtraService(extraService)}>
                        Eliminar
                      </span>
                    </li>
                  )
                })}
              </ul>

              {selectedServiceData?.description && (
                <div className={s.totalAmountServices}>
                  Descripción: <span>{selectedServiceData?.description}</span>
                </div>
              )}

              {totalServicesPrice > 0 && (
                <div className={s.totalAmountServices}>
                  Total:{' '}
                  <span>
                    {handleSetMoneySign(country)} {totalServicesPrice}
                  </span>
                </div>
              )}

              {addExtraServiceBtn && (
                <div className={s.containerBtnAddPractice}>
                  <Button className={s.buttonAddPractice} onClick={handleOpenPopUpExtraService}>
                    Agregar otro servicio
                  </Button>
                </div>
              )}
            </div>
          )}

          <CardsDoctorsCentreTarget
            entities={filteredEntities}
            service={[selectedServiceData, ...selectedExtraServices]}
            searchingFor={searchingFor}
            allServices={allServices}
            setSelectedService={setSelectedService}
            selectedInsurance={selectedInsurance}
          />

          {!!openPopUpAddExtraService && (
            <PopUpAddExtraService
              open={openPopUpAddExtraService}
              onClose={() => setOpenPopUpAddExtraService(false)}
              allServices={practicesData?.all}
              selectedExtraServices={selectedExtraServices}
              setSelectedExtraServices={setSelectedExtraServices}
              filteredEntities={filteredEntities}
              selectedService={selectedServiceData}
            />
          )}
        </div>
      </div>
      {openRestorePendingTurnPopUp && (
        <RestorePendingTurnPopUp
          open={openRestorePendingTurnPopUp}
          setOpen={setOpenRestorePendingTurnPopUp}
          pendingTurnData={findPendingTurnInCentre(patientCentresTurns, centreId)}
          centreId={centreId}
          centreData={centreData}
        />
      )}
    </Layout1>
  )
}

export default LandingCentreTargetPage
