import * as React from 'react'
import { useSelector } from 'react-redux'
import CaluModal from '@/components/CaluModal'

import MoreInformationContent from './MoreInformationContent'

export default function FormDialog({ open, setOpen }) {
  const centre = useSelector((state) => state.centres.centreTarget)

  return (
    <CaluModal open={open} setOpen={setOpen} title={centre?.name?.toUpperCase()}>
      <MoreInformationContent />
    </CaluModal>
  )
}
