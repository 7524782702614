import React from 'react'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormCreateCashboxItem from '../formCreateCashboxItem'

export default function PopUpAddEntry({ open, selectedCashboxItem, onClose }) {
  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={open} onClose={onClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle={selectedCashboxItem ? 'Detalle de entrada' : 'Nueva entrada'} handleClose={onClose} />
        </DialogTitle>
        <DialogContent className="animate__animated animate__fadeIn">
          <FormCreateCashboxItem onClose={onClose} formValues={selectedCashboxItem} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
