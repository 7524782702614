import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import CaluButton from '@/components/styledComponents/CaluButton'
import { SUBSCRIPTION_PAYMENT_SELECTED } from '@/store/subscriptionPayments'
import { getAllSubscriptionPayments } from '@/store/subscriptionPayments/actions'

import { handleCalculateFinalPrice } from '../functions/handleCalculateFinalPrice'
import { handleFindPlanPrice } from '../functions/handleFindPlanPrice'
import PaymentsTable from './PaymentsTable'
import VoucherPopUp from './VoucherPopUp'

import s from './styles.module.scss'

const InfoPayments = () => {
  const dispatch = useDispatch()

  const [dataVoucher, setDataVoucher] = useState(null)
  const [openVoucherPopUp, setOpenVoucherPopUp] = useState(false)

  const { centreAdminDetail } = useSelector((state) => state?.centres)
  const { allPlanPrices } = useSelector((state) => state?.planPrices)
  const { isLoading } = useSelector((state) => state?.subscriptionPayments)

  const centreConfiguration = centreAdminDetail?.centreConfiguration

  const setInit = async () => {
    const params = {
      centre: centreAdminDetail._id,
    }
    await dispatch(getAllSubscriptionPayments(params))
  }

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDisabledButton = () => {
    if (!centreConfiguration) return true
    if (
      !centreConfiguration?.subscriptionPlan ||
      !centreConfiguration?.subscriptionAgendasQuantity ||
      !centreConfiguration?.subscriptionPaymentType
    )
      return true
    return false
  }

  const handleGenerateVoucher = async () => {
    dispatch(SUBSCRIPTION_PAYMENT_SELECTED(null))

    const suggestedPrice = handleFindPlanPrice(
      allPlanPrices,
      centreAdminDetail?.country || 'ARG',
      centreConfiguration?.subscriptionPlan,
      centreConfiguration?.subscriptionAgendasQuantity,
    )

    const finalPrice = handleCalculateFinalPrice(
      suggestedPrice,
      centreConfiguration?.subscriptionExtraAmount?.type,
      centreConfiguration?.subscriptionExtraAmount?.value,
    )

    const dataVoucher = {
      centre: { _id: centreAdminDetail._id, name: centreAdminDetail?.name },
      subscriptionPlan: centreConfiguration?.subscriptionPlan,
      subscriptionAgendasQuantity: centreConfiguration?.subscriptionAgendasQuantity,
      subscriptionExtraAmount: centreConfiguration?.subscriptionExtraAmount,
      subscriptionPaymentType: centreConfiguration?.subscriptionPaymentType,
      currency: centreAdminDetail?.country || 'ARG',
      amount: finalPrice,
    }

    setDataVoucher(dataVoucher)
    setOpenVoucherPopUp(true)
  }

  return (
    <div className={s.mainContainer}>
      {isLoading ? (
        <LoaderCircle size={50} />
      ) : (
        <div>
          <PaymentsTable setOpenVoucherPopUp={setOpenVoucherPopUp} />
          <div className={s.contButtons}>
            <CaluButton
              color="primary"
              size="medium"
              onClick={() => handleGenerateVoucher()}
              disabled={handleDisabledButton()}
            >
              Generar cupón de pago
            </CaluButton>
          </div>
        </div>
      )}

      {openVoucherPopUp && (
        <VoucherPopUp open={openVoucherPopUp} setOpen={setOpenVoucherPopUp} dataVoucher={dataVoucher} />
      )}
    </div>
  )
}

export default InfoPayments
