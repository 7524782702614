import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from '@/context/UserProvider'
import { CENTRE_ADMIN_SELECTED } from '@/store/centres'
import { getDetailInfoUserCentreAdmin, handlePaginationChangeCentres } from '@/store/centres/actions'
import { resetDoctorCentreState } from '@/store/doctorCentres/actions'
import { resetMachineState } from '@/store/machines/actions'
import { showToast } from '@/utils/toast'
import CircleIcon from '@mui/icons-material/Circle'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import KeyIcon from '@mui/icons-material/Key'
import { Box, Button, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import dayjs from 'dayjs'

import s from '../../styles.module.scss'

const AdminTable = ({ setOpen, searchText, selectedStatus, setLoadingDataUser }) => {
  const dispatch = useDispatch()
  const { syncUser } = useUser()

  const { allCentres, paginationData, isLoading } = useSelector((state) => state?.centres)

  const { limit, totalDocs, page } = paginationData || {}

  const handleClickEdit = async (data) => {
    setOpen(true)
    setLoadingDataUser(true)

    dispatch(CENTRE_ADMIN_SELECTED(data))
    await dispatch(getDetailInfoUserCentreAdmin(data?._id))
    setLoadingDataUser(false)
  }

  const handleClickSetSelectedEntity = async (data) => {
    dispatch(resetDoctorCentreState())
    dispatch(resetMachineState())

    const entity = {
      _id: data?._id,
      role: 'admin',
    }

    const auxEntity = JSON.stringify(entity)
    await localStorage.setItem('selectedEntity', auxEntity)

    syncUser()

    showToast('Centro seleccionado correctamente', 'success')
  }

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  }

  const columns = [
    {
      field: 'status',
      headerName: '',
      minWidth: 100,
      maxWidth: 120,
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title={params?.row?.raw?.status}>
          <CircleIcon />
        </Tooltip>
      ),
    },
    {
      field: 'name',
      headerName: 'Nombre',
      minWidth: 100,
      ...stylesColumns,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 150,
      ...stylesColumns,
    },
    {
      field: 'creationDate',
      headerName: 'Fecha de creación',
      minWidth: 100,
      ...stylesColumns,
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      flex: 1,
      ...stylesColumns,
    },
    {
      field: 'key',
      headerName: '',
      minWidth: 100,
      maxWidth: 140,
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title="Usar este centro">
          <Button
            onClick={() => handleClickSetSelectedEntity(params?.value)}
            className={s.rowButton}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            <KeyIcon />
          </Button>
        </Tooltip>
      ),
    },
    {
      field: 'raw',
      headerName: '',
      minWidth: 100,
      maxWidth: 140,
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title="Editar">
          <Button
            onClick={() => handleClickEdit(params?.value)}
            className={s.rowButton}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            <EditOutlinedIcon />
          </Button>
        </Tooltip>
      ),
    },
  ]

  const rows = allCentres?.map((entry) => {
    return {
      id: entry?.id,
      name: entry?.name,
      email: entry?.email || '-',
      creationDate: dayjs(entry?.creationDate).format('YYYY-MM-DD'),
      status: entry?.status,
      key: entry,
      raw: entry,
    }
  })

  const handlePaginationChange = ({ page, pageSize }) => {
    dispatch(handlePaginationChangeCentres(page + 1, pageSize, searchText, selectedStatus))
  }

  return (
    <Box
      sx={{
        px: 0,
        '& .header': {
          fontSize: '14px',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
        '& .redColor': {
          color: '#e74c3c',
          fontWeight: 800,
        },
        '& .greenColor': {
          color: '#1ab395',
          fontWeight: 800,
        },
        '& .yellowColor': {
          color: '#f1c40f',
          fontWeight: 800,
        },
        '& .centreName': {
          fontWeight: 'bold',
        },
      }}
    >
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: limit,
            },
          },
        }}
        rows={rows || []}
        rowCount={totalDocs || 0}
        loading={isLoading}
        columns={columns}
        getCellClassName={(params) => {
          if (params.row.name && params.field === 'name') {
            return 'centreName'
          }
          if (params.row.status === 'demo' && params.field === 'status') {
            return 'yellowColor'
          }
          if (params.row.status === 'active' && params.field === 'status') {
            return 'greenColor'
          }
          if (params.row.status === 'blocked' && params.field === 'status') {
            return 'redColor'
          }
        }}
        paginationMode="server"
        pagination
        onPaginationModelChange={handlePaginationChange}
        pageSizeOptions={[30, 50, 100]}
        disableRowSelectionOnClick={true}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        slotProps={{
          pagination: {
            labelRowsPerPage: 'Entradas por página:',
            labelDisplayedRows: () =>
              `${(page - 1) * limit + 1} - ${page * limit > totalDocs ? totalDocs : page * limit} de ${totalDocs}`,
          },
        }}
        sx={{ fontSize: '13px' }}
        rowHeight={45}
        autoHeight={true}
      />
    </Box>
  )
}

export default AdminTable
