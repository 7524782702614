/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import { useUser } from '@/context/UserProvider'
import { DateRangeDinamicStyles } from '@/pages/AgendaPage/forms/downloadTurns/component/DateRangeDinamicStyles'
import { fetchTurns } from '@/services/turns'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { datesBetween } from '@/utils/functions/datesBetween'
import { Grid } from '@mui/material'
import { DateCalendar } from '@mui/x-date-pickers'

import dayjs from 'dayjs'
import ConfirmHolidaysPopUp from './ConfirmHolidaysPopUp'

import s from './styles.module.scss'

const Calendars = () => {
  const { defaultCentre } = useUser()

  const [startDate, setStartDate] = useState(dayjs(new Date()).format())
  const [finishDate, setFinishDate] = useState(startDate)
  const [isLoading, setIsLoading] = useState(false)
  const [confirmPopUp, setConfirmPopUp] = useState(false)
  const [filterActiveTurns, setFilterActiveTurns] = useState([])
  const [dataToUpdateCentre, setDataToUpdateCentre] = useState({})

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const handleSetStartDate = (newDate) => {
    setStartDate(dayjs(newDate).format())
    setFinishDate(dayjs(newDate).format())
  }

  const daysToDifference = (daySelected) => {
    let day = dayjs(daySelected)
    let currentDay = dayjs(dayjs(new Date()).format('YYYY-MM-DD'))
    let difference = currentDay.diff(day, 'days')
    return difference
  }

  const ableToPush = (day, state) => {
    let formattedDay = day.slice(0, 10)
    if (daysToDifference(formattedDay) <= 7 && !state.includes(day)) return true
    return false
  }

  const handleAddHolidays = async () => {
    setIsLoading(true)
    let auxCentreHolidays = [...centreDetail.holidays]
    auxCentreHolidays = auxCentreHolidays.map((elem) => elem.slice(0, 10))

    let dataToGetTurns = {
      centre: defaultCentre,
      start: dayjs(startDate).utc(true).hour(0).minute(0).second(0).toISOString(),
      finish: dayjs(finishDate).utc(true).hour(23).minute(59).second(59).toISOString(),
    }

    let fetchTurnsForSpecificDate = await fetchTurns(dataToGetTurns)
    let filterActiveTurns = fetchTurnsForSpecificDate?.filter((turn) => turn?.state !== 'cancelled')
    setFilterActiveTurns(filterActiveTurns)

    let startDay = startDate.slice(0, 10)
    let finishDay = finishDate.slice(0, 10)
    let arrayWithDaysBetween = []

    arrayWithDaysBetween = datesBetween(startDay, finishDay)
    arrayWithDaysBetween.map((elem) => {
      if (ableToPush(elem, auxCentreHolidays)) {
        auxCentreHolidays.push(elem)
      }
      return elem
    })

    auxCentreHolidays = auxCentreHolidays.map((elem) => {
      return dayjs(elem).utc(true).format()
    })

    auxCentreHolidays = auxCentreHolidays.filter((item, i) => {
      return auxCentreHolidays.indexOf(item) === i
    })

    let dataUpdate = {
      ...centreDetail,
      holidays: auxCentreHolidays,
    }

    setDataToUpdateCentre(dataUpdate)
    setConfirmPopUp(true)
    setIsLoading(false)
  }

  const renderWeekPickerDay = (props) => {
    const { day, selectedDay, ...other } = props

    const dayIsBetween = day.isBetween(startDate, finishDate, 'day', '[]')
    const isFirstDay = day.isSame(startDate, 'day')
    const isLastDay = day.isSame(finishDate, 'day')

    return (
      <DateRangeDinamicStyles
        {...other}
        day={day}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    )
  }

  return (
    <Grid container className={s.container}>
      <Grid item xs={12}>
        <p className={s.subtitle}>{`Cargar intervalos en los que ${capitalizeFirstLetters(
          centreDetail?.name,
        )} permanecerá cerrado`}</p>
      </Grid>
      <Grid item xs={12} sm={6}>
        <span className={s.spanCalendar}>
          Desde (<a onClick={() => setStartDate(dayjs(new Date()).format())}>hoy</a>)
        </span>

        <DateCalendar value={dayjs(startDate)} onChange={handleSetStartDate} slots={{ day: renderWeekPickerDay }} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <span className={s.spanCalendar}>
          Hasta (<a onClick={() => setFinishDate(startDate)}>hoy</a>)
        </span>
        <DateCalendar
          value={dayjs(finishDate)}
          onChange={(newDate) => setFinishDate(dayjs(newDate).format())}
          minDate={dayjs(startDate)}
          disableHighlightToday={true}
          slots={{ day: renderWeekPickerDay }}
        />
      </Grid>
      <Grid item xs={12} className={s.containerBtn}>
        <CaluButton color="primary" size="medium" loading={isLoading} onClick={handleAddHolidays}>
          Agregar
        </CaluButton>
      </Grid>

      <ConfirmHolidaysPopUp
        open={confirmPopUp}
        setOpen={setConfirmPopUp}
        startDate={startDate}
        finishDate={finishDate}
        filterActiveTurns={filterActiveTurns}
        dataToUpdateCentre={dataToUpdateCentre}
      />
    </Grid>
  )
}

export default Calendars
