import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_AVAILABLE_NOTIFICATIONS } from '@/store/notifications'
import { markNotificationAsRead } from '@/store/notifications/actions'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { NotificationsActive } from '@mui/icons-material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { IconButton, Menu, Tooltip } from '@mui/material'
import Badge from '@mui/material/Badge'

import navbarStyles from '../../styles.module.scss'
import s from './styles.module.scss'

const UserNotifications = () => {
  const dispatch = useDispatch()
  const userNotifications = useSelector((state) => state.notifications.allNotifications)
  const [showUserNotifications, setShowUserNotifications] = useState(false)
  const [currentTarget, setCurrentTarget] = useState(document.firstElementChild)
  const [unReadNotificationsLength, setUnReadNotificationsLength] = useState(0)

  useEffect(() => {
    const notificationsNoRead = userNotifications.filter((notification) => !notification.isRead).length
    setUnReadNotificationsLength(notificationsNoRead)
  }, [userNotifications])

  const handleNotificationRead = (notificationId) => {
    mxp.track(mxp.events.navbar.notificationsRead)
    dispatch(SET_AVAILABLE_NOTIFICATIONS(false))
    dispatch(markNotificationAsRead(notificationId))
    setShowUserNotifications(false)
  }

  return (
    <>
      <Tooltip title="Notificaciones">
        <IconButton
          className={navbarStyles.iconButton}
          onClick={(e) => {
            mxp.track(mxp.events.navbar.notificationsClicked)
            setShowUserNotifications(true)
            setCurrentTarget(e.currentTarget)
          }}
        >
          <Badge badgeContent={unReadNotificationsLength} color="error">
            <NotificationsActive />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={currentTarget}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showUserNotifications}
        onClose={() => setShowUserNotifications(false)}
      >
        <div className={s.divContainer}>
          {userNotifications.map((notification, i) => {
            if (notification.isRead) {
              return (
                <div className={notification.highlight ? s.highlight : s.divNotification} key={i}>
                  <div
                    dangerouslySetInnerHTML={{ __html: notification.message }}
                    className={notification.highlight ? s.marginHighlight : s.marginNoHighlight}
                  />
                </div>
              )
            } else {
              return (
                <div className={notification.highlight ? s.highlight : s.divNotification} key={i}>
                  <div
                    dangerouslySetInnerHTML={{ __html: notification.message }}
                    className={notification.highlight ? s.marginHighlight : s.marginNoHighlight}
                  />
                  <Tooltip title="Marcar leída">
                    <IconButton onClick={() => handleNotificationRead(notification._id)}>
                      <CheckCircleOutlineIcon className={s.checkCircle} />
                    </IconButton>
                  </Tooltip>
                </div>
              )
            }
          })}
        </div>
      </Menu>
    </>
  )
}

export default UserNotifications
