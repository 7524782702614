import React, { useState } from 'react'
import defaultUserImage from '@/assets/images/default.png'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import ImageCircle from '@/components/imageCircle'
import { useDeleteImage } from '@/hooks/useDeleteImage'
import { useUploadImage } from '@/hooks/useUploadImage'
import { updateSecretaryCentre } from '@/store/secretaryCentre/actions'
import { Grid } from '@mui/material'

import s from './styles.module.scss'

const DetailSecretary = ({ selectedSecretary }) => {
  const { loadingImage, uploadImage } = useUploadImage()
  const { deleteProfileImage } = useDeleteImage()

  const [openDeleteImage, setOpenDeleteImage] = useState(false)

  return (
    <Grid container className={`${s.container} animate__animated animate__fadeIn`}>
      <Grid item xs={12}>
        <div className={s.containerImg}>
          <ImageCircle
            image={selectedSecretary?.image}
            defaultImage={defaultUserImage}
            uploadImage={(e) => uploadImage(e, 'image', updateSecretaryCentre, selectedSecretary, true)}
            loading={loadingImage}
            className="changeProfileImage"
            id={'secretaryImage'}
          />
        </div>
        {selectedSecretary?.image && (
          <small className={s.deleteImageButton} onClick={() => setOpenDeleteImage(true)}>
            Eliminar
          </small>
        )}
        <AlertDialogSlide
          open={openDeleteImage}
          setOpen={setOpenDeleteImage}
          textContent={'¿Confirma que quiere eliminar la imagen?'}
          textBackButton="Cancelar"
          textConfirmationButton={'Confirmar'}
          onClose={() => deleteProfileImage('image', selectedSecretary, updateSecretaryCentre, true)}
        />
      </Grid>
      <Grid item xs={12} className={s.title}>
        <p>
          {selectedSecretary?.lastname.toUpperCase()}, {selectedSecretary?.firstname?.toUpperCase()}
        </p>
      </Grid>
    </Grid>
  )
}

export default DetailSecretary
