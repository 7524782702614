import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import { getSubscriptionPaymentsBackup } from '@/store/subscriptionPayments/actions'
import convertCSVToExcelAndDownload from '@/utils/functions/convertCSVToExcelAndDownload'
import { showToast } from '@/utils/toast'
import { Grid } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import dayjs from 'dayjs'

import s from '../styles.module.scss'

const ExportSubscriptionPaymentsContent = () => {
  const dispatch = useDispatch()

  const [from, setFrom] = useState(dayjs())
  const [to, setTo] = useState(dayjs())

  const { isLoading } = useSelector((state) => state.subscriptionPayments)

  const handleDownloadBackupSubscriptionPayments = async () => {
    const params = {
      startDate: dayjs(from).utc(true).startOf('month').format(),
      endDate: dayjs(to).utc(true).startOf('month').format(),
    }

    const csvContent = await dispatch(getSubscriptionPaymentsBackup(params))

    if (csvContent) {
      try {
        convertCSVToExcelAndDownload(
          csvContent,
          `backup_suscripciones_${dayjs(from).format('MM-YYYY')}_a_${dayjs(to).format('MM-YYYY')}`,
        )
      } catch (error) {
        showToast('Se produjo un error en la descarga', 'error')
      }
    }
  }

  const handleCalculateMinDateCalendar = () => {
    let fromDate = dayjs(from)
    let newDate = fromDate.add(6, 'month')
    let today = dayjs()

    if (newDate.isAfter(today)) {
      newDate = today
    }

    return newDate
  }

  useEffect(() => {
    handleCalculateMinDateCalendar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from])

  return (
    <Grid container spacing={3} className={s.mainContainerPayments}>
      <Grid item>
        <DatePicker
          label="Desde"
          value={from}
          views={['month', 'year']}
          format="MM/YYYY"
          maxDate={dayjs()}
          onChange={(newValue) => {
            setFrom(newValue)
          }}
          slotProps={{
            textField: {
              required: true,
              variant: 'standard',
            },
          }}
        />
      </Grid>
      <Grid item>
        <DatePicker
          label="Hasta"
          value={to}
          views={['month', 'year']}
          format="MM/YYYY"
          minDate={from}
          maxDate={handleCalculateMinDateCalendar()}
          onChange={(newValue) => {
            setTo(newValue)
          }}
          onError={(error) => {
            if (error) {
              setTo(dayjs(from))
            }
          }}
          slotProps={{
            textField: {
              required: true,
              variant: 'standard',
            },
          }}
        />
      </Grid>
      <Grid item xs={12} className={s.contBtnDownloadPayments}>
        <CaluButton
          color="primary"
          size="medium"
          onClick={handleDownloadBackupSubscriptionPayments}
          loading={isLoading}
        >
          Descargar datos
        </CaluButton>
      </Grid>
    </Grid>
  )
}

export default ExportSubscriptionPaymentsContent
