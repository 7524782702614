import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { sendReportTreatmentByEmail } from '@/store/treatments/actions'
import { Button, TextField } from '@mui/material'

import s from './styles.module.scss'

const SendDetailTreatmentByEmail = ({ treatment, centre }) => {
  const [email, setEmail] = useState('')
  const [sentEmail, setSentEmail] = useState(false)

  const dispatch = useDispatch()
  const centreDetail = {
    _id: centre._id,
    email: centre.email,
    logo: centre.logo,
    name: centre.name,
    phones: centre.phones,
    street: centre.street,
  }

  const handleSendEmail = () => {
    if (!sentEmail) {
      const auxEmail = {
        to: email,
        treatment: {
          ...treatment,
          centre: centreDetail,
        },
      }
      try {
        dispatch(sendReportTreatmentByEmail(auxEmail))
        setEmail('')
        setSentEmail(true)
      } catch (e) {
        console.log(e)
      }
    }
  }
  return (
    <div className={s.divContainer}>
      <h5>Enviar detalle del grupo de sesiones por email</h5>
      <div className={s.containerInput}>
        <TextField
          variant="standard"
          label="Email"
          className={s.widthInput}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />

        <Button variant="contained" className={sentEmail ? s.disabledButton : s.sendButton} onClick={handleSendEmail}>
          ENVIAR
        </Button>
      </div>
    </div>
  )
}

export default SendDetailTreatmentByEmail
