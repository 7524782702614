import {
  addNewSubscriptionPlan,
  deleteSubscriptionPlan,
  fetchSubscriptionPlans,
  putSubscriptionPlan,
} from '@/services/subscriptionPlans'
import {
  FETCH_ALL_SUBSCRIPTION_PLANS,
  LOADED_SUBSCRIPTION_PLAN,
  SUBSCRIPTION_PLAN_CREATED,
  SUBSCRIPTION_PLAN_DELETE,
  SUBSCRIPTION_PLAN_UPDATED,
} from '@/store/subscriptionPlans'
import { showToast } from '@/utils/toast'

const getAllSubscriptionPlans = (params) => async (dispatch) => {
  try {
    const allSubscriptionPlans = await fetchSubscriptionPlans(params)
    dispatch(FETCH_ALL_SUBSCRIPTION_PLANS(allSubscriptionPlans))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const postNewSubscriptionPlan =
  (data, hideAlert = false) =>
  async (dispatch) => {
    try {
      const newPlan = await addNewSubscriptionPlan(data)
      dispatch(SUBSCRIPTION_PLAN_CREATED(newPlan))
      if (newPlan && !hideAlert) {
        showToast('Plan agregado correctamente', 'success')
      }
      return newPlan
    } catch (error) {
      const { message } = error
      console.log('Error message: ', message)
      showToast('Error al crear el plan', 'error')
    }
  }

const deleteSubscriptionPlanAdded = (id) => async (dispatch) => {
  try {
    await deleteSubscriptionPlan(id)
    dispatch(SUBSCRIPTION_PLAN_DELETE(id))
    showToast('Plan Eliminado', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateSubscriptionPlan = (data, hideAlert) => async (dispatch) => {
  try {
    const planUpdated = await putSubscriptionPlan(data)
    dispatch(SUBSCRIPTION_PLAN_UPDATED(planUpdated))
    if (planUpdated && !hideAlert) {
      showToast('Plan actualizado correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const isLoadedSubscriptionPlans = (data) => async (dispatch) => {
  try {
    dispatch(LOADED_SUBSCRIPTION_PLAN(data))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export {
  deleteSubscriptionPlanAdded,
  getAllSubscriptionPlans,
  isLoadedSubscriptionPlans,
  postNewSubscriptionPlan,
  updateSubscriptionPlan,
}
