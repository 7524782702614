import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import CaluInput from '@/components/styledComponents/CaluInput'
import { CaluSelect } from '@/components/styledComponents/CaluSelect'
import { birthDayList } from '@/helpers/constants'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { Grid } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const PatientsSearchBar = ({
  addNewPatient,
  inputSearch,
  setInputSearch,
  setOpenPopUpTags,
  selectedTag,
  setSelectedTag,
  setSelectedBirth,
  birthSelectValue,
  setBirthSelectValue,
}) => {
  const { t } = useTranslation('global')

  const allPatientTags = useSelector((state) => state?.patientTags?.all)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const handleChangeSelectedTag = (e) => {
    setSelectedTag(e.target.value)
  }

  const handleChangeSelectedBirth = (e) => {
    let selectedValue = e.target.value
    let birthDates = {
      birthStart: '',
      birthFinish: '',
    }

    if (selectedValue === '-') {
      setBirthSelectValue('-')
      setSelectedBirth(birthDates)
      return
    }

    if (selectedValue === 'today') {
      birthDates = {
        birthStart: dayjs().utc(true).hour(0).minute(0).second(0).format(),
        birthFinish: dayjs().utc(true).hour(23).minute(59).second(59).format(),
      }
      setSelectedBirth(birthDates)
      setBirthSelectValue('today')
      return
    }

    if (selectedValue === 'week') {
      birthDates = {
        birthStart: dayjs().startOf('week').utc(true).hour(0).minute(0).second(0).format(),
        birthFinish: dayjs().endOf('week').utc(true).hour(23).minute(59).second(59).format(),
      }
      setSelectedBirth(birthDates)
      setBirthSelectValue('week')
      return
    }

    if (selectedValue === 'month') {
      birthDates = {
        birthStart: dayjs().startOf('month').utc(true).hour(0).minute(0).second(0).format(),
        birthFinish: dayjs().endOf('month').utc(true).hour(23).minute(59).second(59).format(),
      }
      setSelectedBirth(birthDates)
      setBirthSelectValue('month')
      return
    }
  }

  return (
    <Grid container className={s.mainContainer}>
      <Grid item xs={12} lg={4}>
        <CaluInput
          id="search"
          type="text"
          autoComplete="off"
          labelText={`Buscar ${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}`}
          value={inputSearch}
          onChange={(e) => setInputSearch(e.target.value)}
          className={s.searchBarContainer}
        />
      </Grid>

      <Grid container item xs={12} lg={4} className={s.selectContainer}>
        <Grid item xs={12} lg={6}>
          <CaluSelect
            labelText="Filtrar por etiquetas"
            firstOptionText={'Todas'}
            firstOptionValue={'-'}
            value={selectedTag}
            onChange={handleChangeSelectedTag}
            items={allPatientTags}
            className={s.inputsSelect}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CaluSelect
            labelText="Filtrar por cumpleaños"
            value={birthSelectValue}
            onChange={handleChangeSelectedBirth}
            items={birthDayList}
            className={s.inputsSelect}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} lg={4} className={s.containerButtonNewEntry}>
        <CaluButton color="primary" type="add" size="large" onClick={addNewPatient}>
          Crear {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}
        </CaluButton>

        <CaluButton color="secondary" size="large" className={s.bgButton} onClick={() => setOpenPopUpTags(true)}>
          Gestionar etiquetas
        </CaluButton>
      </Grid>
    </Grid>
  )
}

export default PatientsSearchBar
