import React, { useEffect, useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import CardMsgWithIcon from '@/components/CardMsgWithIcon'
import { useUser } from '@/context/UserProvider'
import Layout1 from '@/layouts/Layout1'
import CustomErrorCard from '@/pages/LinkDoctorOrSecretaryRegisterPage/CustomErrorCard'
import { getVerificationData, onlyVerificationToken } from '@/store/users/actions'

import s from './styles.module.scss'

const LinkDoctorOrSecretaryVerificationPage = () => {
  const dispatch = useDispatch()
  const TOKEN = useParams().TOKEN
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)

  const { user, logout } = useUser()
  const { verificationStatus, verificationData } = useSelector((state) => state.users)

  const isSameUser = verificationData?.email === user?.email
  const role = pathname.includes('profesional') ? 'doctor' : 'secretary'

  const handleVerificationToken = async () => {
    await dispatch(onlyVerificationToken(TOKEN, role))
  }

  const setInit = async () => {
    await dispatch(getVerificationData(TOKEN, role))
  }

  useEffect(() => {
    if (TOKEN && role) setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!verificationData) return

    if (isSameUser && verificationStatus === null) {
      handleVerificationToken()
      setIsLoading(false)
      return
    }

    if (!user || verificationStatus === false) {
      setIsLoading(false)
      return
    }

    setIsLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, verificationData, verificationStatus])

  const handleLogoutAndRedirect = () => {
    logout()
    navigate('/')
  }

  const showErrorsMessages = () => {
    if (verificationData && !user) {
      return (
        <div className={s.container}>
          <CustomErrorCard
            title={`Error! Debes conectarte con el email (${verificationData?.email}) para poder aceptar esta invitación.`}
            text1={`Por favor conectate con el email (${verificationData?.email}) y luego vuelve a ingresar al enlace que recibiste en tu correo electrónico.`}
            textButton="Conectarme"
            onClick={() => (window.location.href = '/ingresar')}
          />
        </div>
      )
    }

    if (verificationData && !isSameUser) {
      return (
        <div className={s.container}>
          <CustomErrorCard
            title="Error! Tu cuenta actual no coincide con el email de la invitación"
            text1={`Por favor conectate con el email (<b>${verificationData?.email}</b>) y luego vuelve a ingresar al enlace que recibiste en tu correo electrónico.`}
            textButton="Cambiar de cuenta"
            onClick={handleLogoutAndRedirect}
          />
        </div>
      )
    }
  }

  return (
    <Layout1 navbar isLoading={isLoading}>
      {verificationStatus === null && showErrorsMessages()}
      {verificationStatus && (
        <div className={s.container}>
          <CardMsgWithIcon
            icon={<FaCheck />}
            iconClass="greenColor"
            title="Identidad verificada correctamente"
            text="Ya puedes acceder al sistema haciendo clic en INGRESAR"
            textButton="INGRESAR"
            path="/ingresar"
          />
        </div>
      )}

      {verificationStatus === false && (
        <div className={s.container}>
          <CardMsgWithIcon
            icon={<IoClose />}
            title="Ups! Ocurrió un error"
            text="La ruta ingresada no es válida"
            textButton="Volver al inicio"
            path="/"
          />
        </div>
      )}
    </Layout1>
  )
}

export default LinkDoctorOrSecretaryVerificationPage
