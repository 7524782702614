import React from 'react'
import TitlePopUp from '@/components/TitlePopUp'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'

import FormInternalCentreNote from './formInternalCentreNote'

const InternalCentreNotePopUp = ({ open, setOpen, dataNote }) => {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} open={open} maxWidth="md" fullWidth onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle={`${dataNote ? 'Editar nota' : 'Nueva nota'}`} handleClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <FormInternalCentreNote formValues={dataNote} handleClosePopUp={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default InternalCentreNotePopUp
