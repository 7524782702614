import React, { useEffect, useState } from 'react'
import { useController, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import { CheckboxField } from '@/components/Form/customFields/CheckboxField'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { SelectField } from '@/components/Form/customFields/SelectField'
import { CaluFormSelect } from '@/components/formComponents/customFields/CaluFormSelect'
import CaluButton from '@/components/styledComponents/CaluButton'
import { optionsSelectIsCalu, subscriptionStatus, typeOfCategories } from '@/helpers/constants'
import { deleteCentreAdded } from '@/store/centres/actions'
import { Grid, MenuItem } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false, onClose }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const checkBoxColor = '#f8ac59'

  const [openAlertDelete, setOpenAlertDelete] = useState(false)

  const centreAdminDetail = useSelector((state) => state?.centres?.centreAdminDetail)

  const watchEnableTurnsPaymentLinkModule = useWatch({ name: 'centreConfiguration.enableTurnsPaymentLinkModule' })
  const watchHasWappModule = useWatch({ name: 'centreConfiguration.hasWappModule' })
  const { field: fieldMercadopagoApplicationFee } = useController({
    name: 'centreConfiguration.mercadopagoApplicationFee',
  })

  useEffect(() => {
    if (watchEnableTurnsPaymentLinkModule) {
      fieldMercadopagoApplicationFee.onChange(centreAdminDetail?.centreConfiguration?.mercadopagoApplicationFee || 0.49)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteCentre = () => {
    dispatch(deleteCentreAdded(centreAdminDetail._id))
    setOpenAlertDelete(false)
    onClose()
  }

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <CustomField label="Nombre del centro *" name="name" fullWidth />
        </Grid>

        <Grid item xs={5} className={s.status}>
          <SelectField label="Estado" name="status" type="text" fullWidth>
            {subscriptionStatus?.map((plan) => (
              <MenuItem key={plan.value} value={plan.value}>
                {`${plan.name}`}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        <Grid item xs={12}>
          <SelectField label="Categoría" name="category" type="text" fullWidth>
            {typeOfCategories?.map((category) => (
              <MenuItem key={category.value} value={category.value}>
                {`${category.name}`}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        <Grid item xs={12}>
          <p className={s.subTitle}>Módulos disponibles</p>
        </Grid>

        <Grid item xs={12}>
          <CheckboxField label={`Landing de ${t('turnLabel.p')} online`} name="visible" checkBoxColor={checkBoxColor} />
        </Grid>

        <Grid item xs={12} style={{ paddingTop: '0px' }}>
          <CheckboxField label={`Mensajes SMS`} name="hasSMS" checkBoxColor={checkBoxColor} />
        </Grid>

        <Grid item xs={12} style={{ paddingTop: '0px' }}>
          <CheckboxField label={`Tratamientos`} name="hasTreatments" checkBoxColor={checkBoxColor} />
        </Grid>

        <Grid item xs={12} style={{ paddingTop: '0px' }}>
          <CheckboxField label={`Recetas`} name="hasPrescriptionsModule" checkBoxColor={checkBoxColor} />
        </Grid>

        <Grid item xs={12} style={{ paddingTop: '0px' }}>
          <CheckboxField label={`Gestión / Caja`} name="hasCashboxModule" checkBoxColor={checkBoxColor} />
        </Grid>

        <Grid item xs={12} style={{ paddingTop: '0px' }}>
          <CheckboxField label={`Stock / Productos`} name="hasStockModule" checkBoxColor={checkBoxColor} />
        </Grid>

        <Grid item container spacing={2} style={{ paddingTop: '0px', display: 'flex', alignItems: 'flex-end' }}>
          <Grid item xs={12} sm={6}>
            <CheckboxField
              label={`(Beta) Módulo WAPP`}
              name="centreConfiguration.hasWappModule"
              checkBoxColor={checkBoxColor}
            />
          </Grid>
          {watchHasWappModule && (
            <Grid item xs={12} sm={6}>
              <CustomField
                label="Cantidad máxima de mensajes por mes"
                name="centreConfiguration.maxWappMessages"
                fullWidth
                type="number"
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} style={{ paddingTop: '0px' }}>
          <CheckboxField
            label={`(Beta) Ver cupones de pago`}
            name="centreConfiguration.enableMyPayments"
            checkBoxColor={checkBoxColor}
          />
        </Grid>

        <Grid
          item
          container
          spacing={2}
          style={{ paddingTop: '0px', display: 'flex', alignItems: 'flex-end' }}
          className={s.lastItem}
        >
          <Grid item xs={12} sm={6}>
            <CheckboxField
              label={`(Beta) Habilitar enlace a Mercado Pago`}
              name="centreConfiguration.enableTurnsPaymentLinkModule"
              checkBoxColor={checkBoxColor}
            />
          </Grid>
          {watchEnableTurnsPaymentLinkModule && (
            <Grid item xs={12} sm={6}>
              <CustomField
                label="Porcentaje de cargo por el uso de la app"
                name="centreConfiguration.mercadopagoApplicationFee"
                fullWidth
                type="number"
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <p className={s.subTitle}>Configuración emails</p>
        </Grid>

        <Grid item xs={12}>
          <CheckboxField label={`Botón cancelar en emails`} name="noEmailCancelButton" checkBoxColor={checkBoxColor} />
        </Grid>

        <Grid item xs={12} style={{ paddingTop: '0px' }} className={s.lastItem}>
          <CheckboxField
            label={`Botón confirmar en emails`}
            name="noEmailConfirmButton"
            checkBoxColor={checkBoxColor}
          />
        </Grid>

        <Grid item xs={12}>
          <p className={s.subTitle}>Otras configuraciones</p>
        </Grid>

        <Grid item xs={12}>
          <CheckboxField
            label={`Descargar recordatorios`}
            name="centreConfiguration.enableDownloadReminders"
            checkBoxColor={checkBoxColor}
          />
        </Grid>

        <Grid item xs={12}>
          <CheckboxField
            label={`Ocultar edición de horarios laborales en profesionales`}
            name="centreConfiguration.hideDoctorWorkTimes"
            checkBoxColor={checkBoxColor}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomField label="Webhook para notificaciones" name="centreConfiguration.webhookUrl" fullWidth />
        </Grid>

        <Grid item xs={12} style={{ paddingTop: '0px', marginTop: '20px' }} className={s.lastItem}>
          <CaluFormSelect items={optionsSelectIsCalu} labelText="Mensaje migración a Calu" name="isCalu" type="text" />
        </Grid>

        <Grid item xs={12} className={s.containerButtonsForm}>
          <CaluButton
            color="primary"
            inverted="true"
            size="medium"
            onClick={() => setOpenAlertDelete(!openAlertDelete)}
            loading={isLoading}
          >
            Eliminar
          </CaluButton>
          <AlertDialogSlide
            title="Eliminar centro"
            open={openAlertDelete}
            setOpen={setOpenAlertDelete}
            textContent={'¿Está seguro que quiere eliminar este centro?'}
            textContentExtraOptional="Esta acción no se podrá deshacer."
            textBackButton="Cancelar"
            textConfirmationButton={'Eliminar'}
            onClose={deleteCentre}
          />

          <CaluButton color="primary" type="submit" size="medium" loading={isLoading}>
            Guardar
          </CaluButton>
        </Grid>
      </Grid>
    </div>
  )
}
