import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import TitlePopUp from '@/components/TitlePopUp'
import { updateClinicHistory } from '@/store/clinicHistory/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { showToast } from '@/utils/toast'
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'

import s from './styles.module.scss'

const PopUpSharedHC = ({
  openPopUp,
  setOpenPopUp,
  doctorsToShareHC,
  allClinicHistories,
  singleClinicHistory,
  globalShared,
  shareWithAll,
}) => {
  const dispatch = useDispatch()

  const allDoctorCentres = useSelector((state) => state.doctorCentres.doctorCentres)

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    setOpenPopUp(false)
  }

  const handleSharedHC = () => {
    let dataToUpdate

    if (globalShared) {
      let updatingHC
      setIsLoading(true)

      if (doctorsToShareHC?.length === 0) {
        updatingHC = allClinicHistories.map(async (ch) => {
          if (ch.isOwner) {
            dataToUpdate = {
              ...ch,
              shared: [],
              shareWithAll: false,
            }

            return await dispatch(updateClinicHistory(dataToUpdate, true))
          }
        })
      } else {
        updatingHC = allClinicHistories.map(async (ch) => {
          if (ch.isOwner) {
            dataToUpdate = {
              ...ch,
              shared: shareWithAll ? allDoctorCentres : doctorsToShareHC,
              shareWithAll: shareWithAll,
            }

            return await dispatch(updateClinicHistory(dataToUpdate, true))
          }
        })
      }

      Promise.all(updatingHC)
        .then((res) => {
          setIsLoading(false)
          handleClose()
          showToast('Fichas compartidas correctamente', 'success')
        })
        .catch((error) => console.log(error))
    } else {
      dataToUpdate = {
        ...singleClinicHistory,
        shared: shareWithAll ? allDoctorCentres : doctorsToShareHC,
        shareWithAll: shareWithAll,
      }
      try {
        dispatch(updateClinicHistory(dataToUpdate))
      } catch (error) {
        console.log(error)
      } finally {
        handleClose()
      }
    }
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={openPopUp} onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={globalShared ? 'Compartir todas las fichas' : 'Compartir ficha individual'}
            handleClose={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <LoaderCircle text="Compartiendo fichas..." />
          ) : (
            <>
              <div className={s.containerPopUp}>
                {shareWithAll ? (
                  <>
                    <p>{globalShared ? 'Va a compartir todas las fichas con:' : 'Va a compartir esta ficha con:'}</p>
                    <ul>
                      {allDoctorCentres?.map((doctor) => (
                        <li key={doctor?._id}>{capitalizeFirstLetters(`${doctor.lastname}, ${doctor.firstname}`)}</li>
                      ))}
                    </ul>
                  </>
                ) : doctorsToShareHC?.length === 0 ? (
                  <p>{globalShared ? '¿Dejar de compartir todas las fichas?' : '¿Dejar de compartir esta ficha?'}</p>
                ) : (
                  <>
                    <p>{globalShared ? 'Va a compartir todas las fichas con:' : 'Va a compartir esta ficha con:'}</p>
                    <ul>
                      {doctorsToShareHC?.map((doctor) => (
                        <li key={doctor?._id}>{capitalizeFirstLetters(`${doctor.lastname}, ${doctor.firstname}`)}</li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
              <div className={s.containerButtons}>
                <Button disableElevation variant="outlined" className={s.cancelButton} onClick={() => handleClose()}>
                  Volver
                </Button>

                <Button disableElevation variant="contained" className={s.confirmButton} onClick={handleSharedHC}>
                  Confirmar
                </Button>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PopUpSharedHC
