import React, { useRef, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { SelectField } from '@/components/Form/customFields/SelectField'
import { SwitchField } from '@/components/Form/customFields/SwitchField'
import ModalDowloadQR from '@/components/ModalDowloadQR'
import CaluButton from '@/components/styledComponents/CaluButton'
import { cancelTimes, limits, patientLimits, starts, timezones } from '@/helpers/constants'
import { baseURL } from '@/helpers/env'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { showToast } from '@/utils/toast'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import { Button, Grid, MenuItem } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import s from './styles.module.scss'

export const FormContent = ({ isLoading = false }) => {
  const { t } = useTranslation('global')
  const slug = useWatch({ name: 'slug' })
  const refSubmitButton = useRef()
  const [openModalQR, setOpenModalQR] = useState(false)

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const handleClick = () => {
    const url = baseURL + slug
    navigator.clipboard.writeText(url)
    showToast('Link copiado en portapapeles', 'success', 2000)
  }

  const handleCloseModalQR = () => {
    setOpenModalQR(false)
  }

  return (
    <div className={`${s.containerForm} animate__animated animate__fadeIn`}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h5 className={s.titleSectionForm}>Tu sitio web</h5>
        </Grid>

        <div className={s.linkContainer}>
          <div className={s.defaultURL}>{baseURL}</div>
          <div className={s.fieldContainer}>
            <CustomField
              label={`Define el enlace que tus clientes deberán utilizar para poder agendar ${t(
                'turnLabel.p',
              )} de manera online.`}
              name="slug"
              fullWidth
            />
          </div>
          <div>
            <Button onClick={handleClick} className={s.copyButton} variant="outlined">
              Copiar
            </Button>
          </div>
          <div>
            <Tooltip
              title={`El cambiar el identificador genera que todos los enlaces previos no funcionen más. Por tal motivo, si modificas el identificador asegúrate de notificar a tus ${t(
                `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`,
              )} de tal cambio.
`}
            >
              <IconButton>
                <ReportProblemIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <CaluButton color="primary" inverted={'true'} size="small" onClick={() => setOpenModalQR(true)}>
          Ver en QR
        </CaluButton>

        <Grid item xs={12}>
          <h5 className={s.titleSectionForm}>Opciones generales</h5>
        </Grid>

        <Grid item md={12}>
          <SwitchField
            label={`Mostrar sólo los primeros tres horarios disponibles de cada dia `}
            name="hideMoreOnlineTurns"
          />
        </Grid>
        <Grid item md={12}>
          <SelectField name="onlineTurnsStart" label={`Permitir tomar ${t('turnLabel.p')} desde`} fullWidth>
            {starts?.map((c) => (
              <MenuItem key={c.code} value={c.code}>
                {c.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        <Grid item container md={12}>
          <Grid item md={12}>
            <SelectField
              name="centreConfiguration[hoursToCancelTurn]"
              label={`Tiempo límite para cancelar ${t('turnLabel.ps')}`}
              fullWidth
            >
              {cancelTimes?.map((c) => (
                <MenuItem key={c.code} value={c.code}>
                  {c.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
        </Grid>

        <Grid item container md={12} spacing={2}>
          <Grid item md={12}>
            <SelectField name="timezone" label="Zona horaria" fullWidth>
              {timezones?.map((t) => (
                <MenuItem key={t.code} value={t.code}>
                  {t.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item md={12}>
            <SelectField
              name="patientOnlineTurns"
              label={`Cantidad máxima de ${t('turnLabel.p')} online activos por ${t(
                `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`,
              )}`}
              fullWidth
            >
              {patientLimits?.map((t) => (
                <MenuItem key={t.value} value={t.value}>
                  {t.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item md={12}>
            <SelectField
              name="onlineTurnsLimit"
              label={`Permitir tomar ${t('turnLabel.p')} hasta dentro de...`}
              fullWidth
            >
              {limits?.map((d) => (
                <MenuItem key={d.value} value={d.value}>
                  {d.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item xs={12}>
            <h5 className={s.titleSectionForm}>Personalización</h5>
          </Grid>

          <Grid item md={12}>
            <CustomField
              label={`Mensaje visible en la central de ${t('turnLabel.p')} online`}
              name="other1"
              fullWidth
            />
          </Grid>

          <Grid item md={12} className={s.containerFieldWpp}>
            <CustomField label={'Link para contactar por WhastsApp'} name="whatsappLink" fullWidth />
            <a
              href="https://postcron.com/es/blog/landings/generador-de-enlaces-para-whatsapp"
              target="_blank"
              rel="noopener noreferrer"
              className={s.moreInfoLink}
            >
              Más info aquí
            </a>
          </Grid>

          <Grid item xs={12}>
            <h5 className={s.titleSectionForm}>Redes sociales</h5>
          </Grid>

          <Grid item md={6} xs={12}>
            <CustomField label="Facebook" name={`socialNetworks[facebook]`} fullWidth />
          </Grid>

          <Grid item md={6} xs={12}>
            <CustomField label="Instagram" name={`socialNetworks[instagram]`} fullWidth />
          </Grid>

          <Grid item md={6} xs={12}>
            <CustomField label="Twitter" name={`socialNetworks[twitter]`} fullWidth />
          </Grid>

          <Grid item md={6} xs={12}>
            <CustomField label="Youtube" name={`socialNetworks[youtube]`} fullWidth />
          </Grid>
        </Grid>
      </Grid>

      <div className={s.containerSaveButton}>
        <CaluButton color="primary" type="submit" size="medium" loading={isLoading} ref={refSubmitButton}>
          Guardar
        </CaluButton>
      </div>

      <ModalDowloadQR open={openModalQR} handleCloseModalQR={handleCloseModalQR} slug={slug} />
    </div>
  )
}
