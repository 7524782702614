import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import useDebounce from '@/hooks/useDebounce'
import { FormControl, MenuItem, Select, TextField } from '@mui/material'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

const SelectWithSearchByParams = ({
  inputLabel,
  valueSelect,
  onChange,
  defaultName,
  className,
  functionToDispatch,
  stateRedux,
  extraParams = null,
}) => {
  const dispatch = useDispatch()

  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)

  const DELAY_TIME = 300
  const inputDebounceValue = useDebounce(searchText, DELAY_TIME)

  useEffect(() => {
    searchEntity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, inputDebounceValue])

  const searchEntity = async () => {
    let params = {
      search: inputDebounceValue,
    }

    if (extraParams) {
      params = {
        ...extraParams,
        ...params,
      }
    }

    setLoading(true)
    await dispatch(functionToDispatch(params))
    setLoading(false)
  }

  const stopImmediatePropagation = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 400,
        width: 550,
        overflow: 'auto',
        marginLeft: '15px',
      },
    },
  }

  return (
    <div className={s.inputContainer}>
      {inputLabel && <div className={s.titleInput}>{inputLabel}</div>}
      <FormControl variant="standard" fullWidth>
        <Select
          sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '2px solid #0F1333' } }}
          value={valueSelect}
          onChange={onChange}
          MenuProps={MenuProps}
          className={className || ''}
          variant={'outlined'}
          inputProps={{
            classes: {
              icon: s.iconSelect,
            },
          }}
        >
          <div className={s.searchBarContainer}>
            <TextField
              onClickCapture={stopImmediatePropagation}
              onKeyDown={(e) => e.stopPropagation()}
              className={s.searchInput}
              id="search"
              size="small"
              autoComplete="off"
              label="Buscar..."
              variant="standard"
              fullWidth={true}
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              autoFocus={true}
            />
          </div>

          {loading ? (
            <LoaderCircle size={30} />
          ) : (
            stateRedux?.map((elem, index) => (
              <MenuItem key={elem._id || index} value={elem._id}>
                {elem?.lastname
                  ? `${elem?.lastname?.toUpperCase()}, ${elem?.firstname?.toUpperCase()}`
                  : elem?.name?.toUpperCase() || `${defaultName} ${index + 1}`}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectWithSearchByParams

SelectWithSearchByParams.propTypes = {
  inputLabel: PropTypes.string.isRequired,
  valueSelect: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultName: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  extraParams: PropTypes.object,
}

SelectWithSearchByParams.defaultProps = {
  defaultName: `${'Tabla'.toUpperCase()}`,
}
