import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useUser } from '@/context/UserProvider'
import Layout1 from '@/layouts/Layout1'
import { Grid } from '@mui/material'

import HeaderOnLineTurns from './components/headerOnLineTurns'
import QuestionCard from './components/questionCard'

import s from './styles.module.scss'

const InfoPatientPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useUser()
  const navigate = useNavigate()
  const centreID = useParams().id

  const {
    turnData,
    patientCentres: selectedPatient,
    confirmedTurn,
    entityData,
  } = useSelector((state) => state.turns?.onLineTurns)

  useEffect(() => {
    setIsLoading(true)
    if (!entityData || !turnData) {
      navigate(`/centro/${centreID}`)
    }

    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout1 navbar isLoading={isLoading}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={s.HeaderTabs}>
          <HeaderOnLineTurns isAuthenticated={!!user} selectedPatient={selectedPatient} confirmedTurn={confirmedTurn} />
        </Grid>

        <Grid item xs={12}>
          <QuestionCard userProfile={user} />
        </Grid>
      </Grid>
    </Layout1>
  )
}

export default InfoPatientPage
