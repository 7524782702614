import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import { useUser } from '@/context/UserProvider'
import Layout1 from '@/layouts/Layout1'
import { getAllCentres } from '@/store/centres/actions'
import { selectedPatient } from '@/store/patients/actions'
import { TURNS_LOADING } from '@/store/turns'
import { getPatientCentreTurnsByDni } from '@/store/turns/actions'
import { toCustomTz } from '@/utils/functions/toCustomTz'

import dayjs from 'dayjs'
import FormCreateProfilePatient from './components/FormCreateProfilePatient'
import MyTurnsPanel from './components/myTurnsPanel'
import RequestNewTurn from './components/myTurnsPanel/components/requestNewTurn'

import s from './styles.module.scss'

const MyTurnsPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useUser()
  const allCentres = useSelector((state) => state.centres?.allCentres)
  const [openPopUp, setOpenPopUp] = useState(false)
  const startDate = dayjs(new Date()).startOf('day').add(-10, 'month').format()
  const finishDate = dayjs(new Date()).startOf('day').add(2, 'month').format()
  const hasExecutedEffect = useRef(false)

  const defaultParams = {
    dni: user?.dni,
    page: 1,
    paginate: true,
    start: toCustomTz(startDate, 'utc', true),
    finish: toCustomTz(finishDate, 'utc', true),
  }

  useEffect(() => {
    getCentres()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user?.patient) {
      dispatch(selectedPatient(user?.patient?._id || user?.patient))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (!hasExecutedEffect.current && user?.dni) {
      getPatientTurnsByDni()
      hasExecutedEffect.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasExecutedEffect, user])

  const getCentres = async () => {
    setIsLoading(true)
    await dispatch(getAllCentres())
    setIsLoading(false)
  }

  const getPatientTurnsByDni = async () => {
    if (user !== null) {
      dispatch(TURNS_LOADING(true))

      await dispatch(getPatientCentreTurnsByDni(defaultParams))
      dispatch(TURNS_LOADING(false))
    }
  }

  const handleClose = () => {
    setOpenPopUp(false)
  }

  return (
    <Layout1 navbar isLoading={isLoading}>
      <div className={s.container}>
        <div className={s.paperTitle}>
          <div className={s.title}>Mis {t('turnLabel.p')}</div>
          {user?.patient && (
            <CaluButton type="add" size="large" color="primary" inverted="true" onClick={() => setOpenPopUp(true)}>
              {`Nueva ${t('turnLabel.s')}`}{' '}
            </CaluButton>
          )}
        </div>

        {user?.patient ? (
          <MyTurnsPanel
            setOpenPopUp={setOpenPopUp}
            defaultParams={defaultParams}
            startDate={startDate}
            finishDate={finishDate}
          />
        ) : (
          <div className={s.containerForm}>
            <FormCreateProfilePatient userData={user} />
          </div>
        )}
        <RequestNewTurn allCentres={allCentres} handleClose={handleClose} open={openPopUp} />
      </div>
    </Layout1>
  )
}

export default MyTurnsPage
