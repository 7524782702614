export const formDefaultValues = {
  name: '',
  type: 'practice',
  description: '',
  duration: '',
  price: '',
  centrePaymentPercentage: '',
  paymentLink: '',
  requirePayment: false,
  paymentPercentage: 100,
}
