import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import CaluButton from '@/components/styledComponents/CaluButton'
import {
  deleteTemplateHCAdded,
  postNewTemplateHC,
  resetSelectedTemplateHC,
  updateTemplateHC,
} from '@/store/clinicHistoryTemplate/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { Autocomplete, Checkbox, TextField } from '@mui/material'

import s from './styles.module.scss'

const FormCreateTemplateHC = ({ handleClose, templateHCSelected, allFieldsHC, entityId, doctorCentreId, isDoctor }) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [orderedFields, setOrderedFields] = useState([])
  const [openAlertDelete, setOpenAlertDelete] = useState(false)

  const icon = <CheckBoxOutlineBlank fontSize="small" />
  const checkedIcon = <CheckBox fontSize="small" />

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      fields: [],
    },
  })

  const watchFields = watch('fields')

  useEffect(() => {
    setOrderedFields(watchFields)
  }, [watchFields])

  const onSubmit = (data, e) => {
    if (!e) return
    e.preventDefault()
    setIsLoading(true)

    if (isDoctor) {
      data.doctorCentre = doctorCentreId
    }

    if (!isDoctor) {
      data.centre = entityId
    }

    data.fields = orderedFields

    try {
      if (templateHCSelected) {
        dispatch(updateTemplateHC(data))
      } else {
        dispatch(postNewTemplateHC(data))
      }
      handleClose()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (templateHCSelected) {
      Object.keys(templateHCSelected).forEach((k) => {
        setValue(k, templateHCSelected[k])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateHCSelected])

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const deleteTemplate = () => {
    dispatch(deleteTemplateHCAdded(templateHCSelected._id))
    dispatch(resetSelectedTemplateHC())
  }

  return (
    <DragDropContext
      onDragEnd={(result) => {
        const { source, destination } = result
        if (!destination) return
        if (source.index === destination.index && source.droppableId === destination.droppableId) return

        setOrderedFields((prevFields) => reorder(prevFields, source.index, destination.index))
      }}
    >
      <form className={s.containerForm} onSubmit={handleSubmit(onSubmit)}>
        <div className={s.content}>
          <h5 className={s.subtitle}>Datos de la ficha de historia clínica personalizada</h5>
          <div className={s.inputForm}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  onKeyDown={handleKeyDown}
                  type="text"
                  label="Nombre identificatorio*"
                  variant="standard"
                  fullWidth
                />
              )}
              rules={{ required: true }}
            />
            {errors.name?.type === 'required' && <p className={s.formAlert}>Nombre requerido</p>}
          </div>

          <div className={s.inputForm}>
            <Controller
              name="fields"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  disableCloseOnSelect
                  options={allFieldsHC}
                  ListboxProps={{ style: { maxHeight: '15rem', fontSize: '14px' } }}
                  getOptionLabel={(option) => capitalizeFirstLetters(option?.name)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option._id}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {capitalizeFirstLetters(option?.name)}
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) => option._id === value._id}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Seleccione nuevos campos para su ficha" variant="standard" />
                  )}
                  onChange={(event, selectedValues) => {
                    setValue('fields', selectedValues)
                  }}
                />
              )}
            />
          </div>

          <div className={s.message}>
            <small>
              Si necesitás algún campo para tu ficha que no se encuentre en el listado,
              <br />
              solicitalo en el 'Chat con soporte' y lo agregaremos a la brevedad
            </small>
          </div>

          <div className={s.divider}></div>

          <div>
            <h5 className={s.subtitle}>Arrastre los campos para ordenarlos según su preferencia</h5>
            <small className={s.smallDescription}>Los campos en gris oscuro no se pueden reordenar</small>
          </div>

          <div className={s.fieldsList}>
            <p className={s.item}>Fecha de visita</p>
            <p className={s.item}>Asunto</p>
            <Droppable droppableId="fieldsList">
              {(droppableProvided) => (
                <ul {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                  {orderedFields?.map((field, index) => {
                    return (
                      <Draggable key={field._id} draggableId={field._id} index={index}>
                        {(draggableProvided) => (
                          <li
                            {...draggableProvided.draggableProps}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.dragHandleProps}
                            className={s.newItem}
                          >
                            {capitalizeFirstLetters(field?.name)}
                          </li>
                        )}
                      </Draggable>
                    )
                  })}
                  {droppableProvided.placeholder}
                </ul>
              )}
            </Droppable>
            <p className={s.item}>Observaciones</p>
          </div>

          <div className={s.divider2}></div>
        </div>
        <div className={s.containerBtnSave}>
          {templateHCSelected && (
            <CaluButton color="error" size="medium" onClick={() => setOpenAlertDelete(!openAlertDelete)}>
              Eliminar
            </CaluButton>
          )}
          <CaluButton color="primary" size="medium" type="submit" loading={isLoading}>
            Guardar
          </CaluButton>
        </div>
        <AlertDialogSlide
          title="Eliminar tipo de ficha"
          open={openAlertDelete}
          setOpen={setOpenAlertDelete}
          textContent={'¿Realmente quiere eliminar el tipo de ficha?'}
          textContentExtraOptional="Esta acción no se podrá deshacer."
          textBackButton="Cancelar"
          textConfirmationButton={'Eliminar'}
          onClose={deleteTemplate}
        />
      </form>
    </DragDropContext>
  )
}

export default FormCreateTemplateHC
