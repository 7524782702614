import React from 'react'
import { useTranslation } from 'react-i18next'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'

import CardCentreInformation from '../cardCentreInformation'

import s from './styles.module.scss'

const LandingHeader = ({ centre }) => {
  const { t } = useTranslation('global')
  const imageURL = centre?.image?.length ? centre?.image : null

  return (
    <div>
      <div
        className={s.logoHeader}
        style={
          imageURL
            ? {
                backgroundImage: `url('${imageURL}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }
            : {
                backgroundColor: '#7fd5f4',
              }
        }
      >
        <div className={s.headerContent}>
          {centre?.logo ? (
            <img className={s.centrePhoto} src={centre?.logo} alt="Logo centro" />
          ) : (
            <div className={s.defaultImage}>
              <div className={s.contDefaultImage}>
                <ImageOutlinedIcon className={s.itemDefaultImage} />
              </div>
            </div>
          )}

          <div className={!centre?.image ? s.centreNameContainer : s.centreNameContainer2}>
            <div className={!centre?.image ? s.centralTurn : s.centralTurn2}>Central de {t('turnLabel.p')}</div>
            <h5 className={s.centreName}>{centre?.name}</h5>
          </div>
        </div>
      </div>

      <div className={s.centreInformation}>
        <CardCentreInformation centre={centre} />
        {/* <CardExtraCentreInformation /> */}
      </div>
    </div>
  )
}

export default LandingHeader
