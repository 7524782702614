import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

const TotalsTurnsDebts = ({ finishDate }) => {
  const { t } = useTranslation('global')

  const { totalTurnsDebts } = useSelector((state) => state.debts)

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '& th': {
                fontSize: '16px',
                backgroundColor: '#FFC17F',
              },
            }}
          >
            <TableCell>Deuda</TableCell>
            <TableCell align="center">
              Total deuda de {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`)}
            </TableCell>
            <TableCell align="center">Total bonos sin autorización</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0, fontWeight: 500, background: '#EEE' } }}>
            <TableCell component="th" scope="row">
              Deudas al {toCustomTz(finishDate, undefined, false, 'DD/MM/YYYY')}
            </TableCell>

            <>
              <TableCell align="center">
                {handleSetMoneySign(country)} {totalTurnsDebts?.totalDebt}
              </TableCell>
              <TableCell align="center">{totalTurnsDebts?.totalInsuranceNotAuthorized}</TableCell>
            </>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TotalsTurnsDebts
