import { TextField } from '@mui/material'

import styled from 'styled-components'

export const STextField = styled(TextField)`
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.base.white};
  border-radius: 4px;
  font-size: 16px;
`
