import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getCentresPaymentsInfo } from '@/store/centres/actions'
import convertCSVToExcelAndDownload from '@/utils/functions/convertCSVToExcelAndDownload'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Button, Menu, MenuItem } from '@mui/material'

import PopUpExportSubscriptionPayments from './PopUpExportSubscriptionPayments'

import s from '@/components/NavBar/components/NavBarContent/styles.module.scss'

export default function ExportOptions() {
  const dispatch = useDispatch()

  const [showExportPanel, setShowExportPanel] = useState(false)
  const [openPopUpPayments, setOpenPopUpPayments] = useState(false)
  const [currentTarget, setCurrentTarget] = useState(document.firstElementChild)

  const handleExportSubscriptionsData = async () => {
    const csvContent = await dispatch(getCentresPaymentsInfo())

    if (csvContent) {
      convertCSVToExcelAndDownload(csvContent)
    }

    setShowExportPanel(false)
  }

  return (
    <>
      <Button
        className={s.navExportOptions}
        size={'large'}
        onClick={(e) => {
          setShowExportPanel(true)
          setCurrentTarget(e.currentTarget)
        }}
      >
        Exportar <KeyboardArrowDownIcon />
      </Button>
      <Menu anchorEl={currentTarget} open={showExportPanel} onClose={() => setShowExportPanel(false)}>
        <MenuItem className={s.hoverText} onClick={handleExportSubscriptionsData}>
          Exportar montos de suscripción
        </MenuItem>
        <MenuItem className={s.hoverText} onClick={() => setOpenPopUpPayments(true)}>
          Exportar pagos de suscripción
        </MenuItem>
      </Menu>

      {openPopUpPayments && (
        <PopUpExportSubscriptionPayments
          openPopUp={openPopUpPayments}
          setOpenPopUp={setOpenPopUpPayments}
          setShowExportPanel={setShowExportPanel}
        />
      )}
    </>
  )
}
