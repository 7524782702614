import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import SignaturePad from '@/components/SignaturePad/SignaturePad'
import CaluButton from '@/components/styledComponents/CaluButton'
import { useUploadImage } from '@/hooks/useUploadImage'
import { updateCentre } from '@/store/centres/actions'
import { Redo, RestartAlt, Undo } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { TextField } from '@mui/material'

import s from './styles.module.scss'

const SignatureRecord = () => {
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const extraData = { addInMyProfile: true }
  const [padState, setPadState] = useState([])
  const [postPadState, setPostPadState] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const { uploadImage } = useUploadImage()
  const sigPad = useRef({})

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      doctorNumber: '',
    },
  })

  useEffect(() => {
    if (centreDetail) {
      Object.keys(centreDetail).forEach((k) => {
        if (k === 'doctorNumber') {
          setValue(k, centreDetail[k])
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centreDetail])

  const handleMouseUp = (signature) => {
    setPostPadState([])
    let newPadState = [...padState] || []
    newPadState.push(sigPad.current.toDataURL())
    setPadState(newPadState)
  }

  const handleUndo = () => {
    if (padState.length) {
      let newPadState = [...padState] || []
      setPadState(newPadState)

      let postState = [...postPadState] || []
      postState.push(newPadState.pop())
      setPostPadState(postState)

      sigPad.current.clear()
      sigPad.current.fromDataURL(padState[newPadState.length - 1])
    }
  }

  const handleRedo = () => {
    if (postPadState.length) {
      let postState = [...postPadState]
      const newPadState = postState.pop()
      setPostPadState(postState)

      let prevPadState = [...padState] || []
      prevPadState.push(newPadState)
      setPadState(prevPadState)

      sigPad.current.clear()
      sigPad.current.fromDataURL(newPadState)
    }
  }

  const handleRestart = () => {
    sigPad.current.clear()
    setPostPadState([])
  }

  const onSubmit = async (data, e) => {
    if (!e) return
    e.preventDefault()
    setIsLoading(true)
    const auxData = {
      ...centreDetail,
      doctorNumber: data.doctorNumber,
    }
    try {
      const res1 = await fetch(sigPad.current.getTrimmedCanvas().toDataURL('image/png'))
      const res2 = await res1.blob()
      let file = new File([res2], 'sign.png', { type: 'image/png' })
      if (res2.size > 81) {
        uploadImage(file, 'signImage', updateCentre, auxData, extraData)
      }
      const newExtraData = res2.size > 81 && { hideAlert: true, addInMyProfile: true }
      await dispatch(updateCentre(auxData, newExtraData))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container className={s.mainContainer}>
        <Grid item xs={12}>
          <h2 className={s.titleSection}>Matrícula</h2>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="doctorNumber"
            control={control}
            render={({ field }) => (
              <TextField {...field} type="text" label="Numero de matrícula" variant="standard" fullWidth />
            )}
          />
        </Grid>
        <h5 className={s.header}>Registre dentro del rectángulo blanco la firma que será utilizada en sus recetas.</h5>
        <Grid item xs={6} className={s.signContainer}>
          {centreDetail?.signImage ? (
            <>
              <p className={s.paragraph}>Su firma actual:</p>
              <img className={s.signImage} alt="Signature" src={centreDetail?.signImage} />
            </>
          ) : (
            <p className={s.paragraph}>Actualmente no tiene firma cargada.</p>
          )}
        </Grid>
        <Grid item container className={s.signPadContainer}>
          <Grid item xs={12} md={6}>
            <SignaturePad sigPad={sigPad} handleMouseUp={handleMouseUp} />
          </Grid>
        </Grid>
        <Grid item container className={s.buttonsPadContainer}>
          <Grid item xs={12} md={6}>
            <Undo className={s.icon} onClick={() => handleUndo()} />
            <Redo className={s.icon} onClick={() => handleRedo()} />
            <RestartAlt className={s.icon} onClick={() => handleRestart()} />
          </Grid>
        </Grid>

        <Grid item xs={12} className={s.contButton}>
          <CaluButton color="primary" size="medium" type="submit" loading={isLoading}>
            Guardar firma
          </CaluButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default SignatureRecord
