import * as React from 'react'
import { Link } from 'react-router-dom'
import CaluModal from '@/components/CaluModal'
import { version } from '@/helpers/env'
import CircleIcon from '@mui/icons-material/Circle'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

import s from './styles.module.scss'

export default function SupportPopUp({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false)
  }

  const showTitleSupport = () => {
    return (
      <div>
        <CircleIcon className={s.circleIcon} /> Soporte
      </div>
    )
  }

  return (
    <CaluModal open={open} setOpen={setOpen} title={showTitleSupport()}>
      <p className={s.subTitle}>Podés contactarte con soporte a través de los siguientes canales:</p>
      <ul className={s.list}>
        <li className={s.styleList}>
          <a
            href="https://wa.me/5492216175785"
            rel="noreferrer"
            target="_blank"
            className={s.tagA}
            onClick={() => handleClose()}
          >
            <WhatsAppIcon className={s.fontIcon} /> +549 221 617-5785
          </a>
        </li>

        <li className={s.styleList}>
          <a
            className={s.tagA}
            href="mailto:hola@calu.app"
            rel="noreferrer"
            target="_blank"
            onClick={() => handleClose()}
          >
            <MailOutlineIcon className={s.fontIcon} /> hola@calu.app
          </a>
        </li>
      </ul>

      <p className={s.appVersion}>
        Versión v{version} - <Link to={'/estado'}>estado</Link>
      </p>
    </CaluModal>
  )
}
