import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'internalCentreNotes',
  initialState: {
    allInternalCentreNotes: [],
    paginationData: {
      page: 1,
      limit: 30,
    },
    internalCentreNoteSelected: null,
    error: false,
    isLoading: false,
  },
  reducers: {
    INTERNAL_CENTRE_NOTE_CREATED: (state, { payload }) => {
      state.allInternalCentreNotes = [...(state.allInternalCentreNotes || []), payload]
    },
    FETCH_ALL_INTERNAL_CENTRE_NOTES: (state, { payload }) => {
      state.allInternalCentreNotes = payload.allInternalCentreNotes
      state.paginationData = payload.paginationData
    },
    INTERNAL_CENTRE_NOTE_UPDATED: (state, { payload }) => {
      state.allInternalCentreNotes = state.allInternalCentreNotes.map((note) => {
        if (note._id === payload._id) {
          note = payload
        }
        return note
      })
    },
    INTERNAL_CENTRE_NOTE_SELECTED: (state, { payload }) => {
      state.internalCentreNoteSelected = payload
    },
    RESET_INTERNAL_CENTRE_NOTE_SELECTED: (state, { payload }) => {
      state.internalCentreNoteSelected = null
    },
    INTERNAL_CENTRE_NOTE_DELETED: (state, { payload }) => {
      state.allInternalCentreNotes = state.allInternalCentreNotes.filter((note) => note._id !== payload)
    },
    LOADED_INTERNAL_CENTRE_NOTE: (state, { payload }) => {
      state.isLoading = payload
    },
  },
})

export const {
  INTERNAL_CENTRE_NOTE_CREATED,
  FETCH_ALL_INTERNAL_CENTRE_NOTES,
  INTERNAL_CENTRE_NOTE_UPDATED,
  INTERNAL_CENTRE_NOTE_DELETED,
  LOADED_INTERNAL_CENTRE_NOTE,
  INTERNAL_CENTRE_NOTE_SELECTED,
  RESET_INTERNAL_CENTRE_NOTE_SELECTED,
} = slice.actions
export default slice.reducer
