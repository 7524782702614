import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { postNewPrescriptions, updatePrescription } from '@/store/prescriptions/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import dayjs from 'dayjs'
import { formDefaultValues } from './formConfig'
import { FormContentPrescriptions } from './formContentPrescriptions'
import { formValidationSchema } from './formValidation'

import s from '../../styles.module.scss'

const PrescriptionForm = ({ detailPrescription, centreDetail, isNewPrescription, setExpanded, isSecretary }) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (data, e) => {
    if (!e) return
    setIsLoading(true)
    let buttonClicked = e?.nativeEvent?.submitter?.name

    data.centre = centreDetail._id
    data.date = detailPrescription?.creationDate || dayjs(new Date()).utc().format('YYYY-MM-DDT03:00:00[Z]')

    if (buttonClicked === 'original') {
      data.state = 'done'
    }

    if (buttonClicked === 'copia') {
      data.state = 'draft'
      delete data._id
      delete data.date
      delete data.creationDate
      data.date = dayjs(new Date()).utc().format('YYYY-MM-DDT03:00:00[Z]')
      data.creationDate = data.date
    }

    try {
      if (isNewPrescription) {
        await dispatch(postNewPrescriptions(data))
        setExpanded(false)
        e.target.reset()
      } else if (buttonClicked === 'copia') {
        await dispatch(postNewPrescriptions(data))
      } else {
        await dispatch(updatePrescription(data))
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data, e) => handleSubmit(data, e)}
        defaultValues={{ ...formDefaultValues, ...detailPrescription }}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContentPrescriptions
          submitLoading={isLoading}
          detailPrescription={detailPrescription}
          isNewPrescription={isNewPrescription}
          isSecretary={isSecretary}
        />
      </ContainerForm>
    </div>
  )
}

export default PrescriptionForm
