import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import useDebounce from '@/hooks/useDebounce'
import Layout1 from '@/layouts/Layout1'
import { RESET_SELECTED_USER } from '@/store/users'
import { getAllUsers, isLoadedUsers } from '@/store/users/actions'

import PopUpUser from './components/popUpUser'
import UsersSearchBar from './components/UsersSearchBar'
import UsersTable from './components/usersTable'

import s from './styles.module.scss'

const UsersPage = () => {
  const { allUsers, selectedUser, isLoaded } = useSelector((state) => state?.users)
  const [openPopUp, setOpenPopUp] = useState(false)
  const [inputSearch, setInputSearch] = useState('')
  const [roleSelected, setRoleSelected] = useState('-')
  const DELAY_TIME = 300
  const inputDebounceValue = useDebounce(inputSearch, DELAY_TIME)
  const dispatch = useDispatch()

  const getUsers = async () => {
    let params
    if (inputDebounceValue?.length === 0) {
      params = {
        limit: 30,
        page: 1,
        role: roleSelected === '-' ? null : roleSelected,
      }
    } else {
      params = {
        search: inputDebounceValue,
        role: roleSelected === '-' ? null : roleSelected,
      }
    }

    dispatch(isLoadedUsers(true))
    await dispatch(getAllUsers(params))
    dispatch(isLoadedUsers(false))
  }

  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDebounceValue, roleSelected])

  const handleClosePopUp = () => {
    setOpenPopUp(!openPopUp)
    dispatch(RESET_SELECTED_USER())
  }

  return (
    <Layout1 navbar>
      <div>
        <UsersSearchBar
          inputSearch={inputSearch}
          setInputSearch={setInputSearch}
          roleSelected={roleSelected}
          setRoleSelected={setRoleSelected}
        />
        {isLoaded ? (
          <LoaderCircle size={50} />
        ) : allUsers?.length === 0 ? (
          <p className={s.noItems}>No hay usuarios cargados aún</p>
        ) : (
          <UsersTable setOpen={setOpenPopUp} inputSearch={inputSearch} roleSelected={roleSelected} />
        )}
        {openPopUp && <PopUpUser open={openPopUp} selectedUser={selectedUser} onClose={handleClosePopUp} />}
      </div>
    </Layout1>
  )
}

export default UsersPage
