import { searchCities } from '@/services/cities'
import { GET_SEARCHED_CITIES, RESET_SEARCHED_CITIES } from '@/store/cities/index'

const getSearchedCities = (city) => async (dispatch) => {
  try {
    const allCities = await searchCities(city)
    dispatch(GET_SEARCHED_CITIES(allCities))
    return allCities
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSearchedCities = () => (dispatch) => {
  dispatch(RESET_SEARCHED_CITIES())
}

export { getSearchedCities, resetSearchedCities }
