import sound from '@/assets/sounds/maramba.mp3'
import i18next from '@/configs/i18next'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { handleWappReminder } from '@/utils/functions/handleWappReminders'
import { cancelTurn, cancelTurnByError, changeTurnState } from '@/utils/functions/updateTurns'
import { sendWhatsappEmpty } from '@/utils/functions/whatsappMessages'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'

export const rootMenuItems = (data) => {
  return [
    {
      content: 'Estados',
      visibleState: ['init', 'arrived', 'happening', 'finished', 'absent', 'cancelled', 'confirmed'],
      subMenuItems: () => getStateMenuSubItems(data),
    },
    {
      content: 'Cancelar',
      visibleState: ['init', 'arrived', 'happening', 'finished', 'confirmed'],
      color: '#ed5564',
      subMenuItems: () => getCancelMenuSubItems(data),
    },
    {
      content: 'Reprogramar',
      visibleState: ['init', 'arrived', 'happening', 'finished', 'absent', 'cancelled', 'confirmed'],
      onClick: () => {
        mxp.track(mxp.events.agenda.turnEvent.rescheduleOpen)
        data.setOpenRescheduleTurn(true)
      },
    },
    {
      content: 'Enviar recordatorio por WhatsApp',
      visibleState: ['init', 'confirmed', 'absent'],
      onClick: () => handleWappReminder(data),
    },
    {
      content: `Enviar mensaje de ${i18next.t('turnLabel.sc', { ns: 'global' })}`,
      visibleState: ['cancelled'],
      onClick: () => handleWappReminder(data),
    },
    {
      content: 'Otros Mensajes WhatsApp',
      visibleState: ['init', 'arrived', 'happening', 'finished', 'absent', 'cancelled', 'confirmed'],
      subMenuItems: () => getWhatsAppMenuSubItems(data),
    },
    {
      content: 'Copiar',
      visibleState: ['init', 'arrived', 'happening', 'finished', 'absent', 'cancelled', 'confirmed'],
      onClick: data.handleClickCopyTurn,
    },
  ].filter((item) => item.visibleState.includes(data?.selectedTurn?.state))
}

const getStateMenuSubItems = (data) => {
  return [
    {
      content: 'Restaurar',
      visibleState: ['arrived', 'happening', 'finished', 'absent', 'cancelled', 'confirmed'],
      onClick: () => {
        mxp.track(mxp.events.agenda.turnEvent.statusChanged)
        changeTurnState('init', data)
      },
      color: '#f8ac59',
    },
    {
      content: 'Confirmado',
      visibleState: ['init', 'arrived', 'happening', 'finished', 'absent'],
      onClick: () => {
        mxp.track(mxp.events.agenda.turnEvent.statusChanged)
        changeTurnState('confirmed', data)
      },
      color: '#1ab395',
    },
    {
      content: 'Llegó',
      visibleState: ['init', 'confirmed'],
      onClick: () => {
        mxp.track(mxp.events.agenda.turnEvent.statusChanged)
        changeTurnState('arrived', data)
      },
      color: '#1c83c6',
    },
    {
      content: 'Pasó',
      visibleState: ['init', 'arrived', 'confirmed'],
      onClick: () => {
        mxp.track(mxp.events.agenda.turnEvent.statusChanged)
        changeTurnState('happening', data)
        const audio = new Audio(sound)
        audio.play()
      },
      color: '#9e82cb',
    },
    {
      content: 'Finalizó',
      visibleState: ['init', 'arrived', 'happening', 'confirmed'],
      onClick: () => {
        mxp.track(mxp.events.agenda.turnEvent.statusChanged)
        changeTurnState('finished', data)
      },
      color: '#34495e',
    },
    {
      content: 'Ausente',
      visibleState: ['init', 'confirmed'],
      onClick: () => {
        mxp.track(mxp.events.agenda.turnEvent.statusChanged)
        changeTurnState('absent', data)
      },
      color: '#ed5564',
    },
  ].filter((item) => item.visibleState.includes(data?.selectedTurn?.state))
}

const getCancelMenuSubItems = (data) => {
  return [
    {
      content: `Cancelado por ${i18next.t(
        `subCategoryLabel.${getCentreSubCategory(data?.centreDetail?.category)}.patient`,
        {
          ns: 'global',
        },
      )}`,
      onClick: () => cancelTurn('patient', data),
      color: '#ed5564',
    },
    {
      content: 'Cancelado por profesional',
      onClick: () => cancelTurn('doctor', data),
      color: '#ed5564',
    },
    {
      content: 'Cancelado por error al cargar',
      onClick: () => cancelTurnByError(data),
      color: '#ed5564',
    },
  ]
}

const getWhatsAppMenuSubItems = (data) => {
  const auxData = {
    ...data,
  }

  return [
    {
      content: `Enviar detalle de post-${i18next.t('turnLabel.s', { ns: 'global' })}`,
      onClick: () => {
        auxData.state = 'finished'
        handleWappReminder(auxData)
      },
    },
    {
      content: 'Enviar invitación para crear cuenta',
      onClick: () => {
        auxData.state = 'createAcountInvitation'
        handleWappReminder(auxData)
      },
    },
    {
      content: 'Enviar invitacion en blanco',
      onClick: () => {
        sendWhatsappEmpty(data.selectedTurn, data.centreDetail)
      },
    },
  ]
}
