// sweetalert
import Swal from 'sweetalert2'

export const showSuccessAlert = (success, time = 2000) => {
  Swal.fire({
    text: success,
    icon: 'success',
    width: '20rem',
    timer: time,
    showConfirmButton: false,
  })
}

export const showErrorAlert = (error, time = 2000) => {
  Swal.fire({
    text: error,
    icon: 'error',
    width: '20rem',
    timer: time,
    showConfirmButton: false,
  })
}

export const showWarningAlert = (warning, time = 2000) => {
  Swal.fire({
    title: warning,
    icon: 'warning',
    width: '30rem',
    timer: time,
    showConfirmButton: false,
  })
}

export const showInternetDisconnectedAlert = () => {
  Swal.fire({
    title: 'Sin conexión a Internet',
    text: 'Por favor verifique que su conexión a internet esté activa o vuelva a conectarse a una red.',
    icon: 'warning',
    width: '30rem',
    timer: 6000,
    showConfirmButton: false,
  })
}
