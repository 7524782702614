import { getCentreDetail } from '@/services/centres'

export const setCurrentCentre = async (idCentre) => {
  try {
    const centre = await getCentreDetail(idCentre)
    return centre
  } catch (error) {
    console.log(error.message)
    return error
  }
}
