import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PageHearedTitle } from '@/components/pages'
import { useUser } from '@/context/UserProvider'
import { setCurrentCentre } from '@/controllers/centres'
import DetailProfile from '@/pages/PerfilPage/components/detailProfile'
import { postcentreConfiguration } from '@/store/centreConfigurations/actions'
import { getCurrentCentre, updateCentre } from '@/store/centres/actions'
import { getListInsurances } from '@/store/insurances/actions'
import { CENTRE_SELECTED as CENTRE_SELECTED_MY_PROFILE } from '@/store/myProfile'
import {
  getDoctorDetailAndSelected,
  getPatientDetailAndSelected,
  getSecretaryDetailAndSelected,
  resetMyProfileState,
} from '@/store/myProfile/actions'

import Layout1 from './../../layouts/Layout1/index'
import DetailDoctor from './components/detailDoctor'
import DetailPatient from './components/detailPatient'
import DetailSecretary from './components/detailSecretary'

import s from './styles.module.scss'

const PerfilPage = () => {
  const dispatch = useDispatch()
  const { user, defaultCentre } = useUser()
  const extraDataUpdateCentre = { hideAlert: true }

  const [openPatientPopUp, setOpenPatientPopUp] = useState(false)
  const [openDoctorPopUp, setOpenDoctorPopUp] = useState(false)

  const selectedDoctor = useSelector((state) => state.myProfile.doctorSelected)
  const selectedSecretary = useSelector((state) => state.myProfile.secretarySelected)
  const selectedPatient = useSelector((state) => state.myProfile.patientSelected)
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isCentre, isDoctor, isAdmin, isSecretary, isPatient, entityInLocalStorage } = usersProfileConnected

  const setInit = async () => {
    if (isAdmin) {
      dispatch(CENTRE_SELECTED_MY_PROFILE(centreDetail))
      return
    }

    if (isCentre || (isAdmin && centreDetail)) {
      const currentCentre = await setCurrentCentre(defaultCentre)
      if (!currentCentre.centreConfiguration) {
        const hideAlert = true
        const defaultCentreConfiguration = {
          centre: currentCentre._id,
        }
        const centreConfigCreated = await dispatch(postcentreConfiguration(defaultCentreConfiguration, hideAlert))
        const auxCentre = {
          ...currentCentre,
          centreConfiguration: centreConfigCreated,
        }
        dispatch(updateCentre(auxCentre, extraDataUpdateCentre))
        dispatch(getCurrentCentre(auxCentre, true))
        return
      } else {
        dispatch(getCurrentCentre(currentCentre, true))
        return
      }
    }

    if (isDoctor) {
      dispatch(getDoctorDetailAndSelected(entityInLocalStorage?.doctorCentreId))
      return
    }

    if (isSecretary) {
      const currentCentre = await setCurrentCentre(defaultCentre)
      await dispatch(getSecretaryDetailAndSelected(entityInLocalStorage?.secretaryCentreId))
      dispatch(getCurrentCentre(currentCentre, true))
      return
    }
  }

  useEffect(() => {
    if (!entityInLocalStorage) return
    dispatch(getListInsurances())
    setInit()

    return () => {
      dispatch(resetMyProfileState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityInLocalStorage])

  useEffect(() => {
    if (isPatient) {
      dispatch(getListInsurances())
      dispatch(getPatientDetailAndSelected(user?.patient?._id))
    }

    return () => {
      dispatch(resetMyProfileState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPatient])

  const modifyPatientProfile = () => {
    setOpenPatientPopUp(!openPatientPopUp)
  }

  const modifyDoctorProfile = () => {
    setOpenDoctorPopUp(!openDoctorPopUp)
  }

  return (
    <Layout1 isLoading={false} navbar>
      <div>
        <div className={s.mainContainer}>
          <div className={s.contentContainer}>
            <PageHearedTitle titleText="Configuración general" />

            {isSecretary && (
              <div className={s.mainViewContainer}>
                <DetailSecretary selectedSecretary={selectedSecretary} />
              </div>
            )}

            {(isCentre || (isAdmin && centreDetail)) && (
              <div className={s.mainViewContainer}>
                <DetailProfile />
              </div>
            )}

            {isDoctor && (
              <div className={s.mainViewContainer}>
                <DetailDoctor
                  selectedDoctor={selectedDoctor}
                  openDoctorPopUp={openDoctorPopUp}
                  setOpenDoctorPopUp={setOpenDoctorPopUp}
                  modifyDoctorProfile={modifyDoctorProfile}
                />
              </div>
            )}

            {isPatient && (
              <div className={s.mainViewContainer}>
                <DetailPatient
                  selectedPatient={selectedPatient}
                  openPatientPopUp={openPatientPopUp}
                  setOpenPatientPopUp={setOpenPatientPopUp}
                  modifyPatientProfile={modifyPatientProfile}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout1>
  )
}

export default PerfilPage
