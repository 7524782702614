import { TextField } from '@mui/material'

import styled from 'styled-components'

export const STextField = styled(TextField)`
  height: ${(props) => (props.multiline ? 'auto' : '48px')};
  width: 100%;
  background: ${(props) => props.theme.colors.base.white};
  border-radius: 4px;

  ${(props) =>
    props.multiline &&
    `
    overflow: hidden;
    resize: none;
    `}
  & .MuiOutlinedInput-root {
    font-size: 16px !important;
    height: 100%;
    width: 100%;
    &:hover fieldset {
      border-color: #0f1333;
    }
    &.Mui-focused fieldset {
      border-color: #0f1333;
    }
    ${(props) =>
      props.multiline &&
      `
      padding: 8px 14px;
      `}
  }
  .MuiInputBase-inputMultiline {
    min-height: 35px;
  }
`
