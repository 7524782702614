import { useController } from 'react-hook-form'
import { TextField } from '@mui/material'

import PropTypes from 'prop-types'

export const SelectHourField = ({ label, name, initialValue, className }) => {
  const {
    field: { onChange, onBlur, value = initialValue, ref },
    fieldState: { error },
  } = useController({ name })

  return (
    <TextField
      className={className}
      error={Boolean(error)}
      fullWidth={true}
      helperText={Boolean(error) && error?.message}
      label={label || name.toUpperCase()}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      ref={ref}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 300,
        min: '00:00',
        max: '23:59',
      }}
      size="small"
      type="time"
      sx={{ width: '100%' }}
      value={value || ''}
    />
  )
}

SelectHourField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
}
