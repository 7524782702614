import React from 'react'
import { useTranslation } from 'react-i18next'
import CaluButton from '@/components/styledComponents/CaluButton'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { Dialog, DialogContent } from '@mui/material'

import s from './styles.module.scss'

const PopUpCardInsurance = ({ open, setOpen, selectedDay, turnData, entityData, centre, refSubmitButton }) => {
  const { t } = useTranslation('global')

  const handleClose = () => {
    setOpen(!open)
  }

  const handleSubmit = async () => {
    mxp.track(mxp.events.centreLanding.steps.turnConfirmDone)
    refSubmitButton?.current?.click()
    setOpen(!open)
  }

  return (
    <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={open} onClose={handleClose} scroll="paper">
      <DialogContent className={s.containerPopUp}>
        <div className={s.infoContainer}>
          <h2 className={s.title}>¿Los datos ingresados son correctos ?</h2>
          <div className={s.detailTurn}>
            <p>
              {t('turnLabel.S')} para el día <b>{selectedDay?.toUpperCase()}</b> a las <b>{turnData?.startHour}hs</b>
            </p>
            {entityData?.firstname && (
              <p>
                Con
                <b>
                  {entityData?.lastname?.toUpperCase()}, {entityData?.firstname?.toUpperCase()}
                </b>
              </p>
            )}
            <p>
              En<b>{centre?.name?.toUpperCase()}</b>
            </p>
          </div>
        </div>
        <div className={s.divider}></div>
        <div className={s.containerButtons}>
          <div className={s.itemButton}>
            <CaluButton
              color="primary"
              inverted="true"
              size="medium"
              className={s.button}
              onClick={() => handleClose()}
            >
              Cancelar
            </CaluButton>
          </div>
          <div className={s.itemButton}>
            <CaluButton color="primary" size="medium" className={s.button} onClick={() => handleSubmit()}>
              Sí, reservar
            </CaluButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default PopUpCardInsurance
