import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCheck } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import CaluButton from '@/components/styledComponents/CaluButton'
import { useUser } from '@/context/UserProvider'
import { getAllTemplatesHCField } from '@/controllers/clinicHistoryField'
import { getListFieldsHC } from '@/store/clinicHistoryField/actions'
import { getPatientsCentresForBackup } from '@/store/patientCentres/actions'
import { xlsxFromJson } from '@/utils/excel'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { showToast } from '@/utils/toast'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { Autocomplete, Checkbox, TextField } from '@mui/material'

import dayjs from 'dayjs'
import { adapterToExcel } from './excelDataAdapter'

import s from '../styles.module.scss'

const ContentPopUp = () => {
  const { defaultCentre } = useUser()
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const icon = <CheckBoxOutlineBlank fontSize="small" />
  const checkedIcon = <CheckBox fontSize="small" />

  const [isLoadingTemplatesHC, setIsLoadingTemplatesHC] = useState(false)
  const [loadingBackup, setLoadingBackup] = useState(false)
  const [extraFields, setExtraFields] = useState([])
  const [buttons, setButtons] = useState([{ label: 'Parte 1', disabled: false }])

  const { allFieldsHC } = useSelector((state) => state.fieldsHC)
  const { backupPatients } = useSelector((state) => state.patientCentres)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const centreCountry = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const setInit = async () => {
    setIsLoadingTemplatesHC(true)
    const AllTemplatesHCFields = await getAllTemplatesHCField()
    dispatch(getListFieldsHC(AllTemplatesHCFields))
    setIsLoadingTemplatesHC(false)
  }

  useEffect(() => {
    setInit()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!backupPatients?.patientCentres) return
    downloadExcelPatients(backupPatients?.currentPage, extraFields, centreCountry)
    if (backupPatients?.nextPage) {
      setButtons([...buttons, { label: `Parte ${backupPatients?.nextPage}`, disabled: false }])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backupPatients?.nextPage])

  const handleGenerateBackupPatientCentres = async (index) => {
    setLoadingBackup(true)
    setButtons(
      buttons?.map((button, i) => {
        if (i === index) {
          return { ...button, disabled: true }
        } else {
          return button
        }
      }),
    )

    let params = {
      isDownload: true,
      centre: defaultCentre,
      page: backupPatients?.nextPage ? backupPatients?.nextPage : 1,
    }

    try {
      await dispatch(getPatientsCentresForBackup(params))
      setLoadingBackup(false)
    } catch (error) {
      setLoadingBackup(false)
      showToast('Se produjo un error', 'error')
    }
  }

  const downloadExcelPatients = async (index, extraFields, centreCountry) => {
    const fileProps = {
      fileName: `Backup_${t(
        `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.Patients`,
      )}_${index}_${dayjs().format('DD-MM-YYYY')}`,
    }

    const auxPatientCentres = backupPatients?.patientCentres?.map((patient) => {
      return adapterToExcel(patient, extraFields, centreCountry)
    })
    xlsxFromJson(auxPatientCentres, fileProps)
  }

  if (isLoadingTemplatesHC) {
    return <LoaderCircle />
  }

  return (
    <div className={s.mainContainer}>
      <p>
        Desde aquí podrá descargar una planilla con todos los{' '}
        {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`)}
      </p>
      <p>Este proceso puede tardar varios minutos</p>

      <div className={s.autocomplete}>
        <Autocomplete
          multiple
          disableCloseOnSelect
          options={allFieldsHC}
          ListboxProps={{ style: { maxHeight: '15rem', fontSize: '14px' } }}
          getOptionLabel={(option) => capitalizeFirstLetters(option?.name)}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option._id}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {capitalizeFirstLetters(option?.name)}
            </li>
          )}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccione los campos extras que quiera agregar al backup"
              variant="standard"
            />
          )}
          onChange={(event, selectedValues) => {
            setExtraFields(selectedValues)
          }}
        />
      </div>

      <div className={s.divider}></div>

      <p>Generar y descargar backup:</p>

      <div className={s.containerBtns}>
        {buttons?.map((button, index) => (
          <CaluButton
            key={index}
            color="primary"
            size="medium"
            onClick={() => handleGenerateBackupPatientCentres(index)}
            disabled={button?.disabled}
            loading={loadingBackup}
          >
            {button?.label}
          </CaluButton>
        ))}
      </div>

      {backupPatients?.nextPage === null && (
        <p className={s.backupFullfilled}>
          <i>
            <BsCheck />
          </i>
          Todas las partes de backup de {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`)}{' '}
          han sido descargadas
        </p>
      )}
    </div>
  )
}

export default ContentPopUp
