import { apiErrorHandler } from '@/helpers/errorHandlers'

import APICalu from '../client'
import APIWapp from '../clientWapp'

const fetchStatus = async (centreId) => {
  const route = `api/wapp/${centreId}/status`
  return APIWapp.post(route, { centre: centreId })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postUpDevice = async (centreId) => {
  const route = `api/wapp/${centreId}/up`
  return APIWapp.post(route, { centre: centreId })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postSendText = async (deviceId, data) => {
  const auxData = {
    ...data,
    centre: deviceId,
  }
  const route = `api/wapp/${deviceId}/sendText`
  return APIWapp.post(route, { ...auxData })

    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postDownDevice = async (deviceId) => {
  const route = `api/wapp/${deviceId}/down`
  return APIWapp.post(route, { centre: deviceId })

    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchWappTurnReminders = async (params) => {
  const route = `api/wappReminders/turns`
  return APICalu.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchWappRemindersHistory = async (params) => {
  const route = `api/wappReminders`
  return APICalu.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchWappTurnReminderById = async (turnId) => {
  const route = `api/wappReminders/turns/${turnId}`
  return APICalu.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postWappReminder = async (params) => {
  const route = `api/wappReminders`
  return APICalu.post(route, params)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export {
  fetchStatus,
  fetchWappRemindersHistory,
  fetchWappTurnReminderById,
  fetchWappTurnReminders,
  postDownDevice,
  postSendText,
  postUpDevice,
  postWappReminder,
}
