import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CaluModal from '@/components/CaluModal'
import LoaderCircle from '@/components/LoaderCircle'
import { getCentreTarget } from '@/store/centres/actions'
import { getWappTurnReminders, updateWappTurnHistory } from '@/store/wapp/actions'
import { handleWappReminder } from '@/utils/functions/handleWappReminders'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { showToast } from '@/utils/toast'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import Tooltip from '@mui/material/Tooltip'

import dayjs from 'dayjs'
import HeaderManagement from './components/HeaderManagement'
import ManagementTable from './components/ManagementTable'

import s from './styles.module.scss'

const TabManagement = () => {
  const dispatch = useDispatch()
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected
  const deviceId = entityInLocalStorage._id
  const { wappHistory, wappTurnReminders, isLoading } = useSelector((state) => state.wapp)
  const { selectedTurnReminder } = wappHistory
  const [isResendingReminder, setIsResendingReminder] = useState(false)
  const [startDate, setStartDate] = useState(dayjs().add(1, 'day').startOf('day').format())
  const [finishDate, setFinishDate] = useState(dayjs(startDate).endOf('day').format())
  const [open, setOpen] = useState(false)
  const { status: wappStatus } = useSelector((state) => state.wapp)
  const { centreProfile } = useSelector((state) => state.centres)

  const filteredTurnRemindersAvailable = wappTurnReminders?.filter(
    (item) => !(item?.doctorName && !item?.doctorCentre) && !(item?.machineName && !item?.machine),
  )

  const setInit = async () => {
    let params = {
      centre: deviceId,
      start: toCustomTz(startDate, 'utc', true),
      finish: toCustomTz(finishDate, 'utc', true),
      page: 1,
      limit: 30,
    }

    dispatch(getWappTurnReminders(params))
  }

  useEffect(() => {
    dispatch(getCentreTarget(deviceId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, finishDate])

  const renderDateReminder = (a) => {
    const formatedDate = dayjs(a?.date).format('DD/MM/YYYY [a las] HH:mm [hs]')
    const status = a.status
    let icon = null

    if (status === 'sent') {
      icon = (
        <Tooltip title="Enviado">
          <CheckIcon className={s.checkIcon} />
        </Tooltip>
      )
    }
    if (status === 'error') {
      icon = (
        <Tooltip title="Error al enviar">
          <CloseIcon className={s.errorIcon} />
        </Tooltip>
      )
    }

    return (
      <div className={s.iconContainer}>
        <b>Fecha de envío </b>
        <span className={s.formatedDate}>
          {icon && icon}
          {formatedDate}
        </span>
      </div>
    )
  }

  const resendReminder = async () => {
    const selectedTurn = selectedTurnReminder.raw
    const hideAlert = true
    let auxData = {
      selectedTurn: {
        ...selectedTurn,
        centre: centreProfile,
      },
      dispatch: dispatch,
      wappStatus: wappStatus,
    }

    try {
      setIsResendingReminder(true)
      const createdWappReminder = await handleWappReminder(auxData, hideAlert)
      const auxTurn = {
        ...selectedTurn,
        wappReminder: createdWappReminder,
      }

      await dispatch(updateWappTurnHistory(auxTurn))
      setIsResendingReminder(false)
      showToast('Recordatorio reenviado correctamente', 'success', 2000)
    } catch (e) {
      setIsResendingReminder(false)
      setOpen(false)
    }
  }

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <HeaderManagement
        startDate={startDate}
        setStartDate={setStartDate}
        finishDate={finishDate}
        setFinishDate={setFinishDate}
        deviceId={deviceId}
        isLoadingReminders={isLoading}
      />
      {isLoading ? (
        <LoaderCircle size={50} />
      ) : filteredTurnRemindersAvailable?.length === 0 ? (
        <div className={s.noItems}>No hay items para las fechas solicitadas</div>
      ) : (
        <ManagementTable
          startDate={startDate}
          finishDate={finishDate}
          deviceId={deviceId}
          setOpen={setOpen}
          filteredTurnRemindersAvailable={filteredTurnRemindersAvailable}
        />
      )}

      <CaluModal
        open={open}
        setOpen={setOpen}
        title="Detalle recordatorios"
        maxWidth="lg"
        textConfirmationButton={'Reenviar mensaje'}
        onClose={resendReminder}
        isLoading={isResendingReminder}
        disabled={isResendingReminder}
      >
        {selectedTurnReminder?.attempts
          ?.slice()
          .sort((a, b) => dayjs(b.date).diff(dayjs(a.date)))
          .map((a) => (
            <ul>
              <li key={a._id}>
                {renderDateReminder(a)}
                <div>
                  <b>Tipo de mensaje </b>
                  {a?.messageType || '-'}
                </div>
                <div>
                  <b>Mensaje </b>
                  {a.message}
                </div>
              </li>
            </ul>
          ))}
      </CaluModal>
    </div>
  )
}

export default TabManagement
