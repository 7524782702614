import React from 'react'
import { CircularProgress } from '@mui/material'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

const LoaderCircle = ({ size, text, className }) => {
  return (
    <div className={`${s.circularProgress} ${className}`}>
      <CircularProgress size={size} color="inherit" />
      {text && <div className={s.colorText}>{text}</div>}
    </div>
  )
}

export default LoaderCircle
LoaderCircle.propTypes = {
  size: PropTypes.number,
  text: PropTypes.string,
  className: PropTypes.string,
}

LoaderCircle.defaultProps = {
  size: 40,
}
