export const reducePayload = (turn) => {
  if (!turn) return {}
  // to force deep copy
  let auxTurn = JSON.parse(JSON.stringify(turn))

  // reduce turn.service
  // don't reduce service, we need indications to be sent on mails
  // if (auxTurn?.service?._id) {
  //   auxTurn.service = auxTurn.service._id
  // }

  if (auxTurn?.service?._id === 'consultation') {
    auxTurn.service = null
    auxTurn.firstTime = false
  }

  if (auxTurn?.service?._id === 'firstConsultation') {
    auxTurn.service = null
    auxTurn.firstTime = true
  }

  // reduce turn.extraServices
  if (auxTurn?.extraServices) {
    auxTurn.extraServices = auxTurn.extraServices.map((s) => (s?._id ? s._id : s))
  }

  // reduce turn.centre.services
  if (auxTurn?.centre?.services) {
    auxTurn.centre.services = auxTurn.centre.services.map((s) => (s?._id ? s._id : s))
  }

  // reduce turn.doctorCentre
  if (auxTurn?.doctorCentre?._id) {
    auxTurn.doctorCentre = {
      _id: auxTurn?.doctorCentre?._id,
      firstname: auxTurn?.doctorCentre?.firstname,
      lastname: auxTurn?.doctorCentre?.lastname,
    }
  }

  // reduce turn.patientCentre
  if (auxTurn?.patientCentre?._id) {
    auxTurn.patientCentre = {
      _id: auxTurn?.patientCentre?._id,
      firstname: auxTurn?.patientCentre?.firstname,
      lastname: auxTurn?.patientCentre?.lastname,
      email: auxTurn?.patientCentre?.email,
      dni: auxTurn?.patientCentre?.dni,
      mobile: auxTurn?.patientCentre?.mobile,
    }
  }

  // // reduce turn.doctorCentre.services
  // if (auxTurn?.doctorCentre?.services) {
  //   auxTurn.doctorCentre.services = auxTurn.doctorCentre.services.map((s) => (s?._id ? s._id : s))
  // }

  // // reduce turn.doctorCentre.workTimes.services
  // if (auxTurn?.doctorCentre?.workTimes) {
  //   auxTurn.doctorCentre.workTimes = auxTurn.doctorCentre.workTimes.map((wt) => {
  //     const auxWt = { ...wt, services: wt.services?.map((s) => (s?._id ? s._id : s)) }
  //     return auxWt
  //   })
  // }

  // reduce turn.machine
  if (auxTurn?.machine?._id) {
    auxTurn.machine = {
      _id: auxTurn?.machine?._id,
      name: auxTurn?.machine?.name,
    }
  }

  // reduce turn.logs
  if (auxTurn?.logs?.length > 100) {
    auxTurn.logs = auxTurn?.logs?.slice(-100)
  }

  // // reduce turn.machine.services
  // if (auxTurn?.machine?.services) {
  //   auxTurn.machine.services = auxTurn.machine.services.map((s) => (s?._id ? s._id : s))
  // }

  // // reduce turn.machine.workTimes.services
  // if (auxTurn?.machine?.workTimes) {
  //   auxTurn.machine.workTimes = auxTurn.machine.workTimes.map((wt) => {
  //     const auxWt = {
  //       ...wt,
  //       services: wt.services?.map((s) => (s?._id ? s._id : s)),
  //       doctorCentres: wt.doctorCentres?.map((d) => (d?._id ? d._id : d)),
  //     }
  //     return auxWt
  //   })
  // }

  return auxTurn
}
