import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Button, Menu, MenuItem } from '@mui/material'

import PopUpImportCentresPayments from './PopUpImportCentresPayments'

import s from '@/components/NavBar/components/NavBarContent/styles.module.scss'

export default function ImportOptions() {
  const [showImportPanel, setShowImportPanel] = useState(false)
  const [currentTarget, setCurrentTarget] = useState(document.firstElementChild)
  const [openModal, setOpenModal] = useState(false)

  const handleOpenPopUp = () => {
    setOpenModal(true)
    setShowImportPanel(false)
  }

  return (
    <>
      <Button
        className={s.navExportOptions}
        size={'large'}
        onClick={(e) => {
          setShowImportPanel(true)
          setCurrentTarget(e.currentTarget)
        }}
      >
        Importar <KeyboardArrowDownIcon />
      </Button>
      <Menu anchorEl={currentTarget} open={showImportPanel} onClose={() => setShowImportPanel(false)}>
        <MenuItem className={s.hoverText} onClick={handleOpenPopUp}>
          Importar montos de suscripción
        </MenuItem>
      </Menu>

      {openModal && <PopUpImportCentresPayments openPopUp={openModal} setOpenPopUp={setOpenModal} />}
    </>
  )
}
