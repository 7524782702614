export const reducePayload = (doctorCentre) => {
  if (!doctorCentre) return {}
  // to force deep copy
  let auxDoctorCentre = JSON.parse(JSON.stringify(doctorCentre))

  // reduce doctorCentre.services
  if (auxDoctorCentre?.services) {
    auxDoctorCentre.services = auxDoctorCentre.services.map((s) => (s?._id ? s._id : s))
  }

  // reduce doctorCentre.workTimes.services
  if (auxDoctorCentre?.workTimes) {
    auxDoctorCentre.workTimes = auxDoctorCentre.workTimes.map((wt) => {
      const auxWt = { ...wt, services: wt.services?.map((s) => (s?._id ? s._id : s)) }
      return auxWt
    })
  }

  // reduce doctorCentre.centre.services
  if (auxDoctorCentre?.centre?.services) {
    auxDoctorCentre.centre.services = auxDoctorCentre.centre.services.map((s) => (s?._id ? s._id : s))
  }

  return auxDoctorCentre
}
