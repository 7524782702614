export const formDefaultValues = {
  noEmailConfirmButton: false,
  noEmailModifyButton: false,
  noEmailCancelButton: false,
  showEmailTurnPaymentInfo: false,
  turnSurvey: '',
  extraMailField: '',
  centreConfiguration: {
    extraWhatsAppReminderField: '',
    shouldSendNotificationOnTurnCreate: false,
    shouldSendNotificationOnTurnFinish: false,
    shouldSendNotificationOnTurnCancel: false,
    shouldSendNotificationOnTurnModify: false,
    enableAutoWappBirthdayMessage: false,
    showWhatsAppTurnPaymentInfo: false,
    shouldSendReminderEmail: false,
    daysForReminderEmail: 0,
    daysForReminderEmail2: 0,
    shouldSendWappReminder: false,
    daysForWappReminder: 1,
  },
}
