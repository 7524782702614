import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import defaultUserImage from '@/assets/images/default.png'
import CaluModal from '@/components/CaluModal'
import { useUser } from '@/context/UserProvider'
import { RESET_ONLINE_TURNS } from '@/store/turns'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { Button, Menu, MenuItem } from '@mui/material'

import navbarStyles from '../../styles.module.scss'
import s from './styles.module.scss'

export default function UserProfilePanel() {
  const navigate = useNavigate()
  const { user, logout } = useUser()
  const { t } = useTranslation('global')
  const dispatch = useDispatch()

  let OptionsMenuDefault = [
    {
      to: '/turnos',
      children: `Mis ${t('turnLabel.p')}`,
      id: 'myTurnsButton',
    },
    {
      to: '/ajustes',
      children: 'Cambiar contraseña',
      id: 'changePasswordButton',
    },
  ]

  const centreDetail = useSelector((state) => state?.centres?.centreProfile)
  const [showUserProfilePanel, setShowUserProfilePanel] = useState(false)
  const [currentTarget, setCurrentTarget] = useState(document.firstElementChild)
  const [openAlertDelete, setOpenAlertDelete] = useState(false)
  const [optionsMenu, setOptionsMenu] = useState(OptionsMenuDefault)

  const doctorProfiles = useSelector((state) => state.myProfile.doctorProfiles)
  const secretaryProfiles = useSelector((state) => state.myProfile.secretaryProfiles)
  const patient = useSelector((state) => state.myProfile.patientSelected)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isCentre, isPatient } = usersProfileConnected

  const logOutUser = () => {
    dispatch(RESET_ONLINE_TURNS())
    logout()
    navigate('/')
    setOpenAlertDelete(false)
    mxp.track(mxp.events.navbar.logoutConfirmed)
  }

  useEffect(() => {
    if (isPatient) {
      let optionsMenuFiltered = OptionsMenuDefault?.filter((option) => option?.to !== '/turnos')
      setOptionsMenu(optionsMenuFiltered)
    }
    if (isCentre && centreDetail?.status !== 'demo') {
      const newOptionObj = {
        to: '/pagos',
        children: `Mis pagos`,
        id: 'myPaysButton',
      }
      setOptionsMenu([...OptionsMenuDefault, newOptionObj])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const showUserImage = () => {
    let image
    if (doctorProfiles?.length > 0) image = doctorProfiles?.map((doctor) => doctor.image)
    if (secretaryProfiles?.length > 0) image = secretaryProfiles?.map((secretary) => secretary.image)
    if (patient) image = patient.image

    return image || centreDetail?.logo
  }

  const handleClickOptionsMenu = (e) => {
    if (e?.nativeEvent?.target?.id === 'myTurnsButton') {
      mxp.track(mxp.events.navbar.myTurnsClicked)
    }
    if (e?.nativeEvent?.target?.id === 'changePasswordButton') {
      mxp.track(mxp.events.navbar.changePasswordClicked)
    }
  }

  return (
    <>
      <Button
        onClick={(e) => {
          mxp.track(mxp.events.navbar.profileClicked)
          setShowUserProfilePanel(true)
          setCurrentTarget(e.currentTarget)
        }}
      >
        <img
          className={showUserImage() ? s.profilePicture : s.defaultImage}
          src={showUserImage() || defaultUserImage}
          alt="Imagen de perfil"
        />
        <span className={s.fullname}>{user.lastname + ' ' + user.firstname}</span>
      </Button>

      <Menu
        anchorEl={currentTarget}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showUserProfilePanel}
        onClose={() => setShowUserProfilePanel(false)}
      >
        {optionsMenu?.map((item, i) => (
          <Link key={i} to={item?.to} className={navbarStyles.link} onClick={handleClickOptionsMenu}>
            <MenuItem id={item?.id} className={navbarStyles.hoverText}>
              {item?.children}
            </MenuItem>
          </Link>
        ))}
        <MenuItem
          onClick={() => {
            setOpenAlertDelete(true)
            mxp.track(mxp.events.navbar.logoutClicked)
          }}
          className={`${navbarStyles.hoverText} ${navbarStyles.logOut} `}
        >
          Salir
        </MenuItem>

        <CaluModal
          open={openAlertDelete}
          setOpen={setOpenAlertDelete}
          title={'Cerrar sesión'}
          textConfirmationButton={'Si, Salir'}
          onClose={logOutUser}
          maxWidth="xs"
        >
          <div>¿Está seguro que quiere cerrar sesión?</div>
        </CaluModal>
      </Menu>
    </>
  )
}
