import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { AiOutlinePlus } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import PopUpService from '@/pages/ServicesPage/components/mainPopUp'
import { resetSelectedService } from '@/store/services/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { Autocomplete, Box, Button, Checkbox, Divider, Popper, styled, TextField } from '@mui/material'

import s from '../styles.module.scss'

const ServiceAutocomplete = ({ control, setValue }) => {
  const icon = <CheckBoxOutlineBlank fontSize="small" /> // Autocomplete Insurances
  const checkedIcon = <CheckBox fontSize="small" />
  const dispatch = useDispatch()

  const PopperStyledComponent = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'}`,
  }))

  const [openCreateService, setOpenCreateService] = useState(false)
  const [openAutocompleteServices, setOpenAutocompleteServices] = useState(false)
  const [checkAllServices, setCheckAllServices] = useState(false)

  const { allServices } = useSelector((state) => state.services)

  const handleCreateNewService = () => {
    dispatch(resetSelectedService())
    setOpenCreateService(true)
  }

  const checkAllChangeServices = (e) => {
    setCheckAllServices(e.target.checked)
    if (e.target.checked) {
      setValue('services', allServices)
    } else {
      setValue('services', [])
    }
  }

  const getServicesPopperComponent = (params) => (
    <PopperStyledComponent {...params}>
      <Box {...params} />
      <Divider />
      <div className={s.containerPopper}>
        <Box
          sx={{
            backgroundColor: 'white',
            height: '45px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          <Checkbox
            checked={checkAllServices}
            onChange={checkAllChangeServices}
            id="check-all"
            onMouseDown={(e) => e.preventDefault()}
          />
          Marcar todos los servicios
        </Box>
        <Button
          endIcon={<AiOutlinePlus />}
          variant="contained"
          disableElevation
          onClick={() => handleCreateNewService()}
          onMouseDown={(e) => e.preventDefault()}
          className={s.createService}
          sx={{
            backgroundColor: 'white',
            height: '45px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          Crear servicio
        </Button>
      </div>
    </PopperStyledComponent>
  )

  useEffect(() => {
    if (checkAllServices) setValue('services', allServices)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allServices])

  return (
    <div>
      <Controller
        name="services"
        control={control}
        render={({ field }) => (
          <>
            <Autocomplete
              {...field}
              multiple
              open={openAutocompleteServices}
              onOpen={() => setOpenAutocompleteServices(true)}
              onClose={() => setOpenAutocompleteServices(false)}
              disableCloseOnSelect
              ListboxProps={{
                style: {
                  maxHeight: '200px',
                },
              }}
              noOptionsText={'Sin opciones disponibles'}
              options={allServices || []}
              getOptionLabel={(option) => `${capitalizeFirstLetters(option?.name)} `}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option?._id}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected || checkAllServices}
                  />
                  {option?.name}
                </li>
              )}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Servicio(s)"
                  variant="standard"
                  sx={{
                    '&.Mui-InputBase-root': {
                      maxHeight: '100px',
                      overflow: 'scroll',
                    },
                    '&.MuiAutocomplete-endAdornment': {
                      right: '10px',
                    },
                    '&.MuiTextField-root': {
                      maxHeight: '170px',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    },
                  }}
                />
              )}
              onChange={(event, selectedValues, reason) => {
                if (reason === 'selectOption') {
                  setValue('services', selectedValues)
                } else if (reason === 'removeOption') {
                  setCheckAllServices(false)
                  setValue('services', selectedValues)
                } else if (reason === 'clear') {
                  setValue('services', [])
                  setCheckAllServices(false)
                }
              }}
              PopperComponent={getServicesPopperComponent}
            />
          </>
        )}
      />
      <PopUpService
        open={openCreateService}
        setOpen={setOpenCreateService}
        setOpenAutocompleteServices={setOpenAutocompleteServices}
      />
    </div>
  )
}

export default ServiceAutocomplete
