export const isDoctorCentreValidAgenda = (entity) => {
  const getSelectedEntityInLocalStorage = localStorage.getItem('selectedEntity')
  const entityInLocalStorage = JSON.parse(getSelectedEntityInLocalStorage)
  const currentDoctorCentreId = entityInLocalStorage?.doctorCentreId

  if (entityInLocalStorage?.role === 'doctor') {
    if (currentDoctorCentreId === entity?._id) {
      return true
    }

    const isDoctorCentreMachine = entity?.workTimes?.some((wt) => {
      if (wt?.doctorCentres?.length > 0) {
        const hasDoctorCentreWT = wt.doctorCentres.some((doctor) => currentDoctorCentreId === doctor?._id)
        return hasDoctorCentreWT
      }
      return false
    })

    return isDoctorCentreMachine
  }
  return true
}

export const filterDoctorCentreValidWT = (entity) => {
  const getSelectedEntityInLocalStorage = localStorage.getItem('selectedEntity')
  const entityInLocalStorage = JSON.parse(getSelectedEntityInLocalStorage)
  const currentDoctorCentreId = entityInLocalStorage?.doctorCentreId

  if (entityInLocalStorage?.role === 'doctor') {
    entity.workTimes = entity?.workTimes?.filter((wt) => {
      if (wt?.doctorCentres?.length > 0) {
        const hasDoctorCentreWT = wt.doctorCentres.some((doctor) => currentDoctorCentreId === doctor?._id)
        return hasDoctorCentreWT
      }
      return false
    })
  }
  return entity
}
