import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { postFile } from '@/controllers/postFile'
import { updateClinicHistory } from '@/store/clinicHistory/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from '../../styles.module.scss'

const Form = ({ detailHC, isNewTurn, index, allowEditHC }) => {
  const dispatch = useDispatch()
  const [isLoadingOriginalButton, setIsLoadingOriginalButton] = useState(false)
  const [isLoadingDraftButton, setIsLoadingDraftButton] = useState(false)
  const [attached, setAttached] = useState([...detailHC?.attached])
  const [canvasBase64, setCanvasBase64] = useState(null)
  const [newDetailHC, setNewDetailHC] = useState({ ...detailHC })
  const { selectedTurn } = useSelector((state) => state.turns)
  const { patientSelected } = useSelector((state) => state.patientCentres)
  const allDoctorCentres = useSelector((state) => state.doctorCentres.doctorCentres)
  const patientCentre = selectedTurn ? selectedTurn.patientCentre : patientSelected

  const MAX_ATTACHED_FILES = 4

  const findInsuranceIndex = (insuranceId) => {
    let insuranceIndex = patientCentre?.insurances.findIndex((elem) => elem._id === insuranceId)
    return insuranceIndex
  }

  useEffect(() => {
    let odontogramFound = false
    let filteredOdontograms = []

    detailHC?.clinicHistoryFields?.forEach((field) => {
      if (!field.name.includes('Odontograma', 'Odontograma - adultos/niños')) {
        filteredOdontograms.push(field)
      }
      if (field.name.includes('Odontograma', 'Odontograma - adultos/niños') && !odontogramFound) {
        odontogramFound = true
        filteredOdontograms.push(field)
      }
    })

    let newHC = {
      ...detailHC,
      clinicHistoryFields: filteredOdontograms,
    }
    setNewDetailHC(newHC)
  }, [detailHC])

  const handleSubmit = async (data, e) => {
    if (e) {
      let buttonClicked = e?.nativeEvent?.submitter?.name

      if (buttonClicked === 'original') {
        data.isDraft = false
        setIsLoadingOriginalButton(true)
      } else {
        setIsLoadingDraftButton(true)
      }
    }

    //update insurance info
    data.insurance = selectedTurn
      ? selectedTurn?.insurance && {
          _id: selectedTurn?.insurance._id,
          name: selectedTurn?.insurance.name,
          policyNumber:
            selectedTurn?.patientCentre?.policyNumbers[findInsuranceIndex(selectedTurn?.insurance._id)] || '',
          insurancePlan:
            selectedTurn?.patientCentre?.insurancePlan[findInsuranceIndex(selectedTurn?.insurance._id)] || '',
        }
      : patientSelected.insurances[0]
        ? {
            _id: patientSelected.insurances[0]._id,
            name: patientSelected.insurances[0].name,
            policyNumber: patientSelected.policyNumbers[0],
            insurancePlan: patientSelected.insurancePlan[0],
          }
        : ''

    if (data.shareWithAll) {
      data.shared = [...allDoctorCentres]
    }

    if (canvasBase64 !== null) {
      const res1 = await fetch(canvasBase64)
      const res2 = await res1.blob()
      const formData = new FormData()
      formData.append('file', res2, 'ImageFileBlob.png')

      const odontogramURL = await postFile(formData)
      data.clinicHistoryFields.forEach((field) => {
        if (field.name === 'Odontograma' || field.name === 'Odontograma - adultos/niños') {
          field.value = odontogramURL
          return
        }
      })
    }

    try {
      if (!e) {
        await dispatch(updateClinicHistory(data, true))
      } else {
        await dispatch(updateClinicHistory(data))
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingOriginalButton(false)
      setIsLoadingDraftButton(false)
    }
  }

  return (
    <div>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data, e) => handleSubmit(data, e)}
        defaultValues={{ ...formDefaultValues, ...newDetailHC }}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent
          isLoadingDraftButton={isLoadingDraftButton}
          isLoadingOriginalButton={isLoadingOriginalButton}
          detailHC={newDetailHC}
          attached={attached}
          setAttached={setAttached}
          isNewTurn={isNewTurn}
          canvasBase64={canvasBase64}
          setCanvasBase64={setCanvasBase64}
          index={index}
          onSubmit={(data, e) => handleSubmit(data, e)}
          defaultValues={{ ...formDefaultValues, ...newDetailHC }}
          allowEditHC={allowEditHC}
          maxAttachedFiles={MAX_ATTACHED_FILES}
        />
      </ContainerForm>
    </div>
  )
}

export default Form
