import {
  fetchStatus,
  fetchWappRemindersHistory,
  fetchWappTurnReminderById,
  fetchWappTurnReminders,
  postDownDevice,
  postSendText,
  postUpDevice,
  postWappReminder,
} from '@/services/wapp'
import {
  CREATED_WAPP_REMINDER,
  GET_WAPP_REMINDERS_HISTORY,
  GET_WAPP_TURN_HISTORY,
  GET_WAPP_TURN_REMINDERS,
  LOADING,
  SET_SENT_REMINDERS_QUANTITY,
  UPDATE_WAPP_REMINDER_HISTORY,
  UPDATE_WAPP_STATUS,
  UPDATE_WAPP_TURN_HISTORY,
} from '@/store/wapp'

import { showToast } from '../../utils/toast/index'

const updateWappStatus = (centreId) => async (dispatch) => {
  try {
    const statusData = await fetchStatus(centreId)
    dispatch(UPDATE_WAPP_STATUS(statusData))
    return statusData
  } catch (e) {
    if (e) {
      showToast('Ups! algo salio mal, intenta nuevamente', 'error')
    }
  }
}

const linkUpDevice = (centreId) => async (dispatch) => {
  try {
    dispatch(LOADING(true))
    const response = await postUpDevice(centreId)
    dispatch(LOADING(false))
    return response
  } catch (e) {
    if (e) {
      showToast('Ups! algo salio mal, intenta nuevamente', 'error')
      dispatch(LOADING(false))
    }
  }
}

const linkDownDevice = (centreId) => async (dispatch) => {
  try {
    dispatch(LOADING(true))
    const response = await postDownDevice(centreId)
    dispatch(LOADING(false))
    return response
  } catch (e) {
    if (e) {
      showToast('Ups! algo salio mal, intenta nuevamente', 'error')
      dispatch(LOADING(false))
    }
  }
}

const sendWappMessage = (deviceId, data, showAlert) => async (dispatch) => {
  try {
    const response = await postSendText(deviceId, data)
    if (showAlert) {
      showToast('Recordatorio automático enviado correctamente', 'success', 2000)
    }
    return response
  } catch (e) {
    if (e) {
      showToast('Ups! algo salio mal, intenta nuevamente', 'error')
      dispatch(LOADING(false))
    }
  }
}

const getWappTurnReminders = (params) => async (dispatch) => {
  try {
    dispatch(LOADING(true))
    let { docs: allTurns, ...paginationData } = await fetchWappTurnReminders(params)
    dispatch(GET_WAPP_TURN_REMINDERS({ allTurns, paginationData }))
    dispatch(LOADING(false))
  } catch (e) {
    console.log(e)
  }
}

const getWappTurnHistory = (params) => async (dispatch) => {
  try {
    dispatch(LOADING(true))
    let { docs: allTurns, ...paginationData } = await fetchWappTurnReminders(params)
    await dispatch(GET_WAPP_TURN_HISTORY({ allTurns, paginationData }))
    dispatch(LOADING(false))
  } catch (e) {
    console.log(e)
  }
}
const getWappRemindersHistory = (params) => async (dispatch) => {
  try {
    dispatch(LOADING(true))
    let { docs: allReminders, ...paginationData } = await fetchWappRemindersHistory(params)
    await dispatch(GET_WAPP_REMINDERS_HISTORY({ allReminders, paginationData }))
    dispatch(LOADING(false))
  } catch (e) {
    console.log(e)
  }
}

const updateWappTurnHistory = (turn) => async (dispatch) => {
  try {
    dispatch(LOADING(true))
    dispatch(UPDATE_WAPP_TURN_HISTORY(turn))
    dispatch(LOADING(false))
  } catch (e) {
    console.log(e)
  }
}
const updateWappReminderHistory = (reminder) => async (dispatch) => {
  try {
    dispatch(LOADING(true))
    dispatch(UPDATE_WAPP_REMINDER_HISTORY(reminder))
    dispatch(LOADING(false))
  } catch (e) {
    console.log(e)
  }
}

const createWappReminder = (params) => async (dispatch) => {
  try {
    const createdWappRemineder = await postWappReminder(params)
    dispatch(CREATED_WAPP_REMINDER(createdWappRemineder))
    return createdWappRemineder
  } catch (e) {
    console.log(e)
    return e
  }
}

const getWappTurnReminderById = (turnId) => async (dispatch) => {
  try {
    const cratedWappRemineder = await fetchWappTurnReminderById(turnId)
    return cratedWappRemineder
  } catch (e) {
    console.log(e)
    return e
  }
}

const setSentRemindersQuantity = (sentReminders) => async (dispatch) => {
  try {
    await dispatch(SET_SENT_REMINDERS_QUANTITY(sentReminders))
  } catch (e) {
    console.log(e)
  }
}

export {
  createWappReminder,
  getWappRemindersHistory,
  getWappTurnHistory,
  getWappTurnReminderById,
  getWappTurnReminders,
  linkDownDevice,
  linkUpDevice,
  sendWappMessage,
  setSentRemindersQuantity,
  updateWappReminderHistory,
  updateWappStatus,
  updateWappTurnHistory,
}
