import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import LoaderCircle from '@/components/LoaderCircle'
import TitlePopUp from '@/components/TitlePopUp'
import { useUser } from '@/context/UserProvider'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { Grid } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import dayjs from 'dayjs'
import CardInformation from '../cardInformation'

import s from './styles.module.scss'

const PopUpShowMoreTurns = ({
  open,
  setOpen,
  availabilityPerDay,
  daySelected,
  entity,
  allServices,
  selectedService,
  handleCreatePendingTurn,
  isLoading,
}) => {
  const centreID = useParams().id
  const { user } = useUser()

  const extraServices = useSelector((state) => state.turns.onLineTurns.extraServices)

  const handleClose = () => {
    setOpen(false)
  }

  const formatDateLong = (date) => {
    const dayNumber = dayjs(date).format('D')
    const dayName = dayjs(date).format('dddd')
    const month = dayjs(date).format('MMMM')
    return `${dayName} ${dayNumber} de ${month}`
  }

  return (
    <Dialog disableEscapeKeyDown={true} maxWidth="md" open={open} onClose={handleClose}>
      <div className={s.containerPopUp}>
        <TitlePopUp textTitle={`Disponibilidad para el día ${formatDateLong(daySelected)}`} handleClose={handleClose} />

        {isLoading ? (
          <LoaderCircle size={30} />
        ) : (
          <>
            <div className={s.detailPopUp}>
              <CardInformation entity={entity} allServices={allServices} />
            </div>
            <Grid container spacing={2} className={s.containerTurns}>
              {availabilityPerDay?.map((turnData, index) => (
                <Grid key={index} item md={3} sm={4} xs={6} className={s.detailTurns}>
                  <Link
                    to={!!user ? '' : `/turnos/conectate/${centreID}`}
                    className={s.link}
                    onClick={() => mxp.track(mxp.events.centreLanding.agenda.dateSelected)}
                  >
                    <div
                      className={s.itemTurn}
                      onClick={() =>
                        handleCreatePendingTurn(user, entity, turnData, daySelected, selectedService, extraServices)
                      }
                    >
                      <span>{turnData.startHour} hs</span>
                    </div>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </div>
    </Dialog>
  )
}

export default PopUpShowMoreTurns
