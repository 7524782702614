import React from 'react'

import Calendars from './components/calendars'
import ShowInformation from './components/showInformation'

const TabNonWorkingDays = () => {
  return (
    <>
      <Calendars />
      <ShowInformation />
    </>
  )
}

export default TabNonWorkingDays
