import {
  addNewInsurancePrice,
  deleteInsurancePrice,
  fetchInsurancePrices,
  fetchSingleInsurancePrice,
  putInsurancePrice,
} from '@/services/insurancePrices'
import {
  FETCH_INSURANCE_PRICES,
  INSURANCE_PRICE_CREATED,
  INSURANCE_PRICE_DELETE,
  INSURANCE_PRICE_SELECTED,
  INSURANCE_PRICE_UPDATED,
  RESET_INSURANCE_PRICE_SELECTED,
} from '@/store/insurancePrices'
import { showToast } from '@/utils/toast'

const getAllInsurancePrices = (params) => async (dispatch) => {
  try {
    const fetchAllInsurancePrices = await fetchInsurancePrices(params)
    dispatch(FETCH_INSURANCE_PRICES(fetchAllInsurancePrices))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const postInsurancePrice = (data) => async (dispatch) => {
  let insuranceObj = { ...data?.insurance }
  let serviceObj = { ...data?.service }
  data.insurance = data?.insurance?._id
  data.service = data?.service?._id

  try {
    const newInsurancePrice = await addNewInsurancePrice(data)
    if (insuranceObj) newInsurancePrice.insurance = insuranceObj
    if (serviceObj) newInsurancePrice.service = serviceObj
    dispatch(INSURANCE_PRICE_CREATED(newInsurancePrice))
    if (newInsurancePrice) {
      showToast('Plan agregado correctamente', 'success')
    }
    return newInsurancePrice
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const deleteInsurancePriceAdded = (id) => async (dispatch) => {
  try {
    await deleteInsurancePrice(id)
    dispatch(INSURANCE_PRICE_DELETE(id))
    showToast('Plan Eliminado correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateInsurancePrice = (data) => async (dispatch) => {
  let insuranceObject = { ...data.insurance }
  let serviceObject = { ...data.service }
  data.insurance = data.insurance._id
  data.service = data.service._id

  try {
    let insurancePriceUpdated = await putInsurancePrice(data)
    if (insuranceObject) insurancePriceUpdated.insurance = insuranceObject
    if (serviceObject) insurancePriceUpdated.service = serviceObject

    dispatch(INSURANCE_PRICE_UPDATED(insurancePriceUpdated))

    if (insurancePriceUpdated) {
      showToast('Plan actualizado correctamente', 'success')
    }
    return insurancePriceUpdated
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedInsurancePrice = (id) => async (dispatch) => {
  try {
    const singleInsurancePrice = await fetchSingleInsurancePrice(id)
    dispatch(INSURANCE_PRICE_SELECTED(singleInsurancePrice))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedInsurancePrice = () => async (dispatch) => {
  try {
    dispatch(RESET_INSURANCE_PRICE_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export {
  deleteInsurancePriceAdded,
  getAllInsurancePrices,
  postInsurancePrice,
  resetSelectedInsurancePrice,
  selectedInsurancePrice,
  updateInsurancePrice,
}
