import { addNewPatient, deletePatient, fetchOnePatient, fetchPatients, putPatient } from '@/services/patients'
import { PATIENT_SELECTED as PATIENT_SELECTED_PROFILE } from '@/store/myProfile'
import {
  FETCH_PATIENTS,
  IS_LOADING_PATIENTS,
  PATIENT_CREATED,
  PATIENT_DELETE,
  PATIENT_SELECTED,
  PATIENT_UPDATED,
  RESET_PATIENT_SELECTED,
  RESET_PATIENT_STATE,
  SET_PATIENT_SELECTED,
} from '@/store/patients'
import { showToast } from '@/utils/toast'

const getPatients = (params) => async (dispatch) => {
  try {
    let { docs: allPatients, ...paginationData } = await fetchPatients(params)
    dispatch(FETCH_PATIENTS({ allPatients, paginationData }))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const postPatient = (data) => async (dispatch) => {
  try {
    const newPatient = await addNewPatient(data)
    dispatch(PATIENT_CREATED(newPatient))
    showToast('Paciente agregado correctamente', 'success')
    return newPatient
  } catch (error) {
    if (error === 'El elemento que intenta guardar ya existe') {
      showToast('Error al crear el paciente. Ya existe un usuario con el DNI/email ingresado', 'error', 7000)
    } else {
      showToast('Error al crear al paciente', 'error')
    }
  }
}

const deletePatientAdded = (id) => async (dispatch) => {
  try {
    await deletePatient(id)
    dispatch(PATIENT_DELETE(id))
    showToast('Paciente Eliminado correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updatePatient = (data, addInMyProfile) => async (dispatch) => {
  try {
    const patientUpdated = await putPatient(data)
    dispatch(PATIENT_UPDATED(patientUpdated))

    if (addInMyProfile) {
      dispatch(PATIENT_SELECTED_PROFILE(patientUpdated))
    }

    if (patientUpdated) {
      showToast('Paciente actualizado correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedPatient = (id) => async (dispatch) => {
  try {
    const singlePatient = await fetchOnePatient(id)
    dispatch(PATIENT_SELECTED(singlePatient))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedPatient = () => async (dispatch) => {
  try {
    dispatch(RESET_PATIENT_SELECTED(null))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const setPatientSelected = (patient) => (dispatch) => {
  try {
    dispatch(SET_PATIENT_SELECTED(patient))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const clearPatientState = () => async (dispatch) => {
  try {
    dispatch(RESET_PATIENT_STATE())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const handlePaginationChangePatients = (newPage, newLimit, searchText) => async (dispatch) => {
  const params = {
    page: newPage,
    limit: newLimit,
    search: searchText,
  }

  dispatch(IS_LOADING_PATIENTS(true))
  const { docs: allPatients, ...paginationData } = await fetchPatients(params)
  dispatch(FETCH_PATIENTS({ allPatients, paginationData }))
  dispatch(IS_LOADING_PATIENTS(false))
}

export {
  clearPatientState,
  deletePatientAdded,
  getPatients,
  handlePaginationChangePatients,
  postPatient,
  putPatient,
  resetSelectedPatient,
  selectedPatient,
  setPatientSelected,
  updatePatient,
}
