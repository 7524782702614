import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const getNotifications = async (params) => {
  const route = `api/notifications`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getAdminNotifications = async (text) => {
  const route = `api/notifications/search?userEmail=${text}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getNotificationsReads = async () => {
  const route = `api/notificationReads`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postNotificationRead = async (notification) => {
  const route = `api/notificationReads`
  return API.post(route, { notification })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewNotification = async (data) => {
  const route = `api/notifications`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putNotification = async (data) => {
  const route = `api/notifications`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteNotification = async (id) => {
  const route = `api/notifications/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export {
  addNewNotification,
  deleteNotification,
  getAdminNotifications,
  getNotifications,
  getNotificationsReads,
  postNotificationRead,
  putNotification,
}
