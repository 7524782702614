import React from 'react'

import TurnDialogHeader from '../../components/TurnDialogHeader'

const DialogHeader = ({ setOpen, expandPopUp, setExpandPopUp, tabValue, handleClose }) => {
  return (
    <TurnDialogHeader
      handleClose={handleClose}
      setOpen={setOpen}
      expandPopUp={expandPopUp}
      setExpandPopUp={setExpandPopUp}
      tabValue={tabValue}
    />
  )
}

export default DialogHeader
