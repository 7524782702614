import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import { useSocket } from '@/context/SocketProvider'
import { RESET_DOCTOR_CENTRE_SELECTED } from '@/store/doctorCentres/index.js'
import { RESET_MACHINE_SELECTED } from '@/store/machines/index.js'
import {
  resetSelectedPatientCentre,
  updatePatientCentreAndSelectedTurn,
  updatePatientCentres,
} from '@/store/patientCentres/actions'
import { updatePatientTreatment } from '@/store/treatments/actions'
import { cleanSelectedTurn, postNewTurn, resetDataToCreateTurnTreatment } from '@/store/turns/actions'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Button } from '@mui/material'

import dayjs from 'dayjs'
import AddSessionPopUp from '../addSessionPopUp'

import s from './styles.module.scss'

const AddNewSessionButton = ({ centre, treatment, setOpenPopUp, setTreatmentSection }) => {
  const dispatch = useDispatch()
  const selectedTurn = useSelector((state) => state.turns.selectedTurn)
  const machine = useSelector((state) => state.machines.machineSelected) || selectedTurn?.machine
  const doctorCentreSelected =
    useSelector((state) => state.doctorCentres.doctorCentreSelected) || selectedTurn?.doctorCentre
  const { doctorCentres } = useSelector((state) => state.doctorCentres)
  const objectToAddSessionFromAgenda = useSelector((state) => state.turns.treatmentTurn)
  const [open, setOpen] = useState(false)
  const { socket } = useSocket()
  const [loading, setLoading] = useState(false)
  const dataForm = objectToAddSessionFromAgenda?.infoToCreateTurn
  const patientCentre = objectToAddSessionFromAgenda?.patientSelected
  const durationTurn = objectToAddSessionFromAgenda?.durationTurn
  const startHour = objectToAddSessionFromAgenda?.entityId.selectedDate
  const hourTurn = dayjs(startHour).utc().format('H')
  const minuteTurn = dayjs(startHour).utc().format('mm')
  const newStartTurnHour = dayjs(startHour).utc().hour(hourTurn).minute(minuteTurn).format()
  const newFinishTurnHour = dayjs(newStartTurnHour).utc().add(durationTurn, 'm').format()

  const saveTreatmentFromCreateTurn = async () => {
    const hideAlert = true
    let service = dataForm.service

    // dataForm has both turn and patientCentre data
    // remove duplicated fields to avoid overlap of data
    // TODO: map 1-1 turn.attritute with dataForm.attribute
    const dataFormTurnData = {
      ...dataForm,
    }

    delete dataFormTurnData.creationDate
    delete dataFormTurnData.creationBy
    delete dataFormTurnData.modificationDate
    delete dataFormTurnData.modificationBy
    delete dataFormTurnData.logs
    delete dataFormTurnData.available
    //dataFormTurnData?.birth
    dataFormTurnData.birth = dataFormTurnData.birth
      ? dayjs(dataFormTurnData.birth).isValid()
        ? dayjs(dataFormTurnData.birth).toISOString()
        : ''
      : ''

    let auxTurn = {
      ...dataFormTurnData,
      patientCentre: patientCentre,
      amount: treatment.price,
      centre: centre._id,
      date: dayjs(newStartTurnHour).utc().format('YYYY-MM-DD'),
      startHour: newStartTurnHour,
      finishHour: newFinishTurnHour,
      turnDuration: durationTurn,
      consultationDuration: durationTurn,
      treatment: treatment,
      insurance: null,
      insurancePrice: null,
    }

    delete auxTurn._id
    delete auxTurn.id
    let doctorCentre = null

    if (service._id === 'consultation') {
      auxTurn.firstTime = false
      auxTurn.service = null
    } else if (service._id === 'firstConsultation') {
      auxTurn.firstTime = true
      auxTurn.service = null
    } else if (service._id === null) {
      auxTurn.service = null
    } else if (service._id === '-') {
      auxTurn.service = null
    } else {
      auxTurn.service = service
    }
    if (machine) {
      if (auxTurn.doctorCentre === '-') {
        auxTurn.doctorCentre = null
        auxTurn.machine = machine
      } else {
        doctorCentre = doctorCentres?.filter((d) => d._id === dataFormTurnData.doctorCentre)[0]
        auxTurn.machine = machine
        auxTurn.doctorCentre = doctorCentre
      }
    } else {
      auxTurn.doctorCentre = doctorCentreSelected
    }

    try {
      await dispatch(updatePatientCentres(patientCentre, null, hideAlert))
      const newTurn = await dispatch(postNewTurn(auxTurn))
      newTurn.service = auxTurn.service
      const patientTreatment = {
        ...treatment,
        turns: [...treatment.turns, newTurn],
      }

      setTreatmentSection(false)
      const updatedPatientTreatment = await dispatch(updatePatientTreatment(patientTreatment, hideAlert))
      const turnWithTreatment = {
        ...newTurn,
        treatment: updatedPatientTreatment,
      }

      socket.emit('calendar_new_turn', `${turnWithTreatment.centre}_calendar`, turnWithTreatment)
      setLoading(false)
      resetDataToCreateTurnTreatment()
      setOpenPopUp(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
      setOpenPopUp(false)
    } finally {
      dispatch(RESET_DOCTOR_CENTRE_SELECTED())
      dispatch(RESET_MACHINE_SELECTED())
    }
  }

  const saveTreatmentFromUpdateTurn = async () => {
    const hideAlert = true
    let updatePatientCentre = selectedTurn.patientCentre
    let auxTurn = {
      ...selectedTurn,
      treatment: treatment,
    }

    try {
      const updatedTurn = await dispatch(updatePatientCentreAndSelectedTurn(updatePatientCentre, auxTurn, hideAlert))
      const patientTreatment = {
        ...treatment,
        turns: [...treatment.turns, updatedTurn],
      }

      const updatedPatientTreatment = await dispatch(updatePatientTreatment(patientTreatment))

      const turnWithTreatment = {
        ...updatedTurn,
        treatment: updatedPatientTreatment,
      }
      socket.emit('calendar_edit_turn', `${selectedTurn.centre}_calendar`, turnWithTreatment)
      setLoading(false)
      setOpenPopUp(false)
      dispatch(resetSelectedPatientCentre())
      dispatch(cleanSelectedTurn())
    } catch (e) {
      console.log(e)
      setLoading(false)
    } finally {
      dispatch(RESET_DOCTOR_CENTRE_SELECTED())
      dispatch(RESET_MACHINE_SELECTED())
    }
  }

  const saveTurnAsTreatmentSession = () => {
    // setLoading(true)
    if (selectedTurn) {
      saveTreatmentFromUpdateTurn()
    } else {
      saveTreatmentFromCreateTurn()
    }
  }

  const checkAssignedTreatment = () => {
    if (treatment._id === selectedTurn.treatment._id) {
      return 'Este turno ya está asignado a este grupo de sesiones.'
    } else {
      return 'Este turno ya está asignado a un grupo de sesiones.'
    }
  }

  const hasTreatmentAssigned = () => {
    if (selectedTurn?.treatment) {
      return <div className={s.treatmentAssigned}>{checkAssignedTreatment()}</div>
    } else {
      return (
        <Button onClick={() => saveTurnAsTreatmentSession()} fullWidth className={s.colorAddSessionsText}>
          <AddCircleOutlineIcon className={s.addCircleIcon} />
          Guardar esta cita como sesión
        </Button>
      )
    }
  }

  const handleAddNewSessionButton = () => {
    if (!objectToAddSessionFromAgenda) {
      return (
        <Button fullWidth className={s.colorAddSessionsText} onClick={() => setOpen(true)}>
          <AddCircleOutlineIcon className={s.addCircleIcon} />
          Agregar sesión
        </Button>
      )
    } else {
      return (
        <Button onClick={() => saveTurnAsTreatmentSession()} fullWidth className={s.colorAddSessionsText}>
          <AddCircleOutlineIcon className={s.addCircleIcon} />
          Guardar esta cita como sesión
        </Button>
      )
    }
  }
  return (
    <div className={s.divContainer}>
      <h5>Sesiones</h5>
      {!loading ? <>{selectedTurn ? hasTreatmentAssigned() : handleAddNewSessionButton()}</> : <LoaderCircle />}

      {open && <AddSessionPopUp open={open} setOpen={setOpen} centre={centre} treatment={treatment} />}
    </div>
  )
}

export default AddNewSessionButton
