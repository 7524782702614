import React, { useEffect, useState } from 'react'
import { deviceDetector } from '@/helpers/deviceDetector'
import Box from '@mui/material/Box'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'

import s from './styles.module.scss'

const steps = ['1', '2', '3', '4']

export default function HeaderOnLineTurns({ isAuthenticated, selectedPatient, confirmedTurn }) {
  const [activeStep, setActiveStep] = useState(0)
  let isMobile = deviceDetector()

  useEffect(() => {
    if (!isAuthenticated && !selectedPatient) {
      setActiveStep(0)
    }
    if (isAuthenticated && !selectedPatient) {
      setActiveStep(1)
    }

    if (selectedPatient && !confirmedTurn) {
      setActiveStep(2)
    }
    if (confirmedTurn) {
      setActiveStep(3)
    }
  }, [isAuthenticated, selectedPatient, confirmedTurn])

  return (
    <Box className={s.boxContainer}>
      {isMobile && <div className={s.infoText}>Completa los siguientes pasos para reservar tu cita</div>}
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepButton />
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
