import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchUserMe = async (params) => {
  const route = `api/users/me`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchAllUsers = async (params) => {
  const route = `api/users`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postUser = async (userData) => {
  const route = `api/users`
  return API.post(route, userData)

    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getVerificationToken = async (token, role) => {
  const route = `api/verificationToken/${role}/${token}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postVerificationToken = async (token, role) => {
  const route = `api/verificationToken/${role}/${token}`
  return API.post(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putUserPassword = async (email) => {
  const route = `/api/users/password/reset`
  return API.put(route, email)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putUserPasswordById = async (data, userId) => {
  const route = `/api/users/${userId}/password`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putUser = async (data, userId) => {
  const route = `/api/users/${userId}`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteUser = async (userId) => {
  const route = `/api/users/${userId}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export {
  deleteUser,
  fetchAllUsers,
  fetchUserMe,
  getVerificationToken,
  postUser,
  postVerificationToken,
  putUser,
  putUserPassword,
  putUserPasswordById,
}
