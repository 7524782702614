import { css } from 'styled-components'

//purple calu
export const Calu_a = css`
  color: ${(props) => props.theme.colors.a};
`
export const Calu_a_bg = css`
  color: ${(props) => props.theme.colors.base.white};
  background-color: ${(props) => props.theme.colors.a};
  &:hover {
    background-color: ${(props) => props.theme.colors.a};
    color: ${(props) => props.theme.colors.base.white};
  }
`

// dark blue
export const Calu_b = css`
  color: ${(props) => props.theme.colors.b};
`
export const Calu_b_bg = css`
  color: ${(props) => props.theme.colors.base.white};
  background-color: ${(props) => props.theme.colors.b};
  &:hover {
    background-color: ${(props) => props.theme.colors.b};
    color: ${(props) => props.theme.colors.base.white};
  }
`

//sky blue
export const Calu_c = css`
  color: ${(props) => props.theme.colors.c};
`
export const Calu_c_bg = css`
  color: ${(props) => props.theme.colors.b};
  background-color: ${(props) => props.theme.colors.c};
`

//green
export const Calu_d = css`
  color: ${(props) => props.theme.colors.d};
`
export const Calu_d_bg = css`
  color: ${(props) => props.theme.colors.b};
  background-color: ${(props) => props.theme.colors.d};
`

//yellow
export const Calu_e = css`
  color: ${(props) => props.theme.colors.e};
`
export const Calu_e_bg = css`
  color: ${(props) => props.theme.colors.b};
  background-color: ${(props) => props.theme.colors.e};
`

//pink
export const Calu_f = css`
  color: ${(props) => props.theme.colors.f};
`
export const Calu_f_bg = css`
  color: ${(props) => props.theme.colors.base.white};
  background-color: ${(props) => props.theme.colors.f};
  &:hover {
    background-color: ${(props) => props.theme.colors.f};
    color: ${(props) => props.theme.colors.base.white};
  }
`

//red Chedoc
export const Calu_delete = css`
  color: ${(props) => props.theme.colors.turn.absent};
`
export const Calu_delete_bg = css`
  color: ${(props) => props.theme.colors.base.white};
  background-color: ${(props) => props.theme.colors.turn.absent};
  &:hover {
    background-color: ${(props) => props.theme.colors.turn.absent};
    color: ${(props) => props.theme.colors.base.white};
  }
`
