import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const loginUser = async (userCredentials) => {
  const route = `api/auth/local`
  return API.post(route, userCredentials)
    .then((response) => Promise.resolve(response?.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const logoutUser = async () => {
  //
}

export { loginUser, logoutUser }
