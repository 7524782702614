import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Button, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import PopUpEditDoctorCentre from './components/PopUpEditDoctorCentre'
import SearchBar from '../searchBar'

import s from '../../styles.module.scss'

const DoctorCentresTab = ({ directionOfAnimation, indexTab }) => {
  const [doctorCentresFiltered, setDoctorCentresFiltered] = useState([])
  const [inputSearchDoctorCentres, setInputSearchDoctorCentres] = useState('')
  const [openPopUp, setOpenPopUp] = useState(false)
  const [selectedDoctor, setSelectedDoctor] = useState({})
  const [pageSize, setPageSize] = useState(20)

  const doctorCentres = useSelector((state) => state.doctorCentres?.doctorCentres)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  }

  const columns = [
    {
      field: 'doctorCentre',
      headerName: 'Profesional',
      minWidth: 400,
      headerClassName: 'header',
      sortable: false,
      ...stylesColumns,
    },
    {
      field: 'price',
      headerName: `Precio consulta particular (${handleSetMoneySign(country)})`,
      minWidth: 300,
      headerClassName: 'header',
      type: 'number',
      sortable: false,
      ...stylesColumns,
    },
    {
      field: 'priceFirstTime',
      headerName: `Precio primera consulta particular (${handleSetMoneySign(country)})`,
      minWidth: 300,
      headerClassName: 'header',
      type: 'number',
      sortable: false,
      ...stylesColumns,
    },
    {
      field: 'button',
      headerName: '',
      minWidth: 80,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header',
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title="Editar">
          <Button onClick={() => handleClick(params?.row)} className={s.rowButton} tabIndex={params.hasFocus ? 0 : -1}>
            <EditOutlinedIcon />
          </Button>
        </Tooltip>
      ),
    },
  ]

  const rows = doctorCentresFiltered?.map((doctor) => ({
    id: doctor._id,
    doctorCentre: `${doctor?.lastname?.toUpperCase()}, ${capitalizeFirstLetters(doctor?.firstname)}`,
    price: String(doctor?.price || 0),
    priceFirstTime: String(doctor?.priceFirstTime || 0),
    raw: doctor,
  }))

  const handleClick = (data) => {
    setOpenPopUp(true)
    setSelectedDoctor(data)
  }

  const handlePaginationChange = ({ pageSize }) => {
    setPageSize(pageSize)
  }

  return (
    <div className={directionOfAnimation.directionOfAnimation}>
      <SearchBar
        inputSearch={inputSearchDoctorCentres}
        setInputSearch={setInputSearchDoctorCentres}
        prevState={doctorCentres}
        setFilteredState={setDoctorCentresFiltered}
        indexTab={indexTab}
      />
      {doctorCentres?.length === 0 ? (
        <p className={s.emptyList}>No hay profesionales cargados aún</p>
      ) : (
        <div>
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: pageSize,
                },
              },
            }}
            rows={rows || []}
            columns={columns}
            pagination
            onPaginationModelChange={handlePaginationChange}
            pageSizeOptions={[20, 50, 100]}
            slotProps={{
              pagination: {
                labelRowsPerPage: 'Entradas por página:',
                labelDisplayedRows: (props) => `${props.from} - ${props.to} de ${props.count}`,
              },
            }}
            disableRowSelectionOnClick={true}
            rowHeight={45}
            autoHeight={true}
            onCellClick={(params) => handleClick(params?.row)}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            sx={{
              px: 0,
              '& .header': {
                fontSize: '14px',
              },
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
            }}
          />
        </div>
      )}

      <PopUpEditDoctorCentre open={openPopUp} setOpen={setOpenPopUp} selectedDoctor={selectedDoctor} />
    </div>
  )
}

export default DoctorCentresTab
