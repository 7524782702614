import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: `'poppins', sans-serif`,
  },
})

const ThemeMui = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>

export default ThemeMui
