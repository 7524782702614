export const reducePayload = (clinicHistory) => {
  if (!clinicHistory) return {}
  // to force deep copy
  let auxClinicHistory = JSON.parse(JSON.stringify(clinicHistory))

  // reduce clinicHistory.patientCentre
  if (auxClinicHistory?.patientCentre?._id) {
    auxClinicHistory.patientCentre = {
      _id: auxClinicHistory.patientCentre._id,
      firstname: auxClinicHistory.patientCentre.firstname,
      lastname: auxClinicHistory.patientCentre.lastname,
    }
  }

  // reduce clinicHistory.doctorCentre
  if (auxClinicHistory?.doctorCentre?._id) {
    auxClinicHistory.doctorCentre = {
      _id: auxClinicHistory?.doctorCentre?._id,
      firstname: auxClinicHistory?.doctorCentre?.firstname,
      lastname: auxClinicHistory?.doctorCentre?.lastname,
    }
  }

  // reduce clinicHistory.centre
  if (auxClinicHistory?.centre?._id) {
    auxClinicHistory.centre = auxClinicHistory.centre._id
  }

  // reduce clinicHistory.shared
  if (auxClinicHistory?.shared) {
    auxClinicHistory.shared = auxClinicHistory.shared.map((s) => (s?._id ? s._id : s))
  }

  return auxClinicHistory
}
