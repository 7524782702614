import { getCentreDetail, putCentre } from '@/services/centres'
import { CENTRE_UPDATED } from '@/store/centres'
import { FETCH_ROOMS, RESET_ROOM_SELECTED, ROOM_CREATED, ROOM_DELETE, ROOM_SELECTED, ROOM_UPDATED } from '@/store/rooms'
import { showToast } from '@/utils/toast'

const getAllRooms = (id) => async (dispatch) => {
  try {
    const centre = await getCentreDetail(id)
    const rooms = centre.rooms
    dispatch(FETCH_ROOMS({ rooms, centre }))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const postRoom = (data) => async (dispatch, getState) => {
  try {
    const state = getState()
    const rooms = [...state.rooms.allRooms, data]
    const auxCentre = { ...state.rooms.relatedCentre, rooms }
    const centre = await putCentre(auxCentre)
    dispatch(ROOM_CREATED({ rooms, centre }))
    if (centre) {
      showToast('Consultorio agregado correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const deleteRoomAdded = (data) => async (dispatch, getState) => {
  try {
    const state = getState()
    const rooms = state.rooms.allRooms?.filter((room) => room._id !== data)
    const auxCentre = { ...state.rooms.relatedCentre, rooms }
    const centre = await putCentre(auxCentre)
    dispatch(ROOM_DELETE({ rooms, centre }))
    showToast('Consultorio Eliminado correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateRoom = (data, callback) => async (dispatch, getState) => {
  try {
    const state = getState()
    const rooms = state.rooms.allRooms.map((room) => {
      if (room._id === data._id) {
        room = data
      }

      if (room.people[0]) {
        if (room._id !== data._id && room.people[0].name === data.people[0].name) {
          room = { ...room, people: [] }
        }
      }

      return room
    })
    const auxCentre = { ...state.rooms.relatedCentre, rooms }
    const centre = await putCentre(auxCentre)
    dispatch(CENTRE_UPDATED(centre))
    dispatch(ROOM_UPDATED({ rooms, centre }))
    dispatch(ROOM_SELECTED([data]))

    if (centre) {
      showToast('Consultorio actualizado correctamente', 'success')
    }

    if (callback) {
      callback()
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedRoom = (allRooms, idRoom) => async (dispatch) => {
  try {
    const singleRoom = allRooms?.filter((room) => room._id === idRoom)
    dispatch(ROOM_SELECTED(singleRoom))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedRoom = () => async (dispatch) => {
  try {
    dispatch(RESET_ROOM_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const closeSingleRoom = (idRoom, callback) => async (dispatch, getState) => {
  try {
    const state = getState()
    const rooms = state.rooms.allRooms.map((room) => {
      if (room._id === idRoom) {
        room = { ...room, people: [] }
      }
      return room
    })
    const auxCentre = { ...state.rooms.relatedCentre, rooms }
    const centre = await putCentre(auxCentre)
    dispatch(ROOM_UPDATED({ rooms, centre }))

    if (centre) {
      showToast('Consultorio cerrado correctamente', 'success')
    }

    if (callback) {
      callback()
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const closeAllRooms = () => async (dispatch, getState) => {
  try {
    const state = getState()
    const rooms = state.rooms.allRooms.map((room) => {
      room = { ...room, people: [] }
      return room
    })
    const auxCentre = { ...state.rooms.relatedCentre, rooms }
    const centre = await putCentre(auxCentre)
    dispatch(ROOM_UPDATED({ rooms, centre }))

    if (centre) {
      showToast('Consultorios cerrados correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export {
  closeAllRooms,
  closeSingleRoom,
  deleteRoomAdded,
  getAllRooms,
  postRoom,
  resetSelectedRoom,
  selectedRoom,
  updateRoom,
}
