import React, { useState } from 'react'
import LoadingCalu from '@/components/LoadingCalu'
import NavBar from '@/components/NavBar'
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew'

import s from './styles.module.scss'

const Layout1 = ({ navbar, leftSidebar = null, isLoading, children }) => {
  const [leftSidebarIsHidden, hideLeftSideBar] = useState(true)

  return (
    <div className={s['layout-container']}>
      {navbar && (
        <div className={s['navbar-container']}>
          <NavBar />
        </div>
      )}

      {isLoading && <LoadingCalu />}

      <div className={s['content']}>
        {leftSidebar && (
          <div className={`${s['left-sidebar']} ${leftSidebarIsHidden && s['hidden']}`}>
            <div className={s['left-sidebar-toggle']} onClick={() => hideLeftSideBar(!leftSidebarIsHidden)}>
              <ArrowBackIosNew className={`${s['icon']} ${leftSidebarIsHidden && s['hidden']}`} />
            </div>

            <div className={s['sidebar-container']}>{leftSidebar}</div>
          </div>
        )}

        <div className={s['body']}>
          <div className={s['body-container']}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Layout1
