import { attachAuthToken } from '@/helpers/authTokenHandling'
import { apiWappURL } from '@/helpers/env'

import axios from 'axios'

const API = axios.create({
  baseURL: apiWappURL,
  withCredentials: false,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
})

API.interceptors.request.use((config) => {
  const request = attachAuthToken(config)

  return request
})

// API.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   async (error) => {
//     if (error.response) {
//       if (error.response.status === 401) {
//         if (!checkValidRoute()) {
//           return window.location.replace(`${baseURL}ingresar`)
//         }
//       }
//     }

//     return Promise.reject(error)
//   },
// )

export default API
