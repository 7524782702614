import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import FormCreatePatient from '@/pages/PatientCentresPage/components/formCreatePatient/index'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

export default function FormDialog({ open, patientSelected, onClose }) {
  const { t } = useTranslation('global')

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={open} onClose={onClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={
              patientSelected
                ? `Datos personales del ${t(
                    `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`,
                  )}`
                : `Nuevo ${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}`
            }
            handleClose={onClose}
          />
        </DialogTitle>
        <DialogContent>
          <FormCreatePatient onClose={onClose} formValues={patientSelected} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
