import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LinealProgress from '@/components/LinealProgress'
import TitlePopUp from '@/components/TitlePopUp'
import useValidateURLId from '@/hooks/useValidateURLId'
import { clearPatientCentres, getPatientsCentreByInputSearch } from '@/store/patientCentres/actions'
import { getAllCentreTreatments, getAllPatientTreatments } from '@/store/treatments/actions'
import { cleanPatientCentreTurns } from '@/store/turns/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Grid } from '@mui/material'
import { Autocomplete, TextField } from '@mui/material'

import TreatmentSection from '../../../../PatientCentresPage/components/treatmentSection'

import s from './styles.module.scss'

const TreatmentsPopUp = ({ open, onClose }) => {
  const centreID = useValidateURLId()
  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const [searchText, setSearchText] = useState('')
  const [patientValue, setPatientValue] = useState(null)
  const [searchingPatients, setSearchingPatients] = useState(false)
  const [showTreatmentSection, setShowTreatmentSection] = useState(false)
  const [loadingTreatmentSection, setLoadingTreatmentSection] = useState(false)

  const patientCentres = useSelector((state) => state.patientCentres.patientCentres)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  useEffect(() => {
    const searchPatient = async () => {
      setPatientValue(null)
      if (searchText && searchText.length >= 2) {
        const params = {
          centre: centreID,
          search: searchText,
        }
        setSearchingPatients(true)
        await dispatch(getPatientsCentreByInputSearch(params))
        setSearchingPatients(false)
      } else {
        dispatch(clearPatientCentres())
        dispatch(cleanPatientCentreTurns())
      }
    }
    searchPatient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const valueOfPatient = (e, dataPatient) => {
    setPatientValue(dataPatient)
  }

  const handleChange = (e) => {
    setSearchText(e.target.value)
  }

  useEffect(() => {
    const showPatientTreatment = async () => {
      if (patientValue) {
        setShowTreatmentSection(true)
        setLoadingTreatmentSection(true)
        await dispatch(getAllPatientTreatments(centreID, patientValue?._id))
        await dispatch(getAllCentreTreatments(centreID))
        setLoadingTreatmentSection(false)
      }
    }

    showPatientTreatment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientValue])

  const getOptionLabel = (option) => {
    let DNI
    if (option?.dni?.length < 15) {
      DNI = 'DNI: ' + option?.dni
    } else {
      DNI = ''
    }

    return `${option?.lastname?.toUpperCase()}, ${option?.firstname?.toUpperCase()} (${DNI})`
  }

  return (
    <Dialog disableEscapeKeyDown={true} open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogTitle id="scroll-dialog-title" className={s.dialogTitle}>
        <TitlePopUp
          textTitle={`Sesiones del ${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}`}
          handleClose={onClose}
        />
      </DialogTitle>
      <DialogContent className="animate__animated animate__fadeIn">
        <Grid container className={s.containerContent}>
          <Autocomplete
            id="search"
            freeSolo
            disableClearable
            onChange={valueOfPatient}
            options={patientCentres?.map((option) => option)}
            getOptionLabel={(option) => getOptionLabel(option)}
            isOptionEqualToValue={(option) => option?._id}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  label={`Buscar ${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}`}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                  value={searchText}
                  onChange={handleChange}
                  autoFocus={true}
                />
                {searchingPatients && <LinealProgress />}
              </>
            )}
          />
        </Grid>
        <div>
          {showTreatmentSection ? (
            <TreatmentSection
              patientSelected={patientValue}
              loading={loadingTreatmentSection}
              containerClass="noPaddNoMargin"
            />
          ) : (
            <p className={s.infoSearch}>
              Busque un {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)} para ver sus
              tratamientos.
            </p>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default TreatmentsPopUp
