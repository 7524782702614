import React, { useEffect, useState } from 'react'
import { BsTrash } from 'react-icons/bs'
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { TextOverButtons } from '@/components/TextOverButtons'
import { updateCentre } from '@/store/centres/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { Button } from '@mui/material'

import s from './styles.module.scss'

const ShowInformation = () => {
  const dispatch = useDispatch()

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const [auxCentreDetail, setAuxCentreDetail] = useState(JSON.parse(JSON.stringify(centreDetail)))

  useEffect(() => {
    let auxData = JSON.parse(JSON.stringify(centreDetail))
    auxData?.holidays?.sort((a, b) => (a > b ? 1 : -1))
    setAuxCentreDetail(auxData)
  }, [centreDetail])

  const addPropWhenClick = (i) => {
    let auxData = { ...auxCentreDetail }
    if (typeof auxData.holidays[i] !== 'object') {
      auxData.holidays[i] = { day: auxData.holidays[i], showConfirm: true }
      setAuxCentreDetail(auxData)
    } else {
      auxData.holidays[i] = { ...auxData.holidays[i], showConfirm: true }
      setAuxCentreDetail(auxData)
    }
  }

  const removePropWhenClick = (i) => {
    let auxData = { ...auxCentreDetail }
    if (typeof auxData.holidays[i] === 'object') {
      auxData.holidays[i] = { ...auxData.holidays[i], showConfirm: false }
      setAuxCentreDetail(auxData)
    }
  }

  const deleteNoWorkTime = (daySelected) => {
    let holidayList = auxCentreDetail?.holidays?.filter((item) => (item.day ? item.day !== daySelected.day : item))
    holidayList = holidayList.map((elem) => {
      if (typeof elem === 'object') return elem.day
      return elem
    })

    let auxDataToUpdate = {
      ...auxCentreDetail,
      holidays: holidayList,
    }

    try {
      dispatch(updateCentre(auxDataToUpdate))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={s.mainContaienrShowInformation}>
      <div className={s.divider}></div>

      <div className={s.containerListTimes}>
        <div>
          {auxCentreDetail?.holidays?.length === 0 ? (
            <p className={s.emptyList}>No hay días en que el centro permanecerá cerrado aún.</p>
          ) : (
            auxCentreDetail?.holidays?.map((item, i) => (
              <div key={i} className={s.itemNoWorkTimes}>
                <div className={s.detailNoWorkTimes}>
                  {item && (
                    <p>{capitalizeFirstLetters(toCustomTz(item?.day || item, 'utc', false, 'dddd DD MMMM YYYY'))}</p>
                  )}
                </div>
                {item.showConfirm ? (
                  <div className={s.containerButtonsDelete}>
                    <TextOverButtons title="Si, eliminar">
                      <div className={s.btnsDeleteIcons}>
                        <IoIosCheckmarkCircleOutline onClick={() => deleteNoWorkTime(item)} />
                      </div>
                    </TextOverButtons>
                    <TextOverButtons title="No, cancelar">
                      <div className={s.btnsCancelIcons}>
                        <IoIosCloseCircleOutline onClick={() => removePropWhenClick(i)} />
                      </div>
                    </TextOverButtons>
                  </div>
                ) : (
                  <TextOverButtons title="Eliminar">
                    <Button onClick={() => addPropWhenClick(i)} className={s.trashIcon}>
                      <BsTrash />
                    </Button>
                  </TextOverButtons>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default ShowInformation
