import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BsTrash } from 'react-icons/bs'
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import PopUpConfirmNoWorkTime from '@/components/holidaysModule/components/PopUpConfirmNoWorkTime'
import CaluButton from '@/components/styledComponents/CaluButton'
import { TextOverButtons } from '@/components/TextOverButtons'
import { useSocket } from '@/context/SocketProvider'
import { useUser } from '@/context/UserProvider'
import { DateRangeDinamicStyles } from '@/pages/AgendaPage/forms/downloadTurns/component/DateRangeDinamicStyles'
import { fetchTurns } from '@/services/turns'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { formatTime } from '@/utils/functions/formatTime'
import { isNoWorkTimeValid } from '@/utils/functions/noWorkTimeValid'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { showToast } from '@/utils/toast'
import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material'
import { DateCalendar } from '@mui/x-date-pickers'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const HolidaysModule = ({ entity, updateFunction, directionOfAnimation }) => {
  const dispatch = useDispatch()
  const { socket } = useSocket()
  const { defaultCentre } = useUser()

  const [startSelectedDate, setStartSelectedDate] = useState(dayjs(new Date()).format())
  const [finishSelectedDate, setFinishSelectedDate] = useState(startSelectedDate)
  const [openPopUp, setOpenPopUp] = useState(false)
  const [checkboxAllDay, setCheckboxAllDay] = useState(true)
  const [dataToUpdate, setDataToUpdate] = useState([])
  const [dataStartDate, setDataStartDate] = useState('')
  const [dataFinishDate, setDataFinishDate] = useState('')
  const [auxEntity, setAuxEntity] = useState(JSON.parse(JSON.stringify(entity)))
  const [filterActiveTurns, setFilterActiveTurns] = useState([])

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      startDate: dayjs(new Date()),
      finishDate: dayjs(new Date()),
      startTime: dayjs().format('HH:00'),
      finishTime: dayjs().format('HH:00'),
      description: '',
    },
  })

  useEffect(() => {
    setAuxEntity(JSON.parse(JSON.stringify(entity)))
  }, [entity])

  const watchAllDay = watch('allDay')

  const onSubmit = async (data, e) => {
    if (!e) return
    e.preventDefault()

    if (data.allDay) {
      data.startDate = dayjs(startSelectedDate)
        .tz('America/Argentina/Buenos_Aires')
        .hour(0)
        .minute(0)
        .second(0)
        .format()
      data.finishDate = dayjs(finishSelectedDate)
        .tz('America/Argentina/Buenos_Aires')
        .hour(23)
        .minute(59)
        .second(0)
        .format()
      delete data.allDay
      delete data.startTime
      delete data.finishTime
    } else {
      let auxStartTime = formatDaySelected(data.startTime)
      let auxFinishTime = formatDaySelected(data.finishTime)

      data.startDate = auxStartTime
      data.finishDate = auxFinishTime

      delete data.allDay
      delete data.startTime
      delete data.finishTime
    }

    if (!data.description) {
      delete data.description
    }

    let isValid = isNoWorkTimeValid(data, auxEntity?.noWorkTimes)

    if (!isValid) {
      showToast('Fecha de bloqueo no válida', 'error')
      return
    }

    const auxNoWorkTimes = [...auxEntity?.noWorkTimes, data]

    let auxDataToUpdate = {
      ...auxEntity,
      noWorkTimes: auxNoWorkTimes,
    }

    try {
      if (auxEntity) {
        let dataToGetTurns = {
          centre: defaultCentre,
          start: dayjs(data?.startDate).utc(true).second(0).toISOString(),
          finish: dayjs(data?.finishDate).utc(true).second(0).subtract(1, 'minute').toISOString(),
          doctorCentre: auxEntity?.lastname ? auxEntity?._id : null,
          machine: auxEntity?.name ? auxEntity?._id : null,
        }

        let fetchTurnsForSpecificDate = await fetchTurns(dataToGetTurns)
        let filterActiveTurns = fetchTurnsForSpecificDate?.filter((turn) => turn?.state !== 'cancelled')
        setFilterActiveTurns(filterActiveTurns)

        setDataToUpdate(auxDataToUpdate)
        setDataStartDate(data.startDate)
        setDataFinishDate(data.finishDate)
        setOpenPopUp(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteNoWorkTime = async (noWorkTime) => {
    let workTimeDelete = auxEntity?.noWorkTimes?.filter((h) => h._id !== noWorkTime._id)
    let auxDataToUpdate = {
      ...auxEntity,
      noWorkTimes: workTimeDelete,
    }

    try {
      const updatedEntity = await dispatch(updateFunction(auxDataToUpdate))
      socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedEntity)
    } catch (error) {
      console.log(error)
    }
  }

  const formatDaySelected = (time) => {
    let daySelected = dayjs(startSelectedDate).format('YYYY-MM-DD')
    let dateSelected = formatTime(time, false, true)
    let currentDate = dayjs(dateSelected).format('YYYY-MM-DD')
    let replaceDate = dateSelected.replace(currentDate, daySelected)
    return replaceDate
  }

  const addPropWhenClick = (i) => {
    let auxData = { ...auxEntity }
    auxData.noWorkTimes[i].showConfirm = true
    setAuxEntity(auxData)
  }

  const removePropWhenClick = (i) => {
    let auxData = { ...auxEntity }
    auxData.noWorkTimes[i].showConfirm = false
    setAuxEntity(auxData)
  }

  const handleSetStartDate = (newDate) => {
    setStartSelectedDate(dayjs(newDate).format())
    setFinishSelectedDate(dayjs(newDate).format())
  }

  const renderWeekPickerDay = (props) => {
    const { day, selectedDay, ...other } = props

    const dayIsBetween = day.isBetween(startSelectedDate, finishSelectedDate, 'day', '[]')
    const isFirstDay = day.isSame(startSelectedDate, 'day')
    const isLastDay = day.isSame(finishSelectedDate, 'day')

    return (
      <DateRangeDinamicStyles
        {...other}
        day={day}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    )
  }

  useEffect(() => {
    if (!watchAllDay) {
      setFinishSelectedDate(startSelectedDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllDay])

  return (
    <div className={directionOfAnimation}>
      <form className={s.containerForm} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {/* parte izquierda */}
          <Grid item xs={12} sm={10}>
            <h4>Cargar nuevo bloqueo de calendario</h4>
            <Grid item xs={12} sx={{ marginBottom: '10px' }}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    type="text"
                    label="Descripción / motivo"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </Grid>
            {/* contenedor date pickers */}
            <Grid item xs={12} container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={12} md={6}>
                {checkboxAllDay ? (
                  <p className={s.titlePicker}>
                    Desde <span>(Hoy)</span>
                  </p>
                ) : (
                  <p className={s.titlePicker}>
                    Día <span>(Hoy)</span>
                  </p>
                )}
                <div>
                  <DateCalendar
                    value={dayjs(startSelectedDate)}
                    onChange={handleSetStartDate}
                    slots={{ day: renderWeekPickerDay }}
                  />
                </div>
              </Grid>

              {checkboxAllDay ? (
                <Grid item xs={12} md={5}>
                  <p className={s.titlePicker}>
                    Hasta <span>(Hoy)</span>
                  </p>
                  <div>
                    <DateCalendar
                      value={dayjs(finishSelectedDate)}
                      onChange={(newDate) => setFinishSelectedDate(dayjs(newDate).format())}
                      minDate={dayjs(startSelectedDate)}
                      disableHighlightToday={true}
                      slots={{ day: renderWeekPickerDay }}
                    />
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} md={6} container justifyContent="space-between" spacing={3}>
                  <Grid item xs={6}>
                    <div>Desde</div>
                    <div className={s.itemTimeInput}>
                      <Controller
                        name="startTime"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            type="time"
                            defaultValue={dayjs().format('HH:00')}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                            sx={{ width: 100 }}
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>Hasta</div>
                    <div className={s.itemTimeInput}>
                      <Controller
                        name="finishTime"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            type="time"
                            defaultValue={dayjs().format('HH:00')}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                            sx={{ width: 100 }}
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="allDay"
                control={control}
                render={({ field }) => (
                  <>
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={<p className={s.labelCheckbox}>{`Todo el día`}</p>}
                    />
                    <>{field.value ? setCheckboxAllDay(true) : setCheckboxAllDay(false)}</>
                  </>
                )}
                defaultValue={true}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={2} container justifyContent="center" alignItems="center">
            <CaluButton color="primary" size="medium" type="submit">
              Agregar
            </CaluButton>
          </Grid>
        </Grid>
      </form>

      {/* Lista Horarios no laborales */}
      <Grid>
        <div className={s.divider}></div>

        <div className={s.containerListTimes}>
          <div>
            {auxEntity?.noWorkTimes?.length === 0 ? (
              <p className={s.emptyList}>No hay bloqueos de calendario cargados aún.</p>
            ) : (
              auxEntity?.noWorkTimes?.map((time, i) => (
                <div key={time._id} className={s.itemNoWorkTimes}>
                  <div className={s.detailNoWorkTimes}>
                    <p>{capitalizeFirstLetters(time.description) || null}</p>
                    <p className={s.date}>{`${toCustomTz(time?.startDate, undefined, false, 'DD/MM/YY')} ${toCustomTz(
                      time?.startDate,
                      undefined,
                      false,
                      'HH:mm',
                    )} hs - ${toCustomTz(time?.finishDate, undefined, false, 'DD/MM/YY')} ${toCustomTz(
                      time?.finishDate,
                      undefined,
                      false,
                      'HH:mm',
                    )} hs `}</p>
                  </div>
                  {time.showConfirm ? (
                    <div className={s.containerButtonsDelete}>
                      <TextOverButtons title="Si, eliminar">
                        <div className={s.btnsDeleteIcons}>
                          <IoIosCheckmarkCircleOutline onClick={() => deleteNoWorkTime(time)} />
                        </div>
                      </TextOverButtons>
                      <TextOverButtons title="No, cancelar">
                        <div className={s.btnsCancelIcons}>
                          <IoIosCloseCircleOutline onClick={() => removePropWhenClick(i)} />
                        </div>
                      </TextOverButtons>
                    </div>
                  ) : (
                    <TextOverButtons title="Eliminar">
                      <Button onClick={() => addPropWhenClick(i)} className={s.trashIcon}>
                        <BsTrash />
                      </Button>
                    </TextOverButtons>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      </Grid>

      <PopUpConfirmNoWorkTime
        openPopUp={openPopUp}
        setOpenPopUp={setOpenPopUp}
        dataToUpdate={dataToUpdate}
        dataStartDate={dataStartDate}
        dataFinishDate={dataFinishDate}
        updateFunction={updateFunction}
        filterActiveTurns={filterActiveTurns}
        allDay={watchAllDay}
      />
    </div>
  )
}

export default HolidaysModule
