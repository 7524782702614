import { turnStates } from '@/helpers/constants'

export const getTurnStateName = (turnStateValue) => {
  let stateName = null
  turnStates?.forEach((turn) => {
    if (turn.state === turnStateValue) {
      stateName = turn.name
    }
  })
  return stateName
}
