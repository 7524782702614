import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { useSocket } from '@/context/SocketProvider'
import useValidateURLId from '@/hooks/useValidateURLId'
import { deleteTreatmentDebt } from '@/store/debts/actions'
import { removePatientTreatment, updatePatientTreatment } from '@/store/treatments/actions'
import { updatePatienCentreTurn } from '@/store/turns/actions'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { Button, Grid } from '@mui/material'

import AddNewSessionButton from '../../../addNewPatientSession/addNewSessionButton'
import SendDetailTreatmentByEmail from '../../../sendDetailTreatmentByEmail'
import TreatmentItemDetail from '../../../treatmentItemDetail'

import s from './styles.module.scss'

export const FormContent = ({ treatment, setOpenPopUp, setTreatmentSection, handleClose, setLoading }) => {
  const dispatch = useDispatch()
  const { socket } = useSocket()
  const [openAlertDelete, setOpenAlertDelete] = useState(false)
  const [alertFinishTreatment, setAlertFinishTreatment] = useState(false)
  const turns = treatment?.turns?.filter((turn) => turn?.state !== 'cancelled')?.length
  const turnsCount = treatment?.turnsCount
  const centreId = useValidateURLId()
  const [isPaidTreatment, setIsPaidTreatment] = useState(false)

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { allTurns } = useSelector((state) => state.turns)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const changePaymentTreatment = () => {
    const updateTreatment = {
      ...treatment,
      isPaid: true,
      paidPrice: treatment.price,
    }
    dispatch(deleteTreatmentDebt(updateTreatment))
  }

  const activePatientTreatment = () => {
    const updateTreatment = {
      ...treatment,
      state: 'init',
    }
    dispatch(updatePatientTreatment(updateTreatment))
  }

  const undoTreatmentPayment = () => {
    const updateTreatment = {
      ...treatment,
      isPaid: false,
    }
    dispatch(updatePatientTreatment(updateTreatment))
  }
  const deleteTreatment = () => {
    dispatch(removePatientTreatment(treatment._id))
    const filteredTurns = allTurns.filter((turno) => treatment.turns.some((t) => t.id === turno.id))
    if (!filteredTurns?.length) return
    filteredTurns?.map((t) => {
      const auxTurn = {
        ...t,
        state: 'cancelled',
        treatment: null,
      }
      socket.emit('calendar_edit_turn', `${centreId}_calendar`, auxTurn)
      return auxTurn
    })
  }

  const finishTreatmentButton = async () => {
    const updateTreatment1 = treatment?.turns?.map(async (turn) => {
      if (turn.state !== 'cancelled') {
        const hideAlert = true
        const auxTurn = {
          ...turn,
          state: 'cancelled',
        }
        return await dispatch(updatePatienCentreTurn(auxTurn, hideAlert))
      }
      return turn
    })

    Promise.all(updateTreatment1)
      .then(async (response) => {
        const updateTreatment = {
          ...treatment,
          turns: response,
          state: 'finished',
        }
        dispatch(updatePatientTreatment(updateTreatment))
      })
      .catch((error) => console.log(error))
  }

  const showButtons = () => {
    const turns = treatment.turns.map((e) => e).length
    const turnsCount = treatment.turnsCount

    if (treatment.isPaid === false) {
      if (treatment.state === 'init') {
        return (
          <>
            <Button
              size="small"
              className={s.finishButton}
              variant="contained"
              disableElevation
              onClick={() => setAlertFinishTreatment(true)}
            >
              Finalizar
            </Button>

            <Button
              size="small"
              className={s.isPaidButton}
              variant="outlined"
              disableElevation
              onClick={() => setIsPaidTreatment(true)}
            >
              Todo está pago
            </Button>
          </>
        )
      }

      if (treatment.state === 'finished' && turns < turnsCount) {
        return (
          <Button className={s.activeButton} variant="contained" disableElevation onClick={activePatientTreatment}>
            ACTIVAR
          </Button>
        )
      }
    } else {
      return (
        <div className={s.isPaidMessage}>
          Marcaste este grupo de sesiones como pago.{' '}
          <span className={s.undoButton} onClick={undoTreatmentPayment}>
            Deshacer
          </span>
          .
        </div>
      )
    }
  }

  const showTreatmentMessage = () => {
    let turns = treatment?.turns?.filter((turn) => turn?.state !== 'cancelled')?.length
    let turnsCount = treatment.turnsCount

    if (treatment.state === 'init' && turns < turnsCount && turns === 0)
      return 'Este grupo de sesiones no tiene sesiones asignadas aún.'
    if (treatment.state === 'init' && turns === turnsCount)
      return 'Todas las sesiones de este grupo de sesiones ya están asignadas'
    if (treatment.state === 'finished' && turns < turnsCount)
      return 'No se pueden agregar nuevas sesiones porque este grupo de sesiones está finalizado.'
  }

  const sortTurns = () => {
    const turns = treatment?.turns?.map((turn) => turn)
    return turns?.reverse()
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <CustomField label="Nombre del grupo de sesiones*" name="name" fullWidth />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CustomField label="Cantidad de sesiones" name="turnsCount" fullWidth />
      </Grid>
      <Grid item xs={12} sm={4}>
        <CustomField label={`${handleSetMoneySign(country)} Monto a pagar`} name="price" fullWidth type="number" />
      </Grid>
      <Grid item xs={12}>
        <CustomField label="Descripción" name="description" fullWidth multiline maxRows={15} />
      </Grid>

      <Grid container md={12} xs={12} className={s.mainButtonsContainer}>
        <Grid item xs={12} md={6} className={s.divFlex}>
          {showButtons()}
        </Grid>
        <Grid item xs={12} md={6} className={s.buttonsContainer1}>
          <Button
            className={s.deleteButton}
            variant="contained"
            disableElevation
            onClick={() => setOpenAlertDelete(true)}
            size="small"
          >
            Eliminar
          </Button>

          <Button type="submit" className={s.updateButton} variant="contained" disableElevation size="small">
            Actualizar
          </Button>
        </Grid>
      </Grid>

      {treatment?.state === 'init' && turns < turnsCount && (
        <AddNewSessionButton
          centre={centreDetail}
          treatment={treatment}
          setOpenPopUp={setOpenPopUp}
          setTreatmentSection={setTreatmentSection}
        />
      )}

      <p className={s.treatmentMessage}>{showTreatmentMessage()}</p>

      {sortTurns()?.map((turn, index) => (
        <TreatmentItemDetail
          turn={turn}
          treatment={treatment}
          index={index}
          key={turn._id}
          handleClose={handleClose}
          setLoading={setLoading}
        />
      ))}

      <hr className={s.hr} />

      {treatment.state === 'init' && <SendDetailTreatmentByEmail treatment={treatment} centre={centreDetail} />}

      <AlertDialogSlide
        open={openAlertDelete}
        setOpen={setOpenAlertDelete}
        title="Eliminar grupo de sesiones"
        textContent={
          '¿Realmente quiere eliminar el grupo de sesiones? Esta acción no se podrá restaurar. Las sesiones pendientes serán canceladas.'
        }
        textBackButton="Cancelar"
        textConfirmationButton={'Eliminar'}
        onClose={deleteTreatment}
      />
      <AlertDialogSlide
        title="Finalizar grupo de sesiones"
        open={alertFinishTreatment}
        setOpen={setAlertFinishTreatment}
        textContent={
          '¿Realmente quiere finalizar este grupo de sesiones? No podrás agregar más sesiones. Las sesiones pendientes se marcarán como canceladas.'
        }
        textBackButton="Cancelar"
        textConfirmationButton={'Finalizar'}
        onClose={finishTreatmentButton}
      />
      <AlertDialogSlide
        title="Marcar grupo de sesiones como pago"
        open={isPaidTreatment}
        setOpen={setIsPaidTreatment}
        textContent={'Al marcar como pago aparecerá que se pagó el total del importe del grupo de sesiones.'}
        textBackButton="Cancelar"
        textConfirmationButton={'Confirmar'}
        onClose={changePaymentTreatment}
      />
    </Grid>
  )
}
