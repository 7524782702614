import React, { useState } from 'react'

import dayjs from 'dayjs'
import PopUpConfirmTurn from '../popUpConfirmTurn'
import PopUpMoreTurnsAvailables from '../PopUpMoreTurnsAvailables'

import s from './styles.module.scss'

const AvailableUndatedTurn = ({ entityAvailability, singleTurn, setShowConfirmedTurn, entity }) => {
  const [open, setOpen] = useState(false)
  const [openPopUpToConfirmTurn, setOpenPopUpToConfirmTurn] = useState(false)
  const [entityAvailabilityPopUp, setEntityAvailabilityPopUp] = useState([])
  const [daySelected, setDaySelected] = useState('')
  const [infoTurn, setInfoTurn] = useState(null)
  let availableDays = entityAvailability?.availableDays

  const turnsPerDay = (day, entityAvailability) => {
    for (const key in entityAvailability) {
      if (key === day) {
        return entityAvailability[key]
      }
    }
  }

  const formatDate = (date) => {
    const dayNumber = dayjs(date).format('D')
    const dayName = dayjs(date).format('ddd').toUpperCase()
    const month = dayjs(date).format('MMM').toUpperCase()
    return `${dayName} ${dayNumber} ${month}.`
  }

  const handleOpenPopUp = (availabilityPerDay, day) => {
    setOpen(!open)
    setEntityAvailabilityPopUp(availabilityPerDay)
    setDaySelected(day)
  }

  const handleConfirmTurn = (day, elem) => {
    const date = dayjs(day).utc().format('YYYY-MM-DD')
    let hourAndMinuteTurn = elem.startHour.split(':')
    const startHour = dayjs(date)
      .utc()
      .hour(hourAndMinuteTurn[0], 'hour')
      .minute(hourAndMinuteTurn[1], 'minute')
      .format()
    const finishHour = dayjs(startHour).utc().add(elem.turnDuration, 'm').format()
    const objectDate = {
      date: dayjs(date).utc().format('dddd D MMMM'),
      hourTurn: elem.startHour,
      startHour: startHour,
      finishHour: finishHour,
      turnDuration: elem.turnDuration,
    }
    setOpenPopUpToConfirmTurn(true)
    setInfoTurn(objectDate)
  }

  return (
    <div className={s.mainContainer}>
      <div className={s.containerTurns}>
        {availableDays?.map((day) => (
          <div className={s.listTurnsDay} key={day}>
            <p className={s.turnsDay}>{formatDate(day)}</p>
            <span className={s.detailTurns}>
              {turnsPerDay(day, entityAvailability).length > 3 ? (
                <div>
                  {turnsPerDay(day, entityAvailability)
                    .slice(0, 3)
                    .map((elem, i) => (
                      <div className={s.itemTurn} onClick={() => handleConfirmTurn(day, elem)} key={i}>
                        <span>{elem.startHour} hs</span>
                      </div>
                    ))}
                  <div className={s.itemTurn}>
                    <span onClick={() => handleOpenPopUp(turnsPerDay(day, entityAvailability), day)}>
                      <b>{`+ ${turnsPerDay(day, entityAvailability).length - 3} más`}</b>
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  {turnsPerDay(day, entityAvailability).map((elem, i) => (
                    <div className={s.itemTurn} onClick={() => handleConfirmTurn(day, elem)} key={i}>
                      <span>{elem.startHour} hs</span>
                    </div>
                  ))}
                </div>
              )}
            </span>
          </div>
        ))}
      </div>

      <PopUpMoreTurnsAvailables
        open={open}
        setOpen={setOpen}
        availabilityPerDay={entityAvailabilityPopUp}
        daySelected={daySelected}
        entity={entity}
        handleConfirmTurn={handleConfirmTurn}
      />

      {infoTurn && (
        <PopUpConfirmTurn
          open={openPopUpToConfirmTurn}
          setOpen={setOpenPopUpToConfirmTurn}
          infoTurn={infoTurn}
          singleTurn={singleTurn}
          setShowConfirmedTurn={setShowConfirmedTurn}
        />
      )}
    </div>
  )
}

export default AvailableUndatedTurn
