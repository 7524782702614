import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PageHearedTitle } from '@/components/pages'
import { getAllTemplatesHCField } from '@/controllers/clinicHistoryField'
import BaseSideBar from '@/layouts/Layout1/components/BaseSideBar'
import ContentSidebar from '@/pages/AdminPanelHCFields/components/contentSideBar'
import { deleteFieldHCAdded, resetSelectedFieldHC } from '@/store/clinicHistoryField/actions'
import { getListFieldsHC } from '@/store/clinicHistoryField/actions'
import { Paper } from '@mui/material'

import CardInfo from './components/cardInfo'
import PopUpAdminFieldsHC from './components/popUpAdminFieldsHC'
import Layout1 from '../../layouts/Layout1/index'

import s from './styles.module.scss'

const AdminPanelHCFields = () => {
  const dispatch = useDispatch()

  const [openFieldsHCPopUp, setOpenFieldsHCPopUp] = useState(false)
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [openAlertDelete, setOpenAlertDelete] = useState(false)

  const [fieldsHCFiltered, setFieldsHCFiltered] = useState([])

  const { allFieldsHC, selectedHCField } = useSelector((state) => state.fieldsHC)

  const setInit = async (params) => {
    setIsLoading(true)
    const AllTemplatesHCFields = await getAllTemplatesHCField()
    dispatch(getListFieldsHC(AllTemplatesHCFields))

    setIsLoading(false)
  }

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filteredHCFields = allFieldsHC?.filter((field) =>
      field.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .includes(
          input
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase(),
        ),
    )
    setFieldsHCFiltered(filteredHCFields)
  }, [allFieldsHC, input])

  const addNewFieldHC = () => {
    dispatch(resetSelectedFieldHC())
    setOpenFieldsHCPopUp(!openFieldsHCPopUp)
  }

  const modifyFieldHC = () => {
    setOpenFieldsHCPopUp(!openFieldsHCPopUp)
  }

  const deleteFieldHC = () => {
    dispatch(deleteFieldHCAdded(selectedHCField?._id))
    setOpenAlertDelete(false)
  }

  const handleChange = (e) => {
    setInput(e.target.value)
  }

  return (
    <Layout1
      navbar
      isLoading={isLoading}
      leftSidebar={
        <BaseSideBar
          inputLabel={'Buscar campos'}
          inputValue={input}
          inputOnChange={handleChange}
          buttonLabel={'Nuevo campo de HC'}
          buttonOnClick={addNewFieldHC}
          content={<ContentSidebar listItem={fieldsHCFiltered} />}
          panelAdmin={true}
        />
      }
    >
      <div className={s.mainContainer}>
        <div className={s.contentContainer}>
          <PageHearedTitle titleText="Campos de HC" />
          {selectedHCField !== null ? (
            <CardInfo
              modifyItem={modifyFieldHC}
              openAlertDelete={openAlertDelete}
              setOpenAlertDelete={setOpenAlertDelete}
              deleteItem={deleteFieldHC}
            />
          ) : (
            <Paper elevation={3} className={`${s.cardContainer} animate__animated animate__fadeIn`}>
              <p>Seleccione un campo de HC del listado para ver su información</p>
            </Paper>
          )}
        </div>
      </div>

      {openFieldsHCPopUp && <PopUpAdminFieldsHC open={openFieldsHCPopUp} setOpen={setOpenFieldsHCPopUp} />}
    </Layout1>
  )
}

export default AdminPanelHCFields
