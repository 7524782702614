import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSaveButton from '@/components/LoadingSaveButton'
import { useSocket } from '@/context/SocketProvider'
import { deviceDetector } from '@/helpers/deviceDetector'
import RescheduleTurn from '@/pages/RescheduleTurn'
import { Button, Grid, Menu, MenuItem } from '@mui/material'

import { menuItems } from './menuItems'

import s from './styles.module.scss'

const DialogButtonsAction = ({ activeButtonForm, tabValue, showAllForm, setShowAllForm, handleClose, isLoading }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const { socket } = useSocket()
  let isMobile = deviceDetector()

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElActions, setAnchorElActions] = useState(null)
  const [openRescheduleTurn, setOpenRescheduleTurn] = useState(null)

  const centre = useSelector((state) => state.centres.centreProfile)
  const { status: wappStatus } = useSelector((state) => state.wapp)
  const { selectedTurn } = useSelector((state) => state.turns)

  const open = Boolean(anchorEl)
  const openActions = Boolean(anchorElActions)

  const auxData = {
    selectedTurn: {
      ...selectedTurn,
      centre: centre,
    },
    dispatch,
    socket,
    handleClose: handleClose,
    wappStatus: wappStatus,
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const onClose = () => {
    setAnchorEl(null)
    if (isMobile) {
      handleCloseActions()
    }
  }

  const handleClickActions = (event) => {
    setAnchorElActions(event.currentTarget)
  }
  const handleCloseActions = () => {
    setAnchorElActions(null)
  }

  const changeBackgroundColor = (event, color) => {
    event.target.style.background = color
    event.target.style.color = 'white'
  }

  const recoverBackgroundColor = (event) => {
    event.target.style.background = 'white'
    event.target.style.color = 'black'
  }

  const showChangeStateMenu = () => {
    return (
      <>
        <Button
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className={!isMobile ? s.buttonMore : s.menuButton}
          size="small"
        >
          Estado
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {menuItems(auxData)
            ?.filter((item) => item.visibleState.includes(selectedTurn.state))
            .map((element, index) => (
              <MenuItem
                key={index}
                onClick={element.function}
                style={{ display: 'flex', justifyContent: 'space-between' }}
                onMouseOver={(event) => changeBackgroundColor(event, element.color)}
                onMouseLeave={(event) => recoverBackgroundColor(event)}
              >
                {element.content}
              </MenuItem>
            ))}
        </Menu>
      </>
    )
  }

  const showAcctionsMenu = () => {
    return (
      <>
        <Button
          id="basic-button"
          aria-controls={openActions ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openActions ? 'true' : undefined}
          onClick={handleClickActions}
          className={s.buttonMore}
        >
          Acciones
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorElActions}
          open={openActions}
          onClose={handleCloseActions}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem>{showChangeStateMenu()}</MenuItem>
          <MenuItem>{rescheduleTurn()}</MenuItem>
        </Menu>
      </>
    )
  }

  const rescheduleTurn = () => {
    return (
      <Button
        onClick={() => {
          setOpenRescheduleTurn(true)
          handleCloseActions()
        }}
        className={s.menuButton}
      >
        {`Reprogramar ${t('turnLabel.s')}`}
      </Button>
    )
  }

  return (
    <>
      {selectedTurn?.patientCentre && tabValue === '1' ? (
        <Grid container className={s.containerButtons1}>
          <Grid item>{!isMobile ? showChangeStateMenu() : showAcctionsMenu()}</Grid>
          <Grid item className={s.gridItem2}>
            {!showAllForm && (
              <Button
                disabled={isLoading}
                variant="contained"
                className={isLoading ? s.disabledButton : s.buttonMore}
                onClick={() => setShowAllForm(true)}
                size="small"
              >
                Ver más
              </Button>
            )}
            <LoadingSaveButton
              isLoading={isLoading}
              textButton={`Guardar ${t('turnLabel.s')}`}
              onClick={activeButtonForm}
              size="small"
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container className={s.containerButton2}>
          <Button disabled className={s.disabledButton}>{`Guardar ${t('turnLabel.s')}`}</Button>
        </Grid>
      )}
      {openRescheduleTurn && (
        <RescheduleTurn
          open={openRescheduleTurn}
          setOpen={setOpenRescheduleTurn}
          turnInfo={selectedTurn}
          onClose={handleClose}
        />
      )}
    </>
  )
}

export default DialogButtonsAction
