import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import TurnsHistory from '@/components/TurnsHistory'
import useValidateURLId from '@/hooks/useValidateURLId'
import { clearPatientCentres, getPatientsCentreByInputSearch } from '@/store/patientCentres/actions'
import { cleanPatientCentreTurns, getPatientCentreTurns } from '@/store/turns/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { Autocomplete, TextField } from '@mui/material'

import s from './styles.module.scss'

const FormConsultTurns = ({ handleClose }) => {
  const centreID = useValidateURLId()
  const { t } = useTranslation('global')
  const dispatch = useDispatch()
  const { handleSubmit } = useForm()

  const [searchText, setSearchText] = useState('')
  const [patientValue, setPatientValue] = useState(null)
  const [searchingPatients, setSearchingPatients] = useState(false)

  const patientCentres = useSelector((state) => state.patientCentres.patientCentres)
  const patientCentresTurns = useSelector((state) => state.turns.patientCentresTurns)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const handleChange = (e) => {
    setSearchText(e.target.value)
  }

  useEffect(() => {
    const searchPatient = async () => {
      setPatientValue(null)
      if (searchText && searchText.length >= 2) {
        const params = {
          centre: centreID,
          search: searchText,
        }
        setSearchingPatients(true)
        await dispatch(getPatientsCentreByInputSearch(params))
        setSearchingPatients(false)
      } else {
        dispatch(clearPatientCentres())
        dispatch(cleanPatientCentreTurns())
      }
    }
    searchPatient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const onSubmit = async (data, e) => {
    e.preventDefault()
    handleClose()
  }

  const valueOfPatient = (e, dataPatient) => {
    setPatientValue(dataPatient)
  }

  useEffect(() => {
    if (patientValue) {
      dispatch(getPatientCentreTurns(centreID, patientValue?._id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientValue])

  return (
    <div>
      <div className={s.containerForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Autocomplete
            id="search"
            freeSolo
            disableClearable
            onChange={valueOfPatient}
            options={patientCentres?.map((option) => option)}
            getOptionLabel={(option) =>
              option?.dni?.length < 15
                ? `${option?.lastname?.toUpperCase()}, ${option?.firstname?.toUpperCase()} (DNI: ${option?.dni})`
                : `${option?.lastname?.toUpperCase()}, ${option?.firstname?.toUpperCase()}`
            }
            isOptionEqualToValue={(option) => option._id}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus={true}
                label={`Buscar ${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}`}
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
                value={searchText}
                onChange={handleChange}
              />
            )}
          />
        </form>
      </div>

      {searchingPatients ? (
        <LoaderCircle size={40} />
      ) : patientValue !== null ? (
        <TurnsHistory patientCentresTurns={patientCentresTurns} patientCentre={patientValue} />
      ) : (
        <p className={s.infoSearch}>
          Busque un {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)} para ver su
          historial de {t('turnLabel.p')}.
        </p>
      )}
    </div>
  )
}

export default FormConsultTurns
