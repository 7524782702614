import i18next from '@/configs/i18next'
import { addNewCashboxItem, deleteCashboxItem, putCashboxItem } from '@/services/cashBoxItems'
import { deleteTurnById, putAturn } from '@/services/turns'
import {
  CASH_LOADING,
  CASHBOX_ITEM_COMBINED_CREATED,
  CASHBOX_ITEM_COMBINED_UPDATED,
  CASHBOX_ITEM_DELETE,
  CASHBOX_ITEM_SELECTED,
  FETCH_CASHBOX_ENTRIES,
  FETCH_CASHBOX_ITEMS_COMBINED,
  FETCH_CASHBOX_ITEMS_COMBINED_TOTALS,
  LAST_PAYMENT_SEARCH,
  RESET_CASHBOX_ITEM_SELECTED,
} from '@/store/cashboxItems'
import { showToast } from '@/utils/toast'

const getAllCashboxItemsCombined = (fetchAllCashboxItems) => async (dispatch) => {
  dispatch(FETCH_CASHBOX_ITEMS_COMBINED(fetchAllCashboxItems))
}

const getAllCashboxItemsCombinedTotals = (fetchCashboxItemsTotals) => async (dispatch) => {
  dispatch(FETCH_CASHBOX_ITEMS_COMBINED_TOTALS(fetchCashboxItemsTotals))
}

const getAllCashboxEntries = (allEntries) => async (dispatch) => {
  dispatch(FETCH_CASHBOX_ENTRIES(allEntries))
}

const postNewCashboxItem = (data) => async (dispatch) => {
  const auxPatientCentre = data.patientCentre
  const auxCentreProvider = data.centreProvider
  try {
    let newCashBoxItem = await addNewCashboxItem(data)
    newCashBoxItem = {
      ...newCashBoxItem,
      docType: 'cashboxItem',
      patientCentre: data.patientCentre && auxPatientCentre,
      centreProvider: data.centreProvider && auxCentreProvider,
    }
    dispatch(CASHBOX_ITEM_COMBINED_CREATED(newCashBoxItem))
    if (newCashBoxItem) {
      showToast('Entrada creada correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const deleteCashboxItemAdded = (formValue) => async (dispatch) => {
  const deletedItem = formValue?.category === 'other' ? 'Entrada' : `${i18next.t('turnLabel.S', { ns: 'global' })}`
  try {
    if (formValue?.category === 'other') {
      await deleteCashboxItem(formValue._id)
    } else {
      await deleteTurnById(formValue._id)
    }
    dispatch(CASHBOX_ITEM_DELETE(formValue._id))
    showToast(`${deletedItem} eliminada correctamente`, 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateCashboxItem = (data, hideAlert) => async (dispatch) => {
  const auxCentreProvider = data.centreProvider
  const auxPatientCentre = data.patientCentre
  try {
    let cashboxItemUpdated = await putCashboxItem(data)
    cashboxItemUpdated = {
      ...cashboxItemUpdated,
      docType: 'cashboxItem',
      patientCentre: data.patientCentre && auxPatientCentre,
      centreProvider: data.centreProvider && auxCentreProvider,
    }

    dispatch(CASHBOX_ITEM_COMBINED_UPDATED(cashboxItemUpdated))

    if (cashboxItemUpdated && !hideAlert) {
      showToast('Entrada actualizada correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateTurnCashboxItem = (data, hideAlert) => async (dispatch) => {
  const auxCentreProvider = data.centreProvider
  const auxPatientCentre = data.patientCentre
  const auxDoctorCentre = data.doctorCentre
  const auxTreatment = data.treatment
  const auxService = data?.service

  try {
    let turnUpdated = await putAturn(data)
    turnUpdated = {
      ...turnUpdated,
      docType: 'turn',
      patientCentre: data.patientCentre && auxPatientCentre,
      centreProvider: data.centreProvider && auxCentreProvider,
      doctorCentre: data.doctorCentre && auxDoctorCentre,
      treatment: data.treatment && auxTreatment,
      service: auxService,
    }

    dispatch(CASHBOX_ITEM_COMBINED_UPDATED(turnUpdated))

    if (turnUpdated && !hideAlert) {
      showToast('Entrada actualizada correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const cashLoadingAction = (boolean) => async (dispatch) => {
  try {
    dispatch(CASH_LOADING(boolean))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const setLastPaymentSearch = (data) => async (dispatch) => {
  try {
    dispatch(LAST_PAYMENT_SEARCH(data))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedCashboxItem = (data) => async (dispatch) => {
  try {
    dispatch(CASHBOX_ITEM_SELECTED(data))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedCashboxItem = () => async (dispatch) => {
  try {
    dispatch(RESET_CASHBOX_ITEM_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export {
  cashLoadingAction,
  deleteCashboxItemAdded,
  getAllCashboxEntries,
  getAllCashboxItemsCombined,
  getAllCashboxItemsCombinedTotals,
  postNewCashboxItem,
  resetSelectedCashboxItem,
  selectedCashboxItem,
  setLastPaymentSearch,
  updateCashboxItem,
  updateTurnCashboxItem,
}
