import React from 'react'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

const SelectWithTextField = ({
  isLoading,
  inputSearch,
  setInputSearch,
  inputLabel,
  valueSelect,
  onChange,
  firstOptionValue,
  firstOptionText,
  options = [],
  defaultName,
  className,
  variant,
}) => {
  const stopImmediatePropagation = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  const renderItems = () => {
    if (options.length > 0) {
      return options?.map((elem, index) => (
        <MenuItem key={elem._id || index} value={elem._id}>
          {elem?.lastname
            ? `${capitalizeFirstLetters(elem?.lastname)}, ${capitalizeFirstLetters(elem?.firstname)}`
            : capitalizeFirstLetters(elem?.name) || `${defaultName} ${index + 1}`}
        </MenuItem>
      ))
    } else {
      return <div className={s.noItems}>No se encontraron resultados.</div>
    }
  }

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel id="demo-multiple-name-label">{inputLabel}</InputLabel>
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        label={inputLabel}
        value={valueSelect || ''}
        onChange={onChange}
        MenuProps={MenuProps}
        className={className || ''}
        variant={variant || 'standard'}
      >
        <div className={s.searchBarContainer}>
          <TextField
            onClickCapture={stopImmediatePropagation}
            onKeyDown={(e) => e.stopPropagation()}
            className={s.searchInput}
            id="search"
            size="small"
            autoComplete="off"
            label="Buscar..."
            variant="standard"
            fullWidth={true}
            type="text"
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
            onFocus={() => setInputSearch('')}
            autoFocus={true}
            InputProps={{
              endAdornment: isLoading ? <CircularProgress className={s.circularProgress} /> : null,
            }}
          />
        </div>

        {firstOptionValue && firstOptionText && <MenuItem value={firstOptionValue}>{firstOptionText}</MenuItem>}

        {renderItems()}
      </Select>
    </FormControl>
  )
}

export default SelectWithTextField

SelectWithTextField.propTypes = {
  inputSearch: PropTypes.string.isRequired,
  setInputSearch: PropTypes.func.isRequired,
  inputLabel: PropTypes.string.isRequired,
  valueSelect: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  firstOptionValue: PropTypes.string || null,
  firstOptionText: PropTypes.string || null,
  options: PropTypes.array.isRequired,
  defaultName: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
}

SelectWithTextField.defaultProps = {
  defaultName: `${'Tabla'.toUpperCase()}`,
}
