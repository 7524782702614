import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { createWappMessageTemplate, updateWappMessageTemplate } from '@/store/wappMessageTemplates/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const FormCreateBirthdayTemplate = ({ handleClose }) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const { selectedTemplate } = useSelector((state) => state.wappMessageTemplates)
  const { usersProfileConnected } = useSelector((state) => state.users)

  const { entityInLocalStorage } = usersProfileConnected
  const deviceId = entityInLocalStorage?._id

  const handleSubmit = async (data) => {
    setIsLoading(true)
    try {
      let auxTemplateData = {
        name: data.name,
        centre: deviceId,
        body: data.body,
      }
      if (selectedTemplate) {
        auxTemplateData = {
          ...selectedTemplate,
          body: data.body,
        }
        dispatch(updateWappMessageTemplate(auxTemplateData))
      } else {
        dispatch(createWappMessageTemplate(auxTemplateData))
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
      handleClose()
    }
  }

  return (
    <div>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={selectedTemplate || formDefaultValues}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent handleClose={handleClose} submitLoading={isLoading} />
      </ContainerForm>
    </div>
  )
}

export default FormCreateBirthdayTemplate
