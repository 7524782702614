import { deletePatientTag, getPatientTags, postNewPatientTag, putPatientTag } from '@/services/patientTags'
import {
  GET_PATIENT_TAGS,
  POST_NEW_PATIENT_TAG,
  REMOVE_PATIENT_TAG,
  UPDATE_PATIENT_TAG,
} from '@/store/patientTags/index.js'
import { showToast } from '@/utils/toast'

const getAllPatientTags = (centreId) => async (dispatch) => {
  try {
    let patientTags = await getPatientTags(centreId)
    dispatch(GET_PATIENT_TAGS(patientTags))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const createNewPatientTag = (patientTag) => async (dispatch) => {
  try {
    let createdPatientTag = await postNewPatientTag(patientTag)
    dispatch(POST_NEW_PATIENT_TAG(createdPatientTag))
    showToast('Etiqueta creada correctamente', 'success', 5000)
    return createdPatientTag
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updatePatientTag = (patientTag) => async (dispatch) => {
  try {
    let updatePatientTag = await putPatientTag(patientTag)
    dispatch(UPDATE_PATIENT_TAG(updatePatientTag))
    showToast('Etiqueta actualizada correctamente', 'success', 5000)
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const removePatientTag = (patientTagId) => async (dispatch) => {
  try {
    await deletePatientTag(patientTagId)
    dispatch(REMOVE_PATIENT_TAG(patientTagId))
    showToast('Etiqueta eliminada correctamente', 'success', 5000)
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}
export { createNewPatientTag, getAllPatientTags, removePatientTag, updatePatientTag }
