import { FormControl, MenuItem } from '@mui/material'

import PropTypes from 'prop-types'
import { SelectStyles } from './styles'

import s from './styles.module.scss'

export const CaluRawSelect = ({ labelText, firstOptionValue, firstOptionText, ...rest }) => {
  return (
    <div>
      {labelText && <div className={s.titleInput}>{labelText}</div>}
      <FormControl fullWidth={rest.fullWidth}>
        <div className={rest.className || s.defaultStyles}>
          <SelectStyles
            name={rest.name}
            sx={{
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': {
                border: '2px solid #0F1333',
                '&:hover fieldset': {
                  borderColor: '#0f1333',
                },
              },
              height: '100%',
              width: '100%',
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 400,
                  width: 300,
                  overflow: 'auto',
                },
              },
            }}
            error={Boolean(rest.error)}
            inputProps={{
              classes: {
                icon: s.iconSelect,
              },
            }}
            {...rest}
          >
            {firstOptionValue && firstOptionText && <MenuItem value={firstOptionValue}>{firstOptionText}</MenuItem>}
            {rest?.items?.map((i) =>
              i?.color ? (
                <MenuItem key={i._id} value={i._id}>
                  <div className={s.containerTagsSelect}>
                    <span
                      key={i._id}
                      className={s.tagsContainerColor}
                      style={{ background: i.color, color: i.color }}
                    ></span>
                    <span className={s.tagName}>{i.name}</span>
                  </div>
                </MenuItem>
              ) : (
                <MenuItem key={i?.value || i._id} value={i?.value || i._id}>
                  {i.name}
                </MenuItem>
              ),
            )}
          </SelectStyles>
        </div>
      </FormControl>
    </div>
  )
}

CaluRawSelect.propTypes = {
  items: PropTypes.array.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  firstOptionValue: PropTypes.string || null,
  firstOptionText: PropTypes.string || null,
  labelText: PropTypes.string,
}

CaluRawSelect.defaultProps = {
  fullWidth: true,
  type: 'text',
  multiple: false,
  disabled: false,
}
