import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import AttachedImageField from '@/components/Form/customFields/AttachedImageField'
import CaluButton from '@/components/styledComponents/CaluButton'
import TitlePopUp from '@/components/TitlePopUp'
import { useUploadFile } from '@/hooks/useUploadFile'
import { updateSubscriptionPayment } from '@/store/subscriptionPayments/actions'
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'

import s from './styles.module.scss'

const AttachedPopUp = ({ open, setOpen, dataVoucher }) => {
  const dispatch = useDispatch()
  const isFirstRender = useRef(true)

  const [attachedFile, setAttachedFile] = useState([...dataVoucher?.raw?.attached])

  const { uploadFile, loadingFile } = useUploadFile()

  const { selectedPayment } = useSelector((state) => state?.subscriptionPayments)

  const handleClose = () => {
    setOpen(false)
  }

  const handleUpdateAttached = async (data) => {
    await dispatch(updateSubscriptionPayment(data))
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      const updateAttached = {
        ...dataVoucher?.raw,
        attached: attachedFile,
      }
      try {
        handleUpdateAttached(updateAttached)
      } catch (error) {
        console.log(error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachedFile])

  const handleDeleteAttached = (i) => {
    let filteredAttached = attachedFile.filter((_, index) => index !== i)
    setAttachedFile(filteredAttached)
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} open={open} maxWidth="md" fullWidth onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle={`Cupón de pago: ${selectedPayment?.period}`} handleClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <Grid container className={s.contentPopUp}>
            {attachedFile?.length === 0 ? (
              <p className={s.itemP}>Aún no tiene comprobantes cargados</p>
            ) : (
              <div className={s.contAttachedFiles}>
                <p className={s.itemP}>Comprobantes que has adjuntado: </p>

                <div className={s.itemAttachedFiles}>
                  {attachedFile?.map((file, index) => (
                    <div key={index} className={s.attachedItem}>
                      <AttachedImageField
                        file={file}
                        onChange={handleDeleteAttached}
                        index={index}
                        hideDeleteButton={false}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            <Grid item xs={12}>
              <div className={s.contButton}>
                <CaluButton
                  color="primary"
                  size="medium"
                  loading={loadingFile}
                  onClick={() => document.getElementById('uploadAttached').click()}
                  disabled={dataVoucher?.raw?.paymentStatus === 'paid'}
                >
                  <input
                    className={s.file}
                    type="file"
                    id="uploadAttached"
                    onChange={(e) => uploadFile(e, attachedFile, setAttachedFile)}
                  ></input>
                  Adjuntar comprobante
                </CaluButton>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AttachedPopUp
