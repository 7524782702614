import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { useSocket } from '@/context/SocketProvider'
import { addExtraDiscount } from '@/helpers/turns/addExtraDiscount'
import { calculateTurnAmountWithoutExtraDiscount } from '@/helpers/turns/calculateTurnAmountWithoutExtraDiscount'
import updateObjectWithCommonKeys from '@/helpers/updateObjectWithCommonKeys'
import useValidateURLId from '@/hooks/useValidateURLId'
import { updatePatientCentreAndSelectedTurn } from '@/store/patientCentres/actions'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const TurnInfoTab = ({ buttonFormRef, showAllForm, handleClose, isLoading, setIsLoading }) => {
  const dispatch = useDispatch()
  const { socket } = useSocket()
  const centreID = useValidateURLId()
  const { selectedTurn } = useSelector((state) => state?.turns)

  const handleSubmit = async (data) => {
    setIsLoading(true)
    let auxData = {
      ...selectedTurn,
      ...data,
    }
    auxData.amountWithoutExtraDiscount = calculateTurnAmountWithoutExtraDiscount(auxData)
    auxData.amount = addExtraDiscount(auxData?.amountWithoutExtraDiscount, auxData?.extraAmount)

    auxData.patientCentre.gender = auxData.patientCentre.gender.value
    auxData.patientCentre.country = auxData.patientCentre.country.value
    auxData.paymentMethod = auxData.paymentMethod.value

    auxData.extraAmount = !auxData?.extraAmount?.value ? null : auxData.extraAmount

    if (auxData?.patientCentre?.dni === '')
      auxData.patientCentre.dni = crypto.randomUUID().replaceAll('-', '').slice(0, 15)
    if (!auxData?.insurance || auxData?.insurance?._id === '-') auxData.insurance = null
    if (auxData?.extraAmount?.value === 0) auxData.extraAmount = []

    if (!selectedTurn?.machine) {
      if (auxData?.service?._id === 'consultation') {
        auxData.firstTime = false
        auxData.service = null
      }
      if (auxData?.service?._id === 'firstConsultation') {
        auxData.firstTime = true
        auxData.service = null
      }
    }

    if (selectedTurn?.machine) {
      if (!auxData?.doctorCentre?._id) {
        auxData.doctorCentre = null
      }
      if (auxData.service._id === null) {
        auxData.service = null
      }
    }

    let insurances = [...selectedTurn?.patientCentre?.insurances]
    let insurancePlan = [...selectedTurn?.patientCentre?.insurancePlan]
    let policyNumbers = [...selectedTurn?.patientCentre?.policyNumbers]

    if (auxData?.insurance && auxData?.insurance?._id !== '-') {
      if (selectedTurn?.patientCentre?.insurances?.length > 0) {
        const insuranceIndex = insurances?.findIndex((insurance) => auxData?.insurance?._id === insurance?._id)
        if (insuranceIndex === -1) {
          insurances.push(auxData?.insurance)
          insurancePlan.push(auxData?.patientCentre?.insurancePlan[0] || '')
          policyNumbers.push(auxData?.patientCentre?.policyNumbers[0] || '')
        } else {
          insurancePlan[insuranceIndex] = data?.patientCentre?.insurancePlan[0]
          policyNumbers[insuranceIndex] = data?.patientCentre?.policyNumbers[0]
        }
      } else {
        insurances.push(auxData?.insurance)
        insurancePlan = [auxData?.patientCentre?.insurancePlan[0]]
        policyNumbers = [auxData?.patientCentre?.policyNumbers[0]]
      }
    }
    auxData.patientCentre.insurances = insurances
    auxData.patientCentre.insurancePlan = insurancePlan
    auxData.patientCentre.policyNumbers = policyNumbers

    try {
      const shouldUpdateMPLink = selectedTurn?.paymentLink && auxData?.debt !== selectedTurn?.paymentLinkAmount
      auxData.shouldUpdateMPLink = shouldUpdateMPLink

      const auxTurn = await dispatch(updatePatientCentreAndSelectedTurn(auxData.patientCentre, auxData))
      if (!auxTurn) {
        setIsLoading(false)
        return
      }
      socket.emit('calendar_edit_turn', `${centreID}_calendar`, auxTurn)
      setIsLoading(false)
      handleClose()
    } catch (e) {
      console.log(e)
      setIsLoading(false)
      handleClose()
    }
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={updateObjectWithCommonKeys(formDefaultValues, selectedTurn)}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent isLoading={isLoading} onClose={handleClose} showAllForm={showAllForm} />
        <button type="submit" ref={buttonFormRef} hidden={true}></button>
      </ContainerForm>
    </Box>
  )
}

export default TurnInfoTab
