import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import LoaderCircle from '@/components/LoaderCircle'
import SelectWithInput from '@/components/SelectWithInput'
import CaluButton from '@/components/styledComponents/CaluButton'
import { useUser } from '@/context/UserProvider'
import { setAllFieldTablesController } from '@/controllers/fieldTables'
import { getAllFieldTables } from '@/store/fieldTables/actions'
import {
  deleteFieldTableAdded,
  postNewFieldTable,
  resetSelectedFieldTable,
  selectedFieldTable as selectedFieldAction,
  updateFieldTable,
} from '@/store/fieldTables/actions'
import { showToast } from '@/utils/toast'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { Autocomplete, Checkbox, Grid, TextField } from '@mui/material'

import Table from './Table'

import s from './styles.module.scss'

const ComparativeTables = ({ patientCentreId, entityId }) => {
  const dispatch = useDispatch()
  const { user } = useUser()

  const icon = <CheckBoxOutlineBlank fontSize="small" />
  const checkedIcon = <CheckBox fontSize="small" />

  const { allFieldTables, selectedFieldTable } = useSelector((state) => state.fieldTables)
  const allClinicHistories = useSelector((state) => state.clinicHistory.allClinicHistories)

  const [isLoading, setIsLoading] = useState(true)
  const [selectedTable, setSelectedTable] = useState('')
  const [selectedFields, setSelectedFields] = useState([])
  const [filteredFields, setFilteredFields] = useState([])
  const [tableName, setTableName] = useState('')
  const [openAlertDelete, setOpenAlertDelete] = useState(false)
  const [disabledButton, setDisabledButton] = useState(true)

  const setInit = async (params) => {
    const fetchAllFieldTables = await setAllFieldTablesController(params)
    dispatch(getAllFieldTables(fetchAllFieldTables))

    setIsLoading(false)
  }

  useEffect(() => {
    if (user?.roles?.includes('doctor') || user?.roles?.includes('centre')) {
      const params = {
        centre: entityId,
        patientCentre: patientCentreId,
        user: user._id,
      }

      setInit(params)
    }

    return () => {
      dispatch(resetSelectedFieldTable())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch])

  const handleChangeTable = (e) => {
    setSelectedTable(e.target.value)
    dispatch(selectedFieldAction(e.target.value))
  }

  const handleChangeTableName = (e) => {
    if (e.target.value === '' || selectedFields?.length === 0) {
      setDisabledButton(true)
      setTableName(e.target.value)
    } else {
      setDisabledButton(false)
      setTableName(e.target.value)
    }
  }

  const handleResetAllFields = () => {
    setSelectedTable('')
    setSelectedFields([])
    setTableName('')
    setDisabledButton(true)
    dispatch(resetSelectedFieldTable())
  }

  const handleDeleteFieldTable = () => {
    dispatch(deleteFieldTableAdded(selectedFieldTable._id))
    dispatch(resetSelectedFieldTable())
    handleResetAllFields()
  }

  const handleSaveTable = async () => {
    let auxData = {
      user: user._id,
      centre: entityId,
      patientCentre: patientCentreId,
    }

    if (selectedFieldTable) {
      auxData = {
        ...selectedFieldTable,
        fields: selectedFields,
        name: tableName,
      }
      try {
        dispatch(updateFieldTable(auxData))
      } catch (error) {
        showToast('Error al actualizar la tabla', 'error')
      }
    } else {
      auxData = {
        ...auxData,
        fields: selectedFields,
        name: tableName,
      }
      try {
        dispatch(postNewFieldTable(auxData))
      } catch (error) {
        showToast('Error al crear la tabla', 'error')
      }
    }
  }

  useEffect(() => {
    const fieldsToAutocomplete = () => {
      let allHCFields = []
      allClinicHistories?.forEach(({ clinicHistoryFields }) => {
        clinicHistoryFields.forEach((field) => {
          field = {
            ...field,
            _id: field._id || field.clinicHistoryField,
          }

          allHCFields.push(field)
        })
      })

      let hash = {}
      let filteredHCFields = allHCFields.filter((o) => (hash[o._id] ? false : (hash[o._id] = true)))

      setFilteredFields(filteredHCFields)
    }
    fieldsToAutocomplete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedFieldTable !== null) {
      setSelectedFields(selectedFieldTable?.fields)
      setTableName(selectedFieldTable?.name)
      setSelectedTable(selectedFieldTable._id)
      setDisabledButton(false)
    }
  }, [selectedFieldTable])

  if (isLoading) {
    return <LoaderCircle />
  }

  return (
    <div className={`${s.mainContainer} animate__animated animate__fadeIn`}>
      {allFieldTables?.length === 0 ? (
        <p className={s.emptyHC}>No tiene tablas comparativas guardadas</p>
      ) : (
        <div>
          <SelectWithInput
            inputLabel="Tabla comparativa"
            options={allFieldTables}
            onChange={handleChangeTable}
            valueSelect={selectedTable}
          />
        </div>
      )}

      <div className={s.divider}></div>

      <h5>Seleccione los campos que quiera comparar</h5>

      <div>
        <Autocomplete
          multiple
          disableCloseOnSelect
          options={filteredFields}
          noOptionsText="No hay campos disponibles."
          ListboxProps={{ style: { maxHeight: '15rem', fontSize: '14px' } }}
          getOptionLabel={(option) => option?.name?.toUpperCase()}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option._id}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {`${option?.name?.toUpperCase()}`}
            </li>
          )}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          fullWidth
          value={selectedFields}
          renderInput={(params) => <TextField {...params} label="Campos" variant="standard" />}
          onChange={(event, selectedValues) => {
            if (selectedValues.length === 0 || tableName === '') {
              setDisabledButton(true)
              setSelectedFields(selectedValues)
            } else {
              setDisabledButton(false)
              setSelectedFields(selectedValues)
            }
          }}
        />
      </div>

      {selectedFields?.length > 0 && <Table allClinicHistories={allClinicHistories} fields={selectedFields} />}

      <div className={s.containerTextfield}>
        <TextField
          id="tableName"
          label="Nombre de la tabla"
          value={tableName}
          onChange={handleChangeTableName}
          fullWidth
          variant="standard"
        />
      </div>

      <Grid container spacing={1} className={s.mainContainerBtns}>
        {selectedFieldTable !== null ? (
          <>
            <Grid item xs={3.5} sm={2.5} md={1.5}>
              <CaluButton color="primary" size="medium" disabled={disabledButton} onClick={handleSaveTable}>
                Actualizar
              </CaluButton>
            </Grid>

            <Grid item xs={3.5} sm={2.5} md={1.5}>
              <CaluButton color="error" size="medium" onClick={() => setOpenAlertDelete(!openAlertDelete)}>
                Eliminar
              </CaluButton>
              <AlertDialogSlide
                open={openAlertDelete}
                title={'Eliminar tabla'}
                setOpen={setOpenAlertDelete}
                textContent={'¿Realmente quiere eliminar esta tabla comparativa? Esta acción no se podrá restaurar.'}
                textBackButton="Cancelar"
                textConfirmationButton={'Eliminar'}
                onClose={handleDeleteFieldTable}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={3.5} sm={2.5} md={1.5}>
            <CaluButton color="primary" size="medium" disabled={disabledButton} onClick={handleSaveTable}>
              Guardar
            </CaluButton>
          </Grid>
        )}

        <Grid item xs={3.5} sm={2.5} md={1.5}>
          <CaluButton color="secondary" inverted="true" size="small" onClick={handleResetAllFields}>
            Crear nueva
          </CaluButton>
        </Grid>
      </Grid>
    </div>
  )
}

export default ComparativeTables
