import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import DividerSection from '@/components/DividerSection'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { CaluFormAutoComplete } from '@/components/formComponents/customFields/CaluFormAutoComplete'
import CaluButton from '@/components/styledComponents/CaluButton'
import { TextOverButtons } from '@/components/TextOverButtons'
import { useUser } from '@/context/UserProvider'
import { getAllProducts } from '@/store/products/actions.js'
import { patchSelectedTurn } from '@/store/turns/actions'
import { showToast } from '@/utils/toast'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Grid, IconButton } from '@mui/material'

import s from './styles.module.scss'
const ProductsForm = () => {
  const dispatch = useDispatch()
  const { defaultCentre } = useUser()

  const [totalAmountProduct, setTotalAmountProduct] = useState(0)

  const { allProducts } = useSelector((state) => state.products)
  const { selectedTurn } = useSelector((state) => state?.turns)

  const { field: selectedProductField } = useController({ name: 'selectedProduct' })
  const { field: productQuantityField } = useController({ name: 'productQuantity' })

  useEffect(() => {
    if (selectedProductField?.value) {
      const amount = selectedProductField?.value?.price * productQuantityField.value || 0
      setTotalAmountProduct(amount)
    }
  }, [selectedProductField.value, productQuantityField.value])

  const addProduct = () => {
    if (!selectedProductField?.value) return showToast('Debe seleccionar un producto', 'warning', 3000)
    if (productQuantityField?.value === '0') return showToast('Debe ingresar al menos 1 unidad', 'warning', 3000)

    const auxProducts = [
      ...selectedTurn?.products,
      { ...selectedProductField?.value, quantity: parseInt(productQuantityField.value) },
    ]
    dispatch(patchSelectedTurn({ products: auxProducts }))
  }

  const addPropWhenClick = (i) => {
    let auxData = [...selectedTurn.products]
    auxData[i] = { ...auxData[i], showConfirm: true }
    dispatch(patchSelectedTurn({ products: auxData }))
  }

  const deleteItemProductsList = (itemToDelete) => {
    let deleteSelectedProduct = selectedTurn?.products?.filter((index) => index !== itemToDelete)
    dispatch(patchSelectedTurn({ products: deleteSelectedProduct }))
  }

  const removePropWhenClick = (i) => {
    let auxData = [...selectedTurn.products]
    auxData[i] = { ...auxData[i], showConfirm: false }
    dispatch(patchSelectedTurn({ products: auxData }))
  }

  return (
    <Grid container className={s.productsContainer}>
      <DividerSection label="Agregar productos" />
      <Grid container className={s.addProductsContainer}>
        <Grid item xs={12} sm={4}>
          <CaluFormAutoComplete
            name="selectedProduct"
            stateredux={allProducts || []}
            functiontodispatch={getAllProducts}
            centre={defaultCentre}
            inputlabel={`Productos`}
            multiple={false}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <CustomField label="unidades" name="productQuantity" fullWidth />
        </Grid>
        <Grid item xs={12} sm={3} className={s.totalAmountProducts}>
          {`Total $${totalAmountProduct}`}
        </Grid>

        <Grid item xs={2}>
          <CaluButton size="small" inverted="true" onClick={addProduct} className={s.addButton}>
            Agregar
          </CaluButton>
        </Grid>
      </Grid>
      <Grid className={s.gridPayments}>
        {selectedTurn?.products?.length > 0 ? <b className={s.bTag}>Productos agregados</b> : null}
        <ul className={s.paymentsUl}>
          {selectedTurn?.products?.map((product, i) => (
            <li key={product._id} className={s.paymentsList}>
              <span>{`${product.name} (unidades: ${product.quantity}) - total: $${
                product.price * product.quantity
              }`}</span>

              {!product.showConfirm ? (
                <TextOverButtons title=" eliminar" placement="right">
                  <IconButton onClick={() => addPropWhenClick(i)}>
                    <DeleteOutlineIcon className={s.deleteIcon} />
                  </IconButton>
                </TextOverButtons>
              ) : (
                <Grid className={s.containerButtonsDelete}>
                  <TextOverButtons title="Si, eliminar" position="top">
                    <Grid className={s.btnsDeleteIcons}>
                      <IoIosCheckmarkCircleOutline onClick={() => deleteItemProductsList(product)} />
                    </Grid>
                  </TextOverButtons>
                  <TextOverButtons title="No, cancelar">
                    <Grid className={s.btnsCancelIcons}>
                      <IoIosCloseCircleOutline onClick={() => removePropWhenClick(i)} />
                    </Grid>
                  </TextOverButtons>
                </Grid>
              )}
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  )
}

export default ProductsForm
