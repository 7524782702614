import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'users',
  initialState: {
    allUsers: [],
    selectedUser: null,
    isLoaded: false,
    paginationData: {
      page: 1,
      limit: 30,
    },
    errorResetUserPassword: false,
    verificationStatus: null,
    verificationData: {},
    usersProfileConnected: {},
  },
  reducers: {
    GET_ALL_USERS: (state, { payload }) => {
      state.allUsers = payload.allUsers
      state.paginationData = payload.paginationData
    },
    SELECTED_USER: (state, { payload }) => {
      state.selectedUser = payload
    },
    RESET_SELECTED_USER: (state) => {
      state.selectedUser = null
    },
    UPDATED_USER: (state, { payload }) => {
      state.allUsers = state.allUsers.map((user) => {
        if (user._id === payload._id) {
          user = payload
        }
        return user
      })
    },
    DELETE_USER: (state, { payload }) => {
      state.allUsers = state.allUsers.filter((user) => user._id !== payload)
      state.selectedUser = null
    },
    ERROR_RESET_USER_PASSWORD: (state, { payload }) => {
      state.errorResetUserPassword = payload
    },
    VERIFICATION_STATUS: (state, { payload }) => {
      state.verificationStatus = payload
    },
    VERIFICATION_DATA: (state, { payload }) => {
      state.verificationData = payload
    },
    LOADED_USERS: (state, { payload }) => {
      state.isLoaded = payload
    },
    LOADED_USERS_PROFILE_CONNECTED: (state, { payload }) => {
      state.usersProfileConnected = payload
    },
  },
})

export const {
  ERROR_RESET_USER_PASSWORD,
  VERIFICATION_STATUS,
  GET_ALL_USERS,
  SELECTED_USER,
  LOADED_USERS,
  RESET_SELECTED_USER,
  UPDATED_USER,
  DELETE_USER,
  VERIFICATION_DATA,
  LOADED_USERS_PROFILE_CONNECTED,
} = slice.actions
export default slice.reducer
