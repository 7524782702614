export const downloadTableAdapter = (entry, tab) => {
  let buildTable

  if (tab === 0) {
    buildTable = {
      Código: entry?.code,
      'Seguro-médico': entry?.insurance?.name,
      Servicio: entry?.service?.name,
      Descripción: entry?.description || '-',
      Precio: entry?.price || '-',
    }
  }

  if (tab === 1) {
    buildTable = {
      Profesional: `${entry?.lastname?.toUpperCase()}, ${entry?.firstname}`,
      'Precio consulta particular': entry?.price || '-',
      'Precio primera consulta': entry?.priceFirstTime || '-',
    }
  }

  if (tab === 2) {
    buildTable = {
      Servicio: entry?.name,
      Precio: entry?.price,
    }
  }

  return buildTable
}
