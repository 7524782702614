import PropTypes from 'prop-types'

export const handleFindPlanPrice = (
  allPlanPrices,
  country,
  subscriptionPlan,
  subscriptionAgendasQuantity,
  setSuggestedPrice = null,
) => {
  const findPlan = allPlanPrices?.find(
    (plan) =>
      plan?.country === (country || 'ARG') &&
      plan?.subscriptionPlan?._id === subscriptionPlan &&
      Number(subscriptionAgendasQuantity) >= plan?.minAgendas &&
      Number(subscriptionAgendasQuantity) <= plan?.maxAgendas,
  )

  if (!findPlan) {
    if (setSuggestedPrice) {
      setSuggestedPrice('-')
    }
    return
  }

  if (setSuggestedPrice) {
    setSuggestedPrice(findPlan?.price)
  }
  return findPlan?.price
}

handleFindPlanPrice.propTypes = {
  allPlanPrices: PropTypes.array.isRequired,
  country: PropTypes.string.isRequired,
  subscriptionPlan: PropTypes.string.isRequired,
  subscriptionAgendasQuantity: PropTypes.number.isRequired,
  setSuggestedPrice: PropTypes.func,
}
