import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import { useUser } from '@/context/UserProvider'
import { setStatisticsController } from '@/controllers/statistics'
import useValidateURLId from '@/hooks/useValidateURLId'
import { getAllStatistics } from '@/store/statistics/actions'
import { Grid } from '@mui/material'

import AbsentTurnsChart from './components/absentTurnsChart'
import OnlineTurnsChart from './components/onlineTurnsChart'
import TotalTurnsChart from './components/totalTurnsChart'

import s from './styles.module.scss'

const Statistics = (directionOfAnimation) => {
  const dispatch = useDispatch()
  const centreId = useValidateURLId()
  const { user } = useUser()
  const { t } = useTranslation('global')

  const [isLoading, setIsLoading] = useState(true)

  const { statistics } = useSelector((state) => state.statistics)

  const setInit = async (params) => {
    setIsLoading(true)
    const fetchAllStatistics = await setStatisticsController(params)
    let auxFetchAllStatistics = [...fetchAllStatistics].reverse()
    dispatch(getAllStatistics(auxFetchAllStatistics))
    setIsLoading(false)
  }

  useEffect(() => {
    let params = {
      centre: centreId,
    }

    if (user.roles.includes('doctor')) {
      params = {
        ...params,
        doctorCentre: user?.doctorCentres[0],
      }
    }
    setInit(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {statistics.length > 0 ? (
        <Grid container spacing={4} className={`${s.mainContainer} ${directionOfAnimation.directionOfAnimation}`}>
          <Grid item xs={12} className={s.containerTitle}>
            <h5>Panel de estadísticas - Centro</h5>
            <p>
              Los gráficos muestran datos mensuales para los meses en que se hayan detectado más de 10{' '}
              {t('turnLabel.p')}.
            </p>
          </Grid>

          {isLoading ? (
            <LoaderCircle size={50} />
          ) : (
            <>
              <Grid item xs={12}>
                <TotalTurnsChart statistics={statistics} />
              </Grid>
              <Grid item xs={12} md={6}>
                <OnlineTurnsChart statistics={statistics} />
              </Grid>
              <Grid item xs={12} md={6}>
                <AbsentTurnsChart statistics={statistics} />
              </Grid>
            </>
          )}
        </Grid>
      ) : (
        <h2 className={s.emptyStatistics}>Aún no tiene estadísticas el centro</h2>
      )}
    </>
  )
}

export default Statistics
