import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import LoaderCircle from '@/components/LoaderCircle'
import { getCentreBySlug } from '@/store/centres/actions'

const SlugPage = () => {
  const dispatch = useDispatch()
  const centreSlug = useParams().slug

  const [isLoading, setIsLoading] = useState(true)
  const [centreWithSlug, setCentreWithSlug] = useState({})

  const goToCentrePage = async () => {
    let centreFound = await dispatch(getCentreBySlug(centreSlug))
    setCentreWithSlug(centreFound)
  }

  useEffect(() => {
    if (!centreSlug) {
      window.location.href = '/'
      setIsLoading(false)
      return
    }

    goToCentrePage()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centreSlug])

  useEffect(() => {
    if (centreWithSlug === null) {
      window.location.href = `/no/existe`
      setIsLoading(false)
      return
    }

    if (centreWithSlug?._id) {
      window.location.href = `/centro/${centreWithSlug?._id}`
      setIsLoading(false)
      return
    }
  }, [centreWithSlug])

  if (isLoading) {
    return <LoaderCircle text="Cargando..." />
  }

  return <div></div>
}

export default SlugPage
