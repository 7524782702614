export const sortArrayAlphabetically = (array) => {
  return array?.sort((a, b) => {
    return a?.name
      ? a?.name?.toUpperCase() > b?.name?.toUpperCase()
        ? 1
        : -1
      : a?.lastname?.toUpperCase() > b?.lastname?.toUpperCase()
      ? 1
      : -1
  })
}
