import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'centreMercadoPagoInfo',
  initialState: {
    detail: null,
    error: false,
    isLoading: null,
  },
  reducers: {
    FETCH_CENTRE_MERCADO_PAGO_INFO: (state, { payload }) => {
      state.detail = payload
    },
    DELETE_CENTRE_MERCADO_PAGO_INFO: (state) => {
      state.detail = null
    },
    LOADED_CENTRE_MERCADO_PAGO_INFO: (state, { payload }) => {
      state.isLoading = payload
    },
  },
})

export const { FETCH_CENTRE_MERCADO_PAGO_INFO, DELETE_CENTRE_MERCADO_PAGO_INFO, LOADED_CENTRE_MERCADO_PAGO_INFO } =
  slice.actions
export default slice.reducer
