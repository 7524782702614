import { deleteProduct, fetchAllProducts, postProduct, putProduct } from '@/services/products'
import {
  FETCH_ALL_PRODUCTS,
  LOADED_PRODUCTS,
  PRODUCT_CREATED,
  PRODUCT_DELETED,
  PRODUCT_UPDATED,
} from '@/store/products'
import { showToast } from '@/utils/toast'

const getAllProducts = (params) => async (dispatch) => {
  try {
    let { docs: allProducts, ...paginationData } = await fetchAllProducts(params)
    dispatch(FETCH_ALL_PRODUCTS({ allProducts, paginationData }))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const createNewProduct =
  (data, hideAlert = false) =>
  async (dispatch) => {
    try {
      const newProduct = await postProduct(data)
      dispatch(PRODUCT_CREATED(newProduct))
      if (newProduct && !hideAlert) {
        showToast('Producto agregado correctamente', 'success')
      }
      return newProduct
    } catch (error) {
      const { message } = error
      console.log('Error message: ', message)
      showToast('Error al crear el producto', 'error')
    }
  }

const removeProduct = (id) => async (dispatch) => {
  try {
    await deleteProduct(id)
    dispatch(PRODUCT_DELETED(id))
    showToast('Producto Eliminado', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateProduct = (data, hideAlert) => async (dispatch) => {
  try {
    const updatedProduct = await putProduct(data)
    dispatch(PRODUCT_UPDATED(updatedProduct))
    if (updatedProduct && !hideAlert) {
      showToast('Plan actualizado correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const isLoadedProducts = (data) => async (dispatch) => {
  try {
    dispatch(LOADED_PRODUCTS(data))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const handlePaginationChangeProducts = (newPage, newLimit, searchText) => async (dispatch, getState) => {
  const { centreProfile } = getState().centres
  const params = {
    centre: centreProfile?._id,
    page: newPage,
    limit: newLimit,
    search: searchText,
  }

  dispatch(LOADED_PRODUCTS(true))
  await dispatch(getAllProducts(params))
  dispatch(LOADED_PRODUCTS(false))
}

export {
  createNewProduct,
  getAllProducts,
  handlePaginationChangeProducts,
  isLoadedProducts,
  removeProduct,
  updateProduct,
}
