import * as React from 'react'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormCreateProvider from './formCreateProvider'

export default function PopUpCreateProvider({ open, centreProviderSelected, onClose }) {
  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={open} onClose={onClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={centreProviderSelected ? 'Datos personales del proveedor' : 'Nuevo proveedor'}
            handleClose={onClose}
          />
        </DialogTitle>
        <DialogContent className="animate__animated animate__fadeIn">
          <FormCreateProvider onClose={onClose} formValues={centreProviderSelected} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
