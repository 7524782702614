import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const getCentreConfiguration = async (centreId) => {
  const route = `api/centreConfigurations/${centreId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewCentreConfiguration = async (data) => {
  const route = `api/centreConfigurations`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteCentreConfiguration = async (id) => {
  const route = `api/centreConfigurations/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putCentreConfiguration = async (data) => {
  const route = `api/centreConfigurations`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { addNewCentreConfiguration, deleteCentreConfiguration, getCentreConfiguration, putCentreConfiguration }
