import { createGlobalStyle } from 'styled-components'
import { Mui } from './Mui'
import { FullCalendarStyles } from './FullCalendarStyles'
import { CKEditorStyles } from './CKEditorStyles'

const GlobalStyle = createGlobalStyle`
${Mui}
${FullCalendarStyles}
${CKEditorStyles}

html,
body {
    height: 100%;
    width: 100%;
    color: #232323;
    margin: 0 auto;
    box-sizing: border-box;
    line-height: 1.45;
    padding: 0px !important;
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    font-family: 'Poppins', sans-serif;
    background-color: ${(props) => props.theme.colors.base.white};
}

p {
  margin: 0 0 10px;
  font-family: 'Poppins', sans-serif;
}

span {
  font-family: 'Poppins', sans-serif;
}

b {
  font-family: 'Poppins', sans-serif;
}

div {
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}

li {
  font-family: 'Poppins', sans-serif;
}

h2 {
font-weight: 700;
font-size: 28px;
line-height: 130%;
color: #0F1333;
}



::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}







// animation delay
.delay-05 {
  -webkit-animation-delay: 0.5s; /* Chrome, Safari, Opera */
  animation-delay: 0.5s;
}

.delay-10 {
  -webkit-animation-delay: 1.0s; /* Chrome, Safari, Opera */
  animation-delay: 1.0s;
}

.delay-15 {
  -webkit-animation-delay: 1.5s; /* Chrome, Safari, Opera */
  animation-delay: 1.5s;
}

.delay-20 {
  -webkit-animation-delay: 2.0s; /* Chrome, Safari, Opera */
  animation-delay: 2.0s;
}

.delay-25 {
  -webkit-animation-delay: 2.5s; /* Chrome, Safari, Opera */
  animation-delay: 2.5s;
}

// helper classes
.capitalize {
  text-transform: capitalize;
}

.upper {
  text-transform: uppercase;
}

.lower {
  text-transform: uppercase;
}

.ck-editor__editable_inline {
  min-height: 250px;
}

//purple calu
.calu-a-bg {
  color: ${(props) => props.theme.colors.base.white};
  background-color: ${(props) => props.theme.colors.a};
}

//dark blue
.calu-b-bg {
  color: ${(props) => props.theme.colors.base.white};
  background-color: ${(props) => props.theme.colors.b};
}

//sky blue
.calu-c-bg {
  color: ${(props) => props.theme.colors.b};
  background-color: ${(props) => props.theme.colors.c};
}

//green
.calu-d-bg {
  color: ${(props) => props.theme.colors.b};
  background-color: ${(props) => props.theme.colors.d};
}

//yellow
.calu-e-bg {
  color: ${(props) => props.theme.colors.b};
  background-color: ${(props) => props.theme.colors.e};
}

//red
.calu-f-bg {
  color: ${(props) => props.theme.colors.base.white};
  background-color: ${(props) => props.theme.colors.f};
}

//red invert
.calu-fi-bg {
  color: ${(props) => props.theme.colors.f};
  background-color: ${(props) => props.theme.colors.base.white};
}
`

export default GlobalStyle
