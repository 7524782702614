import React, { useState } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import {
  sendWhatsappEmpty,
  sendWhatsappQuickRegister,
  sendWhatsappToRemindTurn,
  sendWhatsappTurnDetail,
} from '@/utils/functions/whatsappMessages'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { IconButton, Menu, MenuItem, TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

export const CustomMobileField = ({ fullWidth, name, countryCode, selectedTurn, centre }) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({ name })

  const { t } = useTranslation('global')

  const [anchoWpp, setAnchoWpp] = useState(null)
  const openWhatsappMenu = Boolean(anchoWpp)

  const handleClickSendWhatsapp = (event) => {
    setAnchoWpp(event.currentTarget)
  }
  const handleCloseMenuWhatsapp = () => {
    setAnchoWpp(null)
  }

  return (
    <>
      <TextField
        name={name}
        className={s.inputWidth90}
        error={Boolean(error)}
        fullWidth={fullWidth}
        helperText={Boolean(error) && error?.message}
        type="number"
        label="Móvil/Celular"
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        ref={ref}
        value={value}
        variant="standard"
        onWheel={(e) => e.target.blur()}
        InputProps={{
          startAdornment: <InputAdornment position="start">{`+${countryCode}`}</InputAdornment>,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClickSendWhatsapp} className={s.iconButtonWhatsapp}>
                <WhatsAppIcon className={s.whatsappIcon} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {openWhatsappMenu && (
        <Menu
          id="basic-menu"
          anchorEl={anchoWpp}
          open={openWhatsappMenu}
          onClose={handleCloseMenuWhatsapp}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              sendWhatsappToRemindTurn(selectedTurn, centre)
              setAnchoWpp(null)
            }}
          >
            Enviar recordatorio por Whatsapp
          </MenuItem>
          <MenuItem
            onClick={() => {
              sendWhatsappTurnDetail(selectedTurn, centre)
              setAnchoWpp(null)
            }}
          >
            Enviar detalle {t('turnLabel.sd')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              sendWhatsappQuickRegister(selectedTurn, centre)
              setAnchoWpp(null)
            }}
          >
            Enviar invitacion para crear cuenta
          </MenuItem>

          <MenuItem
            onClick={() => {
              sendWhatsappEmpty(selectedTurn, centre)
              setAnchoWpp(null)
            }}
          >
            Enviar mensaje en blanco
          </MenuItem>
        </Menu>
      )}
    </>
  )
}

CustomMobileField.propTypes = {
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  selectedTurn: PropTypes.object.isRequired,
}

CustomMobileField.defaultProps = {
  fullWidth: false,
  type: 'number',
  disabled: false,
}
