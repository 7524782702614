import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { Grid } from '@mui/material'

import s from './styles.module.scss'

const HeaderForm = ({ doctorProfile }) => {
  const { t } = useTranslation('global')

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  return (
    <Grid item className={s.formTitle}>
      <>
        {!doctorProfile && (
          <>
            <h3>Solicitud de alta de perfil profesional</h3>
            <p className={s.textContent}>
              Complete el siguiente formulario con sus datos profesionales.
              <br />
              Luego de que verifiquemos sus datos, aparecerá en los listados de profesionales de las búsquedas que los
              {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`)} realicen en{' '}
              <a href="www.calu.app" className={s.colorLink}>
                www.calu.app
              </a>
            </p>
          </>
        )}
        {doctorProfile && doctorProfile.status === 'reviewing' && (
          <>
            <h3>
              Perfil profesional: <span className={s.reviewingState}>EN REVISIÓN</span>
            </h3>
            <p className={s.textContent}>
              Su solicitud fue creada correctamente y se encuentra <span className={s.reviewingState}>en revisión</span>
              .
              <br />
              Estamos verificando su información. Dentro de las próximas 48 horas te avisaremos por mail si ha sido
              aprobada o no.
              <br />
              Puede actualizar sus datos profesionales en el siguiente formulario.
            </p>
          </>
        )}
        {doctorProfile && doctorProfile.status === 'approved' && (
          <>
            <h3>
              Perfil profesional: <span className={s.approvedState}>APROBADO</span>{' '}
            </h3>
            <p className={s.textContent}>
              Su solicitud fue <span className={s.approvedState}>aprobada</span>.
              <br />
              Su perfil profesional ya es visible en las búsquedas que los{' '}
              {t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`)} realizan en{' '}
              <a className={s.colorLink} href="www.calu.app">
                www.calu.app
              </a>
              <br />
              <br />
              Puede actualizar sus datos de profesional en el siguiente formulario.
            </p>
          </>
        )}
        {doctorProfile && doctorProfile.status === 'rejected' && (
          <>
            <h3>
              Perfil profesional: <span className={s.rejectedState}>RECHAZADO</span>{' '}
            </h3>
            <p className={s.textContent}>
              Su solicitud fue <span className={s.rejectedState}>rechazada</span>.
              <br />
              <br />
              Motivo de rechazo: {doctorProfile?.rejectedText || 'No se ha especificado, comunicate con nosotros'}
              <br />
              <br />
              Puede actualizar sus datos profesionales en el siguiente formulario, y volveremos a verificar su perfil
              profesional
              <br />
              dentro de las 48 horas.
            </p>
          </>
        )}
      </>
    </Grid>
  )
}

export default HeaderForm
