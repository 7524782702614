import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'myProfile',
  initialState: {
    centreSelected: null,
    doctorSelected: null,
    secretarySelected: null,
    patientSelected: null,
  },
  reducers: {
    CENTRE_SELECTED: (state, { payload }) => {
      state.centreSelected = payload
    },
    DOCTOR_PROFILE_SELECTED: (state, { payload }) => {
      state.doctorSelected = payload
    },
    SECRETARY_SELECTED: (state, { payload }) => {
      state.secretarySelected = payload
    },
    PATIENT_SELECTED: (state, { payload }) => {
      state.patientSelected = payload
    },

    RESET_ENTITY_SELECTED: (state, { payload }) => {
      state.centreSelected = null
      state.doctorSelected = null
      state.secretarySelected = null
      state.patientSelected = null
    },
    RESET_STATE: (state, { payload }) => {
      state.centreSelected = null
      state.doctorSelected = null
      state.secretarySelected = null
      state.patientSelected = null
    },
  },
})

export const {
  CENTRE_SELECTED,
  DOCTOR_PROFILE_SELECTED,
  SECRETARY_SELECTED,
  PATIENT_SELECTED,
  RESET_ENTITY_SELECTED,
  RESET_STATE,
} = slice.actions
export default slice.reducer
