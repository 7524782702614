import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import ImageCircle from '@/components/imageCircle'
import LinealProgress from '@/components/LinealProgress'
import Root from '@/components/RootDivider'
import WhatsappIconButton from '@/components/WhatsappIconButton'
import { useUser } from '@/context/UserProvider'
import { countryCodes } from '@/helpers/constants'
import { useUploadImage } from '@/hooks/useUploadImage'
import {
  clearPatientCentres,
  getPatientsCentreByDni,
  getPatientsCentreByInputSearch,
  updatePatientCentres,
} from '@/store/patientCentres/actions'
import { absentPatientTurns } from '@/utils/functions/absentPatientTurns'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { handleSetCountryCode } from '@/utils/functions/handleSetCountryCode'
import { handleSetDniLabel } from '@/utils/functions/handleSetDniLabel'
import { parsedDniValue } from '@/utils/functions/parsedDniValue'
import { sortArrayAlphabetically } from '@/utils/functions/sortArrayAlphabetically'
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import InputAdornment from '@mui/material/InputAdornment'
import { DatePicker } from '@mui/x-date-pickers'

import s from '../../styles.module.scss'

const PatientDataForm = ({
  control,
  errors,
  setValue,
  patientSelected,
  showAllForm,
  setPatientSelected,
  watch,
  searchByInput,
  reset,
  setPatientCentreValue,
  setSearchByInput,
  patientCentreValue,
  setDisabledInputLastname,
  setDisabledInputDni,
  disabledInputLastname,
  disabledInputDni,
  setPatientCentresCopy,
  patientCentresCopy,
  patientValueInputLastname,
  setPatientValueInputLastname,
  absentPercentage,
}) => {
  const { defaultCentre } = useUser()
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const allPatientTags = useSelector((state) => state.patientTags.all)
  const { patientCentres } = useSelector((state) => state.patientCentres)
  const centreDetail = useSelector((state) => state?.centres?.centreProfile)
  const [telephoneRequired, setTelephoneRequired] = useState(true)
  const [mobileRequired, setMobileRequired] = useState(true)
  const [numberMobile, setNumberMobile] = useState(null)
  const [dniLabel, setDniLabel] = useState('DNI')
  const [foundPatientByDni, setFoundPatientByDni] = useState(null)

  const [searchByInputLastname, setSearchByInputLastname] = useState('')
  const [searchingPatientByDni, setSearchingPatientByDni] = useState(false)
  const [searchingPatientByLastname, setSearchingPatientByLastname] = useState(false)
  const { loadingImage, uploadImage } = useUploadImage()

  const centreCountry = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()
  const [countryCode, setCountryCode] = useState(54)

  const watchMobile = watch('mobile')
  const watchCountry = watch('country')
  const watchTelephone = watch('telephone')
  const searchDni = watch('dni')

  useEffect(() => {
    if (patientCentres?.length > 0) {
      setPatientCentresCopy([...patientCentres])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientCentres])

  useEffect(() => {
    if (patientCentreValue) {
      setDisabledInputDni(false)
      setDisabledInputLastname(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientCentreValue])

  const handleCountryCode = (country) => {
    const countryCode = handleSetCountryCode(country)
    const dniLabel = handleSetDniLabel(country)
    if (countryCode) setCountryCode(countryCode)
    if (dniLabel) setDniLabel(dniLabel)
  }

  useEffect(() => {
    if (!watchCountry) handleCountryCode(centreCountry)
    if (watchCountry) handleCountryCode(watchCountry)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCountry])

  useEffect(() => {
    if (watchMobile || watchTelephone) {
      if (watchMobile) setNumberMobile(watchMobile)
      setMobileRequired(false)
      setTelephoneRequired(false)
    } else {
      setMobileRequired(true)
      setTelephoneRequired(true)
    }
  }, [watchMobile, watchTelephone])

  const searchPatientByDni = async () => {
    const params = {
      centre: defaultCentre,
      dni: searchDni,
    }

    const foundPatient = await dispatch(getPatientsCentreByDni(params))
    setSearchingPatientByDni(false)
    if (foundPatient?.length) {
      setFoundPatientByDni(foundPatient[0])
    }
  }

  useEffect(() => {
    if (searchDni && searchByInput?.length === 0 && searchByInputLastname.length === 0) {
      setSearchingPatientByDni(true)
      const timeOutInputDni = setTimeout(() => {
        searchPatientByDni()
      }, 500)
      return () => clearTimeout(timeOutInputDni)
    } else {
      setSearchingPatientByDni(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDni, searchByInput])

  useEffect(() => {
    if (foundPatientByDni) {
      setPatientSelected(foundPatientByDni)
      setDisabledInputDni(true)
      setDisabledInputLastname(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundPatientByDni])

  const searchingPatients = async () => {
    const params = {
      centre: defaultCentre,
      search: searchByInputLastname,
    }
    setSearchingPatientByLastname(true)
    await dispatch(getPatientsCentreByInputSearch(params))
    setSearchingPatientByLastname(false)
  }

  useEffect(() => {
    if (searchByInputLastname.length >= 2 && !patientSelected) {
      const timeOutInputLastname = setTimeout(() => {
        searchingPatients()
      }, 500)
      return () => clearTimeout(timeOutInputLastname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchByInputLastname])

  useEffect(() => {
    if (patientValueInputLastname !== null) {
      const getPatientCentre = patientCentresCopy.filter((e) => e._id === patientValueInputLastname)[0]
      setPatientSelected(getPatientCentre)
      setDisabledInputLastname(true)
      setDisabledInputDni(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientValueInputLastname, patientCentresCopy])

  const valueOfPatient = (event, patient) => {
    setPatientValueInputLastname(patient._id)
    dispatch(clearPatientCentres())
  }

  const getOptionLabel = (option) => {
    if (option) {
      return capitalizeFirstLetters(option?.lastname) || capitalizeFirstLetters(option)
    }
    return ''
  }

  const renderOptions = (props, option) => {
    if (option) {
      let DNI
      const dniLabel = handleSetDniLabel(option?.country)
      if (option?.dni?.length < 15) {
        DNI = `${dniLabel} ${option.dni}`
      } else {
        DNI = ''
      }

      return (
        <li {...props} key={option._id} className={s.listOptions}>
          {`${option?.lastname?.toUpperCase()}, ${option?.firstname?.toUpperCase()} (${DNI})`}
        </li>
      )
    }
  }

  const resetForm = () => {
    setPatientSelected(null)
    setPatientCentreValue(null)
    reset()
    setDisabledInputDni(false)
    setDisabledInputLastname(false)
    setSearchByInput('')
    dispatch(clearPatientCentres())
    setPatientValueInputLastname(null)
    setSearchByInputLastname('')
    setValue('dni', '')
  }

  const showWhatsappButton = () => {
    if (patientSelected && patientSelected.mobile) {
      return <WhatsappIconButton countryCode={countryCode} numberMobile={patientSelected.mobile} />
    } else if (!patientSelected && !patientSelected?.mobile && numberMobile?.length >= 10) {
      return <WhatsappIconButton countryCode={countryCode} numberMobile={numberMobile} />
    } else {
      return null
    }
  }

  return (
    <>
      {patientSelected && absentPercentage && (
        <div className={s.absentTurns}>{`Ausentismo: ${absentPercentage?.absentism * 100}% (${absentPatientTurns(
          absentPercentage,
        )})`}</div>
      )}

      {patientSelected && (
        <div className={s.cleanFormText} onClick={resetForm}>
          Limpiar
        </div>
      )}
      <div className={s.div1Container}>
        {showAllForm && (
          <div className={s.divItem}>
            <Root>
              <Divider>
                <Chip label="Datos personales" />
              </Divider>
            </Root>
          </div>
        )}
        {patientSelected && showAllForm && (
          <div className={s.imageCircleContainer}>
            <ImageCircle
              image={patientSelected.image}
              uploadImage={(e) => uploadImage(e, 'image', updatePatientCentres, patientSelected)}
              loading={loadingImage}
              className="uploadImageProfile"
              id="patientImage"
            />
          </div>
        )}
      </div>

      <Grid container>
        <Grid item xs={12} sm={4}>
          <Controller
            name="dni"
            control={control}
            render={({ field }) => (
              <>
                <TextField
                  {...field}
                  value={parsedDniValue(field.value)}
                  type="text"
                  label={dniLabel === 'DI' ? 'DNI, CC, CI' : dniLabel}
                  variant="standard"
                  className={s.inputWidth90}
                  disabled={disabledInputDni}
                  onWheel={(e) => e.target.blur()}
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  onKeyDown={handleKeyDown}
                />
                {searchingPatientByDni && <LinealProgress />}
              </>
            )}
            defaultValue=""
            rules={{
              minLength: {
                value: 4,
                message: 'Debe contener al menos 4 caracteres',
              },
              maxLength: {
                value: 14,
                message: 'Este campo debe tener máximo 14 caracteres.',
              },
            }}
          />
          {errors.dni?.message && <p className={s.formAlert}>{errors.dni?.message}</p>}
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="lastname"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                onChange={valueOfPatient}
                options={patientCentresCopy?.map((option) => option)}
                isOptionEqualToValue={(option) => option._id}
                getOptionLabel={(option) => getOptionLabel(option)}
                renderOption={(props, option) => renderOptions(props, option)}
                disabled={disabledInputLastname}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      variant="standard"
                      label="Apellido *"
                      onKeyDown={handleKeyDown}
                      value={searchByInputLastname}
                      className={s.inputWidth90}
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        style: { fontSize: 14 },
                      }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                      onChange={(e) => {
                        setValue('lastname', e.target.value)
                        setSearchByInputLastname(e.target.value)
                      }}
                    />
                    {searchingPatientByLastname && <LinealProgress />}
                  </>
                )}
              />
            )}
            rules={{ required: true }}
            defaultValue=""
          />
          {errors.lastname?.type === 'required' && <p className={s.formAlert}>Apellido(s) requerido(s)</p>}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="firstname"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                label="Nombre(s) *"
                variant="standard"
                onKeyDown={handleKeyDown}
                className={s.inputWidth98}
                inputProps={{ style: { textTransform: 'capitalize', fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />
            )}
            rules={{ required: true }}
            defaultValue=""
          />
          {errors.firstname?.type === 'required' && <p className={s.formAlert}>Nombre(s) requerido(s)</p>}
        </Grid>
        <Grid item xs={12} sm={showAllForm ? 4 : 6} className={s.marginTextFields}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="email"
                label="Email"
                variant="standard"
                onKeyDown={handleKeyDown}
                className={s.inputWidth90}
                inputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />
            )}
            defaultValue=""
          />
        </Grid>
        <Grid item xs={12} sm={showAllForm ? 4 : 6} className={s.marginTextFields}>
          <Controller
            name="mobile"
            control={control}
            render={({ field }) => (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    {...field}
                    type="number"
                    label="Móvil/Celular *"
                    variant="standard"
                    onKeyDown={handleKeyDown}
                    className={s.fullWidth}
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    onWheel={(e) => e.target.blur()}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{`+${countryCode}`}</InputAdornment>,
                      endAdornment: showWhatsappButton() ? (
                        <InputAdornment position="end">{showWhatsappButton()}</InputAdornment>
                      ) : (
                        ''
                      ),
                    }}
                  />
                </div>
              </>
            )}
            rules={{
              required: mobileRequired,
              maxLength: {
                value: 20,
                message: 'Este campo debe tener máximo 20 caracteres.',
              },
              pattern: {
                value: watchCountry === 'ARG' ? /^(?!15|0)/ : '',
                message: 'Debe ir con característica, sin 0 y sin 15. Ejemplo: 1159876543.',
              },
            }}
            defaultValue=""
          />

          {errors.mobile?.message && <p className={s.formAlert}>{errors.mobile?.message}</p>}
          {errors.mobile?.type === 'required' && <p className={s.formAlert}>Campo requerido</p>}
        </Grid>

        {showAllForm && (
          <>
            <Grid item xs={12} sm={4} className={s.marginTextFields}>
              <Controller
                name="telephone"
                control={control}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      type="number"
                      label="Teléfono fijo"
                      variant="standard"
                      onKeyDown={handleKeyDown}
                      className={s.inputWidth98}
                      onWheel={(e) => e.target.blur()}
                      inputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                    />
                  </>
                )}
                rules={{
                  required: telephoneRequired,

                  maxLength: {
                    value: 20,
                    message: 'Este campo debe tener máximo 20 caracteres.',
                  },
                  pattern: {
                    value: /^(?!0)/,
                    message: 'Debe ir con característica y sin 0. Ejemplo: 1149876543.',
                  },
                }}
                defaultValue=""
              />
              {errors.telephone?.message && <p className={s.formAlert}>{errors.telephone?.message}</p>}
              {errors.telephone?.type === 'required' && <p className={s.formAlert}>Campo requerido</p>}
            </Grid>

            <Grid item xs={12} sm={4} className={s.marginTextFields}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    label={`Nota del ${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}`}
                    variant="standard"
                    multiline
                    className={s.inputWidth90}
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                )}
                defaultValue=""
              />
            </Grid>

            <Grid item xs={12} sm={4} className={s.marginSelect}>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>País</InputLabel>
                    <Select
                      {...field}
                      label="País"
                      className={s.inputWidth90}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 400,
                            width: 550,
                          },
                        },
                      }}
                    >
                      {sortArrayAlphabetically(countryCodes).map((c) => (
                        <MenuItem key={c.value} value={c.value}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                defaultValue={centreCountry}
              />
            </Grid>

            <Grid item xs={12} sm={4} className={s.marginSelect}>
              <Controller
                name="gender"
                control={control}
                defaultValue={'female'}
                render={({ field }) => (
                  <FormControl fullWidth variant="standard" className={s.inputWidth98}>
                    <InputLabel>Género</InputLabel>
                    <Select {...field} label="Sexo" defaultValue="">
                      <MenuItem value={'female'}>Femenino</MenuItem>
                      <MenuItem value={'male'}>Masculino</MenuItem>
                      <MenuItem value={'other'}>Prefiero no decirlo</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} className={s.gridMargin}>
              <Controller
                name="patientTags"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <>
                    <Autocomplete
                      {...field}
                      multiple={true}
                      disableCloseOnSelect
                      options={allPatientTags}
                      getOptionLabel={(option) => capitalizeFirstLetters(option?.name)}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          <span style={{ background: option.color }} className={s.itemTagColor}></span>
                          <span>{capitalizeFirstLetters(option?.name)}</span>
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) => option._id === value._id}
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Etiquetas" variant="standard" />}
                      onChange={(event, selectedValues) => {
                        setValue(
                          'patientTags',
                          selectedValues.map((el) => el),
                        )
                      }}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={s.marginTextFields}>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onKeyDown={handleKeyDown}
                    type="text"
                    label="Dirección"
                    variant="standard"
                    className={s.inputWidth90}
                  />
                )}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} sm={4} className={s.marginTextFields}>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onKeyDown={handleKeyDown}
                    type="text"
                    label="Ciudad"
                    variant="standard"
                    className={s.inputWidth90}
                  />
                )}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} sm={4} className={s.marginTextFields}>
              <Controller
                name="birth"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <DatePicker
                      {...field}
                      format="DD/MM/YYYY"
                      label="Fecha de nacimiento"
                      onChange={(newDate) => {
                        setValue('birth', newDate)
                      }}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          className: `${s.inputWidth98}`,
                          helperText: 'Ingresar la fecha en formato DD/MM/YYYY',
                        },
                      }}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} className={s.marginTextFields}>
              <Controller
                name="blockOnlineTurns"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <>
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={<p className={s.labelCheckbox}>{`Bloquear turnos online`}</p>}
                    />
                    <>
                      {field.value === true ? (
                        <span className={s.spanCheckboxNo}>
                          <i>
                            <AiFillCloseCircle /> Bloqueado
                          </i>
                        </span>
                      ) : (
                        <span className={s.spanCheckboxYes}>
                          <i>
                            <AiFillCheckCircle /> Habilitado
                          </i>
                        </span>
                      )}
                    </>
                  </>
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default PatientDataForm
