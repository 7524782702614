import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  patientCentres: [],
  paginationData: {
    page: 1,
    limit: 30,
  },
  patientSelected: null,
  isLoading: false,
  backupPatients: {},
}
const slice = createSlice({
  name: 'patientCentres',
  initialState,
  reducers: {
    PATIENT_CENTRES_UPDATED: (state, { payload }) => {
      state.patientCentres = payload.patientCentres
      state.paginationData = payload.paginationData
      state.isLoading = false
    },
    PATIENT_CENTRE_UPDATED: (state, { payload }) => {
      state.patientCentres = state.patientCentres.map((patient) => {
        if (patient._id === payload._id) {
          patient = payload
        }
        return patient
      })
    },
    BACKUP_PATIENT_CENTRES: (state, { payload }) => {
      state.backupPatients = payload
    },
    PATIENT_CENTRE_DELETE: (state, { payload }) => {
      state.patientCentres = state.patientCentres.filter((patient) => patient._id !== payload)
      state.patientSelected = null
    },
    PATIENT_CENTRE_CREATED: (state, { payload }) => {
      state.patientCentres = [...(state.patientCentres || []), payload]
    },
    PATIENT_CENTRE_SELECTED: (state, { payload }) => {
      state.patientSelected = payload
    },
    PATIENT_CENTRES_LOADING_UPDATED: (state, { payload }) => {
      state.isLoading = payload
    },
    RESET_PATIENT_CENTRE_SELECTED: (state, { payload }) => {
      state.patientSelected = null
    },
    RESET_PATIENT_CENTRE_STATE: (state, { payload }) => {
      state.patientCentres = []
    },
    RESET_BACKUP_PATIENTS: (state, { payload }) => {
      state.backupPatients = {}
    },
  },
})

export const {
  PATIENT_CENTRES_UPDATED,
  PATIENT_CENTRE_UPDATED,
  BACKUP_PATIENT_CENTRES,
  PATIENT_CENTRE_DELETE,
  PATIENT_CENTRE_CREATED,
  PATIENT_CENTRE_SELECTED,
  RESET_PATIENT_CENTRE_SELECTED,
  RESET_PATIENT_CENTRE_STATE,
  PATIENT_CENTRES_LOADING_UPDATED,
  RESET_BACKUP_PATIENTS,
} = slice.actions
export default slice.reducer
