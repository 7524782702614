import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import { useUser } from '@/context/UserProvider'
import { updatePatient } from '@/store/patients/actions'
import { updateUser } from '@/store/users/actions'
import { parsedDniValue } from '@/utils/functions/parsedDniValue'
import { DatePicker } from '@mui/lab'
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const PersonalData = ({ selectedPatient, handleClose }) => {
  const dispatch = useDispatch()
  const { user } = useUser()

  const [birth, setBirth] = useState(dayjs())
  const [isLoading, setIsLoading] = useState(false)

  const patientCountry = selectedPatient?.country || 'ARG'

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      country: patientCountry,
      gender: 'female',
      firstname: '',
      lastname: '',
      dni: '',
      mobile: '',
      telephone: '',
      address: '',
      city: '',
    },
  })

  const onSubmit = async (data, e) => {
    e.preventDefault()
    setIsLoading(true)

    data.birth = data?.birth ? data?.birth : birth?.utc()?.hour(0).minute(0).second(0).format()

    const userData = {
      ...user,
      firstname: data?.firstname,
      lastname: data?.lastname,
      dni: data?.dni,
      mobile: data?.mobile,
    }

    try {
      dispatch(updatePatient(data, true))
      dispatch(updateUser(userData, user?._id))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
      handleClose()
    }
  }

  useEffect(() => {
    if (selectedPatient) {
      Object.keys(selectedPatient).forEach((k) => {
        if (k === 'birth') {
          setBirth(selectedPatient[k])
        }

        setValue(k, selectedPatient[k])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatient])

  return (
    <form className={`${s.containerForm} animate__animated animate__fadeIn`} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p className={s.subTitleForm}>
            <b>Datos personales</b>
          </p>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="dni"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={parsedDniValue(field.value)}
                type="number"
                label="DNI*"
                variant="standard"
                fullWidth
                onWheel={(e) => e.target.blur()}
              />
            )}
            rules={{
              required: true,
              minLength: {
                value: 4,
                message: 'Debe contener al menos 4 caracteres',
              },
              maxLength: {
                value: 14,
                message: 'Este campo debe tener máximo 14 caracteres.',
              },
            }}
          />
          {errors.dni?.message && <p className={s.formAlert}>{errors.dni?.message}</p>}
          {errors.dni?.type === 'required' && <p className={s.formAlert}>DNI requerido</p>}
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="lastname"
            control={control}
            render={({ field }) => (
              <TextField {...field} type="text" label="Apellido(s)*" variant="standard" fullWidth />
            )}
            rules={{ required: true }}
          />
          {errors.lastname?.type === 'required' && <p className={s.formAlert}>Apellido(s) requerido(s)</p>}
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="firstname"
            control={control}
            render={({ field }) => <TextField {...field} type="text" label="Nombre(s)*" variant="standard" fullWidth />}
            rules={{ required: true }}
          />
          {errors.firstname?.type === 'required' && <p className={s.formAlert}>Nombre(s) requerido(s)</p>}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="mobile"
            control={control}
            render={({ field }) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  {...field}
                  type="number"
                  label="Teléfono Móvil*"
                  variant="standard"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                />
              </div>
            )}
            rules={{
              required: true,
              maxLength: {
                value: 20,
                message: 'Este campo debe tener máximo 20 caracteres.',
              },
              pattern: {
                value: patientCountry === 'ARG' ? /^(?!15|0)/ : '',
                message: 'Debe ir con característica, sin 0 y sin 15. Ejemplo: 1159876543.',
              },
            }}
          />
          {errors.mobile?.type === 'required' && <p className={s.formAlert}>Teléfono Móvil requerido</p>}
          {errors.mobile?.message && <p className={s.formAlert}>{errors.mobile?.message}</p>}
        </Grid>

        <Grid item md={6}>
          <Controller
            name="telephone"
            control={control}
            render={({ field }) => (
              <TextField {...field} type="number" label="Teléfono fijo" variant="standard" fullWidth />
            )}
          />
        </Grid>

        <Grid item md={6}>
          <Controller
            name="address"
            control={control}
            render={({ field }) => <TextField {...field} type="text" label="Dirección" variant="standard" fullWidth />}
          />
        </Grid>

        <Grid item md={6}>
          <Controller
            name="city"
            control={control}
            render={({ field }) => <TextField {...field} type="text" label="Ciudad" variant="standard" fullWidth />}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel>Género</InputLabel>
                <Select {...field} label="Sexo">
                  <MenuItem value={'female'}>Femenino</MenuItem>
                  <MenuItem value={'male'}>Masculino</MenuItem>
                  <MenuItem value={'other'}>Prefiero no decirlo</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item sm={12}>
          <FormControl fullWidth variant="standard">
            <DatePicker
              label="Fecha de nacimiento"
              value={birth}
              format="DD/MM/YYYY"
              onChange={(newDate) => {
                setBirth(newDate)
              }}
              renderInput={(params) => (
                <TextField variant="standard" {...params} helperText="Ingresar la fecha en formato DD/MM/YYYY" />
              )}
            />
          </FormControl>
        </Grid>
        <div className={s.containerBtn}>
          <CaluButton color="primary" type="submit" size="medium" loading={isLoading}>
            Guardar
          </CaluButton>
        </div>
      </Grid>
    </form>
  )
}

export default PersonalData
