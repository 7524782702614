import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { RESET_INTERNAL_CENTRE_NOTE_SELECTED } from '@/store/internalCentreNotes'
import { createNewInternalCentreNote, updateInternalCentreNote } from '@/store/internalCentreNotes/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

const FormInternalCentreNote = ({ formValues, handleClosePopUp }) => {
  const dispatch = useDispatch()

  const { centreAdminDetail } = useSelector((state) => state?.centres)

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (data) => {
    setIsLoading(true)
    const auxData = { ...data }
    auxData.centre = centreAdminDetail?._id

    try {
      if (!formValues?._id) {
        await dispatch(createNewInternalCentreNote(auxData))
      } else {
        await dispatch(updateInternalCentreNote(data))
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
      handleClosePopUp()
      dispatch(RESET_INTERNAL_CENTRE_NOTE_SELECTED())
    }
  }

  return (
    <div>
      <ContainerForm
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={{ ...formDefaultValues, ...formValues }}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent isLoading={isLoading} handleClosePopUp={handleClosePopUp} />
      </ContainerForm>
    </div>
  )
}

export default FormInternalCentreNote
