import React from 'react'
import { Controller } from 'react-hook-form'
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io'
import { useSelector } from 'react-redux'
import DividerSection from '@/components/DividerSection/index.jsx'
import CaluButton from '@/components/styledComponents/CaluButton'
import { TextOverButtons } from '@/components/TextOverButtons'
import { paymentMethods } from '@/helpers/constants'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { showToast } from '@/utils/toast'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import dayjs from 'dayjs'

import s from '../../styles.module.scss'

const AddPaymentForm = (props) => {
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const savePayment = () => {
    const paymentMethod = props.getValues('method')
    const formatedDate = dayjs(props.paymentDate).format()
    let payment = []

    if (!props.paymentAmount) {
      showToast('debe ingresar monto pagado ', 'warning', 2000)
      return
    } else {
      payment.push({
        amount: parseInt(props.paymentAmount),
        method: paymentMethod,
        date: toCustomTz(formatedDate, 'utc', true),
      })
      props.paymentHistory.forEach((payment) => (payment.showConfirm ? delete payment.showConfirm : null))
      props.setPaymentHistory(props.paymentHistory.concat(payment))

      props.setPaymentAmount('')
    }
  }

  const addPropWhenClick = (i) => {
    let auxData = [...props.paymentHistory]
    auxData[i].showConfirm = true
    props.setPaymentHistory(auxData)
  }

  const deleteItemPaymentHistory = (itemToDelete) => {
    let paymentItem = props.paymentHistory.filter((index) => index !== itemToDelete)
    props.setPaymentHistory(paymentItem)
  }

  const removePropWhenClick = (i) => {
    let auxData = [...props.paymentHistory]
    delete auxData[i].showConfirm
    props.setPaymentHistory(auxData)
  }

  return (
    <div>
      <DividerSection label="Agregar pago" />
      <Grid container className={s.paymentTurnContainer}>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth variant="standard" className={s.inputWidth90}>
            <DatePicker
              label="Fecha *"
              value={props.paymentDate}
              format="DD/MM/YYYY"
              onChange={(newDate) => {
                props.setPaymentDate(newDate)
              }}
              slotProps={{
                textField: {
                  variant: 'standard',
                  fullWidth: true,
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            type="number"
            label={`${handleSetMoneySign(country)} Monto pagado`}
            variant="standard"
            className={s.inputWidth90}
            onChange={(e) => props.setPaymentAmount(e.target.value)}
            value={props.paymentAmount}
            onWheel={(e) => e.target.blur()}
            onKeyDown={handleKeyDown}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="method"
            control={props.control}
            defaultValue={'ARScash'}
            render={({ field }) => (
              <FormControl variant="standard" fullWidth className={s.inputWidth90}>
                <InputLabel>Medio de pago</InputLabel>
                <Select {...field}>
                  {paymentMethods?.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <CaluButton size="small" inverted="true" onClick={savePayment} className={s.addPaymentButton}>
            Agregar
          </CaluButton>
        </Grid>
      </Grid>
      <Grid className={s.payments}>
        {props.paymentHistory.length > 0 ? <b>Pagos realizados:</b> : null}
        <ul className={s.paymentsUl}>
          {props.paymentHistory?.map((payment, i) => (
            <li key={i} className={s.paymentsList}>
              <b>{dayjs(payment.date).format('DD/MM/YYYY') + '\u00A0'}</b>
              <span>{`${handleSetMoneySign(country)} ${payment.amount}\u00A0`}</span>
              <span>{`(${convertValueToName(paymentMethods, payment.method)})`}</span>

              {!payment.showConfirm ? (
                <TextOverButtons title=" eliminar" placement="right">
                  <IconButton onClick={() => addPropWhenClick(i)}>
                    <DeleteOutlineIcon className={s.deleteIcon} />
                  </IconButton>
                </TextOverButtons>
              ) : (
                <Grid className={s.containerButtonsDelete}>
                  <TextOverButtons title="Si, eliminar" position="top">
                    <Grid className={s.btnsDeleteIcons}>
                      <IoIosCheckmarkCircleOutline onClick={() => deleteItemPaymentHistory(payment)} />
                    </Grid>
                  </TextOverButtons>
                  <TextOverButtons title="No, cancelar">
                    <Grid className={s.btnsCancelIcons}>
                      <IoIosCloseCircleOutline onClick={() => removePropWhenClick(i)} />
                    </Grid>
                  </TextOverButtons>
                </Grid>
              )}
            </li>
          ))}
        </ul>
      </Grid>
    </div>
  )
}

export default AddPaymentForm
