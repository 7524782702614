import React, { useCallback, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { resetSelectedDoctorCentre } from '@/store/doctorCentres/actions'
import { resetSelectedMachine } from '@/store/machines/actions'
import { getAge } from '@/utils/functions/getAge'
import { getChedocClassWithState } from '@/utils/functions/getChedocClassWithState'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { handleSetDniLabel } from '@/utils/functions/handleSetDniLabel'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { Close, Today } from '@mui/icons-material'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { Box, Grid, IconButton } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import dayjs from 'dayjs'
import { STurnDialogHeader } from './styles'

const TurnDialogHeader = (props) => {
  const selectedTurn = props.selectedTurn
  const state = selectedTurn?.state
  const patientCentre = selectedTurn?.patientCentre
  const country = patientCentre?.country
  const entity = props?.machineSelected || props.doctorCentres
  const workingDays = entity?.workTimes?.map((workTime) => workTime?.weekDay?.code)
  let selectedDate = props.selectedDate || selectedTurn?.startHour
  let setSelectedDate = props.setSelectedDate

  const tabValue = props.tabValue
  const [isOpen, setIsOpen] = useState(false)

  const dispatch = useDispatch()
  const { t } = useTranslation('global')

  const getTurnDate = () => {
    let startDate = toCustomTz(selectedDate, undefined, true, 'ddd DD/MM/YYYY')
    return startDate
  }

  const getStartHourTurn = () => {
    let hourDate = toCustomTz(selectedDate, undefined, true, 'HH')
    return hourDate
  }

  const getStartMinuteTurn = () => {
    let minuteTurn = toCustomTz(selectedDate, undefined, true, 'mm')
    return minuteTurn
  }

  const getFinishHourTurn = useCallback(() => {
    let startHour = selectedDate
    let hourTurn = toCustomTz(startHour, undefined, true, 'HH')
    let minuteTurn = toCustomTz(startHour, undefined, true, 'mm')
    let getStartHourTurn = dayjs(startHour)
      .tz('America/Buenos_Aires')
      .hour(props.startHourTurn || hourTurn)
      .minute(props.startMinuteTurn || minuteTurn)
      .format()

    let getFinishHourTurn = dayjs(getStartHourTurn)
      .tz('America/Buenos_Aires')
      .add(props.durationTurn, 'm')
      .format('HH:mm')

    return getFinishHourTurn
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.durationTurn, props.startHourTurn, props.startMinuteTurn])

  const closePopUp = () => {
    dispatch(resetSelectedDoctorCentre())
    dispatch(resetSelectedMachine())
    props.handleClose()
    if (props.reset) props.reset()
  }

  const closePopUpUndatedTurn = () => {
    dispatch(resetSelectedDoctorCentre())
    dispatch(resetSelectedMachine())
    if (props.handleConfigDoctorCentresPanelClose) props.handleConfigDoctorCentresPanelClose()
    props.handleClose()
    if (props.reset) props.reset()
  }

  const handleChangeSelectedDate = (newDate) => {
    let newSelectedDate = dayjs(newDate).format('YYYY-MM-DD')
    // let newSelectedDateWithHour = newSelectedDate + initialHourTurn

    setSelectedDate(newSelectedDate)
  }

  const handleClick = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  const shouldDisableDate = (date) => {
    const dayOfWeek = date.day()
    return !workingDays?.includes(dayOfWeek)
  }

  const datePickerButton = () => {
    return (
      <IconButton size="small">
        <DatePicker
          value={dayjs(selectedDate)}
          format="DD/MM/YYYY"
          open={isOpen}
          onClose={() => setIsOpen(false)}
          onChange={(newDate) => handleChangeSelectedDate(newDate)}
          shouldDisableDate={shouldDisableDate}
          reduceAnimations={true}
          slots={{
            textField: (props) => {
              return (
                <Box ref={props?.InputProps?.ref}>
                  <Today className="todayIcon" onClick={handleClick} />
                </Box>
              )
            },
          }}
          slotProps={{
            popper: { sx: { width: '230px' } },
          }}
        />
      </IconButton>
    )
  }

  return (
    <STurnDialogHeader>
      <Grid item className={`container ${getChedocClassWithState(state)}`}>
        {selectedTurn ? (
          patientCentre ? (
            <h4 className="title">
              {`${patientCentre?.lastname?.toUpperCase()}, ${patientCentre?.firstname?.toUpperCase()}`}

              {patientCentre?.dni?.length < 15 && (
                <span className="small">{`${handleSetDniLabel(country)} ${patientCentre?.dni}`}</span>
              )}

              <span className="small">
                {patientCentre?.birth && (
                  <span>
                    {toCustomTz(patientCentre?.birth, 'utc', false, 'DD/MM/YYYY')} ({getAge(patientCentre?.birth)} años)
                  </span>
                )}
                {patientCentre?.mobile && patientCentre?.telephone ? (
                  <span>
                    {' '}
                    - Tel: {patientCentre?.mobile} / {patientCentre?.telephone}
                  </span>
                ) : patientCentre?.mobile ? (
                  <span> - Tel: {patientCentre?.mobile}</span>
                ) : (
                  patientCentre?.telephone && <span> - Tel: {patientCentre?.telephone}</span>
                )}
              </span>
            </h4>
          ) : (
            <h4 className="title">❌ PACIENTE ELIMINADO</h4>
          )
        ) : (
          <h4 className="title">
            {selectedDate ? t('turnLabel.Nt') : `${t('turnLabel.Nt')} sin fecha`}
            {props?.machineSelected ? (
              <span className="small">{`con ${props?.machineSelected?.name?.toUpperCase()}`}</span>
            ) : (
              <span className="small">{`con ${props?.doctorCentres?.lastname?.toUpperCase()}, ${props?.doctorCentres?.firstname?.toUpperCase()}`}</span>
            )}
          </h4>
        )}

        {tabValue === '1' && selectedDate ? (
          <div className="infoTurn">
            <div className="dateSection">
              <span className="containerCalendar">{datePickerButton()}</span>
              <b>{getTurnDate()}</b>
            </div>

            <div className="dateSection">
              Duración:
              <Controller
                name="consultationDuration"
                control={props.control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="number"
                    className="inputConsultationDuration"
                    onChange={(e) => props.setDurationTurn(e.target.value)}
                    value={props.durationTurn}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={handleKeyDown}
                  />
                )}
              />
              minutos
            </div>

            <div className="dateSection">
              <input
                type="number"
                defaultValue={getStartHourTurn()}
                onChange={(e) => props.setStartHourTurn(e.target.value)}
                onWheel={(e) => e.target.blur()}
                onKeyDown={handleKeyDown}
              />
              :
              <input
                type="number"
                defaultValue={getStartMinuteTurn()}
                onChange={(e) => props.setStartMinuteTurn(e.target.value)}
                onWheel={(e) => e.target.blur()}
                onKeyDown={handleKeyDown}
              />
              <span className="spanTo">{` hasta ${getFinishHourTurn()} hs`}</span>
            </div>
          </div>
        ) : null}
        <Grid item className="buttonsContainer">
          <IconButton className="closeButton" onClick={() => props.setExpandPopUp(!props.expandPopUp)}>
            <OpenInFullIcon />
          </IconButton>
          <IconButton className="closeButton" onClick={selectedDate ? closePopUp : closePopUpUndatedTurn}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </STurnDialogHeader>
  )
}

export default TurnDialogHeader
