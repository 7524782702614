export const getCentreSubCategory = (category) => {
  if (!category) return 'Salud'

  const beautyArray = ['esthetic', 'spa', 'hairdressing', 'barbershop', 'pedicure-manicure', 'massages', 'other']
  // const healthArray = [
  //   'medicine',
  //   'polyclinic',
  //   'odontology',
  //   'kinesiology',
  //   'alternative-medicine',
  //   'nutrition',
  //   'chiropody',
  //   'psychology',
  // ]

  if (beautyArray?.includes(category)) return 'Belleza'
  return 'Salud'
}
