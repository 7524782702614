import React from 'react'
import { Controller } from 'react-hook-form'
import { RiCloseFill } from 'react-icons/ri'
import defaultImage from '@/assets/images/file.jpg'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

const AttachedImageField = ({ name = null, file, onChange, index, hideDeleteButton }) => {
  const cutString = (str) => {
    if (str?.length > 13) {
      let cutStr = `${str.slice(0, 13)} ...`
      return cutStr
    }
    return str
  }

  const handleDeleteFile = () => {
    onChange(index)
  }

  const commonContent = (
    <div>
      <div className={s.name}>
        {cutString(file?.name)}
        {!hideDeleteButton && (
          <div className={s.icon} onClick={handleDeleteFile}>
            <i>
              <RiCloseFill />
            </i>
          </div>
        )}
      </div>
      <a className={s.tagA} href={file?.url} target="_blank" rel="noreferrer">
        <img
          src={file?.format === 'jpg' || file?.format === 'png' || file?.format === 'jpeg' ? file?.url : defaultImage}
          alt={file?.name}
        />
      </a>
    </div>
  )

  if (name) {
    return <Controller name={name} render={() => commonContent} />
  } else {
    return commonContent
  }
}

export default AttachedImageField

AttachedImageField.propTypes = {
  file: PropTypes.object.isRequired,
}

AttachedImageField.defaultProps = {}
