import {
  CALENDAR_ENTITIES_UPDATED,
  CHANGE_CALENDAR_VIEW,
  ENTITY_SELECTED,
  FILTERED_ENTITIES,
  RESET_SELECTED_ENTITY,
  SELECTED_RANGE,
} from '@/store/changeViewCalendar'

const setCalendarEntities = (payload) => async (dispatch) => {
  try {
    dispatch(CALENDAR_ENTITIES_UPDATED(payload))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedEntity = (entity) => async (dispatch) => {
  try {
    dispatch(ENTITY_SELECTED(entity))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedEntity = () => async (dispatch) => {
  try {
    dispatch(RESET_SELECTED_ENTITY())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const changeCalendarView = (payload) => async (dispatch) => {
  try {
    dispatch(CHANGE_CALENDAR_VIEW(payload))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedRange = (payload) => async (dispatch) => {
  try {
    dispatch(SELECTED_RANGE(payload))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const setFilteredEntities = (payload) => async (dispatch) => {
  try {
    dispatch(FILTERED_ENTITIES(payload))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export {
  changeCalendarView,
  resetSelectedEntity,
  selectedEntity,
  selectedRange,
  setCalendarEntities,
  setFilteredEntities,
}
