export const replaceHTMLTags = (text) => {
  if (text?.includes('<p>')) {
    text = text?.replaceAll('<p>', '').replaceAll('</p>', '')
  }
  if (text?.includes('<strong>')) {
    text = text?.replaceAll('<strong>', '*').replaceAll('</strong>', '*')
  }
  if (text?.includes('<br>')) {
    text = text?.replaceAll('<br>', '\n')
  }
  if (text?.includes('<i>')) {
    text = text?.replaceAll('<i>', '_').replaceAll('</i>', '_')
  }

  return text
}
