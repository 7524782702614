import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchAllProducts = async (params) => {
  const route = `api/products`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postProduct = async (data) => {
  const route = `api/products`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putProduct = async (data) => {
  const route = `api/products`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteProduct = async (id) => {
  const route = `api/products/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const fetchSingleProduct = async (id) => {
  const route = `api/products/${id}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { deleteProduct, fetchAllProducts, fetchSingleProduct, postProduct, putProduct }
