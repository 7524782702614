import React from 'react'
import { Grid, MenuItem, Select, TextField } from '@mui/material'

import s from './styles.module.scss'

const UsersSearchBar = ({ inputSearch, setInputSearch, roleSelected, setRoleSelected }) => {
  return (
    <Grid
      container
      spacing={1}
      className={s.mainContainer}
      sx={{
        '& .MuiGrid-root.MuiGrid-item': {
          padding: '0px',
          paddingTop: '5px',
        },
        '& .MuiInput-underline:before': {
          borderBottomColor: '#ffffff8f',
        },

        '& .MuiInput-underline:after': {
          borderBottomColor: '#f5a645',
        },
      }}
    >
      <Grid item xs={4} className={s.searchBarContainer}>
        <TextField
          sx={{ input: { color: '#fff', fontSize: '14px' } }}
          className={s.searchInput}
          id="search"
          size="small"
          autoComplete="off"
          label="Buscar usuario"
          variant="standard"
          autoFocus
          fullWidth
          type="text"
          value={inputSearch}
          onChange={(e) => setInputSearch(e.target.value)}
          InputLabelProps={{
            style: { color: '#fff', fontSize: '14px' },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} className={s.containerFilterSelects}>
        <Select
          variant="standard"
          value={roleSelected}
          onChange={(e) => setRoleSelected(e.target.value)}
          sx={{
            color: 'white',
            fontSize: '14px',
            width: '150px',
            '.MuiSvgIcon-root ': {
              fill: 'white !important',
            },
          }}
        >
          <MenuItem value={'-'}>Todos los roles</MenuItem>
          <MenuItem value={'centre'}>Rol centro</MenuItem>
          <MenuItem value={'doctor'}>Rol profesional</MenuItem>
          <MenuItem value={'secretary'}>Rol asistente</MenuItem>
          <MenuItem value={'patient'}>Rol paciente</MenuItem>
          <MenuItem value={'no-role'}>Sin roles</MenuItem>
        </Select>
      </Grid>
    </Grid>
  )
}

export default UsersSearchBar
