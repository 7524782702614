import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SELECTED_TEMPLATE } from '@/store/wappMessageTemplates'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { replaceHTMLTags } from '@/utils/functions/replaceHTMLTags'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Button, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import s from './styles.module.scss'

const BirthdayMessageTable = ({ setOpen }) => {
  const dispatch = useDispatch()
  const [rowId, setRowId] = useState('')

  const { allTemplates, isLoading, paginationData } = useSelector((state) => state.wappMessageTemplates)

  const { limit, totalDocs, page } = paginationData || {}

  const handleClick = async (data) => {
    await dispatch(SELECTED_TEMPLATE(data))
    setOpen(true)
  }

  const handleCellClick = (params, event) => {
    handleClick(params?.row?.raw)
  }

  const stylesColumns = {
    headerClassName: 'header',
    flex: 1,
    align: 'center',
    headerAlign: 'flex-start',
  }

  const columns = [
    {
      field: 'id',
      width: 90,
      flex: 1,
    },

    {
      field: 'name',
      headerName: 'Nombre',
      minWidth: 200,
      flex: 1,
    },

    {
      field: 'body',
      headerName: 'Mensaje personalizado',
      minWidth: 800,
      maxWidth: 800,
      ...stylesColumns,
    },

    {
      field: 'raw',
      headerName: '',
      minWidth: 150,
      maxWidth: 180,
      ...stylesColumns,
      renderCell: (params) => (
        <Tooltip title="Editar" placement="top">
          <Button
            onClick={() => handleClick(params?.value)}
            className={rowId === params.value._id ? s.rowHoverButton : s.rowButton}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            <EditOutlinedIcon />
          </Button>
        </Tooltip>
      ),
    },
  ]

  const rows = allTemplates?.map((entry) => {
    return {
      id: entry?._id,
      name: capitalizeFirstLetters(entry?.name),
      body: replaceHTMLTags(entry?.body),
      raw: entry,
    }
  })

  const handleRowOver = (e) => {
    const rowId = e.currentTarget.dataset.id
    const row = rows.find((el) => el.id === rowId)
    setRowId(row.id)
  }

  const handlePaginationChange = ({ page, pageSize }) => {
    // dispatch(handlePaginationChangeHCFragments(page + 1, pageSize, searchText))
  }

  return (
    <Box
      sx={{
        px: 0,
        '& .header': {
          fontSize: '14px',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
          background: '#fff',
        },
      }}
    >
      <DataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          pagination: {
            paginationModel: {
              pageSize: limit,
            },
          },
        }}
        rows={rows || []}
        rowCount={totalDocs || 0}
        loading={isLoading}
        paginationMode="server"
        columns={columns}
        pagination
        onCellClick={(params, event) => handleCellClick(params, event)}
        onPaginationModelChange={handlePaginationChange}
        pageSizeOptions={[30]}
        disableRowSelectionOnClick={true}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        slotProps={{
          pagination: {
            labelRowsPerPage: 'Entradas por página:',
            labelDisplayedRows: () =>
              `${(page - 1) * limit + 1} - ${page * limit > totalDocs ? totalDocs : page * limit} de ${totalDocs}`,
          },
          row: {
            onMouseEnter: handleRowOver,
          },
        }}
        sx={{
          fontSize: '13px',
          marginBottom: '20px',
          background: '#fff',
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell ': {
            display: 'flex',
            justifyContent: 'flex-start',
          },
        }}
        rowHeight={45}
        autoHeight={true}
      />
    </Box>
  )
}

export default BirthdayMessageTable
