import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'doctorProfile',
  initialState: {
    doctorProfile: false,
  },
  reducers: {
    GET_DOCTOR_PROFILE: (state, { payload }) => {
      state.doctorProfile = payload
    },
  },
})

export const { GET_DOCTOR_PROFILE } = slice.actions
export default slice.reducer
