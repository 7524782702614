// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Layout1 from '@/layouts/Layout1'
import BaseSideBar from '@/layouts/Layout1/components/BaseSideBar'
import { setPageLoading } from '@/store/app/actions'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'

import MiniCalendar from './components/MiniCalendar/MiniCalendar'
import Body from './Body'
import { useCalendar } from './CalendarProvider'

export function Layout() {
  const dispatch = useDispatch()
  const { filteredEntityText, setFilteredEntityText } = useCalendar()
  const refCreateAgendaButton = useRef()

  const [openToCreateNewAgenda, setOpenToCreateNewAgenda] = useState(false)
  const [dataMercadoPagoSubscription, setDataMercadoPagoSubscription] = useState(null)

  useEffect(() => {
    dispatch(setPageLoading(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout1
      navbar
      leftSidebar={
        <BaseSideBar
          inputLabel="Filtrar agenda"
          inputValue={filteredEntityText}
          inputOnChange={(e) => {
            setFilteredEntityText(e.target.value)
          }}
          content={
            <MiniCalendar
              setOpenToCreateNewAgenda={setOpenToCreateNewAgenda}
              dataMercadoPagoSubscription={dataMercadoPagoSubscription}
              refCreateAgendaButton={refCreateAgendaButton}
            />
          }
          agendaView={true}
        />
      }
    >
      <Body
        inputLabel="Filtrar agenda"
        inputValue={filteredEntityText}
        inputOnChange={(e) => {
          mxp.track(mxp.events.agenda.filter.changed)
          setFilteredEntityText(e.target.value)
        }}
        openToCreateNewAgenda={openToCreateNewAgenda}
        setOpenToCreateNewAgenda={setOpenToCreateNewAgenda}
        setDataMercadoPagoSubscription={setDataMercadoPagoSubscription}
        refCreateAgendaButton={refCreateAgendaButton}
      />
    </Layout1>
  )
}
