import React from 'react'
import { useSelector } from 'react-redux'

import FormInfoSubscription from './components/formInfoSubscription'

import s from './styles.module.scss'

const InfoSubscription = () => {
  const centreAdminDetail = useSelector((state) => state.centres.centreAdminDetail)

  return (
    <div className={s.mainContainerInfoSubscription}>
      <FormInfoSubscription formValues={centreAdminDetail} />
    </div>
  )
}

export default InfoSubscription
