import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import CaluInput from '@/components/styledComponents/CaluInput'
import CaluRawDatePicker from '@/components/styledComponents/CaluRawDatePicker'
import { CaluSelect } from '@/components/styledComponents/CaluSelect'
import { setAllCashboxItemsCombinedController } from '@/controllers/cashboxItems'
import { typeOfEntries } from '@/helpers/constants'
import useDebounce from '@/hooks/useDebounce'
import useValidateURLId from '@/hooks/useValidateURLId'
import { SELECTED_ENTRY_TYPE, SELECTED_SERVICE_IN_FILTER } from '@/store/cashboxItems'
import {
  cashLoadingAction,
  getAllCashboxEntries,
  getAllCashboxItemsCombined,
  getAllCashboxItemsCombinedTotals,
  selectedCashboxItem as selectedCashboxItemAction,
  setLastPaymentSearch,
} from '@/store/cashboxItems/actions'
import { clearPatientCentres, getPatientsCentreByInputSearch } from '@/store/patientCentres/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { handleCalculateTurnAmount } from '@/utils/functions/handleCalculateTurnAmount'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { Autocomplete, Grid } from '@mui/material'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const HeaderSearchBar = ({
  billingTab,
  setOpenAddEntry,
  inputSearch,
  setInputSearch,
  loadingPatients,
  setLoadingPatients,
  patientValue,
  setPatientValue,
}) => {
  const dispatch = useDispatch()
  const centreID = useValidateURLId()
  const { t } = useTranslation('global')
  const DELAY_TIME = 300

  const { patientCentres } = useSelector((state) => state.patientCentres)
  const { allServices } = useSelector((state) => state.services)
  const { allCashboxItemsCombined, lastPaymentSearch, selectedCashboxItem } = useSelector((state) => state.cashboxItems)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const [startDate, setStartDate] = useState(
    dayjs(lastPaymentSearch?.from || new Date())
      .startOf('week')
      .format(),
  )
  const [finishDate, setFinishDate] = useState(
    lastPaymentSearch?.to
      ? dayjs(lastPaymentSearch?.to).endOf('day').format()
      : dayjs(startDate).add(7, 'day').endOf('day').format(),
  )

  const [selectedTypeOfEntry, setSelectedTypeOfEntry] = useState('-')
  const [initialStateTotalEntries, setInitialStateTotalEntries] = useState([])
  const [selectedService, setSelectedService] = useState('-')

  const inputDebounceValue = useDebounce(inputSearch, DELAY_TIME)

  useEffect(() => {
    const searchPatient = async () => {
      setPatientValue(null)
      if (inputDebounceValue && inputDebounceValue.length >= 2) {
        const params = {
          centre: centreID,
          search: inputDebounceValue,
        }
        setLoadingPatients(true)
        await dispatch(getPatientsCentreByInputSearch(params))
        setLoadingPatients(false)
      } else {
        dispatch(clearPatientCentres())
      }
    }
    searchPatient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDebounceValue])

  const setInit = async (params) => {
    dispatch(cashLoadingAction(true))

    const fetchAllCashboxItemsCombined = await setAllCashboxItemsCombinedController(params)
    dispatch(getAllCashboxItemsCombined(fetchAllCashboxItemsCombined))

    params.totals = true

    const fetchAllCashboxItemsCombinedTotals = await setAllCashboxItemsCombinedController(params)
    dispatch(getAllCashboxItemsCombinedTotals(fetchAllCashboxItemsCombinedTotals))
    dispatch(cashLoadingAction(false))
  }

  useEffect(() => {
    let params = {
      centre: centreID,
      start: toCustomTz(startDate, 'utc', true),
      finish: toCustomTz(finishDate, 'utc', true),
      service: selectedService === '-' ? null : selectedService,
      entryType: selectedTypeOfEntry === '-' ? null : selectedTypeOfEntry,
      patientCentre: !patientValue ? null : patientValue?._id,
      limit: 50,
    }
    if (!billingTab) {
      params = {
        ...params,
        madePayments: true,
      }
    }

    dispatch(
      setLastPaymentSearch({
        from: startDate,
        to: finishDate,
      }),
    )

    setInit(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, finishDate, selectedService, selectedTypeOfEntry, patientValue])

  const valueOfPatient = (e, patient) => {
    setPatientValue(patient)
  }

  // Add totalPayments in cashboxItems
  const calculateTurnPayments = (turn) => {
    let auxTurn = JSON.parse(JSON.stringify(turn))
    let totalPayments = 0
    if (auxTurn.paymentHistory) {
      auxTurn.paymentHistory.forEach((p) => {
        totalPayments += p.amount
      })
    }

    auxTurn = {
      ...auxTurn,
      totalPayments,
    }

    return auxTurn
  }

  // Add amount in turns
  const addAmountProp = (turn) => {
    let auxTurn = JSON.parse(JSON.stringify(turn))

    if (!auxTurn.amount) {
      auxTurn = {
        ...auxTurn,
        amount: handleCalculateTurnAmount(turn),
      }
    }
    return auxTurn
  }

  const buildCashboxEntries = () => {
    if (billingTab) {
      let totalEntries = allCashboxItemsCombined?.map((elem) => {
        if (elem?.docType === 'turn') return addAmountProp(elem)
        if (elem?.docType === 'cashboxItem') return calculateTurnPayments(elem)
        return elem
      })

      let parsedEntries = totalEntries?.map((elem) => {
        return {
          id: elem?._id,
          type: elem?.type || 'income',
          date: elem?.treatment
            ? toCustomTz(elem?.creationDate, 'utc', true, 'YYYY-MM-DD')
            : elem?.startHour
              ? toCustomTz(elem?.startHour, undefined, false, 'YYYY-MM-DD')
              : toCustomTz(elem?.creationDate, undefined, true, 'YYYY-MM-DD'),
          hour: elem?.treatment
            ? toCustomTz(elem?.creationDate, 'utc', true, 'HH:mm')
            : elem?.startHour
              ? toCustomTz(elem?.startHour, undefined, true, 'HH:mm')
              : toCustomTz(elem?.date, undefined, true, 'HH:mm'),
          patientOrProvider: elem?.patientCentre?.lastname
            ? `${elem?.patientCentre?.lastname?.toUpperCase()}, ${elem?.patientCentre?.firstname?.toUpperCase()}`
            : elem?.centreProvider?.name
              ? elem?.centreProvider?.name?.toUpperCase()
              : '-',
          description: elem?.treatment
            ? `Trat: ${elem?.treatment?.name}`
            : elem?.extraServices?.length === 1
              ? (elem?.service?.name || 'consulta') + ', ' + elem?.extraServices[0].name
              : elem?.extraServices?.length > 1
                ? elem?.extraServices.reduce(
                    (previous, current) => previous + ', ' + current?.name,
                    elem?.service?.name || 'consulta',
                  )
                : elem?.service
                  ? elem?.service?.name
                  : elem?.description
                    ? elem?.description
                    : 'consulta',
          amount:
            elem?.docType === 'treatment'
              ? Number(elem?.price) || 0
              : elem?.type?.includes('outcome')
                ? elem?.amount * -1
                : elem?.amount,
          totalPayments:
            elem?.docType === 'treatment'
              ? Number(elem?.paidPrice) || 0
              : elem?.type?.includes('outcome')
                ? elem?.totalPayments * -1
                : elem?.totalPayments,
          debt: elem?.type?.includes('outcome')
            ? (elem?.amount - elem?.totalPayments) * -1
            : elem?.amount - elem?.totalPayments,
          category: elem?.state ? 'Turnos' : 'Otros',
          paymentMethod: elem?.paymentMethod || '',
          raw: elem,
        }
      })
      setInitialStateTotalEntries(parsedEntries)
      dispatch(getAllCashboxEntries(parsedEntries))
    } else {
      let totalEntries = []
      let auxTotalEntries = allCashboxItemsCombined?.length ? [...allCashboxItemsCombined] : []

      auxTotalEntries.forEach((elem) => {
        elem?.paymentHistory?.forEach((i) => {
          let partialData = {
            id: i?._id,
            type: elem?.type || 'income',
            date: toCustomTz(i?.date, undefined, true, 'YYYY-MM-DD'),
            hour: toCustomTz(i?.date, undefined, true, 'HH:mm'),
            patientOrProvider: elem?.patientCentre?.lastname
              ? `${elem?.patientCentre?.lastname?.toUpperCase()}, ${elem?.patientCentre?.firstname?.toUpperCase()}`
              : elem?.centreProvider?.name
                ? elem?.centreProvider?.name?.toUpperCase()
                : '-',
            description:
              elem?.extraServices?.length > 0
                ? elem?.extraServices.reduce((previous, current) => previous + ', ' + current.name, elem?.service?.name)
                : elem?.service
                  ? elem?.service?.name
                  : elem?.description
                    ? elem?.description
                    : 'consulta',
            amount: elem?.type?.includes('outcome') ? i?.amount * -1 : i?.amount,
            paymentMethod: i?.method,
            category: elem?.state ? 'Turnos' : 'Otros',
            raw: elem,
          }
          totalEntries.push(partialData)
        })
      })
      setInitialStateTotalEntries(totalEntries)
      dispatch(getAllCashboxEntries(totalEntries))
    }
  }

  useEffect(() => {
    buildCashboxEntries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCashboxItemsCombined])

  const handleSetStartDate = (newDate) => {
    if (dayjs(newDate).startOf('day').format() === 'Invalid Date') return
    setStartDate(dayjs(newDate).startOf('day').format())
    if (dayjs(newDate).startOf('day').format('DD-MM-YYYY') > dayjs(finishDate).startOf('day').format('DD-MM-YYYY')) {
      setFinishDate(dayjs(newDate).add(1, 'day').endOf('day').format())
    }
  }

  const handleSetFinishDate = (newDate) => {
    if (dayjs(newDate).endOf('day').format() === 'Invalid Date') return
    setFinishDate(dayjs(newDate).endOf('day').format())
  }

  const handleChangeTypeOfEntry = (e) => {
    setSelectedTypeOfEntry(e.target.value)
    dispatch(SELECTED_ENTRY_TYPE(e.target.value))
    let selectedValue = e.target.value
    let filteredEntries

    if (selectedValue === 'income') {
      filteredEntries = initialStateTotalEntries?.filter((entry) => entry.type === 'income')
      dispatch(getAllCashboxEntries(filteredEntries))
      return
    }
    if (selectedValue === 'outcome') {
      filteredEntries = initialStateTotalEntries?.filter((entry) => entry?.type?.includes('outcome'))
      dispatch(getAllCashboxEntries(filteredEntries))
      return
    }
    if (selectedValue === '-') {
      dispatch(getAllCashboxEntries(initialStateTotalEntries))
      return
    }
  }

  const handleChangeServiceSelected = (e) => {
    setSelectedService(e.target.value)
    dispatch(SELECTED_SERVICE_IN_FILTER(e.target.value))
  }

  const addNewEntry = () => {
    if (selectedCashboxItem !== null) {
      dispatch(selectedCashboxItemAction(null))
    }
    setOpenAddEntry(true)
  }

  return (
    <Grid
      container
      className={s.mainContainer}
      sx={{
        '& .MuiInput-underline:after': {
          borderBottomColor: '#4C5EFE', // Solid underline on focus
        },
      }}
    >
      <Grid item xs={12} lg={3}>
        <Autocomplete
          fullWidth
          freeSolo
          loading={loadingPatients}
          loadingText="Cargando..."
          disablePortal
          size="small"
          id="combo-box-demo"
          disableClearable
          noOptionsText="No se encontraron resultados"
          onChange={valueOfPatient}
          options={patientCentres?.map((option) => option)}
          getOptionLabel={(option) =>
            option?.dni?.length < 15
              ? `${option?.lastname?.toUpperCase()}, ${option?.firstname?.toUpperCase()} (DNI: ${option?.dni})`
              : `${option?.lastname?.toUpperCase()}, ${option?.firstname?.toUpperCase()}`
          }
          isOptionEqualToValue={(option) => option._id}
          renderInput={(params) => (
            <CaluInput
              {...params}
              id="search"
              type="text"
              autoComplete="off"
              labelText={`Buscar ${t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`)}`}
              value={inputSearch}
              onChange={(e) => setInputSearch(e.target.value)}
              className={s.searchBarContainer}
            />
          )}
        />
      </Grid>
      <Grid item container spacing={1} xs={12} lg={7} className={s.containerButtons}>
        <Grid item xs={12} lg={3}>
          <CaluRawDatePicker labelText="Desde" value={startDate} onChange={handleSetStartDate} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CaluRawDatePicker
            labelText="Hasta"
            value={finishDate}
            onChange={handleSetFinishDate}
            minDate={dayjs(startDate).add(1, 'day')}
            disableHighlightToday={true}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CaluSelect
            labelText="Tipo de entradas"
            onChange={handleChangeTypeOfEntry}
            firstOptionValue={'-'}
            value={selectedTypeOfEntry}
            items={typeOfEntries}
            className={s.inputsSelect}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CaluSelect
            labelText="Servicios"
            value={selectedService}
            onChange={handleChangeServiceSelected}
            firstOptionValue={'-'}
            firstOptionText={'Todos los servicios'}
            items={allServices}
            className={s.inputsSelect}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={2} className={s.contBtnAddAndExcel}>
        <CaluButton color="primary" size="large" type="add" onClick={addNewEntry}>
          Crear entrada
        </CaluButton>
      </Grid>
    </Grid>
  )
}

export default HeaderSearchBar
