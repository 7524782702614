import React, { useState } from 'react'
import { useController } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { AlertDialogSlide } from '@/components/AlertDialogSlide'
import { AutoCompleteCityField } from '@/components/Form/customFields/AutoCompleteCityField'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { updateDoctorProfile } from '@/store/doctorProfile/actions'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { showToast } from '@/utils/toast'
import { Button, Grid } from '@mui/material'

import dayjs from 'dayjs'
import WorkingHours from '../workingHours'

import s from './styles.module.scss'

const PlaceToWork = ({ doctorProfile, workPlace, index }) => {
  const dispatch = useDispatch()
  const [openPopUp, setOpenPopUp] = useState(false)
  const [deletePlaceToWorkAlert, setDeletePlaceToWorkAlert] = useState(false)
  const { field: fieldAddress } = useController({ name: `workPlaces[${index}][address]` })
  const { field: fieldCity } = useController({ name: `workPlaces[${index}][city]` })
  const workPlaceIndex = index + 1

  const deletePlaceToWork = () => {
    const deleteWorkPlace = doctorProfile.workPlaces.filter((work) => work._id !== workPlace._id)
    const data = {
      ...doctorProfile,
      workPlaces: deleteWorkPlace,
    }

    dispatch(updateDoctorProfile(data))
  }

  const attentionSchedulePopUp = () => {
    if (fieldAddress.value && fieldCity.value) {
      setOpenPopUp(true)
    } else {
      showToast('Primero debes completar los campos de dirección y ciudad', 'error')
    }
  }

  const showWorkDays = (work) => {
    const workingDays = work.days.map((w) => dayjs().day(w).format('dddd'))
    const from = toCustomTz(work?.startHour, undefined, true, 'HH:mm')
    const to = toCustomTz(work?.finishHour, undefined, true, 'HH:mm')
    return `${workingDays.toString().replace(/,/g, ', ')}  de ${from} a ${to}`
  }

  return (
    <Grid container className={s.gridContainer}>
      <Grid item sm={12} className={s.gridItem}>
        <div>LUGAR DE TRABAJO {workPlaceIndex}</div>
        <Button onClick={() => setDeletePlaceToWorkAlert(true)} className={s.deleteButton}>
          x
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomField label="Dirección *" name={`workPlaces[${index}][address]`} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutoCompleteCityField label="Ciudad" name={`workPlaces[${index}][city]`} fullWidth />
      </Grid>

      <Button onClick={attentionSchedulePopUp} className={s.attentionScheduleButton} variant="outlined">
        HORARIOS DE ATENCIÓN
      </Button>
      <Grid item xs={12}>
        <ul>
          {workPlace?.businessHours?.map((work) => (
            <li className={s.businessHoursList}>{showWorkDays(work)}</li>
          ))}
        </ul>
      </Grid>

      {deletePlaceToWorkAlert && (
        <AlertDialogSlide
          open={deletePlaceToWorkAlert}
          setOpen={setDeletePlaceToWorkAlert}
          title="Eliminar lugar de trabajo"
          textContent={'¿Realmente quiere eliminar el lugar de trabajo?'}
          textBackButton="Cancelar"
          textConfirmationButton="Eliminar"
          onClose={deletePlaceToWork}
        />
      )}
      {openPopUp && (
        <WorkingHours
          workPlace={workPlace}
          open={openPopUp}
          setOpen={setOpenPopUp}
          doctorProfile={doctorProfile}
          index={index}
        />
      )}
    </Grid>
  )
}

export default PlaceToWork
