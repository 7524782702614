import { fetchPrescriptions } from '@/services/prescriptions'

export const setAllPrescriptionsController = async (params) => {
  try {
    const fetchAllPrescriptions = await fetchPrescriptions(params)
    return fetchAllPrescriptions
  } catch (error) {
    console.log(error.message)
    return error
  }
}
