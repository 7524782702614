import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import LoadingCalu from '@/components/LoadingCalu/index.jsx'
import NavBar from '@/components/NavBar/index.jsx'
import NonexistentTurn from '@/components/nonexistentTurn/index.jsx'
import CaluButton from '@/components/styledComponents/CaluButton/index.jsx'
import CardInformation from '@/pages/LandingCentreTargetPage/components/cardDoctorCentreTarget/components/cardInformation'
import LandingHeader from '@/pages/LandingCentreTargetPage/components/landingHeader/index.jsx'
import { getCentreTargetAvailabilities } from '@/store/centreAvailability/actions'
import { getCentreTarget } from '@/store/centres/actions'
import { cancelTurnTimeToReprogram, getSingleTurn } from '@/store/turns/actions'
import { DateRange } from '@mui/icons-material'
import { Grid } from '@mui/material'

import dayjs from 'dayjs'
import AvialableUndatedTurn from './components/availableUndatedTurn/index.jsx'
import MesaggeConfirmedSetDateTurn from './components/mesaggeConfirmedSetDateTurn/index.jsx'
import ReprogramTurnDate from './components/reprogramTurnDate'

import s from './styles.module.scss'

const UndatedTurn = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const { id: turnId } = useParams()
  const singleTurn = useSelector((state) => state.turns.selectedTurn)
  const [showConfirmedTurn, setShowConfirmedTurn] = useState(false)
  const [reprogramData, setReprogramData] = useState(null)
  const [startHour, setStartHour] = useState(null)
  const [loading, setLoading] = useState(false)
  const nonExistentTurn = useSelector((state) => state.app.error404)
  const [errorMsgToRescheduleTurn, setErrorMsgToRescheduleTurn] = useState(null)
  const centre = useSelector((state) => state.centres.centreTarget)

  let entity = singleTurn ? (singleTurn.machine ? singleTurn.machine : singleTurn.doctorCentre) : []
  let serviceType = singleTurn?.service ? 'practices' : 'consultations'
  let consultationType = singleTurn?.firstTime ? 'firstConsultation' : 'consultation'
  let serviceId = singleTurn?.service ? singleTurn.service?._id : consultationType

  const entityAvailability = useSelector(
    (state) => state.centreAvailability?.[serviceType]?.[serviceId]?.availability[entity?._id],
  )

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (singleTurn) {
      checkStartHourSingleTurn()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleTurn])

  useEffect(() => {
    if (reprogramData) {
      handleReprogramData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reprogramData])

  const setInit = async () => {
    const hideAlert = true
    setLoading(true)
    await dispatch(getSingleTurn(turnId, false, hideAlert))
    setStartHour(null)
    setLoading(false)
  }

  const checkStartHourSingleTurn = async () => {
    if (singleTurn && !singleTurn.startHour) {
      await getEntityAvailabilities()
      await dispatch(getCentreTarget(singleTurn?.centre?._id || singleTurn?.centre))
      setStartHour(null)
    }

    if (singleTurn && singleTurn.startHour) {
      dispatch(getCentreTargetAvailabilities(singleTurn?.centre?._id || singleTurn?.centre))
      dispatch(getCentreTarget(singleTurn?.centre?._id || singleTurn?.centre))
      const cancel = await dispatch(cancelTurnTimeToReprogram(singleTurn._id))
      setReprogramData(cancel)
      setStartHour(singleTurn.startHour)
      setLoading(false)
    }
  }

  const handleReprogramData = () => {
    let now = dayjs().utc('Z').format()
    let startHour = dayjs(reprogramData.startHour).utc().format()
    let hourToCancelTurn = reprogramData?.hoursToCancelTurn
    let diffHours = dayjs(startHour).diff(now, 'hour')

    if (diffHours < 0) {
      setErrorMsgToRescheduleTurn(`Ya no puedes modificar la fecha de su ${t('turnLabel.s')} por que su fecha ya pasó`)
      return
    }

    if (diffHours < hourToCancelTurn) {
      setErrorMsgToRescheduleTurn(
        `Ya no puedes modificar la fecha de su ${t('turnLabel.s')} por que faltan menos de ` +
          hourToCancelTurn +
          ' horas para el mismo',
      )
      return
    }

    setErrorMsgToRescheduleTurn(null)
  }

  const getEntityAvailabilities = async () => {
    let service
    if (!singleTurn.service && singleTurn.firstTime) {
      service = { _id: 'firstConsultation', name: 'Primera consulta' }
    }

    if (!singleTurn.service && !singleTurn.firstTime) {
      service = { _id: 'consultation', name: 'Consulta' }
    }

    if (singleTurn.service) service = singleTurn.service

    const aux = {
      entityId: entity?._id,
      entityType: singleTurn.machine ? 'machine' : 'doctorCentre',
      selectedService: service,
    }

    await dispatch(getCentreTargetAvailabilities(singleTurn?.centre?._id || singleTurn?.centre, aux))
    setLoading(false)
  }

  if (loading) {
    return <LoadingCalu />
  }

  return (
    <div>
      <NavBar />

      {!nonExistentTurn ? (
        <div className={s.divContainer}>
          {centre && startHour === null && showConfirmedTurn === false && (
            <>
              {entityAvailability ? (
                <div className={s.reprogramTurnContent}>
                  <LandingHeader centre={centre} />
                  <div className={s.turnsAvailable}>
                    <div className={s.cardDetailDoctorCentre}>
                      <div>
                        <CardInformation entity={entity} />
                      </div>

                      <div>
                        <AvialableUndatedTurn
                          entity={entity}
                          setShowConfirmedTurn={setShowConfirmedTurn}
                          entityAvailability={entityAvailability}
                          singleTurn={singleTurn}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={s.noAvailabilityDiv}>
                  <div>
                    <DateRange className={s.icon} />
                  </div>
                  <h3 className={s.noAvailabilityText}>No hay disponibilidad online para el servicio seleccionado</h3>
                  <div className={s.div3}>{`Comunicate telefónicamente con ${centre.name}`}</div>
                  {centre.phones.length > 0 && <Grid>Telefono(s):</Grid>}
                  {centre.phones.length > 0 && (
                    <ul className={s.listUl}>
                      {centre.phones.map((n) => (
                        <li key={n}>{n}</li>
                      ))}
                    </ul>
                  )}

                  <CaluButton disabled={true} size="large">
                    Reprogramar
                  </CaluButton>
                </div>
              )}
            </>
          )}

          {startHour !== null && !showConfirmedTurn && (
            <div className={s.divItem}>
              <ReprogramTurnDate
                singleTurn={singleTurn}
                setStartHour={setStartHour}
                errorMsg={errorMsgToRescheduleTurn}
              />
            </div>
          )}

          {showConfirmedTurn && (
            <div className={s.divItem}>
              <MesaggeConfirmedSetDateTurn />
            </div>
          )}
        </div>
      ) : (
        <div className={s.divItem}>
          <NonexistentTurn />
        </div>
      )}
    </div>
  )
}

export default UndatedTurn
