const addExtraDiscount = (amountWithoutExtraDiscount, extraAmount) => {
  // $1000, $200 --> $1200
  if (!extraAmount?.value) return amountWithoutExtraDiscount

  let amount

  // $1000, +$200 --> $1200
  if (extraAmount?.type === 'plusCash') {
    amount = Number(amountWithoutExtraDiscount) + Number(extraAmount?.value)
  }

  // $1000, -$200 --> $800
  if (extraAmount?.type === 'minusCash') {
    amount = Number(amountWithoutExtraDiscount) - Number(extraAmount?.value)
  }

  // $1000, +10% --> $1100
  if (extraAmount?.type === 'plusPercentage') {
    amount = Math.round(Number(amountWithoutExtraDiscount) * (1 + Number(extraAmount?.value) / 100))
  }

  // $1000, -10% --> $900
  if (extraAmount?.type === 'minusPercentage') {
    amount = Math.round(Number(amountWithoutExtraDiscount) * (1 - Number(extraAmount?.value) / 100))
  }

  return amount
}

export { addExtraDiscount }
