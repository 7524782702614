import React from 'react'
import { useTranslation } from 'react-i18next'
import { SwitchField } from '@/components/Form/customFields/SwitchField'
import CaluButton from '@/components/styledComponents/CaluButton'
import { Grid } from '@mui/material'

import s from '../../../styles.module.scss'

export const FormContent = ({ isLoading = false }) => {
  const { t } = useTranslation('global')

  return (
    <div>
      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        <Grid item xs={12}>
          <h5>Links de pago Mercado Pago</h5>
        </Grid>
        <Grid item xs={12}>
          <SwitchField
            label={`Agregar link de pago a ${t('turnLabel.p')} creadas desde agenda`}
            name="addTurnPaymentLinkOnAgendaCreate"
          />
        </Grid>
        <Grid item xs={12}>
          <SwitchField
            label={`Agregar link de pago a ${t('turnLabel.p')} creadas desde Central de Citas Online`}
            name="addTurnPaymentLinkOnPatientCreate"
          />
        </Grid>
      </Grid>
      <div className={s.containerBtnSaveMPOptions}>
        <CaluButton color="primary" type="submit" size="medium" loading={isLoading}>
          Guardar
        </CaluButton>
      </div>
    </div>
  )
}
