import React from 'react'
import { FaCheck } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import CardMsgWithIcon from '@/components/CardMsgWithIcon'
import FormRegisterNewDoctorOrSecretary from '@/components/FormRegisterNewDoctorOrSecretary'
import { useUser } from '@/context/UserProvider'
import Layout1 from '@/layouts/Layout1'

import CustomErrorCard from './CustomErrorCard'

import s from './styles.module.scss'

const LinkDoctorOrSecretaryRegisterPage = () => {
  const { pathname } = useLocation()

  const { user, logout } = useUser()
  const { verificationStatus } = useSelector((state) => state.users)

  const role = pathname.includes('profesional') ? 'doctor' : 'secretary'
  const typeOfUser = pathname.includes('profesional') ? 'profesional' : 'secretaria'

  return (
    <Layout1 navbar isLoading={false}>
      {user ? (
        <div className={s.container}>
          <CustomErrorCard
            title="Error! Ya estás utilizando una cuenta"
            text1="Por favor desconectate de la cuenta actual para poder crear una nueva cuenta utilizando el email al que te invitaron"
            textButton="Salir de la cuenta actual"
            onClick={() => logout()}
          />
        </div>
      ) : verificationStatus === null ? (
        <div className={s.container}>
          <FormRegisterNewDoctorOrSecretary role={role} typeOfUser={typeOfUser} />
        </div>
      ) : verificationStatus === true ? (
        <div className={s.container}>
          <CardMsgWithIcon
            icon={<FaCheck />}
            iconClass="greenColor"
            title="Cuenta creada correctamente"
            text="Ya puedes acceder al sistema haciendo clic en INGRESAR"
            textButton="INGRESAR"
            path="/ingresar"
          />
        </div>
      ) : (
        <div className={s.container}>
          <CardMsgWithIcon
            icon={<IoClose />}
            title="Ups! Ocurrió un error"
            text="La ruta ingresada no es válida"
            textButton="volver al inicio"
            path="/"
          />
        </div>
      )}
    </Layout1>
  )
}

export default LinkDoctorOrSecretaryRegisterPage
