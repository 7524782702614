import * as React from 'react'
import CaluButton from '@/components/styledComponents/CaluButton'

import s from './styles.module.scss'

export default function CustomErrorCard({ title = '', text1 = '', textButton = 'Guardar', onClick = null }) {
  return (
    <div className={s.mainContainer}>
      <div className={s.containerCard}>
        <h3 className={s.title}>{title}</h3>
        <div className={s.containerTexts}>
          <p dangerouslySetInnerHTML={{ __html: text1 }} />
        </div>
        <div className={s.containerButton}>
          <CaluButton type="link" size="medium" onClick={onClick}>
            {textButton}
          </CaluButton>
        </div>
      </div>
    </div>
  )
}
