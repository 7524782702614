import React from 'react'
import { DataGrid } from '@mui/x-data-grid'

import dayjs from 'dayjs'

const Table = ({ allClinicHistories, fields }) => {
  const fieldsNames = fields.map((field) => field.name)

  //Columns
  const firstColumn = [{ field: 'visit', headerName: 'Fecha', width: 200, headerClassName: 'header' }]
  const columns = fields.map((field) => {
    return { field: `${field.name}`, headerName: `${field.name}`, width: 200, headerClassName: 'header' }
  })
  const totalColumns = [firstColumn, ...columns].flat()

  //Rows
  let filteredHC = []
  allClinicHistories.forEach((hc) =>
    hc.clinicHistoryFields.forEach((field) => {
      if (fieldsNames.includes(field.name)) {
        const auxObj = {
          ...field,
          visit: dayjs(hc?.visit).format('DD/MM/YYYY'),
          id: Math.random(),
        }

        auxObj[field.name] = field.value
        filteredHC.push(auxObj)
      }
    }),
  )

  return (
    <div>
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        rows={filteredHC}
        columns={totalColumns}
        pageSizeOptions={[5]}
        sx={{
          height: '400px',
          px: 3,
          '& .header': {
            fontSize: '14px',
          },
        }}
      />
    </div>
  )
}

export default Table
