import { fetchCashboxItems, fetchCashboxItemsCombined } from '@/services/cashBoxItems'

export const setAllCashboxItemsCombinedController = async (params) => {
  try {
    if (params?.totals || params?.downloadData) {
      const fetchAllCashboxItemsCombinedTotals = await fetchCashboxItemsCombined(params)
      return fetchAllCashboxItemsCombinedTotals
    } else {
      const { docs: fetchAllCashboxItemsCombined, ...paginationData } = await fetchCashboxItemsCombined(params)
      return { fetchAllCashboxItemsCombined, paginationData }
    }
  } catch (error) {
    console.log(error.message)
    return error
  }
}

export const setCashboxItemsTotalsController = async (params) => {
  try {
    const fetchCashboxItemsTotals = await fetchCashboxItems(params)
    return fetchCashboxItemsTotals
  } catch (error) {
    console.log(error.message)
    return error
  }
}
