import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { selectedCentreProvider } from '@/store/centreProvider/actions'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Button, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import s from '../styles.module.scss'

const ManagementTable = ({ setOpenPopUp, providersFiltered }) => {
  const dispatch = useDispatch()

  const [pageSize, setPageSize] = useState(20)

  const handleClick = (data) => {
    setOpenPopUp(true)
    dispatch(selectedCentreProvider(data))
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Proveedor',
      minWidth: 300,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'cuit',
      headerName: 'Cuit',
      minWidth: 200,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'note',
      headerName: 'Nota',
      minWidth: 300,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'button',
      headerName: '',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header',
      renderCell: (params) => (
        <Tooltip title="Editar">
          <Button onClick={() => handleClick(params.value)} className={s.rowButton} tabIndex={params.hasFocus ? 0 : -1}>
            <EditOutlinedIcon />
          </Button>
        </Tooltip>
      ),
    },
  ]

  const rows = providersFiltered?.map((prov) => {
    return {
      id: prov?._id,
      _id: prov?._id,
      name: prov?.name?.toUpperCase(),
      cuit: prov?.cuit,
      note: prov?.note || '',
      button: prov,
    }
  })

  const handlePaginationChange = ({ pageSize }) => {
    setPageSize(pageSize)
  }

  return (
    <div>
      <DataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          pagination: {
            paginationModel: {
              pageSize: pageSize,
            },
          },
        }}
        rows={rows || []}
        columns={columns}
        pagination
        onPaginationModelChange={handlePaginationChange}
        pageSizeOptions={[10, 20, 50, 100, 200]}
        slotProps={{
          pagination: {
            labelRowsPerPage: 'Entradas por página:',
            labelDisplayedRows: (props) => `${props.from} - ${props.to} de ${props.count}`,
          },
        }}
        disableRowSelectionOnClick={true}
        rowHeight={45}
        autoHeight={true}
        onCellClick={(params) => handleClick(params?.row)}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          px: 0,
          '& .header': {
            fontSize: '14px',
          },
          '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
        }}
      />
    </div>
  )
}

export default ManagementTable
