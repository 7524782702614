import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'centreProviders',
  initialState: {
    allCentreProviders: [],
    selectedProvider: null,
  },
  reducers: {
    FETCH_CENTRE_PROVIDERS: (state, { payload }) => {
      state.allCentreProviders = payload
    },
    CENTRE_PROVIDER_UPDATED: (state, { payload }) => {
      state.allCentreProviders = state.allCentreProviders.map((prov) => {
        if (prov._id === payload._id) {
          prov = payload
        }
        return prov
      })
    },
    CENTRE_PROVIDER_DELETE: (state, { payload }) => {
      state.allCentreProviders = state.allCentreProviders.filter((prov) => prov._id !== payload)
    },
    CENTRE_PROVIDER_CREATED: (state, { payload }) => {
      state.allCentreProviders = [...(state.allCentreProviders || []), payload]
    },
    CENTRE_PROVIDER_SELECTED: (state, { payload }) => {
      state.selectedProvider = payload
    },
    RESET_CENTRE_PROVIDER_SELECTED: (state, { payload }) => {
      state.selectedProvider = null
    },
  },
})

export const {
  FETCH_CENTRE_PROVIDERS,
  CENTRE_PROVIDER_UPDATED,
  CENTRE_PROVIDER_DELETE,
  CENTRE_PROVIDER_CREATED,
  CENTRE_PROVIDER_SELECTED,
  RESET_CENTRE_PROVIDER_SELECTED,
} = slice.actions
export default slice.reducer
