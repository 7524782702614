import React from 'react'
import { Controller } from 'react-hook-form'
import DividerSection from '@/components/DividerSection/index.jsx'
import { Grid, TextField } from '@mui/material'

import s from '../../styles.module.scss'
const InvoiceTurn = (props) => {
  return (
    <div>
      <DividerSection label="Factura/comprobante" />
      <Grid container>
        <Grid item xs={12}>
          <Controller
            name="invoice"
            control={props.control}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                type="text"
                label={`Nº de factura/comprobante`}
                variant="standard"
                className={s.inputWidth98}
              />
            )}
            defaultValue=""
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default InvoiceTurn
