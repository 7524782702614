import { ControlledMenu } from '@szhsin/react-menu'

import styled from 'styled-components'

import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

export const SControlledMenu = styled(ControlledMenu)`
  .contextMenu {
    font-size: 14px;
  }
`
