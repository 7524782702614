import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DebtAlert from '@/components/DebtAlert/DebtAlert'
import NoMercadoPagoConnectionAlert from '@/components/NoMercadoPagoConnectionAlert/NoMercadoPagoConnectionAlert'
import TagState from '@/components/TagState'
import { TextOverButtons } from '@/components/TextOverButtons'
import useCheckAvailableCreateAgendas from '@/hooks/useCheckAvailableCreateAgendas'
import { useCalendar } from '@/pages/AgendaPage/CalendarProvider'
import { SELECTED_CALENDAR_DATE } from '@/store/turns'
import { handleCheckDisabledDates } from '@/utils/functions/handleCheckDisabledDates'
import { removeHolidays } from '@/utils/functions/removeHolidays'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import { DateCalendar, PickersDay } from '@mui/x-date-pickers'

import dayjs from 'dayjs'

import s from './styles.module.scss'

export default function MiniCalendar({ refCreateAgendaButton, setOpenToCreateNewAgenda, dataMercadoPagoSubscription }) {
  const { selectedDate, setSelectedDate } = useCalendar()
  const dispatch = useDispatch()
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { filteredEntities, dailyView } = useSelector((state) => state.changeViewCalendar)
  const { daysMonthLoaded, allTurns } = useSelector((state) => state.turns)
  const { debt } = useSelector((state) => state.subscriptionPayments)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const [noMercadoPagoConnection, setNoMercadoPagoConnection] = useState(false)

  let centreHolidays = centreDetail?.holidays || []

  const { isCentre, isAdmin } = usersProfileConnected
  const { shouldCreateAgenda, remainingAgendas } = useCheckAvailableCreateAgendas()

  useEffect(() => {
    if (!dataMercadoPagoSubscription) return

    setNoMercadoPagoConnection(
      centreDetail?.centreConfiguration?.subscriptionPaymentType === 'mercadoPagoSubscription' &&
        (!dataMercadoPagoSubscription ||
          JSON.stringify(dataMercadoPagoSubscription) === '{}' ||
          dataMercadoPagoSubscription?.status === 'cancelled'),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMercadoPagoSubscription])

  const getEntityTurns = (entity) => {
    const entityTurns = allTurns?.filter((t) => [t?.doctorCentre?._id, t?.machine?._id].includes(entity._id))
    return entityTurns
  }

  const getCalendarDayClass = (day) => {
    if (filteredEntities?.length !== 1) return

    const currentDay = dayjs(day).format('YYYY-MM-DD')
    const currentDayCode = dayjs(day).day()
    const holidayParsedDates = centreHolidays?.map((elem) => dayjs(elem).utc().format('YYYY-MM-DD'))
    const filterHolidays = removeHolidays(daysMonthLoaded, holidayParsedDates)
    const isDayLoaded = filterHolidays.includes(currentDay)

    let takenTurnDuration = 0
    let dayIsEntityWorkTime = false
    let totalDayAvailability = 0

    const entityTurns = getEntityTurns(filteredEntities[0])
    const nonDuplicateStartHourTurns = []
    const seen = new Map()

    //elimina del array los turnos con el mismo startHour (sobre turno)
    for (const turn of entityTurns) {
      const startHour = turn?.startHour
      if (!seen.has(startHour)) {
        seen.set(startHour, true)
        nonDuplicateStartHourTurns.push(turn)
      }
    }

    nonDuplicateStartHourTurns?.filter((t) => {
      const turnDay = dayjs(t.startHour).format('YYYY-MM-DD')
      const validTurn = turnDay === currentDay
      if (validTurn) {
        takenTurnDuration += dayjs(t.finishHour).diff(dayjs(t.startHour), 'm')
      }
      return validTurn
    })

    filteredEntities[0]?.workTimes?.map((e) => {
      //weekly interval
      if (e?.weekDay && e?.weekDay?.code === currentDayCode) {
        if (e?.frequency?.code === 0) {
          if (!e.deferred) {
            dayIsEntityWorkTime = true
            totalDayAvailability += dayjs(e?.finishTime).diff(dayjs(e?.startTime), 'm')
          }

          // deferred
          if (e?.deferred && (e?.deferred?.from || e?.deferred?.to)) {
            let isValid = true
            let wFrom = e?.deferred?.from && dayjs(e?.deferred?.from).toISOString().slice(0, 10)
            let wTo = e?.deferred?.to && dayjs(e?.deferred?.to).toISOString().slice(0, 10)

            let isValidFrom = !wFrom
            if (wFrom && currentDay >= wFrom) {
              isValidFrom = true
            }

            let isValidTo = !wTo
            if (wTo && currentDay < wTo) {
              isValidTo = true
            }

            isValid = isValidFrom && isValidTo

            if (isValid) {
              dayIsEntityWorkTime = true
              totalDayAvailability += dayjs(e.finishTime).diff(dayjs(e.startTime), 'm')
            }
          }
        }

        // 15 days
        if (e?.frequency?.code === 1) {
          //uniqueDay = 1/12 (Fecha inicio intervalo cada 15 dias)
          const firstDay = dayjs(e?.frequency?.startDay).utc().format('YYYY-MM-DD')
          const maxWeeks = 60
          //Desde el 1/12 vamos 60 semanas para adelante y creamos 60 dias unicos
          let allUniqueDays = [firstDay]

          for (let i = 1; i < maxWeeks; i++) {
            const iDay = dayjs(firstDay)
              .add(14 * i, 'day')
              .format('YYYY-MM-DD')
            allUniqueDays.push(iDay)
          }

          if (allUniqueDays?.includes(currentDay)) {
            dayIsEntityWorkTime = true
            totalDayAvailability += dayjs(e.finishTime).diff(dayjs(e.startTime), 'm')
          }
        }
      }

      // uniq day interval
      if (e?.weekDay && e.weekDay.code === 8) {
        const uniqDay = dayjs(e.frequency.startDay).utc().format('YYYY-MM-DD')
        if (uniqDay === currentDay) {
          dayIsEntityWorkTime = true
          totalDayAvailability += dayjs(e.finishTime).diff(dayjs(e.startTime), 'm')
        }
      }

      return e
    })

    filteredEntities[0]?.noWorkTimes?.map((nw) => {
      const startDate = dayjs(nw.startDate).utc().format('YYYY-MM-DD')
      const finishDate = dayjs(nw.finishDate).utc().format('YYYY-MM-DD')

      // Verifica si el día actual está dentro del rango de noWorkTime y toma la cantidad de minutos como minutos tomados en la agenda
      if (currentDay >= startDate && currentDay <= finishDate) {
        takenTurnDuration += dayjs(nw.finishDate).diff(dayjs(nw.startDate), 'm')
      }
      return nw
    })

    let customClass = ''
    if (isDayLoaded && dayIsEntityWorkTime) {
      const takenPercentage = Math.round((takenTurnDuration / totalDayAvailability) * 100)

      customClass = 'chedoc-a' //blue
      if (takenPercentage > 50) {
        customClass = 'chedoc-b' //orange
      }
      if (takenPercentage > 90) {
        customClass = 'chedoc-e' //red
      }
    }

    return customClass
  }
  const handleCreateAgenda = () => {
    setOpenToCreateNewAgenda(true)
    mxp.track(mxp.events.agenda.newAgenda.clicked)
  }

  return (
    <div className={dailyView ? s.sidebarContainer : s.sidebarContainerWeeklyView}>
      <div className={s.datePickerContainer}>
        <DateCalendar
          showDaysOutsideCurrentMonth={true}
          shouldDisableDate={(date) => handleCheckDisabledDates(centreHolidays, date)}
          value={dayjs(selectedDate)}
          onChange={(newDate) => {
            setSelectedDate(dayjs(newDate).format('YYYY-MM-DD'))
            dispatch(SELECTED_CALENDAR_DATE(dayjs(newDate).format('YYYY-MM-DD')))
          }}
          slots={{
            day: (DayComponentProps) => {
              return <PickersDay {...DayComponentProps} className={getCalendarDayClass(DayComponentProps.day)} />
            },
          }}
        />
      </div>

      <div className={s.containerButton}>
        <TagState
          text={'Hoy'}
          onClick={() => {
            mxp.track(mxp.events.agenda.todayButton.clicked)
            setSelectedDate(dayjs(new Date().getTime()).format('YYYY-MM-DD'))
            dispatch(SELECTED_CALENDAR_DATE(dayjs(new Date().getTime()).format('YYYY-MM-DD')))
          }}
        />
      </div>

      {(isCentre || isAdmin) && (
        <>
          <TextOverButtons title={!shouldCreateAgenda ? 'Has llegado al limite de creación de agendas' : null}>
            <span>
              <Button
                className={s.buttonCreateAgenda}
                onClick={() => handleCreateAgenda()}
                startIcon={<AddIcon style={{ fontSize: '35px' }} />}
                variant="outlined"
                disabled={!shouldCreateAgenda}
                ref={refCreateAgendaButton}
              >
                Crear agenda
              </Button>
            </span>
          </TextOverButtons>

          {remainingAgendas >= 1 && (
            <p className={s.agendasQuantity}>{`Todavía puedes crear ${remainingAgendas} ${
              remainingAgendas === 1 ? 'agenda' : remainingAgendas > 1 ? 'agendas' : null
            }`}</p>
          )}

          {centreDetail?.centreConfiguration?.enableMyPayments && noMercadoPagoConnection && (
            <NoMercadoPagoConnectionAlert />
          )}

          {!noMercadoPagoConnection && centreDetail?.centreConfiguration?.enableMyPayments && debt?.totalDebt > 0 && (
            <Link to={'/pagos'}>
              <div className={s.containerDebtAlert}>
                <DebtAlert debt={debt} country={country} />
              </div>
            </Link>
          )}
        </>
      )}
    </div>
  )
}
