import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SelectWithInput from '@/components/SelectWithInput'
import CaluButton from '@/components/styledComponents/CaluButton'
import { useUser } from '@/context/UserProvider'
import { Grid, TextField } from '@mui/material'

import s from './styles.module.scss'

const Insurance = ({ handleClose }) => {
  const { user, updateUser } = useUser()
  const { t } = useTranslation('global')

  const [selectedInsurance, setSelectedInsurance] = useState('-')
  const [policyNumber, setPolicyNumber] = useState('')
  const [showPolicyNumber, setShowPolicyNumber] = useState(false)

  const allInsurances = useSelector((state) => state.insurances.insurancesList)

  const handleFilterInsurance = (e) => {
    let selectedInsurance = e.target.value
    setSelectedInsurance(selectedInsurance)
    if (selectedInsurance === '-') {
      setShowPolicyNumber(false)
    } else {
      setShowPolicyNumber(true)
    }
  }

  useEffect(() => {
    if (user.insurance !== '') {
      setSelectedInsurance(user.insurance)
      setShowPolicyNumber(true)
      setPolicyNumber(user.policyNumber)
    }
  }, [user])

  const handleUpdateUser = () => {
    let dataUpdate = {
      ...user,
      insurance: selectedInsurance,
      policyNumber: policyNumber,
    }
    try {
      updateUser(dataUpdate, user._id)
    } catch (error) {
      console.log(error)
    } finally {
      handleClose()
    }
  }

  return (
    <Grid container className={`${s.containerForm} animate__animated animate__fadeIn`} spacing={2}>
      <Grid item xs={showPolicyNumber ? 6 : 12} className={s.select}>
        <SelectWithInput
          inputLabel={`${t('insuranceLabel.S')}`}
          firstOptionText={`PARTICULAR - No tengo ${t('insuranceLabel.s')}`}
          firstOptionValue="-"
          options={allInsurances}
          onChange={handleFilterInsurance}
          valueSelect={selectedInsurance}
        />
      </Grid>

      {showPolicyNumber && (
        <Grid item xs={6} className={s.select}>
          <TextField
            value={policyNumber}
            type="text"
            label="Número de afiliado"
            variant="standard"
            fullWidth
            onChange={(e) => setPolicyNumber(e.target.value)}
          />
        </Grid>
      )}

      <Grid item xs={12} className={s.containerBtn}>
        <CaluButton color="primary" size="medium" onClick={handleUpdateUser}>
          Actualizar
        </CaluButton>
      </Grid>
    </Grid>
  )
}

export default Insurance
