import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sendReportPrescriptionByEmail } from '@/store/prescriptions/actions'
import { Button, TextField } from '@mui/material'

import s from '../../../styles.module.scss'

const SharePdfModule = ({ detailPrescription }) => {
  const dispatch = useDispatch()

  const selectedTurn = useSelector((state) => state.turns?.selectedTurn)

  const [sendPrescriptionEmail, setSendPrescriptionEmail] = useState(selectedTurn?.patientCentre?.email || '')
  const [send, setSend] = useState(false)

  const handleSendReportPrescription = () => {
    let dataToSend = {
      data: {
        generatedFileUrl: detailPrescription?.generatedFileUrl,
        patientFullName: detailPrescription?.patientFullName,
      },
      to: sendPrescriptionEmail,
    }
    dispatch(sendReportPrescriptionByEmail(dataToSend))
    setSend(true)
  }

  return (
    <div>
      <div className={s.divider}></div>
      <div className={s.containerHeader}>
        <h4>Enlace de la receta para compartir</h4>
        <a href={detailPrescription?.generatedFileUrl} target="_blank" rel="noreferrer">
          {detailPrescription?.generatedFileUrl}
        </a>
      </div>
      <div className={s.containerFooter}>
        <TextField
          label="Enviar enlace de la receta por email"
          value={sendPrescriptionEmail}
          type="email"
          onChange={(e) => setSendPrescriptionEmail(e.target.value)}
          fullWidth
          variant="standard"
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
        />
        <Button
          className={send ? s.afterSend : s.beforeSend}
          variant="outlined"
          disableElevation
          onClick={handleSendReportPrescription}
        >
          {send ? 'Enviado' : 'Enviar'}
        </Button>
      </div>
    </div>
  )
}

export default SharePdfModule
