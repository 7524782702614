import React from 'react'
import { Grid } from '@mui/material'

import CardDoctorCentreTarget from '../cardDoctorCentreTarget'

import s from './styles.module.scss'

const CardsDoctorsCentreTarget = ({
  entities,
  service,
  searchingFor,
  allServices,
  setSelectedService,
  selectedInsurance,
}) => {
  return (
    <Grid container className={s.cardsContainer}>
      {!entities?.length ? (
        <div className={s.messageNotTurns}>
          <p>No se encontraron profesionales para su búsqueda</p>
        </div>
      ) : (
        entities?.map((entity) => (
          <CardDoctorCentreTarget
            key={entity?._id}
            entity={entity}
            service={service}
            searchingFor={searchingFor}
            allServices={allServices}
            setSelectedService={setSelectedService}
            selectedInsurance={selectedInsurance}
          />
        ))
      )}
    </Grid>
  )
}

export default CardsDoctorsCentreTarget
