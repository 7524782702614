import { Link } from 'react-router-dom'
import Logo from '@/assets/images/logo.png'
import i18next from '@/configs/i18next'
import { baseURL } from '@/helpers/env'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { Button } from '@mui/material'

import AccountSettings from './components/AccountSettings'
import AdminOptions from './components/AdminOptions'
import AdminSimulateCentreOptions from './components/AdminSimulateCentreOptions'
import HelpUser from './components/HelpUser'
import SelectEntity from './components/SelectEntity'
import UserNotifications from './components/UserNotifications'
import UserProfileDropdown from './components/UserProfileDropdown'

import s from './styles.module.scss'

export const logo = {
  src: Logo,
  href: baseURL,
  alt: 'calu',
}

export const navLinks = (user, defaultCentre, centreDetail, doctorProfile, pathName) => {
  const selectedSection = () => {
    if (pathName?.includes('ingresar')) return 'ingresar'
    if (pathName?.includes('agenda')) return 'agenda'
    if (pathName?.includes('pacientes')) return 'pacientes'
    if (pathName?.includes('caja')) return 'gestión'
    if (pathName?.includes('fichas')) return 'fichas'
    if (pathName?.includes('fragmentos')) return 'fragmentos'
    if (pathName?.includes('perfil-profesional')) return 'perfil-profesional'
    if (pathName?.includes('turnos')) return 'turnos'
    if (pathName?.includes('recordatorios')) return 'recordatorios'
  }

  const selectedEntity = localStorage?.getItem('selectedEntity')

  const showEntitySelector =
    (user?.centres?.length || 0) +
    (user?.doctorCentres?.length || 0) +
    (user?.secretaryCentres?.length || 0) +
    (user?.patient ? 1 : 0)
  const entity = selectedEntity && JSON?.parse(selectedEntity)

  if (!user) {
    return [
      {
        align: 'right',
        content: (
          <Link to={`/ingresar`} className={s.link}>
            <Button
              className={selectedSection() === 'ingresar' ? s.isSelected : ''}
              onClick={() => mxp.track(mxp.events.navbar.loginClicked)}
            >
              Ingresar
            </Button>
          </Link>
        ),
      },
    ]
  }

  const links = [
    {
      content: <AdminOptions />,
      validRoles: ['admin'],

      isAdmin: true,
      align: 'left',
    },
    {
      content: <AdminSimulateCentreOptions />,
      validRoles: ['admin'],

      isAdmin: true,
      align: 'left',
    },
    {
      content: (
        <Link
          to={`/agenda/centro/${defaultCentre}`}
          className={s.link}
          onClick={() => mxp.track(mxp.events.navbar.agendaClicked)}
        >
          <Button className={selectedSection() === 'agenda' ? s.isSelected : ''}>Agenda</Button>
        </Link>
      ),
      validRoles: ['centre', 'secretary', 'doctor'],
      align: 'left',
      requiredSelectedCentre: true,
    },
    {
      content: (
        <Link to={`/perfil-profesional/${user.email}`} className={s.link}>
          <Button className={selectedSection() === 'perfil-profesional' ? s.isSelected : ''}>Perfil profesional</Button>
        </Link>
      ),
      validRoles: ['doctor_no_centre'],
      align: 'left',
    },
    {
      content: (
        <Link
          to={`/pacientes/centro/${defaultCentre}`}
          className={s.link}
          onClick={() => mxp.track(mxp.events.navbar.patientsClicked)}
        >
          <Button className={selectedSection() === 'pacientes' ? s.isSelected : ''}>
            {i18next.t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.Patients`, { ns: 'global' })}
          </Button>
        </Link>
      ),
      validRoles: ['centre', 'secretary', 'doctor'],
      align: 'left',
      requiredSelectedCentre: true,
    },
    {
      content: (
        <Link to={`/recordatorios`} className={s.link}>
          <Button className={selectedSection() === 'recordatorios' ? s.isSelected : ''}>Recordatorios</Button>
        </Link>
      ),
      validRoles: ['centre', 'secretary'],
      align: 'left',
      hasWappModule: true,
    },
    {
      content: (
        <Link
          to={`/caja/${defaultCentre}`}
          className={s.link}
          onClick={() => mxp.track(mxp.events.navbar.cashbox_clicked)}
        >
          <Button className={selectedSection() === 'gestión' ? s.isSelected : ''}>Gestión</Button>
        </Link>
      ),
      validRoles: ['centre', 'secretary'],
      align: 'left',
      requiredSelectedCentre: true,
      requireCashBoxModule: true,
    },
    {
      content: (
        <Link to={`/turnos`} className={s.link} onClick={() => mxp.track(mxp.events.navbar.myTurnsClicked)}>
          <Button className={selectedSection() === 'turnos' ? s.isSelected : ''}>
            Mis {i18next.t('turnLabel.P', { ns: 'global' })}
          </Button>
        </Link>
      ),
      requiredSelectedCentre: true,
      align: 'left',
      validRoles: ['patient'],
    },
    {
      content: <AccountSettings entityRole={entity?.role} />,
      validRoles: ['centre', 'secretary', 'doctor', 'patient'], //cambiaaar
      align: 'left',
      requiredSelectedCentre: true,
    },
    {
      content: <SelectEntity />,
      validRoles: ['centre', 'secretary', 'admin', 'doctor', 'patient'],
      align: 'right',
      requiredMultipleProfiles: true,
    },
    {
      content: <UserNotifications />,
      validRoles: ['centre', 'secretary', 'admin', 'doctor', 'patient'],
      align: 'right',
    },
    {
      content: <HelpUser />,
      validRoles: ['centre', 'secretary', 'doctor', 'patient'],
      align: 'right',
    },
    {
      content: <UserProfileDropdown />,
      validRoles: ['centre', 'secretary', 'admin', 'doctor', 'patient'],
      align: 'right',
    },
  ]

  return links?.filter((l) => {
    const secretaryHasCashboxAccess =
      (entity?.role === 'secretary' &&
        user?.secretaryCentres?.find((s) => s._id === entity?.secretaryCentreId)?.hasCashboxAccess) ||
      entity?.role !== 'secretary'
    const hasCashBoxModule =
      (l.requireCashBoxModule && centreDetail?.hasCashboxModule && secretaryHasCashboxAccess) || !l.requireCashBoxModule
    const hasRequiredCentre = (l.requiredSelectedCentre && entity?._id) || !l.requiredSelectedCentre
    const hasDoctorProfile = (l.requiredDoctorProfile && doctorProfile) || !l.requiredDoctorProfile
    const hasMultipleProfiles = (l.requiredMultipleProfiles && showEntitySelector > 1) || !l.requiredMultipleProfiles
    const hasWappModule = (l.hasWappModule && centreDetail?.centreConfiguration?.hasWappModule) || !l.hasWappModule

    return (
      l.validRoles.includes(entity?.role) &&
      hasRequiredCentre &&
      hasCashBoxModule &&
      hasDoctorProfile &&
      hasMultipleProfiles &&
      hasWappModule
    )
  })
}
