import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { updatePatientTreatment } from '@/store/treatments/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const FormPatientTreatment = ({ formValues, setOpenPopUp, setTreatmentSection, handleClose, setLoading }) => {
  const dispatch = useDispatch()
  const patientTreatments = useSelector((state) => state.treatments.patientTreatments)

  const handleSubmit = async (data) => {
    const treatment = patientTreatments.filter((t) => t._id === data._id)[0]

    const auxData = {
      ...data,
      turns: [...treatment.turns],
    }

    dispatch(updatePatientTreatment(auxData))
  }

  return (
    <div>
      <ContainerForm
        className={s.containerForm}
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={{ ...formDefaultValues, ...formValues }}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent
          treatment={formValues}
          setLoading={setLoading}
          handleClose={handleClose}
          setOpenPopUp={setOpenPopUp}
          setTreatmentSection={setTreatmentSection}
        />
      </ContainerForm>
    </div>
  )
}

export default FormPatientTreatment
