import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  all: [],
  selectedPatientTag: {},
  error: null,
}
const slice = createSlice({
  name: 'patientTags',
  initialState,
  reducers: {
    GET_PATIENT_TAGS: (state, { payload }) => {
      state.all = payload
    },
    POST_NEW_PATIENT_TAG: (state, { payload }) => {
      state.all.push(payload)
    },
    UPDATE_PATIENT_TAG: (state, { payload }) => {
      state.all = state.all.map((turn) => {
        if (turn._id === payload._id) {
          turn = payload
        }
        return turn
      })
    },
    REMOVE_PATIENT_TAG: (state, { payload }) => {
      state.all = state.all.filter((e) => e._id !== payload)
    },
  },
})

export const { GET_PATIENT_TAGS, POST_NEW_PATIENT_TAG, UPDATE_PATIENT_TAG, REMOVE_PATIENT_TAG } = slice.actions
export default slice.reducer
