import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import { useSocket } from '@/context/SocketProvider'
import { useUser } from '@/context/UserProvider'
import { paymentType, turnDurations } from '@/helpers/constants'
import { updateDoctorCentres } from '@/store/doctorCentres/actions'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'

import s from './styles.module.scss'

const Amounts = ({ handleClose, addWorkTimes, entity }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const { socket } = useSocket()
  const { defaultCentre } = useUser()

  const [isLoading, setIsLoading] = useState(false)
  const [changeSymbol, setChangeSymbol] = useState('$')

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const centreConfiguration = useSelector((state) => state.centreConfigurations.centreConfigurationDetail)

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      price: 0,
      priceFirstTime: 0,
      durationFirstTime: 30,
      paymentType: {
        type: 'hour',
        value: 0,
      },
    },
  })

  const onSubmit = async (data, e) => {
    if (!e) return
    e.preventDefault()

    try {
      if (entity) {
        const updatedEntity = await dispatch(updateDoctorCentres(data))
        socket.emit('calendar_edit_entity', `${defaultCentre}_calendar`, updatedEntity)
      }
      handleClose()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (entity) {
      Object.keys(entity).forEach((k) => {
        if (k === 'paymentType') {
          setValue('paymentType.type', entity[k].type)
          setValue('paymentType.value', entity[k].value)
          return
        }
        setValue(k, entity[k])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity])

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <form className={s.containerForm} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h4 className={s.subtitlesForm}>Consulta particular</h4>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label={`${handleSetMoneySign(country)} Precio`}
                  variant="standard"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <h4 className={s.subtitlesForm}>Primera consulta particular</h4>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="priceFirstTime"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label={`${handleSetMoneySign(country)} Precio`}
                  variant="standard"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="durationFirstTime"
              control={control}
              render={({ field }) => (
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Duración {t('turnLabel.sd')}</InputLabel>
                  <Select {...field} label="Duración">
                    {turnDurations.map((d) => (
                      <MenuItem key={d.value} value={d.value}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <h4 className={s.subtitlesForm}>Pagos del profesional al centro de salud</h4>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="paymentType.type"
              control={control}
              render={({ field }) => (
                <>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Seleccione el tipo de pago</InputLabel>
                    <Select {...field} label="Seleccione el tipo de pago">
                      {paymentType.map((p) => (
                        <MenuItem key={p.value} value={p.value}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {field.value === 'hour' ? setChangeSymbol('$') : setChangeSymbol('%')}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="paymentType.value"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label={changeSymbol}
                  variant="standard"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={handleKeyDown}
                />
              )}
            />
          </Grid>
        </Grid>
        <div className={s.containerButtonsForm}>
          {!centreConfiguration?.hideDoctorWorkTimes && (
            <CaluButton color="primary" inverted="true" size="medium" onClick={addWorkTimes}>
              Cargar horarios laborales
            </CaluButton>
          )}

          <CaluButton color="primary" size="medium" type="submit" loading={isLoading}>
            Guardar
          </CaluButton>
        </div>
      </form>
    </div>
  )
}

export default Amounts
