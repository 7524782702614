import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { patchSelectedTurn } from '@/store/turns/actions'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import { Today } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import dayjs from 'dayjs'

const TurnDateBox = () => {
  const dispatch = useDispatch()

  const { selectedTurn } = useSelector((state) => state.turns)
  const [isOpen, setIsOpen] = useState(false)
  const [startHourTurn, setStartHourTurn] = useState(0)
  const [startMinuteTurn, setStartMinuteTurn] = useState(0)
  const [originalTurnDuration, setOriginalTurnDuration] = useState(0)
  const [durationTurn, setDurationTurn] = useState(selectedTurn?.consultationDuration || originalTurnDuration || '')

  const entity = selectedTurn?.machine || selectedTurn?.doctorCentre
  const workingDays = entity?.workTimes?.map((workTime) => workTime.weekDay.code)
  let selectedDate = selectedTurn?.startHour

  useEffect(() => {
    const originalDuration = dayjs(selectedTurn?.finishHour).diff(dayjs(selectedTurn?.startHour), 'minute')
    setOriginalTurnDuration(originalDuration)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedTurn?.consultationDuration > 0) {
      setDurationTurn(selectedTurn?.consultationDuration)
    } else {
      setDurationTurn(originalTurnDuration)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTurn?.consultationDuration])

  const showTurnDate = () => {
    let startDate = toCustomTz(selectedDate, undefined, true, 'ddd DD/MM/YYYY')
    return startDate
  }

  const showStartHourTurn = () => {
    let hourDate = toCustomTz(selectedDate, undefined, true, 'HH')
    return hourDate
  }

  const showStartMinuteTurn = () => {
    let minuteTurn = toCustomTz(selectedDate, undefined, true, 'mm')
    return minuteTurn
  }

  const showFinishHourTurn = useCallback(() => {
    let startHour = selectedDate
    let hourTurn = toCustomTz(startHour, undefined, true, 'HH')
    let minuteTurn = toCustomTz(startHour, undefined, true, 'mm')
    let getStartHourTurn = dayjs(startHour)
      .tz('America/Buenos_Aires')
      .hour(startHourTurn || hourTurn)
      .minute(startMinuteTurn || minuteTurn)
      .format()

    let getFinishHourTurn = dayjs(getStartHourTurn).tz('America/Buenos_Aires').add(durationTurn, 'm').format('HH:mm')

    return getFinishHourTurn
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [durationTurn, startHourTurn, startMinuteTurn])

  const handleSetDurationTurn = (duration) => {
    if (!duration?.length) return setDurationTurn('')
    setDurationTurn(parseInt(duration))
    const formatFinishHour = dayjs(selectedDate).utc().add(duration, 'm').format()
    dispatch(patchSelectedTurn({ consultationDuration: parseInt(duration), finishHour: formatFinishHour }))
  }

  const handleSetHourTurn = (hour) => {
    if (!hour?.length) setStartHourTurn('')
    const formatStartHour = dayjs(selectedDate).utc().hour(hour).format()
    const formatFinishHour = dayjs(formatStartHour).utc().add(durationTurn, 'm').format()
    setStartHourTurn(hour)
    dispatch(patchSelectedTurn({ startHour: formatStartHour, finishHour: formatFinishHour }))
  }

  const handleSetStartMinuteTurn = (minute) => {
    if (!minute.length) return setStartMinuteTurn('')
    const formatStartHour = dayjs(selectedDate).utc().minute(minute).format()
    const formatFinishHour = dayjs(formatStartHour).utc().add(durationTurn, 'm').format()
    setStartMinuteTurn(minute)
    dispatch(patchSelectedTurn({ startHour: formatStartHour, finishHour: formatFinishHour }))
  }

  const handleChangeSelectedDate = (newDate) => {
    let newHourTurn = dayjs(selectedDate).utc().format('H')
    let newMinuteTurn = dayjs(selectedDate).utc().format('mm')
    let newSelectedDate = dayjs(newDate)
      .utc()
      .hour(startHourTurn || newHourTurn)
      .minute(startMinuteTurn || newMinuteTurn)
      .format()

    dispatch(patchSelectedTurn({ startHour: newSelectedDate }))
  }

  const handleClick = () => {
    setIsOpen((isOpen) => !isOpen)
  }

  const shouldDisableDate = (date) => {
    const dayOfWeek = date.day()
    return !workingDays?.includes(dayOfWeek)
  }

  const datePickerButton = () => {
    return (
      <IconButton size="small">
        <DatePicker
          value={dayjs(selectedDate)}
          format="DD/MM/YYYY"
          open={isOpen}
          onClose={() => setIsOpen(false)}
          onChange={(newDate) => handleChangeSelectedDate(newDate)}
          reduceAnimations={true}
          shouldDisableDate={shouldDisableDate}
          slots={{
            textField: (props) => {
              return (
                <Box ref={props?.InputProps?.ref}>
                  <Today className="todayIcon" onClick={handleClick} />
                </Box>
              )
            },
          }}
          slotProps={{
            popper: { sx: { width: '230px' } },
          }}
        />
      </IconButton>
    )
  }
  return (
    <div className="infoTurn">
      <div className="dateSection">
        <span className="containerCalendar">{datePickerButton()}</span>
        <b>{showTurnDate()}</b>
      </div>

      <div className="dateSection">
        Duración:
        <input
          type="number"
          className="inputConsultationDuration"
          value={durationTurn}
          onChange={(e) => handleSetDurationTurn(e.target.value)}
          onWheel={(e) => e.target.blur()}
          onKeyDown={handleKeyDown}
        />
        minutos
      </div>

      <div className="dateSection">
        <input
          type="number"
          defaultValue={showStartHourTurn()}
          onChange={(e) => handleSetHourTurn(e.target.value)}
          onWheel={(e) => e.target.blur()}
          onKeyDown={handleKeyDown}
        />
        :
        <input
          type="number"
          defaultValue={showStartMinuteTurn()}
          onChange={(e) => handleSetStartMinuteTurn(e.target.value)}
          onWheel={(e) => e.target.blur()}
          onKeyDown={handleKeyDown}
        />
        <span className="spanTo">{` hasta ${showFinishHourTurn()} hs`}</span>
      </div>
    </div>
  )
}

export default TurnDateBox
