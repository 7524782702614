import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import { createMercadoPagoPaymentLink } from '@/store/mercadoPago/actions'
import { showToast } from '@/utils/toast'

import s from './styles.module.scss'

const MercadoPagoPaymentLinkModule = ({ selectedTurn, centreDetail, mercadoPagoPaymentLink }) => {
  const dispatch = useDispatch()

  const { detail: centreMercadoPagoInfo } = useSelector((state) => state?.centreMercadoPagoInfo)

  const [creatingPaymentLink, setCreatingPaymentLink] = useState(false)

  const handleCreateMercadoPagoLink = async () => {
    setCreatingPaymentLink(true)
    const dataCreateLink = {
      _id: selectedTurn?._id,
      centre: centreDetail?._id,
      amount: selectedTurn?.debt,
      saveTurn: true,
    }
    await dispatch(createMercadoPagoPaymentLink(dataCreateLink))
    setCreatingPaymentLink(false)
  }

  const copyToClipboard = (string) => {
    navigator.clipboard.writeText(string)
    showToast('Link copiado en portapapeles', 'success', 2000)
  }

  return (
    <div className={s.mainContainerPaymentLinkModule}>
      {centreMercadoPagoInfo ? (
        !selectedTurn?.paymentLink ? (
          <div>
            <CaluButton
              inverted="true"
              size="small"
              loading={creatingPaymentLink}
              onClick={handleCreateMercadoPagoLink}
            >
              Crear link de pago
            </CaluButton>
          </div>
        ) : (
          <div className={s.containerLink}>
            <CaluButton
              size="small"
              inverted="true"
              onClick={() =>
                mercadoPagoPaymentLink?.status === 'paid'
                  ? window.open(mercadoPagoPaymentLink?.activityURL, '_blank')
                  : copyToClipboard(selectedTurn?.paymentLink)
              }
            >
              {mercadoPagoPaymentLink?.status === 'paid' ? 'Ir al detalle de pago MP' : 'Copiar link de pago MP'}
            </CaluButton>

            <p>
              Estado:{' '}
              <span className={s.bold}>{mercadoPagoPaymentLink?.status === 'paid' ? 'Pagado' : 'Pendiente'}</span>
            </p>
          </div>
        )
      ) : null}
    </div>
  )
}

export default MercadoPagoPaymentLinkModule
