import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import DividerSection from '@/components/DividerSection'
import InformationCardItem from '@/components/styledComponents/CaluInformationCard/informationCardItem'
import TitleCard from '@/components/styledComponents/CaluInformationCard/titleCard'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { DialogContentText, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const CardInfoTurn = ({ open, setOpen, turn }) => {
  const { t } = useTranslation('global')
  const dateTurn = turn.startHour
  const centreTargetProfile = useSelector((state) => state.centres.centreProfile)
  const country = centreTargetProfile?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()
  const centreTurn = turn.centre
  const service = turn?.service
  const extraServices = turn?.extraServices
  const patient = turn?.patientCentre

  const handleClose = () => {
    setOpen(false)
  }
  const formatSelectedDate = () => {
    const dayName = dayjs(dateTurn).format('dddd')
    const dayNumber = dayjs(dateTurn).format('D')
    const month = dayjs(dateTurn).format('MMMM')
    return `${dayName}, ${dayNumber} de ${month}`
  }

  const formatHour = () => {
    return dayjs(dateTurn).utc().format('hh:mm')
  }

  const showEntityName = () => {
    if (turn?.machine) {
      return capitalizeFirstLetters(turn.machine.name)
    }
    if (turn?.doctorCentre) {
      return `${capitalizeFirstLetters(turn.doctorCentre.firstname)}, ${capitalizeFirstLetters(
        turn.doctorCentre.lastname,
      )}`
    }
    return 'No especificado'
  }

  const showServiceTurn = () => {
    const serviceName = service ? service.name : turn.firstTime ? 'Primera consulta' : 'Consulta'
    const extraServicesName = turn?.extraServices?.map((elem) => elem.name)
    return !extraServices ? serviceName : `${serviceName}, ${extraServicesName}`
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{ paper: s.paperProps }}
    >
      <DialogTitle className={s.title} id="scroll-dialog-title">
        <div>Información de la cita</div>
        <Button onClick={handleClose} className={s.closeBtn}>
          X
        </Button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}></DialogContentText>
        <TitleCard title="Datos de la cita" className={s.infoTurnTitle} />

        <InformationCardItem icon={<CalendarTodayIcon />} header={'Práctica'} subheader1={`${showServiceTurn()}`} />
        <DividerSection className={s.divSection} />

        <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={12} md={4}>
            <InformationCardItem icon={<CalendarTodayIcon />} header={'Fecha'} subheader1={`${formatSelectedDate()}`} />
          </Grid>
          <Grid item xs={12} md={4}>
            <InformationCardItem icon={<AccessTimeIcon />} header={'Hora'} subheader1={`${formatHour()} hs`} />
          </Grid>

          <Grid item xs={12} md={4}>
            <InformationCardItem icon={<PersonOutlineOutlinedIcon />} header={'Con'} subheader1={showEntityName()} />
          </Grid>
        </Grid>
        <DividerSection className={s.divSection} />
        <div className={s.divAmount}>
          <div className={s.amountText}>Valor</div>
          <div>
            {handleSetMoneySign(country)}
            {turn?.amount || 0}
          </div>
        </div>

        <TitleCard title="Datos del centro" className={s.titleCard} />

        <InformationCardItem icon={<PersonOutlineOutlinedIcon />} header={'Centro'} subheader1={centreTurn?.name} />

        <InformationCardItem
          icon={<LocationOnIcon />}
          header={'Ubicación'}
          subheader1={`Direccion ${centreTurn?.street || 'No especificado'}`}
          subheader2={`Cuidad: ${centreTurn?.city || 'No especificado'}`}
        />

        <TitleCard
          title={`Datos del ${t(`subCategoryLabel.${getCentreSubCategory(centreTargetProfile?.category)}.patient`)}`}
          className={s.titleCard}
        />
        <Grid container spacing={2}>
          <Grid item xs={6} md={3} className={s.patientName}>
            <InformationCardItem header={'Nombre'} subheader1={`${capitalizeFirstLetters(patient?.lastname)}`} />
          </Grid>
          <Grid item xs={6} md={3} className={s.patientName}>
            <InformationCardItem header={'Apellido'} subheader1={`${capitalizeFirstLetters(patient?.firstname)}`} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default CardInfoTurn
