import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ButtonsPagination from '@/components/ButtonsPaginations'
import CaluButton from '@/components/styledComponents/CaluButton'
import { SELECTED_TURN_REMINDER } from '@/store/wapp'
import { getWappTurnReminders, setSentRemindersQuantity } from '@/store/wapp/actions'
import { handleSetCountryCode } from '@/utils/functions/handleSetCountryCode'
import { handleWappReminder } from '@/utils/functions/handleWappReminders'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const ManagementTable = ({ startDate, finishDate, deviceId, setOpen, filteredTurnRemindersAvailable }) => {
  const dispatch = useDispatch()
  const [isLoadingButton, setIsLoadingButton] = useState(false)
  const [selectedButton, setSelectedButton] = useState(null)
  const { isLoading, paginationData, status: wappStatus } = useSelector((state) => state?.wapp)
  const { centreProfile } = useSelector((state) => state?.centres)
  const { totalDocs } = paginationData || {}
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isAdmin } = usersProfileConnected
  const { t } = useTranslation('global')

  const handleSendWhatsApp = async (data, buttonId) => {
    setSelectedButton(buttonId)
    setIsLoadingButton(true)
    const selectedTurn = data.value
    let auxData = {
      selectedTurn: {
        ...selectedTurn,
        centre: centreProfile,
      },
      state: 'init',
      dispatch: dispatch,
      wappStatus: wappStatus,
    }

    try {
      await handleWappReminder(auxData)
      await dispatch(setSentRemindersQuantity(1))
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoadingButton(false)
    }
  }

  const renderDateReminder = (params) => {
    const attempts = params.value

    return (
      <>
        {!attempts && <div className={s.noData}>-</div>}
        <div className={s.attemptsContainer}>
          {attempts?.map((a) => (
            <div className={s.iconContainer} key={a._id}>
              {a.status === 'sent' ? (
                <Tooltip title="Enviado">
                  <CheckIcon className={s.checkIcon} />
                </Tooltip>
              ) : (
                <Tooltip title="Error al enviar">
                  <CloseIcon className={s.errorIcon} />
                </Tooltip>
              )}

              <span>{dayjs(a?.date).format('DD/MM/YYYY [a las] HH:mm [hs]')}</span>
            </div>
          ))}
        </div>
      </>
    )
  }
  const renderMessageType = (params) => {
    const attempts = params.value
    return (
      <>
        {!attempts && <div className={s.noData}>-</div>}
        <div>
          {attempts?.map((a) => (
            <div key={a._id} className={s.messageType}>
              {a.messageType}
            </div>
          ))}
        </div>
      </>
    )
  }

  const handleCellClick = async (params, event) => {
    if (event.target.id === 'sendReminderButton') return
    await dispatch(SELECTED_TURN_REMINDER(params?.row))
    setOpen(true)
  }

  const stylesColumns = {
    headerClassName: 'header',
    alignItems: 'flex-start',
    flex: 1,
  }

  const columns = [
    {
      field: 'fullName',
      headerName: 'Nombre',
      ...stylesColumns,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: 'mobile',
      headerName: 'Teléfono',
      ...stylesColumns,
    },
    {
      field: 'turnDate',
      headerName: `Fecha de ${t('turnLabel.s')}`,
      ...stylesColumns,
    },
    {
      field: 'messageType',
      headerName: 'Tipo de mensaje',

      ...stylesColumns,
      renderCell: (params) => renderMessageType(params),
    },
    {
      field: 'date',
      headerName: 'Fecha de envío',
      ...stylesColumns,
      renderCell: (params) => renderDateReminder(params),
    },

    {
      field: 'raw',
      headerName: '',
      maxWidth: 160,
      ...stylesColumns,
      renderCell: (params) => (
        <>
          <Tooltip title={'Enviar recordatorio'} placement="top">
            <CaluButton
              onClick={() => handleSendWhatsApp(params, params.id)}
              id="sendReminderButton"
              size="small"
              color="primary"
              loading={selectedButton === params.id ? isLoadingButton : false}
              disabled={isAdmin}
            >
              Enviar recordatorio
            </CaluButton>
          </Tooltip>
        </>
      ),
    },
  ]

  const rows = filteredTurnRemindersAvailable?.map((turn) => {
    const patientCentre = turn.patientCentre
    const countryCode = handleSetCountryCode(
      patientCentre?.country || centreProfile?.country || import.meta.env.REACT_APP_COUNTRY,
    )
    const patientMobile = patientCentre?.mobile
    const attempts = turn.wappReminder?.attempts
    return {
      id: turn?._id,
      fullName: `${patientCentre?.lastname?.toUpperCase()}, ${patientCentre?.firstname?.toUpperCase()}`,
      mobile: patientMobile ? countryCode + patientMobile : 'Celular sin registrar',
      turnDate: toCustomTz(turn?.startHour, undefined, true, 'DD/MM/YYYY [a las] HH:mm [hs]'),
      attempts: attempts,
      messageType: attempts,
      date: attempts,
      raw: turn,
    }
  })

  const handleChangePaginationNumber = (page) => {
    let params = {
      centre: deviceId,
      start: toCustomTz(startDate, 'utc', true),
      finish: toCustomTz(finishDate, 'utc', true),
      page: page,
      limit: 30,
    }
    dispatch(getWappTurnReminders(params))
  }

  return (
    <Box
      sx={{
        px: 0,
        '& .header': {
          fontSize: '14px',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
          background: '#fff',
        },
      }}
    >
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'fullname', sort: 'asc' }],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        rows={rows || []}
        rowCount={totalDocs || 0}
        getRowHeight={() => 'auto'}
        loading={isLoading}
        columns={columns}
        disableRowSelectionOnClick={true}
        onCellClick={(params, event) => handleCellClick(params, event)}
        rowClassName={s.rowClassName}
        autoHeight={true}
        hideFooter={true}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          fontSize: '13px',
          padding: '10px',
          marginBottom: '30px',
          background: '#fff',
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell ': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            margin: '10px 0px 10px 0px',
          },
        }}
      />
      {filteredTurnRemindersAvailable?.length >= 1 && (
        <div className={s.paginationButton}>
          <ButtonsPagination
            paginationData={paginationData}
            handleChangePaginationNumber={handleChangePaginationNumber}
          />
        </div>
      )}
    </Box>
  )
}

export default ManagementTable
