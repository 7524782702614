import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { postFile } from '@/controllers/postFile'
import { showToast } from '@/utils/toast'

export const useUploadImage = () => {
  const dispatch = useDispatch()

  const [loadingImage, setLoadingImage] = useState(false)

  const uploadImage = async (event, image = 'image', updateFunction, prevState, extraData) => {
    const file = event?.target?.files[0] || event
    if (file?.type?.slice(0, 5) === 'image') {
      setLoadingImage(true)
      const formData = new FormData()

      formData.append('file', file, file?.name)
      const entityImage = await postFile(formData)

      let auxData = { ...prevState }
      auxData[image] = entityImage?.split(' ').join('%20')

      try {
        dispatch(updateFunction(auxData, extraData))

        setLoadingImage(false)
      } catch (e) {
        console.log(e)
        showToast('Error al subir el archivo', 'error')
      }
    }
  }

  return {
    loadingImage,
    uploadImage,
  }
}
