import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import defaultUserImage from '@/assets/images/default.png'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import ImageCircle from '@/components/imageCircle'
import TitlePopUp from '@/components/TitlePopUp'
import { useDeleteImage } from '@/hooks/useDeleteImage'
import { useUploadImage } from '@/hooks/useUploadImage'
import { updatePatient } from '@/store/patients/actions'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { Button, DialogContent, DialogTitle, Grid } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import dayjs from 'dayjs'
import FormUpdatePatientProfile from './components/formUpdatePatientProfile'

import s from './styles.module.scss'

const DetailPatient = ({ selectedPatient, openPatientPopUp, setOpenPatientPopUp, modifyPatientProfile }) => {
  const logoUpload = useUploadImage()
  const { t } = useTranslation('global')

  const { deleteProfileImage } = useDeleteImage()

  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const [openDeleteImage, setOpenDeleteImage] = useState(false)

  const handleClose = () => {
    setOpenPatientPopUp(false)
  }

  return (
    <Grid container className={`${s.container} animate__animated animate__fadeIn`}>
      <Grid item container md={4} className={s.leftContainer}>
        <Grid item xs={12}>
          <div className={s.containerLogo}>
            <ImageCircle
              image={selectedPatient?.image}
              defaultImage={defaultUserImage}
              uploadImage={(e) => logoUpload.uploadImage(e, 'image', updatePatient, selectedPatient, true)}
              loading={logoUpload.loadingImage}
              className="changeProfileImage"
              id={'patientImage'}
            />
            <AlertDialogSlide
              open={openDeleteImage}
              setOpen={setOpenDeleteImage}
              textContent={'¿Confirma que quiere eliminar la imagen?'}
              textBackButton="Cancelar"
              textConfirmationButton={'Confirmar'}
              onClose={() => deleteProfileImage('image', selectedPatient, updatePatient, true)}
            />
          </div>
          {selectedPatient?.image && (
            <small className={s.deleteImageButton} onClick={() => setOpenDeleteImage(true)}>
              Eliminar
            </small>
          )}
        </Grid>
        <Grid item xs={12} className={s.title}>
          <p>
            {selectedPatient?.lastname.toUpperCase()}, {selectedPatient?.firstname.toUpperCase()}
          </p>
        </Grid>
      </Grid>

      <Grid item container md={8} className={s.rightContainer}>
        <Grid item md={6}>
          <div className={s.data}>
            {selectedPatient?.dni && (
              <p>
                DNI: <span>{selectedPatient?.dni} </span>
              </p>
            )}

            {selectedPatient?.birth && (
              <p>
                Fecha de nacimiento: <span>{dayjs(selectedPatient?.birth).format('DD-MM-YYYY')} </span>
              </p>
            )}

            {selectedPatient?.address && (
              <p>
                Dirección: <span>{selectedPatient?.address} </span>
              </p>
            )}

            {selectedPatient?.city && (
              <p>
                Dirección: <span>{selectedPatient?.city} </span>
              </p>
            )}

            {selectedPatient?.email && (
              <p>
                Email: <span>{selectedPatient?.email} </span>
              </p>
            )}

            {selectedPatient?.mobile && (
              <p>
                Móvil / Celular: <span>{selectedPatient?.mobile} </span>
              </p>
            )}

            {selectedPatient?.telephone && (
              <p>
                Teléfono: <span>{selectedPatient?.telephone} </span>
              </p>
            )}
          </div>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={5} xs={12}>
          <Button onClick={modifyPatientProfile} fullWidth={true} disableElevation className={s.button}>
            Modificar
          </Button>
        </Grid>
      </Grid>
      <Dialog maxWidth="md" fullWidth open={openPatientPopUp} onClose={handleClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp
            textTitle={`Modificar perfil de ${t(
              `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`,
            )}`}
            handleClose={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <FormUpdatePatientProfile handleClose={handleClose} selectedPatient={selectedPatient} />
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

export default DetailPatient
