import React from 'react'

import { ThemeProvider } from 'styled-components'

const theme = {
  typography: {
    fontFamily: `'poppins', sans-serif`,
  },
  colors: {
    base: {
      white: '#fff',
      black: '#000',
      silver: '#bdc3c7',
      success: '#2ecc71',
      successDark: '#27ae60',
      danger: '#e74c3c',
      dangerDark: '#c0392b',
      warning: '#f1c40f',
      warningDark: '#f39c12',
    },
    turn: {
      bg: '#eff2f8',
      init: '#f8ac59',
      confirmed: '#1ab395',
      finished: '#34495e',
      arrived: '#1c83c6',
      happening: '#9e82cb',
      absent: '#ed5564',
      cancelled: '#ed5564',
    },
    // Calu: purple
    a: '#4C5EFE',
    //Calu: dark blue
    b: '#0F1333',
    //Calue: sky-blue
    c: '#7FD5F4',
    // Calu: green
    d: '#ADF39E',
    // Calu: yellow
    e: '#F0CB9B',
    // Calu: red
    f: '#EA9EB5',
    //gray
    g: '#F9F9FF',
    // Chedoc color
    b700: '#e18e33',
  },
}

const ThemeStyledComponents = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>

export default ThemeStyledComponents
