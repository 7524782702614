import React, { useRef, useState } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BsDot } from 'react-icons/bs'
import { GrAdd } from 'react-icons/gr'
import { useSelector } from 'react-redux'
import LogoDefault from '@/assets/images/centre-logo.jpg'
import { AutoCompleteCityField } from '@/components/Form/customFields/AutoCompleteCityField'
import { CustomField } from '@/components/Form/customFields/CustomField'
import { SelectField } from '@/components/Form/customFields/SelectField'
import { SwitchField } from '@/components/Form/customFields/SwitchField'
import ImageCircle from '@/components/imageCircle'
import CaluButton from '@/components/styledComponents/CaluButton'
import { countryCodes } from '@/helpers/constants'
import { useUploadImage } from '@/hooks/useUploadImage'
import { updateCentre } from '@/store/centres/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { sortArrayAlphabetically } from '@/utils/functions/sortArrayAlphabetically'
import { Button, Grid, MenuItem } from '@mui/material'

import s from './styles.module.scss'

export const FormContent = ({ isLoading = false }) => {
  const { t } = useTranslation('global')
  const refSubmitButton = useRef()
  const logoUpload = useUploadImage()
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const [logoLoading, setLogoLoading] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)

  const { field: fieldPhone } = useController({ name: 'phone' })
  const { field: fieldPhones } = useController({ name: 'phones' })

  const addNewPhoneNumber = () => {
    fieldPhones.onChange([...fieldPhones.value, fieldPhone.value])
    fieldPhone.onChange('')
  }

  const deletePhoneNumber = (phoneNumber) => {
    let phoneDelete = fieldPhones.value.filter((phone) => phone !== phoneNumber)
    fieldPhones.onChange(phoneDelete)
  }

  return (
    <div className={`${s.containerForm} animate__animated animate__fadeIn`}>
      <Grid container spacing={2}>
        <Grid item container xs={12} className={s.gridContainer}>
          <Grid item xs={12} md={6} className={s.centreName}>
            <h5 className={s.titleSectionForm}>{`Datos generales del centro : ${capitalizeFirstLetters(
              centreDetail?.name,
            )}`}</h5>
          </Grid>
          <Grid item xs={12} md={6} className={s.gridItemImg}>
            <div className={s.div1}>
              <div className={s.containerLogo}>
                <ImageCircle
                  image={centreDetail?.logo}
                  defaultImage={LogoDefault}
                  uploadImage={async (e) => {
                    setLogoLoading(true)
                    await logoUpload.uploadImage(e, 'logo', updateCentre, centreDetail, () =>
                      setLogoLoading(logoUpload.loadingImage),
                    )
                    setLogoLoading(false)
                  }}
                  loading={logoLoading}
                  className="uploadImageCentreProfile"
                  id={'centreLogo'}
                />
              </div>
              <small className={s.tagSmall}>Cargar foto del centro</small>
            </div>

            <div className={s.div2}>
              <div className={s.containerLogo}>
                <ImageCircle
                  image={centreDetail?.image}
                  defaultImage={LogoDefault}
                  uploadImage={async (e) => {
                    setImageLoading(true)
                    await logoUpload.uploadImage(e, 'image', updateCentre, centreDetail, () => setImageLoading(false))
                    setImageLoading(false)
                  }}
                  loading={imageLoading}
                  className="uploadImageCentreProfile"
                  id={'centreImage'}
                />
              </div>
              <small className={s.tagSmall}>Cargar foto de portada</small>
            </div>
          </Grid>
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomField label="Nombre*" name="name" fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField label="País" name="country" type="text" fullWidth>
            {sortArrayAlphabetically(countryCodes).map((code) => (
              <MenuItem key={code.value} value={code.value}>
                {code.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomField label="Dirección" name="street" fullWidth />
        </Grid>

        <Grid item md={6} xs={12}>
          <AutoCompleteCityField label="Ciudad" name="city" />
        </Grid>

        <Grid item md={6} className={s.gridTelephone}>
          <CustomField label="Teléfono" name="phone" type="number" fullWidth />
          <Button variant="contained" disableElevation onClick={() => addNewPhoneNumber()}>
            <GrAdd />
          </Button>
        </Grid>

        <Grid item md={4} xs={12}>
          {fieldPhones.value?.length ? (
            <ul className={s.listNumbers}>
              {fieldPhones.value?.map((number, index) => (
                <li key={index} className={s.itemNumber}>
                  <div className={s.phoneNumber}>
                    <BsDot />
                    {number}
                  </div>
                  <Button
                    className={s.btnDeleteNumber}
                    variant="contained"
                    disableElevation
                    onClick={() => deletePhoneNumber(number)}
                  >
                    X
                  </Button>
                </li>
              ))}
            </ul>
          ) : null}
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomField label="Email" name="email" fullWidth />
        </Grid>

        <Grid item md={6} xs={12}>
          <CustomField label="Sitio web (ejemplo: https://calu.app)" name="web" fullWidth />
        </Grid>

        <Grid item container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item xs={12}>
            <h5 className={s.titleSectionForm}>Agenda</h5>
          </Grid>
          <Grid item xs={12}>
            <SwitchField label="Mostrar montos en la agenda" name="hidePaymentInfo" isCheckedInverted />
          </Grid>
          <Grid item xs={12}>
            <SwitchField
              label={`Mostrar ausentismo del ${t(
                `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patient`,
              )}`}
              name="showPatientAbsent"
            />
          </Grid>
          <Grid item xs={12}>
            <SwitchField
              label={`DNI opcional en ${t(
                `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`,
              )} - Permitir utilizar Historias Clínicas y Tratamientos en ${t(
                `subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.patients`,
              )} sin DNI`}
              name="centreConfiguration[enableOptionalDniOnPatients]"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item xs={12}>
            <h5 className={s.titleSectionForm}>Envío de emails a administradores/profesionales</h5>
          </Grid>
          <Grid item xs={12}>
            <SwitchField
              label={`Recibir email al crearse ${t('turnLabel.sa')} online`}
              name="emailOnlineTurnCreation"
            />
          </Grid>
          <Grid item xs={12}>
            <SwitchField
              label={`Recibir email al cancelarse ${t('turnLabel.sa')} online`}
              name="emailOnlineTurnCancellation"
            />
          </Grid>
          <Grid item xs={12}>
            <SwitchField
              label={`Enviar email con ${t('turnLabel.p')} del día`}
              name="centreConfiguration[shouldSendDoctorAgendaEmail]"
            />
          </Grid>
        </Grid>
      </Grid>

      <div className={s.containerSaveButton}>
        <CaluButton color="primary" type="submit" size="medium" loading={isLoading} ref={refSubmitButton}>
          Guardar
        </CaluButton>
      </div>
    </div>
  )
}
