import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { useUser } from '@/context/UserProvider'
import { checkAvailabilityAndCreateOrUpdateTurn, selectedOnLineTurnConfirm } from '@/store/turns/actions'
import { getInsurancePrice } from '@/utils/functions/calculateFinalServicePrice'
import { hasRequirePayment } from '@/utils/functions/hasRequirePayment'
import { showToast } from '@/utils/toast'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'
import PopUpTurnNotAvailable from './PopUpTurnNotAvailable'

import s from './styles.module.scss'

const CardInsurance = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useUser()

  const [isLoading, setIsLoading] = useState(false)
  const [turnNotAvailable, setTurnNotAvailable] = useState(false)

  const {
    patientCentres: selectedPatient,
    centreTargetProfile,
    entityData,
    turnDetail,
    selectedService,
    extraServices,
    turnFinalAmount,
  } = useSelector((state) => state.turns?.onLineTurns)
  const { allInsurancePrices } = useSelector((state) => state.insurancePrices)

  const hasRequirePaymentToConfirmTurn =
    (selectedService?.requirePayment || hasRequirePayment(extraServices)) && turnDetail?.paymentLink

  const handleBeforeUnload = (event) => {
    event.preventDefault()
    event.returnValue = ''
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async (data) => {
    if (!centreTargetProfile) {
      showToast('Error al crear el turno', 'error')
      return
    }
    setIsLoading(true)

    const shouldUpdateMPLink = turnDetail?.amount !== turnFinalAmount

    let dataTurn = {
      ...turnDetail,
      centre: centreTargetProfile._id,
      comment: data.policyNumber ? `Número de afiliado: ${data.policyNumber} - ${data.comment}` : data.comment,
      insurance: data?.insurance?._id === '-' ? undefined : data.insurance,
      dni: selectedPatient?.dni,
      creatorDni: user?.dni,
      state: hasRequirePaymentToConfirmTurn ? 'pending' : 'init',
      patientCentre: selectedPatient,
      service: selectedService,
      extraServices: extraServices,
      origin: 'patient',
      amount: turnFinalAmount,
      debt: turnFinalAmount,
      insurancePrice: getInsurancePrice(selectedService, data?.insurance?._id, allInsurancePrices),
      shouldUpdateMPLink,
    }
    if (entityData?.lastname) {
      dataTurn.doctorCentre = entityData
    }
    if (entityData?.name) {
      dataTurn.machine = entityData
    }
    try {
      const validateAndCreateTurn = await dispatch(checkAvailabilityAndCreateOrUpdateTurn(dataTurn))
      if (validateAndCreateTurn) {
        if (hasRequirePaymentToConfirmTurn) {
          navigate(`/turnos/info-pago/${centreTargetProfile._id}`)
        } else {
          dispatch(selectedOnLineTurnConfirm(true))
          navigate(`/turnos/listo/${centreTargetProfile._id}`)
        }
        setIsLoading(false)
      } else {
        setTurnNotAvailable(true)
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
      showToast('Error al crear el turno', 'error')
      setIsLoading(false)
      navigate(`/centro/${centreTargetProfile._id}`)
    }
  }

  return (
    <ContainerForm
      className={s.containerForm}
      onSubmit={(data) => handleSubmit(data)}
      defaultValues={{ ...formDefaultValues }}
      resolver={yupResolver(formValidationSchema)}
    >
      <FormContent isLoading={isLoading} />
      {turnNotAvailable && <PopUpTurnNotAvailable open={turnNotAvailable} setOpen={setTurnNotAvailable} />}
    </ContainerForm>
  )
}

export default CardInsurance
