export const formDefaultValues = {
  country: '',
  dni: '',
  lastname: '',
  firstname: '',
  email: '',
  mobile: '',
  birth: '',
  gender: 'female',
}
