import { addNewPlanPrices, deletePlanPrices, fetchPlanPrices, putPlanPrices } from '@/services/planPrices'
import {
  FETCH_ALL_PLAN_PRICES,
  LOADED_PLAN_PRICES,
  PLAN_PRICES_CREATED,
  PLAN_PRICES_DELETE,
  PLAN_PRICES_UPDATED,
} from '@/store/planPrices'
import { showToast } from '@/utils/toast'

const getAllPlanPrices = (params) => async (dispatch) => {
  try {
    const allPlanPrices = await fetchPlanPrices(params)
    dispatch(FETCH_ALL_PLAN_PRICES(allPlanPrices))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const postNewPlanPrices =
  (data, hideAlert = false) =>
  async (dispatch, getState) => {
    const { allSubscriptionPlans } = getState().subscriptionPlans

    try {
      const newPlan = await addNewPlanPrices(data)
      const findSubscriptionPlan = allSubscriptionPlans?.find((plan) => plan._id === newPlan?.subscriptionPlan)
      newPlan.subscriptionPlan = findSubscriptionPlan

      dispatch(PLAN_PRICES_CREATED(newPlan))
      if (newPlan && !hideAlert) {
        showToast('Plan agregado correctamente', 'success')
      }
      return newPlan
    } catch (error) {
      const { message } = error
      console.log('Error message: ', message)
      showToast('Error al crear el plan', 'error')
    }
  }

const deletePlanPricesAdded = (id) => async (dispatch) => {
  try {
    await deletePlanPrices(id)
    dispatch(PLAN_PRICES_DELETE(id))
    showToast('Plan Eliminado', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updatePlanPrices = (data, hideAlert) => async (dispatch, getState) => {
  const { allSubscriptionPlans } = getState().subscriptionPlans

  try {
    const planUpdated = await putPlanPrices(data)
    const findSubscriptionPlan = allSubscriptionPlans?.find((plan) => plan._id === planUpdated?.subscriptionPlan)
    planUpdated.subscriptionPlan = findSubscriptionPlan

    dispatch(PLAN_PRICES_UPDATED(planUpdated))
    if (planUpdated && !hideAlert) {
      showToast('Plan actualizado correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const isLoadedPlanPrices = (data) => async (dispatch) => {
  try {
    dispatch(LOADED_PLAN_PRICES(data))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export { deletePlanPricesAdded, getAllPlanPrices, isLoadedPlanPrices, postNewPlanPrices, updatePlanPrices }
