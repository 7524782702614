import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Layout1 from '@/layouts/Layout1'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import PropTypes from 'prop-types'
import AccessToMedicalRecords from './components/AccessToMedicalRecords'
import CreateTemplateHC from './components/CreateTemplateHC'
import FragmentsClinicHistory from './components/FragmentsClinicHistory'
import SignatureRecord from './components/SignatureRecord/SignatureRecord'

import s from './styles.module.scss'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const ClinicHistories = () => {
  const [value, setValue] = useState(0)

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isCentre, isAdmin } = usersProfileConnected

  return (
    <Layout1 isLoading={false} navbar>
      <div className={s.mainViewContainer}>
        <Box sx={{ width: '100%', marginBottom: '30px' }}>
          <Box>
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              aria-label="Módulo de gestión"
              value={value}
              onChange={handleTabChange}
              sx={{
                '& .MuiTab-root.Mui-selected': {
                  color: '#4C5EFE',
                },
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
            >
              <Tab label="Fichas" value={0} />
              <Tab label="Fragmentos" value={1} />
              <Tab label="Registro de firma" value={2} />
              {(isCentre || isAdmin) && <Tab label="Permisos" value={3} />}
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <CreateTemplateHC />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FragmentsClinicHistory />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SignatureRecord />
          </TabPanel>
          {(isCentre || isAdmin) && (
            <TabPanel value={value} index={3}>
              <AccessToMedicalRecords />
            </TabPanel>
          )}
        </Box>
      </div>
    </Layout1>
  )
}
export default ClinicHistories
