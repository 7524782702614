export const formDefaultValues = {
  blood: '',
  surginalHistory: '',
  familyHistory: '',
  allergies: '',
  chronicMedications: '',
  chronicDiseases: '',
  habits: '',
  observations: '',
  extraFields: [],
}
