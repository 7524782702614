import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import LoaderCircle from '@/components/LoaderCircle'
import { useUser } from '@/context/UserProvider'
import { RESET_ONLINE_TURNS } from '@/store/turns'
import { SELECTED_ONLINE_TURN_INSURANCE } from '@/store/turns'
import { selectedOnLineTurn } from '@/store/turns/actions'
import { checkAvailabilityAndCreateOrUpdateTurn, cleanPatientCentreTurns } from '@/store/turns/actions'
import { getInsurancePrice } from '@/utils/functions/calculateFinalServicePrice'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import { showToast } from '@/utils/toast'

import dayjs from 'dayjs'
import PopUpShowMoreTurns from '../popUpShowMoreTurns'

import s from './styles.module.scss'

const AvailableTurnsList = ({ entityAvailability, entity, selectedService, allServices, selectedInsurance }) => {
  const dispatch = useDispatch()
  const centreID = useParams().id
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const { user } = useUser()

  let availableDays = entityAvailability?.availableDays

  const [open, setOpen] = useState(false)
  const [entityAvailabilityPopUp, setEntityAvailabilityPopUp] = useState([])
  const [daySelected, setDaySelected] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const centreTargetProfile = useSelector((state) => state.centres?.centreTarget)
  const extraServices = useSelector((state) => state.turns.onLineTurns.extraServices)
  const { allInsurancePrices } = useSelector((state) => state.insurancePrices)

  const turnsPerDay = (day, entityAvailability) => {
    for (const key in entityAvailability) {
      if (key === day) {
        return entityAvailability[key]
      }
    }
  }

  const formatDate = (date) => {
    const dayNumber = dayjs(date).format('D')
    const dayName = dayjs(date).format('ddd').toUpperCase()
    const month = dayjs(date).format('MMM').toUpperCase()
    return `${dayName} ${dayNumber} ${month}.`
  }

  const formatDateLong = (date) => {
    const dayNumber = dayjs(date).format('D')
    const dayName = dayjs(date).format('dddd')
    const month = dayjs(date).format('MMMM')
    return `${dayName} ${dayNumber} de ${month}`
  }

  const handleOpenPopUp = (availabilityPerDay, day) => {
    setOpen(!open)
    setEntityAvailabilityPopUp(availabilityPerDay)
    setDaySelected(day)
  }

  const handleSelectedTurn = (entity, turnData, day, selectedService, extraServices) => {
    dispatch(selectedOnLineTurn(entity, turnData, day, selectedService, extraServices))
    dispatch(SELECTED_ONLINE_TURN_INSURANCE(selectedInsurance))
  }

  const handleCreatePendingTurn = async (userConected, entity, turnData, day, selectedService, extraServices) => {
    setIsLoading(true)
    dispatch(selectedOnLineTurn(entity, turnData, day, selectedService, extraServices))

    if (userConected?.patient || userConected?.roles?.includes('patient')) {
      dispatch(cleanPatientCentreTurns())

      let dataPatientCentre = {
        ...(userConected?.patient || {}),
        centre: centreID,
      }
      delete dataPatientCentre?._id //Aca estoy borrando el patient._id

      const serviceInsurancePrice = getInsurancePrice(selectedService, selectedInsurance, allInsurancePrices)
      const selectedServicePrice = serviceInsurancePrice?.price || selectedService?.price || 0
      const extraServicesPrice = extraServices?.reduce((a, b) => Number(a) + (Number(b.price) || 0), 0)
      const amountTurn = selectedServicePrice + extraServicesPrice

      let dataTurn = {
        centre: centreTargetProfile._id,
        dni: dataPatientCentre?.dni,
        creatorDni: userConected?.dni,
        firstTime: selectedService?._id === 'firstConsultation' ? true : false,
        service: ['consultation', 'firstConsultation'].includes(selectedService?._id) ? null : selectedService,
        origin: 'patient',
        startHour: dayjs(day.concat(turnData?.startHour)).utc(true).toISOString(),
        finishHour: dayjs(day.concat(turnData?.finishHour)).utc(true).toISOString(),
        turnDuration: turnData?.turnDuration,
        consultationDuration: turnData?.turnDuration,
        amount: amountTurn,
        state: 'pending',
      }

      if (extraServices?.length > 0) {
        dataTurn.extraServices = extraServices
      }

      if (entity?.name) {
        dataTurn.machine = entity
      } else {
        dataTurn.doctorCentre = entity
      }

      try {
        const validateAndCreateOrUpdateTurn = await dispatch(checkAvailabilityAndCreateOrUpdateTurn(dataTurn))

        if (validateAndCreateOrUpdateTurn) {
          dispatch(SELECTED_ONLINE_TURN_INSURANCE(selectedInsurance))
          setIsLoading(false)
          navigate(`/turnos/info-paciente/${centreID}`)
        } else {
          setIsLoading(false)
          dispatch(RESET_ONLINE_TURNS())
          navigate(`/centro/${centreTargetProfile._id}`)
        }
      } catch (error) {
        console.log(error)
        showToast('Error al crear el turno', 'error')
        setIsLoading(false)
      }
    }
  }

  return (
    <div className={s.mainContainer}>
      {isLoading ? (
        <LoaderCircle size={30} />
      ) : (
        <>
          <div className={s.containerTurns}>
            {availableDays?.map((day, i) => (
              <div key={i} className={s.listTurnsDay}>
                <p className={s.turnsDay}>{formatDate(day)}</p>
                <span className={s.detailTurns}>
                  {centreTargetProfile?.hideMoreOnlineTurns ? (
                    turnsPerDay(day, entityAvailability)
                      .slice(0, 3)
                      .map((elem, i) => (
                        <Link
                          key={i}
                          to={!!user ? '' : `/turnos/conectate/${centreID}`}
                          className={s.link}
                          onClick={() => mxp.track(mxp.events.centreLanding.agenda.dateSelected)}
                        >
                          <div
                            className={s.itemTurn}
                            onClick={
                              !!user
                                ? () => handleCreatePendingTurn(user, entity, elem, day, selectedService, extraServices)
                                : () => handleSelectedTurn(entity, elem, day, selectedService, extraServices)
                            }
                          >
                            <span>{elem.startHour} hs</span>
                          </div>
                        </Link>
                      ))
                  ) : (
                    <div>
                      {turnsPerDay(day, entityAvailability)
                        .slice(0, 3)
                        .map((elem, i) => (
                          <div key={i}>
                            <Link
                              to={!!user ? '' : `/turnos/conectate/${centreID}`}
                              className={s.link}
                              onClick={() => mxp.track(mxp.events.centreLanding.agenda.dateSelected)}
                            >
                              <div
                                className={s.itemTurn}
                                onClick={
                                  !!user
                                    ? () =>
                                        handleCreatePendingTurn(user, entity, elem, day, selectedService, extraServices)
                                    : () => handleSelectedTurn(entity, elem, day, selectedService, extraServices)
                                }
                              >
                                <span>{elem.startHour} hs</span>
                              </div>
                            </Link>
                          </div>
                        ))}

                      {turnsPerDay(day, entityAvailability).length - 3 > 0 && (
                        <div className={`${s.itemTurn} ${s.itemsQuantityTurns}`}>
                          <span onClick={() => handleOpenPopUp(turnsPerDay(day, entityAvailability), day)}>
                            <b>
                              {`+ ${turnsPerDay(day, entityAvailability).length - 3} `}
                              {t('turnLabel.p')}
                            </b>
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </span>
              </div>
            ))}
          </div>

          <p className={s.nextTurnInfo}>
            {t('turnLabel.Sc')} <b>{formatDateLong(availableDays?.[0])}</b>
          </p>

          <PopUpShowMoreTurns
            open={open}
            setOpen={setOpen}
            availabilityPerDay={entityAvailabilityPopUp}
            daySelected={daySelected}
            entity={entity}
            selectedService={selectedService}
            allServices={allServices}
            handleCreatePendingTurn={handleCreatePendingTurn}
            isLoading={isLoading}
          />
        </>
      )}
    </div>
  )
}

export default AvailableTurnsList
