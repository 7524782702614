import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import useValidateURLId from '@/hooks/useValidateURLId'
import { createNewTemplateTreatment, updateTemplateTreatment } from '@/store/treatments/actions'
import { handleKeyDown } from '@/utils/functions/handleKeyDown'
import { Grid, TextField } from '@mui/material'

import s from './styles.module.scss'

const FormCreateTemplateTreatment = ({ handleClose, treatmentSelected }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const centre = useValidateURLId()

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm()

  useEffect(() => {
    if (treatmentSelected) {
      Object.keys(treatmentSelected).forEach((k) => {
        setValue(k, treatmentSelected[k])
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentSelected])

  const onSubmit = (data, e) => {
    if (!e) return
    e.preventDefault()
    setIsLoading(true)

    try {
      const newTreatment = {
        ...data,
        centre: centre,
      }
      if (treatmentSelected) {
        dispatch(updateTemplateTreatment(newTreatment))
        handleClose()
      } else {
        dispatch(createNewTemplateTreatment(newTreatment))
        handleClose()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <form className={s.containerForm} onSubmit={handleSubmit(onSubmit)}>
        <h5 className={s.subtitle}>Plantilla de tratamiento</h5>
        <Grid container>
          <Grid item md={4} className={s.inputForm}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  label="Nombre del tratamiento*"
                  variant="standard"
                  className={s.textField}
                  onKeyDown={handleKeyDown}
                />
              )}
              rules={{ required: true }}
            />
            {errors.name?.type === 'required' && <p className={s.formAlert}>Nombre requerido *</p>}
          </Grid>
          <Grid item md={4}>
            <Controller
              name="turnsCount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Cantidad de sesiones"
                  variant="standard"
                  className={s.textField}
                  onKeyDown={handleKeyDown}
                />
              )}
              rules={{
                max: {
                  value: 60,
                  message: 'Supera la cantidad maxima de sesiones permitida',
                },
              }}
            />
            {errors.turnsCount?.message && <p className={s.formAlert}>{errors.turnsCount?.message}</p>}
          </Grid>
          <Grid item md={4}>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="$ Monto total a pagar"
                  variant="standard"
                  className={s.textField}
                  onKeyDown={handleKeyDown}
                />
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField {...field} multiline type="text" label="Descripción" variant="standard" fullWidth />
              )}
            />
          </Grid>
        </Grid>

        <div className={s.containerBtnSave}>
          <CaluButton color="primary" size="medium" type="submit" loading={isLoading}>
            Guardar
          </CaluButton>
        </div>
      </form>
    </>
  )
}

export default FormCreateTemplateTreatment
