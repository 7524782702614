import React from 'react'
import TitlePopUp from '@/components/TitlePopUp'
import CardInformation from '@/pages/LandingCentreTargetPage/components/cardDoctorCentreTarget/components/cardInformation'
import { Dialog, Grid } from '@mui/material'

import dayjs from 'dayjs'

import s from '@/pages/LandingCentreTargetPage/components/cardDoctorCentreTarget/components/popUpShowMoreTurns/styles.module.scss'

const PopUpMoreTurnsAvailables = ({ open, setOpen, availabilityPerDay, daySelected, entity, handleConfirmTurn }) => {
  const handleClose = () => {
    setOpen(false)
  }

  const formatDateLong = (date) => {
    const dayNumber = dayjs(date).format('D')
    const dayName = dayjs(date).format('dddd')
    const month = dayjs(date).format('MMMM')
    return `${dayName} ${dayNumber} de ${month}`
  }

  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="lg" open={open} onClose={handleClose}>
        <div className={s.containerPopUp}>
          <TitlePopUp
            textTitle={`Disponibilidad para el día ${formatDateLong(daySelected)}`}
            handleClose={handleClose}
          />

          <div className={s.detailPopUp}>
            <CardInformation entity={entity} />
          </div>
          <Grid container spacing={2} className={s.containerTurns}>
            {availabilityPerDay?.map((day, index) => (
              <Grid key={index} item md={3} sm={4} xs={6} className={s.detailTurns}>
                <div className={s.itemTurn} onClick={() => handleConfirmTurn(daySelected, day)}>
                  <span>{day.startHour} hs</span>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </Dialog>
    </div>
  )
}

export default PopUpMoreTurnsAvailables
