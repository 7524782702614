// prettier-ignore
import { paymentMethods } from '@/helpers/constants'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { getTurnStateName } from '@/utils/functions/getTurnStateName'

import dayjs from 'dayjs'

export const adapterToExcel = (turns, centre, t, allDoctorCentres, allMachines) => {
  let allTurns = turns.filter((turn) => turn.patientCentre !== null)
  allTurns = JSON.parse(JSON.stringify(allTurns))
  allTurns.forEach((turn) => {
    if (turn.doctorCentre) {
      turn.doctorCentre = allDoctorCentres.filter((doctor) => doctor._id === turn.doctorCentre)[0]
    }
    if (turn.machine) {
      turn.machine = allMachines.filter((machine) => machine._id === turn.machine)[0]
    }
  })

  const checkLogDate = (turn, textState) => {
    const logIndex = turn.logs.findIndex(({ state }) => state === textState)
    if (logIndex >= 0) return dayjs(turn.logs[logIndex].date).format('DD-MM-YYYY HH:mm')
  }

  const calculatePayment = (turn, doctorCentre) => {
    let centrePaymentPercentage
    let centrePayment
    if (doctorCentre?.paymentType?.type === 'percentage') {
      centrePaymentPercentage = doctorCentre.paymentType.value || 0
      centrePayment = turn.treatment ? '—' : (turn.amount * centrePaymentPercentage) / 100
    }

    if (doctorCentre?.paymentType?.type === 'hour') {
      centrePaymentPercentage = '—'
      centrePayment = '—'
    }

    if (turn?.service?.centrePaymentPercentage) {
      centrePaymentPercentage = turn.service.centrePaymentPercentage || 0
      centrePayment = turn.treatment ? '—' : (turn.amount * centrePaymentPercentage) / 100
    }
    return { centrePaymentPercentage, centrePayment }
  }

  const getInsurance = (turn) => {
    if (!turn.insurance || !turn.patientCentre) return

    let insuranceIndex = turn.patientCentre.insurances.findIndex((insurance) => insurance._id === turn.insurance._id)
    if (insuranceIndex !== -1) {
      const insurancePlan = turn.patientCentre.insurancePlan[insuranceIndex]
      const policyNumber = turn.patientCentre.policyNumbers[insuranceIndex]
      return { insurancePlan, policyNumber }
    }
  }

  const showDeletedTurn = (turn) => {
    return `${capitalizeFirstLetters(t('turnLabel.sel'))} por ${turn?.logs?.[turn?.logs?.length - 1]?.by?.toUpperCase()}`
  }

  const turnOrigin = {
    patient: 'PACIENTE ONLINE',
    fastCreateCentre: 'PACIENTE SELECCIONÓ FECHA',
    fastCreateDoctor: 'PACIENTE SELECCIONÓ FECHA',
  }

  const showTurnServices = (turn) => {
    const extraServices = turn?.extraServices?.map((e) => e?.name).toString()

    if (!turn?.service && turn?.firstTime) return 'Primera consulta'
    if (!turn?.service && !turn?.firstTime) return 'Consulta'
    if (turn?.service && extraServices?.length === 0) {
      return capitalizeFirstLetters(turn?.service?.name)
    } else {
      return capitalizeFirstLetters(turn?.service?.name) + ',' + capitalizeFirstLetters(extraServices)
    }
  }

  return allTurns.map(({ ...turn }) => {
    return {
      // patient data
      Apellido: capitalizeFirstLetters(turn.patientCentre?.lastname || ''),
      Nombre: capitalizeFirstLetters(turn.patientCentre?.firstname || ''),
      Mail: turn.patientCentre?.email ? turn.patientCentre.email : null,
      Móvil: turn.patientCentre?.mobile ? turn.patientCentre.mobile : null,
      Teléfono: turn.patientCentre?.telephone ? turn.patientCentre.telephone : null,
      DNI: turn?.patientCentre?.dni !== 15 ? turn?.patientCentre?.dni : '',
      'Nota paciente': turn.patientCentre?.note,
      Comentario: turn.comment,
      // turn data
      Profesional: turn?.doctorCentre
        ? `${capitalizeFirstLetters(turn?.doctorCentre?.lastname)}, ${capitalizeFirstLetters(
            turn?.doctorCentre?.firstname,
          )}`
        : '',
      Equipo: turn?.machine ? capitalizeFirstLetters(turn?.machine.name) : '',
      Fecha: dayjs(turn.startHour).format('DD-MM-YYYY'),
      'Hora inicio': dayjs(turn.startHour).utc().format('HH:mm'),
      'Hora fin': dayjs(turn.finishHour).utc().format('HH:mm'),
      Duración: turn.consultationDuration,
      'Info adicional plan': getInsurance(turn)?.insurancePlan,
      'OS/Prepaga': turn?.insurance?.name || '',
      'Nro de afiliado': getInsurance(turn)?.policyNumber,
      'Bono/práctica': turn.insurancePrice ? 'Si' : 'No',
      'Bono autorizado': turn.insuranceAuthorization ? 'Si' : 'No',
      Servicio: showTurnServices(turn),
      Sesión: turn?.treatment ? 'Si' : 'No',
      'Monto a pagar': turn?.treatment ? '—' : turn?.amount,
      'Monto pagado': turn?.totalPayments,
      'Medio de pago': convertValueToName(paymentMethods, turn?.paymentHistory?.[0]?.method) || 'Efectivo',
      Deuda: turn.treatment ? '—' : turn.debt,
      'Extras (aplicados)':
        turn?.extraAmount?.type === 'minusCash' || turn?.extraAmount?.type === 'plusCash'
          ? turn?.extraAmount.value
          : '',
      'Descuentos (aplicados)':
        turn?.extraAmount?.type === 'minusPercentage' || turn?.extraAmount?.type === 'plusPercentage'
          ? turn?.extraAmount?.value
          : '',
      'Motivo extra/descuento': turn?.extraAmount?.description,
      'Comisión %': calculatePayment(turn, turn?.doctorCentre)?.centrePaymentPercentage,
      'Comisión $': calculatePayment(turn, turn?.doctorCentre)?.centrePayment,
      'Centro de salud': capitalizeFirstLetters(centre.name),
      Origen: turnOrigin[turn.origin] || turn.createdBy?.toUpperCase(),
      Estado: turn?.available === false ? showDeletedTurn(turn) : getTurnStateName(turn?.state),
      'Fecha creación': dayjs(turn.creationDate).format('DD-MM-YYYY HH:mm'),
      'Fecha "llegó paciente"': checkLogDate(turn, 'arrived'),
      'Fecha "pasó paciente"': checkLogDate(turn, 'happening'),
      'Fecha "finalizó"': checkLogDate(turn, 'finished'),
    }
  })
}
