import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { useUser } from '@/context/UserProvider'
import { postPatient } from '@/store/patients/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

import s from './styles.module.scss'

const FormCreateProfilePatient = ({ userData }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [dataNewPatient, setDataNewPatient] = useState({})

  const { updateUser } = useUser()

  const handleSubmit = async (data) => {
    setIsLoading(true)

    data.centre = userData.centres[0]
    try {
      const newPatientResponse = await dispatch(postPatient(data))
      setDataNewPatient(newPatientResponse)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (dataNewPatient?._id) {
      const auxData = {
        ...userData,
        patient: userData?.patient || dataNewPatient._id,
        roles: userData?.roles.includes('patient') ? userData?.roles : [...userData?.roles, 'patient'],
        dni: dataNewPatient?.dni,
      }
      updateUser(auxData, userData._id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataNewPatient])

  return (
    <ContainerForm
      className={s.containerForm}
      onSubmit={(data) => handleSubmit(data)}
      defaultValues={{ ...formDefaultValues, ...userData }}
      resolver={yupResolver(formValidationSchema)}
    >
      <FormContent isLoading={isLoading} userData={userData} />
    </ContainerForm>
  )
}

export default FormCreateProfilePatient
