import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import { CustomField } from '@/components/Form/customFields/CustomField'
import CaluButton from '@/components/styledComponents/CaluButton'
import { removeProduct } from '@/store/products/actions'
import { Grid } from '@mui/material'

import s from '../styles.module.scss'

export const FormContent = ({ isLoading = false, onClose }) => {
  const dispatch = useDispatch()
  const [openAlertDelete, setOpenAlertDelete] = useState(false)
  const { selectedProduct } = useSelector((state) => state?.products)

  const deletePlan = () => {
    dispatch(removeProduct(selectedProduct?._id))
    setOpenAlertDelete(false)
    onClose()
  }

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <CustomField label="Nombre del producto *" name="name" type="text" fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomField label="Descripción" name="description" type="text" fullWidth />
        </Grid>

        <Grid item xs={12} sm={4}>
          <CustomField label="Precio *" name="price" type="number" fullWidth />
        </Grid>

        <Grid item xs={12} className={s.containerButtonsForm}>
          {selectedProduct && (
            <>
              <CaluButton color="error" size="medium" onClick={() => setOpenAlertDelete(!openAlertDelete)}>
                Eliminar
              </CaluButton>
              <AlertDialogSlide
                title="Eliminar producto"
                open={openAlertDelete}
                setOpen={setOpenAlertDelete}
                textContent={'¿Está seguro que quiere eliminar este producto?'}
                textContentExtraOptional="Esta acción no se podrá deshacer."
                textBackButton="Cancelar"
                textConfirmationButton={'Eliminar'}
                onClose={deletePlan}
              />
            </>
          )}

          <CaluButton color="primary" size="medium" type="submit" loading={isLoading}>
            Guardar
          </CaluButton>
        </Grid>
      </Grid>
    </div>
  )
}
