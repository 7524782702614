import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'bankTransferInfo',
  initialState: {
    bankTransferInfo: null,
    isLoading: false,
  },
  reducers: {
    FETCH_BANKTRANSFER_INFO: (state, { payload }) => {
      state.bankTransferInfo = payload
    },
    LOADING_BANKTRANSFER: (state, { payload }) => {
      state.isLoading = payload
    },
  },
})

export const { FETCH_BANKTRANSFER_INFO, LOADING_BANKTRANSFER } = slice.actions
export default slice.reducer
