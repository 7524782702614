// @ts-check
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Layout1 from '@/layouts/Layout1'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import TabBirthdayMessage from './components/TabBirthdayMessage'
import TabConnection from './components/TabConnection'
import TabManagement from './components/TabManagement'
import TabTurnsHistory from './components/TabTurnsHistory'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 0.5 }}>{children}</Box>}
    </div>
  )
}

const WappPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(0)
  const status = useSelector((state) => state?.wapp?.status)

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }
  const isConnectedRef = useRef(false)
  const isDisconnectedRef = useRef(false)

  useEffect(() => {
    if (!isConnectedRef.current && status?.status === 'connected') {
      setValue(1)
      isConnectedRef.current = true
    }
    if (!isDisconnectedRef.current && status?.status !== 'connected') {
      setValue(0)
      isDisconnectedRef.current = true
    }
  }, [status])

  return (
    <Layout1 navbar isLoading={isLoading}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Módulo wapp"
            value={value}
            onChange={handleTabChange}
            sx={{
              '& .MuiTab-root.Mui-selected': {
                color: '#4C5EFE',
              },
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            <Tab label="Conexión" value={0} />
            <Tab label="Recordatorios" value={1} disabled={status?.status !== 'connected'} />
            <Tab label="Historial" value={2} />
            <Tab label="Mensaje de cumpleaños" value={3} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <TabConnection isLoading={isLoading} setIsLoading={setIsLoading} handleTabChange={handleTabChange} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TabManagement />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TabTurnsHistory />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TabBirthdayMessage />
        </TabPanel>
      </Box>
    </Layout1>
  )
}
export default WappPage
