import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'prescriptions',
  initialState: {
    allPrescriptions: [],
    selectedPrescription: null,
  },
  reducers: {
    FETCH_PRESCRIPTIONS: (state, { payload }) => {
      state.allPrescriptions = payload
    },
    PRESCRIPTION_UPDATED: (state, { payload }) => {
      state.allPrescriptions = state.allPrescriptions.map((clinicHistory) => {
        if (clinicHistory._id === payload._id) {
          clinicHistory = payload
        }
        return clinicHistory
      })
      state.selectedPrescription = payload
    },
    PRESCRIPTION_DELETE: (state, { payload }) => {
      state.allPrescriptions = state.allPrescriptions.filter((clinicHistory) => clinicHistory._id !== payload)
      state.selectedPrescription = null
    },
    PRESCRIPTION_CREATED: (state, { payload }) => {
      state.allPrescriptions = [payload, ...(state.allPrescriptions || [])]
      state.selectedPrescription = payload
    },
    PRESCRIPTION_SELECTED: (state, { payload }) => {
      state.selectedPrescription = payload
    },
    RESET_PRESCRIPTION_SELECTED: (state, { payload }) => {
      state.selectedPrescription = null
    },
  },
})

export const {
  FETCH_PRESCRIPTIONS,
  PRESCRIPTION_UPDATED,
  PRESCRIPTION_DELETE,
  PRESCRIPTION_CREATED,
  PRESCRIPTION_SELECTED,
  RESET_PRESCRIPTION_SELECTED,
} = slice.actions
export default slice.reducer
