import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import LogoCalu from '@/assets/images/logo-sm.png'
import AlertDialogSlide from '@/components/AlertDialogSlide/AlertDialogSlide'
import CaluButton from '@/components/styledComponents/CaluButton'
import TagState from '@/components/TagState'
import { days, months } from '@/helpers/daysAndMonths'
import { cancelTurnTimeToReprogram, setCancelledTurn, setConfirmedTurn } from '@/store/turns/actions'
import { availableToChangeTurnTime } from '@/utils/functions/availableToChangeTurnTime'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { getCentreSubCategory } from '@/utils/functions/getCentreSubCategory'
import { getTurnStateName } from '@/utils/functions/getTurnStateName'
import { toCustomTz } from '@/utils/functions/toCustomTz'

import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import CardTurnDetail from '../cardTurnDetail'

import s from './styles.module.scss'

const CardTurn = ({ turn }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('global')
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const [message, setMessage] = useState('')
  const [disabledButton, setDisabledButton] = useState(false)
  const [loadingCancelTurn, setLoadingCancelTurn] = useState(false)
  const [openAlertCancelTurn, setOpenAlertCancelTurn] = useState(false)
  const [loadingRescheduleTurn, setLoadingRescheduleTurn] = useState(false)
  const [loadingConfirmTurn, setLoadingConfirmTurn] = useState(false)
  const [openAlertRescheduleTurn, setOpenAlertRescheduleTurn] = useState(false)
  const [openInfoTurn, setOpenInfoTurn] = useState(false)

  const getCurrentDayTitle = () => {
    const parsedDate = dayjs(turn?.startHour)
    const dayTitle = `${capitalizeFirstLetters(days[parsedDate.get('day')])} ${parsedDate.get('date')} de ${
      months[parsedDate.get('month')]
    } ${parsedDate.get('year')}`

    return dayTitle
  }

  const cancelTurnByPatient = async () => {
    await dispatch(setCancelledTurn(turn))
  }

  const rescheduleTurnByPatient = () => {
    navigate(`/turnos/definir-horario/${turn?._id}`)
  }

  const clickToCalculateTimeToCancelOrReschedule = async () => {
    const dataTurn = await dispatch(cancelTurnTimeToReprogram(turn?._id))
    const result = availableToChangeTurnTime(dataTurn)
    return result
  }

  const handleCancelClick = async () => {
    if (!disabledButton) {
      setLoadingCancelTurn(true)
      const message = await clickToCalculateTimeToCancelOrReschedule()
      setLoadingCancelTurn(false)

      if (message?.code === 3) {
        setOpenAlertCancelTurn(!openAlertCancelTurn)
        return
      } else {
        setMessage(message?.text)
        setDisabledButton(true)
        return
      }
    }
  }

  const handleRescheduleClick = async () => {
    if (!disabledButton) {
      setLoadingRescheduleTurn(true)
      const message = await clickToCalculateTimeToCancelOrReschedule()
      setLoadingRescheduleTurn(false)
      if (message?.code === 3) {
        setOpenAlertRescheduleTurn(!openAlertRescheduleTurn)
        return
      } else {
        setMessage(message?.text)
        setDisabledButton(true)
        return
      }
    }
  }
  const handleConfirmTurn = async () => {
    setLoadingConfirmTurn(true)
    await dispatch(setConfirmedTurn(turn))
    setLoadingConfirmTurn(false)
  }

  const isPastTurn = () => {
    const now = dayjs(new Date()).format()
    const turnTime = dayjs(turn.startHour).format()
    if (turnTime < now) {
      return true
    } else {
      return false
    }
  }

  const showEntityName = () => {
    if (turn?.machine) {
      return turn.machine.name
    }
    if (turn?.doctorCentre) {
      return turn.doctorCentre.firstname + ' ' + turn.doctorCentre.lastname
    }
    return 'No especificado'
  }

  const showTurnState = () => {
    const { state } = turn
    return getTurnStateName(state)
  }

  const showEntityPractice = () => {
    if (!turn.service) {
      return !turn.firstTime ? 'Consulta' : 'Primera consulta'
    } else {
      return turn.service.name
    }
  }

  const restorePendingTurn = async (turn) => {
    const centreId = turn?.centre?._id
    navigate(`/centro/${centreId}`)
   
  }

  return (
    <div className={turn?.state === 'pending' ? s.pendingCard : s.containerCard}>
      <div className={s.headerCard}>
        <img src={turn?.centre?.logo || LogoCalu} alt="imagen" className={s.cardPhoto} />
        <div className={s.entityNameContainer}>
          <div className={s.entityName}>{capitalizeFirstLetters(turn?.centre?.name)}</div>
        </div>
      </div>

      <div className={s.dateContainer}>
        <div className={s.titleDate} style={showTurnState() && { opacity: '0.5' }}>
          <div>{getCurrentDayTitle()}</div>
          <div>{toCustomTz(turn?.startHour, undefined, true, 'HH:mm')} hs</div>
        </div>
        {showTurnState() && <TagState text={showTurnState()} />}
      </div>

      <div className={s.dataTurnContainer}>
        <div className={s.subHeader}>
          <span>Con: </span> <span className={s.practiceName}>{capitalizeFirstLetters(showEntityName())}</span>
        </div>
        <div className={s.subHeader}>
          <span>Práctica: </span> <span className={s.practiceName}>{capitalizeFirstLetters(showEntityPractice())}</span>
        </div>
        <div className={s.subHeader}>
          <span>{t(`subCategoryLabel.${getCentreSubCategory(centreDetail?.category)}.Patient`)}: </span>{' '}
          <span className={s.practiceName}>{`${capitalizeFirstLetters(
            turn?.patientCentre?.lastname,
          )}, ${capitalizeFirstLetters(turn?.patientCentre?.firstname)}`}</span>
        </div>
      </div>

      <CaluButton type="link" onClick={() => setOpenInfoTurn(true)}>
        {`Ver información de ${t('turnLabel.sl')}`}
      </CaluButton>

      {message !== '' && <div className={s.containerMessage}>{message}</div>}
      {isPastTurn() || turn?.state === 'cancelled' || turn?.state === 'absent' || turn?.state === 'pending' ? null : (
        <div className={s.containerButtons}>
          <CaluButton
            size="small"
            loading={loadingConfirmTurn}
            disabled={loadingConfirmTurn}
            onClick={handleConfirmTurn}
            className={s.confirmButton}
          >
            Confirmar {t('turnLabel.s')}
          </CaluButton>
          <CaluButton
            color="primary"
            size="small"
            loading={loadingRescheduleTurn}
            disabled={disabledButton}
            onClick={handleRescheduleClick}
            className={s.button}
          >
            Reprogramar {t('turnLabel.s')}
          </CaluButton>

          <CaluButton
            color="secondary"
            size="small"
            loading={loadingCancelTurn}
            disabled={disabledButton}
            onClick={handleCancelClick}
            className={s.button}
          >
            Cancelar
          </CaluButton>

          <AlertDialogSlide
            open={openAlertRescheduleTurn}
            setOpen={setOpenAlertRescheduleTurn}
            textContent={`¿Realmente quiere reprogramar ${t('turnLabel.se')}?`}
            textBackButton="Cancelar"
            textConfirmationButton={'Si, Reprogramar'}
            onClose={rescheduleTurnByPatient}
          />

          <AlertDialogSlide
            open={openAlertCancelTurn}
            setOpen={setOpenAlertCancelTurn}
            textContent={`¿Realmente quiere cancelar ${t('turnLabel.se')}?`}
            textBackButton="Volver"
            textConfirmationButton={'Si, cancelar'}
            onClose={cancelTurnByPatient}
          />
        </div>
      )}
      {turn?.state === 'pending' && (
        <div className={s.containerButtons}>
          <CaluButton color="secondary" size="small" className={s.button} onClick={() => restorePendingTurn(turn)}>
            Continuar reserva
          </CaluButton>
        </div>
      )}
      {openInfoTurn && <CardTurnDetail open={openInfoTurn} setOpen={setOpenInfoTurn} turn={turn} />}
    </div>
  )
}

export default CardTurn

CardTurn.propTypes = {
  turn: PropTypes.object.isRequired,
}
