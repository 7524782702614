import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const getMercadoPagoSubscription = async (params) => {
  const route = `api/mercadoPago/subscription`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getMercadoPagoSubscriptionPayments = async (params) => {
  const route = `api/mercadoPago/subscription/payments`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getMercadoPagoPaymentLink = async (params) => {
  const route = `api/mercadoPago/paymentLink`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postMercadoPagoSubscription = async (centreData) => {
  const route = `api/mercadoPago/subscription`
  return API.post(route, centreData)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postMercadoPagoPaymentLink = async (data) => {
  const route = `api/mercadoPago/paymentLink`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putMercadoPagoSubscription = async (data) => {
  const route = `api/mercadoPago/subscription/${data.mercadoPagoPreapprovalPlanId}`
  return API.put(route, data.body)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export {
  getMercadoPagoPaymentLink,
  getMercadoPagoSubscription,
  getMercadoPagoSubscriptionPayments,
  postMercadoPagoPaymentLink,
  postMercadoPagoSubscription,
  putMercadoPagoSubscription,
}
