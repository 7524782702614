import React from 'react'
import { useSelector } from 'react-redux'
import { getChedocClassWithState } from '@/utils/functions/getChedocClassWithState'
import { Grid } from '@mui/material'

import ButtonsContainer from './components/ButtonsContainer'
import TurnDateBox from './components/TurnDateBox'
import TurnInfoPatient from './components/TurnInfoPatient'
import { STurnDialogHeader } from './styles'

const TurnDialogHeader = ({
  tabValue,
  expandPopUp,
  setExpandPopUp,
  setSelectedDate,
  handleClose,
  reset,
  handleConfigDoctorCentresPanelClose,
  doctorCentres,
}) => {
  const { selectedTurn } = useSelector((state) => state.turns)

  return (
    <STurnDialogHeader>
      <Grid item className={`container ${getChedocClassWithState(selectedTurn?.state)}`}>
        <TurnInfoPatient doctorCentres={doctorCentres} />
        {tabValue === '1' && <TurnDateBox />}
        <ButtonsContainer expandPopUp={expandPopUp} setExpandPopUp={setExpandPopUp} handleClose={handleClose} />
      </Grid>
    </STurnDialogHeader>
  )
}

export default TurnDialogHeader
