import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import { getCentreMercadoPagoInfo } from '@/store/centreMercadoPagoInfo/actions'

import MercadoPagoCard from './components/MercadoPagoCard'
import MercadoPagoOptions from './components/MercadoPagoOptions'

import s from './styles.module.scss'

const TabPayments = () => {
  const dispatch = useDispatch()

  const { isLoading } = useSelector((state) => state?.centreMercadoPagoInfo)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  useEffect(() => {
    dispatch(getCentreMercadoPagoInfo(centreDetail?._id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centreDetail])

  return (
    <div className={s.mainContainer}>
      {isLoading ? (
        <LoaderCircle />
      ) : (
        <>
          <MercadoPagoCard />
          <MercadoPagoOptions />
        </>
      )}
    </div>
  )
}

export default TabPayments
