export const en = {
  "DEFAULT": {
    "turnLabel": {
      "s": "turno",
      "sa": "un turno",
      "sc": "turno cancelado",
      "Sco": "Turno confirmado",
      "sco": "turno está confirmado",
      "sca": "turno ha sido cancelado",
      "sd": "del turno",
      "se": "este turno",
      "sel": "turno eliminado",
      "sf": "turno finalizado",
      "spr": "turno previo",
      "scop": "turno copiado",
      "sg": "turno ha sido agendado",
      "sl": "el turno",
      "sn": "un nuevo turno",
      "sni": "ningún turno",
      "so": "otro turno",
      "sr": "reprogramado",
      "su": "último turno",
      "S": "Turno",
      "Sa": "Turno actualizado",
      "Sag": "Turno agendado",
      "Sc": "Turno más cercano",
      "Sca": "Turno cancelado",
      "Se": "Este turno",
      "Sl": "El turno",
      "Sel": "Turno eliminado",
      "p": "turnos",
      "pa": "los turnos",
      "PA": "Los turnos",
      "pc": "turnos cancelados",
      "pcr": "turnos creados",
      "pp": "próximos turnos",
      "ppa": "turnos pasados",
      "ppr": "turnos próximos",
      "ps": "turnos seleccionados",
      "psl": "los turnos seleccionados",
      "P": "Turnos",
      "Pc": "Turnos cancelados",
      "Pa": "Turnos agendados",
      "oc": "cancelado",
      "or": "reservado",
      "ors": "reservados",
      "nt": "nuevo turno",
      "Nt": "Nuevo turno",
      "el": "el mismo",
    },
    "insuranceLabel": {
      "s": "obra social",
      "sel": "obra social eliminada",
      "sl": "la obra social",
      "ss": "obra social seleccionada",
      "S": "Obra social",
      "Sc": "Obra social creada",
      "Sel": "Obra social eliminada",
      "Sm": "Obra social modificada",
      "Ss": "Obra social seleccionada",
      "p": "obras sociales",
      "pa": "las obras sociales",
      "pdi": "distintas obras sociales",
      "P": "Obras sociales",
      "Pac": "Obras sociales actualizadas",
      "frag": "obra-social",
    },
    "subCategoryLabel": {
      "Salud": {
       "patient": "paciente", 
       "Patient": "Paciente", 
       "patients": "pacientes", 
       "Patients": "Pacientes", 
      },
      "Belleza": {
       "patient": "cliente", 
       "Patient": "Cliente", 
       "patients": "clientes", 
       "Patients": "Clientes", 
      }
    }
  },
  'ARG': {
    "PLANS_URL": " https://www.calu.app/planes",
    "PRE_MOBILE": "549",
    "DNI_LABEL": "DI",
    "MONEY": '$',

  },
  "MEX": {
    "PLANS_URL": "https://www.calu.app/planes", 
    "PRE_MOBILE": "521",
    "DNI_LABEL": "CURP",
    "MONEY": '$',
    "turnLabel": {
      "s": "cita",
      "sa": "una cita",
      "sc": "cita cancelada",
      "sco": "cita está confirmada",
      "sca": "cita ha sido cancelada",
      "sd": "de la cita",
      "se": "esta cita",
      "sel": "cita eliminada",
      "sf": "cita finalizada",
      "spr": "cita previa",
      "scop": "cita copiada",
      "sg": "cita ha sido agendada",
      "sl": "la cita",
      "sn": "una nueva cita",
      "sni": "ninguna cita",
      "so": "otra cita",
      "sr": "reprogramada",
      "su": "última cita",
      "S": "Cita",
      "Sa": "Cita actualizada",
      "Sag": "Cita agendada",
      "Sc": "Cita más cercana",
      "Sca": "Cita cancelada",
      "Scl": "Cita más cercana",
      "Se": "Esta cita",
      "Sl": "La cita",
      "Sel": "Cita eliminada",
      "p": "citas",
      "pa": "las citas",
      "PA": "Las citas",
      "pds": "citas agendadas",
      "pc": "citas canceladas",
      "pcr": "citas creadas",
      "pp": "próximas citas",
      "ppa": "citas pasadas",
      "ppr": "citas próximas",
      "ps": "citas seleccionadas",
      "psl": "las citas seleccionadas",
      "P": "Citas",
      "Pc": "Citas canceladas",
      "Pa": "Citas agendadas",
      "oc": "cancelada",
      "or": "reservada",
      "ors": "reservadas",
      "nt": "nueva cita",
      "Nt": "Nueva cita",
      "el": "la misma",
    },
    "insuranceLabel": {
      "s": "seguro médico",
      "sel": "seguro médico eliminado",
      "sl": "el seguro médico",
      "ss": "seguro médico seleccionado",
      "S": "Seguro médico",
      "Sel": "Seguro médico eliminado",
      "Sc": "Seguro médico creado",
      "Sm": "Seguro médico modificado",
      "Ss": "Seguro médico seleccionado",
      "p": "seguros médicos",
      "pa": "los seguros médicos",
      "pdi": "distintos seguros médicos",
      "P": "Seguros médicos",
      "Pac": "Obras sociales actualizadas",
      "frag": "seguro-médico",
    }
  },
  "ESP": {
    "PRE_MOBILE": '34',
    "DNI_LABEL": 'DI',
    "MONEY": '€',
  },
  "CHL": {
    "PRE_MOBILE": '56',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
  "URY": {
    "PRE_MOBILE": '598',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
  "BRA": {
    "PRE_MOBILE": '55',
    "DNI_LABEL": 'DI',
    "MONEY": 'R$',
  },
  "PRY": {
    "PRE_MOBILE": '595',
    "DNI_LABEL": 'DI',
    "MONEY": '₲',
  },
  "PER": {
    "PRE_MOBILE": '51',
    "DNI_LABEL": 'DI',
    "MONEY": 'S/.',
  },
  "COL": {
    "PRE_MOBILE": '57',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
  "BOL": {
    "PRE_MOBILE": '591',
    "DNI_LABEL": 'DI',
    "MONEY": 'Bs',
  },
  "VEN": {
    "PRE_MOBILE": '58',
    "DNI_LABEL": 'DI',
    "MONEY": 'Bs F',
  },
  "ECU": {
    "PRE_MOBILE": '593',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
  "GBR": {
    "PRE_MOBILE": '44',
    "DNI_LABEL": 'DI',
    "MONEY": '€',
  },
  "USA": {
    "PRE_MOBILE": '1',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
  "NZL": {
    "PRE_MOBILE": '64',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
  "HON": {
    "PRE_MOBILE": '504',
    "DNI_LABEL": 'DI',
    "MONEY": 'L',
  },
  "PAN": {
    "PRE_MOBILE": '507',
    "DNI_LABEL": 'DI',
    "MONEY": 'B/',
  },
  "CRI": {
    "PRE_MOBILE": '506',
    "DNI_LABEL": 'DI',
    "MONEY": '₡',
  },
  "IRL": {
    "PRE_MOBILE": '353',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  }, 
  "DEU": {
    "PRE_MOBILE": '49',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
  "NLD": {
    "PRE_MOBILE": '31',
    "DNI_LABEL": 'DI', 
    "MONEY": '$',
  },
  "RUS": {
    "PRE_MOBILE": '7',
    "DNI_LABEL": 'DI',
    "MONEY": '₽',
  },
  "AUS": {
    "PRE_MOBILE": '61',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
  "DEN": {
    "PRE_MOBILE": '45',
    "DNI_LABEL": 'DI',
    "MONEY": 'kr',
  },
   "ISR": {
    "PRE_MOBILE": '972',
    "DNI_LABEL": 'DI',
    "MONEY": 'ILS',
  },
  "ITA": {
    "PRE_MOBILE": '39',
    "DNI_LABEL": 'DI',
    "MONEY": '€',
  },
  "SWI": {
    "PRE_MOBILE": '41',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
  "ARM": {
    "PRE_MOBILE": '374',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
  "CZE": {
    "PRE_MOBILE": '420',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
  "BEL": {
    "PRE_MOBILE": '32',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
  "FRA": {
    "PRE_MOBILE": '33',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
  "AND": {
    "PRE_MOBILE": '376',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
  "SLV": {
    "PRE_MOBILE": '503',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
   "SWE": {
    "PRE_MOBILE": '46',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
   "TUR": {
    "PRE_MOBILE": '90',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
   "UKR": {
    "PRE_MOBILE": '380',
    "DNI_LABEL": 'DI',
    "MONEY": '$',
  },
  "POR": {
    "PRE_MOBILE": '351',
    "DNI_LABEL": 'DI',
    "MONEY": '€',
  },
  "UAE": {
    "PRE_MOBILE": '971',
    "DNI_LABEL": 'DI',
    "MONEY": 'AED',
  },
}
