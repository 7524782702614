export const formDefaultValues = {
  dni: '',
  doctorCategory: '',
  doctorNumber: '',
  email: '',
  firstname: '',
  insurances: [],
  lastname: '',
  mobile: '',
  note: '',
  specialities: [],
  status: '',
  subspecialities: '',
  telephone: '',
  web: '',
  workPlaces: [
    {
      city: '',
      address: '',
    },
  ],
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: false,
  7: false,
}
