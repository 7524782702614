import { fetchDoctorProfile, postDoctorProfile, putDoctorProfile } from '@/services/doctorProfile'
import { GET_DOCTOR_PROFILE } from '@/store/doctorProfile/index'
import { showToast } from '@/utils/toast'

const addNewDoctorProfile = (data) => async (dispatch) => {
  try {
    const newDoctorProfile = await postDoctorProfile(data)
    showToast('Perfil profesional creado correctamente', 'success')
    return newDoctorProfile
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateDoctorProfile = (data) => async (dispatch) => {
  try {
    const updateDocProfile = await putDoctorProfile(data)
    const getDocProfile = await fetchDoctorProfile(updateDocProfile.email)
    dispatch(GET_DOCTOR_PROFILE(getDocProfile))
    showToast('Solicitud de perfil profesional actualizada', 'success')
    return updateDocProfile
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const getDoctorProfile = (email) => async (dispatch) => {
  try {
    const getDocProfile = await fetchDoctorProfile(email)
    dispatch(GET_DOCTOR_PROFILE(getDocProfile))
    return getDocProfile
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}
export { addNewDoctorProfile, getDoctorProfile, updateDoctorProfile }
