import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import CaluButton from '@/components/styledComponents/CaluButton'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import InfoIcon from '@mui/icons-material/Info'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import TaskAltIcon from '@mui/icons-material/TaskAlt'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const AprovedTurn = () => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()
  const { selectedService, turnData, day, centreTargetProfile } = useSelector((state) => state.turns?.onLineTurns)

  return (
    <>
      <div className={s.confirmTitle}>¡{t('turnLabel.Sco')}!</div>

      <div className={s.containerCard}>
        <TaskAltIcon className={s.checkIcon} />

        <div className={s.title}>Se ha confirmado tu {t('turnLabel.s')} del</div>
        <div className={s.dateTurn}>
          {dayjs(day).format('dddd DD')} de {dayjs(day).format('MMMM')} - {turnData?.startHour} hs
        </div>

        {selectedService?.indications?.length > 0 && (
          <div className={s.containerIndications}>
            <p className={s.infoIndications}>
              <i>
                <InfoIcon fontSize="large" />
              </i>{' '}
              Te recordamos las siguientes indicaciones para tu turno:
            </p>
            <ul>
              {selectedService?.indications.map((indication, i) => {
                return (
                  <li key={i}>
                    <i>
                      <ArrowRightIcon />
                    </i>
                    {capitalizeFirstLetters(indication)}
                  </li>
                )
              })}
            </ul>
          </div>
        )}

        {turnData?.paymentLink && (
          <div className={s.containerIndications}>
            <p className={s.infoIndications}>
              <i>
                <LocalAtmIcon fontSize="large" />
              </i>{' '}
              Puedes pagar tu cita utilizando el siguiente botón de pago:
            </p>

            <div className={s.contPayButton} onClick={() => window.open(turnData?.paymentLink, '_blank')}>
              <CaluButton size="large" style={{ backgroundColor: '#4287F5' }}>
                Pagar con MercadoPago
              </CaluButton>
            </div>
          </div>
        )}

        {!turnData?.paymentLink && selectedService?.paymentLink && (
          <div className={s.containerIndications}>
            <p className={s.infoIndications}>
              <i>
                <LocalAtmIcon fontSize="large" />
              </i>{' '}
              Puedes pagar ahora desde el siguiente botón de pago
            </p>
            <p className={s.extraInfo}>
              Por favor informar pago enviando el comprobante por mail a{' '}
              <a href={`mailto:${centreTargetProfile?.email}`}>
                {centreTargetProfile?.email || 'email del centro de salud'}
              </a>
            </p>
            <div className={s.contPayButton} onClick={() => window.open(selectedService?.paymentLink, '_blank')}>
              <CaluButton size="large">Pagar ahora</CaluButton>
            </div>
          </div>
        )}

        <CaluButton type="link" size="large" onClick={() => navigate('/turnos')}>
          Ir a mis {t('turnLabel.p')}
        </CaluButton>
      </div>
    </>
  )
}

export default AprovedTurn
