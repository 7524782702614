import React from 'react'
import CaluButton from '@/components/styledComponents/CaluButton'
import { subscriptionStatus } from '@/helpers/constants'
import { Grid, MenuItem, Select, TextField } from '@mui/material'

import ExportOptions from './components/ExportOptions'
import ImportOptions from './components/ImportOptions'

import s from './styles.module.scss'

const AdminSearchBar = ({ addNewCentre, inputSearch, setInputSearch, selectedStatus, setSelectedStatus }) => {
  return (
    <Grid
      container
      spacing={1}
      className={s.mainContainer}
      sx={{
        '& .MuiGrid-root.MuiGrid-item': {
          padding: '0px',
          paddingTop: '5px',
        },
        '& .MuiInput-underline:before': {
          borderBottomColor: '#ffffff8f',
        },

        '& .MuiInput-underline:after': {
          borderBottomColor: '#f5a645', // Solid underline on focus
        },
      }}
    >
      <Grid item xs={6} md={3} className={s.searchBarContainer}>
        <TextField
          sx={{ input: { color: '#fff', fontSize: '14px' } }}
          className={s.searchInput}
          id="search"
          size="small"
          autoComplete="off"
          label="Buscar centro"
          variant="standard"
          fullWidth={true}
          type="text"
          autoFocus
          value={inputSearch}
          onChange={(e) => setInputSearch(e.target.value)}
          InputLabelProps={{
            style: { color: '#fff', fontSize: '14px' },
          }}
        />
      </Grid>
      <Grid item container xs={12} md={9} spacing={1} className={s.containerButtons}>
        <Grid item xs={12} md={6} className={s.containerFilterSelects}>
          <Select
            onChange={(e) => setSelectedStatus(e.target.value)}
            variant="standard"
            value={selectedStatus}
            defaultValue={'all'}
            sx={{
              color: 'white',
              fontSize: '14px',
              width: '150px',
              '.MuiSvgIcon-root ': {
                fill: 'white !important',
              },
            }}
          >
            <MenuItem value={'all'}>Todos los estados</MenuItem>
            {subscriptionStatus?.map((plan) => (
              <MenuItem key={plan.value} value={plan.value}>
                {`${plan.name}`}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={6} className={s.containerButtonNewEntry}>
          <ImportOptions />
          <ExportOptions />
          <CaluButton color="primary" size="medium" onClick={addNewCentre}>
            Crear centro
          </CaluButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AdminSearchBar
