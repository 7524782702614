import React from 'react'
import { Close } from '@mui/icons-material'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { Grid, IconButton } from '@mui/material'

const ButtonsContainer = ({ expandPopUp, setExpandPopUp, handleClose }) => {
  return (
    <Grid item className="buttonsContainer">
      <IconButton className="closeButton" onClick={() => setExpandPopUp(!expandPopUp)}>
        <OpenInFullIcon />
      </IconButton>
      <IconButton className="closeButton" onClick={handleClose}>
        <Close />
      </IconButton>
    </Grid>
  )
}

export default ButtonsContainer
