import * as React from 'react'
import TitlePopUp from '@/components/TitlePopUp'
import { DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FormCreateNewPlanPrices from '../formCreateNewPlanPrices'

export default function FormDialog({ open, planSelected, onClose }) {
  return (
    <div>
      <Dialog disableEscapeKeyDown={true} maxWidth="md" fullWidth open={open} onClose={onClose} scroll="paper">
        <DialogTitle style={{ padding: '0px' }}>
          <TitlePopUp textTitle={planSelected ? 'Datos del plan' : 'Nuevo plan'} handleClose={onClose} />
        </DialogTitle>
        <DialogContent>
          <FormCreateNewPlanPrices onClose={onClose} formValues={planSelected} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
