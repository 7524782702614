import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import appointmentLogo from '@/assets/images/appointment.png'
import SelectWithInput from '@/components/SelectWithInput/SelectWithInput.jsx'
import CaluButton from '@/components/styledComponents/CaluButton'
import useValidateURLId from '@/hooks/useValidateURLId'
import { createNewPatientTreatment } from '@/store/treatments/actions'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Grid, TextField } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import s from './styles.module.scss'

const NewPatientTreatmentForm = ({ patientSelected }) => {
  const [treatmentSelected, setTreatmentSelected] = useState('-')
  const centreTreatments = useSelector((state) => state.treatments?.allTreatments)
  const centre = useValidateURLId()
  const dispatch = useDispatch()

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      turnsCount: '',
      price: '',
      description: '',
      _id: null,
    },
  })

  useEffect(() => {
    if (treatmentSelected === 'sin plantilla') {
      reset()
    }
    if (treatmentSelected !== '-' && treatmentSelected !== 'sin plantilla') {
      reset()
      const treatment = centreTreatments.find((treatment) => treatment._id === treatmentSelected)
      if (treatment) {
        Object.keys(treatment).forEach((k) => {
          if (treatment[k] === null) {
            setValue(k, '')
          } else {
            setValue(k, treatment[k])
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentSelected])

  const onSubmit = (data) => {
    const newPatientTreatment = {
      centre: centre,
      patientCentre: patientSelected,
      name: data.name,
      turnsCount: parseInt(data.turnsCount),
      price: parseInt(data.price),
      description: data.description,
      paidPrice: 0,
    }
    try {
      dispatch(createNewPatientTreatment(newPatientTreatment))
      setTreatmentSelected('-')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Accordion className={s.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={s.accordionSummary}
      >
        <div className={s.divSessionsContainer}>
          <img src={appointmentLogo} alt="ficha" className={s.imageFont} />

          <span className={s.newTreatmentTitle}>Nuevo grupo de sesiones</span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Controller
          name="treatments"
          control={control}
          render={({ field }) => (
            <SelectWithInput
              inputLabel="Seleccione una plantilla de sesiones o cree uno de cero"
              valueSelect={treatmentSelected}
              onChange={(e) => setTreatmentSelected(e.target.value)}
              options={centreTreatments}
              firstOptionValue="sin plantilla"
              firstOptionText="Sin plantilla"
            />
          )}
          defaultValue={[]}
        />
        {treatmentSelected !== '-' && (
          <form className={s.containerForm} onSubmit={handleSubmit(onSubmit)}>
            <Grid container className={s.newPatientTreatmentContainer}>
              <Grid item xs={12} md={4} className={s.inputForm}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="Nombre del grupo de sesiones*"
                      variant="standard"
                      className={s.textField}
                    />
                  )}
                  rules={{ required: true }}
                />
                {errors.name?.type === 'required' && <p className={s.formAlert}>Nombre requerido *</p>}
              </Grid>
              <Grid item xs={12} md={4} className={s.inputForm}>
                <Controller
                  name="turnsCount"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      label="Cantidad de sesiones"
                      variant="standard"
                      className={s.textField}
                      onWheel={(e) => e.target.blur()}
                    />
                  )}
                  rules={{
                    max: {
                      value: 60,
                      message: 'Supera la cantidad maxima de sesiones permitida',
                    },
                  }}
                />
                {errors.turnsCount?.message && <p className={s.formAlert}>{errors.turnsCount?.message}</p>}
              </Grid>
              <Grid item xs={12} md={4} className={s.inputForm}>
                <Controller
                  name="price"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      label={`${handleSetMoneySign(country)} Monto total a pagar`}
                      variant="standard"
                      className={s.textField}
                      onWheel={(e) => e.target.blur()}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} className={s.inputForm}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="Descripción"
                      variant="standard"
                      fullWidth
                      multiline
                      maxRows={15}
                    />
                  )}
                />
              </Grid>
              <div className={s.containerBtnSave}>
                <CaluButton color="primary" type="submit" size="small">
                  Guardar
                </CaluButton>
              </div>
            </Grid>
          </form>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default NewPatientTreatmentForm
