import { fetchStatistics } from '@/services/statistics'

export const setStatisticsController = async (params) => {
  try {
    const fetchAllStatistics = await fetchStatistics(params)
    return fetchAllStatistics
  } catch (error) {
    console.log(error.message)
    return error
  }
}
