import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import CaluButton from '@/components/styledComponents/CaluButton'
import CaluInput from '@/components/styledComponents/CaluInput'
import { createUserNoLogin, getVerificationData } from '@/store/users/actions'
import { capitalizeFirstLetters } from '@/utils/functions/capitalizeFirstLetters'
import { parsedDniValue } from '@/utils/functions/parsedDniValue'
import { Grid } from '@mui/material'

import PropTypes from 'prop-types'

import s from './FormRegisterNewDoctorOrSecretary.module.scss'

const FormRegisterNewDoctorOrSecretary = ({ role, typeOfUser }) => {
  const dispatch = useDispatch()
  const TOKEN = useParams().TOKEN

  const [isLoading, setIsLoading] = useState(false)
  const [showErrorPasswords, setShowErrorPasswords] = useState(false)

  const { verificationData } = useSelector((state) => state.users)

  const setInit = async () => {
    await dispatch(getVerificationData(TOKEN, role))
  }

  useEffect(() => {
    if (TOKEN && role) setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      dni: '',
      password: '',
      repitePassword: '',
    },
  })

  const onSubmit = async (data, e) => {
    e.preventDefault()
    setIsLoading(true)

    if (data.password !== data.repitePassword) {
      setShowErrorPasswords(true)
      return
    } else {
      setShowErrorPasswords(false)
    }

    delete data.repitePassword

    data.firstname = verificationData?.firstname || ''
    data.lastname = verificationData?.lastname || ''
    data.gender = verificationData?.gender || 'female'
    data.email = verificationData?.email

    data.roles = [role]

    try {
      const response = await dispatch(createUserNoLogin(data, TOKEN, role))
      return response
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={s.cardContainer}>
      <div className={s.loginTitleContainer}>
        <div className={s.loginTitle}>Registro de {typeOfUser}</div>
      </div>

      <div className={s.contTagP}>
        <p>
          Hola <span className={s.bold}>{capitalizeFirstLetters(verificationData?.firstname)}</span>, desde el centro de
          salud <span className={s.bold}>{capitalizeFirstLetters(verificationData?.centre?.name)}</span> te crearon un
          nuevo perfil para que disfrutes de las funcionalidades de Calu.
        </p>
        <p>Por favor, completa los siguientes datos para comenzar a utilizar tu agenda:</p>
      </div>

      <form className={s.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="dni"
              control={control}
              render={({ field }) => (
                <CaluInput
                  {...field}
                  value={parsedDniValue(field.value)}
                  type="text"
                  labelText="DNI*"
                  onWheel={(e) => e.target.blur()}
                />
              )}
              rules={{
                required: true,
                minLength: {
                  value: 4,
                  message: 'Debe contener al menos 4 caracteres',
                },
                maxLength: {
                  value: 14,
                  message: 'Este campo debe tener máximo 14 caracteres.',
                },
              }}
            />
            {errors.dni?.message && <p className={s.formAlert}>{errors.dni?.message}</p>}
            {errors.dni?.type === 'required' && <p className={s.formAlert}>DNI requerido</p>}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => <CaluInput {...field} type="password" labelText="Contraseña *" />}
              rules={{ required: true }}
            />
            {errors.password?.type === 'required' && <p className={s.formAlert}>Contraseña requerida</p>}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="repitePassword"
              control={control}
              render={({ field }) => <CaluInput {...field} type="password" labelText="Repite contraseña *" />}
              rules={{ required: true }}
            />
            {errors.repitePassword?.type === 'required' && <p className={s.formAlert}>Contraseña requerida</p>}
          </Grid>
        </Grid>

        {showErrorPasswords && (
          <div className={s.containerShowErrors}>
            <b>Las contraseñas deben coincidir</b>
            <b>Vuelve a intentarlo o escribenos por el Chat para que te ayudemos</b>
          </div>
        )}

        <div className={s.contTagP}>
          <p>
            Para conectarte deberás utilizar tu email (<span className={s.bold}>{verificationData?.email}</span>) y la
            contraseña que ingresaste anteriormente.
          </p>
        </div>

        <div className={s.containerBtn}>
          <CaluButton type="submit" color="primary" size="large" loading={isLoading} disabled={isLoading}>
            Crear cuenta
          </CaluButton>
        </div>
      </form>
    </div>
  )
}

export default FormRegisterNewDoctorOrSecretary

FormRegisterNewDoctorOrSecretary.propTypes = {
  role: PropTypes.string.isRequired,
  typeOfUser: PropTypes.string.isRequired,
}
