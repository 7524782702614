import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ContainerForm } from '@/components/Form/ContainerForm'
import { postcentreConfiguration, updatecentreConfiguration } from '@/store/centreConfigurations/actions'
import { updateCentreAdmin } from '@/store/centres/actions'
import { yupResolver } from '@hookform/resolvers/yup'

import { formDefaultValues } from './formConfig'
import { FormContent } from './formContent'
import { formValidationSchema } from './formValidation'

const FormInfoSubscription = ({ formValues }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [suggestedPrice, setSuggestedPrice] = useState('')
  const [finalPrice, setFinalPrice] = useState('')

  const handleSubmit = async (data) => {
    setIsLoading(true)
    let auxCentreConfiguration

    if (!formValues?.centreConfiguration) {
      const centreConfigData = {
        centre: data?._id,
        ...data.centreConfiguration,
      }
      const centreConfig = await dispatch(postcentreConfiguration(centreConfigData, true))
      auxCentreConfiguration = centreConfig
    } else {
      auxCentreConfiguration = data.centreConfiguration
      await dispatch(updatecentreConfiguration(auxCentreConfiguration))
    }

    data.centreConfiguration = auxCentreConfiguration

    try {
      await dispatch(updateCentreAdmin(data))
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <div>
      <ContainerForm
        onSubmit={(data) => handleSubmit(data)}
        defaultValues={{ ...formDefaultValues, ...formValues }}
        resolver={yupResolver(formValidationSchema)}
      >
        <FormContent
          isLoading={isLoading}
          suggestedPrice={suggestedPrice}
          setSuggestedPrice={setSuggestedPrice}
          finalPrice={finalPrice}
          setFinalPrice={setFinalPrice}
        />
      </ContainerForm>
    </div>
  )
}

export default FormInfoSubscription
