import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import NavBar from '@/components/NavBar'
import CaluButton from '@/components/styledComponents/CaluButton'
import { getBankTransferInfo, updateInfoBankTransfer } from '@/store/bankTransferInfo/actions'
import { TextField } from '@mui/material'

import s from './styles.module.scss'

const AdminPanelBankTransferInfo = () => {
  const dispatch = useDispatch()

  const { bankTransferInfo, isLoading } = useSelector((state) => state?.bankTransferInfo)

  const [infoBank, setInfoBank] = useState('')
  const [loadingDataBank, setLoadingDataBank] = useState(false)

  const setInit = async () => {
    setLoadingDataBank(true)
    await dispatch(getBankTransferInfo())
    setLoadingDataBank(false)
  }

  useEffect(() => {
    setInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setInfoBank(bankTransferInfo?.info)
  }, [bankTransferInfo])

  const handleUpdateInfoBank = async () => {
    const dataUpdateInfoBank = {
      ...bankTransferInfo,
      info: infoBank,
    }
    await dispatch(updateInfoBankTransfer(dataUpdateInfoBank))
  }

  return (
    <div className={s.container}>
      <NavBar />
      {loadingDataBank ? (
        <div className={s.circularProgress}>
          <LoaderCircle size={50} />
        </div>
      ) : (
        <div className={s.cardContainer}>
          <div className={s.title}>Datos bancarios</div>
          <div>
            <TextField
              multiline
              value={infoBank || ''}
              type="text"
              onChange={(e) => setInfoBank(e.target.value)}
              fullWidth
              variant="standard"
            />
          </div>
          <div className={s.contButton}>
            <CaluButton
              color="primary"
              size="medium"
              disabled={!infoBank || isLoading}
              loading={isLoading}
              onClick={handleUpdateInfoBank}
            >
              Guardar
            </CaluButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdminPanelBankTransferInfo
