import React from 'react'
import CaluModal from '@/components/CaluModal'
import CaluButton from '@/components/styledComponents/CaluButton'

import s from './styles.module.scss'

const NoMercadoPagoConnectionPopUp = ({ open, setOpen, centreDetail }) => {
  const showTitle = () => {
    return <div>Bienvenido a Calu!</div>
  }

  const saveVariableInLocalStorage = () => {
    const currentDate = new Date()
    localStorage.setItem('noMercadoPagoConnectionModal', currentDate.toISOString())
  }

  const handleInitSubscription = () => {
    saveVariableInLocalStorage()
    setOpen(false)
  }

  return (
    <CaluModal open={open} setOpen={setOpen} title={showTitle()} functionInHandleClose={saveVariableInLocalStorage}>
      <div>
        <p>
          ¡Estás a un paso de volver tu trabajo mucho más sencillo! Te pedimos que te suscribas al débito automático
          presionando el siguiente botón para comenzar a disfrutar del sistema.
        </p>

        <div className={s.containerLink}>
          <a
            href={centreDetail?.centreConfiguration?.mercadoPagoPreapprovalPlanInitPoint}
            rel="noreferrer"
            target="_blank"
          >
            <CaluButton color="primary" onClick={handleInitSubscription} size="large">
              Iniciar suscripción
            </CaluButton>
          </a>
        </div>
      </div>
    </CaluModal>
  )
}

export default NoMercadoPagoConnectionPopUp
