import React from 'react'
import { useDispatch } from 'react-redux'
import logo from '@/assets/images/home.png'
import { selectedRoom } from '@/store/rooms/actions'
import { Button } from '@mui/material'

import s from './styles.module.scss'

const ContentSideBar = ({ listItem, allRooms }) => {
  const dispatch = useDispatch()

  return (
    <div>
      {listItem === null ? (
        <div>No hay espacios cargados aún</div>
      ) : (
        listItem?.map((item) => (
          <div key={item._id}>
            <Button className={s.rooms} onClick={async () => await dispatch(selectedRoom(allRooms, item._id))}>
              <div>
                <img src={logo} alt="rooms" />
              </div>
              <div>
                <p className={s.roomName}>{item?.name?.toUpperCase()}</p>
                {item.people?.length > 0 ? (
                  <p className={s.doctorName}>{item?.people[0]?.name?.toUpperCase()} asignado</p>
                ) : (
                  <p className={s.doctorName}>NADIE asignado</p>
                )}
              </div>
            </Button>
          </div>
        ))
      )}
    </div>
  )
}

export default ContentSideBar
