import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import PropTypes from 'prop-types'
import Notes from './components/notes'
import WorkingHours from './components/workingHours'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const FormUsefulInformation = ({ handleClose }) => {
  const [valuePanel, setValuePanel] = useState(0)

  const handleChangePanel = (event, newValue) => {
    setValuePanel(newValue)
  }

  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isCentre, isAdmin, isSecretary } = usersProfileConnected

  const showInternalNoteTab = isCentre || isSecretary || isAdmin

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={valuePanel}
            onChange={handleChangePanel}
            aria-label="Información útil"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              '& .MuiTab-root.Mui-selected': {
                color: '#009688',
              },
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            <Tab label="Horarios laborales" />
            {showInternalNoteTab ? <Tab label="Notas" /> : null}
          </Tabs>
        </Box>
      </Box>

      <TabPanel value={valuePanel} index={0}>
        <WorkingHours />
      </TabPanel>

      <TabPanel value={valuePanel} index={1}>
        <Notes />
      </TabPanel>
    </div>
  )
}

export default FormUsefulInformation
