import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'specialities',
  initialState: {
    specialitiesList: [],
    specialitySelected: null,
  },
  reducers: {
    FETCH_SPECIALITIES: (state, { payload }) => {
      state.specialitiesList = payload
    },
    SPECIALITY_SELECTED: (state, { payload }) => {
      state.specialitySelected = payload
    },
    RESET_SPECIALITY_SELECTED: (state, { payload }) => {
      state.specialitySelected = null
    },
    SPECIALITY_CREATED: (state, { payload }) => {
      state.specialitiesList = [...(state?.specialitiesList || []), payload]
    },
    SPECIALITY_UPDATED: (state, { payload }) => {
      state.specialitiesList = state?.specialitiesList?.map((speciality) => {
        if (speciality?._id === payload?._id) {
          speciality = payload
        }
        return speciality
      })
      state.specialitySelected = payload
    },
    SPECIALITY_DELETE: (state, { payload }) => {
      state.specialitiesList = state?.specialitiesList?.filter((speciality) => speciality?._id !== payload)
      state.specialitySelected = null
    },
  },
})

export const {
  FETCH_SPECIALITIES,
  SPECIALITY_SELECTED,
  RESET_SPECIALITY_SELECTED,
  SPECIALITY_CREATED,
  SPECIALITY_DELETE,
  SPECIALITY_UPDATED,
} = slice.actions
export default slice.reducer
