import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const getCentreInsurances = async (centreId) => {
  const route = `api/centreInsurances/${centreId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewCentreInsurances = async (data) => {
  const route = `api/centreInsurances`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deleteCentreInsurances = async (centreId) => {
  const route = `api/centreInsurances/${centreId}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putCentreInsurances = async (data) => {
  const route = `api/centreInsurances`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { addNewCentreInsurances, deleteCentreInsurances, getCentreInsurances, putCentreInsurances }
