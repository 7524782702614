import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import TitlePopUp from '@/components/TitlePopUp'
import { markNotificationAsRead } from '@/store/notifications/actions'
import { SET_NOTIFICATIONS_STATE } from '@/store/notifications/index'
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'

import s from './styles.module.scss'

const PopUpUnreadNotifications = ({ open, setOpen, unreadNotifications }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (unreadNotifications.length === 0) {
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadNotifications])

  const handleClose = () => {
    setOpen(false)
    dispatch(SET_NOTIFICATIONS_STATE(false))
  }

  const handleNotificationRead = (notificationId) => {
    dispatch(markNotificationAsRead(notificationId))
  }

  return (
    <>
      <Dialog disableEscapeKeyDown={true} open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle id="scroll-dialog-title" className={s.title}>
          <TitlePopUp textTitle={`Nuevas notificaciones`} handleClose={handleClose} />
        </DialogTitle>
        <DialogContent>
          <div className={s.divContainer}>
            {unreadNotifications?.map((notification, index) => (
              <div className={s.notificationsContainer} key={index}>
                <div className={s.text}>
                  <div dangerouslySetInnerHTML={{ __html: notification.message }} className={s.textNotification} />
                </div>
                <div>
                  <Button
                    variant="contained"
                    className={s.colorButton}
                    onClick={() => handleNotificationRead(notification._id)}
                  >
                    MARCAR LEIDA
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PopUpUnreadNotifications
