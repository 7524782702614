import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'

const LinealProgress = () => {
  return (
    <Stack sx={{ width: '100%', color: 'grey.500', marginTop: '5px' }} spacing={3}>
      <LinearProgress color="primary" />
    </Stack>
  )
}

export default LinealProgress
