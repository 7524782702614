import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CaluButton from '@/components/styledComponents/CaluButton'
import CaluInput from '@/components/styledComponents/CaluInput'
import { selectedCentreProvider } from '@/store/centreProvider/actions'
import { Grid } from '@mui/material'

import s from '../styles.module.scss'

const HeaderSearchBar = ({ input, setInput, setOpenPopUp }) => {
  const dispatch = useDispatch()

  const { selectedProvider } = useSelector((state) => state.centreProvider)

  const inputOnChange = (e) => {
    setInput(e.target.value)
  }

  const addNewEntry = () => {
    if (selectedProvider !== null) {
      dispatch(selectedCentreProvider(null))
    }
    setOpenPopUp(true)
  }

  return (
    <Grid container spacing={1} className={s.mainContainer}>
      <Grid item xs={12} md={3} className={s.searchBarContainer}>
        <CaluInput value={input} onChange={inputOnChange} labelText="Buscar proveedor" />
      </Grid>

      <Grid item xs={12} md={3}>
        <CaluButton onClick={addNewEntry} type="add" variant="contained" size="medium">
          Crear proveedor
        </CaluButton>
      </Grid>
    </Grid>
  )
}

export default HeaderSearchBar
