import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  allPatients: [],
  paginationData: {
    page: 1,
    limit: 30,
  },
  patientSelected: null,
  isLoading: false,
}
const slice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    FETCH_PATIENTS: (state, { payload }) => {
      state.allPatients = payload.allPatients
      state.paginationData = payload.paginationData
    },
    PATIENT_UPDATED: (state, { payload }) => {
      state.allPatients = state.allPatients.map((patient) => {
        if (patient._id === payload._id) {
          patient = payload
        }
        return patient
      })
      state.patientSelected = payload
    },
    PATIENT_DELETE: (state, { payload }) => {
      state.allPatients = state.allPatients.filter((patient) => patient._id !== payload)
      state.patientSelected = null
    },
    PATIENT_CREATED: (state, { payload }) => {
      state.allPatients = [...(state.allPatients || []), payload]
    },
    PATIENT_SELECTED: (state, { payload }) => {
      state.patientSelected = payload
    },
    RESET_PATIENT_SELECTED: (state) => {
      state.patientSelected = null
    },
    SET_PATIENT_SELECTED: (state, { payload }) => {
      state.patientSelected = payload
    },
    RESET_PATIENT_STATE: () => initialState,
    IS_LOADING_PATIENTS: (state, { payload }) => {
      state.isLoading = payload
    },
  },
})

export const {
  FETCH_PATIENTS,
  PATIENT_UPDATED,
  PATIENT_DELETE,
  PATIENT_CREATED,
  PATIENT_SELECTED,
  RESET_PATIENT_SELECTED,
  RESET_PATIENT_STATE,
  IS_LOADING_PATIENTS,
  SET_PATIENT_SELECTED,
} = slice.actions
export default slice.reducer
