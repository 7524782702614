import { useController } from 'react-hook-form'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material'

import PropTypes from 'prop-types'

export const CheckboxField = ({
  className,
  checkBoxColor,
  label,
  name,
  checkedLabel = '',
  uncheckedLabel = '',
  inverted = false,
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({ name })

  const getIcon = (value, inverted) => {
    const iconProps = {
      style: { verticalAlign: 'middle', marginRight: '5px' },
    }

    if ((value && !inverted) || (!value && inverted)) {
      return <AiFillCheckCircle {...iconProps} />
    }
    return <AiFillCloseCircle {...iconProps} />
  }

  const getColorLabel = (value, inverted) => {
    if ((value && !inverted) || (!value && inverted)) {
      return '#1ab395'
    }
    return '#ed5564'
  }

  const labelText = value ? checkedLabel : uncheckedLabel
  const icon = getIcon(value, inverted)
  const colorLabel = getColorLabel(value, inverted)

  return (
    <>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              onBlur={onBlur}
              ref={ref}
              value={value}
              checked={value}
              onChange={onChange}
              sx={{
                '&.Mui-checked': {
                  color: checkBoxColor,
                },
              }}
            />
          }
          label={
            <p style={{ margin: '0px', display: 'flex' }} className={className}>
              {label}
              {checkedLabel && uncheckedLabel && (
                <span
                  style={{
                    marginLeft: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '14px',
                    color: colorLabel,
                  }}
                >
                  {icon} {labelText}
                </span>
              )}
            </p>
          }
        />
        {Boolean(error) && <FormHelperText error>{error?.message}</FormHelperText>}
      </FormControl>
    </>
  )
}

CheckboxField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  checkedLabel: PropTypes.string,
  uncheckedLabel: PropTypes.string,
  inverted: PropTypes.bool,
}

CheckboxField.defaultProps = {}
