import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'centreConfigurations',
  initialState: {
    centreConfigurationDetail: null,
  },
  reducers: {
    CENTRE_CONFIGURATION_SELECTED: (state, { payload }) => {
      state.centreConfigurationDetail = payload
    },
    CENTRE_CONFIGURATION_UPDATED: (state, { payload }) => {
      if (state?.centreConfigurationDetail?._id === payload?._id) {
        state.centreConfigurationDetail = payload
      }
    },
    CENTRE_CONFIGURATION_DELETE: (state, { payload }) => {
      state.centreConfigurationDetail = null
    },
    CENTRE_CONFIGURATION_CREATED: (state, { payload }) => {
      state.centreConfigurationDetail = payload
    },
  },
})

export const {
  CENTRE_CONFIGURATION_SELECTED,
  CENTRE_CONFIGURATION_UPDATED,
  CENTRE_CONFIGURATION_DELETE,
  CENTRE_CONFIGURATION_CREATED,
} = slice.actions
export default slice.reducer
