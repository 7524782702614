// DEPRECATE THIS FUNCTION
// USE NEW FUNCTION: helpers/calculateTurnAmountWithoutExtraDiscount

export const handleCalculateTurnAmount = (turn) => {
  if (turn?.amount === 0) return 0
  let amount = 0

  if (turn?.treatment) {
    amount = Number(turn?.treatment?.price)
  }

  if (!turn?.service) {
    if (!turn?.firstTime) {
      amount = Number(turn.doctorCentre?.[0]?.price) || 0
    } else {
      amount = Number(turn.doctorCentre?.[0]?.priceFirstTime) || 0
    }
  }

  if (turn?.service) {
    amount = Number(turn?.service?.price) || 0

    if (turn?.extraServices?.length > 0) {
      const extraServicesPrice = turn?.extraServices?.reduce((a, b) => Number(a) + (Number(b.price) || 0), 0)
      amount = amount + extraServicesPrice
    }
  }

  return amount
}
