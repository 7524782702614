const getAuthToken = () => {
  try {
    const authToken = localStorage.getItem('token')
    return authToken
  } catch (error) {
    return null
  }
}

const attachAuthToken = (config) => {
  let request = {}
  const authToken = getAuthToken()
  if (authToken) {
    request = {
      ...config,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  } else {
    request = {
      ...config,
    }
  }

  return request
}

export { attachAuthToken, getAuthToken }
