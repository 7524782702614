import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import InvoiceTurn from '@/components/InvoiceTurn'
import { getAllPatientTags } from '@/store/patientTags/actions.js'
import { Grid } from '@mui/material'

import AddPaymentForm from './components/AddPaymentForm'
import PatientDataForm from './components/PatientDataForm'
import PaymentDataForm from './components/PaymentDataForm'
import ProductsForm from './components/ProductsForm'
import TurnDataForm from './components/TurnDataForm'

export const FormContent = ({ showAllForm }) => {
  const dispatch = useDispatch()
  const { selectedTurn } = useSelector((state) => state.turns)

  useEffect(() => {
    dispatch(getAllPatientTags(selectedTurn?.centre))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`animate__animated animate__fadeIn`}>
      <Grid container style={{ padding: '10px' }}>
        <PatientDataForm showAllForm={showAllForm} />
        <TurnDataForm />
        <ProductsForm />
        <PaymentDataForm />
        <InvoiceTurn />
        <AddPaymentForm />
      </Grid>
    </div>
  )
}
