import React from 'react'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import dayjs from 'dayjs'

import s from '../../styles.module.scss'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement,
)

const AbsentTurnsChart = ({ statistics }) => {
  const { t } = useTranslation('global')

  const chartLabels = statistics?.map((elem) => {
    return dayjs(elem?.code.slice(0, -3)).format('MMM.YYYY').toUpperCase()
  })

  const chartData = statistics?.map((elem) => {
    let auxData = (elem?.absentTurnsCount / elem?.totalTurnsCount) * 100
    return auxData.toFixed(2)
  })

  return (
    <div className={s.itemChart}>
      <Bar
        height={225}
        width={450}
        options={{
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: true,
              labels: {
                font: {
                  weight: '600',
                },
              },
            },
          },
        }}
        data={{
          labels: chartLabels,
          datasets: [
            {
              label: `Porcentaje de ${t('turnLabel.p')} ausentes`,
              data: chartData,
              borderColor: ['rgb(234, 29, 73)'],
              backgroundColor: ['rgba(255, 99, 132, 0.2)'],
              borderWidth: 3,
              minBarLength: 10,
            },
          ],
        }}
      />
    </div>
  )
}

export default AbsentTurnsChart
