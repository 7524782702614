import { useState } from 'react'
import Layout1 from '@/layouts/Layout1'
import { Grid } from '@mui/material'

import SuccessPayment from './components/SuccessPayment'

import s from './styles.module.scss'

const SuccessPaymentPage = () => {
  const [isLoading] = useState(false)

  return (
    <Layout1 navbar isLoading={isLoading}>
      <Grid className={s.mainContainer}>
        <SuccessPayment />
      </Grid>
    </Layout1>
  )
}

export default SuccessPaymentPage
