import { Select } from '@mui/material'

import styled from 'styled-components'

export const SelectStyles = styled(Select)`
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.base.white};
  border-radius: 4px;
  font-size: 16px;
  padding: 12px;
  gap: 8px;
  border-color: red;
`
