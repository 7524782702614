import { es } from '@/assets/translations'

export const handleSetDniLabel = (countryCode) => {
  for (const country in es) {
    if (country === countryCode) {
      return es[country]['DNI_LABEL']
    }
  }

  return 'DI'
}
