import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'cities',
  initialState: {
    searchedCities: [],
  },
  reducers: {
    GET_SEARCHED_CITIES: (state, { payload }) => {
      state.searchedCities = payload
    },
    RESET_SEARCHED_CITIES: (state) => {
      state.searchedCities = []
    },
  },
})

export const { GET_SEARCHED_CITIES, RESET_SEARCHED_CITIES } = slice.actions
export default slice.reducer
