import styled from 'styled-components'

export const STurnDialogHeader = styled.div`
  .container {
    background-color: ${(props) => props.theme.colors.turn.finished};
    color: ${(props) => props.theme.colors.base.white};
    border: none !important;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    margin: 0px;

    @media (max-width: 767px) {
      flex-direction: column;
      padding: 0px;
    }

    .title {
      min-height: 64px;
      background-image: linear-gradient(200deg, transparent, rgba(0, 0, 0, 0.2));
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      flex-grow: 1;
      font-size: 18px;
      font-weight: 600;
      padding: 10px 0px 10px 30px;
      margin: 0px;

      @media (max-width: 767px) {
        width: 100%;
        align-items: center;
        margin-top: 40px;
      }

      .small {
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        display: block;
      }
    }

    .infoTurn {
      background-color: rgba(52, 73, 94, 0.45);
      font-size: 12px;
      display: flex;
      flex-direction: column;
      padding: 4px 10px;

      @media (max-width: 767px) {
        width: 100%;
        align-items: center;
        margin-right: 0px;
      }

      .dateSection {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 2px;

        @media (max-width: 767px) {
          justify-content: center;
        }

        .containerCalendar {
          button {
            color: #fff;

            &:hover {
              background-color: transparent;
            }
          }
        }

        input {
          max-width: 35px;
          font-weight: 600;
          border: none;
          border-radius: 2px;
        }

        .spanTo {
          margin: 2px;
          color: ${(props) => props.theme.colors.base.white};
        }
      }
    }

    .closeButton {
      color: white;
      top: 10px;
      padding: 5px;
    }

    .buttonsContainer {
      display: flex;
      align-self: self-start;
      @media (max-width: 767px) {
        position: absolute;
        right: 10px;
        padding: 0px;
      }
    }
  }
`
