import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import LoadingCalu from '@/components/LoadingCalu'

const MainPage = () => {
  const navigate = useNavigate()

  const { usersProfileConnected } = useSelector((state) => state.users)
  const { isCentre, isDoctor, isAdmin, isSecretary, isPatient, entityInLocalStorage } = usersProfileConnected

  const checkRoleAndRedirect = () => {
    if (isCentre || isDoctor || isSecretary) {
      navigate(`/agenda/centro/${entityInLocalStorage._id}`)
    }
    if (isAdmin) {
      navigate(`/admin/centros`)
    }
    if (isPatient) {
      navigate(`/turnos`)
    }
  }

  useEffect(() => {
    if (!entityInLocalStorage) {
      navigate('/ingresar')
    }
    checkRoleAndRedirect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityInLocalStorage])

  return <LoadingCalu />
}

export default MainPage
