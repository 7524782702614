import React from 'react'
import AddIcon from '@mui/icons-material/Add'

import PropTypes from 'prop-types'

import s from './styles.module.scss'

const TagState = ({ text, icon = null, onClick = null, className = '' }) => {
  return (
    <div className={`${s.divTagState} ${onClick ? s.link : ''} ${className}`} onClick={onClick}>
      {icon === 'add' ? <AddIcon /> : null}
      {text}
    </div>
  )
}

export default TagState

TagState.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}
