import * as React from 'react'
import { useSelector } from 'react-redux'
import { handleSetMoneySign } from '@/utils/functions/handleSetMoneySign'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import dayjs from 'dayjs'

const SummaryTable = ({ billingTab }) => {
  const { lastPaymentSearch, totals: cashboxItemsTotals } = useSelector((state) => state.cashboxItems)

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const country = centreDetail?.country || import.meta.env.REACT_APP_COUNTRY.toUpperCase()

  const tableHeadData = billingTab ? ['Monto a pagar', 'Monto pagado', 'Deuda'] : ['Monto pagado']

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              '& th': {
                fontSize: '16px',
                backgroundColor: '#FFC17F',
              },
            }}
          >
            <TableCell>Saldo</TableCell>
            {tableHeadData?.map((elem, i) => {
              return (
                <TableCell key={i} align="center">
                  {elem}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0, fontWeight: 500, background: '#EEE' } }}>
            <TableCell component="th" scope="row">
              Saldo al {toCustomTz(dayjs(lastPaymentSearch?.to), undefined, false, 'DD/MM/YYYY')}
            </TableCell>
            {billingTab ? (
              <>
                <TableCell align="center">
                  {handleSetMoneySign(country)} {cashboxItemsTotals?.totalAmount}
                </TableCell>
                <TableCell align="center">
                  {handleSetMoneySign(country)} {cashboxItemsTotals?.totalPayments}
                </TableCell>
                <TableCell align="center">
                  {handleSetMoneySign(country)} {cashboxItemsTotals?.totalDebts}
                </TableCell>
              </>
            ) : (
              <TableCell align="center">
                {handleSetMoneySign(country)} {cashboxItemsTotals?.totalPayments}
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SummaryTable
