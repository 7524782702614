import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CaluButton from '@/components/styledComponents/CaluButton'
import HistoryIcon from '@mui/icons-material/History'

import s from '../styles.module.scss'

const PendingPayment = () => {
  const { t } = useTranslation('global')
  const navigate = useNavigate()

  return (
    <>
      <div className={s.statusTitle}>Pago pendiente de confirmación</div>
      <div className={s.title}>Aguardamos la confirmación del pago para reservar su {t('turnLabel.s')}</div>

      <div className={s.containerCard}>
        <HistoryIcon className={s.checkIcon} />

        <CaluButton color="primary" size="large" onClick={() => navigate('/turnos')}>
          Ir a mis {t('turnLabel.p')}
        </CaluButton>
      </div>
    </>
  )
}

export default PendingPayment
