export const formDefaultValues = {
  name: '',
  country: '',
  street: '',
  city: '',
  phone: '',
  email: '',
  web: '',
  doctorNumber: '',
  hidePaymentInfo: false,
  showPatientAbsent: false,
  emailOnlineTurnCreation: false,
  emailOnlineTurnCancellation: false,
  centreConfiguration: {
    enableOptionalDniOnPatients: false,
    shouldSendDoctorAgendaEmail: false,
  },
}
