// @ts-check
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useValidateURLId from '@/hooks/useValidateURLId'
import Layout1 from '@/layouts/Layout1'
import ProductsPage from '@/pages/ProductsPage'
import Statistics from '@/pages/Statistics'
import { getAllServices } from '@/store/services/actions'
import { resetDaysMonth } from '@/store/turns/actions'
import { directionOfAnimation } from '@/utils/functions/directionOfAnimation'
import { Box, Tab, Tabs, tabsClasses } from '@mui/material'

import PropTypes from 'prop-types'
import BillingTab from './components/billingTab'
import PaymentsTab from './components/paymentsTab'
import PopUpAddEntry from './components/popUpAddEntry'
import ProvidersTab from './components/providersTab'
import TreatmentsDebtsTab from './components/treatmentsDebtsTab'
import TurnsDebtsTab from './components/turnsDebtsTab'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 0.5 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const CashRegister = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('global')
  const centreID = useValidateURLId()
  const [tab, setTab] = useSearchParams({ tab: 'facturacion' })
  const navigate = useNavigate()

  const { selectedCashboxItem } = useSelector((state) => state.cashboxItems)
  const centreDetail = useSelector((state) => state.centres.centreProfile)

  const [value, setValue] = useState(0)
  const [prevValuePanel, setPrevValuePanel] = useState(0)
  const [openAddEntry, setOpenAddEntry] = useState(false)
  const [inputSearch, setInputSearch] = useState('')
  const [loadingPatients, setLoadingPatients] = useState(false)
  const [patientValue, setPatientValue] = useState(null)

  const valuesTabs = [
    'facturacion',
    'pagos',
    `deudas ${t('turnLabel.p')}`,
    'deudas sesiones',
    'proveedores',
    'productos',
    'estadisticas',
  ]

  const tabSelected = tab.get('tab')

  const handleTabChange = (event, newValue) => {
    setPrevValuePanel(value)
    setValue(newValue)
    setTab({ tab: valuesTabs[newValue] })
    setInputSearch('')
  }

  useEffect(() => {
    let indexTab = valuesTabs.findIndex((elem) => elem === tabSelected)
    if (indexTab === -1 || (indexTab === 5 && !centreDetail?.hasStockModule)) {
      setTab({ tab: 'facturacion' })
      setValue(0)
      return
    }
    setTab({ tab: tabSelected })
    setValue(indexTab)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected])

  const handleClose = () => {
    setOpenAddEntry(!openAddEntry)
  }

  useEffect(() => {
    if (!centreDetail?.hasCashboxModule) navigate(`/`)

    const params = { centre: centreID }
    dispatch(getAllServices(params))
    dispatch(resetDaysMonth())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout1 navbar>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Módulo de gestión"
            value={value}
            onChange={handleTabChange}
            sx={{
              '& .MuiTab-root.Mui-selected': {
                color: '#4C5EFE',
              },
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            <Tab label="Facturación" value={0} />
            <Tab label="Pagos" value={1} />
            <Tab label={`Deudas ${t('turnLabel.p')}`} value={2} />
            <Tab label="Deudas de sesiones" value={3} />
            <Tab label="Proveedores" value={4} />
            {centreDetail?.hasStockModule && <Tab label="Productos" value={5} />}
            <Tab label="Estadisticas" value={6} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <BillingTab
            setOpenAddEntry={setOpenAddEntry}
            inputSearch={inputSearch}
            setInputSearch={setInputSearch}
            loadingPatients={loadingPatients}
            setLoadingPatients={setLoadingPatients}
            patientValue={patientValue}
            setPatientValue={setPatientValue}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PaymentsTab
            setOpenAddEntry={setOpenAddEntry}
            inputSearch={inputSearch}
            setInputSearch={setInputSearch}
            directionOfAnimation={directionOfAnimation(value, prevValuePanel)}
            loadingPatients={loadingPatients}
            setLoadingPatients={setLoadingPatients}
            patientValue={patientValue}
            setPatientValue={setPatientValue}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TurnsDebtsTab directionOfAnimation={directionOfAnimation(value, prevValuePanel)} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TreatmentsDebtsTab directionOfAnimation={directionOfAnimation(value, prevValuePanel)} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ProvidersTab directionOfAnimation={directionOfAnimation(value, prevValuePanel)} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          {centreDetail?.hasStockModule ? (
            <ProductsPage directionOfAnimation={directionOfAnimation(value, prevValuePanel)} />
          ) : (
            <p>Módulo Productos no está activo</p>
          )}
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Statistics directionOfAnimation={directionOfAnimation(value, prevValuePanel)} />
        </TabPanel>
      </Box>

      <PopUpAddEntry open={openAddEntry} onClose={handleClose} selectedCashboxItem={selectedCashboxItem} />
    </Layout1>
  )
}
export default CashRegister
