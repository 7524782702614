import i18next from '@/configs/i18next'

const printResume = () => {
  var w = window.open('', `Imprimir resumen de ${i18next.t('turnLabel.s', { ns: 'global' })}`)

  w.document.write(
    `<html><head><title>Resumen|de|${i18next.t('turnLabel.s', { ns: 'global' })}` +
      '</title>' +
      '<link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" />' +
      '<style>' +
      'body { font-family: "Roboto", sans-serif; font-size: 12px; }' +
      'div { border-radius: 5px; padding: 10px; }' +
      'p { line-height: 10px; margin-bottom: 0px; }' +
      'i { margin-left: -10px; }' +
      'hr { border-top: 1px solid #FAFAFA; }' +
      '@page { size: A5; margin: 10px; }' +
      '</style></head><body><div>',
  )
  w.document.write(document.getElementById('print-turn').innerHTML)
  w.document.write('</div></body></html>')

  w.document.close()
  w.focus()
  w.print()
  w.close()
}

export default printResume
