import { useController } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers'

import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import s from './styles.module.scss'

export const DateField = ({ className, fullWidth, label, name, initValue, margin = true, variant }) => {
  const {
    field: { onChange, onBlur, value = initValue, ref },
    fieldState: { error },
  } = useController({ name })

  return (
    <div className={margin ? s.inputContainer : s.withoutMargin}>
      {label && <div className={s.titleInput}>{label}</div>}
      <DatePicker
        className={className || ''}
        inputRef={ref}
        format="DD/MM/YYYY"
        onChange={(newDate) => {
          if (newDate && dayjs(newDate).isValid()) onChange(dayjs(newDate).toISOString())
        }}
        timezone="UTC"
        value={dayjs(value)}
        slotProps={{
          textField: {
            variant: variant,
            fullWidth,
            className: className || '',
            error: Boolean(error),
            helperText: 'Ingresar la fecha en formato DD/MM/YYYY',
            name,
            onBlur,
            sx: {
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '2px solid #0f1333',
                },
                '&:hover fieldset': {
                  borderColor: '#0f1333',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#0f1333',
                },
              },
            },
          },
        }}
      />
    </div>
  )
}

DateField.propTypes = {
  className: PropTypes.string,
  initValue: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
}

DateField.defaultProps = {
  fullWidth: false,
  initValue: null,
  variant: 'outlined',
}
