import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import LoaderCircle from '@/components/LoaderCircle'
import CaluButton from '@/components/styledComponents/CaluButton'
import { mercadoPagoStatus, subscriptionPaymentType, subscriptionStatus } from '@/helpers/constants'
import { convertIdToName } from '@/utils/functions/convertIdToName'
import { convertValueToName } from '@/utils/functions/convertValueToName'
import { Chip } from '@mui/material'

import s from '../../styles.module.scss'

const Information = ({ isLoading, dataSubscription }) => {
  const demoColor = '#ffea8a'
  const activeColor = '#63d2bc'
  const cancelledColor = '#ea9eb5'

  const [initSubscription, setInitSubscription] = useState(false)

  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { allSubscriptionPlans } = useSelector((state) => state?.subscriptionPlans)
  const { bankTransferInfo } = useSelector((state) => state?.bankTransferInfo)

  const getChipProps = (params) => {
    if (params === 'active') {
      return {
        label: convertValueToName(subscriptionStatus, params),
        style: {
          backgroundColor: activeColor,
        },
      }
    }

    if (params === 'demo') {
      return {
        label: convertValueToName(subscriptionStatus, params),
        style: {
          backgroundColor: demoColor,
        },
      }
    }
  }

  const getChipPropsMPStatus = (params) => {
    if (params === 'authorized') {
      return {
        label: convertValueToName(mercadoPagoStatus, params),
        style: {
          backgroundColor: activeColor,
        },
      }
    }

    if (params === 'pending') {
      return {
        label: convertValueToName(mercadoPagoStatus, params),
        style: {
          backgroundColor: demoColor,
        },
      }
    }

    if (['cancelled', 'paused'].includes(params)) {
      return {
        label: convertValueToName(mercadoPagoStatus, params),
        style: {
          backgroundColor: cancelledColor,
        },
      }
    }
  }

  return (
    <div className={s.informationContainer}>
      <h4 className={s.title}>Datos de mi suscripción</h4>

      <div className={s.item}>
        {centreDetail?.centreConfiguration?.subscriptionPlan && (
          <div>
            <span>Plan:</span>{' '}
            <span className={s.bold}>
              {convertIdToName(allSubscriptionPlans, centreDetail?.centreConfiguration?.subscriptionPlan)}
            </span>{' '}
            <span
              className={s.bold}
            >{`(${centreDetail?.centreConfiguration?.subscriptionAgendasQuantity} Agendas)`}</span>
          </div>
        )}
        <span>Estado de cuenta:</span> <Chip variant="filled" size="small" {...getChipProps(centreDetail?.status)} />
      </div>

      <div className={s.divider}></div>

      {isLoading ? (
        <div className={s.circularProgress}>
          <LoaderCircle size={50} />
        </div>
      ) : (
        <div className={s.item}>
          {centreDetail?.centreConfiguration?.subscriptionPaymentType && (
            <>
              <span>Método de pago:</span>{' '}
              <span className={s.bold}>
                {centreDetail?.centreConfiguration?.subscriptionPaymentType === 'mercadoPagoSubscription'
                  ? 'Mercado Pago'
                  : convertValueToName(
                      subscriptionPaymentType,
                      centreDetail?.centreConfiguration?.subscriptionPaymentType,
                    )}
              </span>{' '}
            </>
          )}
          {(!centreDetail?.centreConfiguration?.subscriptionPaymentType ||
            centreDetail?.centreConfiguration?.subscriptionPaymentType === 'paymentLink') && (
            <div className={s.item}>
              {centreDetail?.paymentLink && (
                <div>
                  Link:{' '}
                  <a className={s.itemLink} href={centreDetail?.paymentLink} rel="noreferrer" target="_blank">
                    {centreDetail?.paymentLink}
                  </a>
                </div>
              )}
              {centreDetail?.paymentDescription && (
                <div>
                  Descripción: <span>{centreDetail?.paymentDescription}</span>
                </div>
              )}
              {centreDetail?.paymentNote && (
                <div className={s.paymentNote}>
                  Nota: <span>{centreDetail?.paymentNote}</span>
                </div>
              )}
            </div>
          )}

          {centreDetail?.centreConfiguration?.subscriptionPaymentType === 'bankTransfer' && (
            <div className={s.item}>
              <p>Realice su transferencia bancaria al siguiente destinatario:</p>
              <pre>{bankTransferInfo?.info}</pre>
            </div>
          )}

          {centreDetail?.centreConfiguration?.subscriptionPaymentType === 'mercadoPagoSubscription' &&
            centreDetail?.centreConfiguration?.mercadoPagoPreapprovalPlanInitPoint &&
            (!dataSubscription ||
              JSON.stringify(dataSubscription) === '{}' ||
              dataSubscription?.status === 'cancelled') && (
              <div className={s.item}>
                <p>Con el siguiente botón puede iniciar su suscripción de Calu:</p>
                <p>
                  <a
                    href={centreDetail?.centreConfiguration?.mercadoPagoPreapprovalPlanInitPoint}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <CaluButton
                      color="primary"
                      loading={initSubscription}
                      size="medium"
                      onClick={() => setInitSubscription(true)}
                    >
                      Iniciar suscripción
                    </CaluButton>
                  </a>
                </p>
                {initSubscription ? (
                  <p className={s.linkSubscription}>
                    Creación de suscripción en curso. Si ya creó su suscripción por favor recargue la página
                  </p>
                ) : (
                  <p className={s.linkSubscription}>
                    {centreDetail?.centreConfiguration?.mercadoPagoPreapprovalPlanInitPoint}
                  </p>
                )}
              </div>
            )}

          {centreDetail?.centreConfiguration?.subscriptionPaymentType === 'mercadoPagoSubscription' &&
            dataSubscription?.status && (
              <div>
                <span>Estado de suscripción:</span>{' '}
                <Chip variant="filled" size="small" {...getChipPropsMPStatus(dataSubscription?.status)} />
              </div>
            )}

          {centreDetail?.centreConfiguration?.subscriptionPaymentType === 'mercadoPagoSubscription' &&
            dataSubscription?.status === 'authorized' && (
              <p className={s.cancelSubscriptionText}>Para cancelar la suscripción contáctate con soporte</p>
            )}
        </div>
      )}

      {centreDetail?.centreConfiguration?.subscriptionNote && (
        <div>
          <div className={s.divider}></div>
          <p className={s.paymentNote}>{centreDetail?.centreConfiguration?.subscriptionNote}</p>
        </div>
      )}
    </div>
  )
}

export default Information
