import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ButtonsPagination from '@/components/ButtonsPaginations'
import { SELECTED_REMINDER, SELECTED_TURN_REMINDER } from '@/store/wapp'
import { getWappRemindersHistory, getWappTurnHistory } from '@/store/wapp/actions'
import { getMessageTypeReminder } from '@/utils/functions/getMessageTypeReminder'
import { handleSetCountryCode } from '@/utils/functions/handleSetCountryCode'
import { toCustomTz } from '@/utils/functions/toCustomTz'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { DataGrid } from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import dayjs from 'dayjs'

import s from './styles.module.scss'

const ManagementTable = ({ startDate, finishDate, deviceId, setOpen, selectValue, filterOptionsValue }) => {
  const dispatch = useDispatch()
  const { wappHistory, isLoading } = useSelector((state) => state.wapp)
  const { turnHistory, remindersHistory, paginationData } = wappHistory
  const { totalDocs } = paginationData || {}
  const { centreProfile } = useSelector((state) => state?.centres)
  const { t } = useTranslation('global')

  const renderDateReminder = (params) => {
    const attempts = params.value

    return (
      <div className={s.attemptsContainer}>
        {attempts?.map((a) => (
          <div className={s.iconContainer} key={a._id}>
            {a.status === 'sent' ? (
              <Tooltip title="Enviado">
                <CheckIcon className={s.checkIcon} />
              </Tooltip>
            ) : (
              <Tooltip title="Error al enviar">
                <CloseIcon className={s.errorIcon} />
              </Tooltip>
            )}

            <span>{dayjs(a?.date).format('DD/MM/YYYY [a las] HH:mm [hs]')}</span>
          </div>
        ))}
      </div>
    )
  }

  const renderMessageType = (params) => {
    const attempts = params.value

    return (
      <div>
        {attempts?.map((a) => (
          <div key={a._id} className={s.messageType}>
            {a.messageType}
          </div>
        ))}
      </div>
    )
  }

  const stylesColumns = {
    headerClassName: 'header',
    alignItems: 'flex-start',
    flex: 1,
  }

  const columns = [
    {
      field: 'fullName',
      headerName: 'Nombre',
      ...stylesColumns,
      renderCell: (params) => <strong>{params.value}</strong>,
    },

    {
      field: 'mobile',
      headerName: 'Teléfono',
      ...stylesColumns,
    },

    {
      field: 'turnDate',
      headerName: `Fecha de ${t('turnLabel.s')}`,
      ...stylesColumns,
      renderCell: (params) => <div className={s.divAlignText}>{params.value}</div>,
    },
    {
      field: 'messageType',
      headerName: `Tipo de recordatorio`,
      ...stylesColumns,
      renderCell: (params) => renderMessageType(params),
    },
    {
      field: 'date',
      headerName: 'Fecha de envío',
      ...stylesColumns,
      renderCell: (params) => renderDateReminder(params),
    },
  ]

  const reminderHistoryData = () => {
    if (turnHistory?.length) return turnHistory
    if (remindersHistory?.length) return remindersHistory
    return []
  }

  const rows = reminderHistoryData()?.map((r) => {
    const patientCentre = r.patientCentre

    const countryCode = handleSetCountryCode(
      patientCentre?.country || centreProfile?.country || import.meta.env.REACT_APP_COUNTRY,
    )
    const patientMobile = patientCentre?.mobile
    const wappReminder = r?.wappReminder || r
    const attempts = wappReminder?.attempts
    let turn = r?.wappReminder ? r : wappReminder?.turn
    turn = {
      ...turn,
      patientCentre: patientCentre,
    }

    return {
      id: r?._id,
      fullName: `${patientCentre?.lastname?.toUpperCase()}, ${patientCentre?.firstname?.toUpperCase()}`,
      mobile: patientMobile ? countryCode + patientMobile : 'Celular sin registrar',
      turnDate: turn?.startHour ? toCustomTz(turn?.startHour, undefined, true, 'DD/MM/YYYY [a las] HH:mm [hs]') : '-',
      attempts: attempts,
      messageType: attempts,
      date: attempts,
      turn: turn,
    }
  })

  const handleChangePaginationNumber = (page) => {
    let params = {
      centre: deviceId,
      start: toCustomTz(startDate, 'utc', true),
      finish: toCustomTz(finishDate, 'utc', true),
      page: page,
      limit: 30,
    }
    if (selectValue === 1) {
      params.sent = true
      dispatch(getWappTurnHistory(params))
      return
    }
    const messageType = getMessageTypeReminder(filterOptionsValue)
    if (messageType) {
      params.messageType = messageType
    }

    dispatch(getWappRemindersHistory(params))
  }

  const handleCellClick = async (params, event) => {
    if (selectValue === 1) {
      dispatch(SELECTED_TURN_REMINDER(params?.row))
    }
    if (selectValue === 2) {
      dispatch(SELECTED_REMINDER(params?.row))
    }
    setOpen(true)
  }

  return (
    <Box
      sx={{
        px: 0,
        '& .header': {
          fontSize: '14px',
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
          background: '#fff',
        },
      }}
    >
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'fullname', sort: 'asc' }],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        rows={rows || []}
        rowCount={totalDocs || 0}
        getRowHeight={() => 'auto'}
        loading={isLoading}
        columns={columns}
        disableRowSelectionOnClick={true}
        onCellClick={(params, event) => handleCellClick(params, event)}
        rowClassName={s.rowClassName}
        hideFooter={true}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          fontSize: '13px',
          background: '#fff',
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell ': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            margin: '10px 0px 10px 0px',
          },
        }}
        rowHeight={45}
        autoHeight={true}
      />
      {(turnHistory?.length >= 1 || remindersHistory?.length >= 1) && (
        <div className={s.paginationButton}>
          <ButtonsPagination
            paginationData={paginationData}
            handleChangePaginationNumber={handleChangePaginationNumber}
          />
        </div>
      )}
    </Box>
  )
}

export default ManagementTable
