import React from 'react'

import s from './styles.module.scss'

const PageHearedTitle = (props) => {
  const { titleText, titleClass } = props
  return (
    <div className={titleClass === 'bgGreenChedoc' ? s.bgGreenChedoc : s.containerTitle}>
      <p>{titleText}</p>
    </div>
  )
}

export default PageHearedTitle
