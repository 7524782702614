import React from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import TextField from '@mui/material/TextField'

const SearchQuestion = ({ input, handleChange }) => {
  return (
    <div>
      <TextField
        id="search"
        autoComplete="off"
        label="Buscador de preguntas frecuentes"
        variant="standard"
        fullWidth={true}
        type="text"
        value={input}
        onChange={handleChange}
        InputProps={{
          endAdornment: <AiOutlineSearch />,
        }}
      />
    </div>
  )
}

export default SearchQuestion
