import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Button, Menu, MenuItem } from '@mui/material'

import s from '../../styles.module.scss'

export default function AdminOptions() {
  const centreDetail = useSelector((state) => state.centres.centreProfile)
  const { usersProfileConnected } = useSelector((state) => state.users)
  const { entityInLocalStorage } = usersProfileConnected

  const [showAdminPanel, setShowAdminPanel] = useState(false)
  const [currentTarget, setCurrentTarget] = useState(document.firstElementChild)

  const [chargePanelOptions, setChargePanelOptions] = useState(false)
  const [chargePanelOptionsCurrentTarget, setChargePanelOptionsCurrentTarget] = useState(document.firstElementChild)

  return (
    <>
      <Button
        className={s.navButton}
        size={'large'}
        onClick={(e) => {
          setShowAdminPanel(true)
          setCurrentTarget(e.currentTarget)
        }}
      >
        Opciones <KeyboardArrowDownIcon />
      </Button>
      <Menu anchorEl={currentTarget} open={showAdminPanel} onClose={() => setShowAdminPanel(false)}>
        <Link to="/admin/centros" className={s.link}>
          <MenuItem className={s.hoverText}>Centros</MenuItem>
        </Link>

        <Link to="/admin/usuarios" className={s.link}>
          <MenuItem className={s.hoverText}>Usuarios</MenuItem>
        </Link>

        <Link to="/admin/pacientes" className={s.link}>
          <MenuItem className={s.hoverText}>Perfiles Paciente</MenuItem>
        </Link>

        <Link to="/admin/notificaciones" className={s.link}>
          <MenuItem className={s.hoverText}>Notificaciones</MenuItem>
        </Link>

        <Link to="/admin/precio-de-planes" className={s.link}>
          <MenuItem className={s.hoverText}>Precio de planes</MenuItem>
        </Link>

        <MenuItem
          className={s.hoverText}
          onClick={(e) => {
            setChargePanelOptions(true)
            setChargePanelOptionsCurrentTarget(e.currentTarget)
          }}
        >
          Panel de carga <KeyboardArrowRightIcon />
        </MenuItem>
        <Menu
          id="menu-charge-panel"
          anchorEl={chargePanelOptionsCurrentTarget}
          open={chargePanelOptions}
          onClose={() => setChargePanelOptions(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Link to="/admin/panel/camposHC" className={s.link}>
            <MenuItem className={s.hoverText}>Campos de HC</MenuItem>
          </Link>
          <Link to="/admin/panel/obras-sociales" className={s.link}>
            <MenuItem className={s.hoverText}>Obras sociales</MenuItem>
          </Link>
          <Link to="/admin/panel/especialidades" className={s.link}>
            <MenuItem className={s.hoverText}>Especialidades</MenuItem>
          </Link>
          <Link to="/admin/panel/datosbancarios" className={s.link}>
            <MenuItem className={s.hoverText}>Datos bancarios</MenuItem>
          </Link>
        </Menu>

        {!entityInLocalStorage?._id ? null : centreDetail?._id === entityInLocalStorage?._id ? (
          <Link to="/admin/panel/centro" className={s.link}>
            <MenuItem className={s.hoverText}>Panel de carga del centro</MenuItem>
          </Link>
        ) : null}
      </Menu>
    </>
  )
}
