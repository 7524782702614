import { apiErrorHandler } from '@/helpers/errorHandlers'

import API from '../client'

const fetchPlanPrices = async (params) => {
  const route = `api/planPrices`
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const addNewPlanPrices = async (data) => {
  const route = `api/planPrices`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const deletePlanPrices = async (id) => {
  const route = `api/planPrices/${id}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const putPlanPrices = async (data) => {
  const route = `api/planPrices`
  return API.put(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { addNewPlanPrices, deletePlanPrices, fetchPlanPrices, putPlanPrices }
