import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PageHearedTitle } from '@/components/pages'
import BaseSideBar from '@/layouts/Layout1/components/BaseSideBar'
import ContentSidebar from '@/pages/AdminNotificationsPage/components/contentSideBar'
import {
  deleteNotificationAdded,
  getAdminNotificationsBySearch,
  getUserNotifications,
  resetSelectedNotification,
} from '@/store/notifications/actions'
import { Paper } from '@mui/material'

import CardInfoNotification from './components/cardInfoNotification'
import PopUpAdminNotifications from './components/popUpAdminNotifications'
import Layout1 from '../../layouts/Layout1/index'

import s from './styles.module.scss'

const AdminNotificationsPage = () => {
  const dispatch = useDispatch()

  const [openNotificationPopUp, setOpenNotificationPopUp] = useState(false)
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [openAlertDelete, setOpenAlertDelete] = useState(false)
  const { notifications, notificationSelected } = useSelector((state) => state.notifications)

  useEffect(() => {
    if (input?.length) {
      dispatch(getAdminNotificationsBySearch(input))
    } else {
      init()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input])

  const init = async () => {
    await dispatch(getUserNotifications({ role: 'admin' }))
    setIsLoading(false)
  }

  const addNewNotification = () => {
    dispatch(resetSelectedNotification())
    setOpenNotificationPopUp(!openNotificationPopUp)
  }

  const modifyNotification = () => {
    setOpenNotificationPopUp(!openNotificationPopUp)
  }

  const deleteNotification = () => {
    dispatch(deleteNotificationAdded(notificationSelected?._id))
    setOpenAlertDelete(false)
  }

  const handleChange = (e) => {
    setInput(e.target.value)
  }

  return (
    <Layout1
      navbar
      isLoading={isLoading}
      leftSidebar={
        <BaseSideBar
          inputLabel={'Buscar notificación'}
          inputValue={input}
          inputOnChange={handleChange}
          buttonLabel={'Nueva notificación'}
          buttonOnClick={addNewNotification}
          content={<ContentSidebar listItem={notifications} />}
          panelAdmin={true}
        />
      }
    >
      <div className={s.mainContainer}>
        <div className={s.contentContainer}>
          <PageHearedTitle titleText="Notificaciones" />
          {notificationSelected !== null ? (
            <CardInfoNotification
              modifyNotification={modifyNotification}
              openAlertDelete={openAlertDelete}
              setOpenAlertDelete={setOpenAlertDelete}
              deleteNotification={deleteNotification}
            />
          ) : (
            <Paper elevation={3} className={`${s.cardContainer} animate__animated animate__fadeIn`}>
              <p>Seleccione una notificación del listado para ver su información</p>
            </Paper>
          )}
        </div>
      </div>

      {openNotificationPopUp && (
        <PopUpAdminNotifications open={openNotificationPopUp} setOpen={setOpenNotificationPopUp} />
      )}
    </Layout1>
  )
}

export default AdminNotificationsPage
