import { weekDays } from '@/helpers/constants'

export const getDayCode = (name) => {
  let code = null
  weekDays.forEach((d) => {
    if (d.name === name) {
      code = d.code
    }
  })
  return code
}
