//debe recibir el array que queremos filtrar y el texto que queremos buscar
export const filterArrayBySearchText = (array, searchText) => {
  const filteredArray = array?.filter((entity) =>
    (entity.lastname || entity.name)
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .includes(
        searchText
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase(),
      ),
  )
  return filteredArray
}
