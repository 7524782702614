import { PAGE_LOADING_UPDATED, SET_BACKURL_MESSAGE } from '@/store/app'

const setPageLoading = (isPageLoading) => (dispatch) => {
  dispatch(PAGE_LOADING_UPDATED(isPageLoading))
}

const setBackURLMessage = (message) => (dispatch) => {
  dispatch(SET_BACKURL_MESSAGE(message))
}

export { setBackURLMessage, setPageLoading }
