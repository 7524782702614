import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useUser } from '@/context/UserProvider'
import { mixpanelInstance as mxp } from '@/utils/mixpanel'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Grid, IconButton, Menu } from '@mui/material'

import { logo, navLinks } from './navbar.config'

import s from './styles.module.scss'

const NavBarContent = () => {
  const { pathname } = useLocation()
  const [anchorElNav, setAnchorElNav] = useState(null)

  const { user, defaultCentre } = useUser()
  const { centreProfile } = useSelector((state) => state?.centres)
  const { doctorProfile } = useSelector((state) => state?.doctorProfile)

  const config = navLinks(user, defaultCentre, centreProfile, doctorProfile, pathname)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  let navBarItems = { left: [], right: [] }

  config?.forEach((item) => {
    item?.align === 'right' ? navBarItems['right'].push(item) : navBarItems['left'].push(item)
  })

  return (
    <Box className={s.boxContainer}>
      <Grid item className={s.leftItemsContainer}>
        <a href={logo?.href} onClick={() => mxp.track(mxp.events.navbar.logoClicked)}>
          <img className={s.logo} src={logo?.src} alt={logo?.alt} />
        </a>

        <div className={s.itemsContent}>
          {navBarItems.left.map((item, i) => (
            <div key={i}>{item?.content}</div>
          ))}
        </div>
      </Grid>

      <Grid item className={s.rightItemsContainer}>
        <div className={s.itemsContent}>
          {navBarItems.right.map((item, i) => (
            <span key={i}>{item?.content}</span>
          ))}
        </div>

        <IconButton onClick={handleOpenNavMenu} className={s.mobileMenuButton}>
          <MenuIcon />
        </IconButton>

        <Menu
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
        >
          <div className={s.mobileListMenu}>
            {config?.map((item, i) => (
              <span key={i}>{item.content}</span>
            ))}
          </div>
        </Menu>
      </Grid>
    </Box>
  )
}

export default NavBarContent
