import React, { useEffect } from 'react'
import LoaderCircle from '@/components/LoaderCircle/index.jsx'
import { Paper } from '@mui/material'

import NewPatientTreatmentForm from './newPatientTreatment.jsx'
import PatientTreatmentItem from './patientTreatmentItem'

import s from './styles.module.scss'

const TreatmentSection = ({
  patientSelected,
  setLoading,
  loading,
  containerClass,
  setOpenPopUp,
  setTreatmentSection,
  handleClose,
  requestPatientData = undefined,
}) => {
  useEffect(() => {
    if (requestPatientData) requestPatientData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Paper elevation={3} className={`${s.cardContainer} ${s[containerClass]}`}>
      {!loading ? (
        <>
          <NewPatientTreatmentForm patientSelected={patientSelected} />
          <PatientTreatmentItem
            setOpenPopUp={setOpenPopUp}
            setTreatmentSection={setTreatmentSection}
            setLoading={setLoading}
            handleClose={handleClose}
          />
        </>
      ) : (
        <LoaderCircle />
      )}
    </Paper>
  )
}

export default TreatmentSection
