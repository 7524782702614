import {
  addNewClinicHistory,
  deleteClinicHistory,
  fetchClinicHistoryBackup,
  fetchSingleClinicHistory,
  putClinicHistory,
} from '@/services/clinicHistory'
import {
  CLINIC_HISTORY_CREATED,
  CLINIC_HISTORY_DELETE,
  CLINIC_HISTORY_SELECTED,
  CLINIC_HISTORY_UPDATED,
  FETCH_CLINIC_HISTORIES,
  RESET_CLINIC_HISTORY_SELECTED,
  SET_ISLOADING,
} from '@/store/clinicHistory'
import { showToast } from '@/utils/toast'

const getAllClinicHistories = (fetchAllClinicHistories) => async (dispatch) => {
  dispatch(FETCH_CLINIC_HISTORIES(fetchAllClinicHistories))
}

const getClinicHistoriesBackup = (centreId) => async (dispatch) => {
  dispatch(SET_ISLOADING(true))
  try {
    const backupHC = await fetchClinicHistoryBackup(centreId)
    return backupHC
  } catch (error) {
    const { message } = error
    console.log(message)
    showToast('Se produjo un error', 'error')
  } finally {
    dispatch(SET_ISLOADING(false))
  }
}

const postNewClinicHistory = (data) => async (dispatch, getState) => {
  const state = getState()
  const auxCentre = state.centres.centreProfile
  const auxPatientCentre = state.turns.selectedTurn
    ? state.turns.selectedTurn.patientCentre
    : state.patientCentres.patientSelected
  const auxIsOwner = data.isOwner
  const auxDoctorCentre = data.doctorCentre

  try {
    let newHC = await addNewClinicHistory(data)
    newHC = {
      ...newHC,
      isOwner: auxIsOwner,
      centre: auxCentre,
      patientCentre: auxPatientCentre,
      doctorCentre: auxDoctorCentre,
    }

    dispatch(CLINIC_HISTORY_CREATED(newHC))
    if (newHC) {
      showToast('Ficha guardada correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log('Error message: ', message)
  }
}

const deleteClinicHistoryAdded = (id) => async (dispatch) => {
  try {
    await deleteClinicHistory(id)
    dispatch(CLINIC_HISTORY_DELETE(id))
    showToast('Ficha eliminada correctamente', 'success')
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const updateClinicHistory = (data, hideAlert) => async (dispatch) => {
  const auxIsOwner = data.isOwner
  const auxShareData = data.shared
  const auxCentre = data.centre
  const auxDoctorCentre = data.doctorCentre
  const auxPatientCentre = data.patientCentre

  try {
    let clinicHistoryUpdated = await putClinicHistory(data)
    clinicHistoryUpdated = {
      ...clinicHistoryUpdated,
      centre: auxCentre,
      doctorCentre: auxDoctorCentre,
      patientCentre: auxPatientCentre,
      isOwner: auxIsOwner,
      shared: auxShareData,
    }

    dispatch(CLINIC_HISTORY_UPDATED(clinicHistoryUpdated))

    if (clinicHistoryUpdated && !hideAlert) {
      showToast('Ficha actualizada correctamente', 'success')
    }
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const selectedClinicHistory = (id) => async (dispatch) => {
  try {
    const singleTemplate = await fetchSingleClinicHistory(id)
    dispatch(CLINIC_HISTORY_SELECTED(singleTemplate))
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

const resetSelectedClinicHistory = () => async (dispatch) => {
  try {
    dispatch(RESET_CLINIC_HISTORY_SELECTED())
  } catch (error) {
    const { message } = error
    console.log(message)
  }
}

export {
  deleteClinicHistoryAdded,
  getAllClinicHistories,
  getClinicHistoriesBackup,
  postNewClinicHistory,
  resetSelectedClinicHistory,
  selectedClinicHistory,
  updateClinicHistory,
}
